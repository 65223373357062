<div class="container-fluid d-flex h-100">
  <div class="wrapper">
    <div class="leftSection loginWrap">
      <div class="d-flex align-items-center t-c h-100">
        <div class="form">
          <figure class="w-40 m-x-auto">
            <img src="./assets/images/contactus.png" alt="contact us">
          </figure>
          <h2 class="m-b-0 font-weight-bold text-uppercase">{{getTitle()}}</h2>
          <p>{{getBody()}}
          </p>
          <a [routerLink]="['/login']" class="curved btn-link" matRipple>Continue Login</a>
        </div>
      </div>
    </div>
    <div class="rightSection mainWrap" tabindex="0">
      <div class="describe">
        <picture>
          <source media="(max-width: 280px)" srcset="./assets/images/screenshot.png">
          <img src="./assets/images/screenshot.png" alt="screenshot">
        </picture>
        <figure class="logo">
          <img src="./assets/images/icon-insiq.png" alt="insiq">
        </figure>
        <p>
          AI-powered Product Development for everyone. Everything you need to transform your idea into a successful
          product.
        </p>

        <!-- <div class="bar-T p-y-10 m-b-30">
            <a href="https://www.insiq.io/about/" target="_blank" class="p-r-20">About Us</a>
            <a [routerLink]="['/contact']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Contact Us</a>
             <a [routerLink]="['/terms-and-conditions']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Terms and Conditions</a>
            <a [routerLink]="['/help']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Help</a>
          </div>-->

        <div class="bar-T p-y-10 m-b-30">
          <a href="https://www.insiq.io/about/" target="_blank" class="p-r-20">About Us</a>
          <a [routerLink]="['/contact']" [queryParams]="{
              url: view.returnUrl
            }" class="p-r-20">Contact Us</a>
          <a [routerLink]="['/terms-and-conditions']" [queryParams]="{
              url: view.returnUrl
            }" class="p-r-20">Terms of Service</a>
          <a href="https://www.insiq.io/product/#screenfq-Accordian" target="_blank" class="p-r-20">Help</a>
          <a href="https://www.insiq.io/pricing/#faq_prcng" target="_blank" class="p-r-20">FAQ</a>
        </div>

        <div class="copyright">
          <span>&copy; {{config.currentYear}} InsIQ. All rights reserved</span>
          <br>
          <a href="https://www.insiq.io" target="_blank">www.insiq.io</a>
        </div>
      </div>
    </div>
  </div>
</div>