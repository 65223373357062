<div class="container-fluid d-flex h-100">
  <div class="wrapper" [ngClass]="!ai.display ? 'active' : 'hidden'">
    <app-sub-header [data]="header" (aiEvent1)="ai.smartDev(1,ao.view, ss.getProject())" (aiEvent2)="ai.smartDev(2,ao.view, ss.getProject())" (aiEvent3)="ai.smartDev(3,ao.view, ss.getProject())" (aiEvent4)="ai.smartDev(4,ao.view, ss.getProject())"
      (aiFav)="ai.wishList(ao.view, ss.getProject(), '1')"></app-sub-header>

    <mat-tab-group mat-stretch-tabs class="pageTabs mat-elevation-z4 w-100">
      <mat-tab label="Overview">
        <!-- TreeView -->
        <div class="conArea" [ngClass]="isList() ? 'active' : 'hidden'">
          <app-smart-tree (createNode)="newConnectNode($event)" (nodeOpen)="onNodeOpen($event)"
            (nodeClose)="onNodeClose($event)" [data]="data"></app-smart-tree>
        </div>

        <!-- Popups -->
        <!--{{!isList()}}
        {{ao.view.isForm()}}
        {{!ao.chooselist}}-->
        <div class="conArea p-x-20 p-t-20 p-b-30" *ngIf="!isList()">
          <div *ngIf="!re.view.isEdit()">
            <div class="row " *ngIf="ao.view.isForm()">
              <div class="col-sm-5">
                <app-tree-preview [data]="ao.view" title="Concepts"></app-tree-preview>
              </div>
              <div class="col-sm-19">
                <div class="workArea">
                  <mat-card class="popup">
                    <form [formGroup]="ao.view.form" novalidate>
                      <div class="popHeader d-flex align-items-center">
                        <span class="ib-m title">Risk Analysis</span>
                        <div class="spacer"></div>
                        <!-- <button mat-raised-button class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">SmartDev2m</span>
                      </button> -->
                        <button type="button" mat-icon-button class="close ib-m" (click)="ao.cancel()"
                          title="Close" matTooltipPosition="below">
                          <mat-icon fontSet="i" class="i-close"></mat-icon>
                        </button>
                      </div>
                      <mat-card-content class="popContent">
                        <mat-tab-group [(selectedIndex)]="ao.selectedTab">
                          <mat-tab label="Concepts">
                            <div class="rel dataTable ">
                              <p-table #dt [value]="ao.concepts" selectionMode="multiple" [paginator]="true"
                                [rows]="ao.view.pageSize" [(selection)]="ao.selectedConceptItem" dataKey="id"
                                [globalFilterFields]="['title','description']">
                                <ng-template pTemplate="caption">
                                  <div style="text-align: right">
                                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                                    <input type="text" pInputText size="50" placeholder="Search"
                                      (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                                  </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                  <tr>
                                    <th pSortableColumn="title"> Concept Title <p-sortIcon [field]="title"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="description"> Description <p-sortIcon [field]="description">
                                      </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="select"> Select <p-sortIcon [field]="select"></p-sortIcon>
                                    </th>
                                  </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-row>
                                  <tr [pSelectableRow]="row">
                                    <td>{{row.title}}</td>
                                    <td>{{row.description}}</td>
                                    <td>
                                      <button type="submit" row.title="row" (click)="ao.select(row)" mat-raised-button
                                        color="primary" class="curved m-x-5">Select</button>
                                    </td>
                                  </tr>
                                </ng-template>
                              </p-table>
                              <div class="t-c bar-T m-t-20 p-y-5">
                                <button type="button" (click)="ao.cancel()" mat-raised-button color="warn"
                                  class="curved m-x-5">Cancel</button>
                              </div>
                            </div>
                          </mat-tab>
                          <mat-tab label="Requirement" *ngIf="ao.requirementstatus">
                            <!-- Requirements -->
                            <div class="rel dataTable">
                              <p-table #dt [value]="ao.conceptRequirements" selectionMode="multiple" [paginator]="true"
                                [rows]="ao.view.pageSize" [(selection)]="ao.selectedRequirements" dataKey="id"
                                class="w-100">
                                <ng-template pTemplate="header">
                                  <tr>
                                    <th style="width: 3em">
                                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th pSortableColumn="discoveryRequirementId.requirement">
                                      Requirement
                                      <!-- <p-sortIcon [field]="requirement"></p-sortIcon>-->
                                    </th>
                                    <th class="w-120px" pSortableColumn="discoveryRequirementId?.importanceId">
                                      Importance
                                      <!-- <p-sortIcon [field]="importance"></p-sortIcon>-->

                                      <p-sortIcon [field]="discoveryRequirementId?.importanceId?.title"></p-sortIcon>
                                    </th>
                                    <th class="w-200px"
                                      pSortableColumn="discoveryRequirementId?.requirementTypeItemCode.description">
                                      Requirement
                                      sub-type
                                      <!-- <p-sortIcon [field]="requirementTypeItemCode"></p-sortIcon>-->
                                      <p-sortIcon
                                        [field]="discoveryRequirementId?.requirementTypeItemCode?.description">
                                      </p-sortIcon>
                                    </th>
                                    <th class="w-180px" pSortableColumn="discoveryRequirementId?.keyplayerId.keyplayer">
                                      Keyplayer
                                      <!-- <p-sortIcon [field]="keyplayerId"></p-sortIcon>-->
                                      <p-sortIcon [field]="discoveryRequirementId?.keyplayerId?.keyplayer"></p-sortIcon>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th class="p-a-0">
                                      <!--                                              <i class="fa fa-close" (click)="ao.importance=null;dt.filter(null, 'importance', 'equals')"
                                      style="cursor:pointer" *ngIf="ao.importance"></i>
                                    <mat-slider class="w-40px ib-m" min="0" max="10" step="1" value="0"
                                      thumbLabel="true" formControlName="importanceChange" (change)="ao.onImportanceChange($event, dt)"></mat-slider>-->
                                      <!-- <div class="ib-m w-20px m-l-5 t-r"> &gt;={{(re.importance)? re.importance : 0}}</div> -->
                                      <p-multiSelect [options]="importances" [style]="{'width':'100%'}"
                                        defaultLabel="All "
                                        (onChange)="dt.filter($event.value, 'discoveryRequirementId.importanceId.title','in')">
                                      </p-multiSelect>

                                    </th>
                                    <th>
                                      <p-multiSelect [options]="ao.requirementTypeItemCodes" [style]="{'width':'100%'}"
                                        defaultLabel="All Requirement Sub-type"
                                        (onChange)="dt.filter($event.value, 'discoveryRequirementId.requirementTypeItemCode.description', 'in')">
                                        <ng-template let-rtc let-i="index" pTemplate="item">
                                          <span>{{rtc.requirementTypeCode.label}} - {{rtc.label}} </span>
                                        </ng-template>
                                      </p-multiSelect>

                                    </th>
                                    <th>
                                      <p-multiSelect [options]="ao.discoveryKeyplayers" [style]="{'width':'100%'}"
                                        defaultLabel="All Keyplayer"
                                        (onChange)="dt.filter($event.value, 'discoveryRequirementId.keyplayerId.keyplayer', 'in')">
                                      </p-multiSelect>
                                    </th>

                                  </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-row>
                                  <tr [pSelectableRow]="row">
                                    <td class="t-c">
                                      <p-tableCheckbox [value]="row"></p-tableCheckbox>
                                    </td>
                                    <td>{{row.discoveryRequirementId.requirement}}</td>
                                    <td>{{row.discoveryRequirementId.importanceId.title}}</td>
                                    <td>{{row.discoveryRequirementId.requirementTypeItemCode.description}}</td>
                                    <td>{{row.discoveryRequirementId.keyplayerId.keyplayer}}</td>
                                  </tr>
                                </ng-template>
                              </p-table>
                              <div class="t-c bar-T m-t-20 p-y-5">
                                <button type="button" (click)="ao.cancel()" mat-raised-button color="warn"
                                  class="curved m-x-5">Cancel</button>
                                <button type="submit" (click)="ao.save()" [disabled]="!ao.isValid()" mat-raised-button
                                  color="primary" class="curved m-x-5">Select</button>
                              </div>
                            </div>

                          </mat-tab>
                        </mat-tab-group>
                      </mat-card-content>
                    </form>
                  </mat-card>
                </div>
              </div>
            </div>

            <!-- HTML for feature,testing and failure -->

            <div class="row" *ngIf="!ao.chooselist">

              <div class="col-sm-19" *ngIf="!ao.view.isForm()">
                <div class="workArea">
                  <mat-card class="popup">
                    <div class="popHeader d-flex align-items-center">
                      <span class="ib-m title">Risk Analysis</span>
                      <div class="spacer"></div>
                      <!-- <button mat-raised-button class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">SmartDev2m</span>
                      </button> -->
                      <button type="button" mat-icon-button class="close ib-m" (click)="rfp.cancel()" title="Close"
                        matTooltipPosition="below">
                        <mat-icon fontSet="i" class="i-close"></mat-icon>
                      </button>
                    </div>
                    <mat-card-content class="popContent">
                      <div class="rel">
                        <form [formGroup]="rfp.view.form" novalidate>
                          <div class="infoBar" *ngIf="rfp.view.isNew()">
                            <div class="m-b-15 d-flex">
                              <div class="ib-m">
                                <label class="fw"><small>Keyplayer Type</small></label>
                                <p-dropdown [options]="rf.discoveryKeyplayers" (onChange)="rf.filterKeyplayer($event)"
                                  formControlName="filterKeyplayer" placeholder="Keyplayer" optionLabel="label">
                                </p-dropdown>
                              </div>
                              <div class="spacer"></div>
                              <div class="ib-m">
                                <label class="fw"><small>Requirement Type</small></label>
                                <p-dropdown [options]="rf.requirementTypeItemCodes"
                                  (onChange)="rf.filterRequirementType($event)" formControlName="filterRequirementType"
                                  placeholder="Requirement Type">
                                  <ng-template let-rfc let-i="index" pTemplate="item">
                                    <span>{{rfc.requirementTypeCode?.label}} - {{rfc.label}} </span>
                                  </ng-template>
                                </p-dropdown>
                              </div>
                            </div>
                            <div class="fw">
                              <label class="fw"><small>Requirements</small></label>
                              <!--  <pre>{{rf.requirements | json}}</pre>-->
                              <p-dropdown [options]="rf.requirements" [filter]="true" [style]="{'width':'100%'}"
                                formControlName="analysisRequirementId" placeholder="Requirements"
                                optionLabel="requirement">
                                <!-- <ng-template let-creq pTemplate="item"   >
                              <div  class="ib-m">{{creq.label}} </div>
                            </ng-template>-->
                              </p-dropdown>
                              <small class="mat-error"
                                *ngIf="rf.view.invalid('analysisRequirementId')">{{rf.view.error('analysisRequirementId')}}</small>
                            </div>
                          </div>
                          <div class="m-b-15 d-flex">
                            <div class="ib-m">
                              <label class="fw"><small>Keyplayer Type</small></label>
                              <div class="o-80">{{rfp.view.value('analysisRequirementId').keyplayerId.label}}</div>
                            </div>
                            <div class="spacer"></div>
                            <div class="ib-m">
                              <label class="fw"><small>Requirement Type</small></label>
                              <!-- <div class="o-80 ib-m m-r-10">Behavioural and visceral</div> -->
                              <mat-chip-list class="ib-m">
                                <mat-chip color="primary" disabled selected></mat-chip>
                              </mat-chip-list>
                            </div>
                          </div>
                          <div class="m-b-15 fw">
                            <label class="fw"><small>Requirement</small></label>
                            <div class="scrollY max h-120px b-b-2 dotted p-y-5"></div>
                          </div>
                        </form>
                        <mat-tab-group class="dataTable m-x--20">
                          the tab for failures
                          <mat-tab label="Failures">

                            <form [formGroup]="fa.view.form" novalidate>
                              <div class="p-a-20">
                                <button type="button" mat-raised-button color="primary" class="curved m-x-5"
                                  (click)="ao.choosenew()">Choose Failure</button>
                                <mat-form-field class="m-b-15 fw">
                                  <input matInput placeholder="Title">
                                  <mat-error *ngIf="re.view.invalid('requirement')">{{re.view.error('requirement')}}
                                  </mat-error>
                                </mat-form-field>
                                <mat-form-field class="fw m-b-15">
                                  <mat-label>Description</mat-label>
                                  <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                                  <mat-error *ngIf="re.view.invalid('rationalisation')">
                                    {{re.view.error('rationalisation')}}</mat-error>
                                </mat-form-field>
                                <div class="t-c bar-T m-t-20 p-y-5">
                                  <button type="button" (click)="re.cancel()" mat-raised-button color="warn"
                                    class="curved m-x-5">Cancel</button>
                                  <button type="submit" (click)="re.save()" [disabled]="re.view.invalid()"
                                    mat-raised-button color="primary" class="curved m-x-5">Save</button>
                                  <button type="submit" (click)="re.saveAndAdd()" [disabled]="re.view.invalid() "
                                    mat-raised-button color="primary" class="curved m-x-5">Save & Add</button>
                                  <button type="submit" (click)="re.save()" [disabled]="re.view.invalid()"
                                    mat-raised-button color="primary" class="curved m-x-5">Update</button>
                                </div>
                              </div>
                            </form>
                          </mat-tab>
                          <mat-tab label="Features">
                            <form [formGroup]="fe.view.form" novalidate>
                              <div class="p-a-20">
                                <button type="button" mat-raised-button color="primary" class="curved m-x-5"
                                  (click)="ao.choosenew()">Choose Feature</button>
                                <mat-form-field class="m-b-15 fw">
                                  <input matInput placeholder="Title">
                                  <mat-error *ngIf="re.view.invalid('requirement')">{{re.view.error('requirement')}}
                                  </mat-error>
                                </mat-form-field>
                                <mat-form-field class="fw m-b-15">
                                  <mat-label>Description</mat-label>
                                  <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                                  <mat-error *ngIf="re.view.invalid('rationalisation')">
                                    {{re.view.error('rationalisation')}}</mat-error>
                                </mat-form-field>
                                <div class="t-c bar-T m-t-20 p-y-5">
                                  <button type="button" (click)="re.cancel()" mat-raised-button color="warn"
                                    class="curved m-x-5">Cancel</button>
                                  <button type="submit" (click)="re.save()" [disabled]="re.view.invalid()"
                                    mat-raised-button color="primary" class="curved m-x-5">Save</button>
                                  <button type="submit" (click)="re.saveAndAdd()" [disabled]="re.view.invalid() "
                                    mat-raised-button color="primary" class="curved m-x-5">Save & Add</button>
                                  <button type="submit" (click)="re.save()" [disabled]="re.view.invalid()"
                                    mat-raised-button color="primary" class="curved m-x-5">Update</button>
                                </div>
                              </div>
                            </form>
                          </mat-tab>
                          <mat-tab label="Testing">
                            <form [formGroup]="tv.view.form" novalidate>
                              <div class="p-a-20">
                                <button type="button" mat-raised-button color="primary" class="curved m-x-5"
                                  (click)="ao.choosenew()">Choose
                                  Testing</button>
                                <mat-form-field class="m-b-15 fw">
                                  <input matInput placeholder="Title">
                                  <mat-error *ngIf="re.view.invalid('requirement')">{{re.view.error('requirement')}}
                                  </mat-error>
                                </mat-form-field>
                                <mat-form-field class="fw m-b-15">
                                  <mat-label>Description</mat-label>
                                  <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                                  <mat-error *ngIf="re.view.invalid('rationalisation')">
                                    {{re.view.error('rationalisation')}}</mat-error>
                                </mat-form-field>
                                <div class="t-c bar-T m-t-20 p-y-5">
                                  <button type="button" (click)="re.cancel()" mat-raised-button color="warn"
                                    class="curved m-x-5">Cancel</button>
                                  <button type="submit" (click)="re.save()" [disabled]="re.view.invalid()"
                                    mat-raised-button color="primary" class="curved m-x-5">Save</button>
                                  <button type="submit" (click)="re.saveAndAdd()" [disabled]="re.view.invalid() "
                                    mat-raised-button color="primary" class="curved m-x-5">Save & Add</button>
                                  <button type="submit" (click)="re.save()" [disabled]="re.view.invalid()"
                                    mat-raised-button color="primary" class="curved m-x-5">Update</button>
                                </div>
                              </div>
                            </form>
                          </mat-tab>
                        </mat-tab-group>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
          <!-- end of testing,feature and failure-->

          <!--for showing the lists-->

          <div class="rel dataTable m-x--20" *ngIf="ao.chooselist">
            <p-table #dt [value]="ao.conceptRequirements" selectionMode="multiple" [paginator]="true"
              [rows]="ao.view.pageSize" [(selection)]="ao.selectedRequirements" dataKey="id" class="w-100">
              <ng-template pTemplate="caption">
                <div style="text-align: right">
                  <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                  <input type="text" pInputText size="50" placeholder="Search" style="width:auto">
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3em">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th pSortableColumn="discoveryRequirementId.requirement">
                    Title
                    <!-- <p-sortIcon [field]="requirement"></p-sortIcon>-->
                  </th>
                  <th pSortableColumn="discoveryRequirementId.requirement">
                    Description
                    <!-- <p-sortIcon .[field]="importance"></p-sortIcon>-->
                  </th>

                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <!-- <th class="p-a-0">
                      <i class="fa fa-close" (click)="ao.importance=null;dt.filter(null, 'importance', 'equals')"
                          style="cursor:pointer" *ngIf="ao.importance"></i>
                  <mat-slider class="w-40px ib-m" min="0" max="10" step="1" value="0" thumbLabel="true"
                              formControlName="importanceChange" (change)="ao.onImportanceChange($event, dt)"></mat-slider>
                    <div class="ib-m w-20px m-l-5 t-r"> &gt;={{(re.importance)? re.importance : 0}}</div> 
                  </th>-->
                  <th>
                    <!-- <p-multiSelect [options]="ao.requirementTypeItemCodes" [style]="{'width':'100%'}"
                    defaultLabel="All Requirement Sub-type" (onChange)="dt.filter($event.value, 'requirementTypeItemCode.description', 'in')">
                    <ng-template let-rtc let-i="index" pTemplate="item">
                      <span >{{rtc.requirementTypeCode.label}} - {{rtc.label}} </span>
                    </ng-template>
                  </p-multiSelect>-->

                  </th>
                  <th>
                    <!-- <p-multiSelect [options]="ao.discoveryKeyplayers" [style]="{'width':'100%'}" defaultLabel="All Keyplayer" (onChange)="dt.filter($event.value, 'keyplayerId.keyplayer', 'in')"></p-multiSelect>-->
                  </th>

                </tr>
              </ng-template>
              <!-- <ng-template pTemplate="body" let-row>
                <tr [pSelectableRow]="row">
                  <td class="t-c">
                <p-tableCheckbox [value]="row"></p-tableCheckbox>
                </td>
                <td>{{row.discoveryRequirementId.requirement}}</td>
                <td>{{row.discoveryRequirementId.importance}}</td>
                <td>Title of feature,failure/testing</td>
                <td>Title of feature,failure/testing</td>
                </tr>
              </ng-template>-->
              <ng-template pTemplate="body">
                <tr>
                  <td class="t-c">
                    <p-tableCheckbox></p-tableCheckbox>
                  </td>
                  <!-- <td>{{row.discoveryRequirementId.requirement}}</td>
                  <td>{{row.discoveryRequirementId.importance}}</td>-->
                  <td>{{rfp.view.value('conceptRequirementFeatureId').title}}</td>

                </tr>
              </ng-template>
            </p-table>

            <div class="t-c bar-T m-t-20 p-y-5">
              <button type="button" (click)="ao.cancel()" mat-raised-button color="warn"
                class="curved m-x-5">Cancel</button>
              <button type="submit" (click)="ao.save()" [disabled]="!ao.isValid()" mat-raised-button color="primary"
                class="curved m-x-5">Select</button>
            </div>
          </div>

          <!-- Added to show requirement -->
          <div class="row" *ngIf="re.view.isForm()">
            <div class="col-sm-5">
              <app-tree-preview [data]="re.view" title="Requirement"></app-tree-preview>
            </div>
            <div class="col-sm-19">
              <div class="workArea">
                <mat-card class="popup">
                  <form [formGroup]="re.view.form" novalidate>
                    <div class="popHeader d-flex align-items-center">
                      <span class="ib-m title">Requirement</span>
                      <div class="spacer"></div>
                      <!-- <button mat-raised-button class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">SmartDev2m</span>
                      </button> -->
                      <button type="button" mat-icon-button class="close ib-m" (click)="re.cancel()" title="Close"
                        matTooltipPosition="below">
                        <mat-icon fontSet="i" class="i-close"></mat-icon>
                      </button>
                    </div>
                    <mat-card-content class="popContent">
                      <div class="p-a-20">
                        <div class="m-b-15 fw">
                          <label class="d-block"><small>Importance </small></label>
                          <label
                            class="d-block scrollY max h-120px b-b-2 dotted p-y-5">{{re.view.value('importanceId').title}}
                          </label>
                        </div>

                        <div class="m-b-15 fw">
                          <label class="d-block"><small>Requirement </small></label>
                          <label class="d-block scrollY max h-120px b-b-2 dotted p-y-5">{{re.view.value('requirement')}}
                          </label>
                        </div>
                        <div class="m-b-15 fw">
                          <label class="d-block"><small>Rationalisation </small></label>
                          <label
                            class="d-block scrollY max h-120px b-b-2 dotted p-y-5">{{re.view.value('rationalisation')}}
                          </label>
                        </div>

                        <div class="m-b-15 fw">
                          <label class="d-block"><small>Note </small></label>
                          <label class="d-block scrollY max h-120px b-b-2 dotted p-y-5">{{re.view.value('note')}}
                          </label>
                        </div>

                        <div class="t-c bar-T m-t-20 p-y-5">
                          <button type="button" (click)="re.cancel()" mat-raised-button color="warn"
                            class="curved m-x-5">Cancel</button>
                        </div>
                      </div>
                    </mat-card-content>
                  </form>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Messages" [disabled]="true">
        <div class="conArea">
          Under Construction...
        </div>
      </mat-tab>
      <mat-tab label="Files" [disabled]="true">
        <div class="conArea">
          Under Construction...
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>
  <div class="wrapper" [ngClass]="ai.display ? 'active' : 'hidden'">
    <app-ai [ai]="ai" class="w-100"></app-ai>
  </div>
</div>