import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tree-preview',
  templateUrl: './tree-preview.component.html',
  styleUrls: ['./tree-preview.component.scss']
})
export class TreePreviewComponent implements OnInit {

  @Input() data: any;
  @Input() title: string;

  constructor() { }

  ngOnInit() {
  //  console.log(this.title);
  }

}
