import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { View, Config, FormDefault } from '../../config';
import { SharedService } from './shared.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';

@Injectable({
  providedIn: 'root'
})
export class RequirementFeatureView {

  url = 'conceptrequirementfeature';
  // observations;
  public view: View;
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    public ss: SharedService) {
    this.view = new View(http, router, route, alertService);
    this.ss.setRequirementFeatureView(this);
  }

  setForm() {
    this.view.initForm(this.url, this.getForm(), this.getNewDefault(), false);
    this.newform();
    this.view.state = 'list';
    this.view.refreshList = false;
    Config.isProjectSelected = true;
    this.discoveryKeyplayers = null;
    this.initData();
  }

  getNewDefault() {
    return new FormDefault(
      ['conceptRequirementId'],
      [
        this.ss.requirement
      ]);
  }

  newform() {
    this.view.newform();
  }

  save() {
    this.view.set('label', this.view.value('title'));
    this.view.save();
  }

  edit(row) {
    this.view.edit(row);
  }

  saveAndAdd() {
    this.view.set('label', this.view.value('title'));
    this.view.saveAndAdd();
  }

  cancel() {
    this.getList();
    this.ss.getRequirementView().getList();
    this.view.cancel();
  }

  getList() {
    this.view.get(`${this.url}/all/${this.ss.requirement.id}`).subscribe(res => {
      this.view.list = res;
    });
  }

  getForm() {
    return this.fb.group({
      id: [''],
      label: [''],
      title: ['', Validators.required],
      description: [''],
      conceptRequirementId: ['', Validators.required],
      filterRequirementType: [''], //dummy
      filterKeyplayer: [''] //dummy
    });
  }

  discoveryKeyplayers;
  requirementTypeItemCodes;
  requirements;
  requirementsAll;
  private initData() {
    this.view.get(`conceptrequirement/concept/${this.ss.concept.id}`).subscribe(res => {
      this.requirements = res;
      this.requirementsAll = res;
    });

    if (this.discoveryKeyplayers === null) {
      this.view.get(`discoverykeyplayer/concept/${this.ss.getProjectId()}/${this.ss.concept.id}`).subscribe(res => {
        this.discoveryKeyplayers = res;
      });
      this.view.get(`requirementtypeitem/all`).subscribe(res => {
        //console.log(res );
        this.requirementTypeItemCodes = res;
        for (let x = 0; x < this.requirementTypeItemCodes.length; x++) {
          let item = this.requirementTypeItemCodes[x];
          item['value'] = item['id'];;
          item['label'] = item['requirementTypeCode'].label + ' ' + item['label'];
        }
      });
    }
  }
  filterRT(event) {
    // console.log(event);
    this.requirements = this.requirementsAll.filter(u => u.discoveryRequirementId.requirementTypeItemCode.id === event.value);
    let selKp = this.view.value('filterKeyplayer', null);
    if (selKp !== null) {
      this.requirements = this.requirements.filter(u => u.discoveryRequirementId.keyplayerId.id === selKp.id);
    }
  }
  filterKeyplayer(event) {
    this.requirements = this.requirementsAll.filter(u => u.discoveryRequirementId.keyplayerId.id === event.value.id);
    let selRt = this.view.value('filterRequirementType', null);
    if (selRt !== null) {
      this.requirements = this.requirements.filter(u => u.discoveryRequirementId.requirementTypeItemCode.id === selRt.id);
    }
  }

}
