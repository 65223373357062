import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { View, Config, FormDefault } from '../../config';
import { Requirement } from '../../_models/requirement';
import { SharedService } from './shared.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';
import { SharedProjectService } from '../project/sharedproject.service';
@Injectable({
  providedIn: 'root'
})
export class RequirementTestingView {
  url = 'discoveryrequirementtesting';

  public view: View;
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    public ss: SharedService,
    private ssp:SharedProjectService) {
    this.view = new View(http, router, route, alertService);
    this.ss.setRequirementTestingView(this);
    this.view.refreshList = false;
    this.setForm();
  }

  getNewDefault() {
    if (this.ss.re === null) {
      return null;
    }
    return new FormDefault(
      ['discoveryRequirementId'], [this.ss.requirement]);
  }

  setForm() {
    this.view.initForm(this.url, this.getForm(), this.getNewDefault(), false);
    Config.isProjectSelected = true;
    this.view.state = 'list';

  }

  newform() {
    this.initData();
    this.view.newform();
  }

  edit(row) {
    this.view.edit(row);
    this.initData();
    // if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'A') {
    // }
  }

  save() {
    //console.log(this.view.form.value);
    this.view.set('label', this.view.value('strategy'));
    this.view.saveCallback(() => {
      this.ss.getRequirementView().getList();
      this.view.state = 'edit';
    });
  }

  saveAndAdd() {
    this.view.set('label', this.view.value('strategy'));
    this.view.saveAndAddCallback(() => {
      this.ss.getRequirementView().getList();
    });
    // this.view.saveAndAdd();
  }

  cancel() {
    this.getList();
    this.view.cancel();
  }

  getList() {
    this.view.get(`${this.url}/all/${this.ss.requirement.id}`).subscribe(res => {
      this.view.list = res;
    });
  }

  getForm() {
    return this.fb.group({
      id: [''],
      label: [''],
      strategy: ['', Validators.required],
      discoveryRequirementId: [''],
      filterKeyplayer: [''], //Dummy
      filterRequirementType: [''] //Dummy
    });
  }

  discoveryKeyplayers;
  requirementTypeItemCodes;
  requirements;
  requirementsAll;
  private initData() {
    this.discoveryKeyplayers = this.ss.kp.view.list;
    this.view.get(`discoveryrequirement/all/${this.ssp.getProjectId()}`).subscribe(res => {
      this.requirements = res;
      this.requirementsAll = res;
      for (let x = 0; x < this.requirementsAll.length; x++) {
        let item = this.requirementsAll[x];
        item['value'] = item['id'];
        item['label'] = item['requirement'];
      }
    });

    //  if (this.discoveryKeyplayers === null) { //TODO can be in a cache
    this.view.get(`requirementtypeitem/all`).subscribe(res => {
      this.requirementTypeItemCodes = res;
      for (let x = 0; x < this.requirementTypeItemCodes.length; x++) {
        let item = this.requirementTypeItemCodes[x];
        item['value'] = item['id'];;
        item['label'] = item['requirementTypeCode'].label + ' ' + item['label'];
      }
    });
    // }
  }
  filterRT(event) {
    // console.log(event);
        this.requirements = this.requirementsAll.filter(u => u.requirementTypeItemCode.id === event.value);
        let selKp = this.view.value('filterKeyplayer', null);
        if (selKp !== null) {
          this.requirements = this.requirements.filter(u => u.keyplayerId.id === selKp.id);
        }
  }
  filterKeyplayer(event) {
        this.requirements = this.requirementsAll.filter(u => u.keyplayerId.id === event.value.id);
        let selRt = this.view.value('filterRequirementType', null);
        if (selRt !== null) {
          this.requirements = this.requirements.filter(u => u.requirementTypeItemCode.id === selRt.id);
        }
  }


}
