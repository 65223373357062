<div class="container-fluid d-flex h-100">
  <div class="wrapper">
    <div class="rightSection mainWrap noSidebar">
      <!-- Cancellation Form -->
      <div class="row" *ngIf="!norefund&&!showStatus">
        <div class="col-24">
          <h2 class="pageHeading">InsIQ Subscription Cancellation Form</h2>

          <div class="t-c m-b-30">
            <h5 class="text-danger f-medium">We're sorry to see you go!</h5>
            <p>
              Before you cancel, please take 1 minute to fill out this form. Your feedback will help us improve our
              services.
            </p>

            <hr class="m-b-20">
          </div>

          <form>
            <h6 class="f-medium">1. Account Information:</h6>
            <div class="col-xl-12 col-lg-12">
              <!-- <figure class="avatar circle">
                <img src="assets/images/users/avatar.png" [attr.alt]="view.value('name')"
                  [attr.title]="view.value('name')">
              </figure> -->
              <label for="name" class="d-block m-b-5">Name</label>
              <mat-form-field class="w-100 m-b-10 fw flat" floatLabel="never" appearance="fill">
                <input type="text" matInput placeholder="Name" value="{{currentUserData?.name}}" readonly="true">
              </mat-form-field>

              <label for="email" class="d-block m-b-5">Email</label>
              <mat-form-field class="w-100 m-b-10 fw flat" floatLabel="never" appearance="fill">
                <input type="text" matInput placeholder="Email" value="{{currentUserData?.email}}" readonly="true">
              </mat-form-field>
            </div>

            <h6 class="f-medium">2. Subscription Details:</h6>
            <div>
              <label for="subscription">Current Plan:</label>

              <div class="col-xl-12 col-lg-12 m-y-20" *ngIf="(currentUserData?.currentPlan)&&(currentUsr?.pin!='USR')">
                <div class="card fieldsetCard planCard">
                  <div class="cardHeader">
                    <div class="title">{{currentUserData?.currentPlan}}</div>
                  </div>
                  <div class="cardContent">
                    <div class="col">
                      <div class="validTill">
                        <div *ngIf="config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'">Your InsIQ Plan Expired
                        </div>
                        <div *ngIf="currentUsr?.usertype=='APPSUMO'">Lifetime Validity</div>
                        <div *ngIf="currentUsr?.usertype!=='APPSUMO'">
                          <div>Subscription Valid till: </div>
                          <div class="date">{{(currentUserData?.validTill)?.split(' ')[0]}}</div>
                        </div>
                      </div>

                      <div class="message _warning" *ngIf="!config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'">
                        <!-- <i class="i i-warning"></i> -->
                        <span *ngIf="config?.currentUser?.daysRemaining>=0">Expires in
                          {{currentUserData?.daysRemaining}} Days</span>
                        <span *ngIf="config?.currentUser?.daysRemaining<0">Account Expired</span>
                      </div>
                      <div class="message _warning" *ngIf="config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'">
                        <i class="i i-warning"></i>
                        <span>Account will Terminate by {{currentUserData?.gp | date: 'dd/MM/yyyy'}}</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-xl-12 col-lg-12">
          <form [formGroup]="view.form" (ngSubmit)="submitCancel()">
            <div class="form-group m-y-20">
              <h6 class="f-medium">3. Reason for Cancellation (optional, please select all that apply):</h6>
              <div formGroupName="reason">
                <div *ngFor="let reason of cancellationReasons; index as i;">
                  <label>
                    <input type="checkbox" class="custom" [formControlName]="reason" id="reason_{{i}}" name="reason"
                      [value]="true">
                    <label for="reason_{{i}}">{{ reason }}</label>
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group m-y-20">
              <h6 class="f-medium">4. How would you evaluate your overall experience with us (optional)?</h6>
              <div class="rating m-b-20">
                <mat-button-toggle-group aria-label="Rating" formControlName="experience">
                  <mat-button-toggle value="5" color="primary">
                    <mat-icon>sentiment_very_satisfied</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="4" color="primary">
                    <mat-icon>sentiment_satisfied</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="3" color="primary">
                    <mat-icon>sentiment_neutral</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="2" color="primary">
                    <mat-icon>sentiment_dissatisfied</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="1" color="primary">
                    <mat-icon>sentiment_very_dissatisfied</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>

            <div class="form-group m-y-20">
              <h6 class="f-medium">5. Would you consider re-subscribing if we addressed your concerns (optional)?</h6>
              <div>
                <label>
                  <input type="radio" class="custom" formControlName="resub" id="resub_Yes" value="Yes">
                  <label for="resub_Yes">Yes</label>
                </label>
                <label>
                  <input type="radio" class="custom" formControlName="resub" id="resub_Maybe"
                    value="Maybe in the future">
                  <label for="resub_Maybe">Maybe in the future</label>
                </label>
                <label>
                  <input type="radio" class="custom" formControlName="resub" id="resub_No" value="No">
                  <label for="resub_No">No</label>
                </label>
              </div>
            </div>

            <div class="form-group m-y-20">
              <h6 class="f-medium">6. Would you like to be contacted by our support team to discuss your feedback
                (optional)?</h6>
              <div>
                <label>
                  <input type="radio" class="custom" formControlName="support" id="support_Yes" value="Yes">
                  <label for="support_Yes">Yes</label>
                </label>
                <label>
                  <input type="radio" class="custom" formControlName="support" id="support_No" value="No">
                  <label for="support_No">No</label>
                </label>
              </div>
            </div>

            <div class="form-group m-y-20">
              <h6 class="f-medium">7. Lastly, we'd love to hear any feedback. Do you have any suggestions or comments
                to
                help us improve (optional)?</h6>
              <div class="feedback-container fc-group">
                <textarea class="fc p-y-20" rows="4" formControlName="suggestions"></textarea>
              </div>
            </div>

            <div class="form-group m-y-20">
              <h6 class="f-medium">8. Confirmation:</h6>
              <div>
                <label>
                  <input type="checkbox" class="custom" formControlName="confirmationCheckbox" id="confirmationCheckbox"
                    required>
                  <label for="confirmationCheckbox">By submitting this form, I confirm that I wish to cancel my InsIQ
                    subscription. I understand that my current plan will be terminated. This means I will
                    lose access to my projects until I purchase a new plan, and my team members will no longer be able
                    to
                    log in</label>
                </label>
              </div>
            </div>

            <div class="m-t-20">
              <label for="date">{{ currentDate | date:'longDate' }}</label>
            </div>
            <div class="t-c m-t-20 action">
              <button type="submit" class="btn btn-primary btn-lg" matRipple
                [disabled]="!view.form.valid">Submit</button>
            </div>
          </form>
        </div>
      </div>

      <!-- Refund / Show Status Form -->
      <div class="row justify-content-center" *ngIf="norefund||showStatus">
        <div class="col-xl-24 col-lg-24 col-md-24">
          <h2 class="pageHeading">InsIQ Subscription Cancelled Successfully</h2>

          <div class="t-c m-b-30">
            <h5 class="text-danger f-medium">We're sorry to see you go!</h5>
            <p>
              Your Subscription has been successsfully cancelled.If you have any furthur concerns or queries
              regarding the cancellation, please feel free to reach out us at
              <a href="mailto:support@insiq.io" class="link underline">support@insiq.io</a>.
            </p>

            <hr class="m-b-20">
          </div>

          <div class="t-c m-b-30" *ngIf="norefund&&!showStatus">
            <button type="button" (click)="payNowHere()" class="btn btn-primary btn-lg">Proceed to InsIQ</button>
          </div>

          <!-- <mat-card class="mat-elevation-z8" *ngIf="waitflag">
            <mat-card-content>

              <div class="selectedPlan" matRipple>
                <div class="dtl">
                  <div class="title">Please Wait</div>
                  <div class="validity">Checkout in Progress.Do not press back button or refresh page.
                  </div>
                </div>
                <div class="action">
                  <mat-icon class="i-2x" color="primary">timer
                  </mat-icon>
                </div>
              </div>
            </mat-card-content>
          </mat-card> -->

        </div>
        <div class="col-xl-11 col-lg-10 col-md-12">
          <mat-card class="mat-elevation-z8 billPayment p-a-0" *ngIf="showStatus&&!norefund">
            <mat-card-header>Refund Transaction Details</mat-card-header>
            <mat-card-content class="p-a-30">
              <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                  <mat-label>Choose an option</mat-label>
                <mat-select>
                  <mat-option value="month" selected>Monthly Plan</mat-option>
                  <mat-option value="annual">Annual Plan</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="d-flex text-primary m-b-20 f-medium">
                <span>Switch to annual and save</span>
                <span class="m-l-auto f-bold">US$60.00/yr</span>
              </div> -->
              <div class="d-flex text-dark m-y-20 h5 flex-wrap">
                <span class="p-r-5">Transaction Id</span>
                <!-- <span class="m-l-auto f-black" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}} USD</span> -->
                <span class="m-l-auto f-medium">{{transactionLog?.paymentintentid.toUpperCase()}}</span>
              </div>

              <div class="d-flex text-dark m-y-20 h5">
                <span class="p-r-5">Refund Status</span>
                <!-- <span class="m-l-auto f-black" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}} USD</span> -->

                <span class="m-l-auto f-medium text-danger" [ngClass]="{
                  'text-danger': transactionLog?.description!='succeeded'?true:false,
                  'text-success': transactionLog?.description=='succeeded'?true:false
                }">{{transactionLog?.description}}</span>
              </div>

              <hr>
              <div class="d-flex text-dark m-y-20 h5">
                <span class="p-r-5">Refund Amount</span>
                <span class="m-l-auto f-medium">{{extractNumericValue(transactionLog?.amount)}} USD</span>
              </div>

              <hr>

              <div class="t-c p-t-20">
                <button type="button" (click)="payNowHere()" class="btn btn-primary btn-lg">Proceed to InsIQ</button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
