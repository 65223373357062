import {
  OnInit,
  NgZone,
  Inject,
  Component,
  OnDestroy
} from '@angular/core';

import {
  Router,
  NavigationEnd,
  NavigationStart
} from '@angular/router';

import {
  query,
  style,
  animate,
  stagger,
  trigger,
  keyframes,
  transition
} from '@angular/animations';

import {
  AlertService,
  AuthenticationService
} from './_services';

import {
  View,
  Config
} from './config/global.config';

import {
  HttpParams,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { CustomThemeService } from './custom-theme.service';
import { SnackbarComponent } from './_helpers/snackbar/snackbar.component';
import { SharedProjectService } from './components/project/sharedproject.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  viewProviders: [Config],
  animations: [
    trigger('zoomIn', [
      transition('* => *', [
        query(':self', stagger('300ms', [
          animate('1.5s ease-out', keyframes([
            style({ transform: 'scale3d(0, 0, 0)', offset: 0 }),
            style({ transform: 'scale3d(1, 1, 1)', offset: 1 })
          ]))]), { optional: true })
      ])
    ]),
    trigger('flyFromLeft', [
      transition('* => *', [
        query(':self', stagger('300ms', [
          animate('1.5s ease-out', keyframes([
            style({ transform: 'translateX(-100%)', opacity: 0, offset: 0 }),
            style({ transform: 'translateX(0%)', opacity: 1, offset: 1 })
          ]))]), { optional: true })
      ])
    ]),
    trigger('flyFromTop', [
      transition('* => *', [
        query(':self', stagger('300ms', [
          animate('1.5s ease-out', keyframes([
            style({ opacity: 0, transform: 'translateY(-100%)', offset: 0 }),
            style({ opacity: 1, transform: 'translateY(0%)', offset: 1 })
          ]))]), { optional: true })
      ])
    ]),
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-100%)' }),
            stagger(
              '500ms',
              animate(
                '1s ease-out',
                style({ opacity: 1, transform: 'translateY(0%)' })
              )
            )
          ],
          { optional: true }
        ),
        // query(':leave', animate('500ms', style({ opacity: 0 })), {
        //   optional: true
        // })
      ])
    ]),
    trigger('listStaggerXS', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-100%)' }),
            stagger(
              '2000ms',
              animate(
                '1s ease-out',
                style({ opacity: 1, transform: 'translateY(0%)' })
              )
            )
          ],
          { optional: true }
        ),
        // query(':leave', animate('2000ms', style({ opacity: 0 })), {
        //   optional: true
        // })
      ])
    ]),
    trigger('fadeIn', [
      transition('* => *', [
        query(':self', stagger('300ms', [
          animate('1.5s ease-in', keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 1, offset: 1 })
          ]))]), { optional: true })
      ])
    ])
  ]
  // providers: [{
  //   provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  // }]
})
export class AppComponent implements OnInit, OnDestroy {
  data: any;
  progress = 0;
  title = 'InsIQ';
  planning: boolean;
  platformName = '';
  appSupport = true;
  browserVersion = '';
  timer: NodeJS.Timeout;
  isUserLoggedIn = false;
  animateState = 'inactive';
  pagesWithoutChat = ['/login', '/register'];

  animoStats = {
    idea: false,
    aiInsights: false,
    whatToDo: false,

    haveIdea: false,
    addIdea: false,
    evaluate: false,
    ai: false,
    aiInsight: false,

    discovery: false,
    concept: false,
    risk: false,
    detailedDesign: false,
    virtualTesting: false,
    prototype: false,
    product: false,
    feedback: false
  };

  constructor(
    public view: View,
    public zone: NgZone,
    public router: Router,
    public config: Config,
    private http: HttpClient,
    public platform: Platform,
    iconRegistry: MatIconRegistry,
    private _snackBar: MatSnackBar,
    public ssp: SharedProjectService,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: Document,
    public customThemeService: CustomThemeService,
    private authenticationService: AuthenticationService
  ) {
    // iconRegistry.registerFontClassAlias('mat-icon', 'i');
    iconRegistry.setDefaultFontSetClass('i');
    this.planning = true;

    // if (navigator.appVersion.indexOf('Win') !== -1) {
    //   // console.log('Windows');
    //   document.querySelector('meta[name=viewport]')
    //     .setAttribute(
    //       'content',
    //       `width=device-width, initial-scale=${1 / window.devicePixelRatio}`
    //     );
    // }

    this.data = {
      evaluation: {
        idea: [
          {
            icon: 'shopping_basket',
            text: 'Enter your product category',
            help: null
          },
          {
            icon: 'idea',
            text: 'Describe your Product/Idea',
            help: null
          },
          {
            icon: 'description',
            text: 'Tell us more about your Product/Idea',
            help: null
          },
          {
            icon: 'store_mall_directory',
            text: 'Preferred region where your Product could be sold',
            help: null
          },
          {
            icon: 'person_outline',
            text: 'Who is your Customer?',
            help: null
          },
          {
            icon: 'title',
            text: 'What would you like to name your product?',
            help: null
          }
        ],
        aiInsights: [
          {
            icon: 'ai',
            text: 'Similar Products or Projects',
            highlight: 'visibility',
            help: null
          },
          {
            icon: 'ai',
            text: 'Trend,News ,General Info',
            help: null,
            highlight: 'visibility'
          },
          {
            icon: 'ai',
            text: 'Patents and Trademarks',
            help: null,
            highlight: 'visibility'
          },
          {
            icon: 'ai',
            text: 'Suppliers and Manufacturers',
            help: null,
            highlight: 'visibility'
          },
          {
            icon: 'ai',
            text: 'Product Reviews',
            help: null,
            highlight: 'visibility'
          },
          {
            icon: 'ai',
            text: 'Regulatory and Compliance',
            help: null,
            highlight: 'visibility'
          },
          {
            icon: 'ai',
            text: 'Key Influencers, Professionals',
            help: null,
            highlight: 'visibility'
          }
        ],
        whatToDo: [
          {
            icon: 'ai',
            text: 'Review each AI insights data',
            help: null,
            highlight: 'visibility'
          },
          {
            icon: 'ai',
            text: 'Mark revelant one by clicking Favorite',
            help: null,
            highlight: 'visibility'
          },
          {
            icon: 'ai',
            text: 'Perform Deep Dive on each Insights',
            help: null,
            highlight: 'visibility'
          },
          {
            icon: 'ai',
            text: 'Record your thoughts on each revelant AI data as a note to include in report as well as for future reference',
            help: null,
            highlight: 'visibility'
          },
          {
            icon: 'ai',
            text: 'Download the final Executive Report from InsIQ',
            help: null,
            highlight: 'visibility'
          }
        ]
      },
      evaluate: 'Evaluate Your Product Idea',
      aiInsight: [
        'Similar Products',
        'Patents',
        'Consumers',
        'Influencers',
        'Contributors'
      ],
      planning: {
        discovery: [
          {
            icon: 'stakeholder',
            text: 'Identify & Add KeyPlayers of your Product',
            help: {
              layout: 'tipWhatIsKP'
            }
          },
          {
            icon: 'interview',
            text: 'Add Insights/Needs of Key players about your product',
            help: null
          },
          {
            highlight: 'AI',
            icon: 'requirement',
            text: 'Transform those Insights into product Requirements',
            help: null
          },
          {
            highlight: 'AI',
            icon: 'testing',
            text: 'Add Test methods for testing requirements',
            help: null
          }
        ],
        concept: [
          {
            icon: 'concept',
            text: 'Create a Concept & add requirements you discovered',
            help: null
          },
          {
            icon: 'features',
            text: 'Identify & Add Features that can fulfil the requirements',
            help: null
          },
          {
            icon: 'process',
            text: 'Identify & Add Processes to create the features',
            help: null
          }
        ]
      },
      execution: {
        risk: [
          {
            icon: 'design',
            text: 'Select a Concept that you want to consider for Risk',
            help: null
          },
          {
            icon: 'requirement',
            text: 'Identify How design can fail to meet the requirement',
            help: null
          },
          {
            icon: 'sms_failed',
            text: 'Improve & Add Test methods for testing these failures',
            help: null
          },
          {
            icon: 'features',
            text: 'Improve & Add Features that could prevent these failures',
            help: null
          },
          {
            icon: 'process',
            text: 'Identify How each process can fail to meet the design',
            help: null
          },
          {
            icon: 'testing',
            text: 'Improve & Add Test methods for testing these failures',
            help: null
          },
          {
            icon: 'process',
            text: 'Improve & Add Processes that could prevent these failures',
            help: null
          }
        ],
        detailedDesign: [
          {
            icon: 'design',
            text: 'Choose Design Concept',
            help: null
          },
          {
            icon: 'settings_input_composite',
            text: 'Add Components required for each feature',
            help: null
          },
          {
            icon: 'build',
            text: 'Add Tools required to do each process',
            help: null
          }
        ],
        virtualTesting: [
          {
            icon: 'virtualTesting',
            text: 'Assign & Perform Virtual Tests identified during design risk analysis.',
            help: null
          },
          {
            icon: 'flag',
            text: 'Record Test Result & Flag <span class="text-accent">Features</span> that need to be reviewed.',
            help: null
          },
          {
            icon: 'virtualTesting',
            text: 'Assign & Perform Virtual Tests identified during process risk analysis.',
            help: null
          },
          {
            icon: 'flag',
            text: 'Record Test Result & Flag <span class="text-accent">Processes</span> that need to be reviewed.',
            help: null
          }
        ],
        prototype: [
          {
            icon: 'prototype',
            text: 'Create product Prototype',
            help: null
          },
          {
            icon: 'design',
            text: 'Choose design requirements for the prototype',
            help: null
          },
          {
            icon: 'testing',
            text: 'Assign & Perform Test Methods defined during risk analysis',
            help: null
          },
          {
            icon: 'flag',
            text: 'Record Test Result & Flag Features/Processes that need to be reviewed.',
            help: null
          }
        ],
        product: [
          {
            icon: 'prototype',
            text: 'Create product Prototype',
            help: null
          },
          {
            icon: 'product-plus',
            text: 'Create a Product Build by selecting design requirements',
            help: null
          },
          {
            icon: 'monetization_on',
            text: 'System generate BOM and Cost details',
            help: null
          }
        ],
      },
      review: {
        feedback: [
          {
            icon: 'feedback',
            text: 'Feedback about the product',
            help: null
          }
        ]
      }
    };

    router.events.subscribe(event => {
      this.isUserLoggedIn = this.authenticationService.isUserLoggedIn();
      if (event instanceof NavigationStart) {
        // console.log(event);
        if (!(this.pagesWithoutChat.indexOf(event.url) > -1 || !(this.isUserLoggedIn))) {
          this.config.start();
        }
      }
      if (event instanceof NavigationEnd) {
        // console.log('end', event);
        this.config.end();
        this.config.dev2MOverview = false;
        this.resetAnimation();

        let isChatDisabled = this.pagesWithoutChat.indexOf(event.url) > -1 || !(this.isUserLoggedIn);

        (function () {
          function onTidioChatApiReady() {
            // Code after chat loaded
            // console.log({ isChatDisabled });
            window['tidioChatApi'].display(!isChatDisabled);
          }
          if (window['tidioChatApi']) {
            window['tidioChatApi'].on("ready", onTidioChatApiReady);
          } else {
            document.addEventListener("tidioChat-ready", onTidioChatApiReady);
          }
        })();
      }

      // NavigationStart
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 5 * 1000,
    });
  }

  toggleOverview() {
    this.config.dev2MOverview = !this.config.dev2MOverview;
    this.planning = true;
    if (!this.config.dev2MOverview) {
      // this.resetAnimation();
    }
  }

  resetAnimation() {
    this.animoStats = {
      addIdea: true,
      idea: false,
      ai: false,
      aiInsights: false,
      whatToDo: false,

      haveIdea: true,
      evaluate: false,
      aiInsight: false,

      discovery: false,
      concept: false,
      risk: false,
      detailedDesign: false,
      virtualTesting: false,
      prototype: false,
      product: false,
      feedback: false
    };
  }
  testClick() {
    localStorage.setItem('upgradeFlag', 'false');

    this.router.navigate(['/subscription']);
  }
  nextAnimation(event: any, s: string) {
    console.warn(`Animate ${s} is ${event.phaseName}`);
    if (event.phaseName === 'done') {
      switch (s) {
        case 'addIdea':
          this.animoStats.addIdea = true;
          break;
        case 'idea':
          this.animoStats.idea = true;
          break;
        case 'aiInsights':
          this.animoStats.aiInsights = true;
          break;
        case 'whatToDo':
          this.animoStats.whatToDo = true;
          break;
        case 'ai':
          this.animoStats.ai = true;
          break;
        case 'aiInsight':
          this.animoStats.aiInsight = true;
          break;
        case 'discovery':
          this.animoStats.discovery = true;
          break;
        case 'concept':
          this.animoStats.concept = true;
          break;
        case 'risk':
          this.animoStats.risk = true;
          break;
        case 'detailedDesign':
          this.animoStats.detailedDesign = true;
          break;
        case 'virtualTesting':
          this.animoStats.virtualTesting = true;
          break;
        case 'prototype':
          this.animoStats.prototype = true;
          break;
        case 'product':
          this.animoStats.product = true;
          break;
        case 'feedback':
          this.animoStats.feedback = true;
          break;
        default:
          break;
      }
    } else {
      return;
    }
  }

  tabChanged(e: number) {
    // console.log(e);
    // this.resetAnimation();
    // console.log(this.animoStats);
    this.planning = (e === 0);
    // console.log(e);
    switch (e) {
      case 0:
        this.animoStats.idea = true;
        break;
      case 1:
        setTimeout(() => {
          this.nextAnimation({ phaseName: 'done' }, 'discovery');
        }, 550);
        break;
      case 2:
        setTimeout(() => {
          this.nextAnimation({ phaseName: 'done' }, 'risk');
        }, 550);
        break;
      case 3:
        setTimeout(() => {
          this.nextAnimation({ phaseName: 'done' }, 'feedback');
        }, 550);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    Config.customDomain = false;
    // const tidioScript = document.createElement("script");
    // tidioScript.src = environment.tidio;
    // tidioScript.async = true;
    // document.querySelector('body').appendChild(tidioScript);
    // console.log(tidioScript);


    const header = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa('clientIdPassword' + ':' + 'secret'))
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams({

    });
    this.http.post<any>(`${Config.apiUrl}/user/domainmap`, { headers: header }).subscribe(data => {
      //console.log("domain MAP" + data);
      if (data == false) {
        //console.log("domain MAPggggg");

        this.alertService.error(
          'Invalid domain!',
          true, 20 * 1000
        );
        this.router.navigate(['/invaliddomain']);
        // this.router.navigate(['https://app.insiq.io']);

      }

    });
    this.http.post<any>(`${Config.apiUrl}/user/whitelabelling`, { headers: header }).subscribe(data => {
      //  console.log("domain related" + data);
      if (data?.domainname != null) {
        Config.customDomain = true;
      } else {
        Config.customDomain = false;
      }
      if (data?.apptheme) {

        let dynamiclogostat = false;
        if (data?.logopath) {
          dynamiclogostat = true;
        }
        this.customThemeService.changeTheme(data?.apptheme.themeName, 'data:image/png;base64,' + data?.logoImage, dynamiclogostat);

      } else {
        this.customThemeService.changeTheme('theme-default', 'logo.png', false);
      }
    });
    //console.log('dff', localStorage.getItem('currentUser'));
    // localStorage.setItem('theme', 'theme-default');

    // console.log(window.screen.availWidth);
    // console.log(environment.supportedScreensize);
    if (window.screen.availWidth < environment.supportedScreensize) {
      this.alertService.error(
        'InsIQ can only be accessed as a web app.<br>The mobile version will be available soon!',
        true, 20 * 1000
      );
    }
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        const token = localStorage.getItem('currentUser');
        if (token == undefined) {
          localStorage.removeItem('currentUser');
          this.router.navigate(['/login']);
          Config.isProjectSelected = false;
          Config.isLoggedIn = false;
          Config.user = null;
        }
      } else {
        this.alertService.error(
          'InsIQ can only be accessed on a modern browser.<br>Please use a modern browser!',
          true, 20 * 1000
        );
      }
    });
    const uAgent = navigator.userAgent;

    // setTimeout(() => {
    //   Config.setLoaderMessage('InsIQ is finding the perfect insights for you, this can take a few seconds');
    //   // Config.setLoaderImage('./assets/images/insiq_ai_insights.png');
    //   Config.loading = true;
    // }, 8000);

    if (this.platform.FIREFOX === true) {
      // console.log('FIREFOX');
      this.browserVersion = uAgent.split(/Firefox\//)[1];
      this.platformName = 'Firefox';
    }
    if (this.platform.ANDROID === true) {
      // console.log('ANDROID');
      this.browserVersion = uAgent.split(/Android\//)[1];
      this.platformName = 'Android';
    }
    if (this.platform.WEBKIT === true) {
      // console.log('WEBKIT');
      this.browserVersion = uAgent.split(/Safari\//)[1];
      this.platformName = 'Safari';
      if (parseInt(this.browserVersion, 10) < 10) {
        this.appSupport = false;
        console.log('Dev2M App Not works in Safari < 10');
      }
    }
    if (this.platform.BLINK === true) {
      // console.log('BLINK');
      this.browserVersion = uAgent.split(/Chrome\//)[1];
      this.platformName = 'Chrome';
      if (parseInt(this.browserVersion, 10) < 55) {
        this.appSupport = false;
        console.log('Dev2M App Not works in Chrome < 55');
      }
    }
    if (this.platform.TRIDENT === true) {
      // console.log('TRIDENT');
      this.platformName = 'Internet Explorer';
      this.appSupport = false;
      this.browserVersion = uAgent.split(/rv\:/)[1];
      if (parseInt(this.browserVersion, 10) < 11) {
        this.appSupport = false;
        console.log('Dev2M App Not works in Internet Explorer < 11');
      }
      // console.log('Dev2M App Not works in Internet Explorer');
    }
    if (this.platform.EDGE === true) {
      // console.log('EDGE');
      this.platformName = 'Edge';
      this.browserVersion = uAgent.split(/Edge\//)[1];
      if (parseInt(this.browserVersion, 10) < 13) {
        this.appSupport = false;
        console.log('Dev2M App Not works in Edge < 13');
      }
    }

    //this.customThemeService.changeTheme(localStorage.getItem('theme'), 'logo.png', false);
  }

  ngOnDestroy() {
    // console.log('destroyed');
    clearInterval(this.timer);
  }
}
