import {
  OnInit,
  Component,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import {
  FormBuilder, Validators
} from '@angular/forms';
import {
  Router,
  ActivatedRoute,
  ParamMap
} from '@angular/router';
import {
  HttpClient
} from '@angular/common/http';
import {
  AlertService
} from '../../_services';
import {
  View
} from '../../config/global.config';

import { Config } from '../../config/global.config';
import { countryList } from 'src/app/core/core.module';
import { switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { environment } from 'src/environments/environment';
import { loadStripe } from '@stripe/stripe-js';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';

type PlanType = 'api' | 'data' | 'user';


@Component({
  selector: 'app-sachetsubscription',
  templateUrl: './sachetsubscription.component.html',
  styleUrls: ['./sachetsubscription.component.scss']
})
export class SachetsubscriptionComponent implements OnInit {
  prevUrl: any;
  backbtnflg: any;

  tab = {
    left: 0,
    width: 0,
    selected: 'api'
  };
  toggle = true;
  isApi = true;
  isData = false;
  isUser = false;
  checkoutFlag = false;
  selectedPlan: any;
  waitflag = false;
  avaTaxResponse;

  selectedIndex = 0;
  toggleBool = false;
  isPlanSelected = false;
  filteredFeatures: any[];
  filterdApiSachet: any[];
  filterdDataSachet: any[];
  filterdUserSachet: any[];

  countryList: any[];
  regionList: any[];
  filteredCountry: any[];
  public countries = countryList;

  @ViewChild('tabApi', { static: false }) tabApi: ElementRef<any>;
  @ViewChild('tabData', { static: false }) tabData: ElementRef<any>;

  // @ViewChild('customTabs', { static: true }) customTabs: ElementRef<any>;
  @ViewChild('chooseYourPlan', { static: false }) chooseYourPlan: ElementRef<any>;
  sachetType: PlanType;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  stripePromise = loadStripe(environment.stripe);

  constructor(
    public view: View,
    public router: Router,
    public config: Config,
    public fb: FormBuilder,
    public http: HttpClient,
    public route: ActivatedRoute,
    public subscriptionview: View,
    public alertService: AlertService,
    public dialog: MatDialog,

  ) {
    this.sachetType = <PlanType>this.route.snapshot.paramMap.get('id');

    switch (this.sachetType) {
      case 'data':
        this.isData = true;
        this.isApi = false;
        this.isUser = false;
        break;
      case 'api':
        this.isData = false;
        this.isApi = true;
        this.isUser = false;
        break;

      case 'user':
        this.isData = false;
        this.isApi = false;
        this.isUser = true; break;
      default:
        router.navigate(['/sachetsubscription', 'api']);
        break;
    }

    this.tab.selected = this.sachetType; // 'api';
    this.view.initForm('sachetsubscription', this.getNewForm(), null, false);
    this.view.refreshList = false;
    // this.view = new View(http, router, route, alertService);
    // this.subscriptionview = new View(http, router, route, alertService);
    // this.subscriptionview.initForm('subscription', this.getSubscriptionForm(), null, false);
    this.backbtnflg = JSON.parse(localStorage.getItem('cond'));
    localStorage.setItem('cond', 'false');
  }

  disabledAgreement = true;

  getAvg(n: number) {
    return Math.floor((n + 1) / 2);
  }
  featureChecker(id: number) {
    return this.config.featureChecker(id);
  }
  getNewForm() {
    return this.fb.group({
      id: [],
      apiSachetId: [],

      country: [''],
      phone: [],
      email: [],
      billingName: [''],
      address: [''],
      city: [''],
      zip: [''],
      region: [''],
      company: [],
      vat: []
    });
  }
  clientfeatureChecker() {
    return this.config.featureChecker(18);
  }
  getCurrency() {
    return Config.subscriptionCurrency;
  }
  UserSachet(plan) {
    this.view.get('apiusage/usercount').subscribe(res => {
      let userval;
      userval = res[0];

      let sachetpurchased = userval?.sachetpurchased;
      let sachetPossible = userval?.sachetuser;

      if (sachetpurchased >= sachetPossible) {
        this.alertService.error(
          'Your Add-ons Limit for Current Plan Exhausted',
          true, 20 * 1000);
      } else {
        this.selectPlan(plan);

      }
    });
    // this.router.navigate(['/sachetsubscription/user']);


  }

  changeCheck(event) {
    this.disabledAgreement = !event.checked;
  }
  disablecheck() {
    if (!this.disabledAgreement && (this.view.value('billingName') && this.view.value('country') && this.view.value('address'))) {
      return false;
    } else {
      return true;
    }
  }
  cancelview() {
    localStorage.setItem('cond', 'false');

    this.router.navigate(['/profile']);
  }
  checkoutdisablecheck() {
    if (this.view.value('billingName') && this.view.value('country') && this.view.value('address') && this.view.value('company') && this.view.value('zip') && this.view.value('city')) {
      return false;
    } else {
      return true;
    }
  }
  filterCountry(event: { query: any; }) {
    let query = event.query;
    query = query?.toLowerCase().trim();
    this.filteredCountry = this.countryList?.filter((pt: { name: string; }) => pt?.name?.toLowerCase()?.indexOf(query) >= 0);
    //  console.log(this.filteredCountry);
  }

  checkout(plan: any) {
    this.view.set('apiSachetId', plan.apiSachetId.id);
    let country = this.view.value('country');
    let region = this.view.value('region');
    if (this.view.value('billingName') && this.view.value('country') && this.view.value('address')) {
      Config.loading = true;
      Config.setLoaderMessage('Proceeding to Checkout');
      this.waitflag = true;
      this.view.saveCallback(() => {

        Config.loading = true;
        Config.setLoaderMessage('Proceeding to Checkout');

        this.view.get('sachetsubscription/checkout').subscribe(res => {
          // console.log(res);
          Config.loading = true;
          Config.setLoaderMessage('Proceeding to Checkout');

          //console.log(country);
          const result = [];
          const map = new Map();

          if (!map.has(country.toLowerCase().trim())) {
            map.set(country.toLowerCase().trim(), true);    // set any value to Map
            result.push({
              name: country
            });
          }


          //  this.countryList = result;

          this.view.set('country', country.toLowerCase().trim());
          this.view.set('region', region?.toLowerCase().trim());
          Config.loading = false;


          this.avaTaxResponse = res;
          this.checkoutFlag = true;
          this.waitflag = false;
        });




        //  window.location.href = Config.BRAINTREE_URL + '/' + this.config?.currentUser?.id + '/' + plan_price + '/1';

      }, this.view.url, '');

    } else {
      this.alertService.error(
        'Please Provide Mandatory Billing Address Details.',
        true, 10 * 1000);
    }

  }

  backtocheckout() {

    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Action', message: 'Are you sure want to abort the Subscription Transaction ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data == 'confirm') {
        this.checkoutFlag = false;
        this.waitflag = false;
        this.view.initForm('sachetsubscription', this.getNewForm(), null, false);

        this.reselectPlan();
      }
    });

    // let country = this.view.value('country').name;
    // let region = this.view.value('region').name;


    // const result = [];
    // const map = new Map();

    // if (!map.has(country.toLowerCase().trim())) {
    //   map.set(country.toLowerCase().trim(), true);    // set any value to Map
    //   result.push({
    //     name: country
    //   });
    // }

    // this.view.set('country', country);
    // this.view.set('region', region);

    //  this.countryList = result;

  }

  subscribe(plan: any) {
    this.view.set('apiSachetId', plan.apiSachetId.id);
    // let plan_price: string;
    // if (plan.offerStatus === 1) {
    //   plan_price = plan.offerPrice;
    // } else {
    //   plan_price = plan.price;
    // }
    // let plan_validity: string;
    // if (plan.paymode === "1") {
    //   plan_validity = '30 Days Validity';
    // } else {
    //   plan_validity = '365 Days Validity';
    // }
    // const plan_name = plan.planId.plancode + '-' + plan.planId.description;

    // this.view.set('revision', '1');
    // this.view.set('autoRenewal', '1');
    // this.view.set('status', '0');

    // this.view.set('planId', plan.planId.id);

    // this.view.set('pricingId', plan.id);
    // this.view.set('amountPaid', plan_price);

    // if (plan.price === 0) {
    //   this.view.set('billingName', 'InsIQ Trial User');
    //   this.view.set('address', 'Lorum Ipsum');
    //   this.view.set('country', 'USA');
    //   this.view.saveCallback(() => {
    //     window.location.href = '/project';
    //   }, this.view.url, '');
    // } else {

    if (this.view.value('billingName') && this.view.value('country') && this.view.value('address')) {
      Config.loading = true;
      Config.setLoaderMessage('Redirecting to Payment Gateway.');

      this.view.saveCallback(async () => {
        //  window.location.href = Config.BRAINTREE_URL + '/' + this.config?.currentUser?.id + '/' + plan.apiSachetId.price + '/2';
        Config.loading = true;
        const payment = {
          userid: this.config?.currentUser?.id,
          name: this.view.value('billingName'),
          currency: 'usd',
          // amount on cents *10 => to be on dollar
          amount: this.avaTaxResponse?.totalAmount * 100,
          quantity: '1',
          paytype: '1'
          //cancelUrl: 'http://localhost:4200/cancel',
          // successUrl: 'http://localhost:4200/success',
        };

        const stripe = await this.stripePromise;

        // this is a normal http calls for a backend api
        this.http
          .post(`${environment.serverUrl}/payment`, payment)
          .subscribe((data: any) => {
            Config.loading = false;
            // I use stripe to redirect To Checkout page of Stripe platform
            // console.log(data);
            localStorage.setItem('paymentId', data.id);
            stripe.redirectToCheckout({
              sessionId: data.id,
            }).then(result => {
              console.log(result);
            })
              .catch(error => {
                console.error(error);
              });;
          });
      }, this.view.url, '');

    } else {
      this.alertService.error(
        'Please Provide Mandatory Billing Address Details.',
        true, 10 * 1000);
    }

  }
  async payNowHere(plan: any) {
    // console.log(plan);
    // window.location.href = Config.BRAINTREE_URL + '/' + this.config?.currentUser?.id + '/' + this.avaTaxResponse?.totalAmount + '/2';

    // window.location.href = Config.BRAINTREE_URL + '/' + this.config?.currentUser?.id + '/' + this.avaTaxResponse?.totalAmount + '/1';
    // let amount: any = textbox.value;
    Config.loading = true;
    const payment = {
      userid: this.config?.currentUser?.id,
      name: this.view.value('billingName'),
      currency: 'usd',
      // amount on cents *10 => to be on dollar
      amount: this.avaTaxResponse?.totalAmount * 100,
      quantity: '1',
      paytype: '2'
      //cancelUrl: 'http://localhost:4200/cancel',
      // successUrl: 'http://localhost:4200/success',
    };

    const stripe = await this.stripePromise;

    // this is a normal http calls for a backend api
    this.http
      .post(`${environment.serverUrl}/payment`, payment)
      .subscribe((data: any) => {
        Config.loading = false;
        this.config.userCount();

        // I use stripe to redirect To Checkout page of Stripe platform
        // console.log(data);
        localStorage.setItem('paymentId', data.id);
        stripe.redirectToCheckout({
          sessionId: data.id,
        }).then(result => {
          console.log(result);
        })
          .catch(error => {
            console.error(error);
          });;
      });


  }
  getPayTxt(price: number) {
    let pay: string;
    if (price === 0) {
      pay = 'Start Free Trial';
    } else {
      pay = 'Pay Now';
    }
    return pay;
  }


  moveToSelectedTab() {
    this.toggle = !this.toggle;
    this.selectedIndex = 0;
  }
  moveToSelectedTab1() {
    this.toggle = !this.toggle;
    this.selectedIndex = 1;
  }

  reselectPlan() {
    this.checkoutFlag = false;
    this.waitflag = false;
    this.view.initForm('sachetsubscription', this.getNewForm(), null, false);

    this.isPlanSelected = false;
  }

  selectPlan(plan) {
    this.view.get('billinginfo').subscribe(res => {

      if (res && res !== null) {
        this.view.set('billingName', res[0].billingName);
        this.view.set('phone', res[0].phone);
        this.view.set('address', res[0].address);
        this.view.set('email', res[0].email);
        this.view.set('zip', res[0]?.zip);
        this.view.set('city', res[0]?.city);
        this.view.set('company', res[0]?.company);
        this.view.set('vat', res[0]?.vat);
        const result = [];
        const map = new Map();

        if (!map.has(res[0]?.country.toLowerCase().trim())) {
          map.set(res[0]?.country.toLowerCase().trim(), true);    // set any value to Map
          result.push({
            name: res[0]?.country
          });
        }


        //  this.countryList = result;

        this.view.set('country', res[0]?.country);
        this.view.set('region', res[0]?.region);
      }
    });

    if (plan.apiSachetId.price === 0) {
      this.subscribe(plan);
    } else {
      this.selectedPlan = plan;
      this.isPlanSelected = true;
    }
  }
  openTnC(typeVal) {
    // const dialogRef =
    sessionStorage.setItem('tandclink', typeVal);

    sessionStorage.setItem('tandc', 'REG');
    this.dialog.open(TermsDialogComponent, {
      width: '98vw',
      height: '98vh',
      autoFocus: false,
      panelClass: 'TnC',
      disableClose: true
    });
  }
  // @HostListener('window:resize', ['$event'])
  // resizeTabs(event: any) {
  //   // console.log('Tab Resized', event);
  //   if (this.customTabs === undefined) {
  //     return;
  //   }
  //   const tgt = this.customTabs.nativeElement.querySelectorAll('.customTabs button.active')[0];
  //   console.log(tgt);
  //   this.choosePlan({ target: tgt }, tgt.dataset.plan);
  // }

  // choosePlan(e: any, opt: PlanType) {
  //   // console.log(e.target, opt);
  //   // console.log(this.customTabs);
  //   this.isApi = opt === 'api' ? true : false;
  //   // this.customTabs.nativeElement.querySelectorAll('button').forEach(tab => {
  //   //   tab.classList.remove('active');
  //   // });
  //   // e.target.classList.add('active');
  //   this.tab.selected = opt;
  //   setTimeout(() => {
  //     this.getTabPos(e.target);
  //   }, 150);
  // }

  // getTabPos(tgt: any) {
  //   // console.log('ink', tgt);
  //   this.tab.left = tgt.offsetLeft;
  //   this.tab.width = tgt.offsetWidth;
  // }

  ngOnInit(): void {
    this.view.get('apisachetplans/sachetoption').subscribe(res => {
      //   console.log(res);
      // this.view.list = res;
      this.filterdApiSachet = res.filter(u => u.apiSachetId.sachetTypeid === 1);
      this.filterdDataSachet = res.filter(u => u.apiSachetId.sachetTypeid === 2);
      this.filterdUserSachet = res.filter(u => u.apiSachetId.sachetTypeid === 3);


      this.view.get('billinginfo/countrylist').subscribe(res => {

        // this.countryList=res;

        const result = [];
        const map = new Map();
        for (const item of res) {
          if (!map.has(item.name.toLowerCase().trim())) {
            map.set(item.name.toLowerCase().trim(), true);    // set any value to Map
            result.push({
              name: item.name
            });
          }
        }

        this.countryList = result;
        // console.log(this.countryList);
      });

      this.view.get('billinginfo/regionlist').subscribe(res => {
        // console.log(res);

        const result = [];
        const map = new Map();
        for (const item of res) {
          if (!map.has(item.name.toLowerCase().trim())) {
            map.set(item.name.toLowerCase().trim(), true);    // set any value to Map
            result.push({
              name: item.name
            });
          }
        }

        this.regionList = result;
      });
    });

    if (this.config.currentUser ? this.config.currentUser?.pin === 'USR' : JSON.parse(localStorage.getItem('currentUserStore'))?.pin === 'USR') {

      this.router.navigate(['/login']);
    }
  }
}
