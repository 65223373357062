<div class="d-block p-a-0">

  <h4 class="text-accent">
    <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" (click)="backtoPage()" title="Close">
      <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
    </button>
  </h4>
  <button type="button" class="btn btn-primary btn-link" matRipple (click)="downloadContent()">
    <i class="i i-ai_report"></i>
    <span> Download Reports</span>
  </button>
  <ckeditor *ngIf="editorForm && editorForm.get('content')" class="m-t-30" [editor]="Editor"
    [formControl]="editorForm.get('content')">
  </ckeditor>
</div>