import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-nav',
  templateUrl: './scroll-nav.component.html',
  styleUrls: ['./scroll-nav.component.scss']
})
export class ScrollNavComponent implements OnInit {
  @Input() scrollContainer!: any;
  @Input() scrollThreshold: number = 0.5;
  @Input() scrollOffsetTop: number = 0;
  @Input() scrollOffsetBottom: number = 0;

  showScrollTop: boolean = false;
  showScrollBottom: boolean = false;

  constructor() { }

  ngOnInit() {
    this.updateScrollState();
    this.scrollContainer.addEventListener('scroll', () => this.onScroll());
  }

  onScroll() {
    this.updateScrollState();
  }

  scrollToTop() {
    const scrollEl = this.scrollContainer;
    scrollEl.scrollTo({ top: 0, behavior: 'smooth' });
  }

  scrollToBottom() {
    const scrollEl = this.scrollContainer;
    const scrollHeight = scrollEl.scrollHeight;

    scrollEl.scrollTo({ top: scrollHeight, behavior: 'smooth' });
  }

  updateScrollState() {
    const scrollEl = this.scrollContainer;
    const scrollHeight = scrollEl.scrollHeight;
    const scrollTop = scrollEl.scrollTop;
    const clientHeight = scrollEl.clientHeight;
    const visibleHeight = Math.min(scrollTop + clientHeight, scrollHeight) - scrollTop;
    const percentageVisible = visibleHeight / scrollHeight;

    // this.showScrollTop = scrollTop >= clientHeight * this.scrollThreshold;
    // this.showScrollBottom = percentageVisible < 1;
    // console.log({
    //   scrollContainer: this.scrollContainer,
    //   scrollTop,
    //   scrollHeight,
    //   trigger: clientHeight + this.scrollOffsetBottom
    // });

    this.showScrollTop = scrollTop >= this.scrollOffsetTop;
    this.showScrollBottom = scrollTop < (scrollHeight - (clientHeight + this.scrollOffsetBottom));
  }
}
