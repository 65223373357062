<div class="loading" [ngClass]="{
  transparent: transparentLoader,
  hasProgress: showProgress,
  hasMessage: message !== '',
  hasImage: image !== undefined
}" *ngIf="loading">
  <div *ngIf="image === undefined && !transparentLoader">
    <mat-progress-bar [ngClass]="{lg: image !== undefined}"
      [mode]="image !== undefined ? 'determinate' : 'indeterminate'" [value]="progress" color="accent">
    </mat-progress-bar>
    <i></i>
  </div>

  <div class="wrap" *ngIf="!transparentLoader">
    <figure *ngIf="image !== undefined">
      <div class="loader" *ngIf="showProgress">
        <span>
          {{this.progress}}
          <small>%</small>
        </span>
      </div>
      <img [src]="image" alt="InsIQ">
      <figcaption *ngIf="message !== ''">{{message}}</figcaption>

      <!-- <blockquote class="helperQuote" *ngIf="canExit">
        <div class="text">
          <div class="title">Not yet ready?</div>
          <div class="content">
            You can exit from here & came back later!
          </div>
        </div>
        <div class="actions">
          <button matRipple type="button" class="btn btn-accent curved" (click)="exitLoading($event)">Exit</button>
        </div>
      </blockquote> -->

      <!-- <div *ngIf="showProgress" class="progressStatus" [ngInit]="figureCallBack()" data-percentage="0">
        <svg id="pie" viewPort="0 0 100 100" version="1.1">
          <circle r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0"></circle>
          <circle class="bar" r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0">
          </circle>
        </svg>
      </div> -->

      <!-- <button matRipple type="button" class="close btn btn-link" (click)="exitLoading($event)" *ngIf="exitLoader"
        title="Close">
        <i class="i i-close"></i>
      </button> -->
    </figure>
  </div>

  <div class="message" *ngIf="message !== '' && image === undefined && !transparentLoader">
    <img src="./assets/images/typing-loader.gif" alt="..." *ngIf="inlineLoading">
    <span>{{message}}</span>
  </div>
</div>