import { Router } from '@angular/router';
import { AIView } from '../ai/insight.view';
import { NotesDialogComponent } from '../notesalerts/notes-dialog';
import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-trend-dialog',
  templateUrl: 'trend-dialog.html',
  styleUrls: ['trend-dialog.scss']
})
export class TrendDialogComponent {
  showWish = false;
  favStat = false;
  cols;
  notesDialogRef: MatDialogRef<NotesDialogComponent, any>;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  constructor(
    public aiv: AIView,
    public router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogReftrend: MatDialogRef<TrendDialogComponent>
  ) {
    this.showWish = false;
    this.favStat = false;
    this.cols = [
      { field: 'actions', header: 'Actions' },
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },

    ];
  }

  @ViewChild('secondDialog', { static: true }) STAT_POPUP: TemplateRef<any>;

  backtoPage() {
    this.dialogReftrend.close();
    this.aiv.getLiked();
    this.aiv.listViewInsight = true;

    this.aiv.getComments();
    this.aiv.getReports();
  }
  openLinkInNewTab(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
  backtoHome() {
    this.dialogReftrend.close();
    this.aiv.listViewInsight = true;
    this.aiv.getLiked();
    this.aiv.getComments();
    this.aiv.getReports();
    this.router.navigate(['']);
  }

  insightDeep(aiId, fl) {
    this.aiv.insightDeep(aiId, fl);
  }

  likeOrUnlike(row, val) {
    row.aiUrl = row.aiurl;
    row.aiImages = '';
    row.aiVideos = '';

    this.aiv.likeOrUnlikecat(row, val, 'Trend Insights');
  }
  addremoveReport(row) {
    row.aiUrl = row.aiurl;
    row.aiImages = '';
    row.aiVideos = '';
    row.aiNameTemp = 'Trend Insights';
    this.aiv.addremoveReport(row);
  }
  testfn(row) {
    if (this.aiv.featureChecker(15)) {
      this.testfn1(row);
    } else {
      this.aiv.featurealert('Notes');
    }
  }
  testfn1(row) {
    this.aiv.newnotesave = false;
    if (row?.liked?.aiLikeon === 1) {
      this.favStat = true;
    } else {
      this.favStat = false;
    }
    // console.log(row);
    // console.log(row.aiNotes);
    this.aiv.notesRow = row;
    this.aiv.view.set('notes', row.noteAdd);


    this.aiv.noteOld = row.noteAdd;
    if (!this.aiv.noteOld) {
      this.aiv.view.set('notesId', null);
    } else {
      this.aiv.view.set('notesId', 1);
    }
    //  console.log(this.STAT_POPUP);
    this.notesDialogRef = this.dialog.open(NotesDialogComponent, {
      data: this.STAT_POPUP
    });
  }

  cancelNote() {
    this.notesDialogRef.close();
  }
  cnfrmLike() {
    //console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Add to Favorite?', message: 'Would you like to mark this Insight as Favorite also ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.likeOrUnlike(this.aiv.notesRow, '1');
      }
    });
  }
  saveNote() {



    this.aiv.saveNotetrend();
    if (!this.favStat) {
      this.cnfrmLike();
    }
    this.notesDialogRef.close();
  }

  openConfirmationDialog() {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure want to delete this note ?',
        cancelbtn: 'No',
        okbtn: 'Yes'
      },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.aiv.deleteNotetrend();
        this.notesDialogRef.close();
      }
    });
  }

  deleteNote() {
    this.openConfirmationDialog();
  }

  saveNotems() {
    this.aiv.saveNotemstrend();
    this.notesDialogRef.close();
  }

  fbshare(e: { preventDefault: () => void; }, urls: string) {
    e.preventDefault();
    const facebookWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php${urls}`
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }

  linkedinshre(e: { preventDefault: () => void; }, urls: string, aiTitle: string) {
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${urls}&title=${aiTitle}&summary=${aiTitle}&source=InsIQ`;
    e.preventDefault();
    const linkedinWindow = window.open(url);
    if (linkedinWindow.focus) {
      linkedinWindow.focus();
    }
    return false;
  }

  twittershare(e: { preventDefault: () => void; }, urls: string) {
    const text = 'InsIQ';
    const hashtag = 'InsIQ';
    e.preventDefault();
    const twitterWindow = window.open(
      `http://twitter.com/share?text=${text}&url=${urls}&hashtags=${hashtag}`
    );
    if (twitterWindow.focus) {
      twitterWindow.focus();
    }
    return false;
  }

  watzappshare(e: { preventDefault: () => void; }, urls: string, aiTitle: string) {
    e.preventDefault();
    const watzappWindow = window.open(
      `https://web.whatsapp.com/send?text=InsIQ AI Insight:  ${aiTitle}  Visit: ${urls} for more info  #InsIQ,#aipowered,#devtominsights`
    );
    if (watzappWindow.focus) {
      watzappWindow.focus();
    }
    return false;
  }

  trimtooltip(noteAdd: string) {
    try {
      if (noteAdd.length > 5) {
        return noteAdd.split(' ').slice(0, 5).join(' ') + '...';
      } else {
        return noteAdd;
      }
    } catch (exception) { }
  }
}
