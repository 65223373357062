import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { View, Config, FormDefault } from '../../config';
import { SharedService } from './shared.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';

@Injectable({
  providedIn: 'root'
})
export class AnalysisView {
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    public ss: SharedService) {
    this.analysisView = new View(http, router, route, alertService);
    this.view = new View(http, router, route, alertService);
    this.analysisView.initForm(this.url, this.getanalysisForm(), null, false);
    this.ss.setAnalysisView(this);
    this.view.refreshList = false;
    this.view.refreshForm = false;
    this.requirementstatus = false;
    this.analysisView.refreshList = false;
    this.chooselist = false;
    //  this.analysisView.refreshForm = false;

    this.setForm();
    // this.smartdev2m = false;
    // this.selectedTab = 0;
  }
  // discoveryRequirements;
  concepts;
  selectedConceptItem;
  selectedconceptId;
  chooselist: boolean;
  selectedRequirements: any[];
  conceptRequirements;
  selectedConcepts: any[];
  requirementTypeItemCodes;
  discoveryKeyplayers;
  url = 'analysis';
  selectedTab: number;
  importance: number;
  importanceTimeout: any;
  smartdev2m: boolean;
  requirementstatus: boolean;
  public view: View;
  private analysisView: View;
  isValid() {
    return this.selectedRequirements && this.selectedRequirements.length > 0;
  }

  setForm() {
    this.selectedRequirements = [];
    //  this.discoveryRequirements = null;
    this.requirementstatus = false;
    this.concepts = null;
    this.selectedTab = 0;
    this.initForm();

    this.conceptRequirements = null;
    this.selectedConcepts = [];
    this.selectedConceptItem = null;
    this.getList();
    this.view.state = 'list';
    this.view.delete = ((id) => {
      return this.ss.getAnalysisView().delete(id);
    });
    this.view.edit = ((row) => {
      return this.ss.getAnalysisView().edit(row);
    });
  }
  initForm() {
    this.view.initForm(this.url, this.getForm(), null, false);
    Config.isProjectSelected = true;
  }

  newform(load = true) {
    //console.log('here');
    this.requirementstatus = false;
    this.importance = null;
    this.loadList();

    // this.importance = nul
    // this.view.newform();

    this.view.newform();
  }
  private loadList() {
    this.view.get(`concept/concept/${this.ss.getProjectId()}`).subscribe(res => {
      this.concepts = res;
    });
    
  }

  delete(id) {
    const list = this.view.list.filter(u => u.id === id);
    let item = null;
    for (let x = 0; x < list.length; x++) {
      item = list[x];
    }
    return this.view._delete(id, null, null, null, true, () => {

      if (item !== null) {
        this.concepts.push(item.conceptId);
      }
      this.selectedTab = 0;
      this.selectedConceptItem = null;
      this.requirementstatus = false;
    });
  }
  save() {
    this.requirementstatus = false;
    const n = this.selectedRequirements.length;

    this.view.set('conceptId', this.selectedConceptItem);
    this.view.set('projectId', this.ss.getProject());
    // this.view.set('analysisRequirementList', this.selectedRequirements);
    this.analysisView.state = 'new';
    //console.log(this.view.form.value);
    this.analysisView.set('analysis', this.view.form.value);

    const analysisRequirement = [];
    for (let x = 0; x < this.selectedRequirements.length; x++) {
      analysisRequirement.push(this.selectedRequirements[x].discoveryRequirementId);
      analysisRequirement[x].conceptRequirementId = new Object();
      analysisRequirement[x].conceptRequirementId.id = this.selectedRequirements[x].id;
    }
    //console.log(analysisRequirement);

    this.analysisView.set('analysisRequirementList', analysisRequirement);
    this.analysisView.saveCallback(() => {

      for (let x = 0; x < this.selectedRequirements.length; x++) {
        const item = this.selectedRequirements[x];
        this.conceptRequirements = this.conceptRequirements.filter(u => u.id !== item.id);
      }

      this.analysisView.state = 'new';
      this.selectedRequirements = [];
      this.loadList();
      this.getList();
    });

  }

  saveAndAdd() {
    // this.view.set('label', this.view.value('requirement'));
    this.save();
  }

  edit(analysis) {
    this.view.active = analysis;
    //console.log(analysis);
    this.view.set('id', analysis.id);
    this.view.state = 'edit';
    this.select(analysis.conceptId);
    this.loadList();
  }

  select(concept) {
    this.requirementstatus = true;
    //console.log(concept);
    this.selectedConceptItem = concept;


    this.selectedTab = 1;
    this.view.get(`conceptrequirement/getconcept/${concept.id}`).subscribe(res => {
      //console.log(res);
      this.conceptRequirements = res;
      //console.log('The list is here');

    });
    //         this.view.get(`discoveryrequirement/concept/${this.ss.getProjectId()}/${this.selectedConceptItem}`).subscribe(res => {
    //        this.discoveryRequirements = res;
    //      });
    this.view.get(`discoverykeyplayer/project/${this.ss.getProjectId()}`).subscribe(res => {
      this.discoveryKeyplayers = res;
      for (let x = 0; x < res.length; x++) {
        const item = this.discoveryKeyplayers[x];
        item['value'] = item['keyplayer'];
      }
    });
    this.view.get(`requirementtypeitem/all`).subscribe(res => {
      this.requirementTypeItemCodes = res; // = [];
      for (let x = 0; x < res.length; x++) {
        const item = this.requirementTypeItemCodes[x];
        item['value'] = item['description'];
      }
    });
    //  this.view.newform();


    // console.log(this.selectedTab );
    // this.reqView.newform();
  }
  cancel() {
    //console.log(this.view.isForm(), this.ss.getRequirementTypeView());
   // this.ss.getRequirementTypeView().getList();
     //console.log(this.view.isForm());
    this.view.cancel();
     //console.log(this.view.isForm());
    this.selectedTab = 0;
    //console.log(this.view.isForm());

  }
  choosenew() {
    this.chooselist = true;
  }

  getList() {
    this.view.get(`${this.url}/all/${this.ss.getProjectId()}`).subscribe(res => {
      this.view.list = res;
    });
  }

  getForm() {
    return this.fb.group({
      id: [''],
      conceptId: ['', Validators.required],
      label: [''],
      projectId: ['', Validators.required],
      importanceChange: [''] // dummy
    });
  }
  getanalysisForm() {
    return this.fb.group({
      id: [''],
      analysis: ['', Validators.required],
      analysisRequirementList: ['', Validators.required]
    });
  }

  onImportanceChange(event, dt) {
    if (this.importanceTimeout) {
      clearTimeout(this.importanceTimeout);
    }
    this.importanceTimeout = setTimeout(() => {
      dt.filter(event.value, 'importance', 'gte');
      this.importance = event.value;
    }, 250);
  }

  filterRTI(event, dt) {
    //console.log(event);
    dt.filter(event.value, 'requirementTypeItemCode.description', 'in');
  }

}
