<div class="container-fluid d-flex h-100">
  <div class="wrapper" [ngClass]="!ai.display ? 'active' : 'hidden'">
    <app-sub-header [data]="header" (aiEvent1)="ai.smartDev(1,co.view, ss.getProject())" (aiEvent2)="ai.smartDev(2,co.view, ss.getProject())" (aiEvent3)="ai.smartDev(3,co.view, ss.getProject())" (aiEvent4)="ai.smartDev(4,co.view, ss.getProject())"
      (aiFav)="ai.wishList(co.view, ss.getProject(), '1')"></app-sub-header>

    <mat-tab-group mat-stretch-tabs class="pageTabs mat-elevation-z4 w-100">
      <mat-tab label="Overview">
        <!-- TreeView -->
        <div class="conArea" [ngClass]="isList() ? 'active' : 'hidden'">
          <app-smart-tree (createNode)="newConnectNode($event)" (nodeOpen)="onNodeOpen($event)"
            (nodeClose)="onNodeClose($event)" [data]="data"></app-smart-tree>
        </div>

        <!-- Popups -->
        <div class="conArea p-x-20 p-t-20 p-b-30" *ngIf="!isList()">
          <!-- CONCEPT -->
          <div class="row " *ngIf="co.view.isForm()">
            <div class="col-sm-5">
              <app-tree-preview [data]="co.view" title="Concept"></app-tree-preview>
            </div>
            <div class="col-sm-19">
              <div class="workArea">
                <mat-card class="popup">
                  <form [formGroup]="co.view.form" novalidate>
                    <div class="popHeader d-flex align-items-center">
                      <span class="ib-m title">Concept</span>
                      <div class="spacer"></div>
                      <!-- <button mat-raised-button class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">SmartDev2m</span>
                      </button> -->
                      <button type="button" mat-icon-button class="close ib-m" (click)="co.cancel()" title="Close"
                        matTooltipPosition="below">
                        <mat-icon fontSet="i" class="i-close"></mat-icon>
                      </button>
                    </div>
                    <mat-card-content class="popContent">
                      <mat-form-field class="m-b-15 fw">
                        <input matInput placeholder="Concept" formControlName="title" maxlength="100">
                        <mat-error *ngIf="co.view.invalid('title')">{{co.view.error('title')}}</mat-error>
                      </mat-form-field>

                      <mat-form-field class="fw m-b-15">
                        <mat-label> Description</mat-label>
                        <textarea matInput formControlName="description" cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                        <mat-error *ngIf="co.view.invalid('description')">{{co.view.error('description')}}</mat-error>
                      </mat-form-field>


                    </mat-card-content>
                    <mat-card-actions class="t-c">
                      <button type="button" (click)="co.cancel()" mat-raised-button color="warn"
                        class="curved m-x-5">Cancel</button>
                      <button type="submit" (click)="co.save()" [disabled]="co.view.invalid()" *ngIf="co.view.isNew()"
                        mat-raised-button color="primary" class="curved m-x-5">Save</button>
                      <button type="submit" (click)="co.saveAndAdd()" [disabled]="co.view.invalid() "
                        *ngIf="co.view.isNew()" mat-raised-button color="primary" class="curved m-x-5">Save &
                        Add</button>
                      <button type="submit" (click)="co.save()" [disabled]="co.view.invalid()" *ngIf="co.view.isEdit()"
                        mat-raised-button color="primary" class="curved m-x-5">Update</button>
                    </mat-card-actions>
                  </form>
                </mat-card>
              </div>
            </div>
          </div>

          <!-- Requirements -->
          <div class="row " *ngIf="re.view.isForm()">
            <div class="col-sm-5">
              <app-tree-preview [data]="re.view" title="Requirement"></app-tree-preview>
            </div>
            <div class="col-sm-19">
              <div class="workArea">
                <mat-card class="popup">
                  <form [formGroup]="re.view.form" novalidate>
                    <div class="popHeader d-flex align-items-center">
                      <span class="ib-m title">Requirement</span>
                      <div class="spacer"></div>
                      <!-- <button mat-raised-button class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">SmartDev2m</span>
                      </button> -->
                      <button type="button" mat-icon-button class="close ib-m" (click)="re.cancel()" title="Close"
                        matTooltipPosition="below">
                        <mat-icon fontSet="i" class="i-close"></mat-icon>
                      </button>
                    </div>
                    <mat-card-content class="popContent">
                      <div class="m-b-15 d-flex">
                        <div class="ib-m">
                          <label class="fw"><small>Design Concept</small></label>
                          <div class="o-80">{{ss.concept.label}}</div>
                        </div>
                        <div class="spacer"></div>
                        <div class="ib-m">
                          <label class="fw"><small>Requirement Type</small></label>
                          <!-- <div class="o-80 ib-m m-r-10">Behavioural and visceral</div> -->
                          <mat-chip-list class="ib-m">
                            <mat-chip color="primary" disabled selected>
                              <span>{{ss.requirementType.label}}</span>
                              <span *ngIf="re.view.isEdit()">&nbsp;-
                                {{re.view.value('discoveryRequirementId').requirementTypeItemCode.label}}</span>
                            </mat-chip>
                          </mat-chip-list>
                        </div>
                      </div>

                      <div class="rel dataTable" *ngIf="re.view.isNew()">
                        <p-table #dt [value]="re.discoveryRequirements" selectionMode="multiple" [paginator]="true"
                          [rows]="re.view.pageSize" [(selection)]="re.selectedRequirements" dataKey="id" class="w-100">
                          <ng-template pTemplate="header">
                            <tr>
                              <th style="width: 3em">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                              </th>
                              <th pSortableColumn="requirement">
                                Requirement
                                <p-sortIcon [field]="requirement"></p-sortIcon>
                              </th>
                              <th class="w-120px" pSortableColumn="importanceId?.title">
                                Importance
                                <p-sortIcon [field]="importanceId?.title"></p-sortIcon>
                              </th>
                              <th class="w-200px" pSortableColumn="requirementTypeItemCode.description">
                                Requirement Type
                                <p-sortIcon [field]="requirementTypeItemCode"></p-sortIcon>
                              </th>
                              <th class="w-180px" pSortableColumn="keyplayerId.keyplayer">Keyplayer <p-sortIcon
                                  [field]="keyplayerId"></p-sortIcon>
                              </th>
                            </tr>
                            <tr>
                              <th></th>
                              <th></th>
                              <th class="p-a-0">
                                <!--   
                                  <i class="fa fa-close" (click)="re.importance=null;dt.filter(null, 'importance', 'equals')" style="cursor:pointer" *ngIf="re.importance"></i>
                                   <mat-slider class="w-40px ib-m" min="0" max="10" step="1" value="0" thumbLabel="true" formControlName="importanceChange" (change)="re.onImportanceChange($event, dt)"></mat-slider> 
                                  <div class="ib-m w-20px m-l-5 t-r"> &gt;={{(re.importance)? re.importance : 0}}</div>
                                -->
                                <p-multiSelect [options]="importances" [style]="{'width':'100%'}" defaultLabel="All "
                                  (onChange)="dt.filter($event.value, 'importanceId.title','in')">
                                </p-multiSelect>

                              </th>
                              <th>
                                <p-multiSelect [options]="re.requirementTypeItemCodes" [style]="{'width':'100%'}"
                                  defaultLabel="All "
                                  (onChange)="dt.filter($event.value, 'requirementTypeItemCode.id', 'in')">
                                  <ng-template let-rtc let-i="index" pTemplate="item">
                                    <span>{{rtc.requirementTypeCode.label}} {{rtc.label}} </span>
                                  </ng-template>
                                </p-multiSelect>
                              </th>
                              <th>
                                <p-multiSelect [options]="re.discoveryKeyplayers" [style]="{'width':'100%'}"
                                  defaultLabel="All "
                                  (onChange)="dt.filter($event.value, 'keyplayerId.keyplayer', 'in')"></p-multiSelect>
                              </th>

                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-row>
                            <tr [pSelectableRow]="row">
                              <td class="t-c">
                                <p-tableCheckbox [value]="row"></p-tableCheckbox>
                              </td>
                              <td>{{row.requirement}}</td>
                              <td>{{row.importanceId?.title}}</td>
                              <td>{{row.requirementTypeItemCode.description}}</td>
                              <td>{{row.keyplayerId.keyplayer}}</td>
                            </tr>
                          </ng-template>
                        </p-table>

                        <div class="t-c p-y-5 bar-T m-t-20">
                          <button type="button" (click)="re.cancel()" mat-raised-button color="warn"
                            class="curved m-x-5">Cancel</button>
                          <button type="submit" (click)="re.save()" [disabled]="!re.isValid()" *ngIf="re.view.isNew()"
                            mat-raised-button color="primary" class="curved m-x-5">Select</button>
                        </div>

                      </div>
                      <div class="" *ngIf="re.view.isEdit()">
                        <div class="m-b-15 fw">
                          <label class="d-block"><small>Importance </small></label>
                          <label
                            class="d-block scrollY max h-120px b-b-2 dotted p-y-5">{{re.view.value('discoveryRequirementId').importanceId?.title}}
                          </label>
                        </div>
                        <!-- <mat-form-field class="m-b-15 fw">
                          <input type="text" matInput placeholder="Importance" disabled value="re.view.value('discoveryRequirementId').importance">
                        </mat-form-field> -->
                        <div class="m-b-15 fw">
                          <label class="d-block"><small>Requirement </small></label>
                          <label
                            class="d-block scrollY max h-120px b-b-2 dotted p-y-5">{{re.view.value('discoveryRequirementId').requirement}}
                          </label>
                        </div>
                        <div class="m-b-15 fw">
                          <label class="d-block"><small>Rationalisation </small></label>
                          <label
                            class="d-block scrollY max h-120px b-b-2 dotted p-y-5">{{re.view.value('discoveryRequirementId').rationalisation}}
                          </label>
                        </div>
                        <div class="m-b-15 fw">
                          <label class="d-block"><small>Note </small></label>
                          <label
                            class="d-block scrollY max h-120px b-b-2 dotted p-y-5">{{re.view.value('discoveryRequirementId').note}}
                          </label>
                        </div>
                        <!-- <div class="m-b-15 fw">
                          <label class="d-block"><small>Requirement Type </small></label>
                          <label class="d-block scrollY max h-120px b-b-2 dotted p-y-5">{{re.view.value('discoveryRequirementId').requirementTypeItemCode.label}}
                          </label>
                        </div>-->

                        <!-- <app-asset-manager [data]="re" [(index)]="re.selectedTab"></app-asset-manager> -->

                        <div class="t-c">
                          <button type="button" (click)="re.cancel()" mat-raised-button color="warn"
                            class="curved m-x-5">Cancel</button>
                        </div>
                      </div>
                    </mat-card-content>
                  </form>
                </mat-card>
              </div>
            </div>
          </div>

          <!-- Feature -->
          <div class="row " *ngIf="rf.view.isForm()">
            <div class="col-sm-5">
              <app-tree-preview [data]="rf.view" title="Feature"></app-tree-preview>
            </div>
            <div class="col-sm-19">
              <div class="workArea">
                <mat-card class="popup">
                  <form [formGroup]="rf.view.form" novalidate>
                    <div class="popHeader d-flex align-items-center">
                      <span class="ib-m title">Feature</span>
                      <div class="spacer"></div>

                      <!-- <button mat-raised-button class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">SmartDev2m</span>
                      </button> -->
                      <button type="button" mat-icon-button class="close ib-m" (click)="rf.cancel()" title="Close"
                        matTooltipPosition="below">
                        <mat-icon fontSet="i" class="i-close"></mat-icon>
                      </button>
                    </div>
                    <mat-card-content class="popContent">
                      <div class="infoBar" *ngIf="rf.view.isNew()">
                        <div class="m-b-15 d-flex">
                          <div class="ib-m">
                            <label class="fw"><small>Keyplayer Type</small></label>
                            <p-dropdown [options]="rf.discoveryKeyplayers" (onChange)="rf.filterKeyplayer($event)"
                              formControlName="filterKeyplayer" placeholder="Keyplayer" optionLabel="label">
                            </p-dropdown>
                          </div>
                          <div class="spacer"></div>
                          <div class="ib-m">
                            <label class="fw"><small>Requirement Type</small></label>
                            <p-dropdown [options]="rf.requirementTypeItemCodes" (onChange)="rf.filterRT($event)"
                              formControlName="filterRequirementType" placeholder="Requirement Type">
                            </p-dropdown>
                          </div>
                        </div>
                        <div class="fw">
                          <label class="fw"><small>Requirements</small></label>
                          <!--  <pre>{{rf.requirements | json}}</pre>-->
                          <p-dropdown [options]="rf.requirements" [filter]="true" [style]="{'width':'100%'}"
                            formControlName="conceptRequirementId" placeholder="Requirements" optionLabel="label">
                            <!-- <ng-template let-creq pTemplate="item"   >
                              <div  class="ib-m">{{creq.label}} </div>
                            </ng-template>-->
                          </p-dropdown>
                          <small class="mat-error"
                            *ngIf="rf.view.invalid('conceptRequirementId')">{{rf.view.error('conceptRequirementId')}}</small>
                        </div>
                      </div>

                      <div class="m-b-15 d-flex">
                        <div class="ib-m">
                          <label class="fw"><small>Keyplayer Type</small></label>
                          <div class="o-80">
                            {{rf.view.value('conceptRequirementId').discoveryRequirementId.keyplayerId.label}}</div>
                        </div>
                        <div class="spacer"></div>
                        <div class="ib-m">
                          <label class="fw"><small>Requirement Type</small></label>
                          <!-- <div class="o-80 ib-m m-r-10">Behavioural and visceral</div> -->
                          <mat-chip-list class="ib-m">
                            <mat-chip color="primary" disabled selected>
                              <span>{{rf.view.value('conceptRequirementId').discoveryRequirementId.requirementTypeCode.label}}</span>
                              <span *ngIf="rf.view.isEdit()">&nbsp;-
                                {{rf.view.value('conceptRequirementId').discoveryRequirementId.requirementTypeItemCode.label}}</span>
                            </mat-chip>
                          </mat-chip-list>
                        </div>
                      </div>
                      <div class="m-b-15 fw">
                        <label class="fw"><small>Requirement</small></label>
                        <div class="scrollY max h-120px b-b-2 dotted p-y-5">
                          {{rf.view.value('conceptRequirementId').discoveryRequirementId.requirement}}</div>
                      </div>

                      <mat-form-field class="m-b-15 fw">
                        <mat-label>Feature</mat-label>
                        <input matInput placeholder="Feature" formControlName="title" maxlength="255">
                        <mat-error *ngIf="rf.view.invalid('title')">{{rf.view.error('title')}}</mat-error>
                      </mat-form-field>

                      <mat-form-field class="fw m-b-15">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description" cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                        <mat-error *ngIf="rf.view.invalid('description')">{{rf.view.error('description')}}</mat-error>
                      </mat-form-field>

                      <mat-card-actions class="t-c">
                        <button type="button" (click)="rf.cancel()" mat-raised-button color="warn"
                          class="curved m-x-5">Cancel</button>
                        <button type="submit" (click)="rf.save()" [disabled]="rf.view.invalid()" *ngIf="rf.view.isNew()"
                          mat-raised-button color="primary" class="curved m-x-5">Save</button>
                        <button type="submit" (click)="rf.saveAndAdd()" [disabled]="rf.view.invalid() "
                          *ngIf="rf.view.isNew()" mat-raised-button color="primary" class="curved m-x-5">Save &
                          Add</button>
                        <button type="submit" (click)="rf.save()" [disabled]="rf.view.invalid()"
                          *ngIf="rf.view.isEdit()" mat-raised-button color="primary"
                          class="curved m-x-5">Update</button>
                      </mat-card-actions>
                    </mat-card-content>
                  </form>
                </mat-card>
              </div>
            </div>
          </div>

          <!-- Feature Process -->
          <div class="row " *ngIf="rfp.view.isForm()">
            <div class="col-sm-5">
              <app-tree-preview [data]="rfp.view" title="Feature Process"></app-tree-preview>
            </div>
            <div class="col-sm-19">
              <div class="workArea">
                <mat-card class="popup">
                  <form [formGroup]="rfp.view.form" novalidate>
                    <div class="popHeader d-flex align-items-center">
                      <span class="ib-m title">Feature Process</span>
                      <div class="spacer"></div>

                      <!-- <button mat-raised-button class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">SmartDev2m</span>
                      </button> -->
                      <button mat-icon-button class="close ib-m" (click)="rfp.cancel()" title="Close"
                        matTooltipPosition="below">
                        <mat-icon fontSet="i" class="i-close"></mat-icon>
                      </button>
                    </div>
                    <mat-card-content class="popContent">
                      <div class="m-b-20" *ngIf="rfp.view.isNew()">
                        <div class="m-b-15 d-flex">
                          <div class="ib-m">
                            <label class="fw"><small>Keyplayer Type</small></label>
                            <p-dropdown [options]="rfp.discoveryKeyplayers" (onChange)="rfp.filterKeyplayer($event)"
                              formControlName="filterKeyplayer" placeholder="Keyplayer" optionLabel="label">
                            </p-dropdown>
                          </div>
                          <div class="spacer"></div>
                          <div class="ib-m">
                            <label class="fw"><small>Requirement Type</small></label>
                            <p-dropdown [options]="rfp.requirementTypeItemCodes" (onChange)="rfp.filterRT($event)"
                              formControlName="filterRequirementType" placeholder="Requirement Type">
                            </p-dropdown>
                          </div>
                        </div>
                        <div class="m-b-15 fw">
                          <label class="fw"><small>Requirements</small></label>
                          <p-dropdown [options]="rfp.requirements" [filter]="true" [style]="{'width':'100%'}"
                            (onChange)="rfp.filterRequirement($event)" formControlName="filterRequirement"
                            placeholder="Requirements" optionLabel="label">
                          </p-dropdown>
                        </div>
                        <div class="fw">
                          <label class="fw"><small>Features</small></label>
                          <p-dropdown [options]="rfp.features" [filter]="true" [style]="{'width':'100%'}"
                            formControlName="conceptRequirementFeatureId" placeholder="Features" optionLabel="label">
                          </p-dropdown>
                          <small class="mat-error"
                            *ngIf="rfp.view.invalid('conceptRequirementFeatureId')">{{rfp.view.error('conceptRequirementFeatureId')}}</small>
                        </div>
                      </div>

                      <div class="m-b-15 d-flex">
                        <div class="ib-m">
                          <label class="fw"><small>Keyplayer Type</small></label>
                          <div class="o-80">
                            {{rfp.view.value('conceptRequirementFeatureId').conceptRequirementId.discoveryRequirementId.keyplayerId.label}}
                          </div>
                        </div>
                        <div class="spacer"></div>
                        <div class="ib-m">
                          <label class="fw"><small>Requirement Type</small></label>
                          <!-- <div class="o-80 ib-m m-r-10">Behavioural and visceral</div> -->
                          <mat-chip-list class="ib-m">
                            <mat-chip color="primary" disabled selected>
                              <span>{{rfp.view.value('conceptRequirementFeatureId').conceptRequirementId.discoveryRequirementId.requirementTypeCode.label}}</span>
                              <span *ngIf="rfp.view.isEdit()">&nbsp;-
                                {{rfp.view.value('conceptRequirementFeatureId').conceptRequirementId.discoveryRequirementId.requirementTypeItemCode.label}}</span>
                            </mat-chip>
                          </mat-chip-list>
                        </div>
                      </div>
                      <div class="m-b-15 fw">
                        <label class="fw"><small>Requirement</small></label>
                        <div class="scrollY max h-120px b-b-2 dotted p-y-5">
                          {{rfp.view.value('conceptRequirementFeatureId').conceptRequirementId.discoveryRequirementId.requirement}}
                        </div>
                      </div>

                      <div class="m-b-15 fw">
                        <label class="fw"><small>Feature</small></label>
                        <div class="m-b-15 scrollY max h-120px b-b-2 dotted p-y-5">
                          {{rfp.view.value('conceptRequirementFeatureId').title}}</div>
                      </div>

                      <mat-form-field class="m-b-15 fw">
                        <mat-label>Feature Process</mat-label>
                        <input matInput placeholder="Feature Process" formControlName="title" maxlength="255">
                        <mat-error *ngIf="rfp.view.invalid('title')">{{rfp.view.error('title')}}</mat-error>
                      </mat-form-field>

                      <mat-form-field class="fw m-b-15">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description" cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                        <mat-error *ngIf="rfp.view.invalid('description')">{{rfp.view.error('description')}}</mat-error>
                      </mat-form-field>

                      <mat-card-actions class="t-c">
                        <button type="button" (click)="rfp.cancel()" mat-raised-button color="warn"
                          class="curved m-x-5">Cancel</button>
                        <button type="submit" (click)="rfp.save()" [disabled]="rfp.view.invalid()"
                          *ngIf="rfp.view.isNew()" mat-raised-button color="primary" class="curved m-x-5">Save</button>
                        <button type="submit" (click)="rfp.saveAndAdd()" [disabled]="rfp.view.invalid() "
                          *ngIf="rfp.view.isNew()" mat-raised-button color="primary" class="curved m-x-5">Save
                          & Add</button>
                        <button type="submit" (click)="rfp.save()" [disabled]="rfp.view.invalid()"
                          *ngIf="rfp.view.isEdit()" mat-raised-button color="primary"
                          class="curved m-x-5">Update</button>
                      </mat-card-actions>

                    </mat-card-content>
                  </form>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Messages" [disabled]="true">
        <div class="conArea">
          Under Construction...
        </div>
      </mat-tab>
      <mat-tab label="Files" [disabled]="true">
        <div class="conArea">
          Under Construction...
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>
  <div class="wrapper" [ngClass]="ai.display ? 'active' : 'hidden'">
    <app-ai [ai]="ai" class="w-100"></app-ai>
  </div>
</div>