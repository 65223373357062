<div class="d-block p-a-0">
  <h4 class="text-accent">
    <button class="closeBtn btn btn-dark btn-link" [mat-dialog-close]="true" title="Close">
      <i class="i i-close1" role="img" aria-hidden="true"></i>
    </button>
  </h4>
  <mat-card-header>
    <!-- <div mat-card-avatar class="b-a-1 t-c">
      <i class="i i-idea i-2_5x text-primary"></i>
    </div> -->
    <mat-card-title>Insights Analysis</mat-card-title>
    <!-- <mat-card-subtitle>Choose right keywords from the list below</mat-card-subtitle> -->
  </mat-card-header>
  <div class="m-x-auto">
    <div class="editableContent" contenteditable="true" #editableDiv (input)="updateHtmlString()"></div>
  </div>
  <div class="w-100 d-flex m-t-30 m-b-20 justify-content-end">
    <button type="button" (click)="copyText()" matRipple class="btn btn-primary btn-lg lighten justify-content-center">
      <i class="i i-copy"></i>
      <span>Copy</span>
    </button>
    <button type="button" (click)="handleButtonClick()" matRipple
      class="btn btn-primary btn-lg lighten justify-content-center m-l-20">
      <i class="i i-favourite"></i>
      <span>Save & Add to Reports</span>
    </button>
  </div>
</div>