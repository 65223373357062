<ul class="smartTree">
  <li class="type-theme {{tree.config?.class}}" id="group-{{tree.key}}" [ngClass]="{
    'disabled': tree.config.disabled
  }" *ngFor="let tree of data; first as isFirst; index as i">

    <ng-template #defaultLayout>
      <div class="helpContent">
        <div class="heading">
          {{tree.name}}
        </div>
        <!-- <p>{{tree.name}} Descriptions</p>
        <div class="note">
          E.g.: - Examples
        </div> -->
      </div>
    </ng-template>

    <div class="headerNode" *ngIf="tree.name !== ''">
      <figure>
        <i class="i {{tree.icon}} i-3x"></i>
        <div class="progress" *ngIf="tree.config.percentage">
          <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="0"
            style="width: 0%;"></div>
        </div>
        <div class="percentage" *ngIf="tree.config.percentage">
          <!-- 0% -->
        </div>
      </figure>
      <span class="title">{{tree.name}}</span>

      <button mat-button class="action btn" matTooltipPosition="after" title="Add New {{tree.name}}"
        (click)="tree.comp.newform()" *ngIf="tree.config?.new && !tree.config?.import">
        <i class="i i-addNew"></i>
      </button>
      <button mat-button class="action btn" matTooltipPosition="after" title="Import {{tree.name}}"
        (click)="tree.comp.newform()" *ngIf="!tree.config?.new && tree.config?.import">
        <i class="i i-import"></i>
      </button>

      <app-quick-help [layout]="temp" [icon]="false" [assistant]="true" *ngIf="quickHelp"></app-quick-help>

      <ng-template #temp>
        <ng-container *ngTemplateOutlet="helpTemplate[i] ? helpTemplate[i]: defaultLayout"></ng-container>
      </ng-template>
    </div>

    <ul *ngIf="!tree.child" [ngClass]="{
      'connector': (tree.name === ''),
      'open': isFirst
    }" class="tree" id="tree-{{tree.key}}" attr.data-group-tree="{{tree.getChildLabelTree()}}"
      attr.data-child-tree="{{tree.getChildLabelTree()}}">
      <li class="connectorTree" *ngFor="let node of tree.view?.list" [ngClass]="{
        'open': tree.activeNode === node.id && node.count > 0
      }">
        <button [disabled]="!tree.config.edit" mat-stroked-button color="primary" class="curved fw"
          (click)="tree.comp.edit(node)" [title]="node.label">
          <!-- <mat-icon fontSet="i" class="i-taste"></mat-icon> -->
          <!-- {{node.label}} - {{node.count}} - {{tree.key}} -->
          {{node.label}}
        </button>
        <button mat-icon-button class="nodeBtn" title="Add New {{tree.config.childName | titlecase}}"
          matTooltipPosition="after" *ngIf="(!tree.config?.nodeImport) && (node.count < 1 && tree.config.childId!=='')"
          attr.data-target-tree="{{tree.config.multi? tree.getChildLabelTree() : tree.getChildLabelTree(node.child)}}"
          (click)="newConnectNode($event, node, tree.config.childId, tree)">
          <mat-icon fontSet="i" class="i-addNew"></mat-icon>
        </button>
        <button mat-icon-button class="nodeBtn" title="Import {{tree.config.childName | titlecase}}"
          matTooltipPosition="after" *ngIf="tree.config?.nodeImport && (node.count < 1 && tree.config.childId!=='')"
          attr.data-target-tree="{{tree.config.multi? tree.getChildLabelTree() : tree.getChildLabelTree(node.child)}}"
          (click)="newConnectNode($event, node, tree.config.childId, tree)">
          <mat-icon fontSet="i" class="i-import"></mat-icon>
        </button>

        <button mat-icon-button class="nodeBtn collapser" *ngIf="node.count > 0 && tree.config.childName !== ''"
          (click)="toggleTree($event, node, data, tree)"
          attr.data-target-tree="{{tree.config.multi? tree.getChildLabelTree() : tree.getChildLabelTree(node.child)}}">
          <mat-icon fontSet="i" [ngClass]="tree.activeNode === node.id ? 'i-minus': 'i-plus'"></mat-icon>
        </button>
      </li>
    </ul>

    <!-- Child Tree -->
    <ul [ngClass]="{
      'connector': (childtree.name === ''),
      'open': isFirst
    }" *ngFor="let childtree of tree.child" class="tree" id="tree-{{childtree.key}}"
      attr.data-group-tree="{{childtree.getChildLabelTree()}}" attr.data-child-tree="{{childtree.getChildLabelTree()}}">
      <li class="connectorTree" *ngFor="let cnode of childtree.view?.list" [ngClass]="{
        'open': childtree.activeNode === cnode.id && cnode.count > 0
      }">
        <button [disabled]="!tree.config.edit" mat-stroked-button color="primary" class="curved fw"
          (click)="childtree.comp.edit(cnode)" [title]="cnode.label">
          <!-- {{cnode.label}} - {{cnode.count}} -->
          {{cnode.label}}
        </button>
        <button mat-icon-button class="nodeBtn" title="Add New {{childtree.config.childName | titlecase}}"
          matTooltipPosition="after"
          *ngIf="(!tree.config?.nodeImport) && (cnode.count < 1 && childtree.config.childId!=='')"
          attr.data-target-tree="{{tree.getChildLabelTree()}}"
          (click)="newConnectNode($event, cnode, childtree.config.childId, childtree)">
          <mat-icon fontSet="i" class="i-addNew"></mat-icon>
        </button>
        <button mat-icon-button class="nodeBtn" title="Import {{childtree.config.childName | titlecase}}"
          matTooltipPosition="after"
          *ngIf="tree.config?.nodeImport && (cnode.count < 1 && childtree.config.childId!=='')"
          attr.data-target-tree="{{tree.getChildLabelTree()}}"
          (click)="newConnectNode($event, cnode, childtree.config.childId, childtree)">
          <mat-icon fontSet="i" class="i-import"></mat-icon>
        </button>
        <button mat-icon-button class="nodeBtn" *ngIf="cnode.count > 0 && childtree.config.childId!==''"
          (click)="toggleTree($event, cnode, data, childtree)" attr.data-target-tree="{{tree.getChildLabelTree()}}">
          <mat-icon fontSet="i" [ngClass]="childtree.activeNode === cnode.id ? 'i-minus': 'i-plus'"></mat-icon>
        </button>
      </li>
    </ul>

    <div class="footerNode" *ngIf="tree.config.pagination" [ngClass]="{
      'hidden': (tree.view?.list?.length < 5 && tree?.child?.view?.list?.length < 5)
    }">
      <button mat-icon-button color="primary" matTooltipPosition="below" title="Next">
        <mat-icon fontSet="i" class="i-keyboard_arrow_down" aria-label="Next"></mat-icon>
      </button>
      <button mat-icon-button color="primary" matTooltipPosition="below" title="Previous">
        <mat-icon fontSet="i" class="i-keyboard_arrow_up" aria-label="Previous"></mat-icon>
      </button>
    </div>
  </li>
</ul>