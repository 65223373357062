import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { View, Config, FormDefault } from '../../config';
import { Requirement } from '../../_models/requirement';
import { SharedService } from './shared.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';

@Injectable({
  providedIn: 'root'
})
export class RequirementFeatureProcessView {
  url = 'analysisrequirementfeatureprocess';

  public view: View;

  discoveryKeyplayers;
  requirementTypeItemCodes;
  requirements;
  requirementsAll;
  features;
  featuresAll;

  constructor(
  
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    public ss: SharedService) {
    this.view = new View(http, router, route, alertService);
    this.ss.setRequirementFeatureProcessView(this);
    this.view.refreshList = false;
   
    // this.setForm();
  }

  getNewDefault() {
  
  
    if (this.ss.re === null) {
      return null;
    }
    return new FormDefault(
      ['analysis_requirement_id'], [this.ss.requirementFeature]);
  }

  setForm(load=true) {
     //console.log('rfp setform');
    this.view.initForm(this.url, this.getForm(), this.getNewDefault(), false);
    Config.isProjectSelected = true;
    this.view.state = 'list';
        //console.log('rfp setform', this.view.state);
    this.discoveryKeyplayers = null;
    if (load)
    {
    this.initData();
    }
  }

  newform() {
    this.view.newform();
  }

  edit(row) {
    this.view.edit(row);
  }

  save() {
  //  console.log(this.view.form.value);
    this.view.set('label', this.view.value('title'));
    this.view.saveCallback(() => {
      // this.ss.getRequirementFeatureView().getList();
      this.view.state = 'edit';
    });
  }

  saveAndAdd() {
    this.view.set('label', this.view.value('title'));
    this.view.saveAndAddCallback(() => {
      // this.ss.getRequirementFeatureView().getList();
    });
    // this.view.saveAndAdd();
  }

  cancel() {
    this.ss.getRequirementFeatureView().getList();
    this.getList();
    this.view.cancel();
  }

  getList() {
   
    this.view.get(`${this.url}/all/${this.ss.requirement.id}`).subscribe(res => {
      this.view.list = res;
    });
  }

  getForm() {
   //console.log('test log here');
    return this.fb.group({
      id: [''],
      conceptRequirementId: [''],
      title: ['', Validators.required],
      description: [''],
     analysisRequirementId : ['', Validators.required],
      filterRequirement: [''], // dummy
      filterRequirementType: [''], // dummy
      filterKeyplayer: [''] // dummy
      
    });
     
  }

  private initData() {
 //console.log('rfp init data');
    this.view.get(`analysisrequirementfeature/${this.ss.analysis.id}`).subscribe(res => {
      this.features = res;
      this.featuresAll = res;
    });

    this.view.get(`analysisrequirement/${this.ss.analysis.id}`).subscribe(res => {6
      this.requirements = res;
      this.requirementsAll = res;
    });

    if (this.discoveryKeyplayers === null) {
      this.view.get(`discoverykeyplayer/concept/${this.ss.getProjectId()}/${this.ss.concept.id}`).subscribe(res => {
        this.discoveryKeyplayers = res;
      });
      this.view.get(`requirementtypeitem/all`).subscribe(res => {
        this.requirementTypeItemCodes = res;
          for (let x = 0; x < this.requirementTypeItemCodes.length; x++) {
        let item = this.requirementTypeItemCodes[x];
        item['value'] = item['id'];;
      }
      });
    }
  }

  filterRequirementType(event) {
   
    this.requirements = this.requirementsAll.filter(u => u.discoveryRequirementId.requirementTypeItemCode.id === event.value);
    const selKp = this.view.value('filterKeyplayer', null);
    if (selKp !== null) {
      this.requirements = this.requirements.filter(u => u.discoveryRequirementId.keyplayerId.id === selKp.id);
    }
    this.view.set('filterRequirement', null);
    this.features = null;
  }

  filterKeyplayer(event) {
    this.requirements = this.requirementsAll.filter(u => u.discoveryRequirementId.keyplayerId.id === event.value.id);
    const selRt = this.view.value('filterRequirementType', null);
    if (selRt !== null) {
      this.requirements = this.requirements.filter(u => u.discoveryRequirementId.requirementTypeItemCode.id === selRt.id);
     
    }
    this.view.set('filterRequirement', null);
    this.features = null;
  }

  filterRequirement(event) {
    
    this.reloadFeature(event.value.id);
  }

  private reloadFeature(reqId) {
    //console.log(reqId,this.featuresAll);
    if (reqId !== null) {
      
      this.features = this.featuresAll.filter(u => u.conceptRequirementId.id === reqId);
    } else {
      this.features = null;
    }
  }

}
