<div class="d-block p-a-0">
  <h4 class="text-accent">
    <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" (click)="backtoPage()">
      <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
    </button>
  </h4>
  <mat-card-header>
    <div mat-card-avatar class="b-a-1 t-c">
      <i class="i i-patent i-2_5x text-primary"></i>
    </div>
    <mat-card-title>InsIQ Patent Info</mat-card-title>
    <mat-card-subtitle>{{data.charttitle}}</mat-card-subtitle>
  </mat-card-header>
  <div class="mg-brick">
    <mat-card class="m-b-30 privilaged b-patent">
      <div class="w-100">
        <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="data.patentCountry !== ''">
          <mat-icon fontSet="i" class="i-pin_drop i-1_5x ib-m"></mat-icon>
          <span class="ib-m">{{data.patentCountry}}</span>
        </div>
        <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="data.patentDomain !== ''">
          <mat-icon fontSet="i" class="i-domain i-1_5x ib-m"></mat-icon>
          <span class="ib-m">{{data.patentDomain}}</span>
        </div>
      </div>
      <mat-card-content>
        <div class="w-100">
          <!-- <div class="m-b-5">{{row.aiNameTemp}}</div> -->
          <div class="m-t-5" innerHTML={{data.patentClaim}}></div>
          <div class="ib-m"></div>
        </div>

        <!-- <div *ngIf="ai.notesviewflag" href="javascript:;" (click)="ai.addOrEditNotes(row)"
          title="Click to Add/Edit Note" matTooltipPosition="below" class="notes">{{row.noteAdd}}
        </div> -->
      </mat-card-content>
    </mat-card>
  </div>
<!-- Bricks for Support -->
<!-- <div class="mg-brick h-120px"></div>
<div class="mg-brick h-120px"></div>
<div class="mg-brick h-120px"></div> -->
<!-- Bricks for Support -->
</div>