import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  OnInit,
  Component,
  ViewChild
} from '@angular/core';
import { Config } from '../../config';
import { SharedService } from './shared.service';
import { TreeData } from '../../_models/treedata';
import { AnalysisView } from './analysis.view';
import { RequirementView } from './requirement.view';
import { RequirementTypeView } from './requirementtype.view';
import { RequirementGroupView } from './requirementgroup.view';
import { FeatureView } from './feature.view';
import { FailureView } from './failure.view';
import { TestingView } from './testing.view';
import { RequirementFeatureView } from './requirement-feature.view';
import { RequirementFeatureProcessView } from './requirement-feature-process.view';
import { AIView } from '../ai/insight.view';

enum RiskGroup {
  failures,
  features,
  testing
}

enum Risk {
  designConcept,
  requirementType,
  requirement,
  riskGroup,
  riskGroupDetail
}

@Component({
  selector: 'app-risk-analysis',
  templateUrl: './risk-analysis.component.html',
  styleUrls: ['./risk-analysis.component.scss']
})
export class RiskAnalysisComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data = new Array;
  header: any;
  active: any;
  importances;
  constructor(
    public ai: AIView,
    public ao: AnalysisView,
    public rt: RequirementTypeView,
    public rg: RequirementGroupView,
    public re: RequirementView,
    public fe: FeatureView,
    public fa: FailureView,
    public tv: TestingView,
    public rf: RequirementFeatureView,
    public rfp: RequirementFeatureProcessView,
    public ss: SharedService,
    public config: Config
  ) {
    this.ss.projectId = null;
    this.ao.setForm();
    this.rt.setForm();
    this.rg.setForm();
    this.re.setForm();
    this.fe.setForm();
    this.fa.setForm();
    this.tv.setForm();
    this.rf.setForm(false);
    this.rfp.setForm(false);
    re.view.get('importance/all').subscribe(res => {
      this.importances = res;
      // //console.log( this.importances);
      for (let x = 0; x < this.importances.length; x++) {
        const item = this.importances[x];
        item['value'] = item['title'];
        item['label'] = item['title'];
      }
    });
    this.header = {
      percentage: 10,
      title: 'Risk Analysis',
      projectName: this.ss.project.productName
    };
    this.data[Risk.designConcept] = new TreeData(this.ss.getAnalysisView(), {
      name: 'Design Analysis',
      key: 'designAnalysis',
      children: 'requirementType',
      disabled: false,
      new: false,
      import: true
    });

    this.data[Risk.requirementType] = new TreeData(this.ss.getRequirementTypeView(), {
      name: 'Requirement Type',
      key: 'requirementType',
      children: 'requirement',
      new: false,
      edit: false,
      multi: true,
      import: false,
      nodeIcon: true,
      nodeImport: false,
      percentage: false
    });

    this.data[Risk.requirement] = new TreeData(this.ss.getRequirementView(), {
      name: 'Requirements',
      key: 'requirement',
      children: 'riskGroup',
      new: false,
      import: false
    });

    this.data[Risk.riskGroup] = new TreeData(this.ss.getRequirementGroupView(), {
      name: 'Requirement Group',
      new: false,
      edit: false,
      key: 'riskGroup',
      children: 'riskGroupDetail'
    });

    this.data[Risk.riskGroupDetail] = new TreeData(null, {
      name: '?',
      key: 'riskGroupDetail',
      children: ''
    });
    this.data[Risk.riskGroupDetail].child = [
      new TreeData(this.ss.getFailureView(), {
        name: 'Failure',
        key: 'failures',
        children: ''
      }),
      new TreeData(this.ss.getFeatureView(), {
        name: 'Feature',
        key: 'features',
        children: ''
      })
      ,
      new TreeData(this.ss.getTestingView(), {
        name: 'Testing',
        key: 'testing',
        children: ''
      })
    ];
  }

  ngOnInit() {
    this.ao.view.state = 'list';
    this.rt.view.state = 'list';
    this.rg.view.state = 'list';
    this.re.view.state = 'list';
    this.fe.view.state = 'list';
    this.fa.view.state = 'list';
    this.tv.view.state = 'list';
    this.rf.view.state = 'list';
    this.rfp.view.state = 'list';
  }

  isList() {
    return !(
      this.ao.view.isForm() ||
      // this.rt.view.isForm() ||
      // this.rg.view.isForm() ||
      this.re.view.isForm() ||
      this.fa.view.isForm() ||
      this.fe.view.isForm() ||
      this.tv.view.isForm()
    );
  }

  //  openRequirementTab() {
  //    this.re.selectedTab = 1;
  //    this.re.initForm();
  //    this.re.newform();
  //  }

  nodeCloser(fields, data = this.data) {
    for (let x = 0; x < fields.length; x++) {
      const item = fields[x];
      if (item === Risk.requirementType) {
        this.rt.view.list = null;
        data[Risk.requirementType].activeNode = null;
      } else if (item === Risk.requirement) {
        this.re.view.list = null;
        data[Risk.requirement].activeNode = null;
      } else if (item === Risk.riskGroup) {
        this.rg.view.list = null;
        data[Risk.riskGroup].activeNode = null;
      } else if (item === Risk.riskGroupDetail) {
        this.fe.view.list = null;
        this.fa.view.list = null;
        this.tv.view.list = null;
        data[Risk.riskGroupDetail].activeNode = null;
      }
    }
  }
  nodeCloseManager(key, data) {
    // console.log(key);
    switch (key) {
      case 'designAnalysis':
        this.nodeCloser([Risk.riskGroupDetail, Risk.riskGroup, Risk.requirement, Risk.requirementType], data);
        break;
      case 'requirementType':
        this.nodeCloser([Risk.riskGroupDetail, Risk.riskGroup, Risk.requirement], data);
        break;
      case 'requirement':
        this.nodeCloser([Risk.riskGroupDetail, Risk.riskGroup], data);
        break;
      case 'riskGroup':
        this.nodeCloser([Risk.riskGroupDetail], data);
        break;
      default:
        this.nodeCloser([RiskGroup.features, Risk.requirement, Risk.requirementType], data);
        break;
    }
  }

  onNodeOpen(e) {
    const [/*target*/, key, node, /* data */] = e;
    // //console.log(key, node);
    if (key === 'designAnalysis') {
      this.ss.analysis = node;
      this.ss.getRequirementTypeView().getList();
    } else if (key === 'requirementType') {
      // console.log('hereeezzzz');
      this.ss.requirementType = node;
      this.ss.getRequirementView().setForm();
      this.ss.getRequirementView().getList();

    } else if (key === 'requirement') {
      this.ss.requirement = node;
      this.ss.getRequirementGroupView().setForm();
      this.ss.getRequirementGroupView().getList();
    } else if (key === 'riskGroup') {
      // console.log('hereee', node.id);
      // TODO set requirment risk group to ss if needed
      this.data[Risk.riskGroupDetail].name = node.label;
      this.data[Risk.riskGroupDetail].icon = this.data[Risk.riskGroup].getIcon(node.id);
      if (node.id === RiskGroup.failures) {
        this.data[Risk.riskGroupDetail].comp = this.data[Risk.riskGroup].child[RiskGroup.failures].comp;
        this.ss.getFailureView().setForm();
        this.ss.getFailureView().getList();
      } else if (node.id === RiskGroup.features) {
        this.data[Risk.riskGroupDetail].comp = this.data[Risk.riskGroup].child[RiskGroup.features].comp;
        this.ss.getFeatureView().setForm();
        this.ss.getFeatureView().getList();
      } else if (node.id === RiskGroup.testing) {
        this.data[Risk.riskGroupDetail].comp = this.data[Risk.riskGroup].child[RiskGroup.testing].comp;
        this.ss.getTestingView().setForm();
        this.ss.getTestingView().getList();
      }
    }
  }

  onNodeClose(e) {
    const [/*target*/, key, /* node */, data] = e;
    this.nodeCloseManager(key, data);
  }

  newConnectNode(e) {
    let [node, key] = e;
    node = (node) ? node : '';
    key = key;
    // console.log(key, node);
    if (key === 'requirement') {
      this.ss.requirementType = node;
      this.ss.getAnalysisView().setForm();
      this.nodeCloser([Risk.riskGroupDetail, Risk.riskGroup, Risk.requirement]);
      this.ss.getAnalysisView().newform();
    } else if (key === 'riskGroup') {
      // console.log('risk group');
      this.ss.requirementgroup = node;
      this.ss.getRequirementGroupView().getList();
      this.nodeCloser([Risk.riskGroupDetail, Risk.riskGroup]);
    } else if (key === 'riskGroupDetail') {
      // //console.log(node.id, RiskGroup.failures);
      // this.data[Risk.riskGroupDetail].name = node.label;
      // this.data[Risk.riskGroupDetail].icon = this.data[Risk.riskGroupDetail].getIcon(node.id);
      // this.nodeCloser([Risk.riskGroupDetail]);
      // if (node.id === 'failures') {
      //   this.data[Risk.riskGroupDetail].comp = this.data[Risk.riskGroupDetail].child[RiskGroup.failures].comp;
      //   this.ss.getFailureView().setForm();
      //   this.ss.getFailureView().newform();
      // } else if (node.id === 'features') {
      //   this.data[Risk.riskGroupDetail].comp = this.data[Risk.riskGroupDetail].child[RiskGroup.features].comp;
      //   this.ss.getFeatureView().setForm();
      //   this.ss.getFeatureView().newform();
      // } else if (node.id === 'testing') {
      //   this.data[Risk.riskGroupDetail].comp = this.data[Risk.riskGroupDetail].child[RiskGroup.testing].comp;
      //   this.ss.getTestingView().setForm();
      //   this.ss.getTestingView().newform();
      // }
    }
  }
}
