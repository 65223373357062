import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.html',
  styleUrls: ['confirmation-dialog.scss']
})
export class ConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public router: Router,

  ) {
  }

  backtoPage() {
    this.dialogRef.close();
  }
  backtoHome() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }
  getIcon(s, title) {
    // console.log(s);
    // console.log(title);

    // console.log(s.toLowerCase());
    try {
      if (s === undefined) {
        return 'deep_dive';
      } else {
        switch (s.trim().toLowerCase()) {
          case 'similar products or projects':
            if (title.includes('Curated')) {
              return 'product_box_star';
            } else {
              return 'product_box';
            }
          case 'consumer information':

            if (title.includes('Curated')) {
              return 'trends_star';
            } else {
              return 'trends';
            }


          case 'product news':
            if (title.includes('Curated')) {
              return 'news_star';
            } else {
              return 'news';
            }

          case 'suppliers and manufacturers':
            if (title.includes('Curated')) {
              return 'factory_star';
            } else {
              return 'factory';
            }
          case 'key influencers, professionals':
            if (title.includes('Curated')) {
              return 'professional_star';
            } else {
              return 'professional';
            }
          case 'product reviews':
            if (title.includes('Curated')) {
              return 'review_star';
            } else {
              return 'review';
            }
          case 'regulatory and compliance':
            if (title.includes('Curated')) {
              return 'regulatory_star';
            } else {
              return 'regulatory';
            }
          case 'patents and trademarks':
            if (title.includes('Curated')) {
              return 'patent_star';
            } else if (title.includes('Trademark')) {
              return 'tm';
            } else {
              return 'patent';
            }
          case 'b2b':
            if (title.includes('Curated')) {
              return 'b2b_star';
            } else {
              return 'b2b';
            }
          case 'InsIQ Trends Interest over Time':
            return 'trending_up';
          default:
            if (title.includes('Curated')) {
              return 'trending_up';
            } else {
              return 'trending_up';
            }
        }
      }
    } catch (exception) {
    }
  }
  getImg(chType: any) {
    switch (chType) {
      case 'InsIQ Trends Interest over Time':
        return 'trendschart.gif';
      default:
        return 'catchart.gif';
    }
  }
}
