<div class="d-block p-a-0">
    <h4 class="text-accent">
        <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" [mat-dialog-close]="true" title="Close">
            <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
        </button>
    </h4>
    <mat-card-header>
        <div mat-card-avatar class="b-a-1 t-c">
            <i class="i i-idea i-2_5x text-primary"></i>
        </div>
        <mat-card-title>InsIQ Keyword Suggestions</mat-card-title>
        <mat-card-subtitle>Choose right keywords from the list below


        </mat-card-subtitle>
    </mat-card-header>
    <div class="m-x-auto">
        <div class="w-100 d-flex align-items-center justify-content-center">
            <mat-card class="h-50px p-a-10 m-r-15" color="accent" style="border-radius: 28px;">
                <div class="d-flex">
                    <div class="ib-m">
                        <mat-chip-list aria-label="Insight categories">
                            <mat-chip color="primary" style="cursor: pointer;" (click)="ai.selectkeyword(row, i)"
                                matTooltipPosition="below" title="{{row}}"
                                *ngFor="let row of ai.smartKeywordSuggestion; index as i;">

                                {{getKeyText(row)}}
                            </mat-chip>
                        </mat-chip-list>

                    </div>
                </div>
                <mat-card-subtitle class="m-t-30 m-b-20 f-bold" *ngIf="ai.selectedKeywordSuggestion.length!=0">Selected
                    Keywords
                </mat-card-subtitle>

                <div class="d-flex">
                    <div class="ib-m">
                        <mat-chip-list aria-label="Insight categories">
                            <mat-chip color="primary" style="cursor: pointer;" [selected]="true"
                                (click)="ai.removekeyword(row, i)" matTooltipPosition="below" title="{{row}}"
                                *ngFor="let row of ai.selectedKeywordSuggestion; index as i;">

                                {{getKeyText(row)}} <mat-icon fontSet="i" class="i-cancel i-1_3x ib-m m-l-5 m-t-5">
                                </mat-icon>
                            </mat-chip>
                        </mat-chip-list>

                    </div>
                </div>
            </mat-card>

        </div>
    </div>
    <div class="w-100 d-flex align-items-center m-t-30 m-b-20 justify-content-center">

        <button type="button" (click)="backtoPage()" matRipple
            class="btn btn-lg btn-default lighten m-x-5 btn-cancel">Cancel</button>


        <button type="button" (click)="saveKeyword()" matRipple class="btn btn-lg btn-primary m-x-5"
            [disabled]="ai.selectedKeywordSuggestion.length==0">Save</button>

    </div>
</div>