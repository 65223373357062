import { Component, OnInit, Input, TemplateRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-quick-help',
  templateUrl: './quick-help.component.html',
  styleUrls: ['./quick-help.component.scss']
})
export class QuickHelpComponent implements OnInit {
  @Input() layout: TemplateRef<any>;
  @Input() helpLink: string;
  @Input() helpVideo: string;

  @Input() icon: boolean;
  @Input() cssClass: string;
  @Input() tour: boolean;
  @Input() assistant: boolean;
  @Input() align: string;

  opened: boolean;

  constructor(@Inject(DOCUMENT) private document) {
    this.icon = true;
    this.tour = false;
    this.assistant = false;
  }

  hoverIn() {
    this.opened = true;
  }

  hoverOut() {
    this.opened = true;
  }

  ngOnInit() {
    if (document.querySelectorAll('.quick-tip .tour').length > 0) {
      setTimeout(() => {
        document.querySelectorAll('.quick-tip .tour')[0].classList.add('animated');
        setTimeout(() => {
          if (document.querySelectorAll('.quick-tip .tour').length > 0) {
            document.querySelectorAll('.quick-tip .tour')[0].classList.remove('animated');
          }
        }, 5000);
      }, 500);
    }
    // console.log(this.layout);
  }

}
