import {
  NgZone,
  OnInit,
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup
} from '@angular/forms';
import {
  style,
  trigger,
  animate,
  keyframes,
  transition
} from '@angular/animations';
import {
  View,
  Config,
  FormDefault
} from '../../config/global.config';

import { get } from 'http';
import { OAuthSettings } from './oauth';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../_models/user';
import { AIView } from '../ai/insight.view';
import { MatSort } from '@angular/material/sort';
import { AlertService } from 'src/app/_services';
import { UserProject } from '../../_models/userproject';
import { AlertsService } from '../profile/alerts.service';
import { Client } from '@microsoft/microsoft-graph-client';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SharedProjectService } from '../project/sharedproject.service';
import { MyErrorStateMatcher } from '../../_directives/validator.directive';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Msal from '@azure/msal-browser';
import { CustomThemeService } from 'src/app/custom-theme.service';
import { CloneprojectComponent } from '../cloneproject/cloneproject.component';
import { NavigationService } from 'projects/tag-cloud/src/lib/navigation-service';
const msalConfig = {
  auth: {
    clientId: "fec2ff3d-3c5b-4573-8011-730a3af0b708",

  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  animations: [
    trigger('pulse', [
      transition('inactive => active', animate('1.5s ease-out', keyframes([
        style({ transform: 'scale3d(0.5, 0.5, 0.5)', offset: 0 }),
        style({ transform: 'scale3d(1.2, 1.2, 1.2)', offset: .4 }),
        style({ transform: 'scale3d(1, 1, 1)', offset: 1 })
      ]))),
    ])
  ]
})

export class ProjectComponent implements OnInit, AfterViewInit {
  @Output() linkEvent = new EventEmitter<any>();
  initialFormValues: any; // Store initial form values here
  pageId = 0;
  url = 'mschannel';
  costsTime = false;
  isPrjAdmin = true;
  homeFlag = true;
  isAdminUrl = false;
  sentimentDisable = true;
  reportflag: boolean;
  cacheflag: boolean;
  app;
  projSetting = false;
  cardDisplay = false;
  flSelected = 199;
  favflag: boolean;
  channelflag: boolean;
  animateState = 'inactive';
  public authenticated: boolean;
  userDataTable = new MatTableDataSource([]);
  videoUrl = '//www.youtube.com/embed/' + environment.help.videoId;
  @ViewChild('docReport', { static: false }) docReport: ElementRef;
  matcher = new MyErrorStateMatcher();
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  cloneDialogRef: MatDialogRef<CloneprojectComponent, any>;
  selectedButton: number = null;
  researchMode = false;
  turboMode = false;
  messages;
  constructor(
    public ai: AIView,
    public view: View,
    public zone: NgZone,
    public config: Config,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    public msaccountview: View,
    public mschannelview: View,
    private ssp: SharedProjectService,
    private alertService: AlertService,
    private alertsService: AlertsService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public customThemeService: CustomThemeService,
    public cloneDialog: MatDialog,
    private route: ActivatedRoute,
    private navigationService: NavigationService

  ) {
    //console.log('jjfg')
    this.app = new Msal.PublicClientApplication(msalConfig);
    this.flSelected = 199;
    this.channelflag = false;
    this.reportflag = false;
    this.favflag = false;
    this.cacheflag = false;
    this.sentimentDisable = true;
    this.cardDisplay = false;
    ssp.pro = this;
    // console.log(localStorage.getItem('projLoc'))
    // if (localStorage.getItem('projLoc') === 'header') {
    //   this.homeFlag = false;
    //   localStorage.setItem('projLoc', "done");

    // }
    // else {
    this.homeFlag = true;

    // }

    this.projSetting = false;

    this.view.initForm('project', this.getNewForm(), this.getNewDefault(), false);
    this.view.refreshList = false;
    // console.log(this.router.url)
    this.view.state = (this.router.url === '/new-project') ? 'new' : 'list';
    if (this.view.isNew()) {
      this.autoModeSelect();
      this.newform();
    }
    // this.mschannelview = new View(http, router, route, alertService);
    // this.feedbackForm.controls.userId.setValue(config.getUsername());
    // this.view.set('mode', 'Research Mode');
    this.view.form.value.mode = null

  }

  filteredBrands: any[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // importance: number;
  userList: any[];
  userListAll: any;
  userRoleList: any; // For user role combo
  userSelected: any[]; // temp variable to select users
  userRoleSelected: any; // temp variable to set the selected role
  showvideo = false;
  designModule = false;
  researchModule = false;
  riskanalysismodule = false;
  dynamiclogo = false;
  imageUrl;
  userFiltered: User[];
  showUserToProject = false;
  catSelection = false;
  // currentUser;
  s: String;
  productTypes: any[];
  hasMs = false;
  minDate: Date;
  tourflag = false;
  countryList: any;
  filterdProject: any[];
  selectedProject;
  chatSequence: number = 0;

  newProjectUser: boolean;
  title: any;
  apiLanguage: any;
  projId: any;
  selectedLanguage: any;
  ngAfterViewInit() {
    // this.view.initPage(this.paginator, this.sort);+
    //    this.userDataTable.data = this.refreshUserDataTable();
    this.userDataTable = new MatTableDataSource(this.getUserDataTable());

    this.changeDetectorRef.markForCheck();
  }

  getUserDataTable() {
    return this.view.form.get('projectUserList')?.value;
  }

  refreshUserDataTable() {
    this.userDataTable.data = this.getUserDataTable();
  }

  triggerAnimation() {
    // console.log('trigger', this.animateState);
    this.animateState = 'active';
  }

  resetAnimation() {
    // console.log('reset', this.animateState);
    this.zone.run(() => {
      this.animateState = 'inactive';
    });
  }

  animationDone(event: AnimationEvent) {
    // console.log(event);
    // if (event.fromState === 'active' && event.toState === 'inactive') {
    //   console.log('DONE...');
    // }
  }

  keywordSuggest(view) {
    let retval = this.ai.keywordSuggest(view, false, this.flSelected);
    // console.log('Thirumbi vanne');
    // console.log(retval);
  }
  newValUpdate(newVal) {
    // console.log(newVal);
  }
  selectProject(e: any, project: any) {
    // console.log(e)
    // console.log(project)
    //this.edit(project);

    if (
      (e.target.parentNode.nodeName !== 'BUTTON') &&
      (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'A')
    ) {
      this.edit(project);
      // Config.isProjectSelected = true;
      // this.router.navigate(['/dashboard']);
      // this.router.navigate(['/discovery']);
    } else {
      return;
    }
  }
  selectButton(buttonNumber: number) {
    this.selectedButton = buttonNumber;
  }
  getFormValues() {
    return {
      productName: this.view.form.get('productName').value.trim(),
      productDescription: this.view.form.get('productDescription').value.trim(),
      brandName: this.view.form.get('brandName').value.trim(),
    };
  }
  getNewForm() {
    return this.fb.group({
      id: [''],
      msteam: ['1'],
      msid: [],
      mode: [''],
      productName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      productNewOrExisting: [1, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      productDescription: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      productTypeId: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // productCategory: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      customer: [],
      targetRegion: [],
      productBenifit: [],
      similarProduct: [],
      inspiration: [],
      brandName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      competitors: [],
      competitorspdt: [],
      companyname: [],
      // projectDueDate: ['', Validators.required],
      projectOwner: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      projectUserList: [],
      status: ['1'],
      apiLanguage: [''],
      problemSolve: [],
      productEnviornment: [],
      productChannel: [],
      prodnVol: [],
      manufacturer: [],
      productRegulation: [],
      sellRate: [],
      projectPin: [],
      whatDoYouWantToDo: '',
      keyword31: [],
      keyword6: [],
      keyword10: [],
      keyword2: [],
      keyword32: [],
      keyword8: [],
      keyword11: [],
      keyword7: [],
      keyword5: [],
      keyword4: [],
      keyword9: [],

    },);
  }
  checkProductName(group: FormGroup) { // here we have the 'passwords' group
    const category = group.controls.productNewOrExisting.value?.toString()?.trim();
    const brandName = group.controls.brandName.value?.toString()?.trim();

    return (brandName === null || brandName === '' || brandName === undefined) ? { notSame: true } : null;

  }

  getNewMSChannelForm() {
    // return this.feedbackForm;
    return this.fb.group({
      id: [],
      userId: [''],
      msid: [''],
      projectid: [''],
      channelid: [''],
      displayName: [''],
      description: [''],
      url: ['']
    });
  }

  flSelector(fl) {
    if ((this.view.value('productDescription') == null || this.view.value('productDescription')?.toString()?.trim() === '')) {
      this.view.alertService.error('Please Fill Keywords of your Product / Idea before selecting category');
    } else {
      this.ai.filtervalue = fl;
      this.ai.keywordsel = +fl;
      this.flSelected = fl;
      const productDescriptionValue = this.view.value('productDescription')?.trim() ?? undefined;

      if (this.flSelected !== 0 && this.flSelected !== 199 && this.flSelected !== 101) {
        //console.log('hereeeee')
        if (this.flSelected == 31) {
          if (this.view.value('keyword31') === '' || this.view.value('keyword31') === null || this.view.value('keyword31') === 'null') {

            this.view.set('keyword31', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 6) {

          if (this.view.value('keyword6') === '' || this.view.value('keyword6') === null || this.view.value('keyword6') === 'null') {

            this.view.set('keyword6', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 10) {

          if (this.view.value('keyword10') === '' || this.view.value('keyword10') === null || this.view.value('keyword10') === 'null') {

            this.view.set('keyword10', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 2) {

          if (this.view.value('keyword2') === '' || this.view.value('keyword2') === null || this.view.value('keyword2') === 'null') {

            this.view.set('keyword2', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 32) {

          if (this.view.value('keyword32') === '' || this.view.value('keyword32') === null || this.view.value('keyword32') === 'null') {

            this.view.set('keyword32', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 8) {

          if (this.view.value('keyword8') === '' || this.view.value('keyword8') === null || this.view.value('keyword8') === 'null') {

            this.view.set('keyword8', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 11) {

          if (this.view.value('keyword11') === '' || this.view.value('keyword11') === null || this.view.value('keyword11') === 'null') {

            this.view.set('keyword11', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 7) {

          if (this.view.value('keyword7') === '' || this.view.value('keyword7') === null || this.view.value('keyword7') === 'null') {

            this.view.set('keyword7', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 5) {

          if (this.view.value('keyword5') === '' || this.view.value('keyword5') === null || this.view.value('keyword5') === 'null') {

            this.view.set('keyword5', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 4) {

          if (this.view.value('keyword4') === '' || this.view.value('keyword4') === null || this.view.value('keyword4') === 'null') {

            this.view.set('keyword4', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (this.flSelected == 9) {

          if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

            this.view.set('keyword9', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        }

      }

    }
  }

  flSelectorimg(fl, event) {
    this.flSelected = fl;
    const target = event.currentTarget as HTMLElement;

    // // Reset background for all menu items
    const menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach((menuItem) => {
      (menuItem as HTMLElement).classList.remove('active');
    });

    // Set background for the clicked menu item
    // target.style.backgroundColor = 'grey';
    target.classList.add('active');
  }
  insightGenerator(view) {
    this.ai.dashCharts = [];

    if (this.view.value('mode') == 'Turbo Mode') {
      // console.log('heree');
      // console.log(this.chatSequence);
      // this.chatSequence = this.getHighestDtSequence() + 1; // Update chatSequence

      this.cardDisplay = false;
      if (this.flSelected == 0) {
        this.smartDev(this.flSelected, view, true);

      } else {
        this.smartDev(this.flSelected, view);

      }

    } else {
      this.cardDisplay = false;
      if (this.flSelected == 0) {
        this.smartDev(this.flSelected, view, true);

      } else {
        this.smartDev(this.flSelected, view);

      }
    }

  }
  getProjName(project) {

    let projName: any;
    if (project.productNewOrExisting === 1) {
      // projName = project.productName;
      projName = project.brandName || project.productName;

    } else if (project.productNewOrExisting === 2) {
      projName = project.brandName;
    }
    return projName;
  }
  ngOnInit() {

    const data = JSON.parse(localStorage.getItem('currentUserStore'));
    Config.imageurl = data?.imageurl;
    this.navigationService.onTriggerOnInit().subscribe(() => {

      const data = JSON.parse(localStorage.getItem('currentUserStore'));
      Config.imageurl = data?.imageurl;
      let location = localStorage.getItem('projLoc');
      let locationFinal;
      if (location !== null && typeof location == 'string') {
        // console.log('enttttttt')
        locationFinal = location;
      }


      //  this.autoModeSelect();
      this.ai.messages = [];
      // Execute any initialization logic here

      const storedData = localStorage.getItem('projRouted');
      if (storedData) {
        //console.log('hereee')
        this.homeFlag = false;

        const jsonData = JSON.parse(storedData);
        // localStorage.removeItem('projRouted');

        this.edit(jsonData);

      } else {

        // Handle case when the data is not found
        if (locationFinal === 'homeUp') {
          this.view.get('project/all').subscribe(res => {

            this.view.get('project/pinned').subscribe(res1 => {
              for (let i = 0; i < res.length; i++) {
                res[i].projectPin = 0;

                for (let j = 0; j < res1.length; j++) {


                  if (res[i].id === res1[j]?.projectId?.id) {
                    res[i].projectPin = 1;
                  }
                  // else {
                  //   res[i].projectPin = 0;

                  // }
                }
              } this.view.list = res.sort((a, b) => b.projectPin - a.projectPin);

              this.filterdProject = res;
            });
          });
          this.homeFlag = false;
          this.view.state = 'list';

        } else if (locationFinal === 'header') {
          this.homeFlag = true;

        } else if (locationFinal === 'hdone') {
          this.homeFlag = false;
          this.view.get('project/all').subscribe(res => {

            this.view.get('project/pinned').subscribe(res1 => {
              //  console.log(res1);
              for (let i = 0; i < res.length; i++) {
                res[i].projectPin = 0;

                for (let j = 0; j < res1.length; j++) {
                  // console.log('res', res[i].id)
                  //console.log('res1', res1[j].projectId.id)

                  if (res[i].id === res1[j]?.projectId?.id) {
                    //    console.log('found')
                    res[i].projectPin = 1;
                  }
                  // else {
                  //   res[i].projectPin = 0;

                  // }
                }
              } this.view.list = res.sort((a, b) => b.projectPin - a.projectPin);

              this.filterdProject = res;
            });
          });
          // localStorage.removeItem('projLoc');

          // localStorage.setItem('projLoc', "done");
        } else {
          // this.homeFlag = true;
          if (localStorage.getItem('newT') == 'true') {

            this.autoModeSelect();
          }
        }
      }
      //console.log(this.router.url)
      if (this.router.url === '/profile') {
        this.view.state = 'new'; this.newform(); this.catSelection = false; this.autoModeSelect()
      }
    });
    try {
      // this.homeFlag = true;

      this.catSelection = false;

      //  this.view.set('mode', 'Research Mode');
      this.view.set('mode', null);

      this.view.get('project/all').subscribe(res => {

        this.view.get('project/pinned').subscribe(res1 => {
          //  console.log(res1);
          for (let i = 0; i < res.length; i++) {
            res[i].projectPin = 0;
            for (let j = 0; j < res1.length; j++) {
              // console.log('res', res[i].id)
              //console.log('res1', res1[j].projectId.id)

              if (res[i]?.id === res1[j]?.projectId?.id) {
                // console.log('found')
                res[i].projectPin = 1;
              }

            }
          } this.view.list = res.sort((a, b) => b.projectPin - a.projectPin);

          this.filterdProject = res;
          localStorage.setItem('projList', JSON.stringify(this.filterdProject));

          this.config.projlist = this.filterdProject;
          const deleteData = sessionStorage.getItem('deleteData');

          if (deleteData) {
            sessionStorage.removeItem('deleteData');

            this.alertService.success('Project Deleted Successfully.', true);
            this.homeFlag = false;

          }
          // console.log('***', localStorage.getItem('projRouted'));

          const storedData = localStorage.getItem('projRouted');
          if (storedData) {
            //    console.log('hereee33333')
            this.homeFlag = false;

            const jsonData = JSON.parse(storedData);
            //localStorage.removeItem('projRouted');

            this.edit(jsonData);
          } else {
            // Handle case when the data is not found
            let location = localStorage.getItem('projLoc');
            let locationFinal;
            if (location !== null && typeof location == 'string') {
              //  console.log('enttttttt')
              locationFinal = location;
            }

            if (locationFinal === 'homeUp') {
              // console.log('home up')
              this.homeFlag = false;
              this.view.state = 'list';

              //  localStorage.setItem('projLoc', "done");

            } else if (locationFinal === 'header') {
              this.homeFlag = true;
              //  localStorage.setItem('projLoc', "hdone");
            } else if (locationFinal === 'hdone') {
              this.homeFlag = false;
              //  localStorage.setItem('projLoc', "done");
            } else {
              // this.homeFlag = true;

            }
          }
          if (res === undefined || this.view.list.length <= 0) {
            this.showvideo = true;
          }

          for (let i = 0; i < res[0]?.subscriptionId?.planId?.planFeaturesList?.length; i++) {
            // console.log(res[0].subscriptionId.planId.planFeaturesList[i].featureId.id);

            if (res[0].subscriptionId.planId.planFeaturesList[i].featureId.id === 1) {

              this.researchModule = true;

            }
            if (res[0]?.subscriptionId?.planId?.planFeaturesList[i]?.featureId.id === 2) {
              this.designModule = true;
            }
            if (res[0]?.subscriptionId?.planId?.planFeaturesList[i]?.featureId.id === 3) {
              this.riskanalysismodule = true;
            }
            if (res[0]?.subscriptionId?.planId?.planFeaturesList[i]?.featureId.id === 23) {
              this.researchMode = true;
            }
            if (res[0]?.subscriptionId?.planId?.planFeaturesList[i]?.featureId.id === 24) {
              this.turboMode = true;
            }
          }

        });



      });
      this.config.whilelabelhandler();
      this.config.projectClone();
      this.ai.desiagnApiCall = false;
      this.userDataTable = new MatTableDataSource(this.getUserDataTable());
      this.isAdminUrl = location.host.includes('admin');
      if (this.config.isLoggedIn() && this.config.isAdmin()) {
        this.router.navigate(['/subscribers']);
      }
      this.title = 'RESEARCH';

      const today = new Date();
      const month = today.getMonth();
      const year = today.getFullYear();
      this.minDate = new Date();
      this.minDate.setMonth(month);
      this.minDate.setFullYear(year);


      this.view.get('user/all').subscribe(res => {
        this.userListAll = res;
        this.userList = res;
        this.userFiltered = this.userList;
      });
      this.view.get('role/project').subscribe(res => {
        this.userRoleList = res;
      });
      this.view.get('user/username/' + this.config.getUsername()).subscribe(res => {

        this.config.currentUser = res;
        localStorage.setItem('currentUserStore', JSON.stringify(res));
        localStorage.setItem('userint', res.usertype);

        this.view.set('projectOwner', this.config.currentUser);
        // console.log(res.redirectStatus);
        if (res.redirectStatus === 0 && !this.config.isAdmin()) {
          // console.log('here');
          this.router.navigate(['/subscription']);
        } else {
          this.view.get('apilanguage/all').subscribe(res => {
            this.apiLanguage = res;
            for (let i = 0; i < this.apiLanguage.length; i++) {
              if (this.apiLanguage[i].defaultFlag === 1) {
                // if (!this.view.value('apiLanguage')) {
                //   console.log('founded');
                this.view.set('apiLanguage', this.apiLanguage[i].languageCode);
                localStorage.setItem('apiLanguage', this.apiLanguage[i].languageCode);
                // }
              }
            }

            this.selectedLanguage = this.view.value('apiLanguage');
            // console.log('init',this.view.value('apiLanguage'));

          });

          this.config.apiUsage();

          this.config.dataUsage();
          this.config.userCount();

        }
        // console.log(res);
      });
      this.view.get('producttype/all/sorted').subscribe(res => {

        const result = [];
        const map = new Map();
        for (const item of res) {
          if (!map.has(item.title.toLowerCase().trim())) {
            map.set(item.title.toLowerCase().trim(), true);    // set any value to Map
            result.push({
              title: item.title
            });
          }
        }

        this.productTypes = result;
      });

      // this.view.get('countrylist/all').subscribe(res => {
      //   this.countryList = res;
      //   console.log(this.countryList);
      // });
      this.view.get('msaccount/msaccountvalid').subscribe(async res => {
        if (res !== null) {
          this.hasMs = true;
        }
      });

      this.view.end();
    }
    catch (e) {
      console.log(e)
    }
    const storedData = localStorage.getItem('projRouted');
    if (storedData) {
      //  console.log('hereee33333')


      const jsonData = JSON.parse(storedData);
      //localStorage.removeItem('projRouted');
      // console.log(localStorage.getItem('projLoc'))


      this.homeFlag = false;
      this.edit(jsonData);


      return;
    } else {
      //  console.log(localStorage.getItem('projLoc'))

      if (localStorage.getItem('projLoc') === 'homeUp') {
        this.homeFlag = false;
        //this.view.state = 'list';
        // localStorage.removeItem('projLoc');
        // localStorage.setItem('projLoc', "done");

      } else if (localStorage.getItem('projLoc') === 'header') {
        this.homeFlag = true;
        // localStorage.removeItem('projLoc');

        //localStorage.setItem('projLoc', "done");
      } else if (localStorage.getItem('projLoc') === 'hdone') {
        this.homeFlag = false;
        //localStorage.removeItem('projLoc');

        //  localStorage.setItem('projLoc', "done");
      } else {
        this.homeFlag = true;

      }
    }
    // console.log('**********', this.homeFlag)

    // /* DEBUG loader */
    // setTimeout(() => {
    //   Config.loading = true;
    //   Config.setLoaderMessage('InsIQ is searching for suitable keywords');
    // }, 7000);
    if (localStorage.getItem('newT') == 'true') {

      this.autoModeSelect();
      localStorage.removeItem('newT');
    }
  }
  projectOwnerCheck() {
    if (this.view.value('projectOwner')?.name.trim() !== this.config.currentUser?.name.trim()) {
      return false;
    } else {
      return true;
    }
  }
  projectOwnerCheck1(owner) {
    //console.log(owner)
    if (owner?.name.trim() !== this.config.currentUser?.name.trim()) {
      return false;
    } else {
      return true;
    }
  }
  linkClick(s: any) {
    // console.log(_event)
    this.view.get('project/all').subscribe(res => {

      this.view.get('project/pinned').subscribe(res1 => {
        //  console.log(res1);
        for (let i = 0; i < res.length; i++) {
          res[i].projectPin = 0;

          for (let j = 0; j < res1.length; j++) {
            // console.log('res', res[i].id)
            //console.log('res1', res1[j].projectId.id)

            if (res[i].id === res1[j]?.projectId?.id) {
              //    console.log('found')
              res[i].projectPin = 1;
            }
            // else {
            //   res[i].projectPin = 0;

            // }
          }
        } this.view.list = res.sort((a, b) => b.projectPin - a.projectPin);

        this.filterdProject = res;
      });
    });
    this.homeFlag = false;
    this.linkEvent.emit([s]);
    this.view.state = 'list';
    localStorage.removeItem('projRouted')
    localStorage.removeItem('projLoc');
  }
  linkClick1(s: any) {
    // console.log('hereee')
    this.homeFlag = true;
    localStorage.removeItem('projRouted')
    localStorage.removeItem('projLoc');

  }
  autoModeSelect() {
    //  console.log('heree')
    this.newform();
    if (this.featureChecker(23)) {
      this.researchMode = true;

    }
    if (this.featureChecker(24)) {
      this.turboMode = true;

    }
    if (this.turboMode && !this.researchMode) {
      this.chooseMode('', 'Turbo Mode');
      this.chooseCategory(1)
    }
    if (!this.turboMode && !this.researchMode) {
      Config.loading = true;
      this.view.get('planfeature/featurelistuser').subscribe(res => {
        Config.loading = false;

        let count = 0;
        for (let i = 0; i < res?.length; i++) {
          if (res[i]?.featureId.id === 23) {
            this.researchMode = true;
          }
          if (res[i]?.featureId.id === 24) {
            this.turboMode = true;
          }
          if (this.turboMode && !this.researchMode) {
            this.chooseMode('', 'Turbo Mode');
            this.chooseCategory(1)
          }
        }

      });

    }



  }
  pinstatus(pin: number, id: string) {
    Config.loading = true;
    let pinVal;
    let setval = 0;
    if (pin == 1) {
      pinVal = 'pinproject';
      setval = 1;
    } else {
      pinVal = 'unpinproject';
      setval = 0;
    }
    this.view.get('project/' + pinVal + '/' + id).subscribe(res => {
      // console.log(_event)
      this.view.get('project/all').subscribe(res => {

        this.view.get('project/pinned').subscribe(res1 => {
          //  console.log(res1);
          for (let i = 0; i < res.length; i++) {
            res[i].projectPin = 0;

            for (let j = 0; j < res1.length; j++) {
              // console.log('res', res[i].id)
              //console.log('res1', res1[j].projectId.id)

              if (res[i].id === res1[j]?.projectId?.id) {
                //    console.log('found')
                res[i].projectPin = 1;
              }
              // else {
              //   res[i].projectPin = 0;

              // }
            }
          } this.view.list = res.sort((a, b) => b.projectPin - a.projectPin);

          this.filterdProject = res;
          if (this.view.isForm()) {
            // this.ssp.getProject().projectPin = pin;
            this.view.active.projectPin = setval;
          }
          Config.loading = false;
        });
      });
    });
  }

  shareProj(project) {
    const dialogData = project;
    this.cloneDialogRef = this.cloneDialog.open(CloneprojectComponent, {
      width: '600px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: dialogData
    });
  }

  chooseCategory(cat: number) {
    this.catSelection = true;
    this.view.set('productNewOrExisting', cat);
  }

  chooseMode(event: any, mode: string) {
    this.view.set('mode', mode);
    if (mode === 'Turbo Mode') {
      this.view.set('productTypeId', 'Turbo Mode');
    }
  }

  getPin(proj) {
    return proj?.filter(obj => obj.projectPin === 1)
  }

  pinUpdator(pin, id,): Observable<HttpEvent<any>> {
    //console.log('hereee')
    const formData: FormData = new FormData();

    formData.append('pin', pin);
    formData.append('id', id);

    const req = new HttpRequest('POST', `${Config.apiUrl}/project/pinproject`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  featurestatus(id: any) {
    if (this.featureChecker(id)) {
      return true;
    } else {
      return false;
    }
  }

  featureChecker(id: any) {
    return this.config.featureChecker(id);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  MSTeamChannel() {
    // console.log(channelId);
    const channelname = ((document.getElementById('channelname') as HTMLInputElement).value);
    if (channelname == '') {
      this.channelflag = true;
    } else {
      // this.mschannelview.initForm(this.url, this.getNewMSChannelForm(), null, false);
      this.view.get('msaccount/msaccountvalid').subscribe(async res => {
        // console.log(res);
        if (res !== null) {
          const result = await this.app.loginPopup(OAuthSettings)
          if (result) {
            this.authenticated = true;
            //   console.log('Trooooo'+this.view.value('id'));
            this.getachannel(res.teamId, res.id, this.view.value('id'), channelname);
          }
        }
      });
    }
  }

  async getAccessToken(): Promise<string> {
    let result: string | PromiseLike<string>;

    try {
      let tokenResponse = await this.app.handleRedirectPromise();

      let accountObj: any;
      if (tokenResponse) {
        accountObj = tokenResponse.account;
      } else {
        accountObj = this.app.getAllAccounts()[0];
      }

      if (accountObj && tokenResponse) {
        console.log("[AuthService.init] Got valid accountObj and tokenResponse")
      } else if (accountObj) {
        console.log("[AuthService.init] User has logged in, but no tokens.");
        try {
          tokenResponse = await this.app.acquireTokenSilent({
            account: this.app.getAllAccounts()[0],
            scopes: ["user.read"]
          })
        } catch (err) {
          await this.app.acquireTokenRedirect({ scopes: ["user.read"] });
        }
      } else {
        console.log("[AuthService.init] No accountObject or tokenResponse present. User must now login.");
        await this.app.loginRedirect({ scopes: ["user.read"] })
      }
      //  console.log('fffToken', tokenResponse.accessToken);
      result = tokenResponse.accessToken;

    } catch (error) {
      console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
    }

    if (result) {
      return result;
    }
    return null;
  }

  async getachannel(teamId: any, msid: any, projid: any, channelname: string) {
    Config.loading = true;
    Config.setLoaderMessage('InsIQ is creating your MS Teams Channel');
    if (!this.authenticated) { return null; }

    const graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        const token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done('Could not get an access token', null);
        }
      }
    });

    // const channel = {displayName: this.view.get('productName'), description: this.view.get('productDescription')};
    // let res = await graphClient.api('/teams/'+teamId+'/channels').post(channel);
    // console.log(res);
    // const client = Client.init(graphClient);

    try {
      const channel = { displayName: channelname, description: this.view.value('productDescription') };
      const res = await graphClient.api('/teams/' + teamId + '/channels')
        .post(channel);

      if (res.webUrl !== '') {
        Config.loading = false;
        const msval = 1;
        this.view.set('msteam', msval);
        this.view.set('msid', res.id);
        this.view.save();

        this.alertService.success('InsIQ created your MS Teams Channel Successfully !!!', true, 10 * 1000);
        // this.goforanupdate(projid,msid,res.id,res.displayName, res.description, res.webUrl);
      } else {
        Config.loading = false;
        this.alertService.error(
          'InsIQ failed to create your MS Teams Channel Please try later !!!',
          true, 10 * 1000);
      }
    } catch (error) {
      Config.loading = false;
      this.alertService.error(
        'InsIQ failed to create your MS Teams Channel...' + error?.message + '',
        true, 20 * 1000);
    }
  }
  isFormDirty() {
    return this.view.form.dirty;
  }
  newGo() {
    this.ai.turboMode = 0;
    this.ai.dashCharts = [];
    this.ai.sourceQuerry = '';
    this.ai.turboSourceMsg = 'To get the details, please click the source against each chat query.';
    this.ai.showSuggestions = false;
    this.ai.turboRerunFl = 0;
    this.ai.suggestedQuestions = [];
    this.ai.currentAiTabIndex = 0;
    // console.log(this.isFormDirty())
    if (!this.isFormDirty()) {
      this.smartDev(2, this.view, true);
    }
    else {
      if (this.checkFormChanges()) {
        this.insightGenerator(this.view);

      }
      else {
        this.smartDev(2, this.view, true);

      }
    }
  }
  checkFormChanges() {
    const currentValues = this.getFormValues();
    const initialValues = this.initialFormValues;

    const productNameChanged = currentValues?.productName.trim() !== initialValues?.productName.trim();
    const productDescriptionChanged = currentValues?.productDescription.trim() !== initialValues?.productDescription.trim();
    const brandNameChanged = currentValues?.brandName.trim() !== initialValues?.brandName.trim();
    //console.log(currentValues.brandName.trim(), initialValues.brandName.trim())
    if (productNameChanged || productDescriptionChanged || brandNameChanged) {
      // console.log('Changes detected in productName, productDescription, or brandName fields.');
      // You can perform additional actions here if needed
      return true;
    } else {
      //console.log('No changes detected in productName, productDescription, or brandName fields.');
      return false;
    }

    // Optionally, update initialFormValues after checking
    //this.initialFormValues = currentValues;
  }
  isEqual(obj1: any, obj2: any): boolean {
    // console.log(JSON.stringify(obj1).trim(), JSON.stringify(obj2).trim())
    return JSON.stringify(obj1).trim() === JSON.stringify(obj2).trim();
  }

  goforanupdate(projid: any, msid: any, channelid: any, channelname: any, channeldesc: any, channelurl: any) {
    this.mschannelview.initForm(this.url, this.getNewMSChannelForm());
    this.mschannelview.set('msid', msid);
    this.mschannelview.set('projectid', projid);
    this.mschannelview.set('channelid', channelid);
    this.mschannelview.set('displayName', channelname);
    this.mschannelview.set('description', channeldesc);
    this.mschannelview.set('url', channelurl);

    this.mschannelview.save(this.url);
  }
  //  onLangChange(event) {
  //
  //    sessionStorage.setItem('apiLanguage', event.value);
  //    this.selectedLanguage = sessionStorage.getItem('apiLanguage');
  //  }

  cancelDesign() {
    this.pageId = 0;
  }
  nextTab() {
    this.pageId = 1;

  }
  onInputChange(event: Event): void {
    const value = (event.target as HTMLTextAreaElement).value
      .replace(/\s+/g, ' ')                  // Trim leading and trailing spaces
      .replace(/[\/\\|?]+/g, ' ');            // Replace consecutive '/', '\', '|', '?' with a single space

    this.view.set('productName', value);
  }

  onInputChange1(event: Event): void {
    const value = (event.target as HTMLTextAreaElement).value
      .replace(/\s+/g, ' ')    // Replace multiple whitespace characters with a single space
      .replace(/[\/\\|?]+/g, ' ');  // Replace consecutive '/', '\', '|', '?' with a single space

    this.view.set('productDescription', value);
  }


  onSubmit(loading = true, message = null) {
    function formatTextWithSpaces(text) {
      if (text && typeof text === 'string') {
        return text.replace(/,\s*/g, ', ');
      }
      return text;
    }
    // if(this.pageId ===0)
    // {
    //  this.pageId = 1;
    //
    // }
    // else if(this.pageId ===1)
    // {
    //  this.pageId = 0;
    //
    // }
    if (this.view.value('productNewOrExisting') === 1) {
      // this.view.set('competitors', '');
      // this.view.set('competitorspdt', '');
      // this.view.set('companyname', '');

    } else if (this.view.value('productNewOrExisting') === 2) {
      // this.view.set('targetRegion', '');
      this.view.set('customer', '');

    }
    // console.log('SUBMIT triggerred');
    if (this.view.value('msteam') === undefined || this.view.value('msteam') === null) {
      const msval = 0;
      this.view.set('msteam', msval);
      // this.view.set('msid', '');
    }
    let pinVal;
    if (this.isFormValid()) {


      const productNameValue = this.view.value('productName')?.trim() ?? undefined;
      this.view.set('productName', productNameValue === undefined ? null : productNameValue);
      const productDescriptionValue = this.view.value('productDescription')?.trim() ?? undefined;

      if (this.flSelected !== 0 && this.flSelected !== 199 && this.flSelected !== 101) {
        //console.log('hereeeee')
        if (this.flSelected == 31) {
          if (this.view.value('keyword31') === '' || this.view.value('keyword31') === null || this.view.value('keyword31') === 'null') {

            this.view.set('keyword31', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword31Val = this.view.value('keyword31')?.trim() ?? undefined;
            this.view.set('keyword31', keyword31Val === undefined ? null : formatTextWithSpaces(keyword31Val));

          }


        } else if (this.flSelected == 6) {

          if (this.view.value('keyword6') === '' || this.view.value('keyword6') === null || this.view.value('keyword6') === 'null') {

            this.view.set('keyword6', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword6Val = this.view.value('keyword6')?.trim() ?? undefined;
            this.view.set('keyword6', keyword6Val === undefined ? null : formatTextWithSpaces(keyword6Val));

          }

        } else if (this.flSelected == 10) {

          if (this.view.value('keyword10') === '' || this.view.value('keyword10') === null || this.view.value('keyword10') === 'null') {

            this.view.set('keyword10', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword10Val = this.view.value('keyword10')?.trim() ?? undefined;
            this.view.set('keyword10', keyword10Val === undefined ? null : formatTextWithSpaces(keyword10Val));

          }

        } else if (this.flSelected == 2) {

          if (this.view.value('keyword2') === '' || this.view.value('keyword2') === null || this.view.value('keyword2') === 'null') {

            this.view.set('keyword2', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword2Val = this.view.value('keyword2')?.trim() ?? undefined;
            this.view.set('keyword2', keyword2Val === undefined ? null : formatTextWithSpaces(keyword2Val));

          }

        } else if (this.flSelected == 32) {

          if (this.view.value('keyword32') === '' || this.view.value('keyword32') === null || this.view.value('keyword32') === 'null') {

            this.view.set('keyword32', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword32Val = this.view.value('keyword32')?.trim() ?? undefined;
            this.view.set('keyword32', keyword32Val === undefined ? null : formatTextWithSpaces(keyword32Val));

          }

        } else if (this.flSelected == 8) {

          if (this.view.value('keyword8') === '' || this.view.value('keyword8') === null || this.view.value('keyword8') === 'null') {

            this.view.set('keyword8', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword8Val = this.view.value('keyword8')?.trim() ?? undefined;
            this.view.set('keyword8', keyword8Val === undefined ? null : formatTextWithSpaces(keyword8Val));

          }

        } else if (this.flSelected == 11) {

          if (this.view.value('keyword11') === '' || this.view.value('keyword11') === null || this.view.value('keyword11') === 'null') {

            this.view.set('keyword11', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword11Val = this.view.value('keyword11')?.trim() ?? undefined;
            this.view.set('keyword11', keyword11Val === undefined ? null : formatTextWithSpaces(keyword11Val));

          }

        } else if (this.flSelected == 7) {

          if (this.view.value('keyword7') === '' || this.view.value('keyword7') === null || this.view.value('keyword7') === 'null') {

            this.view.set('keyword7', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword7Val = this.view.value('keyword7')?.trim() ?? undefined;
            this.view.set('keyword7', keyword7Val === undefined ? null : formatTextWithSpaces(keyword7Val));

          }

        } else if (this.flSelected == 5) {

          if (this.view.value('keyword5') === '' || this.view.value('keyword5') === null || this.view.value('keyword5') === 'null') {

            this.view.set('keyword5', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword5Val = this.view.value('keyword5')?.trim() ?? undefined;
            this.view.set('keyword5', keyword5Val === undefined ? null : formatTextWithSpaces(keyword5Val));

          }

        } else if (this.flSelected == 4) {

          if (this.view.value('keyword4') === '' || this.view.value('keyword4') === null || this.view.value('keyword4') === 'null') {

            this.view.set('keyword4', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword4Val = this.view.value('keyword4')?.trim() ?? undefined;
            this.view.set('keyword4', keyword4Val === undefined ? null : formatTextWithSpaces(keyword4Val));

          }

        } else if (this.flSelected == 9) {

          if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

            this.view.set('keyword9', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
          } else {
            const keyword9Val = this.view.value('keyword9')?.trim() ?? undefined;
            this.view.set('keyword9', keyword9Val === undefined ? null : formatTextWithSpaces(keyword9Val));

          }

        }

      } else {
        this.view.set('productDescription', productDescriptionValue === undefined ? null : formatTextWithSpaces(productDescriptionValue));
      }


      const customerValue = this.view.value('customer')?.trim() ?? undefined;
      this.view.set('customer', customerValue === undefined ? null : customerValue);

      const targetRegionValue = this.view.value('targetRegion')?.trim() ?? undefined;
      this.view.set('targetRegion', targetRegionValue === undefined ? null : targetRegionValue);

      const productBenifitValue = this.view.value('productBenifit')?.trim() ?? undefined;
      this.view.set('productBenifit', productBenifitValue === undefined ? null : productBenifitValue);

      const similarProductValue = this.view.value('similarProduct')?.trim() ?? undefined;
      this.view.set('similarProduct', similarProductValue === undefined ? null : similarProductValue);

      const inspirationValue = this.view.value('inspiration')?.trim() ?? undefined;
      this.view.set('inspiration', inspirationValue === undefined ? null : inspirationValue);

      const brandNameValue = this.view.value('brandName')?.trim() ?? undefined;
      this.view.set('brandName', brandNameValue === undefined ? null : brandNameValue);

      const competitorsValue = this.view.value('competitors')?.trim() ?? undefined;
      this.view.set('competitors', competitorsValue === undefined ? null : formatTextWithSpaces(competitorsValue));

      const competitorspdtValue = this.view.value('competitorspdt')?.trim() ?? undefined;
      this.view.set('competitorspdt', competitorspdtValue === undefined ? null : formatTextWithSpaces(competitorspdtValue));

      const companynameValue = this.view.value('companyname')?.trim() ?? undefined;
      this.view.set('companyname', companynameValue === undefined ? null : companynameValue);

      const problemSolveValue = this.view.value('problemSolve')?.trim() ?? undefined;
      this.view.set('problemSolve', problemSolveValue === undefined ? null : problemSolveValue);

      const productEnviornmentValue = this.view.value('productEnviornment')?.trim() ?? undefined;
      this.view.set('productEnviornment', productEnviornmentValue === undefined ? null : productEnviornmentValue);

      const productChannelValue = this.view.value('productChannel')?.trim() ?? undefined;
      this.view.set('productChannel', productChannelValue === undefined ? null : productChannelValue);

      const prodnVolValue = this.view.value('prodnVol')?.trim() ?? undefined;
      this.view.set('prodnVol', prodnVolValue === undefined ? null : prodnVolValue);

      const manufacturerValue = this.view.value('manufacturer')?.trim() ?? undefined;
      this.view.set('manufacturer', manufacturerValue === undefined ? null : manufacturerValue);

      const productRegulationValue = this.view.value('productRegulation')?.trim() ?? undefined;
      this.view.set('productRegulation', productRegulationValue === undefined ? null : productRegulationValue);

      const sellRateValue = this.view.value('sellRate')?.trim() ?? undefined;
      this.view.set('sellRate', sellRateValue === undefined ? null : sellRateValue);





      if (!this.view.isEdit()) {
        this.addUserToProject();
      }

      //
      // let newList=this.view.value('projectUserList');
      // for(let i=0;i<this.view.value('projectUserList').length;i++)
      // {
      //   console.log(this.view.value('projectUserList')[i]);
      //   if(this.view.value('projectUserList')[i].roleCode===null||this.view.value('projectUserList')[i].roleCode===undefined)
      // {
      //   console.log('Found');
      //    newList = this.view.value('projectUserList').filter((u: { userId: { id: any; }; }) => u.userId.id !== this.view.value('projectUserList')[i].userId.id);

      // }

      // }
      //  this.view.set('projectUserList',newList) ;
      // for (let i = 0; i < this.view.list.length; i++) {
      //   if (this.view.list[i].id === this.selectedProject.id) {

      //     this.view.list[i].projectUserList = newList;
      //   }
      // }

      pinVal = this.view?.active?.projectPin;

      if (loading) {
        this.view.saveSet(['projectUserList', 'productTypeId']);

      } else {
        this.view.saveSet(['projectUserList', 'productTypeId'], null, message, null, this.view.state, null, loading);

      }
      // this.view.save();
      this.view.set('projectPin', pinVal);

      this.selectProject = this.view.value;
      if (this.view.isNew() && this.view.value('mode') == 'Research Mode') {

        this.cardDisplay = true;

      }

      if (this.view.value('mode') == 'Turbo Mode') {
        this.projSetting = true;

      }

    }
    //  this.router.navigate(['/project']);

    this.scrollToTop(document.querySelector('.active .rightSection'), 1000);
    // console.log(this.view.value('productTypeId'));
    this.view.get('producttype/all/sorted').subscribe(res => {
      const result = [];
      const map = new Map();
      for (const item of res) {
        if (!map.has(item.title.toLowerCase().trim())) {
          map.set(item.title.toLowerCase().trim(), true);    // set any value to Map
          result.push({
            title: item.title,

          });
        }
      }

      this.productTypes = result;
    });

    //  this.view.set('productTypeId', this.view.value('productTypeId'));
    //    this.addUserToProject();
    //    this.view.saveSet(['projectUserList']);
    //    // this.view.saveSetAndRedirectToList(['projectUserList'], '/');
    this.showUserToProject = false;
    this.userFiltered = null;
    this.userSelected = null;

    // if (this.view.value('productNewOrExisting') === 2) {
    //   this.ai.compRequired = true;
    //   this.ai.blogStatus = false;
    //   this.ai.compStatus = false;

    // }
    // else {
    this.ai.compRequired = true;
    this.ai.compStatus = false;
    this.ai.compStatusDate = true;
    this.ai.blogStatus = false;
    // }

    if (this.view.isNew()) {
      this.cacheflag = false;
      this.favflag = false;
      this.reportflag = false;

      //this.cardDisplay = true;

    } else {
      let aitypeVal;
      if (!this.ai.desiagnApiCall) {

        aitypeVal = '1';
      } else {
        aitypeVal = '2';
      }
      this.cacheStatus(aitypeVal, this.view.value('id'));
    }
    //this.view.end();
    // console.log(pinVal)
    let setval = 0;
    if (pinVal == '1') {

      setval = 1;
    } else {
      setval = 0;
    }
    //  console.log('SSS', setval)
    try { this.view.active.projectPin = setval; }
    catch (e) {

    }
    this.changeDetectorRef.detectChanges();
    this.showvideo = false;
    //console.log(this.view.active.projectPin)
  }

  scrollToTop(el: { scrollTop: number; scrollTo: (arg0: number, arg1: number) => void; }, scrollDuration: number) {
    const cosParameter = el.scrollTop / 2;
    let scrollCount = 0;
    let oldTimestamp = performance.now();
    function step(newTimestamp: number) {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
      if (scrollCount >= Math.PI) { el.scrollTo(0, 0); }
      if (el.scrollTop === 0) { return; }
      el.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
      oldTimestamp = newTimestamp;
      requestAnimationFrame(step);
    }
    requestAnimationFrame(step);
  }

  newform() {
    // console.log('hereee',this.view.value('apiLanguage'));
    // localStorage.setItem('apiLanguage', this.view.value('apiLanguage'));
    this.newProjectUser = true;
    this.view.newform();
    this.resetUserAutoSelect();
    this.selectedLanguage = localStorage.getItem('apiLanguage');
    this.view.set('projectOwner', this.config.currentUser);
    this.view.set('apiLanguage', this.selectedLanguage);
    this.userDataTable = new MatTableDataSource(this.getUserDataTable());

  }

  getNewDefault() {

    return new FormDefault(['productNewOrExisting', 'projectOwner'], [1, this.config.currentUser]);
  }

  filterUser(event: { query: any; }) {

    // console.log(this.selectedProject?.projectOwner);
    let query = event.query;
    query = query.toLowerCase();
    this.userFiltered = this.userList.filter((user: { name: string; }) => user.name.toLowerCase().indexOf(query) === 0);
    // console.log(this.userFiltered);

    // console.log(this.userDataTable.data);
    this.userFiltered = this.userFiltered.filter(user => user.id !== this.view.value('projectOwner').id);

    // console.log(this.config.currentUser.id);
    if ((this.view.value('projectOwner').id !== this.config.currentUser.id)) {
      let flag = 1;
      for (let i = 0; i < this.userDataTable.data?.length; i++) {
        //  console.log(this.userDataTable.data[i].userId.id);
        if (this.userDataTable.data[i].userId.id === this.config.currentUser.id) {

          flag = 0;
        }
      }
      if (flag === 1) {

        //  console.log(this.userDataTable.data);
        if ((this.view.value('projectOwner').id !== this.config.currentUser.id) && this.config.currentUser?.pin !== 'USR') {
          this.userFiltered.push(this.config.currentUser);
          // console.log('1');
        }


      } else {
        this.userFiltered = this.userFiltered.filter(user => user.id !== this.config.currentUser.id);

      }
      // console.log('here foundee');
    } else if ((this.view.value('projectOwner').id !== this.config.currentUser.id) && this.userDataTable.data.length === 0) {
      // console.log('2');

      // console.log('here foundee');
      this.userFiltered.push(this.config.currentUser);
    }


  }

  filterOwner(event: { query: any; }) {
    let query = event.query;
    query = query.toLowerCase();
    // console.log(this.userList);
    this.userFiltered = this.userList.filter((user: { name: string; }) => user.name.toLowerCase().indexOf(query) === 0);
    if (this.config.currentUser?.pin !== 'USR') {
      this.userFiltered.push(this.config.currentUser);
    } else {

    }

  }

  filterCountry(event: { query: any; }) {
    let query = event.query;
    query = query.toLowerCase();
    this.userFiltered = this.userList.filter((user: { name: string; }) => user.name.toLowerCase().indexOf(query) === 0);
    this.userFiltered.push(this.config.currentUser);
  }

  cancel() {
    this.getList();
    this.view.cancel('/');
  }

  getList() {
    this.view.get('project/all').subscribe(res => {
      this.view.list = res;
    });
  }

  // Add user roles to project
  setShowUserToProject() {
    this.showUserToProject = true;
  }

  disableAddUserToProject() {
    return (!this.userSelected || !this.userRoleSelected || this.userSelected === null || this.userRoleSelected === null);
  }

  addUserToProject() {
    let pinVal;
    if (this.userSelected && this.userSelected !== null) {
      this.newProjectUser = false;
      const updatedList = this.view.value('projectUserList', []);
      this.userSelected.forEach((user: any) => {
        const up: UserProject = new UserProject();
        up.roleCode = this.userRoleSelected;
        up.userId = user;
        updatedList.push(up);
      });
      this.view.set('projectUserList', updatedList);
      this.resetUserAutoSelect();
    }
    this.userSelected = null;
    this.userRoleSelected = null;
    this.refreshUserDataTable();
    pinVal = this.view?.active?.projectPin;

    if (this.view.isEdit()) {
      this.view.saveSet(['projectUserList', 'productTypeId'], null, 'Successfully added member.', null, this.view.state, null, true);
    }
    this.view.set('projectPin', pinVal);
  }

  cancelAddUser() {
    this.showUserToProject = false;
    this.userFiltered = null;
    this.userSelected = null;
  }


  removeProjectUser(pu: { userId: { id: any; }; id: any; }): void {


    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion?', message: ' Do you want to delete this Member from your Project?', cancelbtn: 'Cancel', okbtn: 'Delete' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        const projectUserList = this.view.value('projectUserList', []);
        // console.log(projectUserList);
        const newList = projectUserList.filter((u: { userId: { id: any; }; }) => u.userId.id !== pu.userId.id);
        // console.log(newList);

        this.view.set('projectUserList', newList);
        this.view.list.projectUserList = newList;
        // console.log(this.selectedProject);
        if (pu.id && pu.id !== null) {
          this.view.deleteList(pu.id, 'projectuser', 'Successfully deleted the member.');
          // this.userDataTable = new MatTableDataSource(this.getUserDataTable());
        } else if (this.selectedProject === undefined) {
          // this.view.deleteList(pu.id, 'projectuser', 'Successfully deleted the member.');
          // this.userDataTable = new MatTableDataSource(this.getUserDataTable());
        } else {
          // console.log('heere');
          this.view.get(`projectuser/userdelete/${this.selectedProject?.id}/${pu.userId.id}`).subscribe(res => {
            this.alertService.success('Successfully deleted the member.', true, 10 * 1000);

          });
        }
        this.userDataTable.data = this.getUserDataTable();
        // this.userDataTable = this.userDataTable.data;
        // console.log(this.getUserDataTable());
        // console.log(pu.userId.id);
        for (let i = 0; i < this.view.list.length; i++) {
          if (this.view.list[i].id === this.selectedProject?.id) {

            this.view.list[i].projectUserList = this.view.list.projectUserList;
          }
        }

        this.resetUserAutoSelect();
      }
    });
    // console.log(this.selectedProject);


  }

  SentimentprogressManager() {

    setInterval(() => {


      if (this.sentimentDisable) {
        this.SentimentapiProgressUpdate();

      }

    }, 7000);
  }
  SentimentapiProgressUpdate() {
    // console.log('Progress Checking');
    // console.log(this.view.value('id'));
    // console.log(this.selectedProject?.id);
    //   console.log(this.ai.SentimentapiProgressUpdate(this.selectedProject?.id));
    if (this.selectedProject?.id != null) {
      let flag;
      const querryUrlParams = 'rn=' + this.view.value('id') + '&ts=0';

      if (location.host.includes(Config.MAINPROD_URL)) {
        flag = 1;
      } else {
        flag = 0;
      }
      this.view.get(`aiinsights/sentimentprogress/${flag}/${querryUrlParams}`).subscribe(res => {
        // this.progress = res[0].sPercent;
        // this.config.loadingProgress.next(this.progress);

        console.log(res[0].sPercent);
        if (res[0].sPercent == 100) {
          this.sentimentDisable = false;
        } else {
          this.sentimentDisable = true;

        }

      });
    }

    return true;
  }
  getHighestDtSequence(): number {
    if (!this.messages || this.messages.length === 0) {
      return -1; // Return -1 if messages is undefined, null, or empty
    }

    let highestSequence = -1;
    for (const message of this.messages) {
      if (message.dtsequence > highestSequence) {
        highestSequence = message.dtsequence;
      }
    }
    return highestSequence;
  }

  getReports(id: any) {
    this.projId = id;
    let aitypeVal;
    if (!this.ai.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }



    this.view.get(`aiinsight/favoritelist/${aitypeVal}/${id}`).subscribe(res => {
      if (res != '') {
        this.favflag = true;
      }
    });
    this.view.get(`aireport/reports/${aitypeVal}/${id}`).subscribe(res => {
      if (res != '' && this.view.value('mode') == 'Research Mode') {
        this.reportflag = true;
      }
    });
    this.cacheStatus(aitypeVal, id);
    this.view.get(`aireport/aicharts/${id}/${aitypeVal}`).subscribe(res => {
      if (res != '' && this.view.value('mode') == 'Research Mode') {
        this.reportflag = true;
      }
    });

    this.view.get(`aiinsights/getallinsightsummary/${id}`).subscribe(res => {
      if (res != '' && this.view.value('mode') == 'Research Mode') {
        this.reportflag = true;
      }
    });
  }


  cacheStatus(aitypeVal, id) {
    this.view.get(`aiinsights/cachestatus/${aitypeVal}/${id}`).subscribe(res => {

      this.ai.compStatus = res.compStatus;
      this.ai.blogStatus = res.blogStatus;

      this.ai.compStatusDate = res.compStatusDate;
      this.ai.blogStatusDate = res.blogStatusDate;
      this.ai.compRequired = res.compRequired;

      if (res.cacheStatus) {

        this.cacheflag = true;
      } else {
        this.cacheflag = false;
      }
    });
  }
  edit(proj: any) {



    this.cardDisplay = false;
    this.flSelected = 199;
    this.catSelection = true;
    // console.log(proj)
    this.userFiltered = null;
    this.userSelected = null;
    this.selectedProject = proj;
    //  console.log(proj);
    this.userDataTable.data = proj.projectUserList;
    // console.log( this.userDataTable);
    this.reportflag = false;
    this.favflag = false;
    this.cacheflag = false;
    if (proj) {
      this.getReports(proj.id);
    }
    this.pageId = 0;
    this.tourflag = false;
    this.showUserToProject = false;

    // if (refresh === true) {
    //   this.pageId = 1;
    //   setTimeout(() => {
    //     this.pageId = 0;
    //   }, 150);
    // }
    for (let y = 0; y < proj.projectUserList?.length; y++) {
      if (this.config?.currentUser?.id === proj?.projectUserList[y].userId.id) {
        if (proj.projectUserList[y].roleCode.id === 'PJUR' || proj.projectUserList[y].roleCode.id === 'PJOB') {
          this.isPrjAdmin = false;
        } else {
          this.isPrjAdmin = true;
        }
      } else {
        this.isPrjAdmin = true;
      }
    }

    this.view.edit(proj);
    this.newProjectUser = true;
    this.resetUserAutoSelect();
    this.initialFormValues = this.getFormValues(); // Save initial form values

    // this.animateState = 'active';
    // this.triggerAnimation();
    setTimeout(() => {
      this.tourflag = true;
    }, 300);
    if (this.view.value('mode') == 'Turbo Mode') {
      this.projSetting = true;
    }
    window.dispatchEvent(new Event('resize'));

    this.config.scrollToActive('.stackMenu');
  }

  delete(row: { id: any; }) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion?', message: ' Do you want to delete this Project and all its associated data?', cancelbtn: 'Cancel', okbtn: 'Delete' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.view.state = 'list';
        this.view._deleteProject(row.id);

        this.view.state = 'list';

      }
    });
  }

  showHelp(helptext: any, defaulttext: any) {
    if (helptext === null || helptext === undefined) {
      return defaulttext;
    } else {
      return helptext;
    }
  }

  public isFormValid(brand = true, projectView = this.view) {
    // projectView.alertService.success('TEST: success ', false, 800 * 1000);
    // projectView.alertService.error('TEST: ERROR ', false, 800 * 1000);
    // return false;

    // this.s = this.view.value('productName');
    // console.log(this.view.value('productName')?.trim().split(' ').length);
    // if (projectView.value('productName') !== null) {
    //   if (projectView.value('productName').replace(/  +/g, ' ').trim().split(' ').length < 2) {
    //     projectView.alertService.error('Short Description about your Product / Idea should have 2 words');
    //     return false;
    //   } else if (projectView.value('productName').replace(/  +/g, ' ').trim().split(' ').length > 10) {
    //     projectView.alertService.error('Short Description about your Product / Idea is limited to 10 words');
    //     return false;
    //   }
    // }
    //   console.log(projectView.value('productName'));
    if (projectView.value('productName') == null || projectView.value('productName')?.toString()?.trim() === '') {
      projectView.alertService.error('Please Fill your Product Description');
      return false;
    }
    if (projectView.value('productTypeId') == null || projectView.value('productTypeId')?.toString()?.trim() === '') {
      projectView.alertService.error('Please Fill Product Category about your Product / Idea');
      return false;
    }

    if (projectView.value('productName') !== null && projectView.value('productName').length > 256) {
      projectView.alertService.error('The project description must be less than 256 characters.');
      return false;
    }
    // if (projectView.value('productDescription') !== null) {
    //   if (projectView.value('productDescription').replace(/  +/g, ' ').trim().split(' ').length < 10) {
    //     projectView.alertService.error('Detailed Description about your Product / Idea should have 10 words');
    //     return false;
    //   } else if (projectView.value('productDescription').replace(/  +/g, ' ').trim().split(' ').length > 40) {
    //     projectView.alertService.error('Detailed Description about your Product / Idea is limited to 40 words');
    //     return false;
    //   }
    // }
    if ((projectView.value('productDescription') == null || projectView.value('productDescription')?.toString()?.trim() === '') && !this.ai.keywordSaveFlag) {
      projectView.alertService.error('Please Fill Keywords of your Product / Idea');
      return false;
    }

    if ((projectView.value('brandName') == null || projectView.value('brandName')?.toString()?.trim() === '')) {
      if (this.view.form.value.mode === 'Turbo Mode') {
        projectView.alertService.error('Please Fill Project Name');

      } else {
        projectView.alertService.error('Please Fill Product Name');

      }
      return false;
    }

    if (projectView.value('competitors') != null) {
      const temp = projectView.value('competitors');
      const count = (temp.match(/,/g) || []).length;
      if (count > 2) {

        projectView.alertService.error('Only 3 competitors allowed!');
        return false;
      }
    }



    if (projectView.value('competitorspdt') != null) {
      const temp1 = projectView.value('competitorspdt');
      const count1 = (temp1.match(/,/g) || []).length;
      if (count1 > 2) {

        projectView.alertService.error('Only 3 competitor products allowed!');
        return false;
      }
    }

    if (projectView.value('brandName') !== null) {
      if (projectView.value('brandName').replace(/  +/g, ' ').trim().split(' ').length > 5) {
        projectView.alertService.error('Product Name is limited to 5 words');
        return false;
      }
    }
    try {
      projectView.set('productTypeId', projectView.value('productTypeId').trim());
    } catch (error) {
      // console.error(error);
    }
    return true;
  }
  projSettingOpenButton() {
    // let val;
    // if (this.projSetting) {
    //   val = 'View your product settings ▲';
    // }
    // else {
    //   val = 'View your product settings ▼';
    // }
    return this.projSetting;
  }
  projSettingOpen() {
    if (this.projSetting) {
      this.projSetting = false;
    } else {
      this.projSetting = true;
    }
  }
  private resetUserAutoSelect() {
    const updatedList = this.view.value('projectUserList');
    if ((updatedList) && updatedList !== null) {
      // this.projectUsers = updatedList.length;
      this.userList = this.userListAll;
      updatedList.forEach((up: { userId: { id: any; }; }) => {
        this.userList = this.userList?.filter((u: { id: any; }) => u.id !== up.userId.id);
        this.userFiltered = this.userList;
      });
    } else {
      // this.projectUsers = 0;
      this.userList = this.userListAll;
      this.userFiltered = this.userList;
    }
    // console.log( this.userDataTable);
  }
  txtchange(bn) {

    const count = (bn.match(/,/g) || []).length;
    if (count > 2) {

      this.view.alertService.error('Only Max. of 3 competitors allowed!');
      return false;
    }
  }
  filterProject(val: string) {
    //  console.log('searchinggggg');
    //   console.log(val);
    const query = val.toLowerCase();
    if (this.view.list && this.view.list != null) {
      this.filterdProject = this.view.list.filter((
        project: { productName: string; productTypeId: { title: string; }; brandName: string }
      ) => (
        project.productName.toLowerCase().indexOf(query) >= 0 ||
        project.productTypeId.title.toLowerCase().indexOf(query) >= 0 || project.brandName?.toLowerCase().indexOf(query) >= 0
      ));
    } else {
      this.filterdProject = [];
    }
  }
  favgo(view: View) {
    let pinVal = this.view.active.projectPin;
    console.log(view)
    let setval = 0;
    if (pinVal == '1') {

      setval = 1;
    } else {
      setval = 0;
    }
    //  console.log('SSS', setval)
    this.view.active.projectPin = setval;
    this.changeDetectorRef.detectChanges();
    // console.log(this.view.active.projectPin)
    this.ai.view.set('mode', this.view.active.mode);
    this.view.set('projectPin', pinVal);

    this.ai.projectFavFlag = true;
    this.ai.wishList(view);
  }
  async smartDev(fl: any, view: View, flag = false) {
    // console.log(flag);
    let pinVal = this.view?.active?.projectPin;

    let setval = 0;
    if (pinVal == '1') {

      setval = 1;
    } else {
      setval = 0;
    }
    //  console.log('SSS', setval)
    this.view.active.projectPin = setval;
    this.changeDetectorRef.detectChanges();
    // console.log(this.view.active.projectPin)
    this.view.set('projectPin', pinVal);

    if (this.ssp.pro.isFormValid(false, this.view)) {


      if (fl == 0) {
        // if (!this.ai.compStatus || !this.ai.blogStatus) {
        //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
        //     data: {
        //       title: 'Insight Call Required',
        //       message: 'Please run ' + this.getLabels() + ' before running Sentiment Analysis',
        //       cancelbtn: 'Cancel',

        //       okbtn: 'OK',
        //       btnflg: false
        //     },
        //   });

        // } else if (!this.ai.compStatusDate || !this.ai.blogStatusDate) {
        //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
        //     data: {
        //       title: 'New Insight Call Recommended',
        //       message: 'Please refresh ' + this.getLabelsDate() + ' to get the most recent  Sentiment Analysis data.',
        //       cancelbtn: 'CANCEL',

        //       okbtn: 'PROCEED'
        //     },
        //   });
        //   this.warningDialogRef.afterClosed().subscribe(result => {
        //     if (result?.data === 'confirm') {
        //       if (!flag) {
        //         this.onSubmit(false, '');
        //         // setTimeout(() => {


        //         this.ai.smartDev(fl, view, null, false, flag);

        //       } else {
        //         this.ai.smartDev(fl, view, null, false, flag);

        //       }
        //     }
        //   });
        // } else {
        if (!flag) {
          this.onSubmit(false, '');
          // setTimeout(() => {


          this.ai.smartDev(fl, view, null, false, flag);

        } else {
          this.ai.smartDev(fl, view, null, false, flag);

        }
        //  }
      } else {
        if (!flag) {
          this.onSubmit(false, '');
          this.ai.smartDev(fl, view, null, false, flag);

        } else {
          this.ai.smartDev(fl, view, null, false, flag);

        }
      }
    }


  }

  getLabels() {
    if (this.ai.compRequired && !this.ai.compStatus && !this.ai.blogStatus) {
      return 'Blogs & Reviews and Competitors';
    } else if (this.ai.compRequired && !this.ai.compStatus) {
      return 'Competitors';
    } else if (this.ai.compRequired && !this.ai.blogStatus) {
      return 'Blogs & Reviews';
    } else if (!this.ai.compStatus) {
      return 'Competitors';
    } else if (!this.ai.blogStatus) {
      return 'Blogs & Reviews';
    }
  }
  getLabelsDate() {
    if (this.ai.compRequired && !this.ai.compStatusDate && !this.ai.blogStatusDate) {
      return 'Blogs & Reviews and Competitors';
    } else if (this.ai.compRequired && !this.ai.compStatusDate) {
      return 'Competitors';
    } else if (this.ai.compRequired && !this.ai.blogStatusDate) {
      return 'Blogs & Reviews';
    } else if (!this.ai.compStatusDate) {
      return 'Competitors';
    } else if (!this.ai.blogStatusDate) {
      return 'Blogs & Reviews';
    }
  }
  async designsmartDev(fl: any, view: View, flag = false) {

    if (this.ssp.pro.isFormValid(false, this.view)) {


      this.onSubmit(false, '');


      setTimeout(() => {
        this.ai.designsmartDev(fl, view, null, false, flag);
        this.ai.showDashboard();

      }, 6000);

    }
  }
  reportGenerator(title: any, id: any) {
    this.ai.reportGenerator(title, id);
  }

  filterBrands(event: { query: any; }) {
    let query = event.query;
    query = query.toLowerCase().trim();
    this.filteredBrands = this.productTypes?.filter((pt: { title: string; }) => pt?.title?.toLowerCase()?.indexOf(query) >= 0);
  }

  getLabel() {
    let label: string;
    if (this.view.value('productNewOrExisting') === 1) {
      label = 'What would you like to name your product?';
    } else if (this.view.value('productNewOrExisting') === 2) {
      label = 'Existing Product Name';
    }
    return label;
  }

  getLabel1() {
    let label: string;
    if (this.view.value('productNewOrExisting') === 1) {
      label = 'Preferred region where your Product could be sold';
    } else if (this.view.value('productNewOrExisting') === 2) {
      label = 'Product Competitor';
    }
    return label;
  }
  sentiment(view: View) {
    this.ai.sentimentapi(view);
    this.ai.sentimentclickflg = true;
    this.ai.sentimentapistat = true;
    this.ai.smartTitle = 'Sentiment Analysis';
    this.ai.sentimentclick();
    this.ai.SentiwishList();

  }
  getLabel2() {
    let label: string;
    if (this.view.value('productNewOrExisting') === 1) {
      label = 'Who is your Customer?';
    } else if (this.view.value('productNewOrExisting') === 2) {
      label = 'Product Company Name';
    }
    return label;
  }
  getPlaceHolder() {
    let label: string;
    if (this.view.value('productNewOrExisting') === 1) {
      label = 'If you have chosen a tradename/ brand, else InsIQ could help you choose one.';
    } else if (this.view.value('productNewOrExisting') === 2) {
      label = 'Provide your company name which have the product';
    }
    return label;
  }
  categorylabel(category: number) {
    let label: string;
    if (category === 1) {
      label = 'Explore New Idea';
    } else if (category === 2) {
      label = 'Monitor your Product';
    }
    return label;
  }
  tabClick(tab: { index: number; }) {

    switch (tab.index) {
      case 0:
        this.title = 'RESEARCH';
        this.reportflag = false;
        this.favflag = false;
        this.cacheflag = false;
        this.ai.desiagnApiCall = false;
        this.getReports(this.projId);
        break;
      case 1:
        this.title = 'DESIGN';
        this.reportflag = false;
        this.favflag = false;
        this.cacheflag = false;

        this.ai.desiagnApiCall = true;
        this.getReports(this.projId);
        break;
      case 2:

        this.title = 'RISK ANALYSIS';
        break;
    }
    this.pageId = tab.index;
  }

  rasteriseCanvas(canvas: HTMLCanvasElement) {
    const image = new Image();
    image.src = canvas.toDataURL('image/png');
    // console.log(image.src);
    canvas.replaceWith(image);
  }

  onMlInputChange(event) {
    // console.log(event.target.value);
  }

  //  getLabel(text-label)
  //  {
  //    let label;
  //    if(this.view.value('productNewOrExisting')=='1')
  //    {
  //      label='What would you like to name your product?';
  //    }
  //     else if(this.view.value('productNewOrExisting')=='2')
  //    {
  //      label='Existing Product Name';
  //    }
  //    return label;
  //  }
  //   getPlaceHolder(text-label)
  //  {
  //    let label;
  //    if(this.view.value('productNewOrExisting')=='1')
  //    {
  //      label='If you have chosen a tradename/ brand, else InsIQ could help you choose one.';
  //    }
  //     else if(this.view.value('productNewOrExisting')=='2')
  //    {
  //      label='Provide your company name which have the product';
  //    }
  //    return label;
  //  }
}
