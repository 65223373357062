import {
  OnInit,
  ViewChild,
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { OAuthSettings } from './oauth';
import { MatSort } from '@angular/material/sort';
import { Validators, FormBuilder } from '@angular/forms';
import { AlertsService } from '../profile/alerts.service';
import { Client } from '@microsoft/microsoft-graph-client';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Config, View, AppPattern } from '../../config/global.config';
import { AlertService } from 'src/app/_services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import * as Msal from '@azure/msal-browser';
import { CustomThemeService } from 'src/app/custom-theme.service';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'projects/tag-cloud/src/lib/navigation-service';
const msalConfig = {
  auth: {
    clientId: "fec2ff3d-3c5b-4573-8011-730a3af0b708",

  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [ConfirmationService]

})
export class UserComponent implements OnInit, AfterViewInit {
  @Output() linkEvent = new EventEmitter<any>();

  hasMs = false;
  isbtob = false;
  aduser = false;
  checkoutFlag = false;
  pagetile: string;
  list = new Array();
  temp = new Array();
  userlist: any = null;
  adUserpopup: boolean;
  disabledAgreement = true;

  aduserlist = new Array();
  projList: any[] = []; // Initialize as an empty array or with default values
  selected = 0;
  sachetList;
  app;
  public authenticated: boolean;
  // cols: { field: string; header: string; }[];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public dataSource = new MatTableDataSource<any>(this.list);
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  stripePromise = loadStripe(environment.stripe);

  constructor(
    public view: View,
    public config: Config,
    private router: Router,
    public http: HttpClient,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public msaccountview: View,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private navigationService: NavigationService,
    public customThemeService: CustomThemeService,
    private changeDetectorRefs: ChangeDetectorRef,

  ) {
    this.app = new Msal.PublicClientApplication(msalConfig);

    this.view.initForm('user', this.getNewForm());
    this.refreshB2B();
    this.pagetile = 'All';
    this.adUserpopup = false;
  }

  ngAfterViewInit() {

    if (!this.config.projlist) {
      this.projList = JSON.parse(localStorage.getItem('projList'));
      this.cdr.detectChanges();
    }

    this.view.initPage(this.paginator, this.sort);

    // this.cols = [
    //   { field: 'name', header: 'Name' },
    //   { field: 'author', header: 'Author' },
    //   { field: 'price', header: 'Price' }
    // ];
  }
  b2bChange(e) {
    const v = (e.checked) ? 1 : 0;
    this.isbtob = e.checked;
    this.view.form.value.isbtob = v;
    // console.log(v);
    // console.log(this.isbtob);
  }
  trackByFunction(item) {
    return item.id; // Use a unique identifier property
  }

  addmember() {
    let userval;
    this.view.get('apiusage/usercount').subscribe(res => {

      userval = res[0];

      let freeUser = userval?.freeuser;
      let sachetPossible = userval?.sachetuser;

      const totalPossible = parseInt(freeUser, 10) + parseInt(sachetPossible, 10);
      const totalUsed = parseInt(userval?.totalused, 10);


      const sachetBalance = parseInt(userval?.sachetremaining, 10);
      if (totalPossible == totalUsed) {
        this.alertService.error(
          'Your User Limit for Current Plan Exhausted',
          true, 20 * 1000);
      }
      else if ((totalPossible != totalUsed) && sachetBalance == 0 && freeUser == 0) {
        this.alertService.error(
          'Your User Limit for Current Plan Exhausted',
          true, 20 * 1000);
      } else {
        this.view.set('active', 1);

      }
    });


  }
  toNewProject() {
    this.navigationService.triggerOnInit();
    localStorage.removeItem('projRouted')
    localStorage.removeItem('projLoc');
    localStorage.setItem('newT', 'true');

    this.router.navigate(['/new-project']);
  }
  yotubeClick() {
    let myurl = 'https://www.youtube.com/@InsIQ_AI/videos';
    window.open(myurl, '_blank');
  }




  acdemyClick() {
    let myurl = 'https://academy.insiq.io/course/elevate-your-product-strategy';
    window.open(myurl, '_blank');
  }
  getProjName(project) {

    let projName: any;
    if (project.productNewOrExisting === 1) {
      //projName = project.productName;
      projName = project.brandName || project.productName;

    } else if (project.productNewOrExisting === 2) {
      projName = project.brandName;
    }
    return projName;
  }
  chooseProject(project) {
    localStorage.setItem('projRouted', JSON.stringify(project));
    window.location.href = '/project';

  }
  changeCheck(event) {
    this.disabledAgreement = !event.checked;
  }
  refreshB2B() {
    this.isbtob = (this.view.form.value.isbtob === 1) ? true : false;

    // console.log(this.isbtob);
  }
  ngOnInit() {
    this.config.whilelabelhandler();

    this.projList = this.config.projlist;


    this.view.get('msaccount/msaccountvalid').subscribe(async res => {
      if (res !== null) {
        this.hasMs = true;
      }
    });

    this.view.get('user/allsachetlist').subscribe(async res => {
      //  console.log(res)
      if (res !== null) {
        // Assuming res is an array and this.view.list is an array
        this.sachetList = res;
        // Iterate through res and merge into this.view.list based on matching ids
      }
    });

    this.view.get('user/username/' + this.config.getUsername()).subscribe(res => {

      if (res.redirectStatus === 0 && !this.config.isAdmin()) {

        this.router.navigate(['/subscription']);
      }


    });

    if (this.config.currentUser ? this.config.currentUser?.pin === 'USR' : JSON.parse(localStorage.getItem('currentUserStore'))?.pin === 'USR') {

      this.router.navigate(['/login']);
    }
  }
  onKeydown(event) {
    if (event.keyCode === 32 || event.keyCode === 191 || event.keyCode === 219 || event.keyCode === 221 || event.keyCode === 222 || event.keyCode === 220 || event.keyCode === 186 || event.keyCode === 187) {
      return false;
    }
  }


  edit(user, i) {
    this.selected = i;
    this.view.edit(user);
    this.sachetList.forEach(resItem => {
      const matchingItem = this.view.list.find(viewItem => viewItem?.id === resItem?.teamuserId?.id);

      if (matchingItem) {
        // Merge the data from resItem into matchingItem
        Object.assign(matchingItem, resItem);
      }
    });

    // Now, this.view.list contains the merged data
    //  console.log(this.view.list);



  }
  newmember() {
    let userval;
    this.view.get('apiusage/usercount').subscribe(res => {

      userval = res[0];

      let freeUser = userval?.freeuser;
      let sachetPossible = userval?.sachetuser;
      const totalPossible = parseInt(freeUser, 10) + parseInt(sachetPossible, 10);
      const totalUsed = parseInt(userval?.totalused, 10);
      const sachetBalance = parseInt(userval?.sachetremaining, 10);
      if (totalPossible == totalUsed) {
        this.alertService.error(
          'Your User Limit for Current Plan Exhausted',
          true, 20 * 1000);
      }
      else if ((totalPossible != totalUsed) && sachetBalance == 0 && freeUser == 0) {
        this.alertService.error(
          'Your User Limit for Current Plan Exhausted',
          true, 20 * 1000);
      } else {
        this.view.newform();
        this.view.set('active', 1);
      }
    });
  }

  linkClick(s: any) {
    this.navigationService.triggerOnInit();
    this.router.navigate(['/project']);


    localStorage.removeItem('projRouted')
    localStorage.setItem('projLoc', 'hdone');
    this.linkEvent.emit([s]);
    //this.view.state = 'list';
  }

  onSubmit() {
    // console.log(this.view.form.value.active);
    this.view.set('active', this.view.form.value.active);
    if (this.view.isNew()) {
      this.view.save('user', 'Successfully added a member.');

      try {
        this.selected = this.view?.list?.length;

      }
      catch (e) {

      }
    } else {
      this.view.save('user');

    }

    setTimeout(() => {
      this.config.userCount();
      this.view.get('user/allsachetlist').subscribe(async res => {
        //  console.log(res)
        if (res !== null) {
          // Assuming res is an array and this.view.list is an array
          this.sachetList = res;
          this.sachetList.forEach(resItem => {
            const matchingItem = this.view.list.find(viewItem => viewItem?.id === resItem?.teamuserId?.id);

            if (matchingItem) {
              // Merge the data from resItem into matchingItem
              Object.assign(matchingItem, resItem);
            }
          });
          // Iterate through res and merge into this.view.list based on matching ids
        }
      });
    }, 2500);

  }

  getNewForm() {
    return this.fb.group({
      id: [''],
      name: ['', Validators.required],
      loginKey: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(AppPattern.EMAIL)
      ])],
      isbtob: [],
      usertype: [],
      active: ['1'],
      remarks: [],
      integrationid: []
    });
  }
  getType(usertype: string) {
    let type: { icon: string; label: string; };
    if (usertype === 'MS') {
      type = { icon: 'i-user-ms', label: 'AD User' };
    } else {
      type = { icon: 'i-user-dev2m', label: 'InsIQ User' };
    }
    return type;
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
  MSAduser() {
    this.adUserpopup = true;

    this.view.get('msaccount/msaccountvalid').subscribe(async res => {
      // console.log(res);
      if (res == null) {

      } else {
        let result = await this.app.loginPopup(OAuthSettings)

        if (result) {
          this.authenticated = true;
          this.getachannel();
        }
      }
    });
  }

  async getAccessToken(): Promise<string> {
    let result;
    {

      try {
        let tokenResponse = await this.app.handleRedirectPromise();

        let accountObj;
        if (tokenResponse) {
          accountObj = tokenResponse.account;
        } else {
          accountObj = this.app.getAllAccounts()[0];
        }

        if (accountObj && tokenResponse) {
          console.log("[AuthService.init] Got valid accountObj and tokenResponse")
        } else if (accountObj) {
          console.log("[AuthService.init] User has logged in, but no tokens.");
          try {
            tokenResponse = await this.app.acquireTokenSilent({
              account: this.app.getAllAccounts()[0],
              scopes: ["user.read"]
            })
          } catch (err) {
            await this.app.acquireTokenRedirect({ scopes: ["user.read"] });
          }
        } else {
          console.log("[AuthService.init] No accountObject or tokenResponse present. User must now login.");
          await this.app.loginRedirect({ scopes: ["user.read"] })
        }
        //   console.log('fffToken', tokenResponse.accessToken);
        result = tokenResponse.accessToken;

      } catch (error) {
        console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
      }

    }
    if (result) {
      return result;
    }
    return null;
  }
  getRenew(date1, date2) {
    let retvalue = false;
    // Convert the date strings to Date objects
    const activeFromDate = new Date(date1);
    const activeTillDate = new Date(date2);

    // Calculate the time difference in milliseconds
    const timeDifference = activeTillDate.getTime() - activeFromDate.getTime();

    // Calculate the number of days
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    // console.log(daysDifference)
    if (daysDifference > 50) {
      retvalue = true;
    }
    return retvalue;
  }

  async getachannel() {
    this.list = new Array();
    if (!this.authenticated) { return null; }

    const graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        const token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done('Could not get an access token', null);
        }
      }
    });
    try {
      const res = await graphClient.api('/users').get();

      this.userlist = res;
      this.changeDetectorRefs.detectChanges();
      // console.log(this.view.list);
      for (let i = 0; i < this.userlist.value.length; i++) {
        this.aduserlist.push(this.userlist.value[i]);
      }
      this.refreshAdList(this.userlist);
      // console.log(this.list);
      //  this.hasMs=false;
      this.aduser = true;
      // const dialogRef = this.dialog.open(AdUserDialogComponent, {
      //   data: { userDetails: this.list },
      // });
    } catch (error) {
      this.alertService.error(
        'InsIQ failed to Fetch Your Ad Users.' + error?.message + '',
        true, 20 * 1000);
    }
  }

  refreshAdList(mylist) {
    // console.log(mylist);
    // console.log(this.view.list);
    if (this.view.list === null || typeof this.view.list === 'undefined') {
      // console.log('qwerrr');
      for (let i = 0; i < this.userlist.value.length; i++) {
        this.list.push(mylist.value[i]);
      }
    } else {
      let x = 1;
      for (let i = 0; i < mylist?.value?.length; i++) {

        x = 1;
        for (let j = 0; j < this.view?.list?.length; j++) {
          if (mylist.value[i].id == this.view.list[j].integrationid) {
            x = 0;
            break;
          }
        }
        if (x === 1) {
          // new user
          this.list.push(mylist.value[i]);
        }
      }
    }

    this.dataSource.data = this.list;
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.connect();

    this.changeDetectorRefs.detectChanges();
  }
  adduser(name: any, integrationid: any, email: any) {
    // console.log(integrationid);
    this.view.initForm('user', this.getNewForm());
    this.view.set('active', 1);
    this.view.set('name', name);
    this.view.set('loginKey', email);
    this.view.set('email', email);
    this.view.set('usertype', 'MS');
    this.view.set('integrationid', integrationid);
    this.view.saveSetAndRedirectToList();
    this.temp = this.list.filter(u => u.id !== integrationid);
    this.list = this.temp;
    this.view.set('loginKey', '');
    this.dataSource.data = this.list;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.connect();

    this.changeDetectorRefs.detectChanges();
  }
  delUser(id) {
    // console.log(this.aduserlist);

    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion?', message: ' Do you want to delete this Member? This process cannot be undone.', cancelbtn: 'Cancel', okbtn: 'Delete' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.config.userCount();

        this.view.deleteList(id, 'user', 'Successfully deleted the member');
        this.view.cancel();
      }
    });

    // this.temp = this.aduserlist.filter(u => u.id == integrationid);
    // this.list.push(this.aduserlist.filter(u => u.id == integrationid));
    // this.view.initPage(this.paginator, this.sort);
  }
  renewClick() {
    this.checkoutFlag = true;
  }
  openTnC(typeVal) {
    // const dialogRef =
    sessionStorage.setItem('tandclink', typeVal);

    sessionStorage.setItem('tandc', 'REG');
    this.dialog.open(TermsDialogComponent, {
      width: '98vw',
      height: '98vh',
      autoFocus: false,
      panelClass: 'TnC',
      disableClose: true
    });
  }
  backtocheckout() {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Action', message: 'Are you sure want to abort the Subscription Transaction ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data == 'confirm') {
        this.checkoutFlag = false;

      }
    });
  }
  disablecheck() {
    if (!this.disabledAgreement) {
      return false;
    } else {
      return true;
    }
  }
  async payNowHere() {
    // console.log(plan);
    // window.location.href = Config.BRAINTREE_URL + '/' + this.config?.currentUser?.id + '/' + this.avaTaxResponse?.totalAmount + '/2';

    // window.location.href = Config.BRAINTREE_URL + '/' + this.config?.currentUser?.id + '/' + this.avaTaxResponse?.totalAmount + '/1';
    // let amount: any = textbox.value;
    Config.loading = true;
    const payment = {
      userid: this.view?.active?.teamuserId.id,
      name: this.config?.currentUser?.name,
      currency: 'usd',
      // amount on cents *10 => to be on dollar
      amount: this.view?.active?.price * 100,
      quantity: '1',
      paytype: '3'
      //cancelUrl: 'http://localhost:4200/cancel',
      // successUrl: 'http://localhost:4200/success',
    };

    const stripe = await this.stripePromise;

    // this is a normal http calls for a backend api
    this.http
      .post(`${environment.serverUrl}/payment`, payment)
      .subscribe((data: any) => {
        Config.loading = false;
        //this.config.userCount();

        // I use stripe to redirect To Checkout page of Stripe platform
        // console.log(data);
        localStorage.setItem('paymentId', data.id);
        localStorage.setItem('renewUserId', this.view?.active?.teamuserId.id);

        stripe.redirectToCheckout({
          sessionId: data.id,
        }).then(result => {
          console.log(result);
        })
          .catch(error => {
            console.error(error);
          });;
      });
  }

  getTitle() {
    if (this.adUserpopup) {
      this.pagetile = 'Import AD';
    } else {
      this.pagetile = 'All';
    }
    return this.pagetile;
  }

  CloseAduser() {
    this.adUserpopup = false;
  }

  getKey(mail, userPrincipalName) {
    if (mail == null) {
      return userPrincipalName;
    } else {
      return mail;
    }
  }
}
