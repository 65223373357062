<mat-toolbar>
  <mat-toolbar-row>
    <a [routerLink]="['/project']" class="projectName">{{data.projectName}}</a>
    <div class="spacer"></div>
    <span class="pageTitle">{{data.title}}</span>
    <div class="spacer"></div>
    <!-- <div class="percentage">
      {{data.percentage}}% Completed
      <div class="progress">
        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" [style.width.%]="data.percentage"
          [attr.aria-valuenow]="data.percentage"></div>
      </div>
    </div> -->
    <button type="button" mat-raised-button (click)="aiFavEventHandler()"
      class="ib-m curved text-accent t-normal f-400 m-x-5">
      <mat-icon class="i-1_5x ib-m">star_border</mat-icon>
      <span class="ib-m">Fav</span>
    </button>
    <!-- <button type="button" mat-raised-button (click)="aiEventHandler()" class="ib-m curved text-accent t-normal f-400">
      <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
      <span class="ib-m">Ask TOM</span>
    </button> -->
    <mat-menu #appMenu="matMenu" xPosition="before">
      <button mat-menu-item (click)="aiEventHandler1()">Insights</button>
      <button mat-menu-item (click)="aiEventHandler2()">Product Insights</button>
      <button mat-menu-item (click)="aiEventHandler3()">Market Insights</button>
      <button mat-menu-item (click)="aiEventHandler4()">IP Insights</button>
    </mat-menu>

    <button mat-raised-button [matMenuTriggerFor]="appMenu" class="t-normal curved text-accent">
      <mat-icon class="i-1_5x ib-m">AI</mat-icon>
      <span class="ib-m">Ask TOM</span>
      <i class="i i-2x">arrow_drop_down</i>
    </button>
    
    <!-- <mat-button-toggle-group class="ib-m m-r-20 curved sm t-normal f-400">
      <mat-button-toggle class="text-accent" (click)="aiEventHandler1()">
        <mat-icon class="i-1_5x ib-m">AI</mat-icon>
        <span class="ib-m">Ask TOM</span>
      </mat-button-toggle>

      <mat-button-toggle [matMenuTriggerFor]="appMenu" color="accent">
        <mat-icon>arrow_drop_down</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group> -->
    <!-- <div class="b-l-1 p-l-5 iconLinks m-l-20">
      <button mat-button title="Add a Cost" matTooltipPosition="below">
        <mat-icon fontSet="i" fontIcon="i-calc" aria-label="Add a Cost"></mat-icon>
      </button>
      <button mat-button title="Lessons Learned" matTooltipPosition="below">
        <mat-icon fontSet="i" fontIcon="i-bulb" aria-label="Lessons Learned"></mat-icon>
      </button>
    </div> -->
  </mat-toolbar-row>
</mat-toolbar>