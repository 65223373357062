<div class="container-fluid d-flex h-100">
  <div class="wrapper" [ngClass]="{notLoggedIn: !config.isLoggedIn()}">
    <div class="leftSection loginWrap">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="insiq">
      </figure>
      <div class="d-flex align-items-top t-c">
        <div class="w-100">
          <!-- <figure>
            <img src="./assets/images/insiq.png" alt="dev2m">
          </figure> -->
          <ul class="menu" *ngIf="!regFlag">
            <li>
              <button mat-menu-item data-menu="terms" [routerLink]="'/terms-and-conditions'" routerLinkActive="active">
                Terms of Service
              </button>
            </li>
            <li>
              <button mat-menu-item data-menu="privacy" [routerLink]="'/privacy-policy'" routerLinkActive="active">
                Privacy Policy
              </button>
            </li>
            <!-- <li>
              <button mat-menu-item data-menu="subscriber">
                Subscriber Agreement
              </button>
            </li>
            <li>
              <button mat-menu-item data-menu="acceptUse">
                Acceptable Use
              </button>
            </li> -->
            <li>
              <button mat-menu-item data-menu="cookies" [routerLink]="'/cookies-policy'" routerLinkActive="active">
                Cookies Policy
              </button>
            </li>
            <li>
              <button mat-menu-item data-menu="subterms" [routerLink]="'/subterms'" routerLinkActive="active">
                Subscription Terms and Conditions/Policies
              </button>
            </li>

            <li>
              <button mat-menu-item data-menu="payterms" [routerLink]="'/payterms'" routerLinkActive="active">
                Payment Terms and Conditions/Policies </button>
            </li>

            <li>
              <button mat-menu-item data-menu="cancelterms" [routerLink]="'/cancelterms'" routerLinkActive="active">
                Cancellation Policy </button>
            </li>
            <li class="m-y-30">
              <a [routerLink]="['/']" class="curved btn-link" matRipple>Go Back</a>
            </li>
          </ul>

          <ul class="menu" *ngIf="regFlag">
            <li>
              <button mat-menu-item data-menu="terms" (click)="loadPage('terms')"
                [ngClass]="(tandclink == 'terms')?'active':''">
                Terms of Service
              </button>
            </li>
            <li>
              <button mat-menu-item data-menu=" privacy" (click)="loadPage('privacy')"
                [ngClass]="(tandclink == 'privacy')?'active':''">
                Privacy Policy
              </button>
            </li>
            <!-- <li>
              <button mat-menu-item data-menu="subscriber">
                Subscriber Agreement
              </button>
            </li>
            <li>
              <button mat-menu-item data-menu="acceptUse">
                Acceptable Use
              </button>
            </li> -->
            <li>
              <button mat-menu-item data-menu="cookies" (click)="loadPage('cookies')" activeItem="active"
                [ngClass]="(tandclink == 'cookies')?'active':''">
                Cookies Policy
              </button>
            </li>
            <li>
              <button mat-menu-item data-menu="subterms" (click)="loadPage('subterms')" activeItem="active"
                [ngClass]="(tandclink == 'subterms')?'active':''">
                Subscription Terms and Conditions/Policies
              </button>
            </li>

            <li>
              <button mat-menu-item data-menu="payterms" (click)="loadPage('payterms')" activeItem="active"
                [ngClass]="(tandclink == 'payterms')?'active':''">
                Payment Terms and Conditions/Policies </button>
            </li>

            <li>
              <button mat-menu-item data-menu="cancelterms" (click)="loadPage('cancelterms')" activeItem="active"
                [ngClass]="(tandclink == 'cancelterms')?'active':''">
                Cancellation Policy </button>
            </li>
            <li class="m-y-30">
              <a (click)="closedialog()" class="curved btn-link" matRipple>Go Back</a>
            </li>
          </ul>

        </div>
      </div>
    </div>
    <div class="rightSection mainWrap {{getstyleFlag()}}" tabindex="0">
      <mat-card [class.mat-elevation-z24]="true">
        <mat-card-content>
          <ng-container *ngTemplateOutlet="layout ? layout: terms"></ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #defaultLayout>InsIQ</ng-template>

<ng-template #terms>
  <h1 class="title">Terms of Service</h1>
  <p>The following Terms of Services governs all use of the InsIQ website, and all content, services, and information
    provided on the website. The policy of InsIQ's site is to respect your privacy regarding any information we may
    access from you while operating our website. This Privacy Policy applies to both users and visitors who access the
    Public Site, thus creating an environment of honesty and transparency from our side. Kindly read the agreement
    adequately before using our website for any cause. Using the InsIQ site or accessing our services implies that you
    have agreed to the mentioned approvals to our terms. Any activity done on the site will be under the Terms of
    Services mentioned on this accord. Kindly note that no modification to the Privacy Policy shall be applicable and
    all other operating rules, policies, and procedures that may be published from time to time on this Site by InsIQ.
  </p>
  <h3>Your InsIQ Account and Site</h3>
  <p>If you use tools at the site of InsIQ, you must responsibly use them. You must immediately notify InsIQ of any
    unauthorized uses of your account or any other breaches of security on the contacts mentioned set forth. InsIQ will
    not be accountable for any acts or omissions by you, including any damages of any kind resulting from such acts or
    omissions.
    <br><br>
    Without limiting any of those representations or warranties, InsIQ has the right (though not the obligation) to, in
    InsIQ’s sole discretion terminate or deny access to and use of the Website to any individual or entity for any
    reason, including a breach in the privacy policy.
  </p>
  <h3>Payment and Renewal</h3>
  <h4>General Terms</h4>
  <p>Optional paid services such as additional results are available on the Website (any such services, an “Upgrade”).
    By selecting an Upgrade you agree to pay InsIQ the monthly or annual subscription fees marked for that service.
    Upgrade fees are not refundable.</p>
  <h4>Automatic Renewal</h4>
  <p>Upgrades can be canceled at any time in the Upgrades section of your site’s dashboard. Unless you notify InsIQ
    before the end of the applicable subscription period that you want to cancel an Upgrade, your Upgrade subscription
    will automatically renew and you authorize us to collect the then-applicable annual or monthly subscription fee (as
    well as any taxes) using any credit card or other payment mechanism we have on record for you. </p>

  <h3>AI Credits Access</h3>
  <h4>Fees- Payment</h4>
  <p>By signing up for the insiq.ai site AI Credits, you agree to pay InsIQ the specified monthly fees in exchange for
    access
    to the products and services we provide for that specific package. Applicable fees will be invoiced starting from
    the day your access is established and in advance of using such services. InsIQ reserves the right to change the
    payment terms and fees thirty (30) days before to written notice to you. AI Credits access can be cancelled by you
    at any
    time on 30 days written notice to InsIQ.</p>
  <h4>Permitted Use</h4>
  <p>You may use the insiq.ai AI Credits to develop products or services using our data, provided that the data is not
    used in
    ways that violate the Terms of Services herein and all other policies and agreements governed by InsIQ. </p>
  <h4>Restricted Use</h4>
  <p>You may not use the insiq.ai AI Credits to substantially replicate products or services offered by InsIQ. If InsIQ
    believes, in its sole discretion, that you have violated or attempted to violate these conditions, your privilege to
    use and access the insiq.ai AI Credits may be temporarily or permanently revoked, with or without notice.</p>

  <h3>Content Posted on Other Websites</h3>
  <p>We cannot review all of the material, including computer software, made available through the websites and webpages
    to which insiq.ai links, and that link to InsIQ. The site of InsIQ does not have any authority over those
    websites and webpages and is not liable for their contents or their use. By linking to an external website or
    webpage, InsIQ does not represent or imply that it endorses such a website or webpage. You are responsible for
    taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses,
    and other harmful or destructive content. InsIQ disclaims any responsibility for any harm resulting from your use
    of external websites and web pages.</p>
  <h3>Copyright Infringement</h3>
  <p>As InsIQ asks others to respect its intellectual property rights, it respects the intellectual property rights of
    others. If you believe that material located on or linked to by InsIQ violates your copyright, you are encouraged
    to notify InsIQ following InsIQ’s Digital Millennium Copyright Act (“DMCA”) Policy. InsIQ will respond to all
    such notices, including removing the infringing material or disabling all links to the infringing material. A
    notification of claimed copyright infringement should be emailed to the Company’s Copyright Agent at
    support@insiq.io. </p>
  <h3>Intellectual Property</h3>
  <p>InsIQ, insiq.ai, the InsIQ logo, and all other trademarks, service marks, graphics, and logos used in connection
    with insiq.ai, or the Website are trademarks or registered trademarks of InsIQ or InsIQ’s licensors. Other
    trademarks, service marks, graphics, and logos used in connection with the Website may be the trademarks of other
    third parties. Your use of the Website grants you no ownership or license to reproduce or otherwise use any InsIQ
    or third-party trademarks.</p>
  <h3>License to Use Customer Data</h3>
  <p>By using the products or services of InsIQ, you (the “Customer”) grant InsIQ (the “Provider”)</p>
  <p>
    (i) a non-exclusive, non-transferable (except as permitted herein), royalty-free, perpetual, and fully-paid license
    to use, reproduce, modify and transmit Data provided by Customer</p>
  <p>(ii) a non-exclusive, non-transferable (except as permitted herein), perpetual, royalty-free, fully-paid license to
    use, reproduce, display, modify, create derivative works of, disclose and distribute any user data derived by
    Provider from the provision of Services to Customer, to perform the Services listed in the applicable Order Form,
    including improving Software and the Services, provided that any disclosure of the Usage Data to any third party
    hereunder is in an aggregate form.</p>
  <h3>Hosting Services</h3>
  <p>Provider agrees to provide the hosting services described herein and as may be outlined in more detail in the
    applicable Order Form, including the right of Customer to access, view, download, transmit and use all customer data
    hosted by Provider. Provider hereby grants Customer a limited, non-exclusive, non-transferable, worldwide right to
    use and access the Software, solely for Customer’s own internal business purposes, subject to the Terms of Services
    of this Agreement, and provided that all fees due and payable under this Agreement have been paid by Customer to
    Provider. Unless agreed to otherwise by the parties, the Software shall be hosted on hardware owned, operated, and
    managed by Provider at Provider’s facilities, or facilities owned and operated by authorized outsourcers and
    contractors of Provider under a written agreement with Provider. </p>
  <h3>All rights not expressly granted to Customer are reserved by Provider
  </h3>
  <p>Unless precisely authorized by Provider, Customer may not access Software or hardware for purposes of monitoring
    its availability, performance, or functionality, or for any other competitive purpose. Customer shall not:</p>
  <p> (i) license, sublicense, sell, resell, transfer, assign, distribute, or otherwise commercially exploit or make
    available to any third party any of the Software or hardware in any way</p>
  <p>(ii) alter or make derivative works based upon Software</p>
  <p>(iii) create Internet “links” to Software or “frame” or “mirror” any content on any other server or wireless or
    Internet-based device</p>
  <p>(iv) reverse engineer or access Software to <br>&emsp;(a) build a new competitive product or service, <br>&emsp;(b)
    build a product
    using similar ideas, features, functions, or graphics of Software, or <br>&emsp;(c) replicate any ideas, features,
    functions,
    or graphics of Software. </p>
  <p>The Customer may use the Software only for its internal business purposes and shall not: <br>&emsp;(1) send spam or
    otherwise
    duplicative or unsolicited messages in violation of applicable laws.<br>&emsp;(2) send or store infringing,
    obscene,
    threatening, defamatory, or otherwise illegal or tortious material, including material harmful to children or that
    violates a third party's privacy rights.<br>&emsp;(3) send or store material containing software viruses, worms,
    Trojan
    horses, or other harmful computer code, files, scripts, agents, or programs.<br>&emsp;(4) interfere with or disrupt
    the
    integrity or performance of the Software, or the data contained therein or<br>&emsp;(5) attempt to gain
    unauthorized
    access
    to Software or its related systems or networks. Provider shall have the right to immediately suspend access to the
    Software in the event Customer’s use or access of the Software results in a risk of loss or damage to the Software,
    Provider’s other systems, or the data or property of any other of Provider’s customers.</p>
  <h3>Audit of Software Usage</h3>
  <p>InsIQ will have the right, upon reasonable prior written notice to Customer, to conduct on its own or to engage
    the third party of its choosing to audit Customer’s use of the Software and all related backup files, to verify
    compliance with this Agreement. The audit will be conducted at Provider’s expense, unless the audit reveals that
    Customer has failed to pay Fees consistent with its actual use of the Software, in which case Customer will
    reimburse Provider for all reasonable costs and expenses incurred by Provider in connection with such audit,
    together with any applicable Fees.</p>
  <h3>Changes to This Policy</h3>
  <p>We reserve the right to change this Policy whenever necessary. Please check back occasionally for updates. Remember
    that when you use the Public Site or the InsIQ Services, you are agreeing to this Policy that is present at that
    time. </p>

  <h3>General Representation and Warranty</h3>
  <p>You represent and warrant that (i) your use of the Website will be in strict accordance with the InsIQ Privacy
    Policy, with this Agreement, and with all applicable laws and regulations (including without restriction to any
    local laws or regulations in your country, state, city, or other governmental areas, regarding online conduct, and
    including all applicable laws regarding the transmission of technical data exported from the country in which you
    reside) and (ii) your use of the Website will not infringe or misappropriate the intellectual property rights of any
    third party.</p>

  <h3>Indemnification</h3>
  <p>You agree to remunerate and hold innocuous InsIQ, its contractors, its licensors, and their respective directors,
    officers, employees, and agents from and against any claims and expenses arising out of your use of the Website,
    including but not limited to your violation of this Agreement.</p>

  <h3>Miscellaneous</h3>
  <p>This Agreement constitutes the entire agreement between InsIQ(Provider) and you(Customer) concerning the subject
    matter hereof, and they may only be modified by the posting by InsIQ of a revised version as mentioned above.
    Except for claims for injunctive or unbiased relief or claims regarding intellectual property rights (which may be
    brought in any competent court without the posting of a bond), any dispute arising under this Agreement shall be
    finally settled following the Comprehensive Arbitration Rules of the Judicial Arbitration and Mediation Service,
    Inc. (“JAMS”) by three arbitrators appointed under such rules. The predominant party in any action or proceeding to
    enforce this Agreement shall be entitled to costs and attorneys’ fees. If any part of this Agreement is held invalid
    or unenforceable, that part will be construed to reflect the parties original intent, and the remaining portions
    will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any
    breach thereof, in any instance, will not waive such term or condition or any subsequent breach thereof. You may
    assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its Terms of
    Services; InsIQ may assign its rights under this Agreement without condition. This Agreement will be imperative
    upon and will accustom to the benefit of the parties, their successors, and permitted assigns.</p>

  <h3>Contact Us</h3>
  <p>If you have any concerns or queries with any of the terms within the Privacy Policy here, contact us at
    support@insiq.io</p>
</ng-template>

<ng-template #subterms>
  <h1 class="title">Subscription Terms and Conditions/Policies</h1>

  <h4>a. Subscription Plans:
  </h4>
  <p>InsIQ offers three subscription plans: Free, Pro, and Business. Each plan offers a distinct set of features and
    capabilities as detailed on our pricing page.

  </p>
  <h4>b. Subscription Period:
  </h4>
  <p>Unless otherwise stated, all subscriptions are billed on a monthly basis. The subscription period starts from the
    date of the initial purchase and renews every month on the same date.

  </p>
  <h4>c. Upgrades & Downgrades:
  </h4>
  <p>Users can upgrade or downgrade their subscription at any time. Changes will take effect from the next billing
    cycle.
  </p>
  <h4>d. Renewals:
  </h4>
  <p>All subscriptions are set to auto-renew by default. Users will be notified prior to the renewal date.


  </p>


</ng-template>

<ng-template #payterms>
  <h1 class="title">Payment Terms and Conditions/Policies </h1>

  <h4>a. Payment Methods:

  </h4>
  <p>We accept major credit cards and other select payment methods.


  </p>
  <h4>b. Billing:

  </h4>
  <p>Subscriptions are billed in advance for the upcoming month. Any additional charges incurred (e.g., for additional
    features) will be billed separately.



  </p>
  <h4>c. Invoices:

  </h4>
  <p>Electronic invoices will be sent to the registered email address. It's the user's responsibility to keep their
    email address updated in our system.


  </p>
  <h4>d. Failed Payments:

  </h4>
  <p>In the event of a failed payment, users will be notified and given a grace period to update their payment details.
    If the payment isn't made within the grace period, the subscription may be suspended.


  </p>
  <h4>e. Refunds:


  </h4>
  <p>Any requests for refunds should be made within 7 days of the billing date.


  </p>

</ng-template>

<ng-template #cancelterms>
  <h1 class="title">Cancellation Policy </h1>

  <h4>a. Cancellation by User:


  </h4>
  <p>Users can cancel their subscription at any time through their account settings. Upon cancellation, the user will
    retain access to their subscription until the end of the current billing cycle.



  </p>
  <h4>b. Cancellation by InsIQ:


  </h4>
  <p>InsIQ reserves the right to suspend or terminate accounts that violate our terms of service. In such cases, users
    will be notified and given a chance to rectify the issue.



  </p>
  <h4>c. No Refunds on Cancellation:


  </h4>
  <p>If a user cancels their subscription before the end of a billing cycle, they will not receive a refund for any
    unused days in the cycle.



  </p>
  <h4>d. Data Retention:


  </h4>
  <p>Upon cancellation, user data will be retained for a period of 30 days, after which it may be permanently deleted.
    Users are encouraged to export their data before cancelling if needed.

  </p>


</ng-template>

<ng-template #privacyPolicy>
  <h1 class="title">Privacy Policy</h1>

  <p>InsIQ policy is to respect your privacy regarding any information we may access from you while operating our
    website. This Privacy Policy applies to both users and visitors who access the Public Site, thus creating an
    environment of honesty and transparency from our side. Kindly read the agreement adequately before using our website
    for any cause. Using the InsIQ site or accessing our services implies that you have agreed to the mentioned
    approvals to our terms. Any activity done on the site will be in accordance with the Terms and Conditions mentioned
    on this accord.</p>
  <ol>
    <li class="subTitle">
      <h2>This Privacy policy asserts that:</h2>
      <ol>
        <li>
          We do not ask you for personal information unless necessary while operating our website.
        </li>
        <li>
          We do not share your personal information with anyone except to concede with the law, develop our products to
          enhance your experience here or protect our rights.
        </li>
        <li>
          We do not store personal information on our servers unless required for the ongoing operation of any one of
          our services.
        </li>

      </ol>
    </li>
    <p>If you do not want InsIQ to collect, use or share your details as mentioned in the Policy, you may not use our
      Public site or access our services.</p>


    <h3>What does InsIQ do?</h3>
    <p>InsIQ is an AI-powered Product Development platform that transforms and sustains ideas into successful products.
      A SaaS tool combining the power of Artificial Intelligence, unstructured data and a system of best practices to
      enable product intelligence insights and optimal decisions at every stage in the product development journey.</p>
  </ol>
  <h3>How InsIQ Uses Your Information Entered into The Public Site?</h3>
  <ol>
    <li class="subTitle">
      <h2>When you purchase or have access to our products and services, we will collect your Log-in Credentials,
        including your first name, last name, and email address, mainly for:
      </h2>

      <ol>
        <li>
          Responding to inquiries
        </li>
        <li>
          Processing requests
        </li>
        <li>
          Placing orders-provide you with aid as a user of InsIQ services
        </li>
        <li>Contacting you about InsIQ Services</li>
      </ol>
    </li>

    <li class="subTitle">
      <h2>Through cookies and similar technologies:
      </h2>

      <ol>
        <li>
          Compile statistics on how the Public Site is used.
        </li>
        <li>
          Measure site errors
        </li>
        <li>
          Improve the User experience of the Public Site
        </li>
        <li>Improve InsIQ’s products and services </li>
        <li>Market InsIQ’s services to you</li>
      </ol>
    </li>


    <li class="subTitle">
      <h2>Other personal data we collect are:
      </h2>

      <ol>
        <li>
          Purchase History – purchases you have made (or that which have been made for you), when your subscriptions
          expire and/or renew. </li>
        <li>
          Billing information – if provided, your credit card number and payment details if you make payment for the
          products and services this way. </li>

      </ol>
    </li>


    <li class="subTitle">
      <h2>We also collect some of your information from third parties like:
      </h2>

      <ol>
        <li>
          The organization(s) to which you belong or person(s) who have arranged your access to our products and
          services </li>
        <li>
          Our partners and service providers in connection with your use, and that fall under the provision of our
          products and services </li>
        <li>
          Publicly available sources like academic publications, patent offices, and regulatory agencies
        </li>

      </ol>
    </li>
  </ol>
  <h3>How does InsIQ Share Your Information?</h3>
  <p>InsIQ may share your information with our offices in UK, California, and India, in order to service your inquiries
    and requests as well as to service you as a User per our Agreement.
  </p>
  <p>We will not rent, sell, or share your information with anyone other than as entitled in this Privacy Policy, with
    the following exception: </p>
  <p>InsIQ may disclose your information without notice to you if, in InsIQ’s reasonable business judgment, such
    disclosure is necessary (a) to comply with applicable laws or to respond to subpoenas, warrants, or regulatory
    requirements, or (b) to protect or to defend InsIQ’s rights or property. </p>

  <h3>We Ensure Your Security</h3>
  <p>InsIQ takes all suitable and critical technical and organizational security measures against the unauthorized or
    wrongful use of your information, and has implemented and will continue to monitor and update proper technical and
    organizational measures to ensure a level of security, relevant to the risk.</p>
  <h3>Children's Policy</h3>
  <p>The Policy here at InsIQ does not cover children below 13, nor do they have access to using our services
    knowingly. If we unknowingly collected the details of children under the mentioned age limit without parental
    consent, we will erase the data with immediate effect. If you believe that any of our users are not of the
    appropriate age group, kindly contact us as set forth below. </p>
  <h3>Contact Us</h3>
  <p>If you have any concerns or queries with any of the terms within the Privacy Policy here, contact us at
    support@insiq.io</p>
  <h3>Changes to This Privacy Policy</h3>
  <p>We reserve the right to change this Privacy Policy whenever necessary. Please check back occasionally for updates.
    Remember that when you use the Public Site or the InsIQ Services, you are agreeing to the Privacy Policy that is
    present at that time.
  </p>
</ng-template>

<ng-template #cookies>
  <h1 class="title">Cookies Policy</h1>
  <h2 class="subTitle">Google Analytics</h2>

  <p>
    Google Analytics is a web analytics tool that helps website owners understand how visitors engage with their
    website. Google Analytics customers can view a variety of reports about how visitors interact with their website
    so they can improve it. Google Analytics collects information anonymously. It reports website trends without
    identifying individual visitors.
  </p>
</ng-template>