<div class="w-100 h-100 loginMain">
  <div class="loginWrapper">


    <div class="card loginCard">
      <div class="cardHeader">
        <div class="title">Reset Password</div>
        <div class="description">
          <span>Have Password? </span>
          <a [routerLink]="['/login']" [queryParams]="{url: view.returnUrl}" class="link f-bold">Sign In</a>
        </div>
      </div>
      <div class="cardContent">
        <form [formGroup]="view.form" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-24">
              <div class="form-group">
                <div class="fc-group __o" [ngClass]="{hasError: view.invalid('inputPwd')}">
                  <input appFcUi [type]="isPasswordReveal ? 'text': 'password'" name="Password" id="password"
                    formControlName="inputPwd" class="fc" placeholder="Password">
                  <div class="ic ev" (click)="isPasswordReveal = !isPasswordReveal" tabindex="0">
                    <i class="i" aria-label="password">{{isPasswordReveal ? 'visibility_off' : 'visibility'}}</i>
                  </div>
                </div>
                <mat-error *ngIf="view.invalid('inputPwd')">{{view.error('inputPwd')}}</mat-error>
              </div>
            </div>
            <div class="col-md-24">
              <div class="form-group">
                <div class="fc-group __o" [ngClass]="{hasError: view.form.hasError('notSame')}">
                  <input appFcUi [type]="isPasswordReveal1 ? 'text': 'password'" name="Password" id="password"
                    formControlName="cpassword" class="fc" placeholder="Confirm Password">
                  <div class="ic ev" (click)="isPasswordReveal1 = !isPasswordReveal1" tabindex="0">
                    <i class="i" aria-label="password">{{isPasswordReveal1 ? 'visibility_off' : 'visibility'}}</i>
                  </div>
                </div>
                <mat-error *ngIf="view.form.hasError('notSame')">Password and Confirm password does not match
                </mat-error>
              </div>
            </div>


          </div>

          <div class="d-flex m-t-20">
            <button class="btn btn-{{btnStatus()}} btn-lg w-100 t-c" [disabled]="view.invalid()" matRipple>
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>