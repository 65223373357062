import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { View, Config, FormDefault } from '../../config';
import { SharedService } from './shared.service';
import { SharedProjectService } from '../project/sharedproject.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';

@Injectable({
  providedIn: 'root'
})
export class ObservationView {
  requirementTypeItemCodes: any;
  requirementTypeItemCodesAll: any;
  requirementTypeCodes; any;
  url = 'discoveryobservation';
  observations;
  discoveryKeyplayers;
  public view: View;
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    public ss: SharedService,
    public ssp: SharedProjectService,
    ) {
    this.view = new View(http, router, route, alertService);
    this.ss.setObservationView(this);
  }

  setForm() {

    this.view.initForm(this.url, this.getForm(), this.getNewDefault(), false);
    this.view.get('requirementtype/all').subscribe(res => {
      this.requirementTypeCodes = res;
      for (let x = 0; x < this.requirementTypeCodes.length; x++) {
        let item = this.requirementTypeCodes[x];
        item['child'] = "observation"; //to make values same as ss.requirementType
      }
      this.ss.requirementType['count'] = 1;
    });

    this.view.get(`requirementtypeitem/all`).subscribe(res => {
      this.requirementTypeItemCodesAll = res;
      this.rtItemEnabled(this.ss.requirementType.id);
    });
    this.discoveryKeyplayers = this.ss.kp.view.list;
    // this.getList();
    this.newform();
    this.view.state = 'list';
    this.view.refreshList = false;
    Config.isProjectSelected = true;
  }

  getNewDefault() {
    return new FormDefault(
      ['projectId', 'keyplayerId', 'requirementTypeCode'],
      [
        this.ssp.getProject(),
        this.ss.keyPlayer,
        this.ss.requirementType
      ]);
  }

  newform() {
    this.view.newform();
    // console.log(this.ss.getProjectId());
    // this.view.set('projectId', this.ss.getProject());
    // this.view.set('keyplayerId', new KeyPlayer(this.ss.keyplayerId));
    // this.view.set('requirementTypeCode', this.ss.requirementTypeCode);
  }

  save() {
    if (this.isFormValid()) {
      this.view.saveCallback(() => {
        this.ss.getRequirementTypeView().getList();
        this.view.state = 'edit';
      });
    }
  }

  edit(row) {
    this.view.edit(row);
    this.rtItemEnabled(row.requirementTypeCode.id);
  }

  saveAndAdd() {
    if (this.isFormValid()) {
      this.view.saveAndAddCallback(() => {
        this.ss.getRequirementTypeView().getList();
        // this.ss.getRequirementGroup().getList();
      });
    }
  }
  private isFormValid() {
    if (this.rtEnabled && this.view.value('requirementTypeItemCode') === null) {
      this.view.alertService.error('Please select a Requirement Sub-Type');
      return false;
    } else {
      this.view.set('label', this.view.value('keyplayerName'));
      if (this.view.value('requirementTypeItemCode') === null) {
        if (this.requirementTypeItemCodes.length === 1) {
          this.view.set('requirementTypeItemCode', this.requirementTypeItemCodes[0]);
          this.view.set('requirementTypeCode', this.requirementTypeItemCodes[0].requirementTypeCode);
        }
      }
    }
    return true;
  }
  cancel() {
    this.getList();
    this.ss.getRequirementTypeView().getList();
    this.view.cancel();
  }

  getList() {
    this.loadList(this.ss.keyPlayer.id, this.ss.requirementType.id);
  }
  
  private loadList(kpId, rtId) {
    this.view.get(`${this.url}/all/${this.ssp.getProjectId()}/${kpId}/${rtId}`).subscribe(res => {
      this.view.list = res;
    });
  }
  loadObservations() {
    this.view.get(`discoveryobservation/all/${this.ssp.getProjectId()}/${this.ss.keyPlayer.id}/${this.ss.requirementType.id}`).subscribe(res => {
      this.observations = res;
    });
  }

  rtEnabled = true;
  private rtItemEnabled(id) {
    this.requirementTypeItemCodes = this.requirementTypeItemCodesAll.filter(u => u.requirementTypeCode.id === id);
    if (this.requirementTypeItemCodes.length === 1) {
      this.rtEnabled = !(this.requirementTypeItemCodes[0].description === '');
    } else {
      this.rtEnabled = true;
    }
  }

  validAI() {
    return (!this.rtEnabled || this.view.value('requirementTypeItemCode') !== null);
  }

  getForm() {
    return this.fb.group({
      id: [''],
      label: [''],
      keyplayerName: ['', Validators.required],
      observation: ['', Validators.required],
      requirementTypeCode: [''],
      requirementTypeItemCode: [''],
      //      filterRequirementTypeCode:[''], // Dummy for filter
      keyplayerId: [''],
      projectId: ['']
    });
  }

  filterRT(event) {
    this.rtItemEnabled(event.value.id);
    this.loadList(this.view.value('keyplayerId').id, event.value.id);
  }

  filterKeyplayer(event) {
    this.loadList(event.value.id, this.view.value('requirementTypeCode').id)
  }
}
