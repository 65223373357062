export const OAuthSettings = {
  appId: 'd39209e1-94d4-4fdf-b3a8-3bbfc19652e6',
  scopes: [
    "user.read",
    "calendars.read",
    "User.Read.All",
    "Directory.Read.All",
    "User.ReadWrite.All",
    "Directory.ReadWrite.All",
    "Directory.AccessAsUser.All",
    "Group.ReadWrite.All"
    
  ]
};
