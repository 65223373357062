import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Project } from '../../_models/project';
import { ProjectComponent } from '../project/project.component';
import { View } from 'src/app/config';
import { Config } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class SharedProjectService {

  pro: ProjectComponent;
  project: Project;
  private projectId: number;
  projectCount: number;

  constructor(
    private view: View,
    private router: Router,
    private config: Config,
  ) {
  }

  linEvHand(e) {
    //  console.log(e);
    const [link] = e;
    if (link === 'new-project' && this.pro !== undefined) {
      // this.view.state = (this.router.url === '/new-project') ? 'new' : 'list';
      this.pro?.newform();
    } else if (link === 'project') {
      if (this.router.url === '/project') {
        this.view.state = 'list';
      } else {
        this.pro?.newform();
      }
    }
  }

  setProject(project) {
    this.project = new Project(
      project.id,
      project.productName,
      project.mode,
      project.productNewOrExisting,
      project.productDescription,
      project.productTypeId,
      project.customer,
      project.productBenifit,
      project.targetRegion,
      project.similarProduct,
      project.inspiration,
      project.brandName,
      project.competitors,
      project.competitorspdt,
      project.companyname,
      project.apiLanguage,
      project.problemSolve,
      project.productEnviornment,
      project.productChannel,
      project.prodnVol,
      project.manufacturer,
      project.productRegulation,
      project.sellRate,
      project.keyword31,
      project.keyword6,
      project.keyword10,
      project.keyword2,
      project.keyword32,
      project.keyword8,
      project.keyword11,
      project.keyword7,
      project.keyword5,
      project.keyword4,
      project.keyword9
    );
    localStorage.setItem('projectSelected', JSON.stringify(project));
    this.setProjectId(project.id);
  }
  getProject() {
    if (!this.projectId || this.projectId === null || this.projectId === undefined) {
      this.project = JSON.parse(localStorage.getItem('projectSelected'));
      if (!this.project || this.project === null || this.project === undefined) {
        let isAdminUrl = location.host.includes('admin');
        if (isAdminUrl && this.config.isAdmin()) {
          this.router.navigate(['/subscribers']);
        } else {
          this.router.navigate(['/']);
        }
      }
      this.projectId = this.project.id;
    }
    return this.project;
  }

  setProjectId(id) {
    this.projectId = id;
  }

  getProjectId() {
    return this.getProject().id;
  }
}
