import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';


@Component({
  selector: 'app-patent-dialog',
  templateUrl: 'patent-dialog.html',
  styleUrls: ['patent-dialog.scss']
})
export class PatentDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<PatentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public router: Router,
 
  ) {
  }

  backtoPage() {
    this.dialogRef.close();
  }
  backtoHome() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }

}
