<div class="container-fluid d-flex h-100">
  <div class="wrapper">
    <div class="leftSection loginWrap">
      <div class="d-flex align-items-center t-c h-100">
        <div class="form">
          <figure class="w-40 m-x-auto">
            <img src="./assets/images/contactus.png" alt="contact us">
          </figure>
          <h2 class="m-b-0 font-weight-bold text-uppercase">Contact Us</h2>
          <form [formGroup]="view.form" (ngSubmit)="onSubmit()">
            <mat-form-field class="w-100">
              <input type="text" matInput placeholder="Name" formControlName="name">
              <mat-error *ngIf="view.invalid('name')">{{error('name')}}</mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <input type="email" matInput placeholder="Email ID *" formControlName="email">
              <mat-error *ngIf="view.invalid('email')">{{view.error('email')}}</mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <input type="text" matInput placeholder="Phone" formControlName="phone">
            </mat-form-field>

            <mat-form-field class="w-100">
              <textarea matInput cdkTextareaAutosize placeholder="Message" #autosize="cdkTextareaAutosize"
                formControlName="mess" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
              <mat-error *ngIf="view.invalid('mess')">{{view.error('mess')}}</mat-error>
            </mat-form-field>

            <div class="m-b-15 m-x--20">
              <p-captcha [siteKey]="captchaKey" (onResponse)="showResponse($event)"></p-captcha>
            </div>
            <button type="submit" [disabled]="view.invalid() || !captcha" matRipple
              class="btn btn-lg btn-primary m-b-15 w-100 t-c">Submit</button>
          </form>
          <a [routerLink]="['/']" [queryParams]="{url: view.returnUrl}" class="btn btn-lg btn-link" matRipple>Go
            Back</a>
        </div>
      </div>
    </div>
    <div class="rightSection mainWrap" tabindex="0">
      <!-- <div class="multilineInput">
        <div class="scroller">
          <textarea rows="{{ textareaRows }}" (input)="updateTextareaRows($event.target.value)"
            placeholder="Product Description"></textarea>
          <div class="hidden-textarea">{{ textareaContent }}</div>
        </div>
      </div> -->
      <div class="describe">
        <picture>
          <source media="(max-width: 280px)" srcset="./assets/images/screenshot.png">
          <img src="./assets/images/screenshot.png" alt="screenshot">
        </picture>
        <!-- <h1 class="h1 f-bold">InsIQ</h1> -->
        <figure class="logo">
          <img src="./assets/images/icon-insiq.png" alt="insiq">
        </figure>
        <p>
          AI-powered Product Development for everyone. Everything you need to transform your idea into a successful
          product.
        </p>

        <!-- <div class="bar-T p-y-10 m-b-30">
          <a href="https://www.insiq.io/about/" target="_blank" class="p-r-20">About Us</a>
          <a [routerLink]="['/contact']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Contact Us</a>
           <a [routerLink]="['/terms-and-conditions']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Terms and Conditions</a>
          <a [routerLink]="['/help']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Help</a>
        </div>-->

        <div class="bar-T p-y-10 m-b-30">
          <a href="https://www.insiq.io" target="_blank" class="p-r-20">About Us</a>
          <!-- <a [routerLink]="['/contact']" [queryParams]="{
            url: view.returnUrl
          }" class="p-r-20">Contact Us</a> -->
          <a (click)="openTnC('terms')" class="p-r-20">Terms of Service</a>
          <a href="https://www.insiq.io" target="_blank" class="p-r-20">Help</a>
          <!-- <a href="https://www.insiq.io/pricing/#faq_prcng" target="_blank" class="p-r-20">FAQ</a> -->
        </div>

        <div class="copyright">
          <span>&copy; {{config.currentYear}} InsIQ. All rights reserved</span>
          <br>
          <a href="https://www.insiq.io" target="_blank">www.insiq.io</a>
        </div>
      </div>
    </div>
  </div>
</div>