<div class="container-fluid d-flex h-100">
  <div class="wrapper" [ngClass]="!ai.display ? 'active' : 'hidden'">
    <app-sub-header [data]="header" (aiEvent1)="ai.smartDev(1,kp.view, ssp.getProject())"  (aiEvent2)="ai.smartDev(2,kp.view, ssp.getProject())"  (aiEvent3)="ai.smartDev(3,kp.view, ssp.getProject())"  (aiEvent4)="ai.smartDev(4,kp.view, ssp.getProject())"
      (aiFav)="ai.wishList(kp.view, ssp.getProject(), '1')"></app-sub-header>

    <mat-tab-group mat-stretch-tabs class="pageTabs mat-elevation-z4 w-100"
      [ngClass]="!ai.display ? 'active' : 'hidden'">
      <mat-tab label="Overview">
        <!-- TreeView -->
        <div class="conArea" [ngClass]="isList() ? 'active' : 'hidden'">
          <app-smart-tree (createNode)="newConnectNode($event)" (nodeOpen)="onNodeOpen($event)"
            (nodeClose)="onNodeClose($event)" [data]="data" [helpTemplate]="[tipWhatIsKP, tipWhatIsRT]">
            <ng-template #tipWhatIsKP>
              <div class="helpContent">
                <div class="heading">
                  Keyplayer
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint quis maiores tempora fuga animi facere?
                  Possimus nostrum ipsum fugit dolores saepe animi vitae, sint, magnam quidem a, earum voluptatem modi!
                </p>
                <div class="note">
                  E.g.: - Parents, Kids
                </div>
              </div>
            </ng-template>
            <ng-template #tipWhatIsRT>
              <div class="helpContent">
                <div class="heading">
                  Requirement Type
                </div>
                <p>RT: Requirement Type</p>
                <div class="note">
                  E.g.: - Behavioral / Visceral, Higher Order Cognitive, Error proofing / Safety
                </div>
              </div>
            </ng-template>
          </app-smart-tree>
        </div>

        <!-- Popups -->
        <div class="conArea p-x-20 p-t-20 p-b-30" *ngIf="!isList()">
          <!-- KEYPLAYER -->
          <div class="row " *ngIf="kp.view.isForm()">
            <div class="col-sm-5" [ngClass]="!ai.display ? 'active' : 'hidden'">
              <app-tree-preview [data]="kp.view" title="Keyplayer"></app-tree-preview>
            </div>
            <div class="col-sm-19">
              <div class="workArea">
                <div [ngClass]="!ai.display ? 'active' : 'hidden'">
                  <mat-card class="popup">
                    <form [formGroup]="kp.view.form" novalidate>
                      <div class="popHeader d-flex align-items-center">
                        <button mat-button type="button" class="back ib-m p-x-0" (click)="kp.cancel()" title="Back"
                          matTooltipPosition="below">
                          <i class="ib-m i">back</i>
                        </button>
                        <div class="spacer"></div>
                        <span class="ib-m title">KeyPlayer</span>
                        <app-quick-help class="ib-m" [layout]="tipKeyPlayer" [tour]="true"></app-quick-help>
                        <div class="spacer"></div>
                        <!-- <button *ngIf="kp.view.isEdit()" mat-raised-button (click)="ai.wishList(kp.view, ssp.getProject(),'3')" class="ib-m m-r-20 curved text-primary t-normal f-400">
                          <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                          <span class="ib-m">Fav</span>
                        </button>
                        <button type="button" mat-raised-button *ngIf="kp.view.isEdit()" class="ib-m m-r-20 curved text-primary t-normal f-400"
                          (click)="ai.smartDevKp(kp.view, ssp.getProject())">
                          <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                          <span class="ib-m">AI KeyPlayer Insights</span>
                        </button> -->
                        <!-- <button type="button" mat-icon-button class="close ib-m" (click)="kp.cancel()" title="Close"
                          matTooltipPosition="below">
                          <mat-icon fontSet="i" class="i-close"></mat-icon>
                        </button> -->
                      </div>
                      <mat-card-content>
                        <div class="popContent">
                          <div class="filterArea" *ngIf="kp.view.isNew()">
                            <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
                              <div class="m-b-0">
                                <label class="d-block"><small>Keyplayer Category</small></label>
                                <p-dropdown [options]="kp.keyplayerCategoryCodes"
                                  formControlName="keyplayerCategoryCode" (onChange)="kp.filterKeyplayer($event)"
                                  placeholder="Keyplayer Category" optionLabel="description"></p-dropdown>
                                <small class="mat-error"
                                  *ngIf="kp.view.invalid('keyplayerCategoryCode')">{{kp.view.error('keyplayerCategoryCode')}}</small>
                              </div>
                            </mat-expansion-panel>
                          </div>

                          <div class="infoBar" *ngIf="kp.view.isEdit()">
                            <label class="fw"><small>Keyplayer Category</small></label>
                            <div class="o-80">
                              {{kp.view.value('keyplayerCategoryCode').description}}
                            </div>
                          </div>

                          <mat-form-field class="m-b-15 fw">
                            <input matInput placeholder="Keyplayer" formControlName="keyplayer" maxlength="255">
                            <mat-error *ngIf="kp.view.invalid('keyplayer')">{{kp.view.error('keyplayer')}}</mat-error>
                          </mat-form-field>

                          <mat-form-field class="fw m-b-15">
                            <mat-label>Keyplayer Description</mat-label>
                            <textarea matInput formControlName="description" cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"
                              maxlength="1000"></textarea>
                            <mat-error *ngIf="kp.view.invalid('description')">{{kp.view.error('description')}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                      </mat-card-content>
                      <mat-card-actions class="t-c p-y-5">
                        <button type="button" (click)="kp.cancel()" mat-raised-button color="warn"
                          class="curved m-x-5">Cancel</button>
                        <button type="submit" (click)="kp.save()" [disabled]="kp.view.invalid()" *ngIf="kp.view.isNew()"
                          mat-raised-button color="primary" class="curved m-x-5">Save</button>
                        <button type="submit" (click)="kp.saveAndAdd()" [disabled]="kp.view.invalid() "
                          *ngIf="kp.view.isNew()" mat-raised-button color="primary" class="curved m-x-5">Save &
                          Add</button>
                        <button type="submit" (click)="kp.save()" [disabled]="kp.view.invalid()"
                          *ngIf="kp.view.isEdit()" mat-raised-button color="primary"
                          class="curved m-x-5">Update</button>
                      </mat-card-actions>
                    </form>
                  </mat-card>
                </div>
                <!-- keyplayer smart dev end-->
              </div>
            </div>
          </div>

          <!-- Insights -->
          <div class="row " *ngIf="ob.view.isForm()">
            <div class="col-sm-5">
              <app-tree-preview [data]="ob.view" title="KeyPlayer Insight"></app-tree-preview>
            </div>
            <div class="col-sm-19">
              <div class="workArea">
                <mat-card class="popup">
                  <form [formGroup]="ob.view.form" novalidate>
                    <div class="popHeader d-flex align-items-center">
                      <button mat-button type="button" class="back ib-m p-x-0" (click)="ob.cancel()" title="Back"
                        matTooltipPosition="below">
                        <i class="ib-m i">back</i>
                      </button>
                      <div class="spacer"></div>
                      <span class="ib-m title">KeyPlayer Insight</span>
                      <app-quick-help class="ib-m" [layout]="tipKeyPlayerInsight" [tour]="true"></app-quick-help>
                      <div class="spacer"></div>
                      <button type="button" mat-raised-button (click)="ai.wishList(kp.view, ssp.getProject(),'5')"
                        class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">Fav</span>
                      </button>
                      <button type="button" [disabled]="!ob.validAI() && ob.view.isNew()" mat-raised-button
                        class="ib-m m-r-20 curved text-primary t-normal f-400"
                        (click)="ai.smartDevKpInsight(ob.view, ssp.getProject(), ss.requirementType, ss.keyPlayer)">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">AI KeyPlayer Insights</span>
                      </button>
                      <!-- <button type="button" mat-icon-button class="close ib-m" (click)="ob.cancel()" title="Close"
                        matTooltipPosition="below">
                        <mat-icon fontSet="i" class="i-close"></mat-icon>
                      </button> -->
                    </div>
                    <mat-card-content class="popContent">
                      <div class="filterArea" *ngIf="ob.view.isNew()">
                        <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
                          <div class="d-flex">
                            <div class="ib-m">
                              <label class="fw"><small>Keyplayer Type</small></label>
                              <p-dropdown [options]="ob.discoveryKeyplayers" (onChange)="ob.filterKeyplayer($event)"
                                formControlName="keyplayerId" placeholder="Keyplayer" optionLabel="label">
                              </p-dropdown>
                            </div>
                            <div class="spacer"></div>
                            <div class="ib-m">
                              <label class="fw"><small>Requirement Type</small></label>
                              <p-dropdown [options]="ob.requirementTypeCodes" (onChange)="ob.filterRT($event)"
                                formControlName="requirementTypeCode" placeholder="Requirement Type"
                                optionLabel="label">
                              </p-dropdown>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </div>


                      <div class="infoBar" *ngIf="ob.view.isEdit()">
                        <div class="d-flex">
                          <div class="ib-m">
                            <label class="fw"><small>Keyplayer Type</small></label>
                            <div class="o-80">{{ob.view.value('keyplayerId').keyplayer}}</div>
                          </div>
                          <div class="spacer"></div>
                          <div class="ib-m">
                            <label class="fw"><small>Requirement Type</small></label>
                            <!-- <div class="o-80 ib-m m-r-10">Behavioural and visceral</div> -->
                            <mat-chip-list class="ib-m">
                              <mat-chip color="primary" disabled selected>{{ob.view.value('requirementTypeCode').label}}
                              </mat-chip>
                            </mat-chip-list>
                          </div>
                        </div>
                      </div>


                      <div class="d-flex w-100">
                        <div class="ib-m w-40">
                          <mat-form-field class="m-b-15 fw">
                            <input matInput placeholder="Source of Insight" formControlName="keyplayerName"
                              maxlength="64">
                            <mat-error *ngIf="ob.view.invalid('keyplayerName')">{{ob.view.error('keyplayerName')}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="ib-m" *ngIf="ob.rtEnabled">
                          <label class="d-block"><small>Requirement Sub-Type</small></label>
                          <p-selectButton class="chipMode" [options]="ob.requirementTypeItemCodes"
                            formControlName="requirementTypeItemCode" optionLabel="description"></p-selectButton>
                        </div>
                      </div>

                      <mat-form-field class="fw">
                        <mat-label>Insight</mat-label>
                        <textarea matInput formControlName="observation" cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                        <mat-error *ngIf="ob.view.invalid('observation')">{{ob.view.error('observation')}}</mat-error>
                      </mat-form-field>

                      <mat-card-actions class="t-c p-y-5">
                        <button type="button" (click)="ob.cancel()" mat-raised-button color="warn"
                          class="curved m-x-5">Cancel</button>
                        <button type="submit" (click)="ob.save()" [disabled]="ob.view.invalid()" *ngIf="ob.view.isNew()"
                          mat-raised-button color="primary" class="curved m-x-5">Save</button>
                        <button type="submit" (click)="ob.saveAndAdd()" [disabled]="ob.view.invalid() "
                          *ngIf="ob.view.isNew()" mat-raised-button color="primary" class="curved m-x-5">Save &
                          Add</button>
                        <button type="submit" (click)="ob.save()" [disabled]="ob.view.invalid()"
                          *ngIf="ob.view.isEdit()" mat-raised-button color="primary"
                          class="curved m-x-5">Update</button>
                      </mat-card-actions>
                    </mat-card-content>
                  </form>
                </mat-card>
              </div>
            </div>
          </div>

          <!-- Requirements -->
          <div class="row " *ngIf="re.view.isForm()">
            <div class="col-sm-5">
              <app-tree-preview [data]="re.view" title="Requirement"></app-tree-preview>
            </div>
            <div class="col-sm-19">
              <div class="workArea">
                <mat-card class="popup">
                  <form [formGroup]="re.view.form" novalidate>
                    <div class="popHeader d-flex align-items-center">
                      <button mat-button type="button" class="back ib-m p-x-0" (click)="re.cancel()" title="Back"
                        matTooltipPosition="below">
                        <i class="ib-m i">back</i>
                      </button>
                      <div class="spacer"></div>
                      <span class="ib-m title">Requirement</span>
                      <app-quick-help class="ib-m" [layout]="tipRequirement" [tour]="true"></app-quick-help>
                      <div class="spacer"></div>
                      <button type="button" mat-raised-button (click)="ai.wishList(kp.view, ssp.getProject(),'2')"
                        class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">Fav</span>
                      </button>
                      <button [disabled]="!re.validAI() && re.view.isNew()" type="button" mat-raised-button
                        (click)="ai.smartDevReq( re.view, ssp.getProject(), ss.keyPlayer, ss.requirementType, ss.ob.view)"
                        class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">AI Requirement Insights</span>
                      </button>

                    </div>

                    <mat-card-content class="popContent">
                      <mat-tab-group [(selectedIndex)]="re.selectedTab">
                        <mat-tab label="KeyPlayer Insights ">
                          <div class="p-a-20">
                            <div class="filterArea">
                              <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
                                <div class="m-b-0 d-flex">
                                  <div class="ib-m">
                                    <label class="fw"><small>Keyplayer Type</small></label>
                                    <p-dropdown [options]="kp.view.list" (onChange)="filterKeyPlayer($event)"
                                      formControlName="keyplayerId" placeholder="Keyplayer" optionLabel="label">
                                    </p-dropdown>
                                  </div>
                                  <div class="spacer"></div>
                                  <div class="ib-m">
                                    <label class="fw"><small>Requirement Type</small></label>
                                    <p-dropdown [options]="re.requirementTypes"
                                      (onChange)="filterRequirmentType($event)" formControlName="requirementTypeCode"
                                      placeholder="Requirement Type" optionLabel="label"></p-dropdown>
                                  </div>
                                </div>
                              </mat-expansion-panel>
                            </div>
                            <h6 class="h6 f-light b-b-1 b-b-primary">KeyPlayer Insights</h6>
                            <ul class="cardList">
                              <li *ngFor="let row of ob.observations">
                                <div class="d-flex">
                                  <div class="w-100">
                                    <div class="m-b-5">{{row.observation}}</div>
                                    <em>{{row.keyplayerName}}</em>
                                  </div>
                                  <div class="align-self-center p-x-15">
                                    <div class="t-c text-primary">{{row.requirementTypeItemCode.label}}</div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="t-c p-y-5">
                            <button type="button" (click)="re.cancel()" mat-raised-button color="warn"
                              class="curved m-x-5">Cancel</button>
                            <button type="button" (click)="re.openRequirementTab()" [disabled]="!re.hasObservation()"
                              mat-raised-button color="primary" class="curved m-x-5">Next</button>
                          </div>
                        </mat-tab>
                        <mat-tab label="Requirement" [ngClass]="re.hasObservation() ? 'active' : 'hidden'">
                          <div class="p-a-20">
                            <div class="infoBar">
                              <div class="d-flex">
                                <div class="ib-m">
                                  <label class="fw"><small>Keyplayer Type</small></label>
                                  <div class="o-80">{{ss.keyPlayer.keyplayer}}</div>
                                </div>
                                <div class="spacer"></div>
                                <div class="ib-m">
                                  <label class="fw"><small>Requirement Type</small></label>
                                  <!-- <div class="o-80 ib-m m-r-10">Behavioural and visceral</div> -->
                                  <mat-chip-list class="ib-m">
                                    <mat-chip color="primary" disabled selected>{{ss.requirementType.label}}
                                    </mat-chip>
                                  </mat-chip-list>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex m-b-30">
                              <div class="ib-m">
                                <label class="d-block">
                                  <small class="m-r-0">Importance *</small>
                                </label>
                                <!--
                                <mat-slider class="w-100 p-x-0" min="0" max="10" step="1" value="0" thumbLabel="true" tickInterval="1" formControlName="importance"></mat-slider>
                                -->
                                <p-selectButton class="chipMode" [options]="importances" formControlName="importanceId"
                                  optionLabel='title'></p-selectButton>
                              </div>
                              <div class="spacer"></div>
                              <div class="ib-m" *ngIf="re.rtEnabled">
                                <label class="d-block"><small>Requirement Sub-Type *</small></label>
                                <p-selectButton class="chipMode" [options]="re.requirementTypeItemCodes"
                                  formControlName="requirementTypeItemCode" optionLabel="description">
                                </p-selectButton>
                              </div>
                            </div>
                            <mat-form-field class="m-b-15 fw">
                              <input matInput placeholder="Requirement *" formControlName="requirement">
                              <mat-error *ngIf="re.view.invalid('requirement')">{{re.view.error('requirement')}}
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="fw m-b-15">
                              <mat-label>Rationalisation</mat-label>
                              <textarea matInput formControlName="rationalisation" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="10"></textarea>
                              <mat-error *ngIf="re.view.invalid('rationalisation')">
                                {{re.view.error('rationalisation')}}
                              </mat-error>
                            </mat-form-field>

                            <mat-form-field class="fw m-b-15">
                              <mat-label>Note</mat-label>
                              <textarea matInput formControlName="note" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="10"></textarea>
                              <mat-error *ngIf="re.view.invalid('note')">{{re.view.error('note')}}</mat-error>
                            </mat-form-field>
                            <!-- <app-asset-manager [data]="re" [(index)]="re.selectedTab"></app-asset-manager> -->
                            <div class="t-c p-y-5">
                              <button type="button" (click)="re.cancel()" mat-raised-button color="warn"
                                class="curved m-x-5">Cancel</button>
                              <button type="submit" (click)="re.save()" [disabled]="re.view.invalid()"
                                *ngIf="re.view.isNew()" mat-raised-button color="primary"
                                class="curved m-x-5">Save</button>
                              <button type="submit" (click)="re.saveAndAdd()" [disabled]="re.view.invalid() "
                                *ngIf="re.view.isNew()" mat-raised-button color="primary" class="curved m-x-5">Save &
                                Add</button>
                              <button type="submit" (click)="re.save()" [disabled]="re.view.invalid()"
                                *ngIf="re.view.isEdit()" mat-raised-button color="primary"
                                class="curved m-x-5">Update</button>
                            </div>
                          </div>

                        </mat-tab>
                      </mat-tab-group>

                    </mat-card-content>
                  </form>
                </mat-card>
              </div>
            </div>
          </div>

          <!-- Testing -->
          <div class="row " *ngIf="te.view.isForm()">
            <div class="col-sm-5">
              <app-tree-preview [data]="te.view" title="Testing"></app-tree-preview>
            </div>
            <div class="col-sm-19">
              <div class="workArea">
                <mat-card class="popup">
                  <form [formGroup]="te.view.form" novalidate>
                    <div class="popHeader d-flex align-items-center">
                        <button mat-button type="button" class="back ib-m p-x-0" (click)="te.cancel()" title="Back"
                        matTooltipPosition="below">
                        <i class="ib-m i">back</i>
                      </button>
                      <div class="spacer"></div>
                      <span class="ib-m title">Testing</span>
                      <app-quick-help class="ib-m" [layout]="tipTesting" [tour]="true"></app-quick-help>
                      <div class="spacer"></div>
                      <button type="button" mat-raised-button (click)="ai.wishList(kp.view, ssp.getProject(),'4')"
                        class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">Fav</span>
                      </button>
                      <button type="button" mat-raised-button
                        (click)="ai.smartDevTest(kp.view, ssp.getProject(), ss.keyPlayer, ss.requirementType, ss.requirement)"
                        class="ib-m m-r-20 curved text-primary t-normal f-400">
                        <mat-icon fontSet="i" class="i-ai i-1_5x ib-m"></mat-icon>
                        <span class="ib-m">AI Testing Insights</span>
                      </button>
                      <!-- <button type="button" mat-icon-button class="close ib-m" (click)="te.cancel()" title="Close"
                        matTooltipPosition="below">
                        <mat-icon fontSet="i" class="i-close"></mat-icon>
                      </button> -->
                    </div>
                    <mat-card-content class="popContent">
                      <div class="filterArea" *ngIf="te.view.isNew()">
                        <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
                          <div class="m-b-15 d-flex">
                            <div class="ib-m">
                              <label class="fw"><small>Keyplayer Type</small></label>
                              <p-dropdown [options]="te.discoveryKeyplayers" (onChange)="te.filterKeyplayer($event)"
                                formControlName="filterKeyplayer" placeholder="Keyplayer" optionLabel="label">
                              </p-dropdown>
                            </div>
                            <div class="spacer"></div>
                            <div class="ib-m">
                              <label class="fw"><small>Requirement Type</small></label>
                              <p-dropdown [options]="te.requirementTypeItemCodes" (onChange)="te.filterRT($event)"
                                formControlName="filterRequirementType" placeholder="Requirement Type">
                              </p-dropdown>
                            </div>
                          </div>
                          <div>
                            <label class="fw"><small>Requirements</small></label>
                            <p-dropdown [options]="te.requirements" [filter]="true" [style]="{'width':'100%'}"
                              formControlName="discoveryRequirementId" placeholder="Requirements"
                              optionLabel="requirement">
                            </p-dropdown>
                            <small class="mat-error"
                              *ngIf="te.view.invalid('discoveryRequirementId')">{{te.view.error('discoveryRequirementId')}}</small>
                          </div>
                        </mat-expansion-panel>
                      </div>

                      <div class="infoBar">
                        <div class="m-b-15 d-flex">
                          <div class="ib-m">
                            <label class="fw"><small>Keyplayer Type</small></label>
                            <div class="o-80">{{te.view.value('discoveryRequirementId').keyplayerId.keyplayer}}</div>
                          </div>
                          <div class="spacer"></div>
                          <div class="ib-m">
                            <label class="fw"><small>Requirement Type</small></label>
                            <!-- <div class="o-80 ib-m m-r-10">Behavioural and visceral</div> -->
                            <mat-chip-list class="ib-m">
                              <mat-chip color="primary" disabled selected>
                                {{te.view.value('discoveryRequirementId').requirementTypeCode.label}}
                                {{te.view.value('discoveryRequirementId').requirementTypeItemCode.label}}</mat-chip>
                            </mat-chip-list>
                          </div>
                        </div>

                        <div class="m-b-0">
                          <label class="fw"><small>Requirement</small></label>
                          <div class="scrollY max h-120px b-b-2 dotted p-y-5">
                            {{te.view.value('discoveryRequirementId').requirement}}</div>
                        </div>
                      </div>

                      <mat-form-field class="fw m-b-15">
                        <mat-label>Testing Strategy</mat-label>
                        <textarea matInput formControlName="strategy" cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                        <mat-error *ngIf="te.view.invalid('strategy')">{{te.view.error('strategy')}}</mat-error>
                      </mat-form-field>

                      <mat-card-actions class="t-c p-y-5">
                        <button type="button" (click)="te.cancel()" mat-raised-button color="warn"
                          class="curved m-x-5">Cancel</button>
                        <button type="submit" (click)="te.save()" [disabled]="te.view.invalid()" *ngIf="te.view.isNew()"
                          mat-raised-button color="primary" class="curved m-x-5">Save</button>
                        <button type="submit" (click)="te.saveAndAdd()" [disabled]="te.view.invalid() "
                          *ngIf="te.view.isNew()" mat-raised-button color="primary" class="curved m-x-5">Save
                          & Add</button>
                        <button type="submit" (click)="te.save()" [disabled]="te.view.invalid()"
                          *ngIf="te.view.isEdit()" mat-raised-button color="primary"
                          class="curved m-x-5">Update</button>
                      </mat-card-actions>

                    </mat-card-content>
                  </form>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Messages" [disabled]="true">
        <div class="conArea">
          Under Construction...
        </div>
      </mat-tab>
      <mat-tab label="Files" [disabled]="true">
        <div class="conArea">
          Under Construction...
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="wrapper" [ngClass]="ai.display ? 'active' : 'hidden'">
    <app-ai [ai]="ai" class="w-100"></app-ai>
  </div>
</div>

<ng-template #tipKeyPlayer>
  <div class="helpContent">
    <h6 class="title">Keyplayer</h6>
    <ol class="p-l-20">
      <li>
        <div class="heading">
          Enter Key player
        </div>
        <cite>
          [ type of customer / stakeholder ]
        </cite>
        <div class="note">
          E.g.: - Corporate Manager,
        </div>
      </li>
      <li>
        <div class="heading">
          Enter Key player description
        </div>
        <cite>
          [ description about the customer / stakeholder ]
        </cite>
        <div class="note">
          E.g.: - Person who handles an organisation’s promotion and branding
        </div>
      </li>
      <li>
        <div class="heading">
          Select Key player category
        </div>
        <cite>
          [a keyplayer can be consumer or a contributor or an influencer]
        </cite>
        <div class="note">
          E.g.: - Consumer
        </div>
      </li>
    </ol>

    <div class="info">
      <div class="d-flex">
        <i class="i i-info_outline i-2x align-self-center"></i>
        <div class="align-self-center">
          Once you know who your Key Players are, it is time to understand what they need or want or lack in
          your product. Conduct interviews or observe and record those insights, each insight of your
          Consumers, Contributors, and Influencer will fall into one these three groups: BEHAVIORAL AND
          VISCERAL or COGNITIVE AND HIGHER ORDER or SAFETY AND ERROR-PROOFING. Group the insights and add
          under each requirement type.
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #tipKeyPlayerInsight>
  <div class="helpContent">
    <h6 class="title">Keyplayer</h6>
    <ol class="p-l-20">
      <li>
        <div class="heading">
          Enter Source of Insight
        </div>
        <cite>
          [ Name of a customer / agency / supplier / manufacturer / digital media]
        </cite>
        <div class="note">
          E.g.: - The cupcake blog
        </div>
      </li>
      <li>
        <div class="heading">
          Enter Insight
        </div>
        <cite>
          [ key player need]
        </cite>
        <div class="note">
          E.g.: - There is a high demand for eggless cup cake.
        </div>
      </li>
      <li>
        <div class="heading">
          Select requirement sub-type (if available)
        </div>
        <cite>
          [how people interact with products, it will be with any one of five basic human senses – Taste, Smell, Touch,
          Visual or Sound]
        </cite>
        <div class="note">
          E.g.: - Taste
        </div>
      </li>
    </ol>

    <div class="info">
      <div class="d-flex">
        <i class="i i-info_outline i-2x align-self-center"></i>
        <div class="align-self-center">
          Once you have list of insights, next step is to transform/translate the insights to measurable
          needs/requirements and mark its level of importance. A single insight may require multiple requirement
          /measurable need to achieve it and vice versa.
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tipRequirement>
  <div class="helpContent">
    <h6 class="title">Requirement</h6>
    <ol class="p-l-20">
      <li>
        <div class="heading">
          Select requirement sub-type
        </div>
        <cite>
          [how people interact with products, it will be with any one of five basic human senses – Taste, Smell,
          Touch, Visual or Sound]
        </cite>
        <div class="note">
          E.g.: - Taste
        </div>
      </li>
      <li>
        <div class="heading">
          Select requirement importance
        </div>
        <cite>
          [degree of the need – Nice to have, Important or Very Important]
        </cite>
        <div class="note">
          E.g.: - Important
        </div>
      </li>
      <li>
        <div class="heading">
          Enter requirement
        </div>
        <cite>
          [ it has to be a measurable need]
        </cite>
        <div class="note">
          E.g.: - Lola’s cupcake range should include eggless cup cake
        </div>
      </li>
      <li>
        <div class="heading">
          Enter rationalization (if any)
        </div>
        <cite>
          [ justification for the requirement]
        </cite>
        <div class="note">
          E.g.: - There is a high demand for food items that avoid animal products.
        </div>
      </li>
      <li>
        <div class="heading">
          Enter Note (if any)
        </div>
        <cite>
          [any instructions, references]
        </cite>
        <div class="note">
          E.g.: - &lt;can be blank&gt;
        </div>
      </li>
    </ol>

    <div class="info">
      <div class="d-flex">
        <i class="i i-info_outline i-2x align-self-center"></i>
        <div class="align-self-center">
          A product is successful only if it meets the needs of the key players. So in next step its required to
          define/identify
          strategies to test whether the requirements are met or not.
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tipTesting>
  <div class="helpContent">
    <h6 class="title">Testing</h6>
    <ol class="p-l-20">
      <li>
        <div class="heading">
          Enter a testing strategy [Test method to verify the requirement]
        </div>
        <!-- <cite>
        </cite> -->
        <div class="note">
          E.g.: - By performing quantitative microwell tests using Neogen’s BioKits egg assay kits
        </div>
      </li>
    </ol>

    <div class="info">
      <div class="d-flex">
        <i class="i i-info_outline i-2x align-self-center"></i>
        <div class="align-self-center">
          This concludes the discovery phase in InsIQ. In next stage you need to create design and process concept
        </div>
      </div>
    </div>
  </div>
</ng-template>