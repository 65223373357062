import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from '../profile/user';
import { AIView } from '../ai/insight.view';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
interface Message {
  dtsequence: number;
  content: string;
  saved: number;
  rerun: number;
  user: User;
}

@Component({
  selector: 'app-savedchat',
  templateUrl: './savedchat.component.html',
  styleUrls: ['./savedchat.component.scss']
})

export class SavedchatComponent implements OnInit {
  savedChatsData;
  groupedData: { [key: number]: Message[] } = {};
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    public ai: AIView,
    warningDialogRef: MatDialogRef<WarningalertsComponent, any>,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }
  getKeys(obj: { [key: number]: Message[] }): number[] {
    return Object.keys(obj).map(key => +key);
  }
  ngAfterViewInit() {
    console.log(this.data);

    // Group messages by dtsequence
    const groupedMessages: { [key: number]: Message[] } = {};
    this.data.forEach(message => {
      if (!groupedMessages[message.dtsequence]) {
        groupedMessages[message.dtsequence] = [];
      }
      groupedMessages[message.dtsequence].push(message);
    });

    // Extract second set of messages for each dtsequence
    const secondMessages: Message[] = [];
    Object.keys(groupedMessages).forEach(dtsequence => {
      const messages = groupedMessages[parseInt(dtsequence, 10)];
      if (messages.length > 1) {
        secondMessages.push(...messages.slice(1));
      }
    });

    // Output the second messages
    //console.log(secondMessages);

    // Assign grouped data to the component property
    this.groupedData = groupedMessages;
    // console.log(this.groupedData);

    this.savedChatsData = secondMessages;
    this.cdr.detectChanges();
  }


  deleteChat(row) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirmation Request', message: 'Are you sure you want to delete this summary from the saved list ?', cancelbtn: 'No', okbtn: 'Yes' },
    });

    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.savedChatsData = this.savedChatsData.filter(message => message.dtsequence != row.dtsequence);
        this.cdr.detectChanges();
        delete this.groupedData[row.dtsequence];
        this.ai.deleteChat(row);
      }
    });
  }
}
