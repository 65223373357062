import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Config } from '../config/global.config';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService, AlertService } from '../_services';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public config: Config,
    private router: Router,
    public dialog: MatDialog,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      // console.log('ERRORRRRR', err);
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        location.reload();
      } else if (
        err.status === 500 &&
        (
          !err.url.includes('aiinsights/trendsapi') ||
          !err.url.includes('aiinsights/deepdiveapi') ||
          !err.url.includes('aiinsights/sentimentapi')
        )
      ) {
        Config.loading = false;

        this.alertService.error(
          'Something went wrong. Please try again later or contact support@insiq.io',
          true, 20 * 1000);

        if (this.config.isAdmin()) {
          this.router.navigate(['/subscribers']);
        } else {
          if (err.url.includes('aiinsights') && !(this.router.url.includes('project'))) {
            this.router.navigate(['/project']);
          }
        }
      } else if (err.status === 406 && err.error.error.includes('noplan')) {
        Config.loading = false;

        this.alertService.error(
          err.error.error_description,
          true, 30 * 1000);
      } else if (err.status === 406 && err.error.error.includes('noquota')) {
        Config.loading = false;
        if (this.config.currentUser ? this.config.currentUser?.pin !== 'USR' : JSON.parse(localStorage.getItem('currentUserStore'))?.pin !== 'USR') {
          this.alertService.error(
            err.error.error_description,
            true, 30 * 1000);
          this.dialog.closeAll();
          this.router.navigate(['/sachetsubscription/api']);
        } else {
          this.alertService.error(
            'Your monthly AI Credits Quota Exhausted, Please Contact your Team Admin to Buy AI Credit Add-ons',
            true, 30 * 1000);
          this.dialog.closeAll();
          if (!this.router.url.includes('project')) {
            this.router.navigate(['/project']);
          }
        }
      }
      else if (err.status === 406 && err.error.error.includes('bgcall')) {
        Config.loading = false;
        this.alertService.error(
          'Previously made CALL is running in the background  please wait a minute to initiate another call.',
          true, 30 * 1000);


      }
      else if (err.status === 404) {
        Config.loading = false;

        this.alertService.error(
          err.error.error_description,
          true, 30 * 1000);
      }



      const error = (err.error !== null) ? err.error.message || err.error.error_description || err.statusText : err.message;

      // console.log(error);
      return throwError(error);
    }));
  }
}
