import {
  OnInit,
  Component,
  OnDestroy
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { Config, View } from '../../config';
import { AlertService } from '../../_services/alert.service';
import { MyErrorStateMatcher } from '../../_directives/validator.directive';

@Component({
  selector: 'app-accountactivate',
  templateUrl: './accountactivate.component.html',
  styleUrls: ['./accountactivate.component.scss']
})
export class AccountactivateComponent implements OnInit, OnDestroy {
  returnUrl = '/';
  restUid = '';
  isAdminUrl = false;
  url = 'sendemail/accountactivate';
  // validurl='sendemail/resetpwd/linkvalidate';
  matcher = new MyErrorStateMatcher();
  constructor(
    public view: View,
    public config: Config,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public alertService: AlertService
  ) {
    //this.view.initForm(this.url, this.getNewForm());
    //  this.validView.initForm(this.validurl, this.getLinkNewForm());
    this.returnUrl = this.route.snapshot.queryParams['activation'] || '/';
    if (this.route.snapshot.queryParamMap.get('activation')) {
      this.route.queryParams.subscribe(params => {
        // console.log(params.activation);
        // this.view.set('activation', params.activation);
        localStorage.setItem('activation', params.activation);
        // this.validView.set('resetid', params.activation);
        // this.validView.state = 'edit';
        // this.validView.save(this.validurl);
        this.view.get('sendemail/accountactivate/linkvalidate/' + params.activation).subscribe(res => {
          this.sucessvalid();
          // this.router.navigate(['/resetpwd']);
        }, (err) => {
          this.handleError(err);
        });

      });
      // do your stuff. example: console.log('id: ', this.route.snapshot.queryParams['id']);
    } else {
      if (localStorage.length > 0) {
        // We have items
        // let myItem = localStorage.getItem('resetid');
        this.view.set('activation', localStorage.getItem('activation'));
      } else {
        // No items
      }
    }
  }
  sucessvalid() {

    this.router.navigate(['/login']);
    this.alertService.success('Your InsIQ account has been activated successfully! Please login to continue', true, 20 * 1000);

  }
  handleError(err) {
    this.router.navigate(['/login']);
    // /android|webos|iphone|ipod/i.test(navigator.userAgent.toLowerCase()) &&
    this.alertService.error(
      err,
      true, 20 * 1000);

  }
  ngOnInit() {
    document.querySelector('body').classList.add('xsEnabled');
    document.querySelector('html').classList.add('xsEnabled');
  }

  ngOnDestroy() {
    document.querySelector('body').classList.remove('xsEnabled');
    document.querySelector('html').classList.remove('xsEnabled');
  }
  getLinkNewForm() {
    return this.fb.group({
      activation: ['']
    });

  }
  // Validators.pattern(AppPattern.EMAIL)
  onSubmit() {

  }
  toggleDev2MOverview() {
    //this.config.dev2MOverview = !this.config.dev2MOverview;
    window.open("https://www.insiq.io/", "_blank");

  }
}
