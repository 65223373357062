import { Component, Inject, Injectable, Injector, OnInit, Optional, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable()
export class MessageFormat {
  title = '!';
  message = '!';
}

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {
  layout: {
    title: string,
    message: string,
    component?: Component,
    template?: TemplateRef<any>
  };

  myInjector: Injector;
  // myContent = [[document.createTextNode('Ahoj')], [document.createTextNode('Svet')]];

  CompleteComponent: Component = new Component(null);

  constructor(
    public dialogRef: MatDialogRef<CommonDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector
  ) {
    // console.log(this.data);
    this.layout = this.data;
    this.CompleteComponent = this.layout.component;
    // (method) Injector.create(providers: StaticProvider[], parent?: Injector): Injector (+1 overload)

    this.myInjector = Injector.create({ providers: [{ provide: MessageFormat, deps: [] }], parent: injector });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
