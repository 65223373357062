import { Config } from '../../config';
import { AIView } from '../ai/insight.view';
import { ConceptView } from './concept.view';
import { MatSort } from '@angular/material/sort';
import { SharedService } from './shared.service';
import { TreeData } from '../../_models/treedata';
import { RequirementView } from './requirement.view';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RequirementTypeView } from './requirementtype.view';
import { RequirementFeatureView } from './requirement-feature.view';
import { RequirementFeatureProcessView } from './requirement-feature-process.view';

enum Concept {
  concept,
  requirementType,
  requirement,
  requirementFeature,
  requirementFeatureProcess
}

@Component({
  selector: 'app-concept',
  templateUrl: './concept.component.html',
  styleUrls: ['./concept.component.scss']
})
export class ConceptComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  data = new Array;
  header: any;
  active: any;
  observations;
  importances;
  constructor(
    public ai: AIView,
    public co: ConceptView,
    public rt: RequirementTypeView,
    public re: RequirementView,
    public rf: RequirementFeatureView,
    public rfp: RequirementFeatureProcessView,
    public ss: SharedService,
    public config: Config
  ) {

    ai.display = false;
    this.co.setForm();
    this.rt.setForm();
    co.view.get('importance/all').subscribe(res => {
      this.importances = res;
      //   console.log( this.importances);
      for (let x = 0; x < this.importances.length; x++) {
        const item = this.importances[x];
        item['value'] = item['title'];
        item['label'] = item['title'];
      }
    });

    this.header = {
      percentage: 10,
      title: 'Concept',
      projectName: this.ss.project.productName
    };

    // console.log(Concept.concept);
    // console.log(Concept[Concept.reqGroup]);

    this.data[Concept.concept] = new TreeData(this.ss.getConceptView(), {
      name: 'Concept',
      key: 'concept',
      children: 'requirementType',
      pagination: true, // false
      disabled: false
    });

    this.data[Concept.requirementType] = new TreeData(this.ss.getRequirementTypeView(), {
      name: 'Requirement Type',
      key: 'requirementType',
      children: 'requirement',
      new: false,
      edit: false,
      import: false,
      nodeIcon: true,
      nodeImport: true,
      percentage: false
    });

    this.data[Concept.requirement] = new TreeData(this.ss.getRequirementView(), {
      name: 'Requirements',
      key: 'requirement',
      childName: 'Feature',
      children: 'requirementFeature',
      new: false,
      import: true
    });

    this.data[Concept.requirementFeature] = new TreeData(this.ss.getRequirementFeatureView(), {
      name: 'Feature',
      childName: 'Process',
      key: 'requirementFeature',
      children: 'requirementFeatureProcess'
    });

    this.data[Concept.requirementFeatureProcess] = new TreeData(this.rfp, {
      name: 'Process',
      key: 'requirementFeatureProcess',
      children: ''
    });
  }

  ngOnInit() {
    this.co.view.state = 'list';
    this.rt.view.state = 'list';
    this.re.view.state = 'list';
    this.rf.view.state = 'list';
    this.rfp.view.state = 'list';
  }

  isList() {
    return !(
      this.co.view.isForm() ||
      this.rf.view.isForm() ||
      this.re.view.isForm() ||
      this.rfp.view.isForm()
    );
  }

  nodeCloser(fields, data = this.data) {
    for (let x = 0; x < fields.length; x++) {
      const item = fields[x];
      if (item === Concept.requirementType) {
        this.rt.view.list = null;
        data[Concept.requirementType].activeNode = null;
      } else if (item === Concept.requirement) {
        this.re.view.list = null;
        data[Concept.requirement].activeNode = null;
      } else if (item === Concept.requirementFeature) {
        this.rf.view.list = null;
        data[Concept.requirementFeature].activeNode = null;
      } else if (item === Concept.requirementFeatureProcess) {
        this.rfp.view.list = null;
        data[Concept.requirementFeatureProcess].activeNode = null;
      }
    }
  }

  nodeCloseManager(key, data) {
    // console.log(key);
    switch (key) {
      case 'concept':
        this.nodeCloser([
          Concept.requirementFeatureProcess,
          Concept.requirementFeature,
          Concept.requirement,
          Concept.requirementType], data);
        break;
      case 'requirementType':
        this.nodeCloser([
          Concept.requirementFeatureProcess,
          Concept.requirementFeature,
          Concept.requirement], data);
        break;
      case 'requirement':
        this.nodeCloser([
          Concept.requirementFeatureProcess,
          Concept.requirementFeature], data);
        break;
      case 'requirementFeature':
        this.nodeCloser([Concept.requirementFeatureProcess], data);
        break;
      default:
        this.nodeCloser([
          Concept.requirementFeatureProcess,
          Concept.requirementFeature,
          Concept.requirement,
          Concept.requirementType], data);
        break;
    }
  }

  onNodeOpen(e) {
    const [/*target*/, key, node, /* data */] = e;
    //   console.log(key);
    if (key === 'concept') {
      this.ss.concept = node;
      this.ss.getRequirementTypeView().getList();
    } else if (key === 'requirementType') {
      this.ss.requirementType = node;
      this.ss.getRequirementView().setForm();
      this.ss.getRequirementView().getList();
      // this.ss.getRequirementView().newform();
    } else if (key === 'requirement') {
      this.ss.requirement = node;
      this.ss.getRequirementFeatureView().setForm();
      this.ss.getRequirementFeatureView().getList();
    } else if (key === 'requirementFeature') {
      this.ss.requirementFeature = node;
      this.ss.getRequirementFeatureProcessView().setForm();
      this.ss.getRequirementFeatureProcessView().getList();
    }
  }

  onNodeClose(e) {
    const [/*target*/, key, /* node */, data] = e;
    this.nodeCloseManager(key, data);
  }

  newConnectNode(e) {
    let [node, key] = e;
    node = (node) ? node : '';
    key = key;
    // key = key.toString().toLowerCase();
    // console.log(key, node);
    if (key === 'requirement') {
      this.ss.requirementType = node;
      this.ss.getRequirementView().setForm();
      this.nodeCloser([Concept.requirementFeatureProcess, Concept.requirementFeature, Concept.requirement]);
      this.ss.getRequirementView().newform();
    } else if (key === 'requirementFeature') {
      this.ss.requirement = node;
      this.ss.getRequirementFeatureView().setForm();
      this.nodeCloser([Concept.requirementFeatureProcess, Concept.requirementFeature]);
      this.ss.getRequirementFeatureView().newform();
    } else if (key === 'requirementFeatureProcess') {
      // this.ss.requirementId = id;
      //   console.log(Concept.requirementFeatureProcess);
      this.ss.requirementFeature = node;
      this.ss.getRequirementFeatureProcessView().setForm();
      this.nodeCloser([Concept.requirementFeatureProcess]);
      this.ss.getRequirementFeatureProcessView().newform();
    }
    // return key;
  }
}
