import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services/alert.service';

import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { Config, View } from '../../config';
import { MyErrorStateMatcher } from '../../_directives/validator.directive';
import { CustomThemeService } from 'src/app/custom-theme.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit, OnDestroy {

  returnUrl = '/';
  restUid = '';
  isAdminUrl = false;
  isPasswordReveal = false;
  isPasswordReveal1 = false;

  url = 'sendemail/resetpwd';
  // validurl='sendemail/resetpwd/linkvalidate';
  matcher = new MyErrorStateMatcher();
  constructor(
    public view: View,
    private fb: FormBuilder,
    private router: Router,
    public config: Config,
    public alertService: AlertService,
    public customThemeService: CustomThemeService,
    private http: HttpClient,

  ) {
    this.view.initForm(this.url, this.getNewForm());
    //  this.validView.initForm(this.validurl, this.getLinkNewForm());
    // this.returnUrl = this.route.snapshot.queryParams['reset'] || '/';


    if (localStorage.length > 0) {
      // We have items
      // let myItem = localStorage.getItem('resetid');
      this.view.set('resetid', localStorage.getItem('resetid'));
    } else {
      // No items
    }
  }
  sucessvalid() {
    this.router.navigate(['/resetpwd'], { queryParams: { reset: 'dummy', poda: 'ppp' } });
  }
  handleError(err) {
    this.router.navigate(['/login']);
    // /android|webos|iphone|ipod/i.test(navigator.userAgent.toLowerCase()) &&

    this.alertService.error(
      err,
      true, 20 * 1000);

  }
  btnStatus() {
    if (this.view.invalid()) {
      return '';
    } else {
      return 'primary';
    }
  }
  ngOnInit() {
    const header = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa('clientIdPassword' + ':' + 'secret'))
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams({

    });
    this.http.post<any>(`${Config.apiUrl}/user/whitelabelling`, { headers: header }).subscribe(data => {
      //  console.log("domain related" + data);
      if (data?.domainname != null) {
        Config.customDomain = true;
      } else {
        Config.customDomain = false;
      }
      if (data?.apptheme) {

        let dynamiclogostat = false;
        if (data?.logopath) {
          dynamiclogostat = true;
        }
        this.customThemeService.changeTheme(data?.apptheme.themeName, 'data:image/png;base64,' + data?.logoImage, dynamiclogostat);

      } else {
        this.customThemeService.changeTheme('theme-default', 'logo.png', false);
      }
    });
    document.querySelector('body').classList.add('xsEnabled');
    document.querySelector('html').classList.add('xsEnabled');
  }

  ngOnDestroy() {
    document.querySelector('body').classList.remove('xsEnabled');
    document.querySelector('html').classList.remove('xsEnabled');
  }
  getLinkNewForm() {
    return this.fb.group({
      resetid: ['']
    });

  }
  getNewForm() {
    return this.fb.group(
      {
        resetid: [''],
        inputPwd: ['', [
          Validators.required,
          Validators.minLength(6)
        ]],
        cpassword: ['']
        // plan: this.fb.group(this.view.get("plan/all"))
      },
      {
        validators: this.checkPasswords
      }
    );

  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.inputPwd.value;
    const confirmPass = group.controls.cpassword.value;


    return pass === confirmPass ? null : { notSame: true };
  }
  // Validators.pattern(AppPattern.EMAIL)
  onSubmit() {

    const myEncodedQueryParamValue = this.view.value('inputPwd').replace("+", "%2B");

    this.view.set('inputPwd', myEncodedQueryParamValue);
    this.view.set('cpassword', myEncodedQueryParamValue);
    this.view.saveRedirect(
      `${this.url}`,
      '/login',
      'Your Password has been reset successfully.Please Login to continue.',
      'Your Reset Password link has expired!! Please try Forgot Password Again.'
    );
  }
  toggleDev2MOverview() {
    //this.config.dev2MOverview = !this.config.dev2MOverview;
    window.open("https://www.insiq.io/", "_blank");

  }

  captchaValid() {
    // return (this.captcha);
  }
}
