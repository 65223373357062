<div class="treePreview">
  <div class="caption">
    <span>{{title}}</span>
    <small>List</small>
  </div>

  <div class="bar-T d-flex m-t-10">
    <button mat-icon-button (click)="data.newform()" color="accent" class=" i-1_5x" title="Create New"
      matTooltipPosition="below">
      <mat-icon fontSet="i" class="i-addNew"></mat-icon>
    </button>
    <div class="spacer"></div>
    <button mat-icon-button (click)="data.clone(data.active)" [disabled]="!data.isEdit()" color="primary" class=" i-1_5x"
      title="Duplicate" matTooltipPosition="below">
      <mat-icon fontSet="i" class="i-content_copy"></mat-icon>
    </button>
    <div class="spacer"></div>
    <button mat-icon-button (click)="data.delete(data.active.id)" [disabled]="!data.isEdit()" color="warn" class=" i-1_5x"
      title="Delete" matTooltipPosition="below">
      <mat-icon fontSet="i" class="i-trash"></mat-icon>
    </button>
  </div>

  <ul class="tree">
    <li *ngFor="let row of data.list">
      <button mat-stroked-button color="primary" class="curved fw" (click)="data.edit(row)" [ngClass]="{'active': row === data.active}">
       {{row.label}}
      </button>
    </li>
  </ul>
</div>