
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { View, Config, FormDefault } from '../../config';
import { SharedService } from './shared.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';

@Injectable({
  providedIn: 'root'
})
export class RequirementView {
  requirementTypeItemCodes: any = null;
  requirementTypes;

  // requirementTypeCode; any;
  url = 'analysisrequirement';
  uploadurl = Config.apiUrl + '/upload';
  uploadedFiles = [];
  selectedTab: number;

  public view: View;
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    public ss: SharedService) {
    this.view = new View(http, router, route, alertService);
    this.ss.setRequirementView(this);
    this.view.refreshList = false;
    this.setForm();
    this.selectedTab = 0;
  }

  setForm() {
    this.initForm();

    this.view.edit = ((row) => {
      this.ss.getRequirementView().edit(row);
    });
    this.requirementTypes = null;

    this.view.state = 'list';
  }
  initForm() {
    this.view.initForm(this.url, this.getForm(), this.getNewDefault(), false);
    Config.isProjectSelected = true;
  }
  refData() {
//    this.view.get(`requirementtypeitem/all/${this.ss.requirementType.id}`).subscribe(res => {
//      this.requirementTypeItemCodes = res;
//    });
    //this.ss.getObservationView().loadObservations();
  }

  getNewDefault() {
    if (this.ss.getRequirementTypeView() == null) {
      return null;
    }
    return new FormDefault(
      ['analysisId'],
      [
        this.ss.analysis
      ]);
  }

  newform(load = true) {
//    if (load) {
//      this.view.get(`requirementtype/observation/${this.ss.getProjectId()}/1`).subscribe(res => {
//        this.requirementTypes = res;
//      });
//    }
    this.refData();
    this.view.newform();
  }

//  edit(row) {
////    this.view.get(`requirementtype/all`).subscribe(res => {
////      this.requirementTypes = res;
////    });
//    this.refData();
//    this.selectedTab = 1;
//    this.view.reset();
//    for (const item of this.view.formFields) {
//      this.view.field(item).setValue(row[item]);
//    }
//    this.view.state = 'edit';
//    this.view.active = row;
//  }
  edit(row) {
//    if (this.discoveryRequirements === null) {
//      this.newform();
//    }
    this.view.reset();
    for (const item of this.view.formFields) {
      this.view.field(item).setValue(row[item]);
    }
    this.view.state = 'edit';
    this.view.active = row;
  }
  onUpload(event) {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  save() {
    this.view.set('label', this.view.value('requirement'));
   // this.view.set('importance', this.view.value('importance', 0));
    this.view.save();
  }

  saveAndAdd() {
    this.view.set('label', this.view.value('requirement'));
   // this.view.set('importance', this.view.value('importance', 0));
    this.view.saveAndAdd();
  }

  cancel() {
    this.ss.getRequirementTypeView().getList();
    this.getList();
    this.view.cancel();
    this.selectedTab = 0;
  }

  getList() {
    this.view.get(`${this.url}/all/${this.ss.analysis.id}/${this.ss.requirementType.id}`).subscribe(res => {
      this.view.list = res;
    });
  }

  getForm() {
    return this.fb.group({
      id: [''],
      requirement: ['', Validators.required],
      label: [''],
      rationalisation: [''],
      note: [''],
      importanceId: [''],
       importanceChange: [''], //Dummy to just show the change
      requirementTypeCode: ['', Validators.required],
      requirementTypeItemCode: ['', Validators.required],
      keyplayerId: ['', Validators.required],
      projectId: ['', Validators.required]
    });
  }


}