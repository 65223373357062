<div class="d-block p-a-0">
  <h4 class="text-accent">
    <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" (click)="backtoPage()">
      <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
    </button>
  </h4>
  <mat-card-header>
    <div mat-card-avatar class="b-a-1 t-c"><i class="i i-ms-team i-2_5x text-primary"></i></div>
    <mat-card-title>Post the Insight as Message to your MS Teams Channel</mat-card-title>
    <mat-card-subtitle>Share InsIQ AI Insight</mat-card-subtitle>
  </mat-card-header>
  <div>
    <mat-card class="m-b-10">

      <mat-card-content>
        <mat-form-field appearance="outline" class="w-100 m-b-20" floatLabel="auto">
          <textarea matInput placeholder="Message to your MS Channel" rows="8" cols="50"
            id="channelmsg">{{data.teammessage}}</textarea>
          <!--               <mat-error *ngIf="errmsg">Message to Ms Channel is Mandatory</mat-error>-->
          <mat-hint class="text-danger" *ngIf="errmsg">Message to Ms Teams Channel is Mandatory !!</mat-hint>
        </mat-form-field>

        <div class="d-flex">
          <button type="button" matRipple class="btn btn-default lighten" (click)="backtoPage()">Cancel</button>
          <div class="m-x-auto"></div>
          <button matRipple class="btn btn-primary lighten" type="button" (click)="sentmsg()">Post
            Message</button>
        </div>

        <!-- <div *ngIf="ai.notesviewflag" href="javascript:;" (click)="ai.addOrEditNotes(row)"
          title="Click to Add/Edit Note" matTooltipPosition="below" class="notes">{{row.noteAdd}}
        </div> -->
      </mat-card-content>
    </mat-card>
  </div>

</div>