import {
  OnInit,
  OnDestroy,
  Component,
  ViewChild
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import {
  View,
  Config,
  AppPattern
} from '../../config';
import * as moment from 'moment';
import { ComboItem } from '../../_services/ComboItem';
import { environment } from 'src/environments/environment';
import { CountryService } from '../../_services/country.service';
import { MyErrorStateMatcher } from '../../_directives/validator.directive';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AlertService } from 'src/app/_services';
import { CustomThemeService } from 'src/app/custom-theme.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;

  istandc = false;
  captcha = false;
  finalCountry: ComboItem;
  finalTimezone: ComboItem;
  isPasswordReveal = false;
  isPasswordReveal1 = false;
  appsumo = false;
  pdthunt = false;
  step1 = "done";
  step2 = "active";
  step3 = "";
  step4 = "";
  companyTypeList;
  companyRoleList;
  overlayflag = false;
  matcher = new MyErrorStateMatcher();
  captchaKey = environment.captchaKey;
  plans: { monthly: any; annually: any; };

  @ViewChild('comboTimezones') comboTimezones: any;

  timeZoneForm: any;
  countries!: any[] | null;
  timeZones: any[] | null = [];

  selectedCountry: ComboItem = {
    id: "",
    value: ""
  };

  selectedTimeZone: ComboItem = {
    id: "",
    value: ""
  };

  targetTime = '-';
  localTime = `${moment().format(environment.format.time)}, ${moment.tz.guess()}`;
  constructor(
    public view: View,
    public config: Config,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private countryService: CountryService,
    public customThemeService: CustomThemeService,

  ) {
    this.captcha = false;

    this.view.initForm('user', this.getNewForm());
    this.refreshtandc();

    view.get(`planpricing/activedisplay`).subscribe((res) => {
      // console.log(res);
      this.plans = res;

      this.plans.monthly = res.filter(u => u.paymode === '1');
      this.plans.annually = res.filter(u => u.paymode === '2');
    });

  }

  ngOnInit() {
    //  console.log(window.location.href.split("/register")[0])
    // if (window.location.href.split("/register")[0] != environment.appUrl) {
    //   // console.log('Test');
    //   this.router.navigate(['/login']);
    // }
    //this.router.navigate(['/login']);
    const header = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa('clientIdPassword' + ':' + 'secret'))
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams({

    });
    this.http.post<any>(`${Config.apiUrl}/user/whitelabelling`, { headers: header }).subscribe(data => {
      // console.log("domain related" + data);
      if (data?.apptheme) {
        let dynamiclogostat = false;
        if (data?.logopath) {
          dynamiclogostat = true;
        }
        this.customThemeService.changeTheme(data?.apptheme.themeName, 'data:image/png;base64,' + data?.logoImage, dynamiclogostat);
      } else {
        this.customThemeService.changeTheme('theme-default', 'logo.png', false);
      }
    });
    document.querySelector('body').classList.add('xsEnabled');
    document.querySelector('html').classList.add('xsEnabled');
    if (location.host.includes('demo') || Config.customDomain) {
      this.router.navigate(['/login']);
    }
    this.activatedRoute.queryParams.subscribe(params => {
      const ext = params['ext'];

      if (ext === 'appsumo') {
        this.appsumo = true;
      }
      // else if (ext === 'product_hunt') {
      //   this.view.set('usertype', "PH");
      //   this.pdthunt = true;
      // }
      // else {
      //   this.router.navigate(['/login']);
      // }
    });
    this.timeZoneForm = this.fb.group({
      country: undefined,
      timezone: undefined
    });
    // console.log(this.countryService.getCountries());
    this.countries = this.countryService.getCountries();
    this.timeZones = this.parseArrayToComboItems(moment.tz.names());
    //timeZones = momentTimezone.tz.names();
    let timeZoneList = [];

    for (let i in this.timeZones) {
      try {
        timeZoneList.push(`(GMT${moment.tz(this.timeZones[i]).format('Z')}) ${this.timeZones[i]}`);
      } catch (e) { }
    }

    this.timeZones = timeZoneList.sort();
    // setInterval(() => {
    //   try{
    //     this.localTime = `${moment().format(environment.format.time)}, ${moment.tz.guess()}`;
    //   }
    //   catch(e)
    //   {}
    //   if (this.selectedTimeZone && this.selectedTimeZone.value !== '') {
    //     // console.log('VALID', this.selectedTimeZone.value);
    //     try{
    //       this.targetTime = `${moment().tz(this.selectedTimeZone.value).format(environment.format.time)}, ${this.selectedTimeZone.value}`;
    //     }
    //     catch(e)
    //     {
    //     }
    //   }
    // }, 500);
    // console.log('GUESS',moment.locale);
    // console.log('VALIDddd', this.selectedTimeZone);
    // console.log('selectedCountry', this.selectedCountry);
    // (<HTMLInputElement> document.getElementById("comboTimezones")).disabled = true;

    this.view.get('user/companytype').subscribe(res => {
      this.companyTypeList = res;
    });
    this.view.get('user/companyrole').subscribe(res => {
      this.companyRoleList = res;
    });

    // console.log(this.appsumo);
  }

  ngOnDestroy() {
    document.querySelector('body').classList.remove('xsEnabled');
    document.querySelector('html').classList.remove('xsEnabled');
  }

  getCurrency() {
    return Config.subscriptionCurrency;
  }
  nextStep() {
    Config.loading = true;
    const header = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa('clientIdPassword' + ':' + 'secret'))
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams({
      fromString: `username=${this.view.value('email')}`
    });
    this.http.post<any>(`${Config.apiUrl}/user/emailvalidator/${this.view.value('email')}`, params, { headers: header }).subscribe(data => {
      Config.loading = false;

      if (data == false) {
        this.alertService.error(
          'Username ' + this.view.value('email') + ' is already taken.Please choose another one..',
          true, 10 * 1000
        );
      } else {
        this.overlayflag = true;
      }
    });

  }
  nexttoRole() {
    this.step2 = "done";
    this.step3 = "active";
  }
  nexttoCode() {
    this.step2 = "done";
    this.step3 = "done";
    this.step4 = "active";
  }
  prevtoPersonal() {
    this.overlayflag = false;
    this.step1 = "done";
    this.step2 = "active";
    this.istandc = false;
  }
  prevtoRole() {
    //  this.overlayflag = false;
    if (this.appsumo) {
      this.step1 = "done";
      this.step2 = "done";
      this.step3 = "active";
    }

  }
  onChange(opt: any) {
    // console.log(opt);
    this.selectedTimeZone = opt;
    this.finalTimezone = this.selectedTimeZone;
  }

  onCountryChange(opt: any) {
    // console.log(opt);
    if (!opt) { return; }
    this.timeZones = [];
    try {
      this.timeZones = (moment.tz.zonesForCountry(opt.id));

    }
    catch (e) { }
    let timeZoneList = [];

    for (let i in this.timeZones) {
      try {
        timeZoneList.push(`(GMT${moment.tz(this.timeZones[i]).format('Z')}) ${this.timeZones[i]}`);
      }
      catch (e) { }
    }
    let timeZoneNames;
    timeZoneNames = timeZoneList.sort();
    // console.log(timeZoneNames);
    this.timeZones = this.parseArrayToComboItems(timeZoneNames);

    this.comboTimezones?.focus();

    this.selectedCountry = opt;
    this.selectedTimeZone = this.timeZones[0];
    this.finalCountry = this.selectedCountry;
    // console.log('TIMEZONES: ', moment.tz.zonesForCountry(opt.id, true));
    // console.log(this.timeZones);
  }

  anualPricecalculator(price: number) {
    let montlypice = price / 12;
    let planPrice: string | number;

    if (!Number.isSafeInteger(montlypice)) {
      planPrice = montlypice.toFixed(2);
    } else {
      planPrice = montlypice;
    }
    return planPrice;
  }

  openTnC(typeVal) {
    sessionStorage.setItem('tandc', 'REG');
    sessionStorage.setItem('tandclink', typeVal);

    // const dialogRef =
    this.dialog.open(TermsDialogComponent, {
      width: '98vw',
      height: '98vh',
      autoFocus: false,
      panelClass: 'TnC',
      disableClose: true
    });
  }

  updateCtrlValue(event, fname) {
    //console.log(event.target.value);
    this.view.set(fname, event.target.value);
  }

  parseArrayToComboItems(array: any[]): ComboItem[] {
    const listItems: ComboItem[] = [];
    array.map((v: any, i: any) => {
      listItems.push({
        id: i,
        value: v
      });
    });
    return listItems;
  }

  googleSign() {
    Config.loading = true;

    // this.msSignIn(form.fcUsrname, data.integrationid);
    window.location.href = Config.GOOGLELOGIN_URL;
  }
  linkedinSign() {
    Config.loading = true;

    // this.msSignIn(form.fcUsrname, data.integrationid);
    window.location.href = Config.LINKEDINLOGIN_URL;
  }

  fbSign() {
    Config.loading = true;

    // this.msSignIn(form.fcUsrname, data.integrationid);
    // window.location.href = Config.FBLOGIN_URL;
  }
  getStyle() {
    if (this.view.invalid() || !this.istandc) {
      return '';
    } else {
      return 'primary';
    }
  }

  tNcChange(e: { checked: boolean; }) {
    const v = (e.checked) ? 1 : 0;
    this.istandc = e.checked;
    this.view.form.value.istandc = v;
    //  console.log(e);
    // console.log(this.isbtob);
  }

  refreshtandc() {
    this.istandc = (this.view.form.value.istandc === 1) ? true : false;
    // console.log(this.istandc);
  }

  getNewForm() {
    return this.fb.group({
      name: ['', Validators.required],
      country: [null, Validators.required],
      timezone: [null, Validators.required],
      companytype: [null],
      companytypeother: [''],
      companyrole: [null],
      companyroleother: [''],

      redeemcode: [''],
      usertype: [''],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(AppPattern.EMAIL)
      ])],
      inputPwd: ['', [
        Validators.required,
        Validators.minLength(6)]
      ],
      cpassword: ['']
      // plan: this.fb.group(this.view.get("plan/all"))
    }, { validators: this.checkPasswords });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.inputPwd.value;
    const confirmPass = group.controls.cpassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
  redeemPrev() {
    this.stepper.selectedIndex = 0;
    this.stepper.selectedIndex = 1;
  }


  filterIndustry(event: { query: any; }) {
    let query = event?.query;
    query = query?.toLowerCase().trim();
    this.companyTypeList = this.companyTypeList?.filter((pt: { type_name: string; }) => pt?.type_name?.toLowerCase()?.indexOf(query) >= 0);
    //  console.log(this.filteredCountry);
  }
  onIndustryClear(opt: any) {
    //  this.view.form.get('timezone').patchValue([]);
  }
  filterRole(event: { query: any; }) {
    let query = event?.query;
    query = query?.toLowerCase().trim();
    this.companyRoleList = this.companyRoleList?.filter((pt: { description: string; }) => pt?.description?.toLowerCase()?.indexOf(query) >= 0);
    //  console.log(this.filteredCountry);
  }
  onRoleClear(opt: any) {
    //  this.view.form.get('timezone').patchValue([]);
  }
  disableCheck() {
    let retvalue = false;
    if (this.view.value('companytype') == 'Others (Please specify)') {
      if (!this.view.value('companytypeother')?.trim()) {
        retvalue = true;
      }
    } else {
      if (!this.view.value('companytype')?.trim()) {
        retvalue = true;
      }
    }

    return retvalue;
  }
  disableCheckRole() {
    let retvalue = false;
    if (this.view.value('companyrole') == 'Others (Please specify)') {
      if (!this.view.value('companyroleother')?.trim()) {
        retvalue = true;
      }
    } else {
      if (!this.view.value('companyrole')?.trim()) {
        retvalue = true;
      }
    }

    return retvalue;
  }
  onSubmit() {
    const myEncodedQueryParamValue = this.view.value('inputPwd').replace('+', '%2B');
    let countryPl = this.selectedCountry?.value;
    let timePl = this.selectedTimeZone?.value;

    this.view.set('inputPwd', myEncodedQueryParamValue);
    this.view.set('cpassword', myEncodedQueryParamValue);
    this.view.set('country', this.selectedCountry?.value);
    this.view.set('timezone', this.selectedTimeZone?.value);
    if (this.view.value('companytype') == 'Others (Please specify)') {
      this.view.set('companytype', 'Other-' + this.view.value('companytypeother'));

    }
    if (this.view.value('companyrole') == 'Others (Please specify)') {
      this.view.set('companyrole', 'Other-' + this.view.value('companyroleother'));

    }
    let returnUrl;
    if (!this.appsumo) {
      returnUrl = 'user/subscribe';
    } else {
      returnUrl = 'user/appsumo';
    }
    this.view.saveRedirect(
      returnUrl,
      '/login',
      `Successfully Registered to InsIQ, Account Activation link has been sent to ${this.view.value('email')}. If activation link is not received,reachout to customer support at support@insiq.io.`,
      'Failed to Register, Please try again or contact support@insiq.io'
    );

    sessionStorage.setItem('tandc', 'CLOSEREG');
    this.view.set('country', this.selectedCountry?.id);
    this.view.set('timezone', this.selectedTimeZone.id);
    this.selectedTimeZone = this.selectedTimeZone;
    this.finalTimezone = this.selectedTimeZone;
    this.stepper.selectedIndex = 2;
  }

  sendEmail() {
    this.router.navigate(['/contact']);
  }
  toggleDev2MOverview() {
    // this.config.dev2MOverview = !this.config.dev2MOverview;
    window.open('https://www.insiq.io/', '_blank');
  }

  error(name: string | number) {
    return this.view.error(name);
  }

  showResponse(event: any) {
    this.captcha = true;
  }
}
