<!-- AI Popup -->
<div class="popup aiPopup">
  <div class="popContent h-100 min">
    <div class="wrapper">
      <div class="leftSection collapsed">
        <figure [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" (click)="linkClick('project')"
          style="cursor:pointer">
          <img src="assets/images/icon-insiq-sm.png" alt="insiq" *ngIf="!customThemeService.dynamiclogo">
          <img src="{{customThemeService.logo}}" alt="insiq" *ngIf="customThemeService.dynamiclogo">
        </figure>

        <div class="actions">
          <button type="button" class="btn btn-primary btn-lg rounded justify-content-center btnAddProject" matRipple
            (click)="this.view.state='new';navigateToNewProject()">
            <!-- <i class="i i-add"></i> -->
            <span>Add Project</span>
          </button>
        </div>

        <nav class="nav">
          <div class="title" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"
            (click)="linkClick('project')" style="cursor:pointer">
            <i class="i i-projects"></i>
            <span>Projects</span>
          </div>
          <ul class="stackMenu">
            <li *ngFor="let project of view.list">
              <button type="button" class="btn" (click)="ai.chooseProject(project)" [ngClass]="{
                active: project?.id === view.active?.id
              }">{{getProjName(project)}}</button>
            </li>
          </ul>
        </nav>

        <!-- <div class="sidebar-footer">
          <button type="button" matRipple (click)="ai.yotubeClick()" class="btn  btn-link">
            <mat-icon fontSet="i" class="i-youtube i-1_7x ib-m"></mat-icon>
            <span class="ib-m m-x-5">Watch our How-To Videos</span>
          </button>
          <button type="button" class="btn btn-primary btn-xl curved m-y-10 justify-content-center" matRipple
            (click)="ai.acdemyClick()">
            <span>Join our free academy</span>
          </button>
        </div> -->
      </div>

      <form [formGroup]="ai.view.form" class="rightSection p-y-0" style="--app-gutter-x:0px;" [ngClass]="{
          reportView: ai.reportviewflag
        }" tabindex="0">
        <button type="button" matRipple (click)="ai.showDashboard()" class="btn btn-primary btn-link"
          *ngIf="!ai.dashboard&&!ai.projectFavFlag&&ai.view.value('mode') == 'Research Mode'">
          <mat-icon fontSet="i" class="i-back i-1_7x ib-m"></mat-icon>
          <span class="ib-m m-x-5">Back to My Results</span>
        </button>

        <div [ngClass]="{
          workarea: ai.view.value('mode') == 'Turbo Mode'
        }" [style]="{
          '--app-gutter-x': '16px',
          '--app-ai-sidebar': ai.getCurrentTab() !== 0 ? '0px' : null
        }">
          <section>
            <div class="toolbar">
              <button type="button" matRipple (click)="ai.smartDevclose()" class="btn btn-primary btn-link"
                *ngIf="ai.dashboard||ai.projectFavFlag||ai.view.value('mode') == 'Turbo Mode'">
                <mat-icon fontSet="i" class="i-back i-1_7x ib-m"></mat-icon>
                <span class="ib-m m-x-5">My Dashboard</span>
              </button>
              <div class="m-l-auto">
                <div class="miniActions" *ngIf="!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch">
                  <button type="button" matRipple (click)="ai.shareProj(ai.project);" class="btn btn-primary btn-link"
                    *ngIf="ai.view.form.value.mode==='Turbo Mode'&&ai.projectOwnerCheck()">
                    <mat-icon fontSet="i" class="i-share i-1_7x ib-m"></mat-icon>
                    <span class="ib-m m-x-5">Share this Project</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="pageWrapper _editMode">
              <div class="sectionHeader">
                <div class="productType">
                  <div *ngIf="view.form.value.productNewOrExisting === 1" class="bar-T d-flex align-items-center"
                    [ngClass]="{
                      'justify-content-start': ai.view.value('mode') == 'Turbo Mode',
                      'justify-content-center': ai.view.value('mode')!= 'Turbo Mode'
                    }">
                    <i class="i ico text-accent" title="Explore New Idea" [ngClass]="{
                      'i-idea': view.form.value.productNewOrExisting == 1,
                      'i-monitor_product': view.form.value.productNewOrExisting == 2
                    }"></i>
                    <span>{{view.form.value.brandName?view.form.value.brandName:view.form.value.productName}}</span>
                  </div>

                  <span *ngIf="view.form.value.productNewOrExisting === 2"
                    class="bar-T m-x-auto d-flex align-items-center" [ngClass]="{
                      'justify-content-start': ai.view.value('mode') == 'Turbo Mode',
                      'justify-content-center': ai.view.value('mode') != 'Turbo Mode'
                    }">
                    <i class="i ico text-accent" title="Monitor your Product" [ngClass]="{
                      'i-idea': view.form.value.productNewOrExisting == 1,
                      'i-monitor_product': view.form.value.productNewOrExisting == 2
                    }"></i>{{view.form.value.brandName}}
                  </span>

                  <div class="pageHeading">
                    <span class="projectName" *ngIf="ai.dashboard||ai.view.value('mode') == 'Turbo Mode'">My
                      Insights</span>
                    <!-- AI Insights -->
                    <span
                      *ngIf="!ai.dashboard&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch&&!ai.insightsearch&&!ai.showWish&&ai.view.value('mode') == 'Research Mode'">{{ai.smartTitle}}</span>
                    <span *ngIf="ai.showWish" (click)="ai.filterWish('')">
                      Favorites
                    </span>
                    <span *ngIf="ai.notesviewflag">
                      Added Notes
                    </span>
                    <span *ngIf="ai.reportviewflag">
                      AI Reports
                    </span>
                    <span *ngIf="ai.insightsearch">
                      AI Insights Search
                    </span>
                  </div>

                  <div class="miniActions t-c"
                    *ngIf="!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch&&!ai.getConfigLoad()">
                    <button type="button" matRipple (click)="ai.showDashboard()" class="btn btn-primary btn-link"
                      *ngIf="!ai.dashboard&&ai.view.value('mode') == 'Research Mode'">
                      <mat-icon fontSet="i" class="i-my_insights_alt i-1_7x ib-m"></mat-icon>
                      <span class="ib-m m-x-5">My Insights</span>
                    </button>
                  </div>
                </div>

                <!-- Removed Project Edit Form inside AI Chat -->
                <div class="gridFormWrapper" [ngClass]="{
                  'd-none': ai.view.value('mode') == 'Turbo Mode',
                  active: ai.projSettingOpenButton()
                }">
                  <button type="button" matRipple [ngClass]="{
                    active: ai.projSettingOpenButton(),
                    collapsed: !ai.projSettingOpenButton()
                  }" class="btn btn-lg btn-primary lighten w-100 btn-link t-c" (click)="ai.projSettingOpen()"
                    *ngIf="!ai.showWish && !ai.notesviewflag && !ai.reportviewflag && !ai.insightsearch && !ai.getConfigLoad()">
                    <span>View your project input</span>
                    <i class="i" [ngClass]="{
                      'i-keyboard_arrow_up': ai.projSettingOpenButton(),
                      'i-keyboard_arrow_down': !ai.projSettingOpenButton()
                    }"></i>
                  </button>

                  <div class="overlayWrap">
                    <ng-container
                      *ngIf="ai.projSettingOpenButton()&&(!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch&&!ai.getConfigLoad())">
                      <div class="compactForm" *ngIf="ai.view.value('mode') == 'Turbo Mode'">
                        <div class="formWrapper">
                          <div class="row">
                            <div class="col-lg-24">
                              <label class="mandatory">Briefly describe your project or product</label>
                            </div>
                            <div class="col-lg-24">
                              <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                                <textarea matInput placeholder="Describe your project or product" rows="4"
                                  formControlName="productName"></textarea>
                              </mat-form-field>
                            </div>
                            <div class="col-lg-24">
                              <label class="mandatory">Project name</label>
                            </div>
                            <div class="col-lg-24">
                              <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                                <input type="text" matInput placeholder="Enter Project Name"
                                  formControlName="brandName">
                              </mat-form-field>
                            </div>
                            <div class="col-lg-24">
                              <label class="mandatory">What do you need to do?</label>
                            </div>
                            <div class="col-lg-24">
                              <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                                <!-- Replace input with textarea -->
                                <textarea matInput formControlName="productDescription"
                                  placeholder="Enter what do you need to do" rows="3"></textarea>
                              </mat-form-field>
                            </div>
                            <div class="action"></div>
                          </div>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+CREATE PROJ -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==199&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is exploring a new idea for a product </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                        </div>
                        <div class="line">
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might</span>
                        </div>
                        <div class="line">
                          <span>emphasize </span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,199)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="productDescription"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                          </div>
                          <span> and could address to </span>
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>
                          <span>. We aim to</span>
                        </div>
                        <div class="line">
                          <span>understand potential consumer interest and market viability in the</span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>. Competitor Companies</span>
                        </div>
                        <div class="line">
                          <span>could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>

                        </div>
                        <div class="line">
                          <span>Kindly provide insights in </span>
                          <div class="fc_group fc_lg disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span> to help us shape our concept and strategy.</span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+Consumer Insights  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==31&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is looking for consumer insights that could inform the development of a product
                          </span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">


                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,31)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword31"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>
                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>
                        </div>
                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We seek insights in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="line">
                          <span> that could help us understand potential consumer needs, preferences, and behaviors in
                            the
                          </span>
                          <div class="fc_group fc_lg">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+BLOGS  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==6&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is looking for blogs and reviews related to products similar to our new idea </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">


                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,6)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword6"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We seek content in </span>
                          <div class="fc_group fc_sm disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>that</span>
                        </div>
                        <div class="line">

                          <span>discusses and evaluates similar products, their features, and benefits in the </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market to help us</span>
                        </div>
                        <div class="line"> <span>shape our product concept.</span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+PRICING Insights  -->

                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==101&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_xxl">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>,needs pricing insights for products similar to our new idea </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,199)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="productDescription"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We require detailed analysis in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                        </div>
                        <div class="line">
                          <span>to help us understand the potential pricing strategies and market opportunities in
                            the</span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>

                      <!-- EXPLORE NEW+PRODUCT NEWS Insights  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==10&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_lg">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, requests recent product news related to products similar to our new idea </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">


                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,10)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword10"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We seek updates in </span>
                          <div class="fc_group fc_sm disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div><span>about</span>
                        </div>
                        <div class="line">

                          <span> industry developments, innovations, and events that may impact our new product idea in
                            the
                          </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                        </div>
                        <div class="line">
                          <span>market.</span>
                        </div>
                      </div>

                      <!-- EXPLORE NEW+Product and competitor sentiment  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==0&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>,is interested in understanding the sentiment towards products similar to our </span>

                        </div>
                        <div class="line">
                          <span>new idea</span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be</span>


                        </div>
                        <div class="line">
                          <span> described as </span>
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,199)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="productDescription"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                          </div>
                          <span>and</span>
                        </div>
                        <div class="line">
                          <span> could address to</span>

                          <div class="fc_group fc_xl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>
                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                        </div>

                        <div class="line">

                          <span>and competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We are seeking sentiment analysis in </span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>
                            to gauge consumer and market reactions towards similar products and their competitors in the
                          </span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_lg">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.This information will help us anticipate potential reception of our product idea
                            and
                          </span>
                        </div>
                        <div class="line">
                          <span>
                            shape our development and marketing strategies accordingly.</span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+Similar Products or Projects  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==2&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_lg">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, requires information on similar products or projects to our new idea </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">


                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,2)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword2"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>
                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We are interested in understanding the competitive</span>

                        </div>
                        <div class="line">
                          <span> landscape in the </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.Please provide insights in.</span>
                          <div class="fc_group fc_sm disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>to help us </span>
                        </div>
                        <div class="line">


                          <span> identify potential strengths, weaknesses, opportunities, and threats.</span>
                        </div>

                      </div>
                      <!-- EXPLORE NEW+COMPETITORS  -->

                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==32&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_lg">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, needs an overview of potential competitors for our new product idea</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,32)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword32"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We seek competitor profiles in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                        </div>
                        <div class="line">
                          <span>to assess their strategies, strengths, and weaknesses in the </span>
                          <div class="fc_group fc_lg">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>

                      <!-- EXPLORE NEW+INFLUENCERS -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==8&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_xl">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is looking for influencers and experts related to our new product idea </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>
                          </div>
                          <span>, which could be described as </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,8)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword8"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We request a list of relevant influencers and experts in </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_lg disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>who could potentially help us promote our new product and improve its visibility in the
                          </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+Scientific Reserch & Tech  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==11&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_xl">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, requests scientific research related to our new product idea </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,11)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword11"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We seek research findings and studies in </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>to support our product development and ensure its potential efficacy and quality in
                            the</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+Product safety & Regulatory  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==7&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_lg">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, requires product safety information for similar products to our new idea </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,7)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword7"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We need insights in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="line">

                          <span> to ensure our product concept complies with safety regulations and standards in the
                          </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+Suppliers logistics and related  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==5&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is seeking information on suppliers and logistics for similar products to our new
                            idea</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,5)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword5"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We require a list of potential suppliers and</span>

                        </div>
                        <div class="line">
                          <span> logistics partners in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span> to help us understand the potential manufacturing and distribution processes</span>

                        </div>
                        <div class="line">
                          <span> for our new product in the </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+Patents and Trademarks  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==4&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is seeking information on patents and trademarks related to our new idea </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,4)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword4"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We require insights in </span>
                          <div class="fc_group fc_sm disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="line">

                          <span>to ensure our new product concept complies with legal requirements and avoids potential
                            infringements in the</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div><span>market.</span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+Analyze your data   -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '1'&&ai.flSelected==9&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_xxl">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is looking to analyze data related to our new idea</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)"
                              placeholder="[What would you like to name your product?]"
                              title="[What would you like to name your product?]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,9)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword9"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> and could address to</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                              title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                          </div>

                          <span>. Competitor Companies could be </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and </span>

                        </div>

                        <div class="line">
                          <span>competitor products could be </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We need insights in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="line">

                          <span>to understand market trends, consumer behaviors, and competitive landscape in the
                          </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market</span>
                        </div>
                        <div class="line"> <span> to shape our new product idea .</span>
                        </div>
                      </div>


                      <!-- Monitor+CREATE PROJ -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==199&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, wants to ensure the success of our </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>

                          </div>
                        </div>
                        <div class="line">

                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>

                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                        </div>
                        <div class="line">

                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,199)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="productDescription"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                          </div>
                          <span>Our Product Company Name is</span>
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                          </div>
                          <span>. Competitor</span>
                        </div>
                        <div class="line">
                          <span> Companies are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor products are</span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>.</span>
                        </div>
                        <div class="line">
                          <span> We aim to gather insights on consumer usage and feedback to enhance our offering.
                            Kindly
                            provide your observations and suggestions in </span>

                        </div>
                        <div class="line">

                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>to help us improve and optimize our product in the </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>

                      </div>
                      <!-- EXPLORE NEW+Consumer Insights  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==31&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is looking for consumer insights for our</span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>

                          </div>
                        </div>
                        <div class="line">
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,31)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword31"></ngx-nitro-multiline>

                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span>. Our Product Company Name is</span>

                          <div class="fc_group fc_xl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                          </div>
                          <span>. Competitor</span>
                        </div>
                        <div class="line">
                          <span> Companies are</span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span>and competitor products are</span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. </span>
                        </div>
                        <div class="line">
                          <span>
                            We aim to understand consumer preferences, behaviors, and purchasing patterns in the
                          </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                        </div>
                        <div class="line">
                          <span> market. Kindly provide insights in</span>
                          <div class="fc_group fc_sm disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>to help us tailor our marketing strategies and improve customer satisfaction. </span>
                        </div>
                      </div>
                      <!-- EXPLORE NEW+BLOGS  -->

                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==6&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_xl">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is looking for blogs and reviews related to our</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which could be described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                          <div class="fc_group fc_xl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,6)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword6"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> Our Product Company Name is </span>

                        </div>
                        <div class="line">

                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                          </div>
                          <span>. Competitor Companies are </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor</span>

                        </div>

                        <div class="line">
                          <span> products are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We seek content in </span>
                          <div class="fc_group fc_sm disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>that discusses and evaluates</span>
                        </div>
                        <div class="line">

                          <span> our product, its features, and benefits in the </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market to enhance our online presence and reputation.</span>
                        </div>
                      </div>
                      <!-- Monitor+PRICING Insights  -->

                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==101&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>,needs pricing insights for our </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">
                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,199)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="productDescription"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                          </div>
                          <span>Our Product Company Name is</span>
                          <div class="fc_group fc_xl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                          </div>
                          <span>. Competitor</span>
                        </div>
                        <div class="line">
                          <span> Companies are</span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span>and competitor products are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>.</span>
                        </div>
                        <div class="line">
                          <span> We require detailed analysis in </span>
                          <div class="fc_group fc_sm disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>to help us establish competitive pricing strategies and identify potential</span>
                        </div>
                        <div class="line">
                          <span> opportunities in the
                          </span>
                          <div class="fc_group fc_lg">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>

                      <!-- Monitor+PRODUCT NEWS Insights  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==10&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_xl">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, requests recent product news related to our </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>
                          </div>
                          <span>, which is described as </span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                          <div class="fc_group fc_xl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,10)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword10"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> Our Product Company Name is</span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                          </div>
                          <span>. Competitor Companies are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor</span>
                        </div>
                        <div class="line">
                          <span> products are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We seek updates in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>about industry</span>
                        </div>
                        <div class="line">
                          <span> developments, innovations, and events that may impact our product's
                            performance in
                            the
                          </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>

                      <!-- Monitor+Product and competitor sentiment  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==0&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is interested in understanding the sentiment towards products similar to our </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,199)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="productDescription"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                          </div>
                          <span> Our Product Company Name is</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                          </div>

                          <span>. Competitor Companies are </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor</span>

                        </div>

                        <div class="line">
                          <span> products are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We are seeking sentiment analysis in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="line">
                          <span>to gauge consumer and market reactions towards similar products and their competitors in
                            the
                          </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                        </div>
                        <div class="line">
                          <span>market. This information will help us anticipate potential reception of our product idea
                            and
                            shape our development and marketing
                          </span>
                        </div>
                        <div class="line">
                          <span> strategies accordingly.</span>
                        </div>
                      </div>
                      <!-- Monitor+Similar Products or Projects  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==2&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_lg">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, requires information on similar products or projects to our </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>

                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,2)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword2"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span>Our Product Company Name is</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                          </div>

                          <span>. Competitor Companies are </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor</span>

                        </div>
                        <div class="line">
                          <span> products are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We are interested in understanding the competitive landscape in the</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.Please provide insights in.</span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>to help us identify potential strengths,</span>
                        </div>
                        <div class="line">
                          <span> weaknesses, opportunities, and threats.</span>
                        </div>
                      </div>
                      <!-- Monitor+COMPETITORS  -->

                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==32&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_lg">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, needs an overview of potential competitors for our</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,32)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword32"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> Our Product Company Name is</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                          </div>

                          <span>. Competitor Companies are</span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor</span>

                        </div>

                        <div class="line">
                          <span> products are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We seek competitor profiles in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="line">
                          <span>to assess their strategies, strengths, and weaknesses in the </span>
                          <div class="fc_group fc_lg">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>

                      <!-- Monitor+INFLUENCERS -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==8&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_xl">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is looking for influencers and experts related to our </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,8)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword8"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> Our Product Company Name is</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                          </div>

                          <span>. Competitor Companies are </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor</span>

                        </div>

                        <div class="line">
                          <span> products are</span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We request a list of relevant influencers and experts in </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span> who could potentially help us promote our product and improve its visibility in the
                          </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>
                      <!-- Monitor+Scientific Reserch & Tech  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==11&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, requests scientific research related to our</span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                        </div>
                        <div class="line">
                          <span>in the </span>
                          <div class="fc_group fc_xxl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                        </div>
                        <div class="line">
                          <span> and emphasize</span>
                          <div class="fc_group fc_xxl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,11)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword11"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span>Our Product Company Name is</span>
                          <div class="fc_group fc_xl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                          </div>
                          <span>. </span>
                        </div>
                        <div class="line">
                          <span>Competitor Companies are </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor products are</span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We seek research findings and studies in </span>
                          <div class="fc_group fc_xl disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="line">
                          <span>to support our product development and ensure its potential efficacy and quality in
                            the</span>
                          <div class="fc_group fc_lg">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>

                      </div>
                      <!-- Monitor+Product safety & Regulatory  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==7&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_xl">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, requires product safety information for similar products to our </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                          <div class="fc_group fc_xl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,7)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword7"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> Our Product Company Name is</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                          </div>

                          <span>. Competitor Companies are </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor</span>

                        </div>

                        <div class="line">
                          <span> products are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We need insights in </span>
                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span> to ensure our product</span>
                        </div>
                        <div class="line">

                          <span> concept complies with safety regulations and standards in the </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>
                      <!-- Monitor+Suppliers logistics and related  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==5&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_lg">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is seeking information on suppliers and logistics for similar products to our</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                          <div class="fc_group fc_xl">


                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,5)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword5"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> Our Product Company Name is</span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                          </div>
                          <span>. Competitor Companies are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor</span>
                        </div>
                        <div class="line">
                          <span> products are</span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We require a list of potential suppliers and logistics partners in</span>
                        </div>
                        <div class="line">

                          <div class="fc_group fc_md disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span> to help us understand the potential manufacturing and distribution processes for our
                            product in the</span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market.</span>
                        </div>
                      </div>
                      <!-- Monitor+Patents and Trademarks  -->
                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==4&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_xl">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>,is seeking information on patents and trademarks related to our </span>

                        </div>
                        <div class="line">
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>


                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and might emphasize</span>
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,4)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword4"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> Our Product Company</span>

                        </div>
                        <div class="line">
                          <span> Name is</span>
                          <div class="fc_group fc_xl">


                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                          </div>

                          <span>. Competitor Companies are </span>

                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span>and</span>
                        </div>

                        <div class="line">

                          <span>competitor products are </span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. We require insights in </span>
                          <div class="fc_group fc_sm disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                                  {{lang.languageName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>to ensure</span>
                        </div>
                        <div class="line">

                          <span>our product concept complies with legal requirements and avoids potential
                            infringements in the
                          </span>
                          <div class="fc_group fc_lg">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                        </div>
                        <div class="line">
                          <span>market.</span>
                        </div>
                      </div>
                      <!--Monitor+Analyze your data   -->

                      <div class="gridForm"
                        *ngIf="ai.view.value('productNewOrExisting') == '2'&&ai.flSelected==9&&ai.view.value('mode') == 'Research Mode'">
                        <div class="line">
                          <span>Our project owner, </span>
                          <div class="fc_group fc_md">
                            <div class="ui-fluid">
                              <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner"
                                [minLength]="1" [suggestions]="userFiltered" placeholder="Project Owners"
                                title="Project Owners" field="name" [forceSelection]="true"
                                [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id))"
                                [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||ai.view.value('projectOwner')?.id === config.currentUser?.id)"
                                [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                                (completeMethod)="filterOwner($event)"></p-autoComplete>
                              <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                                {{view.error('projectOwner')}}
                              </mat-error>
                            </div>
                          </div>
                          <span>, is looking to analyze data related to our</span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                              formControlName="brandName"></ngx-nitro-multiline>
                            <mat-error class="errorClass"
                              *ngIf="ai.view.invalid('brandName')">{{ai.view.error('brandName')}}</mat-error>
                          </div>
                        </div>
                        <div class="line">
                          <span>in the </span>
                          <div class="fc_group fc_xl">
                            <p-autoComplete formControlName="productTypeId" [suggestions]="ai.filteredBrands"
                              (completeMethod)="ai.filterBrands($event)" styleClass="w-100" [minLength]="0"
                              placeholder="[Product Category]" title="[Product Category]" field="title"
                              [dropdown]="false">
                            </p-autoComplete>
                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                          </div>
                          <span>, which is described as </span>
                          <div class="fc_group fc_xl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Description]"
                              title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                            <mat-error class="errorClass"
                              *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                          </div>
                          <span> and emphasize</span>
                        </div>
                        <div class="line">
                          <div class="fc_group fc_xl">

                            <div class="inputGroup mlInput _R">
                              <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                                (input)="ai.onMlInputChange($event,9)" placeholder="[Keywords]" title="[Keywords]"
                                formControlName="keyword9"></ngx-nitro-multiline>
                              <div class="action">
                                <button type="button" class="btn btn-primary lighten" (click)="ai.keywordSuggest(view)"
                                  *ngIf="ai.keywordStat(view)">
                                  <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span> Our Product Company Name is</span>
                          <div class="fc_group fc_xl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                              title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                          </div>
                          <span>. Competitor</span>
                        </div>
                        <div class="line">

                          <span> Companies are </span>
                          <div class="fc_group fc_xxl">

                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Companies]" title="[Competitor Companies]"
                              formControlName="competitors"></ngx-nitro-multiline>
                          </div>
                          <span> and competitor products are</span>
                          <div class="fc_group fc_xxl">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                              placeholder="[Competitor Products]" title="[Competitor Products]"
                              formControlName="competitorspdt"></ngx-nitro-multiline>
                          </div>
                          <span>. </span>
                        </div>

                        <div class="line">
                          <span> We need insights in </span>
                          <div class="fc_group fc_lg disableMatError">
                            <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                              matTooltipPosition="above">
                              <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                                <mat-option *ngFor="let lang of apiLanguage"
                                  [value]="lang.languageCode">{{lang.languageName}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span>to understand market trends, consumer behaviors, and competitive landscape </span>
                        </div>
                        <div class="line">
                          <span>in the </span>
                          <div class="fc_group fc_lg">
                            <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                              (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                              formControlName="targetRegion"></ngx-nitro-multiline>
                          </div>
                          <span>market to shape our product idea.</span>
                        </div>
                      </div>

                      <div class="row justify-content-center">
                        <button type="button" matRipple (click)="ai.projSave()" class="btn btn-primary m-b-10">
                          <i class="i i-save i-1_5x m-r-10"></i>
                          <span>Save Project</span>
                        </button>
                        <button type="button" matRipple (click)="ai.insightGenerator()"
                          *ngIf="ai.view.value('mode') == 'Turbo Mode'"
                          class="btn btn-primary min w-200px m-b-10 m-l-30">
                          <span>Go</span>
                          <i class="i i-send i-1_5x m-l-10"></i>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="toolWrapper t-c" *ngIf="ai.view.value('mode') == 'Research Mode'">
                  <button type="button" matRipple title="Insight Search"
                    (click)="ai.insightSearchView(view);ai.hidePaginator = true;" class="btn btn-primary btn-link"
                    *ngIf="!ai.insightsearch">
                    <mat-icon fontSet="i" class="i-search i-1_7x ib-m rotate"></mat-icon>
                    <span class="ib-m m-x-5">Insight Search</span>
                  </button>
                  <button type="button" matRipple title="AI Report"
                    (click)="ai.reportview(view);ai.hidePaginator = true;" class="btn btn-primary btn-link"
                    *ngIf="!ai.reportviewflag">
                    <mat-icon fontSet="i" class="i-ai_report i-1_7x ib-m"></mat-icon>
                    <span class="ib-m m-x-5">AI Report</span>
                  </button>
                  <button type="button" matRipple title="Added Notes"
                    (click)="ai.notesListview(view);ai.hidePaginator = true;" class="btn btn-primary btn-link"
                    *ngIf="!ai.notesviewflag&&!ai.tagcloudclick&&!ai.InsIQclickflg&&!ai.partnerclickflg||ai.dashboard||ai.tagcloudclick">
                    <mat-icon fontSet="i" class="i-notes i-1_7x ib-m"></mat-icon>
                    <span class="ib-m m-x-5">Added Notes</span>
                  </button>
                  <button type="button" matRipple title="Favorites"
                    (click)="ai.wishListInsight(view);ai.hidePaginator = true;" class="btn btn-primary btn-link"
                    *ngIf="!ai.favstar&&!ai.showWish">
                    <mat-icon fontSet="i" class="i-favourite i-1_7x ib-m"></mat-icon>
                    <span class="ib-m m-x-5">Favorites</span>
                  </button>
                  <button type="button" matRipple (click)="ai.showDashboard();ai.hidePaginator = true;"
                    class="btn btn-primary btn-link"
                    *ngIf="(ai.showWish||ai.notesviewflag||ai.reportviewflag||(ai.insightsearch&&ai.wishListAll))&&!ai.projectFavFlag">
                    <mat-icon fontSet="i" class="i-my_insights_alt i-1_7x ib-m"></mat-icon>
                    <span class="ib-m m-x-5">My Insights</span>
                  </button>
                </div>

                <div class="reportViewSect" *ngIf="ai.reportviewflag">
                  <div class="row align-items-center">
                    <div class="w-100"></div>
                    <div class="col-md-18">
                      <mat-form-field appearance="outline" class="w-100 m-b--10 sm aiField">
                        <input matInput placeholder="Enter title for InsIQ AI Report" formControlName="productName"
                          #productName [appFocusNext]="ai.view.invalid('productName')?productName:search"
                          id="reporttitle">
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <div class="action align-self-center">
                        <button matRipple class="t-normal btn btn-lg btn-primary" (click)="ai.reportGenerator('','')"
                          [disabled]="!productName.value || productName.value.trim() === '' || ai?.chartreportinsights?.length === 0 && ai?.reportinsights?.length === 0&&ai.insightsSummary?.length === 0"
                          #search>
                          <span class="ib-m">Download Report</span>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="reportViewSect" *ngIf="ai.insightsearch">
                  <div class="row align-items-center">
                    <div class="w-100"></div>
                    <div class="col-md-18">
                      <mat-form-field appearance="outline" class="w-100 m-b--10 sm aiField">
                        <input matInput placeholder="Enter Keywords to search Insights" #Aikeyword
                          (keypress)="ai.omit_special_char($event,Aikeyword.value.trim(),'1',true)" id="newHero">
                      </mat-form-field>
                    </div>

                    <div class="col-md-6">
                      <button type="button" matRipple class="btn btn-lg btn-primary lighten"
                        [matMenuTriggerFor]="appMenu" [disabled]="Aikeyword.value.trim()==''" #search>
                        <span class="ib-m">Search Insights</span>
                        <i class="i i-keyboard_arrow_down"></i>
                      </button>
                      <mat-menu #appMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'1',true)"
                          [disabled]="!ai.featureChecker(4)">All Insights</button>
                        <h2 class="subTitle">Market Intelligence</h2>
                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'31',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('31',view)}}</button>
                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'6',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('6',view)}}</button>

                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'10',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('10',view)}}</button>

                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'2',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('2',view)}}</button>
                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'32',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('32',view)}}</button>

                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'8',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('8',view)}}</button>
                        <h2 class="subTitle">Product R&D Intelligence</h2>
                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'11',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('11',view)}}</button>
                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'7',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('7',view)}}</button>
                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'5',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('5',view)}}</button>

                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'4',true)"
                          [disabled]="!ai.featureChecker(4)">{{ai.catName('4',view)}}</button>
                        <h2 *ngIf="ai.featureChecker(18)" class="subTitle">Business Intelligences</h2>
                        <button mat-menu-item (click)="ai.searchApiCall(Aikeyword.value.trim(),'9',true)"
                          [disabled]="!ai.featureChecker(4)"
                          *ngIf="ai.featureChecker(18)">{{ai.catName('9',view)}}</button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <div class="tagWrapper" *ngIf="view.isEdit()&&ai.view.value('mode') == 'Research Mode'">
                  <mat-chip-list aria-label="Insight categories"
                    *ngIf="!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch&&!ai.getConfigLoad()">
                    <mat-chip matTooltipPosition=" below" title="{{row}}" [ngClass]="ai.flSelected==31?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('31'),ai.catName('31',view),'31')">
                      <span>{{ai.catName('31',view)}}</span>
                      <small *ngIf="ai?.getailink('31')?.length"
                        class="badge bg-primary">{{ai?.getailink('31')?.length}}</small>
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}" [ngClass]="ai.flSelected==6?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('6'),ai.catName('6',view),'6')">
                      <span>{{ai.catName('6',view)}}</span>
                      <small *ngIf="ai?.getailink('6')?.length"
                        class="badge bg-primary">{{ai?.getailink('6')?.length}}</small>
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}" (click)="ai.averagePriceClick()"
                      [ngClass]="ai.flSelected==101?'active':''">
                      Pricing Insights
                      <small *ngIf="ai?.averagePriceRes?.sResponse?.length"
                        class="badge bg-primary">{{ai?.averagePriceRes?.sResponse?.length}}</small>
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}" [ngClass]="ai.flSelected==10?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('10'),ai.catName('10',view),'10')">
                      <span>{{ai.catName('10',view)}}</span>
                      <small *ngIf="ai?.getailink('10')?.length"
                        class="badge bg-primary">{{ai?.getailink('10')?.length}}</small>
                    </mat-chip>

                    <mat-chip matTooltipPosition="below" title="{{row}}" [ngClass]="ai.flSelected==2?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('2'),ai.catName('2',view),'2')">
                      <span>{{ai.catName('2',view)}}</span>
                      <small *ngIf="ai?.getailink('2')?.length"
                        class="badge bg-primary">{{ai?.getailink('2')?.length}}</small>
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}" [ngClass]="ai.flSelected==32?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('32'),ai.catName('32',view),'32')">
                      <span>{{ai.catName('32',view)}}</span>
                      <small *ngIf="ai?.getailink('32')?.length"
                        class="badge bg-primary">{{ai?.getailink('32')?.length}}</small>
                    </mat-chip>

                    <mat-chip matTooltipPosition="below" title="{{row}}" [ngClass]="ai.flSelected==8?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('8'),ai.catName('8',view),'8')">
                      <span>{{ai.catName('8',view)}}</span>
                      <small *ngIf="ai?.getailink('8')?.length"
                        class="badge bg-primary">{{ai?.getailink('8')?.length}}</small>
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}" [ngClass]="ai.flSelected==11?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('11'),ai.catName('11',view),'11')">
                      <span>{{ai.catName('11',view)}}</span>
                      <small *ngIf="ai?.getailink('11')?.length"
                        class="badge bg-primary">{{ai?.getailink('11')?.length}}</small>
                    </mat-chip> <mat-chip matTooltipPosition="below" title="{{row}}"
                      [ngClass]="ai.flSelected==7?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('7'),ai.catName('7',view),'7')">
                      <span>{{ai.catName('7',view)}}</span>
                      <small *ngIf="ai?.getailink('7')?.length"
                        class="badge bg-primary">{{ai?.getailink('7')?.length}}</small>
                    </mat-chip> <mat-chip matTooltipPosition="below" title="{{row}}"
                      [ngClass]="ai.flSelected==5?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('5'),ai.catName('5',view),'5')">
                      <span>{{ai.catName('5',view)}}</span>
                      <small *ngIf="ai?.getailink('5')?.length"
                        class="badge bg-primary">{{ai?.getailink('5')?.length}}</small>
                    </mat-chip> <mat-chip matTooltipPosition="below" title="{{row}}"
                      [ngClass]="ai.flSelected==4?'active':''"
                      (click)="ai.showSmartItem(ai?.getailink('4'),ai.catName('4',view),'4')">
                      <span>{{ai.catName('4',view)}}</span>
                      <small *ngIf="ai?.getailink('4')?.length"
                        class="badge bg-primary">{{ai?.getailink('4')?.length}}</small>
                    </mat-chip>

                    <mat-chip matTooltipPosition="below" title="{{row}}" [ngClass]="ai.flSelected==9?'active':''"
                      *ngIf="ai.featureChecker(18)"
                      (click)="ai.showSmartItem(ai?.getailink('9'),ai.catName('9',view),'9')">
                      <span>{{ai.catName('9',view)}}</span>
                      <small *ngIf="ai?.getailink('9')?.length"
                        class="badge bg-primary">{{ai?.getailink('9')?.length}}</small>
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      (click)="ai.showSmartItem(ai?.getailink('0'),ai.catName('0',view),'0')"
                      *ngIf="ai.sentimentResp?.length==0" [ngClass]="ai.flSelected==0?'active':''">
                      Product and Competitor Sentiment
                    </mat-chip>

                    <mat-expansion-panel class="togglePanel" *ngIf="ai.sentimentResp?.length!=0"
                      [expanded]="(ai.flValSelect==0)?true:false">
                      <mat-expansion-panel-header (click)="ai.testclick()">
                        <mat-panel-title>
                          Product & Competitor Sentiment
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="subtitile">
                        Choose a Product or Competitor you want Analyze
                      </div>
                      <div class="subMenuItem">
                        <button matRipple class="btn btn-primary lighten justify-content-center"
                          *ngFor="let sentimentSelected of ai.sentimentResp; first as isFirst"
                          [ngClass]="(ai.sentimentSelected?.keyvalue === sentimentSelected.keyvalue&&ai.sentimentSelected?.Mode === sentimentSelected.Mode) ? 'active' : ''"
                          (click)="ai.sentimentclick(sentimentSelected);ai.flSelected=0;ai.flValSelect=0">
                          <mat-icon fontSet="i" fontIcon="i-{{ai.getSentimnent(sentimentSelected.Mode)}}"
                            aria-label="help_outline" class="i-1_5x">
                          </mat-icon>
                          <span>{{(sentimentSelected.Mode!==2)?sentimentSelected.cn:ai.getSentLabel(sentimentSelected.QueryUsed)}}</span>
                        </button>
                      </div>
                    </mat-expansion-panel>
                  </mat-chip-list>
                  <mat-chip-list aria-label="Insight categories"
                    *ngIf="ai.showWish||ai.notesviewflag||ai.reportviewflag||(ai.insightsearch&&ai.wishListAll)">
                    <mat-chip matTooltipPosition=" below" title="{{row}}"
                      [ngClass]="ai.smartTitle === 'All' || ai.smartTitle === ''? 'active' : ''"
                      (click)="ai.filterWish('')">
                      All Insights
                    </mat-chip>
                    <mat-chip matTooltipPosition=" below" title="{{row}}"
                      *ngIf="ai.reportviewflag&&ai.chartreportinsights!=''"
                      [ngClass]="ai.chartReports === true ? 'active': ''" (click)="ai.chartReport()">
                      Charts
                    </mat-chip>
                    <mat-chip matTooltipPosition=" below" title="{{row}}"
                      [ngClass]="ai.smartTitle === ai.catName('31',view) ? 'active' : ''"
                      [disabled]="ai.wishCategorymanger(ai.catName('31',view))"
                      (click)="ai.filterWish(ai.catName('31',view))">
                      {{ai.catName('31',view)}}
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      [ngClass]="ai.smartTitle === ai.catName('6',view) ? 'active' : ''"
                      [disabled]="ai.wishCategorymanger(ai.catName('6',view))"
                      (click)="ai.filterWish(ai.catName('6',view))">
                      {{ai.catName('6',view)}}
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger('Pricing Insights')"
                      [ngClass]="ai.smartTitle === 'Pricing Insights' ? 'active' : ''"
                      (click)="ai.filterWish('Pricing Insights')">
                      Pricing Insights
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger(ai.catName('10',view))"
                      [ngClass]="ai.smartTitle === ai.catName('10',view) ? 'active' : ''"
                      (click)="ai.filterWish(ai.catName('10',view))">
                      {{ai.catName('10',view)}}
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger('Product and Competitor sentiment')"
                      [ngClass]="ai.smartTitle === 'Product and Competitor sentiment' ? 'active' : ''"
                      (click)="ai.filterWish('Product and Competitor sentiment')">
                      Product and Competitor Sentiment
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger(ai.catName('2',view))"
                      [ngClass]="ai.smartTitle === ai.catName('2',view) ? 'active' : ''"
                      (click)="ai.filterWish(ai.catName('2',view))">
                      {{ai.catName('2',view)}}
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger(ai.catName('32',view))"
                      [ngClass]="ai.smartTitle === ai.catName('32',view) ? 'active' : ''"
                      (click)="ai.filterWish(ai.catName('32',view))">
                      {{ai.catName('32',view)}}
                    </mat-chip>

                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger(ai.catName('8',view))"
                      [ngClass]="ai.smartTitle ==='Influencers, SME’s and related' ? 'active' : ''"
                      (click)="ai.filterWish(ai.catName('8',view))">
                      {{ai.catName('8',view)}}
                    </mat-chip>
                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger(ai.catName('11',view))"
                      [ngClass]="ai.smartTitle === ai.catName('11',view) ? 'active' : ''"
                      (click)="ai.filterWish(ai.catName('11',view))">
                      {{ai.catName('11',view)}}
                    </mat-chip> <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger(ai.catName('7',view))"
                      [ngClass]="ai.smartTitle === ai.catName('7',view) ? 'active' : ''"
                      (click)="ai.filterWish(ai.catName('7',view))">
                      {{ai.catName('7',view)}}
                    </mat-chip> <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger(ai.catName('5',view))"
                      [ngClass]="ai.smartTitle === ai.catName('5',view) ? 'active' : ''"
                      (click)="ai.filterWish(ai.catName('5',view))">
                      {{ai.catName('5',view)}}
                    </mat-chip> <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger(ai.catName('4',view))"
                      [ngClass]="ai.smartTitle === ai.catName('4',view) ? 'active' : ''"
                      (click)="ai.filterWish(ai.catName('4',view))">
                      {{ai.catName('4',view)}}
                    </mat-chip>

                    <mat-chip matTooltipPosition="below" title="{{row}}" *ngIf="ai.featureChecker(18)"
                      [disabled]="ai.wishCategorymanger(ai.catName('9',view))"
                      [ngClass]="ai.smartTitle === ai.catName('9',view) ? 'active' : ''"
                      (click)="ai.filterWish(ai.catName('9',view))">
                      {{ai.catName('9',view)}}
                    </mat-chip>

                    <mat-chip matTooltipPosition="below" title="{{row}}"
                      [disabled]="ai.wishCategorymanger('Trend Insights')"
                      [ngClass]="ai.smartTitle === 'Trend Insights' ? 'active' : ''"
                      (click)="ai.filterWish('Trend Insights')">
                      Trend Insights
                    </mat-chip>

                    <mat-chip matTooltipPosition="below" title="{{row}}" [disabled]="ai.wishCategorymanger('Deep Dive')"
                      [ngClass]="ai.smartTitle === 'Deep Dive' ? 'active' : ''" (click)="ai.filterWish('Deep Dive')">
                      Deep Dive
                    </mat-chip>
                  </mat-chip-list>
                </div>

                <div class="toolbar justify-content-center m-b-0"
                  *ngIf="!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch&&!ai.getConfigLoad()&&ai.view.value('mode') == 'Research Mode'">
                  <div class="action">
                    <button type="button" class="btn btn-lg btn-link btn-danger"
                      (click)="ai.clearCache(view,'0', ai.smartlistItem)">
                      <i class="i i-delete"></i>
                      <span>Clear All Insights</span>
                    </button>
                  </div>
                </div>
                <div class="d-flex align-items-center m-t-5 justify-content-center"
                  *ngIf="!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch&&!ai.getConfigLoad()&&ai.view.value('mode') == 'Research Mode'">

                  <div class="align-items-center">
                    <div class="bar-T m-b-10 align-items-center">
                      <div class="d-flex align-items-center"
                        *ngIf="config.currentUser?.pin!=='USR'||view.state === 'new'||ai.view.value('projectOwner')?.id === config.currentUser?.id">

                        <div class="m-x-auto">
                          <div class="ib-m">
                            <div class="col-xr-12" *ngIf="!showUserToProject">
                              <button type="button" (click)="setShowUserToProject()" mat-stroked-button
                                class="teambutton" *ngIf="isPrjAdmin">+ Invite Team Members</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="button" matRipple (click)="ai.insightGenerator()" [disabled]="ai.flSelected==199"
                        class="m-b-10 btn-cancel btn btn-primary btn-xl">Update Insights</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <mat-tab-group #matTabs mat-align-tabs="center" *ngIf="ai.view.value('mode') == 'Turbo Mode'"
              [(selectedIndex)]="ai.activeTabIndex" class="transparentTabs" [ngClass]="{
              aiChatTab: ai.getCurrentTab() === 0
            }" (selectedTabChange)="onTabChange($event, newMessageFC)">
              <mat-tab label="Analysis and Report">
                <div class="scrollViewer" #aiChatScrollViewer>
                  <form class="card aiChat" [formGroup]="ai.newMessageForm" (ngSubmit)="ai.sendMessage()">
                    <!-- <div class="cardHeader"></div> -->
                    <div class="cardContent">
                      <div class="aiContent">
                        <ul class="messages">
                          <li *ngFor="let message of ai.messages; index as i;"
                            [attr.data-type]="(i+1)%2 === 0 ? 'ai' : message.user.name[0]" [ngClass]="{
                              hasPhoto: message.user.icon
                            }">
                            <div class="avatar" *ngIf="message.user.icon">
                              <img [src]="ai.getCropped()" [alt]="message.user.name[0]">
                            </div>
                            <div class="messageWrap">
                              <span class="userInfo">
                                <div class="username">{{ message.user.name }}</div>
                              </span>
                              <div class="message" [innerHTML]="message.content"></div>
                              <div class="action" *ngIf="message.content && (i+1)%2 === 0&&ai.projectOwnerCheck()">
                                <button class="btn btn-light" type="button" matRipple (click)="ai.saveChat(message)"
                                  [disabled]="message.saved==1">
                                  <i class="i i-save"></i>
                                  <span>{{message.saved==1?'Saved':'Save'}}</span>
                                </button>
                                <button class="btn btn-light" type="button" matRipple
                                  (click)="ai.copyText(ai.htmlToListText(message.content))">
                                  <i class="i i-copy"></i>
                                  <span>Copy</span>
                                </button>
                                <button class="btn btn-light m-l-auto" type="button" matRipple
                                  (click)="ai.toggleSource(message)">
                                  <i class="i" [ngClass]="{
                                    'i-source_on': message.isSelected || (ai.smartlist?.chat === message.chatId),
                                    'i-source_off': !message.isSelected && ai.smartlist?.chat !== message.chatId
                                  }"></i>
                                  <span>View Sources</span>
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>

                        <div *ngIf="ai.showSuggestions" class="suggestions">
                          <h4 class="title">More on this:</h4>
                          <ul>
                            <li *ngFor="let question of ai.suggestedQuestions" (click)="ai.setPromptText(question)">
                              <a href="javascript:;">{{ question }}</a>
                            </li>
                          </ul>
                        </div>

                        <div class="typingLoader" *ngIf="ai.isTyping">
                          <div class="content">
                            <figure>
                              <img src="./assets/images/typing-loader.gif" alt="Typing...">
                            </figure>
                            <div class="text">{{ai.chatLoaderText}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="cardFooter">
                      <div class="aiFooter">
                        <div class="messenger" *ngIf="ai.projectOwnerCheck()">
                          <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                            <textarea matInput pInputTextarea formControlName="newMessage"
                              placeholder="Enter your message here" appAutoFocus></textarea>
                          </mat-form-field>

                          <button class="btn btn-primary btn-link" type="submit"
                            [disabled]="!ai.newMessageForm.get('newMessage').value.trim()||ai.isTyping">
                            <i class="i i-send"></i>
                          </button>
                        </div>

                        <!-- Generate Report button -->
                        <!-- <div class="reportGen justify-content-center" *ngIf="ai?.messages?.length!=0">
                        </div> -->
                      </div>
                    </div>
                  </form>

                  <app-scroll-nav data-attach="relative" [scrollContainer]="aiChatScrollViewer" [scrollThreshold]="0.5"
                    [scrollOffsetTop]="400" [scrollOffsetBottom]="10"></app-scroll-nav>
                </div>

              </mat-tab>
              <mat-tab label="Data Visualization">
                <div class="col-sm-24 t-l p-y-10 p-x-5" *ngIf="!ai.dashboard && ai.tagcloudclick && !ai.showWish">
                  <mat-card class="h-50px p-a-10" color="accent">
                    <h1 class="smartTitle h4 text-accent">
                      <button type="button" matRipple class="close ib-m p-x-0 btn btn-primary btn-link"
                        (click)="ai.showDashboard();ai.clearMenu();" title="Back to Dashboard"
                        matTooltipPosition="below">
                        <i fontSet="i" class="ib-m i i-4x" style="line-height: 0; margin-top: -8px;">back</i>
                      </button>
                      <i class="i i-filter-o ib-m"></i>
                      <span class="ib-m">
                        <small class="ib-m">{{ai.smartTitle.split(':')[0]}}:</small>
                        <b class="ib-m text-primary p-x-5 m-l-5"
                          style="border-radius: 5px;">{{ai.smartTitle.split(':')[1]}}</b>
                      </span>
                    </h1>
                  </mat-card>
                </div>

                <div class="t-c" *ngIf="(!ai.smartlistItem)">
                  <div class="emoji _sq m-x-auto very_dissatisfied" aria-label="Your list is empty!"></div>
                  <h4 class="h3 text-accent m-t-15 m-b-0">{{ai.turboSourceMsg}}</h4>
                </div>

                <div class="aiDashboard p-a-0" [ngClass]="{'d-none': ai.dashboard !== true}">
                  <div class="queryQuestion" *ngIf="(ai.sourceQuerry||ai.getsourceQuerry())&&(ai.smartlistItem)">
                    {{ai.getsourceQuerry()?ai.getsourceQuerry():ai.sourceQuerry}}</div>
                  <div class="mg mg-2">
                    <ng-container *ngFor="let row of ai?.dashCharts; index as i;">
                      <!-- Insight 0 -->
                      <div class="mg-brick">
                        <mat-card class="m-b-20">
                          <div class="d-flex m-b-10 b-b-1">
                            <mat-card-header>
                              <div mat-card-avatar class="b-a-1 t-c"><i class="i i-product_box i-2_5x text-primary"></i>
                              </div>
                              <mat-card-title>{{ai.catName(row.aiTypeID,view)}}</mat-card-title>
                              <mat-card-subtitle>{{ai.charttitle}}</mat-card-subtitle>
                            </mat-card-header>
                            <div class="m-l-auto d-flex align-items-center justify-content-start">
                              <div class="b-r-1 p-r-5 m-r-10 ib-m">
                                <button type="button" class="btn btn-primary btn-md lighten m-x-5 togglerIcon"
                                  *ngIf="ai.projectOwnerCheck()"
                                  (click)="aiv.setChartOptions('can', row.aiTypeID,row.aiTypeID,row.aiTypeID,row,i)"
                                  title="{{row.reportId!==0?' Remove':'Add to Report'}}" [ngClass]="{
                                    active: row.reportId!==0
                                  }">
                                  <i class="i i-ai_report i-1_7x"></i>
                                  <div class="stat">
                                    <span>{{row.reportId!==0?' Remove':'Add to Report'}}</span>
                                  </div>
                                </button>
                              </div>
                              <button class="btn mat-button-base mdlbtn infobtn" title="How to use the Graphs"
                                (click)="ai.helpInfo('Similar Products or Projects')">
                                <img src="./assets/images/infoGif.gif">
                              </button>
                            </div>
                          </div>

                          <mat-card-content>
                            <div class="row h-100">
                              <div class="graph" style="min-height: 260px;">
                                <canvas id="can{{row.aiTypeID}}" baseChart style="cursor:pointer"
                                  [datasets]="row.chartData" [labels]="row.chartLabel" [options]="ai.stackChartOptions"
                                  [plugins]="ai.stackChartPlugins" [legend]="ai.stackChartLegend"
                                  (chartClick)="ai.onChartClick($event,row.aiTypeID, view)"
                                  [chartType]="ai.stackChartType">
                                </canvas>

                              </div>
                              <!-- piechart1 -->
                              <div class="b-t-1">
                                <lib-tag-cloud [data]="row.tagCloud"
                                  (link)="ai.tagManager($event,row.aiTypeID)"></lib-tag-cloud>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <ng-container *ngTemplateOutlet="insightTemplate"></ng-container>
              </mat-tab>
              <mat-tab label="Sources">

                <ng-container *ngTemplateOutlet="insightTemplate"></ng-container>

                <div class="t-c" *ngIf="(!ai.smartlistItem)&&ai.view.value('mode') == 'Turbo Mode'">
                  <div class="emoji _sq m-x-auto very_dissatisfied" aria-label="Your list is empty!"></div>
                  <h4 class="h3 text-accent m-t-15 m-b-0">{{ai.turboSourceMsg}}</h4>
                </div>
              </mat-tab>
            </mat-tab-group>

            <mat-card class="m-b-20" *ngIf="ai.reportviewflag&&ai.getsummary()">
              <div class="d-flex m-b-20 p-b-10 b-b-1 align-items-start justify-content-center">
                <mat-card-header>
                  <mat-card-title class="m-b-0">Insights Analysis</mat-card-title>
                </mat-card-header>
              </div>

              <mat-card-content>
                <div class="row">
                  <!-- Pie Chart -->
                  <div class="col-xl-24">
                    <div class="aiTextWrap insightSummary" [innerHTML]="ai.getsummary()"></div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <!-- <div class="t-c" *ngIf="(!ai.smartlistItem)&&(ai.view.value('mode') != 'Turbo Mode')">
              <div class="emoji _sq m-x-auto very_dissatisfied" aria-label="Your list is empty!"></div>
              <h4 class="h1 text-accent m-t-15 m-b-0">Your list is empty!</h4>
            </div> -->

            <div *ngIf="!ai.notesadd">
              <div class="t-c p-y-30"
                *ngIf="ai.showWish && ((ai.hasRecord != 'none' && ai.hasRecord == 'false')||ai.wishListAll=='')&&!ai.InsIQclickflg&&!ai.sentimentclickflg&&!ai.averagePrice&&!ai.partnerclickflg&&!ai.chartReports">

                <div class="t-c"
                  *ngIf="!(ai.hasRecord=='true' && ai.dashboard !== true && ai.smartlist && !ai.InsIQclickflg&&!ai.chartReports &&!ai.sentimentclickflg&&!ai.averagePrice&& !ai.partnerclickflg)&&ai.view.value('mode') == 'Research Mode'">
                  <div class="emoji _sq m-x-auto very_dissatisfied" aria-label="Your list is empty!"></div>
                  <h4 class="h1 text-accent m-t-15 m-b-0">Your list is empty!</h4>
                </div>

              </div>
              <div class="t-c p-y-30"
                *ngIf="(ai.wishListAll==''&&ai.notesviewflag)||(ai.reportviewflag&&(!ai.chartReports&&(ai.smartreportlist?.length===0||ai.smartreportlist===''||!ai.smartreportlist))&&!ai.getsummary())&&ai.view.value('mode') == 'Research Mode'">
                <img src="./assets/images/norecordfound.png" alt="Your Favourite list is empty!!">
                <h1 class="h1 text-accent m-t-15 m-b-0">Your list is empty!!</h1>
              </div>

              <div class="t-c p-y-30"
                *ngIf="!ai.getConfigLoad()&&!ai.trendchart&&ai.dashboard&&(ai.cacheClear||!ai.smartlist)&&ai.view.value('mode') == 'Research Mode'">
                <img src="./assets/images/norecordfound.png" alt="Your list is empty!">
                <h4 class="h1 text-accent m-t-15 m-b-0">Your list is empty!</h4>
              </div>
              <div class="t-c p-y-30"
                *ngIf="ai.insightsearch&&ai.wishListAll==null&&ai.view.value('mode') == 'Research Mode'">
                <img class="w-200px" src="./assets/images/searchinsights.png" alt="Search Keywords!!!">
                <h1 class="h1 text-accent m-t-30 m-b-0">Search Using Keywords to get related Insights!</h1>
              </div>

              <div class="col-sm-24 t-l p-y-10 p-x-5"
                *ngIf="!ai.dashboard && ai.tagcloudclick && !ai.showWish && ai.view.value('mode') != 'Turbo Mode'">
                <mat-card class="h-50px p-a-10" color="accent">
                  <h1 class="smartTitle h4 text-accent">
                    <button type="button" matRipple class="close ib-m p-x-0 btn btn-primary btn-link"
                      (click)="ai.showDashboard();ai.clearMenu();" title="Back to Dashboard" matTooltipPosition="below">
                      <i fontSet="i" class="ib-m i i-4x" style="line-height: 0; margin-top: -8px;">back</i>
                    </button>
                    <i class="i i-filter-o ib-m"></i>
                    <span class="ib-m">
                      <small class="ib-m">{{ai.smartTitle.split(':')[0]}}:</small>
                      <b class="ib-m text-primary p-x-5 m-l-5"
                        style="border-radius: 5px;">{{ai.smartTitle.split(':')[1]}}</b>
                    </span>
                  </h1>
                </mat-card>
              </div>
              <div class="t-c p-y-30" *ngIf="ai.sentimentclickflg&&!ai.sentimentscore">
                <img src="./assets/images/norecordfound.png" alt="Your list is empty!">
                <h4 class="h1 text-accent m-t-15 m-b-0">Your list is empty!</h4>
              </div>
              <div class="col-sm-24 m-y-20" *ngIf="ai.sentimentclickflg&&ai.sentimentscore">
                <mat-card [class.mat-elevation-z24]="true" class="headerCard">
                  <mat-card-header>
                    <div mat-card-avatar class="b-a-1 t-c">
                      <i class="i i-{{ai.getSentimnent(ai.sentimentSelected.Mode)}} i-2_5x text-primary"></i>
                    </div>
                    <mat-card-title>Sentiment</mat-card-title>
                    <mat-card-subtitle>{{ai.getSubTitle()}}</mat-card-subtitle>
                  </mat-card-header>
                </mat-card>

                <div class="align-items-center m-t-20">
                  <div class="bar-T m-b-10 align-items-center justify-content-center">
                    <button type="button" matRipple (click)="ai.insightSummary(view)"
                      class="btn btn-primary btn-lg lighten justify-content-center">Insights Analysis</button>
                  </div>
                </div>
                <div class="row p-x-10">
                  <div class="col-xl-14 m-b-20">
                    <mat-card class="m-b-20">
                      <div class="d-flex m-b-20 p-b-10 b-b-1 align-items-start">
                        <mat-card-header>
                          <mat-card-title class="m-b-0">Analysis</mat-card-title>
                        </mat-card-header>
                        <div class="m-l-auto d-flex align-items-center" *ngIf="ai.spieChartData">
                          <div class="ib-m">
                            <button type="button" class="btn btn-primary btn-md lighten m-x-5 togglerIcon"
                              *ngIf="ai.sentimentSelected?.reportId!==''"
                              (click)="ai.addChartNotes( ai.sentimentSelected?.reportId)" [ngClass]="{
                                active:  ai.sentimentSelected?.note!==''
                              }" title="{{ai.sentimentSelected?.note!=='' ?'Edit Note':'Add Note'}}">
                              <i class="i i-notes i-1_7x"></i>
                              <div class="stat">
                                {{ai.sentimentSelected?.note!=='' ?'Edit Note':'Add Note'}}
                              </div>
                            </button>
                            <button type="button" [ngClass]="{
                                active: ai.sentimentSelected?.reportId!==''
                              }" class="btn btn-primary btn-md lighten m-x-5 togglerIcon"
                              (click)="aiv.setChartOptions(c99,99,ai.sentimentSelected.Mode,ai.sentimentSelected.keyvalue,null)"
                              title="{{ai.sentimentSelected?.reportId!=='' ? ' Remove':'Add to Report'}}">
                              <i class="i i-ai_report i-1_7x"></i>
                              <div class="stat">
                                <i class="i" [ngClass]="{
                                  'i-add': ai.sentimentSelected?.reportId==='',
                                  'i-check': ai.sentimentSelected?.reportId!==''
                                }"></i>
                                {{ai.sentimentSelected?.reportId!=='' ?' Remove':'Add to Report'}}
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>

                      <mat-card-content>
                        <div class="row">
                          <!-- Pie Chart -->
                          <div class="col-xl-24" style="min-height: 360px;">
                            <canvas #c99 baseChart style="cursor:pointer" [data]="ai.spieChartData"
                              [labels]="ai.spieChartLabels" [chartType]="ai.spieChartType"
                              [options]="ai.spieChartOptions" (chartClick)="ai.sentimentDetail($event)"
                              [plugins]="ai.spieChartPlugins" [legend]="ai.spieChartLegend"
                              [colors]="ai.pieChartColors2">
                            </canvas>
                          </div>

                          <!-- EMOJI -->
                          <div class="col-xl-24">
                            <hr class="o-50">
                            <div class="d-flex justify-content-center align-items-center">
                              <figure *ngFor="let emoji of emojis" class="t-c emojiGroup"
                                [attr.data-current]="ai.getScoreEmoji(ai.sentimentscore, true)" [ngClass]="{
                                disabled: emoji.key !== ai.getScoreEmoji(ai.sentimentscore, true)
                              }">
                                <figcaption class="f-bold m-y-10">{{emoji.value | titlecase}}</figcaption>
                                <div class="emoji _sq m-x-auto {{emoji.key}}" [ngClass]="{
                                disabled: emoji.key !== ai.getScoreEmoji(ai.sentimentscore, true)
                              }"></div>
                              </figure>
                            </div>


                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>

                    <mat-card class="m-b-20">
                      <div class="d-flex m-b-20 p-b-10 b-b-1 align-items-start">
                        <mat-card-header>

                          <mat-card-title class="m-b-0">Trends</mat-card-title>
                        </mat-card-header>
                        <div class="m-l-auto">
                          <div class="ib-m">
                            <button type="button" matRipple class="btn btn-primary btn-md lighten m-x-5 togglerIcon"
                              *ngIf="ai.sentimentSelected?.trendreportId!==''"
                              (click)="ai.addChartNotes( ai.sentimentSelected?.trendreportId,false,991)" [ngClass]="{
                              active:  ai.sentimentSelected?.trendnote!==''
                            }" title="{{ai.sentimentSelected?.trendnote!=='' ? 'Edit Note':'Add Note'}}">
                              <i class="i i-notes i-1_7x"></i>
                              <div class="stat">

                                {{ai.sentimentSelected?.trendnote!=='' ? 'Edit Note':'Add Note'}}
                              </div>
                            </button>
                            <button type="button" matRipple class="btn btn-primary btn-md lighten m-x-5 togglerIcon"
                              [ngClass]="{
                              active: ai.sentimentSelected?.trendreportId!==''
                            }"
                              (click)="aiv.setChartOptions(c991,991,ai.sentimentSelected.Mode,ai.sentimentSelected.keyvalue,null)"
                              title="{{ai.sentimentSelected?.trendreportId!=='' ?' Remove':'Add to Report'}}">
                              <i class="i i-ai_report i-1_7x"></i>
                              <div class="stat">

                                {{ai.sentimentSelected?.trendreportId!=='' ?' Remove':'Add to Report'}}
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>

                      <mat-card-content>
                        <div class="t-c">
                          <ul class="legend">
                            <li class="positive">Positive</li>
                            <li class="neutral">Neutral</li>
                            <li class="negative">Negative</li>
                          </ul>
                        </div>
                        <div style="min-height: 360px;">
                          <canvas #c991 baseChart [datasets]="[{
                          axis: 'y',
                          fill: true,
                          borderWidth: 3,
                          pointHoverRadius: 30,
                          data: ai.sentimentTrendChartData,
                          borderColor: '#0c448b',
                          pointHoverBorderWidth: 2,
                          pointBorderColor: '#0c448b',
                          pointBackgroundColor: '#0c448b',
                          backgroundColor: 'rgba(12, 68, 139, 0.24)',
                          pointHoverBorderColor: 'rgba(12, 68, 139, 0.16)',
                          pointHoverBackgroundColor: 'rgba(12, 68, 139, 0.36)'
                        }]" [color]="['blue']" [labels]="ai.sentimentTrendChartLabels" [chartType]="ai.trendChartType"
                            [options]="ai.sentimentTrendChartOptions" [legend]="ai.sentimentTrendChartLegend">
                          </canvas>

                          <div class="t-c f-bold m-t-10">{{ai.getSubTitle()}}</div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>

                  <div class="col-xl-10">
                    <!-- TAG CLOUD -->
                    <mat-card [class.mat-elevation-z24]="true" class="dtlCard">
                      <div class="d-flex m-b-20 p-b-10 b-b-1 align-items-start">
                        <mat-card-header>

                          <mat-card-title class="m-b-0">Word Cloud</mat-card-title>
                        </mat-card-header>
                      </div>
                      <mat-card-content>
                        <div class="row h-100">
                          <div class="col-sm-24">
                            <lib-tag-cloud [type]="'chips'" [data]="ai.sentimentTag"
                              (link)="ai.sentimenttagManager($event)">
                            </lib-tag-cloud>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>

              </div>
              <div class="col-sm-24 m-y-20" *ngIf="ai.averagePrice&&ai?.averagePriceRes">
                <mat-card [class.mat-elevation-z24]="true" class="headerCard">
                  <mat-card-header>
                    <div mat-card-avatar class="b-a-1 t-c">
                      <i class="i i-price_tag i-2_5x text-primary"></i>
                    </div>
                    <mat-card-title>Pricing Insights</mat-card-title>
                    <mat-card-subtitle>{{ai.averagePriceRes?.QueryUsed}}</mat-card-subtitle>
                  </mat-card-header>
                  <div class="col-sm-24 t-l p-y-10 p-x-5" *ngIf="!ai.priceCloud">
                    <mat-card class="h-50px p-a-10" color="accent">

                      <h1 class="smartTitle h4 text-accent">
                        <button type="button" mat-button class="close ib-m" (click)="ai.avgclose()" title="Close"
                          matTooltipPosition="below">
                          <i fontSet="i" class="ib-m i i-3x">back</i>
                        </button>
                        <i class="i i-filter-o ib-m"></i>
                        <span class="ib-m">
                          <small class="ib-m">Insights filtered by:</small>
                          <b class="ib-m text-primary p-x-5 m-l-5" style="border-radius: 5px;">{{ai.priceFilter}}</b>
                        </span>
                      </h1>
                    </mat-card>
                  </div>
                  <mat-card [class.mat-elevation-z24]="true" class="dtlCard"
                    *ngIf="ai?.ptagCloud?.length!=0&&ai?.priceCloud&&ai.averagePriceRes?.sResponse?.length!=0">
                    <div class="d-flex m-b-20 p-b-10 b-b-1 align-items-start">
                      <mat-card-header>

                        <mat-card-title class="m-b-0">Word Cloud</mat-card-title>
                      </mat-card-header>
                    </div>
                    <mat-card-content>
                      <div class="row h-100">
                        <div class="col-sm-24">

                          <lib-tag-cloud [data]="ai.ptagCloud" (link)="ai.ptagManager($event)">
                          </lib-tag-cloud>

                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                  <div class="align-items-center m-t-20" *ngIf="ai?.averagePriceRes?.sResponse?.length!=0">
                    <div class="bar-T m-b-10 align-items-center justify-content-center">

                      <button type="button" matRipple (click)="ai.insightSummary(view)"
                        class="btn btn-primary btn-lg lighten justify-content-center">Insights Analysis</button>

                    </div>

                  </div>
                  <div class="d-flex align-items-center justify-content-end"
                    *ngIf="ai?.averagePriceRes?.sResponse?.length!=0">
                    <button type="button" matRipple (click)="ai.toggleView();toggleGrid();ai.hidePaginator = true;"
                      mat-mini-fab class="ib-m m-x-5 m-b-20"
                      title="{{ai.listViewInsight?'Switch to Grid View':'Switch to List View'}}">
                      <i *ngIf="ai.listViewInsight" class="i i-grid"></i>
                      <i *ngIf="!ai.listViewInsight" class="i i-list"></i>
                    </button>

                    <button type="button" title="Export Excel" matRipple (click)=" ai.exportToExcel()" mat-mini-fab
                      class="ib-m m-x-5 m-b-20">
                      <i class="i i-files"></i>

                    </button>
                  </div>



                  <div class="t-c p-y-30" *ngIf="ai?.averagePriceRes?.sResponse?.length==0">
                    <img src="./assets/images/norecordfound.png" alt="Your list is empty!">
                    <h1 class="h1 text-accent m-t-30 m-b-0">No Insights found!!!</h1>
                  </div>
                  <mat-card class="tableCard" *ngIf="ai.listViewInsight&&ai?.averagePriceRes?.sResponse?.length!=0">

                    <p-table #yourTable [value]="ai?.averagePriceRes?.sResponse">
                      <ng-template pTemplate="header">
                        <tr>
                          <th *ngFor="let col of pricingCols">{{ col.header }}</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-row>
                        <tr>
                          <td>
                            <button mat-icon-button [matMenuTriggerFor]="menu"
                              aria-label="Example icon-button with a menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="aiDroppWrap">
                              <div class="menuInner">
                                <div class="topBtn">
                                  <button mat-menu-item type="button" [matMenuTriggerFor]="deepDiveMenu" matRipple
                                    *ngIf="!ai.desiagnApiCall" matTooltipClass="md-fs"
                                    title="Deep dive for related insights" matTooltipPosition="below"
                                    class="btn btn-primary btn-lg t-c ">
                                    Deep dive for related insights
                                  </button>
                                </div>
                                <div class="lineBtm">
                                  <button mat-menu-item type="button" class="btn btn-link leftBtn"
                                    (click)="ai.likeOrUnlike(row, 1)"
                                    title="{{row.liked?'Remove Favorite':'Mark as Favorite'}}"
                                    *ngIf="(!ai.showWish||ai.inddeep) && (!row.liked || !(row.liked?.likeon == '0'))"
                                    [ngClass]="{
                                      active: row.liked
                                    }">
                                    <i class="i i-favourite"></i> {{row.liked?'Remove favorite':'Mark as favorite'}}
                                  </button>
                                  <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
                                      active: row.report == '1'
                                    }" title="{{row?.report == '1'?' Remove':'Add to Report'}}"
                                    (click)="ai.addremoveReport(row)">
                                    <i class="i i-ai_report"></i>
                                    {{row?.report == '1'?' Remove from report':'Add to Report'}}
                                  </button>
                                  <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
                                      active: row?.notesId!==null
                                    }" title="{{row?.notesId!==null?trimtooltip(row.noteAdd):'Add Notes'}}"
                                    (click)="ai.addOrEditNotes(row)">
                                    <i class="i i-notes"></i> {{row?.notesId!==null?'Edit Notes':'Add Notes'}}
                                  </button>
                                  <button mat-menu-item type="button" class="btn btn-link leftBtn"
                                    (click)="ai.copyText(row.aiLink)" title="Copy" aria-label="Copy">
                                    <i class="i i-copy"></i>Copy
                                  </button>

                                  <button mat-menu-item type="button" class="btn btn-link leftBtn" title="Share Insight"
                                    [matMenuTriggerFor]="shareMenu">
                                    <i class="i i-share"></i>Share Insight
                                  </button>
                                </div>
                              </div>
                            </mat-menu>

                            <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">

                              <h2 class="subTitle">Market Intelligence</h2>
                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'31')">{{ai.catName('31',
                                view)}}</button>
                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'6')">{{ai.catName('6',
                                view)}}</button>
                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'10')">{{ai.catName('10',
                                view)}}</button>


                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'2')">{{ai.catName('2',
                                view)}}</button>
                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'32')">{{ai.catName('32',
                                view)}}</button>
                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'8')">
                                {{ai.catName('8', view)}}</button>
                              <h2 class="subTitle">Product R&D Intelligence</h2>
                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'11')">{{ai.catName('11',
                                view)}}</button>
                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'7')">{{ai.catName('7',
                                view)}}</button>

                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'5')">{{ai.catName('5',
                                view)}}</button>




                              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'4')">{{ai.catName('4',
                                view)}}</button>


                              <h2 *ngIf="ai.featureChecker(18)" class="subTitle">Business Intelligence</h2>

                              <button *ngIf="ai.featureChecker(18)" mat-menu-item
                                (click)="ai.insightDeep(row.aiId,'9')">{{ai.catName('9', view)}}</button>
                            </mat-menu>
                            <mat-menu #shareMenu="matMenu" xPosition="after" yPosition="above" [overlapTrigger]="false">
                              <ng-template matMenuContent>
                                <button type="button" mat-icon-button color="accent"
                                  (click)="linkedinshre($event,row.aiLink,row.aiTitle)" title="LinkedIn Share"
                                  [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                                  <i class="i i-linkedin"></i>
                                </button>
                                <button type="button" mat-icon-button color="accent"
                                  (click)="twittershare($event,row.aiLink)" title="Twitter Share"
                                  [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                                  <i class="i i-twitter"></i>
                                </button>
                                <button type="button" mat-icon-button color="accent"
                                  (click)="fbshare($event,row.aiLink)" [matTooltipClass]="'md-fs'"
                                  title="Facebook Share" matTooltipPosition="below">
                                  <i class="i i-facebook"></i>
                                </button>
                                <button type="button" mat-icon-button color="accent"
                                  (click)="watzappshare($event,row.aiLink,row.aiTitle)" title="Whatsapp Share"
                                  [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                                  <i class="i i-whatsapp"></i>
                                </button>
                                <button type="button" mat-icon-button color="accent"
                                  *ngIf="ai.msstatus&&ai.mschannelid!= null"
                                  (click)="ai.teamshare(row.aiNameTemp,row.aiLink,row.aiTitle,row.aiText)"
                                  title="Post in MS Teams Channel" [matTooltipClass]="'md-fs'"
                                  matTooltipPosition="below">
                                  <i class="i i-ms-team"></i>
                                </button>
                              </ng-template>
                            </mat-menu>
                          </td>
                          <td>

                            <a [innerHTML]="ai.titleManager(row.aiName)" (click)="openLinkInNewTab(row.aiLink)"></a>
                          </td>
                          <td class="w-10">

                            <div class="priceCard sm outline-secondary my-10" *ngIf="row.aiPrice">
                              <mat-icon fontSet="i" class="i-price_tag ib-m"></mat-icon>
                              <span>{{row.aiPrice}}</span>
                              <small>{{ai.averagePriceRes.Currency}}</small>
                            </div>
                          </td>

                          <td>

                            <div class="showTooltip">
                              <ng-container>

                                <div [innerHTML]="ai.aiTexttrim(splitTextPricing(row.aiText).description)"></div>
                              </ng-container>

                              <div class="overlayTooltip"
                                *ngIf="ai.aiTexttrim(splitTextPricing(row.aiText)?.description)!==splitTextPricing(row.aiText).description">
                                <div [innerHTML]="splitTextPricing(row.aiText).description"></div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="showTooltip">
                              <ng-container>

                                <div [innerHTML]="truncateText(splitTextPricing(row.aiText)?.keywords)"></div>
                              </ng-container>

                              <div class="overlayTooltip"
                                *ngIf="splitTextPricing(row.aiText)?.keywords!==truncateText(splitTextPricing(row.aiText)?.keywords)">
                                <div [innerHTML]="splitTextPricing(row.aiText)?.keywords"></div>
                              </div>
                            </div>
                          </td>
                          <td>{{row.aiDate}}</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </mat-card>
                  <div class="mg p-a-5" *ngIf="!ai.listViewInsight">
                    <div class="mg-brick" *ngFor="let row of ai?.averagePriceRes?.sResponse">
                      <mat-card class="aiCard privilaged {{getborder(row.aiDeepDive)}}"
                        [ngClass]="'c-' + getClass(row.aiNameIdTemp === undefined ?ai.catId(row.aiName) : row.aiNameIdTemp,row.aiTitle)"
                        [class.mat-elevation-z8]="true">

                        <mat-card-actions class="b-t-1">
                          <ul class="buttonWrap">
                            <li>
                              <button type="button" mat-icon-button title="Remove From Favorite"
                                [matTooltipClass]="'md-fs'" (click)="ai.removefav(row)" matTooltipPosition="below"
                                *ngIf="ai.showWish&&!ai.inddeep">
                                <mat-icon class="i-1_7x" color="primary" fontIcon="i-favourite"></mat-icon>
                              </button>
                            </li>
                            <li>
                              <button type="button" mat-icon-button [matTooltipClass]="'md-fs'"
                                title="{{row.liked?'Remove Favorite':'Mark as Favorite'}}" matTooltipPosition="below"
                                *ngIf="(!ai.showWish||ai.inddeep) && (!row.liked || !(row.liked?.likeon == '0'))"
                                (click)="ai.likeOrUnlike(row, 1)" [ngClass]="{
                                active: row.liked
                              }">
                                <mat-icon class="i-2x" fontIcon="i-favourite"></mat-icon>
                              </button>
                            </li>
                            <li>
                              <button type="button" mat-icon-button
                                title="{{row?.report == '1'?' Remove':'Add to Report'}}" matTooltipPosition="below"
                                [matTooltipClass]="'md-fs'" (click)="ai.addremoveReport(row)" [ngClass]="{
                                active: row.report == '1'
                              }">
                                <mat-icon class="i-ai_report i-2x"></mat-icon>
                              </button>
                            </li>

                            <li>
                              <button type="button" mat-icon-button (click)="ai.addOrEditNotes(row)"
                                [matTooltipClass]="'md-fs'" matTooltipPosition="below"
                                [title]="row?.notesId!==null?trimtooltip(row.noteAdd):'Add Notes'" [ngClass]="{
                                active: row?.notesId!==null
                              }">
                                <mat-icon class="i-1_7x i-notes"></mat-icon>

                              </button>
                            </li>


                            <li>
                              <button type="button" mat-icon-button *ngIf="row.aiLink !== ''"
                                (click)="ai.copyText(row.aiLink)" title="Copy" matTooltipPosition="below"
                                [matTooltipClass]="'md-fs'">
                                <mat-icon class="i-1_7x">content_copy</mat-icon>
                              </button>
                            </li>

                            <li>
                              <button type="button" mat-icon-button title="Share Insight" [matTooltipClass]="'md-fs'"
                                [matMenuTriggerFor]="shareMenu">
                                <mat-icon class="i-1_7x">share</mat-icon>
                              </button>
                              <mat-menu #shareMenu="matMenu" xPosition="before" yPosition="above"
                                [overlapTrigger]="false">
                                <ng-template matMenuContent>
                                  <!-- <h2 class="subTitle">Heading 4</h2> -->
                                  <button type="button" mat-icon-button color="accent"
                                    (click)="linkedinshre($event,row.aiLink,row.aiTitle)" title="LinkedIn Share"
                                    [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                                    <i class="i i-linkedin"></i>
                                  </button>
                                  <button type="button" mat-icon-button color="accent"
                                    (click)="twittershare($event,row.aiLink)" title="Twitter Share"
                                    [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                                    <i class="i i-twitter"></i>
                                  </button>
                                  <button type="button" mat-icon-button color="accent"
                                    (click)="fbshare($event,row.aiLink)" [matTooltipClass]="'md-fs'"
                                    title="Facebook Share" matTooltipPosition="below">
                                    <i class="i i-facebook"></i>
                                  </button>
                                  <button type="button" mat-icon-button color="accent"
                                    (click)="watzappshare($event,row.aiLink,row.aiTitle)" title="Whatsapp Share"
                                    [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                                    <i class="i i-whatsapp"></i>
                                  </button>
                                  <button type="button" mat-icon-button color="accent"
                                    *ngIf="ai.msstatus&&ai.mschannelid!= null"
                                    (click)="ai.teamshare(row.aiNameTemp,row.aiLink,row.aiTitle,row.aiText)"
                                    title="Post in MS Teams Channel" [matTooltipClass]="'md-fs'"
                                    matTooltipPosition="below">
                                    <i class="i i-ms-team"></i>
                                  </button>
                                </ng-template>
                              </mat-menu>
                            </li>

                          </ul>
                        </mat-card-actions>
                        <div class="badgelabel">
                          <span>Pricing Insights</span>
                        </div>
                        <mat-card-content>
                          <div class="w-100">
                            <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="row.aiDate">

                              <i class="i i-calendar i-1_5x ib-m"></i>
                              <span class="ib-m">{{row.aiDate}}</span>
                            </div>
                          </div>

                          <div class="w-100">
                            <div class="m-b-5" [innerHTML]="ai.titleManager(row.aiName)"></div>

                            <div class="priceCard sm outline-secondary my-10" *ngIf="row.aiPrice">
                              <mat-icon fontSet="i" class="i-price_tag ib-m"></mat-icon>
                              <span>{{row.aiPrice}}</span>
                              <small>{{ai.averagePriceRes.Currency}}</small>
                            </div>

                            <a [href]="row.aiLink" target="_blank">
                              <div class="text-accent" title="Open in New Tab" [matTooltipClass]="'md-fs'"
                                matTooltipPosition="below">{{row.aiLink}}</div>
                            </a>
                            <div class="m-t-5" [innerHTML]="ai.aiTexttrim(row.aiText)"></div>
                          </div>

                          <div *ngIf="row?.notesId!==null&&ai.notesviewflag" tabindex="0"
                            (click)="ai.addOrEditNotes(row)" [matTooltipClass]="'md-fs'" title="Click to Add/Edit Note"
                            matTooltipPosition="below" class="notes">
                            {{row.noteAdd}}
                          </div>
                        </mat-card-content>
                        <div class="btnWrap">
                          <button type="button" matripple class="btn btn-primary btn-lg t-c"
                            [matMenuTriggerFor]="deepDiveMenu" matTooltipClass="md-fs"
                            title="Deep dive for related insights" matTooltipPosition="below">
                            Deep dive for related insights
                          </button>
                          <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">
                            <h2 class="subTitle">Market Intelligence</h2>
                            <button mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'31')">{{ai.catName('31',view)}}</button>
                            <button mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'6')">{{ai.catName('6',view)}}</button>
                            <button mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'10')">{{ai.catName('10',view)}}</button>
                            <button mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'2')">{{ai.catName('2',view)}}</button>
                            <button mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'32')">{{ai.catName('32',view)}}</button>
                            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'8')">
                              {{ai.catName('8',view)}}</button>
                            <h2 class="subTitle">Product R&D Intelligence</h2>
                            <button mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'11')">{{ai.catName('11',view)}}</button>
                            <button mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'7')">{{ai.catName('7',view)}}</button>
                            <button mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'5')">{{ai.catName('5',view)}}</button>
                            <button mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'4')">{{ai.catName('4',view)}}</button>
                            <h2 *ngIf="ai.featureChecker(18)" class="subTitle">Business Intelligence</h2>
                            <button *ngIf="ai.featureChecker(18)" mat-menu-item
                              (click)="ai.insightDeep(row.aiId,'9')">{{ai.catName('9',view)}}</button>
                          </mat-menu>
                        </div>
                        <!-- code CUTTT -->

                      </mat-card>
                    </div>
                  </div>
                </mat-card>
              </div>


              <div *ngIf="ai.chartReports && ai.chartreportinsights!=''" class="dtlCard m-x-20 m-y-30">
                <!-- Insight 0 -->

                <div class="col-sm-24  m-y-20" *ngFor="let row of ai?.chartreportinsights; index as i;">
                  <mat-card class="m-b-20">
                    <div class="d-flex">
                      <mat-card-header>
                        <div *ngIf="row.aiCode===1" mat-card-avatar class="b-a-1 t-c"><i
                            class="i i-trending_up i-2_5x text-primary"></i>
                        </div>
                        <div *ngIf="row.aiCode!==1" mat-card-avatar class="b-a-1 t-c"><i
                            class="i i-{{ai.repviewIcon(row)}} i-2_5x text-primary"></i>
                        </div>
                        <mat-card-title>{{row.aiName}}</mat-card-title>
                        <mat-card-subtitle>
                          {{((row.aiCode===99||row.aiCode===991)&&row.aiSubcode!==2)?row.chartTitle:ai.charttitle}}
                        </mat-card-subtitle>
                      </mat-card-header>
                      <div class="m-l-auto">
                        <div class="ib-m">
                          <button type="button" matRipple class="btn btn-primary btn-md lighten m-x-5 togglerIcon"
                            [ngClass]="{
                            active:  row.aiNote
                          }" *ngIf="row.id!==0" (click)="ai.addChartNotesview(row.id)"
                            title="{{row.aiNote ?'Edit Note':'Add Note'}}">
                            <i class="i i-notes i-1_7x"></i>
                            <div class="stat">

                              {{row.aiNote ?'Edit Note':'Add Note'}}
                            </div>
                          </button>
                          <button type="button" matRipple class="btn btn-primary btn-md lighten m-x-5 togglerIcon"
                            [ngClass]="{
                            active: row.id!==0
                          }" (click)="aiv.deleteReport(row)" title="{{row.id!==0 ?' Remove':'Add to Report'}}">
                            <i class="i i-ai_report i-1_7x"></i>
                            <div class="stat">
                              {{row.id!==0 ?' Remove' : 'Add to Report'}}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>

                    <mat-card-content>
                      <div class="row h-100">
                        <div class="col-sm-24" style="min-height: 360px;">
                          <img src="data:image/png;base64,{{row.chartImage}}" alt="Report Chart">
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>

              <!-- What is InsIQ-->
              <mat-card [class.mat-elevation-z24]="true" *ngIf="ai.InsIQclickflg" class="dtlCard m-x-20 m-y-30">
                <mat-card-content>
                  <h1 class="title">What is InsIQ?</h1>
                  <p>Hello there! Thank you for using InsIQ. Your insights are ready. Before you start looking at
                    them, we have a few tips on how InsIQ works and how to use the outputs effectively:</p>
                  <h2 class="subTitle">How Does The InsIQ Market Intelligence Module Work?</h2>

                  <ul class="list">
                    <li>
                      InsIQ uses your input for product descriptions, categories, desired markets, etc. and
                      extracts
                      useful concepts and sub-concepts. For example, if you were researching a new beachwear design,
                      some related ideas could be bikinis, famous beaches, surfboards, wetsuits, sunscreen, etc.
                    </li>
                    <li>
                      InsIQ combines these concepts with its internal intelligence and knowledge. It scours the web
                      as well as data sets which get continuously updated for insights which might be useful or give
                      you new ideas. For example, your insights could include popular bathing suits and bikinis sold
                      on Amazon. A superior wetsuit design project featured on Kickstarter. Or, Manufacturers who
                      specialize in beachwear and sunscreen. Popular blogs which write or talk about beachwear or
                      surfing, hazards of sun exposure on beaches, patents, and trademarks for similar products.
                    </li>
                    <li>
                      The goal of InsIQ is not to only give you information which you are looking for, but also to
                      provide insights which may spur new ideas or a-ha moments.
                    </li>
                    <li>
                      All these insights organize into commonly researched categories such as similar products,
                      industry trends, and news, suppliers, regulations, and safety, etc.
                    </li>
                    <li>
                      The outputs and any related notes you make can be downloaded or shared as a custom pdf
                      report.
                    </li>
                  </ul>

                  <h2 class="subTitle">How To Use The InsIQ Market Intelligence Module:</h2>

                  <ul class="list">
                    <li>
                      Take a look at the summary of generated insights. The concepts and sub-concepts which InsIQ
                      extracted from your inputs are displayed. And the number of relevant and related links and
                      documents found in each of the insights categories.
                    </li>
                    <li>
                      You can go to any of the insights categories to review them in detail. When you like something
                      or want to research it further, upvote it, and it will get saved into your favorites. If you
                      downvote something, it will not show up when you refresh your insights.
                    </li>
                    <li>
                      You can share any link or document and make notes about your opinions and analysis of each
                      insight.
                    </li>
                    <li>
                      At any time, you can edit your input to get more or different insights or to change the
                      extracted concepts.
                    </li>
                    <li>
                      When you are ready, download all your insights and notes as a customized PDF report.
                    </li>
                  </ul>

                  <h2 class="subTitle">What To Do Next?</h2>
                  <p>
                    The InsIQ Market Intelligence Module helps you with the first step in your product development
                    process. You are looking at your idea, project or product from various perspectives such as
                    target
                    market, competitors, supply base, complexity, regulatory and safety, intellectual property
                    challenges, and so on. This understanding is essential to help you make the right decisions
                    going
                    forward.
                  </p>

                  <p>
                    Your journey might end here. And, all you need is the report generated by InsIQ for your
                    management or client to help them make the right decisions. Or, you might use this research to
                    convince investors to fund your project.
                  </p>

                  <h2 class="subTitle">Product Design Intelligence</h2>

                  <p>You can move on to the next module, the InsIQ Product Design Intelligence Module (Under
                    Development). With this module, dive deeper into the execution of your project or product
                    development.</p>

                  <p>
                    Identify your top customers, suppliers, partners and influencers, and their critical needs. Know
                    what your testing strategies need to be. And, design features, manufacturing or other
                    development
                    processes. Get insights about patentability, costs, and complexity of your designs.
                  </p>

                  <p>
                    Create multiple concepts or strategies which will ensure success and gain the confidence of
                    management, clients, or investors.
                  </p>

                  <h2 class="subTitle">Risk Intelligence Analysis</h2>
                  <p>
                    Risk analysis is an essential step in product development. With the InsIQ Risk Intelligence
                    Module (Under Development), identify the risks in your designs, processes, and strategies which
                    could prevent you from meeting your goals. Ideate and execute changes to product features,
                    procedures, and test methods to eliminate these risks.
                  </p>

                  <p>
                    All of InsIQ’s AI-powered modules function as an automated work-flow of product development
                    best practices. This work-flow combines with constantly evolving knowledge from the web and internal
                    data sets. Together they provide the expert guidance needed to make your project a success.
                  </p>
                </mat-card-content>
              </mat-card>
              <!--Partners and Services-->

              <mat-card [class.mat-elevation-z24]="true" *ngIf="ai.partnerclickflg" class="dtlCard m-x-20 m-y-30">
                <mat-card-content>
                  <h2 class="title">Partners and Services</h2>
                  <p>
                    The InsIQ team can provide specific services as needed for your product development efforts.
                    We can provide high quality and cost-effective support for:
                  </p>

                  <ul class="list">
                    <li>Industrial Design</li>
                    <li>Website design</li>
                    <li>Custom software development</li>
                    <li>Data science services</li>
                    <li>General consulting</li>
                    <li>Prototyping</li>
                    <li>Manufacturing fixtures</li>
                    <li>Manufacturing – mechanical, electrical and electronics</li>
                  </ul>

                  <p>
                    Please give us some more information about your specific needs. A team member will contact you, or
                    you can send us an email at <a href="mailto:services@insiq.ai"></a>
                  </p>
                </mat-card-content>
              </mat-card>

              <div class="aiDashboard mg mg-2" [ngClass]="{'d-none': ai.dashboard !== true}"
                *ngIf="ai.view.value('mode') == 'Research Mode'">
                <!-- trendschart -->

                <div class="mg-brick" *ngIf="!ai.desiagnApiCall&&ai.trendchart">
                  <mat-card>
                    <div class="d-flex m-b-10 b-b-1">
                      <mat-card-header *ngIf="!ai.desiagnApiCall&&ai.trendchart">
                        <div mat-card-avatar class="b-a-1 t-c"><i class="i i-trending_up i-2_5x text-primary"></i>
                        </div>
                        <mat-card-title>Trends Interest over Time</mat-card-title>
                        <mat-card-subtitle>{{ai.charttitle}}</mat-card-subtitle>
                      </mat-card-header>
                      <div class="m-l-auto d-flex align-items-center justify-content-start"
                        *ngIf="!ai.desiagnApiCall&&ai.trendchart&&ai.featureChecker(20)">
                        <div class="b-r-1 p-r-5 m-r-10 ib-m" *ngIf="ai.trendlist">
                          <button type="button" class="btn btn-primary btn-md lighten m-x-5 togglerIcon"
                            *ngIf="ai.trendlist[0].reportId" (click)="ai.addChartNotes(ai.trendReportId,true)"
                            [ngClass]="{
                                active:ai.trendlist[0].note
                              }" title="{{ai.trendlist[0].note?'Edit Note':'Add Note'}}">
                            <i class="i i-notes i-1_7x"></i>
                            <div class="stat">
                              <span>{{ai.trendlist[0].note?'Edit Note':'Add Note'}}</span>
                            </div>
                          </button>
                          <button type="button" class="btn btn-primary btn-md lighten m-x-5 togglerIcon"
                            (click)="aiv.setChartOptions('chartTrends',1,'1','1',null,0)" [ngClass]="{
                              active: ai.trendlist[0].reportId
                            }" title="{{ai.trendlist[0].reportId?' Remove':'Add to Report'}}">
                            <i class="i i-ai_report i-1_7x"></i>
                            <div class="stat">
                              <span>{{ai.trendlist[0].reportId?' Remove':'Add to Report'}}</span>
                            </div>
                          </button>
                        </div>

                        <button class="btn mat-button-base mdlbtn infobtn" title="How to use the Graphs"
                          (click)="ai.helpInfo('InsIQ Trends Interest over Time')">
                          <img src="./assets/images/infoGif.gif">
                        </button>
                      </div>
                    </div>

                    <mat-card-content *ngIf="ai.dashboard&&!ai.featureChecker(20)">
                      <app-upgrade-plan infoGraphic="premium_feature_trends.png"
                        title="Upgrade your plan to get this feature"
                        message="<strong>Trend Analysis</strong> feature is not available in your current plan. Please upgrade your Plan!">
                      </app-upgrade-plan>
                    </mat-card-content>

                    <mat-card-content *ngIf="!ai.desiagnApiCall&&ai.trendchart&&ai.featureChecker(20)">
                      <div [ngClass]="{
                          onLoading: ai.trendChartData.length===0
                        }" style="min-height: 360px;">
                        <canvas id="chartTrends" baseChart style="cursor:pointer" [datasets]="[{
                        axis: 'y',
                        fill: true,
                        borderWidth: 3,
                        pointHoverRadius: 30,
                        data: ai.trendChartData,
                        borderColor: '#0c448b',
                        pointHoverBorderWidth: 2,
                        pointBorderColor: '#0c448b',
                        pointBackgroundColor: '#0c448b',
                        backgroundColor: 'rgba(12, 68, 139, 0.24)',
                        pointHoverBorderColor: 'rgba(12, 68, 139, 0.16)',
                        pointHoverBackgroundColor: 'rgba(12, 68, 139, 0.36)'
                      }]" [labels]="ai.trendChartLabels" [chartType]="ai.trendChartType"
                          [options]="ai.trendChartOptions" [plugins]="ai.pieChartPlugins" [legend]="ai.trendChartLegend"
                          (chartClick)="ai.onTrendClick($event)">
                        </canvas>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <ng-container *ngFor="let row of ai?.dashCharts; index as i;">
                  <!-- Insight 0 -->
                  <div class="mg-brick"
                    *ngIf="!(!ai.getConfigLoad()&&!ai.trendchart&&ai.dashboard&&(ai.cacheClear||!ai.smartlist))">
                    <mat-card class="m-b-20">
                      <div class="d-flex m-b-10 b-b-1">
                        <mat-card-header>
                          <div mat-card-avatar class="b-a-1 t-c"><i class="i i-product_box i-2_5x text-primary"></i>
                          </div>
                          <mat-card-title>{{ai.catName(row.aiTypeID,view)}}</mat-card-title>
                          <mat-card-subtitle>{{ai.charttitle}}</mat-card-subtitle>
                        </mat-card-header>
                        <div class="m-l-auto d-flex align-items-center justify-content-start">
                          <div class="b-r-1 p-r-5 m-r-10 ib-m">
                            <button type="button" class="btn btn-primary btn-md lighten m-x-5 togglerIcon" [ngClass]="{
                              active: row.note!==''
                            }" *ngIf="row.reportId!==0" (click)="ai.addChartNotes( row.reportId)"
                              title="{{ row.note!=='' ?'Edit Note':'Add Note'}}">
                              <i class="i i-notes i-1_7x"></i>
                              <div class="stat">
                                <span>{{ row.note!=='' ?'Edit Note':'Add Note'}}</span>
                              </div>
                            </button>

                            <button type="button" class="btn btn-primary btn-md lighten m-x-5 togglerIcon" [ngClass]="{
                              active: row.reportId!==0
                            }" (click)="aiv.setChartOptions('can', row.aiTypeID,row.aiTypeID,row.aiTypeID,row,i)"
                              title="{{row.reportId!==0?' Remove':'Add to Report'}}">
                              <i class="i i-ai_report i-1_7x"></i>
                              <div class="stat">
                                <span>{{row.reportId!==0?' Remove':'Add to Report'}}</span>
                              </div>
                            </button>
                          </div>

                          <button class="btn mat-button-base mdlbtn infobtn" title="How to use the Graphs"
                            (click)="ai.helpInfo('Similar Products or Projects')">
                            <img src="./assets/images/infoGif.gif">
                          </button>
                        </div>
                      </div>

                      <mat-card-content>
                        <div class="row h-100">
                          <div class="graph" style="min-height: 260px;">
                            <canvas id="can{{row.aiTypeID}}" baseChart style="cursor:pointer" [datasets]="row.chartData"
                              [labels]="row.chartLabel" [options]="ai.stackChartOptions"
                              [plugins]="ai.stackChartPlugins" [legend]="ai.stackChartLegend"
                              (chartClick)="ai.onChartClick($event,row.aiTypeID, view)" [chartType]="ai.stackChartType">
                            </canvas>
                          </div>
                          <!-- piechart1 -->
                          <div class="b-t-1">
                            <lib-tag-cloud [data]="row.tagCloud"
                              (link)="ai.tagManager($event,row.aiTypeID)"></lib-tag-cloud>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </ng-container>
              </div>
            </div>

            <ng-container
              *ngTemplateOutlet="ai.view.value('mode') != 'Turbo Mode' ? insightTemplate: null"></ng-container>

          </section>
          <aside *ngIf="ai.getCurrentTab() === 0 && ai.view.value('mode') == 'Turbo Mode'&&ai.projectOwnerCheck()">
            <div class="toolbar justify-content-center">
              <!--  [disabled]="ai.isTyping" -->
              <button type="button" class="btn btn-primary btn-link" matRipple (click)="ai.generateReport('dropdown')"
                *ngIf="ai?.messages?.length!=0">
                <i class="i i-open_in_new"></i>
                <span>Generate Report</span>
              </button>
            </div>
            <div class="toolbar p-b-40">
              <button class="btn btn-primary btn-link" type="button" matRipple (click)="ai.savedChat()"
                *ngIf="ai?.messages?.length!=0">
                <i class="i i-save"></i>
                <span> Saved Chats</span>
              </button>
              <button class="btn btn-primary btn-link" type="button" matRipple (click)="ai.clearChat()"
                *ngIf="ai?.messages?.length!=0">
                <i class="i i-sync"></i>
                <span> Clear Chat</span>
              </button>
            </div>
            <div class="w-100 m-t-auto">
              <strong>Sample Prompts: </strong>
              <button *ngFor="let prmptCat of ai.chatPromptCat(); index as i;" type="button"
                (click)="ai.getPromptText(prmptCat)" class="btn btn-primary btn-xs lighten rounded m-r-5 m-b-5"
                [matMenuTriggerFor]="pfpromptMenu1" aria-label="prompt">
                <span>{{prmptCat}}</span>
                <i class="i i-arrow m-l-5"></i>
              </button>
              <mat-menu #pfpromptMenu1="matMenu" class="promptMenu">
                <button *ngFor="let prmptCatPrompt of ai.uniquePromptArray;"
                  (click)="ai.setPromptText(prmptCatPrompt?.prompt)" mat-menu-item>
                  <span>{{prmptCatPrompt?.prompt}}</span>
                </button>
              </mat-menu>
            </div>
          </aside>
        </div>

        <app-modal id="notemodal">
          <div class="h-100 scrollY">
            <mat-card class="m-a-10">
              <mat-card-content>
                <div class="w-100">
                  <div class="t-c">
                    <div class="d-flex">
                      <label class="bigLabel">Capture your notes</label>
                    </div>
                    <mat-form-field class="fw m-b-10 sm" floatLabel="never" appearance="outline" title=""
                      matTooltipPosition="above">
                      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="aiNotes"
                        autocomplete="off"
                        placeholder="Record your thoughts on the AI insight which will be added to PDF report"
                        cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"
                        title="Record your thoughts on the AI insight which will be added to PDF report"
                        (keyup)="ai.txtchange($event.target.value)" appAutoFocus></textarea>
                      <!-- <mat-error *ngIf="view.invalid('notes')">{{view.error('notes')}}</mat-error>-->
                    </mat-form-field>
                    <div class="bar-T m-t-15 action flex-wrap">

                      <button type="button" title="Delete the Notes Entered" matTooltipPosition="above"
                        *ngIf="ai.view.value('notesId')" matRipple (click)="ai.noteDelete()"
                        class="btn btn-lg btn-danger m-x-5 lighten">
                        <!-- <mat-icon fontSet="i" class="i-delete i-1_5x ib-m"></mat-icon>-->
                        <span class="ib-m-l-5">Delete Note</span>
                      </button>
                      <button type="button" (click)="ai.cancelNote()" matRipple
                        class="m-x-5 btn-cancel btn btn-lg btn-default lighten">Cancel</button>

                      <button type="button" (click)="ai.saveNote()" matRipple class="btn btn-lg btn-primary m-x-5"
                        [disabled]="!ai.newnotesave"> Save</button>
                      <button type="button" title="Save and Post to MS Teams Channel" matTooltipPosition="above"
                        [disabled]="!ai.newnotesave" *ngIf="ai.msstatus&&ai.mschannelid!= null" matRipple
                        (click)="ai.saveNotems()" class="ib-m m-r-15 t-normal f-400 btn btn-lg btn-primary">
                        <i class="i i-ms-team"></i>
                        <!-- <mat-icon fontSet="i" class="i-ms-team i-1_5x ib-m"></mat-icon> -->
                        <span class="ib-m-l-5"> Save and Post to MS Teams</span>
                      </button>

                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </app-modal>

        <app-modal id="Chartnotemodal">
          <div class="h-100 scrollY">
            <mat-card class="m-a-10">
              <mat-card-content>
                <div class="w-100">
                  <div class="t-c">
                    <div class="d-flex">
                      <label class="bigLabel">Capture your notes for this Chart</label>
                    </div>
                    <mat-form-field class="fw m-b-10 sm" floatLabel="never" appearance="outline" title=""
                      matTooltipPosition="above">
                      <input type="text" matInput appAutoFocus cdkTextareaAutosize #titleInput value="{{ai.chartnote}}"
                        autocomplete="off" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"
                        placeholder="Record your thoughts on the AI insight which will be added to PDF report"
                        title="Record your thoughts on the AI insight which will be added to PDF report"
                        (keyup)="ai.txtchangeC($event.target.value)">
                    </mat-form-field>
                    <div class="bar-T m-t-15 action flex-wrap">
                      <button type="button" title="Delete the Notes Entered" matTooltipPosition="above"
                        *ngIf="ai.chartnote" matRipple (click)="ai.ChartnoteDelete()"
                        class="btn btn-lg btn-danger lighten m-x-5">
                        <span class="ib-m-l-5">Delete Note</span>
                      </button>
                      <button type="button" (click)="ai.cancelNoteChart()" matRipple
                        class="m-x-5 btn-cancel btn btn-lg btn-default lighten">Cancel</button>
                      <button type="button" (click)="ai.saveNoteChart(titleInput.value,true)" matRipple
                        class="btn btn-lg btn-primary m-x-5" [disabled]="!ai.newnotesaveC">Save</button>
                      <button type="button" title="Save and Post to MS Teams Channel" matTooltipPosition="above"
                        [disabled]="!ai.newnotesaveC" *ngIf="ai.msstatus&&ai.mschannelid!= null" matRipple
                        (click)="ai.saveNotems()" class="ib-m m-r-15 t-normal f-400 btn btn-lg btn-primary">
                        <i class="i i-ms-team"></i>
                        <span class="ib-m-l-5"> Save and Post to MS Teams</span>
                      </button>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </app-modal>
      </form>
    </div>
  </div>
</div>

<ng-template #insightTemplate>
  <div class="aiDashboard aiDashboardCard p-y-0">
    <div class="queryQuestion"
      *ngIf="(ai.sourceQuerry||ai.getsourceQuerry())&&ai.activeTabIndex==2&&(ai.smartlistItem)&&ai.view.value('mode') == 'Turbo Mode'">
      {{ai.getsourceQuerry()?ai.getsourceQuerry():ai.sourceQuerry}}</div>

  </div>

  <div class="w-100 d-flex align-items-center" *ngIf="ai.view.value('mode') == 'Research Mode'">
    <div class="h-50px p-a-10 m-r-15"
      *ngIf="ai.hasRecord == 'true' && ai.dashboard !== true && ai.smartlist &&!ai.InsIQclickflg&&!ai.chartReports&&!ai.sentimentclickflg&&!ai.averagePrice&&!ai.partnerclickflg&&!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag">
      <div class="d-flex">
        <div class="ib-m">

          <div class="cuFilter">
            <button mat-button class="btnAi-filter" (click)="ai.toggleFilterMenu()">
              Filters
              <i class="i i-keyboard_arrow_down" [ngClass]="{'hidden': ai.filterMenuOpen}"></i>
              <i class="i i-keyboard_arrow_up" [ngClass]="{'hidden': !ai.filterMenuOpen}"></i>
            </button>
            <div class="filterMenu" [ngClass]="{'_open': ai.filterMenuOpen}">
              <div>
                <label>
                  <input type="checkbox" [checked]="ai.allSelected" (change)="ai.toggleAllSelection()">
                  All
                </label>
              </div>
              <div class="row">
                <div *ngFor="let category of ai.catfilter(); let i = index" class="col-12">
                  <div class="item">
                    <label>
                      <input type="checkbox" [checked]="ai.selectedCategories[i]"
                        (change)="ai.toggleCategorySelection(i)">
                      {{ category }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="menuFooter"> <button type="button" class="btn btn-primary" matRipple
                  (click)="ai.applyFilter()">Apply
                  Filter</button>
                <button type="button" class="btn-link" mat-button (click)="ai.clearFilter()">Clear
                  Filter</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="m-l-auto d-flex align-items-center"
      *ngIf="ai.smartlistItemDetailpaginator?.length!=0&&(ai.showWish||ai.notesviewflag||ai.reportviewflag||ai.insightsearch)">
      <button type="button" matRipple (click)="ai.toggleView();toggleGrid()"
        title="{{ai.listViewInsight?'Switch to Grid View':'Switch to List View'}}" mat-mini-fab
        class="ib-m m-x-5 m-b-20"><i *ngIf="ai.listViewInsight" class="i i-grid"></i><i *ngIf="!ai.listViewInsight"
          class="i i-list"></i>
      </button>


    </div>
    <div class="m-l-auto d-flex align-items-center"
      *ngIf="ai.hasRecord == 'true' && ai.dashboard !== true && ai.smartlist &&!ai.InsIQclickflg&&!ai.chartReports&&!ai.sentimentclickflg&&!ai.averagePrice&&!ai.partnerclickflg&&!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch">
      <button type="button" title="{{ai.listViewInsight?'Switch to Grid View':'Switch to List View'}}" matRipple
        (click)="ai.toggleView();toggleGrid()" mat-mini-fab class="ib-m m-x-5 m-b-20"><i *ngIf="ai.listViewInsight"
          class="i i-grid"></i><i *ngIf="!ai.listViewInsight" class="i i-list"></i>
      </button>
      <button type="button" mat-mini-fab title="Export Excel" class="ib-m m-x-5 m-b-20" (click)="ai.exportToExcel()">
        <mat-icon fontSet="i" class="i-files i-1_7x ib-m"></mat-icon>
      </button>
      <button type="button" mat-mini-fab title="Insight Search" (click)="ai.searchbyCategory()"
        class="ib-m m-x-5 m-b-20" *ngIf="!ai.insightsearch">
        <mat-icon fontSet="i" class="i-search i-1_7x ib-m"></mat-icon>
      </button>

      <mat-form-field class="sortInput">
        <mat-label>Sort By</mat-label>
        <mat-select [(value)]="ai.selectedSort" (selectionChange)="ai.sortChange($event)">
          <mat-option value="1">Date: Newest First</mat-option>
          <mat-option value="2">Date: Oldest First</mat-option>
          <mat-option value="3">Relevance: High to Low</mat-option>
          <mat-option value="4">Relevance: Low to High</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </div>
  <div class="align-items-center m-t-20" *ngIf="ai.view.value('mode') == 'Research Mode'">
    <div class="bar-T m-b-10 align-items-center justify-content-center">

      <button type="button" matRipple (click)="ai.insightSummary(view)"
        *ngIf="(ai.hasRecord == 'true' && ai.dashboard !== true && ai.smartlist &&!ai.InsIQclickflg&&!ai.chartReports&&!ai.sentimentclickflg&&!ai.averagePrice&&!ai.partnerclickflg&&!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch)&&!(ai.flSelected==199||ai.flSelected==0||ai.flSelected==101)"
        class="btn btn-primary btn-lg lighten justify-content-center">Insights Analysis</button>

    </div>
  </div>
  <!-- table-example.component.html -->
  <mat-card class="tableCard"
    *ngIf="(ai.view.value('mode') == 'Research Mode'&&ai.smartlistItemDetailpaginator?.length!=0&&ai.listViewInsight&&ai.hasRecord == 'true' && ai.dashboard !== true && ai.smartlist && !ai.InsIQclickflg&&!ai.chartReports &&!ai.sentimentclickflg&&!ai.averagePrice&& !ai.partnerclickflg)">
    <p-table [value]="ai.smartlistItemDetail" [rows]="rows" [first]="first" [paginator]="true">
      <ng-template pTemplate="header">
        <tr
          *ngIf="!(ai.showWish||ai.notesviewflag||ai.reportviewflag||ai.insightsearch)&&ai.view.value('mode') == 'Research Mode'">
          <th *ngFor="let col of cols">{{ col.header }}</th>
        </tr>
        <tr *ngIf="ai.showWish||ai.reportviewflag||ai.insightsearch">
          <th *ngFor="let col of colsFav">{{ col.header }}</th>
        </tr>
        <tr *ngIf="ai.notesviewflag">
          <th *ngFor="let col of colsNotes">{{ col.header }}</th>
        </tr>
        <tr *ngIf="ai.view.value('mode') == 'Turbo Mode'">
          <th *ngFor="let col of turbocols">{{ col.header }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td *ngIf="ai.view.value('mode') == 'Research Mode'"><button mat-icon-button [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="aiDroppWrap">
              <div class="menuInner">
                <div class="topBtn">
                  <button mat-menu-item type="button" [matMenuTriggerFor]="deepDiveMenu" matRipple
                    *ngIf="!ai.desiagnApiCall" matTooltipClass="md-fs" title="Deep dive for related insights"
                    matTooltipPosition="below" class="btn btn-primary btn-lg t-c ">
                    Deep dive for related insights
                  </button>
                </div>
                <div class="lineBtm">


                  <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
                    active: row.liked
                  }" (click)="ai.removefav(row)" title="Remove From Favorite" *ngIf="ai.showWish">
                    <i class="i i-favourite"></i> Remove favorite
                  </button>

                  <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
                    active: row.liked
                  }" (click)="ai.likeOrUnlike(row, 1)" title="{{row.liked?'Remove Favorite':'Mark as Favorite'}}"
                    *ngIf="(!ai.showWish||ai.inddeep) && (!row.liked || !(row.liked?.likeon == '0'))">
                    <i class="i i-favourite"></i> {{row.liked?'Remove favorite':'Mark as favorite'}}
                  </button>
                  <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
                    active: row.report == '1'
                  }" title="{{row?.report == '1'?' Remove':'Add to Report'}}" (click)="ai.addremoveReport(row)">
                    <i class="i i-ai_report"></i>
                    {{row?.report == '1'?' Remove from report':'Add to Report'}}
                  </button>

                  <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
                    active: row?.notesId!==null
                  }" title="{{row?.notesId!==null?trimtooltip(row.noteAdd):'Add Notes'}}"
                    (click)="ai.addOrEditNotes(row)">
                    <i class="i i-notes"></i> {{row?.notesId!==null?'Edit Notes':'Add Notes'}}
                  </button>
                  <button mat-menu-item type="button" class="btn btn-link leftBtn" (click)="ai.copyText(row.aiUrl)"
                    title="Copy" aria-label="Copy">
                    <i class="i i-copy"></i>Copy
                  </button>

                  <button mat-menu-item type="button" class="btn btn-link leftBtn" title="Share Insight"
                    [matMenuTriggerFor]="shareMenu">
                    <i class="i i-share"></i>Share Insight
                  </button>
                </div>
              </div>
            </mat-menu>

            <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">

              <h2 class="subTitle">Market Intelligence</h2>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'31')">{{ai.catName('31',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'6')">{{ai.catName('6',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'10')">{{ai.catName('10',
                view)}}</button>

              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'2')">{{ai.catName('2',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'32')">{{ai.catName('32',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'8')">
                {{ai.catName('8', view)}}</button>
              <h2 class="subTitle">Product R&D Intelligence</h2>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'11')">{{ai.catName('11',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'7')">{{ai.catName('7',
                view)}}</button>

              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'5')">{{ai.catName('5',
                view)}}</button>

              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'4')">{{ai.catName('4',
                view)}}</button>

              <h2 *ngIf="ai.featureChecker(18)" class="subTitle">Business Intelligence</h2>

              <button *ngIf="ai.featureChecker(18)" mat-menu-item
                (click)="ai.insightDeep(row.aiId,'9')">{{ai.catName('9', view)}}</button>
            </mat-menu>
            <mat-menu #shareMenu="matMenu" xPosition="after" yPosition="above" [overlapTrigger]="false">
              <ng-template matMenuContent>
                <button type="button" mat-icon-button color="accent"
                  (click)="linkedinshre($event,row.aiUrl,row.aiTitle)" title="LinkedIn Share"
                  [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                  <i class="i i-linkedin"></i>
                </button>
                <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiUrl)"
                  title="Twitter Share" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                  <i class="i i-twitter"></i>
                </button>
                <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiUrl)"
                  [matTooltipClass]="'md-fs'" title="Facebook Share" matTooltipPosition="below">
                  <i class="i i-facebook"></i>
                </button>
                <button type="button" mat-icon-button color="accent"
                  (click)="watzappshare($event,row.aiUrl,row.aiTitle)" title="Whatsapp Share"
                  [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                  <i class="i i-whatsapp"></i>
                </button>
                <button type="button" mat-icon-button color="accent" *ngIf="ai.msstatus&&ai.mschannelid!= null"
                  (click)="ai.teamshare(row.aiNameTemp,row.aiUrl,row.aiTitle,row.aiText)"
                  title="Post in MS Teams Channel" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                  <i class="i i-ms-team"></i>
                </button>
              </ng-template>
            </mat-menu>
          </td>
          <td *ngIf="(ai.showWish||ai.notesviewflag||ai.reportviewflag||ai.insightsearch)">
            <div class="badgelabel">
              <span class="">{{getTooltip(ai.catId(row.aiName),row.aiTitle,ai.desiagnApiCall)}}</span>
            </div>
          </td>
          <td *ngIf="row.aiImages">

            <a [innerHTML]="ai.titleManager(splitKeywordsAndTitle(row.aiTitle).title)"
              (click)="openLinkInNewTab(row.aiUrl)"></a>
          </td>
          <td *ngIf="!row.aiImages">

            <a [innerHTML]="ai.titleManager(row.aiTitle)" (click)="openLinkInNewTab(row.aiUrl)"></a>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <i *ngIf="row.aiVideos !== '' && row.aiVideos" class="i i-ondemand_video m-r-10"
                style="font-size: 30px; cursor: pointer;" title="Click to view Video"
                (click)="ai.openVideoPopup(transform(ai.getVideoembed(row.aiVideos)))"></i>
              <div class="showTooltip">

                <ng-container *ngIf="row.aiVideos; else showTextOrImage">

                  <!-- Use iframe to embed YouTube video -->

                  <div *ngIf="ai.showVideoPopup" class="popupImg">
                    <div class="popup-contentImg">
                      <span class="closeImg" (click)="ai.closeVideoPopup()">&times;</span>
                      <iframe width="250" height="150"
                        [src]="transform(ai.getVideoembed(row.aiVideos)) | safe: 'resourceUrl'" frameborder="0"
                        allowfullscreen></iframe>
                    </div>


                  </div>
                  <div [innerHTML]="ai.aiTexttrim(splitKeywordsAndDescription(row.aiText).description)">
                  </div>
                </ng-container>
                <ng-template #showTextOrImage>
                  <ng-container *ngIf="!row.aiImages; else showImage">
                    <div [innerHTML]="ai.aiTexttrim(splitKeywordsAndDescription(row.aiText).description)">
                    </div>
                  </ng-container>
                  <ng-template #showImage>
                    <!-- Display the image placeholder here -->
                    <i class="i i-photo_size_select_actual" style="font-size: 30px; cursor: pointer;"
                      title="Click to view Image" (click)="ai.openPopup(transform(row.aiImages))"></i>

                    <!-- Pop-up/modal for displaying the actual image -->
                    <div *ngIf="ai.showPopup" class="popupImg">
                      <div class="popup-contentImg">
                        <span class="closeImg" (click)="ai.closePopup()">&times;</span>
                        <img [src]="ai.selectedImageUrl | safe: 'url'" alt="Actual Image" />
                      </div>
                    </div>
                  </ng-template>

                </ng-template>
                <div class="overlayTooltip"
                  *ngIf="ai.aiTexttrim(splitKeywordsAndDescription(row.aiText)?.description)!==splitKeywordsAndDescription(row.aiText).description||(getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)==='Patents & Trademarks'||getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)==='Patents')&&!ai.showWish&&(row.aiCountry !== '' || row.aiTechDomain!== ''||row.aiClaim!== '')&&(row.aiCountry !== undefined||row.aiTechDomain!== undefined||row.aiClaim!== undefined)&&(row.aiCountry||row.aiTechDomain||row.aiClaim)&&(row.aiCountry!==null||row.aiTechDomain!==null||row.aiClaim!==null)">
                  <div [innerHTML]="splitKeywordsAndDescription(row.aiText).description"></div>
                  <div (click)="ai.patentModal(row.aiId)" class="m-t-10 readmore t-r"
                    *ngIf="(getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)==='Patents & Trademarks'||getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)==='Patents')&&!ai.showWish&&(row.aiCountry !== '' || row.aiTechDomain!== ''||row.aiClaim!== '')&&(row.aiCountry !== undefined||row.aiTechDomain!== undefined||row.aiClaim!== undefined)&&(row.aiCountry||row.aiTechDomain||row.aiClaim)&&(row.aiCountry!==null||row.aiTechDomain!==null||row.aiClaim!==null)">
                    Read More...
                  </div>

                </div>
              </div>
            </div>
          </td>

          <td *ngIf="!row.aiImages">
            <div class="showTooltip">
              <div [innerHTML]="truncateText(splitKeywordsAndDescription(row.aiText)?.keywords)"></div>
              <div class="overlayTooltip"
                *ngIf="truncateText(splitKeywordsAndDescription(row.aiText)?.keywords)!=splitKeywordsAndDescription(row.aiText)?.keywords">
                <div [innerHTML]="splitKeywordsAndDescription(row.aiText)?.keywords"></div>
              </div>
            </div>
          </td>
          <td *ngIf="row.aiImages">
            <div class="showTooltip">
              <div [innerHTML]="truncateText(splitKeywordsAndTitle(row.aiTitle)?.keywords)"></div>
              <div class="overlayTooltip"
                *ngIf="truncateText(splitKeywordsAndTitle(row.aiTitle)?.keywords)!=splitKeywordsAndTitle(row.aiTitle)?.keywords">
                <div [innerHTML]="splitKeywordsAndTitle(row.aiTitle)?.keywords"></div>
              </div>
            </div>

          </td>

          <td *ngIf="!(ai.showWish||ai.notesviewflag||ai.reportviewflag||ai.insightsearch)">
            {{row.aiDate}}
          </td>
          <td *ngIf="ai.notesviewflag">
            <div *ngIf="row?.notesId!==null&&ai.notesviewflag" tabindex="0" (click)="ai.addOrEditNotes(row)"
              [matTooltipClass]="'md-fs'" title="Click to Add/Edit Note" matTooltipPosition="below" class="notes">
              {{row.noteAdd}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </mat-card>

  <div class="col-sm-24 t-l p-x-5">
    <div class="mg p-a-5"
      *ngIf="(!ai.listViewInsight&&ai.hasRecord == 'true' && ai.dashboard !== true && ai.smartlist && !ai.InsIQclickflg&&!ai.chartReports &&!ai.sentimentclickflg&&!ai.averagePrice&& !ai.partnerclickflg)||(ai.view.value('mode') == 'Turbo Mode'&&ai.tabNumber==2&&ai.smartlistItem)">

      <div
        *ngIf="(ai.smartlistItemDetail && ai.smartlistItemDetail.length > 0&&!ai.listViewInsight)||(ai.view.value('mode') == 'Turbo Mode'&&ai.tabNumber==2&&ai.smartlistItem)">
        <div class="mg-brick" #divTest
          *ngFor="let row of ai.smartlistItemDetail | slice: (paginator1.first) : (paginator1.first + paginator1.rows)">
          <mat-card class="aiCard m-b-10 privilaged {{getborder(row.aiDeepDive)}}"
            [ngClass]="'c-' + getClass(row.aiNameIdTemp === undefined ?ai.catId(row.aiName) : row.aiNameIdTemp,row.aiTitle)"
            [class.mat-elevation-z8]="true">

            <mat-card-actions class="b-t-1">
              <ul class="buttonWrap justify-content-end">

                <li
                  *ngIf="((ai.showWish&&!ai.inddeep) || ((!ai.showWish||ai.inddeep) && (!row.liked || !(row.liked?.likeon == '0'))))&&ai.view.value('mode') == 'Research Mode'">
                  <button type="button" class="btn btn-link active" title="Remove From Favorite"
                    (click)="ai.removefav(row)" *ngIf="ai.showWish&&!ai.inddeep">
                    <i class="i i-favourite"></i>
                  </button>
                  <button type="button" class="btn btn-link" [ngClass]="{
                  active: row.liked
                }" (click)="ai.likeOrUnlike(row, 1)" title="{{row.liked?'Remove Favorite':'Mark as Favorite'}}"
                    *ngIf="(!ai.showWish||ai.inddeep) && (!row.liked || !(row.liked?.likeon == '0'))">
                    <i class="i i-favourite"></i>
                  </button>
                </li>
                <li *ngIf="ai.view.value('mode') == 'Research Mode'">
                  <button type="button" class="btn btn-link" [ngClass]="{
                 active: row.report == '1'
                }" title="{{row?.report == '1'?' Remove':'Add to Report'}}" (click)="ai.addremoveReport(row)">
                    <i class="i i-ai_report"></i>
                  </button>
                </li>
                <li *ngIf="ai.view.value('mode') == 'Research Mode'">
                  <button type="button" class="btn btn-link" (click)="ai.addOrEditNotes(row)" [ngClass]="{
                  active: row?.notesId!==null
                }" [title]="row?.notesId!==null?trimtooltip(row.noteAdd):'Add Notes'">
                    <i class="i i-notes"></i>
                  </button>
                </li>
                <li *ngIf="row.aiUrl!==''">
                  <button type="button" class="btn btn-link" (click)="ai.copyText(row.aiUrl)" title="Copy"
                    aria-label="Copy">
                    <i class="i i-copy"></i>
                  </button>
                </li>
                <li>
                  <button type="button" class="btn btn-link" title="Share Insight" [matMenuTriggerFor]="shareMenu">
                    <i class="i i-share"></i>
                  </button>
                </li>
              </ul>

              <div class="d-flex align-items-center">

                <mat-menu #shareMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
                  <ng-template matMenuContent>
                    <button type="button" mat-icon-button color="accent"
                      (click)="linkedinshre($event,row.aiUrl,row.aiTitle)" title="LinkedIn Share"
                      [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                      <i class="i i-linkedin"></i>
                    </button>
                    <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiUrl)"
                      title="Twitter Share" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                      <i class="i i-twitter"></i>
                    </button>
                    <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiUrl)"
                      [matTooltipClass]="'md-fs'" title="Facebook Share" matTooltipPosition="below">
                      <i class="i i-facebook"></i>
                    </button>
                    <button type="button" mat-icon-button color="accent"
                      (click)="watzappshare($event,row.aiUrl,row.aiTitle)" title="Whatsapp Share"
                      [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                      <i class="i i-whatsapp"></i>
                    </button>
                    <button type="button" mat-icon-button color="accent" *ngIf="ai.msstatus&&ai.mschannelid!= null"
                      (click)="ai.teamshare(row.aiNameTemp,row.aiUrl,row.aiTitle,row.aiText)"
                      title="Post in MS Teams Channel" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                      <i class="i i-ms-team"></i>
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
            </mat-card-actions>
            <div class="badgelabel icoBagde"
              *ngIf="(ai.hasRecord == 'true' && ai.dashboard !== true && ai.smartlist &&!ai.InsIQclickflg&&!ai.chartReports&&!ai.sentimentclickflg&&!ai.averagePrice&&!ai.partnerclickflg&&!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch)&&ai.view.value('mode') == 'Research Mode'">
              <i class="i i-shield_cancel" *ngIf="(row.airank===0||row.airank=== ''||row.airank=== undefined)"
                title="Insight Source Reputation"></i>
              <i class="i i-shield_yes active _verified" *ngIf="(row.airank===1)" title="Insight Source Reputation"></i>
              <i class="i i-doc_na" *ngIf="!row?.title?.includes('Curated')" title="InsIQ Curated"></i>
              <i class="i i-doc_star active _bookMark" *ngIf="row?.title?.includes('Curated')"
                title="InsIQ Curated"></i>
            </div>

            <div class="badgelabel" *ngIf="!ai.showWish">
              <span class="">{{getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)}}</span>
            </div>
            <div class="badgelabel" *ngIf="ai.showWish">
              <span class="">{{getTooltip(ai.catId(row.aiName),row.aiTitle,ai.desiagnApiCall)}}</span>
            </div>

            <img mat-card-image [src]="transform(row.aiImages) | safe: 'url'" [alt]="row.aiTitle" class="m-t-10"
              *ngIf="row.aiImages && row.aiImages !== ''">
            <div mat-card-image *ngIf="row.aiVideos !== '' && row.aiVideos" class="m-t-10">
              <!-- Use iframe to embed YouTube video -->
              <iframe width="100%" height="315" [src]="transform(ai.getVideoembed(row.aiVideos)) | safe: 'resourceUrl'"
                frameborder="0" allowfullscreen></iframe>
            </div>

            <mat-card-content>

              <div class="w-100 dateWrap" *ngIf="!ai.showWish">
                <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="row.aiDate">
                  <!-- <mat-icon fontSet="i" class="i-date_range i-1_5x ib-m"></mat-icon> -->
                  <i class="i i-calendar i-1_5x ib-m"></i>
                  <span class="ib-m m-l-5">{{row.aiDate}}</span>
                </div>
              </div>
              <div class="w-100">
                <!-- <div class="m-b-5">{{row.aiNameTemp}}</div>-->
                <div class="title" [innerHTML]="ai.titleManager(row.aiTitle)"></div>
                <a [href]="row.aiUrl" target="_blank">
                  <div class="linkText" title="Open in New Tab" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    {{row.aiUrl}}</div>
                </a>

                <div class="priceCard sm outline-secondary my-10" *ngIf="row.aiPrice">
                  <mat-icon fontSet="i" class="i-price_tag ib-m"></mat-icon>
                  <span>{{row.aiPrice}}</span>
                </div>
                <div class="aiTextWrap" [innerHTML]="ai.aiTexttrim(row.aiText)"></div>


                <div (click)="ai.patentModal(row.aiId)" class="m-t-10 readmore t-r"
                  *ngIf="(getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)==='Patents & Trademarks'||getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)==='Patents')&&!ai.showWish&&(row.aiCountry !== '' || row.aiTechDomain!== ''||row.aiClaim!== '')&&(row.aiCountry !== undefined||row.aiTechDomain!== undefined||row.aiClaim!== undefined)&&(row.aiCountry||row.aiTechDomain||row.aiClaim)&&(row.aiCountry!==null||row.aiTechDomain!==null||row.aiClaim!==null)">
                  Read More...
                </div>
              </div>

              <div *ngIf="row?.notesId!==null&&ai.notesviewflag" tabindex="0" (click)="ai.addOrEditNotes(row)"
                [matTooltipClass]="'md-fs'" title="Click to Add/Edit Note" matTooltipPosition="below" class="notes">
                {{row.noteAdd}}
              </div>
            </mat-card-content>


            <div class="btnWrap" *ngIf="ai.view.value('mode') == 'Research Mode'">
              <button type="button" [matMenuTriggerFor]="deepDiveMenu" matRipple *ngIf="!ai.desiagnApiCall"
                matTooltipClass="md-fs" title="Deep dive for related insights" matTooltipPosition="below"
                class="btn btn-primary btn-lg t-c">
                Deep dive for related insights
              </button>
            </div>
            <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">

              <h2 class="subTitle">Market Intelligence</h2>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'31')">{{ai.catName('31',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'6')">{{ai.catName('6',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'10')">{{ai.catName('10',
                view)}}</button>


              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'2')">{{ai.catName('2',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'32')">{{ai.catName('32',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'8')">
                {{ai.catName('8', view)}}</button>
              <h2 class="subTitle">Product R&D Intelligence</h2>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'11')">{{ai.catName('11',
                view)}}</button>
              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'7')">{{ai.catName('7',
                view)}}</button>

              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'5')">{{ai.catName('5',
                view)}}</button>


              <button mat-menu-item (click)="ai.insightDeep(row.aiId,'4')">{{ai.catName('4',
                view)}}</button>


              <h2 *ngIf="ai.featureChecker(18)" class="subTitle">Business Intelligence</h2>

              <button *ngIf="ai.featureChecker(18)" mat-menu-item
                (click)="ai.insightDeep(row.aiId,'9')">{{ai.catName('9', view)}}</button>
            </mat-menu>

          </mat-card>
        </div>
      </div>
    </div>
    <div [class.hidden]="ai.hidePaginator" class="grdpagenate">
      <p-paginator #paginator1 [rows]="8" [totalRecords]="ai.smartlistItemDetail?.length" [pageLinkSize]="3"
        [style]="{'margin-top':'10px'}" [paginatorPosition]="'bottom'"></p-paginator>
    </div>
  </div>

</ng-template>

<ng-template #deep>
  <div class="d-block p-a-0">
    <h4 class="text-accent">
      <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" (click)="ai.closeModal('custom-modal-2');"
        title="Close">
        <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
      </button>
    </h4>
    <mat-card-header>
      <div mat-card-avatar class="b-a-1 t-c"><i class="i i-deep_dive i-2_5x text-primary"></i></div>
      <mat-card-title>InsIQ Deep Dive Insights -- </mat-card-title>
      <mat-card-subtitle>{{ai.charttitle}}</mat-card-subtitle>
    </mat-card-header>
  </div>
  <div class="mg p-b-20">
    <div class="mg-brick" *ngFor="let row of ai.deepdiveDetails">
      <mat-card class="aiCard m-b-10 privilaged"
        [ngClass]="'c-' + getClass(row.aiNameIdTemp === undefined ? row.aiName : row.aiNameIdTemp,row.aiTitle)"
        [class.mat-elevation-z8]="true">

        <div class="badgeLabel" *ngIf="!ai.showWish">
          <mat-icon fontSet="i" attr.aria-label="{{row.aiNameIdTemp}}"
            class="i i-{{getIcon(row.aiNameIdTemp,row.aiTitle)}}"
            title="{{getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)}}"
            *ngIf="ai.tagcloudclick||!ai.showWish||ai.notesviewflag" matTooltipPosition="below">
          </mat-icon>

          <mat-card-actions class="b-t-1">
            <div class="d-flex">
              <button type="button" mat-icon-button title="Remove From Favorite" (click)="ai.removefav(row)"
                matTooltipPosition="below" *ngIf="ai.showWish&&!ai.inddeep">
                <mat-icon class="i-1_7x">delete
                </mat-icon>
              </button>
              <button type="button" mat-icon-button title="{{row.aiLikeon == '1'?'Remove Favorite':'Mark as Favorite'}}"
                matTooltipPosition="below" *ngIf="(!ai.showWish||ai.inddeep) && (!row.liked || !(row.aiLikeon == '0'))"
                (click)="ai.likeOrUnlike(row, 1)">
                <mat-icon class="i-2x" color="{{row.aiLikeon == '1'?'primary':''}}" fontIcon="i-favourite"></mat-icon>
              </button>

              <div class="spacer"></div>


              <button type="button" mat-icon-button *ngIf="row.aiUrl !== ''" (click)="ai.copyText(row.aiUrl)"
                title="Copy" matTooltipPosition="below">
                <mat-icon class="i-1_7x">content_copy</mat-icon>
              </button>

              <button type="button" mat-icon-button title="Share Insight" [matMenuTriggerFor]="shareMenu">
                <mat-icon class="i-1_7x">share</mat-icon>
              </button>
              <mat-menu #shareMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
                <ng-template matMenuContent>
                  <h2 class="subTitle">Heading 10</h2>
                  <button type="button" mat-icon-button color="accent"
                    (click)="linkedinshre($event,row.aiUrl,row.aiTitle)" title="LinkedIn Share"
                    matTooltipPosition="below">
                    <i class="i i-linkedin"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiUrl)"
                    title="Twitter Share" matTooltipPosition="below">
                    <i class="i i-twitter"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiUrl)"
                    title="Facebook Share" matTooltipPosition="below">
                    <i class="i i-facebook"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent"
                    (click)="watzappshare($event,row.aiUrl,row.aiTitle)" title="Whatsapp Share"
                    matTooltipPosition="below">
                    <i class="i i-whatsapp"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" *ngIf="ai.msstatus&&ai.mschannelid!= null"
                    (click)="ai.teamshare(row.aiName,row.aiUrl,row.aiTitle,row.aiText)" title="Post in MS Teams Channel"
                    [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-ms-team"></i>
                  </button>
                </ng-template>
              </mat-menu>
            </div>
          </mat-card-actions>

        </div>

        <div class="badgeLabel" *ngIf="ai.showWish">
          <mat-icon fontSet="i" attr.aria-label="{{row.aiName}}" class="i i-{{getIcon(row.aiName,row.aiTitle)}}"
            title="{{getTooltip(row.aiName,row.aiTitle,ai.desiagnApiCall)}}" matTooltipPosition="below">
          </mat-icon>
        </div>

        <img mat-card-image [src]="transform(row.aiImages) | safe: 'url'" [alt]="row.aiTitle"
          *ngIf="row.aiImages !== ''">
        <div mat-card-image *ngIf="row.aiVideos !== ''">
          <iframe frameborder="0" width="100%"
            [src]="transform(ai.getVideoembed(row.aiVideos)) | safe: 'resourceUrl'"></iframe>
        </div>
        <mat-card-content>
          <div class="w-100" *ngIf="!ai.showWish">
            <div class="ib-m m-r-15 curved text-accent t-normal f-400"
              *ngIf="row.aiDate !== ''||row.aiDate!== undefined">
              <i class="i i-calendar i-1_5x ib-m"></i>
              <span class="ib-m">{{row.aiDate}}</span>
            </div>
          </div>

          <div class="w-100">
            <div class="m-b-5">{{ai.titleManager(row.aiTitle)}}</div>
            <a [href]="row.aiUrl" target="_blank">
              <div class="text-accent" title="Open in New Tab" matTooltipPosition="below">{{row.aiUrl}}</div>
            </a>
            <div class="m-t-5">{{ai.aiTexttrim(row.aiText)}}</div>
            <div class="ib-m"></div>
          </div>
          <div *ngIf="ai.notesviewflag" href="javascript:;" (click)="ai.addOrEditNotes(row)"
            title="Click to Add/Edit Note" matTooltipPosition="below" class="notes">{{row.noteAdd}}
          </div>
        </mat-card-content>

        <button type="button" matRipple [matMenuTriggerFor]="deepDiveMenu" *ngIf="!ai.desiagnApiCall"
          (click)="ai.insightDeep(row.aiId)" matTooltipClass="md-fs" title="Deep dive for related insights"
          matTooltipPosition="below" class="btn btn-lg btn-primary m-r-5">
          Deep dive for related insights
        </button>
        <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">

          <h2 class="subTitle">Heading 9</h2>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'2')">{{ai.catName('2', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'31')">{{ai.catName('31', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'32')">{{ai.catName('32', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'4')">{{ai.catName('4', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'5')">{{ai.catName('5', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'6')">{{ai.catName('6', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'7')">{{ai.catName('7', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'8')">{{ai.catName('8', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'10')">{{ai.catName('10', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'11')">{{ai.catName('11', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'9')" *ngIf="ai.featureChecker(18)">{{ai.catName('9',
            view)}}</button>

        </mat-menu>
      </mat-card>
    </div>
    <!-- Bricks for Support -->

    <!-- Bricks for Support -->
  </div>
</ng-template>

<ng-template #secondDialog>
  <form [formGroup]="ai.view.form">
    <div class="h-100 scrollY b-patent" style="width:800px;">
      <mat-card class="m-a-10">
        <mat-card-content>
          <div class="w-100">
            <div class="t-c">
              <div class="d-flex">
                <label class="bigLabel">Capture your notes</label>
              </div>
              <mat-form-field class="fw m-b-10 sm" floatLabel="never" appearance="outline" title=""
                matTooltipPosition="above">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="aiNotes"
                  autocomplete="off" placeholder="Record your Notes" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"
                  title="Record your Notes Here" (keyup)="ai.txtchange($event.target.value)"></textarea>
              </mat-form-field>
              <div class="m-t-15 action">
                <button type="button" title="Delete the Notes Entered" matTooltipPosition="above"
                  *ngIf="ai.view.value('notes')&&!ai.newnotesave" matRipple (click)="deleteNote()"
                  class="btn btn-lg btn-danger m-x-5 lighten">
                  <span class="ib-m-l-5">Delete Note</span>
                </button>
                <button type="button" (click)="cancelNote()" matRipple
                  class="m-x-5 btn-cancel btn btn-lg btn-default lighten">Cancel</button>
                <button type="button" (click)="saveNote()" matRipple class="btn btn-lg btn-primary m-x-5"
                  [disabled]="!ai.newnotesave">Save</button>
                <div class="ib-m">
                  <button type="button" title="Save and Post to MS Teams Channel" matTooltipPosition="above"
                    [disabled]="!ai.newnotesave" color="primary" *ngIf="ai.msstatus&&ai.mschannelid!= null" matRipple
                    (click)="saveNotems()" class="ib-m m-r-15 t-normal f-400 btn btn-lg btn-primary">
                    <i class="i i-ms-team"></i>
                    <span class="ib-m-l-5">Save and Post to MS Teams</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</ng-template>

<ng-template #sentimentDetail>
  <div class="mat-elevation-z8 rel">
    <table mat-table [dataSource]="ai.dsSentimentDetail">
      <ng-container matColumnDef="aitext">
        <th class="w-300px" mat-header-cell *matHeaderCellDef>AI Text</th>
        <td class="w-300px f-medium" mat-cell *matCellDef="let row">{{row.aiText}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['aitext']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['aitext']"></tr>
    </table>
  </div>
</ng-template>

<!-- MODAL FOR DEEP DIVE INSIGHTS -->
<app-modal id="custom-modal-2">
  <div class="d-block p-a-0">
    <h4 class="text-accent">
      <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" (click)="ai.closeModal('custom-modal-2');"
        title="Close">
        <span>
          <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
        </span>
      </button>
    </h4>
    <mat-card-header>
      <div mat-card-avatar class="b-a-1 t-c">
        <i class="i i-deep_dive i-2_5x text-primary"></i>
      </div>
      <mat-card-title>InsIQ Deep Dive Insights</mat-card-title>
      <mat-card-subtitle>{{ai.charttitle}}</mat-card-subtitle>
    </mat-card-header>
    <div class="col-sm-24 t-l p-y-10 p-x-5">
      <mat-card class="h-50px p-a-10" color="accent">
        <h1 class="smartTitle h4 text-accent">

          <i class="i i-filter-o ib-m"></i>
          <span class="ib-m">
            <b class="ib-m text-primary p-x-5 m-l-5">{{getTooltip(getDeepcat(ai.deepcat),'head',ai.desiagnApiCall)}}</b>
          </span>
        </h1>
      </mat-card>
    </div>
  </div>
  <div class="mg p-b-20">
    <div class="mg-brick" *ngFor="let row of ai.deepdiveDetails">
      <mat-card class="m-b-30 aiCard privilaged"
        [ngClass]="'c-' + getClass(row.aiNameIdTemp === undefined ? row.aiName : row.aiNameIdTemp,row.aiTitle)"
        [class.mat-elevation-z8]="true">

        <div class="badgeLabel" *ngIf="!ai.showWish">
          <mat-icon fontSet="i" attr.aria-label="{{getDeepcat(ai.deepcat)}}"
            class="i i-{{getIcon(getDeepcat(ai.deepcat),row.aiTitle)}}"
            title="{{getTooltip(getDeepcat(ai.deepcat),row.aiTitle,ai.desiagnApiCall)}}" matTooltipPosition="below"
            *ngIf="ai.tagcloudclick||!ai.showWish||ai.notesviewflag">
          </mat-icon>

        </div>

        <div class="badgeLabel" *ngIf="ai.showWish">
          <mat-icon fontSet="i" attr.aria-label="{{getDeepcat(ai.deepcat)}}"
            class="i i-{{getIcon(getDeepcat(ai.deepcat),row.aiTitle)}}"
            title="{{getTooltip(getDeepcat(ai.deepcat),row.aiTitle,ai.desiagnApiCall)}}" matTooltipPosition="below">
          </mat-icon>
        </div>

        <mat-card-actions class="b-t-1">
          <div class="d-flex">


            <div class="spacer"></div>
            <button type="button" mat-icon-button title="Remove From Favorite" (click)="ai.removefav(row)"
              matTooltipPosition="below" *ngIf="ai.showWish&&!ai.inddeep">
              <mat-icon class="i-1_7x">delete</mat-icon>
            </button>
            <button type="button" mat-icon-button title="{{row.aiLikeon == '1'?'Remove Favorite':'Mark as Favorite'}}"
              matTooltipPosition="below" *ngIf="(!ai.showWish||ai.inddeep) && (!row.liked || !(row.aiLikeon == '0'))"
              (click)="ai.likeOrUnlikecat(row, 1,'Deep Dive')">
              <mat-icon class="i-2x" color="{{row.aiLikeon == '1'?'primary':''}}" fontIcon="i-favourite"></mat-icon>
            </button>
            <button type="button" mat-icon-button (click)="testfn(row)" [ngClass]="{
              active: row.noteAdd
            }">
              <mat-icon class="i-1_7x i-notes">note_add</mat-icon>
            </button>

            <button type="button" mat-icon-button *ngIf="row.aiUrl !== ''" (click)="ai.copyText(row.aiUrl)" title="Copy"
              matTooltipPosition="below">
              <mat-icon class="i-1_7x">content_copy</mat-icon>
            </button>

            <button type="button" mat-icon-button title="Share Insight" [matMenuTriggerFor]="shareMenu">
              <mat-icon class="i-1_7x">share</mat-icon>
            </button>
            <mat-menu #shareMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
              <ng-template matMenuContent>
                <h2 class="subTitle">Share Menu</h2>
                <button type="button" mat-icon-button color="accent"
                  (click)="linkedinshre($event,row.aiUrl,row.aiTitle)" title="LinkedIn Share"
                  matTooltipPosition="below">
                  <i class="i i-linkedin"></i>
                </button>
                <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiUrl)"
                  title="Twitter Share" matTooltipPosition="below">
                  <i class="i i-twitter"></i>
                </button>
                <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiUrl)"
                  title="Facebook Share" matTooltipPosition="below">
                  <i class="i i-facebook"></i>
                </button>
                <button type="button" mat-icon-button color="accent"
                  (click)="watzappshare($event,row.aiUrl,row.aiTitle)" title="Whatsapp Share"
                  matTooltipPosition="below">
                  <i class="i i-whatsapp"></i>
                </button>
                <button type="button" mat-icon-button color="accent" *ngIf="ai.msstatus&&ai.mschannelid!= null"
                  (click)="ai.teamshare(row.aiName,row.aiUrl,row.aiTitle,row.aiText)" title="Post in MS Teams Channel"
                  [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                  <i class="i i-ms-team"></i>
                </button>
              </ng-template>
            </mat-menu>
          </div>
        </mat-card-actions>
        <img mat-card-image [src]="transform(row.aiImages) | safe: 'url'" [alt]="row.aiTitle"
          *ngIf="row.aiImages !== ''">
        <div mat-card-image *ngIf="row.aiVideos !== ''">
          <iframe frameborder="0" width="100%" [src]="transform(ai.getVideoembed(row.aiVideos)) | safe: 'resourceUrl'">
          </iframe>
        </div>
        <mat-card-content>
          <div class="w-100" *ngIf="!ai.showWish">
            <div class="ib-m m-r-15 curved text-accent t-normal f-400"
              *ngIf="row.aiDate !== ''||row.aiDate!== undefined">
              <i class="i i-calendar i-1_5x ib-m"></i>
              <span class="ib-m">{{row.aiDate}}</span>
            </div>
          </div>

          <div class="w-100">
            <div class="m-b-5">{{ai.titleManager(row.aiTitle)}}</div>
            <a [href]="row.aiUrl" target="_blank">
              <div class="text-accent" title="Open in New Tab" matTooltipPosition="below">{{row.aiUrl}}</div>
            </a>
            <div class="m-t-5" [innerHTML]="ai.aiTexttrim(row.aiText)"></div>
            <div class="ib-m"></div>
          </div>

          <div href="javascript:;" (click)="testfn(row)" *ngIf="row.noteAdd" title="Click to Add/Edit Note"
            matTooltipPosition="below" class="notes">{{row.noteAdd}}
          </div>
        </mat-card-content>


        <button type="button" matRipple [matMenuTriggerFor]="deepDiveMenu" matRipple *ngIf="!ai.desiagnApiCall"
          matTooltipClass="md-fs" title="Deep dive for related insights" matTooltipPosition="below"
          class="btn btn-lg btn-primary m-r-5">
          Deep dive for related insights
        </button>


        <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">

          <h2 class="subTitle">Heading 7</h2>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'2')">{{ai.catName('2', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'31')">{{ai.catName('31', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'32')">{{ai.catName('32', view)}}</button>

          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'4')">{{ai.catName('4', view)}}</button>

          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'5')">{{ai.catName('5', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'6')">{{ai.catName('6', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'7')">{{ai.catName('7', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'8')">{{ai.catName('8', view)}}</button>

          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'10')">{{ai.catName('10', view)}}</button>
          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'11')">{{ai.catName('11', view)}}</button>

          <button mat-menu-item (click)="ai.insightDeep(row.aiId,'9')" *ngIf="ai.featureChecker(18)">{{ai.catName('9',
            view)}}</button>

        </mat-menu>
      </mat-card>
    </div>
    <!-- Bricks for Support -->

    <!-- Bricks for Support -->
  </div>
</app-modal>

<!-- MODAL FOR TRENDS INSIGHTS-->
<!-- MODAL FOR PATENTS-->
<app-modal class="patmodstyle" id="patent-modal">
  <div class="d-block p-a-0">
    <h4 class="text-accent">
      <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" (click)="ai.closeModal('patent-modal');"
        title="Close">
        <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
      </button>
    </h4>
    <mat-card-header>
      <div mat-card-avatar class="b-a-1 t-c"><i class="i i-patent i-2_5x text-primary"></i></div>
      <mat-card-title>InsIQ Patent Info</mat-card-title>
      <mat-card-subtitle>{{ai.charttitle}}</mat-card-subtitle>
    </mat-card-header>
    <div class="mg-brick">
      <mat-card class="m-b-30 aiCard privilaged b-patent">
        <div class="w-100">
          <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="ai.patentCountry !== ''">
            <mat-icon fontSet="i" class="i-pin_drop i-1_5x ib-m"></mat-icon>
            <span class="ib-m">{{ai.patentCountry}}</span>
          </div>
          <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="ai.patentDomain !== ''">
            <mat-icon fontSet="i" class="i-domain i-1_5x ib-m"></mat-icon>
            <span class="ib-m">{{ai.patentDomain}}</span>
          </div>
        </div>
        <mat-card-content>
          <div class="w-100">
            <div class="m-t-5" [innerHTML]="ai.patentClaim"></div>
            <div class="ib-m"></div>
          </div>


        </mat-card-content>
      </mat-card>
    </div>
    <!-- Bricks for Support -->

    <!-- Bricks for Support -->
  </div>
</app-modal>