import {
  Input,
  Output,
  OnInit,
  Component,
  EventEmitter
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/_helpers/snackbar/snackbar.component';

type TagCloudData = {
  label: any;
  weight?: number;
  styleClass?: string;
};

@Component({
  selector: 'lib-tag-cloud',
  templateUrl: './tag-cloud.component.html',
  styleUrls: ['./tag-cloud.component.scss'],
})
export class TagCloudComponent implements OnInit {
  @Input() data: TagCloudData;
  @Input() type: 'cloud' | 'chips' = 'cloud';
  @Output() link = new EventEmitter<any>();
  private _snackBar: MatSnackBar;

  constructor(
  ) { }

  ngOnInit() {
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 5 * 1000,
    });
  }

  linkHandler(linkData: any) {
    // console.log(linkData);
    this.link.emit([linkData, this.data]);
  }
}
