import {
  OnInit,
  Component,
  OnDestroy
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { Config, View } from '../../config';
import { AlertService } from '../../_services/alert.service';
import { MyErrorStateMatcher } from '../../_directives/validator.directive';
@Component({
  selector: 'app-resetpwd',
  templateUrl: './resetpwd.component.html',
  styleUrls: ['./resetpwd.component.scss']
})
export class ResetpwdComponent implements OnInit, OnDestroy {
  returnUrl = '/';
  restUid = '';
  // captcha = false;
  isAdminUrl = false;
  url = 'sendemail/resetpwd';
  // validurl='sendemail/resetpwd/linkvalidate';
  matcher = new MyErrorStateMatcher();
  constructor(
    public view: View,
    public config: Config,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public alertService: AlertService
  ) {
    // this.captcha = false;
    this.view.initForm(this.url, this.getNewForm());
    //  this.validView.initForm(this.validurl, this.getLinkNewForm());
    this.returnUrl = this.route.snapshot.queryParams['reset'] || '/';
    if (this.route.snapshot.queryParamMap.get('reset')) {
      this.route.queryParams.subscribe(params => {
        // console.log(params.reset);
        this.view.set('resetid', params.reset);
        localStorage.setItem('resetid', params.reset);
        // this.validView.set('resetid', params.reset);
        // this.validView.state = 'edit';
        // this.validView.save(this.validurl);
        this.view.get('sendemail/resetpwd/linkvalidate/' + params.reset).subscribe(res => {
          this.sucessvalid();
          // this.router.navigate(['/resetpwd']);
        }, (err) => {
          this.handleError(err);
        });

      });
      // do your stuff. example: console.log('id: ', this.route.snapshot.queryParams['id']);
    } else {
      if (localStorage.length > 0) {
        // We have items
        // let myItem = localStorage.getItem('resetid');
        this.view.set('resetid', localStorage.getItem('resetid'));
      } else {
        // No items
      }
    }
  }
  sucessvalid() {
    this.router.navigate(['/resetpassword']);
  }
  handleError(err) {
    this.router.navigate(['/login']);
    // /android|webos|iphone|ipod/i.test(navigator.userAgent.toLowerCase()) &&
    this.alertService.error(
      err,
      true, 20 * 1000);

  }
  ngOnInit() {
    document.querySelector('body').classList.add('xsEnabled');
    document.querySelector('html').classList.add('xsEnabled');
  }

  ngOnDestroy() {
    document.querySelector('body').classList.remove('xsEnabled');
    document.querySelector('html').classList.remove('xsEnabled');
  }
  getLinkNewForm() {
    return this.fb.group({
      resetid: ['']
    });

  }
  getNewForm() {
    return this.fb.group({
      resetid: [''],
      inputPwd: ['', [
        Validators.required,
        Validators.minLength(6)]
      ],
      cpassword: ['']
      // plan: this.fb.group(this.view.get("plan/all"))
    }, { validators: this.checkPasswords });

  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.inputPwd.value;
    const confirmPass = group.controls.cpassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
  // Validators.pattern(AppPattern.EMAIL)
  onSubmit() {


    this.view.saveRedirect(
      `${this.url}`,
      '/login',
      'Your Password has been reset successfully.Please Login to continue.',
      'Your Reset Password link has expired!! Please try Forgot Password Again.'
    );
  }
  toggleDev2MOverview() {
    //this.config.dev2MOverview = !this.config.dev2MOverview;
    window.open("https://www.insiq.io/", "_blank");

  }
  //  showResponse(event) {
  //    this.captcha = true;
  //  }

  captchaValid() {
    // return (this.captcha);
  }
}
