import { SharedService } from './shared.service';
import { SharedProjectService } from '../project/sharedproject.service';
import { Injectable } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { View, Config, FormDefault } from '../../config/global.config';

import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KeyplayerView {
  keyplayerCategoryCodes: any;

  url = 'discoverykeyplayer';
  public view: View;
  // smartdev2m: boolean;
  importance: number;
  keyplayerlistAll;
//  keyplayerlistItem;
//  keyplayerlistItemDetail;

  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    private ss: SharedService,
    private ssp:SharedProjectService
    ) {
    this.view = new View(http, router, route, alertService);
    // this.smartdev2m = false;
    //  this.keyplayerName=""
  }

  setForm() {
  //  this.keyplayerlistAll = null;
    this.ss.setKeyPlayerView(this);
    this.view.initForm(this.url, this.getForm(), this.getNewDefault(), false);

    this.view.get('keyplayercategory/all').subscribe(res => {
      this.keyplayerCategoryCodes = res;
      //  for (let x = 0; x < res.length; x++) {
      //   let item = this.keyplayerCategoryCodes[x];
      //   item['value'] = item['id'];
      //  }
    });

    this.getList();
    this.newform();
    this.view.state = 'list';
    this.view.refreshList = false;
    Config.isProjectSelected = true;
  }

  getNewDefault() {
    return new FormDefault(['projectId'], [this.ssp.getProject()]);
  }

  newform() {
    // this.smartdev2m = false;
    this.view.newform();
  }

  save() {
    this.view.set('label', this.view.value('keyplayer'));
    this.view.save();
  }

  edit(row) {
    this.view.edit(row);
    // console.log(this.view.form.value);
    // if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'A') {
    // }
  }

  saveAndAdd() {
    this.view.set('label', this.view.value('keyplayer'));
    this.view.saveAndAdd();
  }

  cancel() {
//    this.keyplayerlist = null;
    this.getList();
    this.view.cancel();
  }

  getList() {
    this.view.get(`${this.url}/all/${this.ssp.getProjectId()}`).subscribe(res => {
        this.keyplayerlistAll = res;
        this.view.list = res;
    });
  }

  getForm() {
    return this.fb.group({
      id: [''],
      label: [''],
      description: ['', Validators.required],
      keyplayer: ['', Validators.required],
      keyplayerCategoryCode: ['', Validators.required],
      projectId: ['']
    });
  }
 filterKeyplayer(event) {
    this.view.list = this.keyplayerlistAll.filter(u => u.keyplayerCategoryCode.id === event.value.id);
  }
}
