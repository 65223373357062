import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Project } from '../../_models/project';
import { ConceptView } from './concept.view';
import { ObservationView } from './observation.view';
import { RequirementView } from './requirement.view';
import { RequirementTypeView } from './requirementtype.view';
import { RequirementGroupView } from './requirementgroup.view';
import { RequirementTestingView } from './requirement-testing.view';
import { AnalysisView } from './analysis.view';
import { FeatureView } from './feature.view';
import { FailureView } from './failure.view';
import { TestingView } from './testing.view';
import { RequirementFeatureView } from './requirement-feature.view';
import { RequirementFeatureProcessView } from './requirement-feature-process.view';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  ao: AnalysisView;
  co: ConceptView;
  rt: RequirementTypeView;
  ob: ObservationView;
  re: RequirementView;
  rg: RequirementGroupView;
  te: RequirementTestingView;
  fe: FeatureView;
  fa: FailureView;
  tv: TestingView;
  rf: RequirementFeatureView;
  rfp: RequirementFeatureProcessView;

  // requirementTypeCode: string;
  project: Project;
  projectId: number;
  projectCount: number;
  //  observationId: number;
  //  requirementId: number;
  analysis;
  keyPlayer;
  requirementType;
  requirement;
  requirementgroup;
  observation;
  failure;
  concept;
  requirementFeature;

  constructor(private http: HttpClient, private router: Router) {
  }

  setProject(project) {
    // tslint:disable-next-line:max-line-length
    this.project = new Project(project.id, project.productName, project.mode,
      project.productNewOrExisting, project.productDescription, project.productTypeId, project.customer, project.productBenifit, project.targetRegion, project.similarProduct, project.inspiration, project.brandName, project.competitors, project.competitorspdt,
      project.companyname, project.apiLanguage, project.problemSolve,
      project.productEnviornment,
      project.productChannel,
      project.prodnVol,
      project.manufacturer,
      project.productRegulation,
      project.sellRate, project.keyword31,
      project.keyword6,
      project.keyword10,
      project.keyword2,
      project.keyword32,
      project.keyword8,
      project.keyword11,
      project.keyword7,
      project.keyword5,
      project.keyword4,
      project.keyword9);
    localStorage.setItem('projectSelected', JSON.stringify(project));
    this.setProjectId(project.id);
  }
  getProject() {
    if (!this.projectId || this.projectId === null || this.projectId === undefined) {
      this.project = JSON.parse(localStorage.getItem('projectSelected'));
      if (!this.project || this.project === null || this.project === undefined) {
        this.router.navigate(['/']);
      }
      this.projectId = this.project.id;
    }
    return this.project;
  }

  setProjectId(id) {
    this.projectId = id;
  }
  getProjectId() {
    return this.getProject().id;
  }

  getConceptView(): ConceptView {
    return this.co;
  }
  setConceptView(co: ConceptView) {
    this.co = co;
  }

  getObservationView(): ObservationView {
    return this.ob;
  }
  setObservationView(ob: ObservationView) {
    this.ob = ob;
  }

  setRequirementTypeView(rt: RequirementTypeView) {
    this.rt = rt;
  }
  getRequirementTypeView() {
    return this.rt;
  }

  setRequirementGroupView(rg: RequirementGroupView) {
    this.rg = rg;
  }

  getRequirementGroupView() {
    return this.rg;
  }

  setRequirementView(re: RequirementView) {
    this.re = re;
  }
  setAnalysisView(ao: AnalysisView) {
    this.ao = ao;
  }

  getRequirementView() {
    return this.re;
  }
  getAnalysisView() {
    return this.ao;
  }

  setRequirementTestingView(te: RequirementTestingView) {
    this.te = te;
  }
  getRequirementTestingView() {
    return this.te;
  }

  setFeatureView(fe: FeatureView) {
    this.fe = fe;
  }
  getFeatureView() {
    return this.fe;
  }

  setFailureView(fa: FailureView) {
    this.fa = fa;
  }
  getFailureView() {
    return this.fa;
  }

  setTestingView(tv: TestingView) {
    this.tv = tv;
  }
  getTestingView() {
    return this.tv;
  }
  setRequirementFeatureProcessView(rfp: RequirementFeatureProcessView) {
    this.rfp = rfp;
  }
  getRequirementFeatureProcessView() {
    return this.rfp;
  }
  getRequirementFeatureView(): RequirementFeatureView {
    return this.rf;
  }
  setRequirementFeatureView(rf: RequirementFeatureView) {
    this.rf = rf;
  }
}
