import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Project } from '../../_models/project';
import { KeyplayerView } from './keyplayer.view';
import { ObservationView } from './observation.view';
import { RequirementView } from './requirement.view';
import { RequirementTypeView } from './requirementtype.view';
import { RequirementGroupView } from './requirementgroup.view';
import { RequirementTestingView } from './requirement-testing.view';
import { ProjectComponent } from '../project/project.component';
import { View } from 'src/app/config';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  kp: KeyplayerView;
  rt: RequirementTypeView;
  ob: ObservationView;
  re: RequirementView;
  rg: RequirementGroupView;
  te: RequirementTestingView;
 // pro: ProjectComponent;
  // requirementTypeCode: string;
//  project: Project;
//
//  private projectId: number;
//  projectCount: number;
  keyPlayer;
  requirementType;
  requirement;
  //  observationId: number;
  //  requirementId: number;

  // keyplayerId: number;

  // public requirementTypes = null;

  constructor(
//    private router: Router,
//    private view: View
    ) {
    // this.loadRequirementTypes();
    // JsonRequest.get(this.http, 'project/all', null).subscribe(res => {
    //   this.projectCount = res.length;
    //   if (res.length > 0) {
    //     this.setProjectId(null);
    //   }
    // });
  }

//  linEvHand(e) {
//    //  console.log(e);
//    const [link] = e;
//    if (link === 'new-project' && this.pro !== undefined) {
//      // this.view.state = (this.router.url === '/new-project') ? 'new' : 'list';
//      this.pro.newform();
//    } else if (link === 'project') {
//      if (this.router.url === '/project') {
//        this.view.state = 'list';
//      } else {
//        this.pro.newform();
//      }
//    }
//  }

//  setProject(project) {
//    this.project = new Project(
//      project.id,
//      project.productName,
//      project.productDescription,
//      project.productTypeId,
//      project.customer,
//      project.productBenifit,
//      project.targetRegion,
//      project.similarProduct,
//      project.inspiration,
//      project.brandName
//    );
//    localStorage.setItem('projectSelected', JSON.stringify(project));
//    this.setProjectId(project.id);
//  }
//  getProject() {
//    if (!this.projectId || this.projectId === null || this.projectId === undefined) {
//      this.project = JSON.parse(localStorage.getItem('projectSelected'));
//      if (!this.project || this.project === null || this.project === undefined) {
//        this.router.navigate(['/']);
//      }
//      this.projectId = this.project.id;
//    }
//    return this.project;
//  }
//
//  setProjectId(id) {
//    this.projectId = id;
//  }
//
//  getProjectId() {
//    return this.getProject().id;
//  }

  getKeyPlayerView(): KeyplayerView {
    return this.kp;
  }
  setKeyPlayerView(kp: KeyplayerView) {
    this.kp = kp;
  }

  getObservationView(): ObservationView {
    return this.ob;
  }
  setObservationView(ob: ObservationView) {
    this.ob = ob;
  }

  setRequirementTypeView(rt: RequirementTypeView) {
    this.rt = rt;
  }
  getRequirementTypeView() {
    return this.rt;
  }

  setRequirementGroupView(rg: RequirementGroupView) {
    this.rg = rg;
  }

  getRequirementGroupView() {
    return this.rg;
  }

  setRequirementView(re: RequirementView) {
    this.re = re;
  }
  getRequirementView() {
    return this.re;
  }

  setRequirementTestingView(te: RequirementTestingView) {
    this.te = te;
  }
  getRequirementTestingView() {
    return this.te;
  }
}
