import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ConceptView } from './concept.view';
import { Project } from '../../_models/project';
import { HttpClient } from '@angular/common/http';
import { RequirementView } from './requirement.view';
import { RequirementTypeView } from './requirementtype.view';
import { RequirementFeatureView } from './requirement-feature.view';
import { RequirementFeatureProcessView } from './requirement-feature-process.view';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  co: ConceptView;
  re: RequirementView;
  rt: RequirementTypeView;
  rf: RequirementFeatureView;
  rfp: RequirementFeatureProcessView;
  // requirementTypeCode: string;
  project: Project;

  projectId: number;
  projectCount: number;
  concept;
  keyPlayer;
  requirementType;
  requirement;
  requirementFeature;
  //  observationId: number;
  //  requirementId: number;

  // keyplayerId: number;

  // public requirementTypes = null;

  constructor(private http: HttpClient, private router: Router) {
    // this.loadRequirementTypes();
    // JsonRequest.get(this.http, 'project/all', null).subscribe(res => {
    //   this.projectCount = res.length;
    //   if (res.length > 0) {
    //     this.setProjectId(null);
    //   }
    // });
  }

  setProject(project) {
    this.project = new Project(
      project.id,
      project.productName,
      project.mode,
      project.productNewOrExisting,
      project.productDescription,
      project.productTypeId,
      project.customer,
      project.productBenifit,
      project.targetRegion,
      project.similarProduct,
      project.inspiration,
      project.brandName,
      project.competitors,
      project.competitorspdt,
      project.companyname,
      project.apiLanguage,
      project.problemSolve,
      project.productEnviornment,
      project.productChannel,
      project.prodnVol,
      project.manufacturer,
      project.productRegulation,
      project.sellRate,
      project.keyword31,
      project.keyword6,
      project.keyword10,
      project.keyword2,
      project.keyword32,
      project.keyword8,
      project.keyword11,
      project.keyword7,
      project.keyword5,
      project.keyword4,
      project.keyword9
    );
    localStorage.setItem('projectSelected', JSON.stringify(project));
    this.setProjectId(project.id);
  }
  getProject() {
    if (!this.projectId || this.projectId === null || this.projectId === undefined) {
      this.project = JSON.parse(localStorage.getItem('projectSelected'));
      if (!this.project || this.project === null || this.project === undefined) {
        this.router.navigate(['/']);
      }
      this.projectId = this.project.id;
      // if (this.projectCount > 0) {
      // } else {
      //   this.router.navigate(['/']);
      // }
    }
    return this.project;
  }

  setProjectId(id) {
    this.projectId = id;
  }
  getProjectId() {
    return this.getProject().id;
  }

  getConceptView(): ConceptView {
    return this.co;
  }
  setConceptView(co: ConceptView) {
    this.co = co;
  }

  getRequirementFeatureView(): RequirementFeatureView {
    return this.rf;
  }
  setRequirementFeatureView(rf: RequirementFeatureView) {
    this.rf = rf;
  }

  setRequirementTypeView(rt: RequirementTypeView) {
    this.rt = rt;
  }
  getRequirementTypeView() {
    return this.rt;
  }

  setRequirementView(re: RequirementView) {
    this.re = re;
  }
  getRequirementView() {
    return this.re;
  }

  setRequirementFeatureProcessView(rfp: RequirementFeatureProcessView) {
    this.rfp = rfp;
  }
  getRequirementFeatureProcessView() {
    return this.rfp;
  }
}
