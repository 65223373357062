<div class="d-block p-a-0">
  <h4 class="text-accent">
    <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" [mat-dialog-close]="true" title="Close">
      <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
    </button>
  </h4>
  <mat-card-header>
    <div mat-card-avatar class="b-a-1 t-c">
      <i class="i i-idea i-2_5x text-primary"></i>
    </div>
    <mat-card-title>InsIQ Shared Project List</mat-card-title>
    <mat-card-subtitle>Find below the list of Projects Shared to you from other InsIQ Users</mat-card-subtitle>
  </mat-card-header>

  <div class="row" *ngIf="projlist">
    <div class="projectCard m-t-15" *ngFor="let project of projlist">


      <mat-card>
        <mat-card-header class="b-b-1 m-b-15">
          <mat-card-title>


            <span>
              <i class="i ico text-accent" [ngClass]="{
                    'i-idea': project.productNewOrExisting==1,
                    'i-monitor_product': project.productNewOrExisting==2
                  }"></i>
            </span>
            <span class="m-l-10">{{project.brandName}}</span>

          </mat-card-title>
          <mat-card-actions>
            <!-- <button mat-icon-button color="accent" [matMenuTriggerFor]="projectMenu" title="Options"
                  matTooltipPosition="below">
                  <mat-icon fontSet="i" class="i-1_5x">turned_in</mat-icon>
                </button> -->

          </mat-card-actions>
        </mat-card-header>
        <mat-card-content>
          <!-- <mat-card-subtitle class="new" *ngIf="project.productNewOrExisting === 1">
                New
              </mat-card-subtitle>
              <mat-card-subtitle class="failure" *ngIf="project.productNewOrExisting === 2">
                Failure Analysis
              </mat-card-subtitle> -->
          <p>
            Shared By:
            <a href="javascript:;">{{project.projectOwner?.name}}</a>
          </p>
          <!-- <p>
                Users:
                <span *ngFor="let user of project.projectUserList; last as isLast">
                  <a href="javascript:;">{{user.userId?.name}}</a>
                  <span *ngIf="!isLast">, </span>
                </span>
              </p> -->
          <div class="description">
            {{getProjName(project)}}
          </div>

          <div class="col-md-24 t-c m-t-30">
            <button type="button" matRipple (click)="acceptProject(project)"
              class="btn btn-lg btn-primary m-x-5">Accept</button>
            <button type="button" matRipple class="btn btn-lg btn-danger m-x-5"
              (click)="rejectProject(project)">Reject</button>
          </div>
          <!-- <div class="progress-text t-r">23%</div>
              <div class="progress hang-value">
                <div class="progress-bar" role="progressbar" style="width: 23%" aria-valuenow="23" aria-valuemin="0"
                  aria-valuemax="100"></div>
              </div> -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>