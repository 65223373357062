import {
  Input,
  OnInit,
  Output,
  NgZone,
  Component,
  ViewChild,
  TemplateRef,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import {
  View,
  Config
} from 'src/app/config';
import {
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import * as XLSX from 'xlsx';
import { AIView } from '../ai/insight.view';
import { Paginator } from 'primeng/paginator';
import { CustomThemeService } from 'src/app/custom-theme.service';
import { NotesDialogComponent } from '../notesalerts/notes-dialog';
import { NavigationService } from 'projects/tag-cloud/src/lib/navigation-service';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { DeepdiveDialogComponent } from '../deepdive-dialog/deepdive-dialog.component';
import { environment } from 'src/environments/environment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-ai',
  templateUrl: './ai.component.html',
  styleUrls: ['./ai.component.scss']
})

export class AiComponent implements OnInit {
  public Editor = ClassicEditor;
  @Input() ai: any;
  @Output() linkEvent = new EventEmitter<any>();

  project: any;
  dashboard = true;
  costsTime = false;
  notesDialogRef: MatDialogRef<NotesDialogComponent, any>;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  croppedImage: SafeUrl;

  emojis = [
    {
      key: 'very_dissatisfied',
      value: 'negative'
    },
    {
      key: 'neutral',
      value: 'neutral'
    },
    {
      key: 'very_satisfied',
      value: 'positive'
    },
  ];

  apiLanguage: string | any[];
  cols: { field: string; header: string; }[];
  colsFav: { field: string; header: string; }[];
  //paginator: any; // You can replace 'any' with the actual type of your paginator
  colsNotes: { field: string; header: string; }[];
  pricingCols: { field: string; header: string; }[];
  turbocols: { field: string; header: string; }[];
  selectedLanguage: any;
  slicedData: any[] = [];

  @ViewChild('matTabs') matTabs: MatTabGroup;
  @ViewChild(Paginator, { static: true }) paginator1: Paginator; // Ensure the correct import and use static
  @ViewChild('secondDialog', { static: true }) STAT_POPUP: TemplateRef<any>;
  @ViewChild('aiChatScrollViewer', { static: true }) aiChatScrollViewer: any;

  // @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;
  // @ViewChild('yourTable') yourTable: Table;
  // @ViewChild('yourPaginator') yourPaginator: Paginator;

  constructor(
    public view: View,
    public aiv: AIView,
    public config: Config,
    private router: Router,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private navigationService: NavigationService,
    public customThemeService: CustomThemeService
  ) {

    this.project = JSON.parse(localStorage.getItem('projectSelected'));
    aiv.setConfig(this.config);
    // changeDetectorRef.detach();

    setTimeout(() => {
      if (this.aiChatScrollViewer) {
        aiv.aiChatScrollViewer = this.aiChatScrollViewer.nativeElement
      }
    }, 0);
  }


  first1: number = 0;

  rows1: number = 8;

  first2: number = 0;

  rows2: number = 10;

  first3: number = 0;

  rows3: number = 10;

  totalRecords: number = 120;
  first = 0;
  rows = 8;
  options = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 120, value: 120 }
  ];
  onPageChange(event: any) {
    this.first = event.first;
  }
  onPageChange1(event: PageEvent) {
    this.first1 = event.first;
    this.rows1 = event.rows;
  }
  onPageChange2(event: PageEvent) {
    this.first2 = event.first;
    this.rows2 = event.rows;
  }

  onPageChange3(event: PageEvent) {
    this.first3 = event.first;
    this.rows3 = event.rows;
  }
  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      if (this.paginator1) {
        this.paginator1.first = 0;
        this.paginator1.rows = 8;
        this.sliceData();

        this.ngZone.run(() => {
          this.cdr.detectChanges();

          setTimeout(() => {
          });
        });
      }
    });

    // this.aiv.scrollTo({
    //   parent: this.aiChatScrollViewer.nativeElement,
    //   child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
    // });
  }

  sliceData() {
    const startIndex = this.paginator1.first;
    const endIndex = startIndex + this.paginator1.rows;
    this.slicedData = this.ai.smartlistItemDetail.slice(startIndex, endIndex);
    // console.log(this.sliceData)
  }

  toggleGrid() {
    if (!this.ai.listViewInsight) {
      //   console.log('hereeee')
      //  this.hidePaginator = !this.hidePaginator;
      if (this.paginator1) {
        this.paginator1.first = 0;
        this.paginator1.rows = 8;
        this.paginator1.changePage(0);

        this.cdr.detectChanges(); this.sliceData();
      }
    }
  }
  get slicedSmartlistItems(): any[] {
    return this.ai.smartlistItemDetail.slice(this.paginator1?.first, this.paginator1?.first + this.paginator1?.rows);
  }
  ngOnInit() {
    this.pricingCols = [
      { field: 'actions', header: 'Actions' },

      { field: 'title', header: 'Title' },
      { field: 'price', header: 'Price' },
      { field: 'description', header: 'Description' },
      { field: 'keywords', header: 'Keywords' },
      { field: 'date', header: 'Date' }
    ];
    this.cols = [
      { field: 'actions', header: 'Actions' },
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'keywords', header: 'Keywords' },
      { field: 'date', header: 'Date' }
    ];
    this.turbocols = [

      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'keywords', header: 'Keywords' },
      { field: 'date', header: 'Date' }
    ];
    this.colsFav = [
      { field: 'actions', header: 'Actions' },
      { field: 'category', header: 'Category' },
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'keywords', header: 'Keywords' },

    ];
    this.colsNotes = [
      { field: 'actions', header: 'Actions' },
      { field: 'category', header: 'Category' },
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'keywords', header: 'Keywords' },
      { field: 'notes', header: 'Notes' }
    ];
    this.view.get('apilanguage/all').subscribe(res => {
      this.apiLanguage = res;
      for (let i = 0; i < this.apiLanguage.length; i++) {
        if (this.apiLanguage[i].defaultFlag === 1) {
          // if (!this.view.value('apiLanguage')) {
          //   console.log('founded');
          this.view.set('apiLanguage', this.apiLanguage[i].languageCode);
          localStorage.setItem('apiLanguage', this.apiLanguage[i].languageCode);
          // }
        }
      }

      this.selectedLanguage = this.view.value('apiLanguage');
      // console.log('init',this.view.value('apiLanguage'));

    });
  }
  openLinkInNewTab(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
  getcols() {
    return this.cols;
  }
  truncateText(inputText: string): string {
    const maxLength = 50;

    if (inputText?.length <= maxLength) {
      return inputText;
    } else {
      return inputText?.substring(0, maxLength) + '...';
    }
  }
  splitKeywordsAndDescription(aiText: any): { keywords: string; description: string } {
    if (!aiText) {
      return { keywords: '', description: '' };
    }

    const splitText = aiText.split('<br /><br />');
    const keywords = splitText[0].replace('Keywords: ', '').trim(); // Trim to remove leading and trailing spaces
    const description = splitText[1] || '';
    return { keywords, description };
  }

  splitTextPricing(text: string) {
    const regex = /Keywords:\s*([^<]*)<br\s*\/>\s*(.*)/i;
    const match = text.match(regex);

    if (match) {
      const keywords = match[1].trim();
      const description = match[2].trim();
      return {
        keywords: keywords,
        description: description
      };
    } else {
      return {
        keywords: '',
        description: text.trim()
      };
    }
  }

  splitKeywordsAndTitle(aiText: any): { keywords: string; title: string } {
    if (!aiText) {
      return { keywords: '', title: '' };
    }

    const splitText = aiText.split('<br />');
    const keywords = splitText[1]?.replace('Keywords: ', '').trim(); // Trim to remove leading and trailing spaces
    const title = splitText[0] || '';
    return { keywords, title };
  }


  transformSafe(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  transform(url: string) {
    //  console.log(url);
    if (!url) { return '/'; }
    url = url.replace('/watch?v=', '/embed/');
    // console.log(url);
    return url; // this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  linkClick(s: any) {
    localStorage.setItem('projLoc', "homeUp");

    // console.log('jjjjjjjj')
    this.linkEvent.emit([s]);
    this.view.state = 'list';

    // window.location.href = '/project';
    this.navigationService.triggerOnInit();
    this.router.navigate(['/project']);
    localStorage.removeItem('projRouted')
    // localStorage.removeItem('projLoc');
  }

  getborder(deep: string): string {
    if (deep === 'Yes') {
      return 'b-accent';
    } else if (deep === 'No') {
      return 'unknownbd';
    } else if (deep === null || typeof deep === 'undefined') {
      return 'unknownbd';
    } else {
      return 'b-primary';
    }
  }

  cancelNote() {
    this.notesDialogRef.close();
  }
  saveNote() {
    this.aiv.saveNotedeep();
    this.notesDialogRef.close();
  }

  DeleteNote() {
    this.aiv.deleteNotedeep();
    this.notesDialogRef.close();
  }
  saveNotems() {
    this.aiv.saveNotemsdeep();
    this.notesDialogRef.close();
  }
  getProjName(project: any) {

    let projName: any;
    if (project.productNewOrExisting === 1) {
      projName = project.brandName || project.productName;
    } else if (project.productNewOrExisting === 2) {
      projName = project.brandName;
    }
    return projName;
  }
  navigateToNewProject() {
    this.navigationService.triggerOnInit();
    localStorage.removeItem('projRouted')
    localStorage.removeItem('projLoc');
    localStorage.setItem('newT', 'true');

    this.router.navigate(['/new-project']);
  }
  getClass(s: string, title: string | string[]) {

    try {
      if (s === undefined) {
        return 'b2b_star';
      } else {
        switch (s.trim().toLowerCase()) {
          case '2':
            return 'product';
          case '3':
            return 'trends';
          case '31':
            return 'trends';
          case '32':
            return 'trends';
          case '10':
            return 'news';
          case '5':
            return 'factory';
          case '8':
            return 'professional';
          case '6':
            return 'review';
          case '7':
            return 'regulatory';
          case '4':
            if (title.includes('Trademark')) {
              return 'tm';
            } else {
              return 'patent';
            }
          case '11':
          case 'research':
            return 'research';
          case '111':
          case 'deep_dive':
            return 'deep_dive';

          case '9':
            return 'b2b';
          default:
            return 'b2b';
        }
      }
    } catch (exception) {
    }
  }
  testfn(row: { noteAdd: any; }) {
    // console.log(row);
    // console.log(row.aiNotes);
    this.aiv.notesRow = row;
    this.aiv.view.set('notes', row.noteAdd);
    // console.log(this.STAT_POPUP);
    this.notesDialogRef = this.dialog.open(NotesDialogComponent, {
      data: this.STAT_POPUP
    });
  }

  deeppopuup() {
    console.log('AI COMPO POPUP');
    console.log(this.STAT_POPUP);
    this.dialog.open(DeepdiveDialogComponent, {
      width: '1100px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: this.STAT_POPUP
    });
  }
  getIcon(s: string, title: string | string[]) {
    //  console.log(s);
    // console.log(title);
    // console.log(s.toLowerCase());
    try {
      if (s === undefined) {
        return 'search';
      } else {
        switch (s.trim().toLowerCase()) {
          case '1':
            return 'trending_up';
            break;
          case '2':
            if (title.includes('Curated')) {
              return 'product_box_star';
            } else {
              return 'product_box';
            }
            break;
          case '3':
            if (title.includes('Curated')) {
              return 'trends_star';
            } else {
              return 'trends';
            }
            break;
          case '31':
            if (title.includes('Curated')) {
              return 'trends_star';
            } else {
              return 'trends';
            }
            break;
          case '32':
            if (title.includes('Curated')) {
              return 'trends_star';
            } else {
              return 'trends';
            }
            break;
          case '10':
            if (title.includes('Curated')) {
              return 'news_star';
            } else {
              return 'news';
            }
            break;
          case '5':
            if (title.includes('Curated')) {
              return 'factory_star';
            } else {
              return 'factory';
            }
            break;
          case '8':
            if (title.includes('Curated')) {
              return 'profession`al_star';
            } else {
              return 'professional';
            }
            break;
          case '6':
            if (title.includes('Curated')) {
              return 'review_star';
            } else {
              return 'review';
            }
            break;
          case '11':
            return 'research';
            break;
          case '101':
            return 'price_tag';
            break;
          case '111':
            return 'deep_dive';
            break;
          case 'deep dive':
            return 'deep_dive';
            break;
          case '7':
            if (title.includes('Curated')) {
              return 'regulatory_star';
            } else {
              return 'regulatory';
            }
            break;
          case '4':
            if (title.includes('Curated')) {
              return 'patent_star';
            } else if (title.includes('Trademark')) {
              return 'tm';
            } else {
              return 'patent';
            }
            break;
          case '9':
            if (title.includes('Curated')) {
              return 'b2b_star';
            } else {
              return 'b2b';
            }
            break;
          case 'deep_dive':
            return 'deep_dive';
            break;
          case 'research':
            return 'research';
            break;

          case 'trend insights':
            return 'trending_up';
            break;
          case 'sentiment analysis':
            return 'sentiment_very_satisfied';
            break;
          case 'average price':
            return 'price_tag';
            break;
          case '13':
            return 'sentiment_very_satisfied';
            break;
          case '12':
            return 'trending_up';
            break;
          case 'favourites':
            return 'star';
            break;
          case 'notes':
            return 'speaker_notes';
            break;
          case 'report':
            return 'doc';
            break;
          default:
            if (title.includes('Curated')) {
              return 'b2b_star';
            } else {
              return 'search';
            }
            break;
        }
      }
    } catch (exception) {
    }
  }

  getLabel() {

    let label: string;
    if (this.view.value('productNewOrExisting') === 1) {
      label = 'Consumer Information';
    } else if (this.view.value('productNewOrExisting') === 2) {
      label = 'Competitors';
    }

    return label;

  }
  getDeepcat(s: string) {

    try {
      if (s === '1') {
        return 'deep_dive';
      } else {
        switch (s.trim().toLowerCase()) {
          case '2':
            return 'similar products or projects';
          case '3':
            return this.getLabel();
          case '31':
            return 'Consumer Information';
          case '32':
            return 'Competitors';
          case '10':
            return 'product news';
          case '5':
            return 'supply chain';
          case '8':
            return 'professionals';
          case '6':
            return 'blogs and reviews';
          case '7':
            return 'product safety';
          case '4':
            return 'patents and trademarks';
          case '9':
            return 'b2b';
          case '11':
            return 'research';

          case '111':
            return 'deep_dive';
          default:
            return 'deep_dive';
        }
      }
    } catch (exception) {
    }
  }
  exportCommonExcel(aiText: any): void {
    const ws: XLSX.WorkSheet = null;
    // Convert the worksheet data to JSON
    const jsonData: any[] = XLSX.utils.sheet_to_json(ws, { header: 1 });

    // Remove the first column from each row
    const modifiedData = jsonData.map((row: any[]) => row.slice(1));

    // Add the headers back
    //modifiedData.unshift(jsonData[0]);

    // Create a new worksheet with the modified data
    const newWs: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedData);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, newWs, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'your-table-data.xlsx');

  }
  getTooltip(s: string, title: string | string[], flag: any) {
    // console.log(s);
    try {
      if (s === undefined) {
        return 'AI Insights';
      } else {
        switch (s.trim().toLowerCase()) {

          case '2':
            if (flag) {
              return 'Product Requirements';
            } else {
              if (title.includes('Curated')) {
                return 'Similar Products or Projects (InsIQ Curated)';
              } else {
                return 'Similar Products or Projects';
              }
            }
          case '3':
            if (flag) {
              return 'Test Methods';
            } else {
              if (title.includes('Curated')) {
                return this.ai.catName(s, this.view) + ' (InsIQ Curated)';
              } else {
                return this.ai.catName(s, this.view);
              }
            }
          case '31':
            if (flag) {
              return 'Test Methods';
            } else {
              if (title.includes('Curated')) {
                return 'Consumer Insights' + ' (InsIQ Curated)';
              } else {
                return 'Consumer Insights';
              }
            }
          case '32':
            if (flag) {
              return 'Test Methods';
            } else {
              if (title.includes('Curated')) {
                return 'Competitors' + ' (InsIQ Curated)';
              } else {
                return 'Competitors';
              }
            }
          case '10':
            if (flag) {
              return 'Product Requirements';
            } else {
              if (title.includes('Curated')) {
                return 'Product News (InsIQ Curated)';
              } else {
                return 'Product News';
              }
            }
          case '5':
            if (flag) {
              return 'Manufacturing Processes';
            } else {
              if (title.includes('Curated')) {
                return 'Suppliers, logistics and related (InsIQ Curated)';
              } else {
                return 'Suppliers, logistics and related ';
              }
            }
          case '8':
            if (flag) {
              return 'Product Requirements';
            } else {
              if (title.includes('Curated')) {
                return 'Influencers & Experts (InsIQ Curated)';
              } else {
                return 'Influencers & Experts';
              }
            }
          case '6':
            if (flag) {
              return 'Intellectual Property';
            } else {
              if (title.includes('Curated')) {
                return 'Blogs and Reviews (InsIQ Curated)';
              } else {
                return 'Blogs and Reviews';
              }
            }
          case '7':
            if (flag) {
              return 'Product Pricing';
            } else {
              if (title.includes('Curated')) {
                return 'Product Safety & Regulatory (InsIQ Curated)';
              } else {
                return 'Product Safety & Regulatory';
              }
            }
          case '4':
            if (flag) {
              return 'Design Features';
            } else {
              if (title.includes('Trademark')) {
                return 'Trademark';
              } else if (title.includes('Curated')) {
                return 'Patents & Trademarks (InsIQ Curated)';
              } else {
                return 'Patents & Trademarks';
              }
            }
          case '11':
            if (flag) {
              return 'Scientific Research';
            } else {
              if (title.includes('Curated')) {
                return 'Scientific Research &Tech (InsIQ Curated)';
              } else {
                return 'Scientific Research &Tech';
              }
            }

          case '111':

            if (title.includes('head')) {
              return 'All Insights';
            } else {
              return 'Deep Dive';
            }
          case '101':


            return 'Pricing Insights';

          case 'deep_dive':
            if (title.includes('head')) {
              return 'All Insights';
            } else {
              return 'Deep Dive';
            }
          case 'trend insights':
            return 'Trend Insight';
          case 'Product and Competitor sentiments':
            return 'Product and Competitor sentiment';
          case 'average price':
            return 'Pricing Insights';
          case '12':
            return 'Trend Insight';
          case '13':
            return 'Product and Competitor sentiment';
          default:
            return 'Analyze your data';
        }
      }
    } catch (exception) {
    }
  }

  fbshare(e: { preventDefault: () => void; }, urls: string) {
    e.preventDefault();
    const facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + urls
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }
  linkedinshre(e: { preventDefault: () => void; }, urls: string, aiTitle: string) {
    const url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + urls + '&title=' + aiTitle + 'For more info: https://www.insiq.io.' + '&summary=My%20favorite%20developer%20program&source=insiq';
    e.preventDefault();
    const linkedinWindow = window.open(url);
    if (linkedinWindow.focus) {
      linkedinWindow.focus();
    }
    return false;
  }

  twittershare(e: { preventDefault: () => void; }, urls: string) {
    const text = 'InsIQ';
    const hashtag = 'insiq';
    e.preventDefault();
    const twitterWindow = window.open(
      'http://twitter.com/share?text=' + text + 'For more info: https://www.insiq.io.' + '&url=' + urls + '&hashtags=' + hashtag
    );
    if (twitterWindow.focus) {
      twitterWindow.focus();
    }
    return false;
  }
  watzappshare(e: { preventDefault: () => void; }, urls: string, aiTitle: string) {
    e.preventDefault();
    const watzappWindow = window.open(
      'https://web.whatsapp.com/send?text=InsIQ AI Insight:  ' + aiTitle + '  Visit : ' + urls + ' for more info  #insiqai,#insiq,#aipowered,#devtominsights. For more info: https://www.insiq.io'
    );
    if (watzappWindow.focus) {
      watzappWindow.focus();
    }
    return false;
  }
  txtchange(bn: string) {

    const count = (bn.match(/,/g) || []).length;
    if (count > 2) {

      this.view.alertService.error('Only Max. of 3 competitors allowed!');
      return false;
    }
  }
  trimtooltip(noteAdd: string) {
    try {
      if (noteAdd.length > 5) {
        return noteAdd.split(' ').slice(0, 5).join(' ') + '...';
      } else {
        return noteAdd;
      }
    } catch (exception) {
    }
  }

  private base64ToBlob(base64Data: string): Blob {
    const byteString = atob(base64Data);
    const mimeString = 'image/png'; // Adjust according to the image type

    // Write the bytes of the string to an ArrayBuffer
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    // Create Blob from ArrayBuffer and mime type
    return new Blob([arrayBuffer], { type: mimeString });
  }

  onMlInputChange(event: any) {
    //console.log(event.target.value);
  }

  alignInkBar() {
    setTimeout(() => {
      this.matTabs.realignInkBar();
    }, 0);
  }

  onTabChange(event: any, newMessageFC: any) {
    // console.log(event.index);
    this.alignInkBar();
    this.ai.tabChanged(event, newMessageFC);
  }
}
