import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { View, Config, FormDefault } from '../../config';
import { SharedService } from './shared.service';
import { SharedProjectService } from '../project/sharedproject.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';

@Injectable({
  providedIn: 'root'
})
export class RequirementView {
  requirementTypeItemCodes: any = null;
  requirementTypes;

  // requirementTypeCode; any;
  url = 'discoveryrequirement';
  uploadurl = Config.apiUrl + '/upload';
  uploadedFiles = [];
  selectedTab: number;

  public view: View;
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    public ss: SharedService,
    private ssp:SharedProjectService) {
    this.view = new View(http, router, route, alertService);
    this.ss.setRequirementView(this);
    this.view.refreshList = false;
    this.setForm();
    this.selectedTab = 0;
  }

  setForm() {
    this.initForm();
    this.view.edit = ((row) => {
      this.ss.getRequirementView().edit(row);
    });
    this.requirementTypes = null;
    this.view.state = 'list';
  }
  initForm() {
    this.view.initForm(this.url, this.getForm(), this.getNewDefault(), false);
    Config.isProjectSelected = true;
  }
  refData() {
    this.ss.getObservationView().loadObservations();
    this.view.get(`requirementtypeitem/all/${this.ss.requirementType.id}`).subscribe(res => {
      this.requirementTypeItemCodes = res;
      this.rtItemEnabled();
    });
  }

  getNewDefault() {
    if (this.ss.getRequirementTypeView() == null) {
      return null;
    }
    return new FormDefault(
      ['projectId', 'keyplayerId', 'requirementTypeCode'],
      [
        this.ssp.getProject(),
        this.ss.keyPlayer,
        this.ss.requirementType
      ]);
  }

  newform(load = true) {
    this.refData();
    if (load) {
      this.loadRt();
    }
    this.view.newform();
  }
  private loadRt() {
    this.view.get(`requirementtype/observation/${this.ssp.getProjectId()}/${this.ss.keyPlayer.id}`).subscribe(res => {
      this.requirementTypes = res;
      for (let x = 0; x < this.requirementTypes.length; x++) {
        let item = this.requirementTypes[x];
        item['count'] = 1; //to make values same as ss.requirementType
      }
    });
  }
  edit(row) {
    this.selectedTab = 1;
    //    this.view.get(`requirementtype/all`).subscribe(res => {
    //      this.requirementTypes = res;
    //    });
    this.loadRt();
    this.refData();
    this.view.reset();
    for (const item of this.view.formFields) {
      this.view.field(item).setValue(row[item]);
    }
    this.view.state = 'edit';
    this.view.active = row;
  }

  openRequirementTab() {
    this.selectedTab = 1;
    this.initForm();
    this.newform();
  }

  onUpload(event) {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  save() {
    if (this.isFormValid()) {
      this.view.save();
    }
  }

  saveAndAdd() {
    if (this.isFormValid()) {
      this.view.saveAndAdd();
    }
  }

  cancel() {
    this.ss.getRequirementTypeView().getList();
    this.getList();
    this.view.cancel();
    this.selectedTab = 0;
  }

  getList() {
    this.view.get(`${this.url}/all/${this.ssp.getProjectId()}/${this.ss.keyPlayer.id}/${this.ss.requirementType.id}`).subscribe(res => {
      this.view.list = res;
    });
  }

  getForm() {
    return this.fb.group({
      id: [''],
      requirement: ['', Validators.required],
      label: [''],
      rationalisation: [''],
      note: [''],
      importanceId: ['', Validators.required],
      requirementTypeCode: ['', Validators.required],
      requirementTypeItemCode: [''],
      keyplayerId: ['', Validators.required],
      projectId: ['', Validators.required]
    });
  }

  hasObservation() {
    const obs = this.ss.getObservationView().observations;
    return (obs && obs.length > 0);
  }

  private isFormValid() {
    if (this.rtEnabled && this.view.value('requirementTypeItemCode') === null) {
      this.view.alertService.error('Please select a Requirement Sub-Type');
      return false;
    } else {
      this.view.set('label', this.view.value('requirement'));
      if (this.view.value('requirementTypeItemCode') === null) {
        if (this.requirementTypeItemCodes.length === 1) {
          this.view.set('requirementTypeItemCode', this.requirementTypeItemCodes[0]);
        }
      }
    }
    return true;
  }

  validAI() {
    return (!this.rtEnabled || this.view.value('requirementTypeItemCode') !== null);
  }

  rtEnabled = true;
  private rtItemEnabled() {
    if (this.requirementTypeItemCodes.length === 1) {
      this.rtEnabled = !(this.requirementTypeItemCodes[0].description === '');
    } else {
      this.rtEnabled = true;
    }
  }
}
