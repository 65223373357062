<div class="savedChats">
  <div class="titlebar">
    <div class="title">Saved Chats</div>
    <button class="closeBtn btn btn-dark btn-link" [mat-dialog-close]="true" title="Close">
      <i class="i i-close1" role="img" aria-hidden="true"></i>
    </button>
  </div>
  <div class="t-c p-y-30" *ngIf="getKeys(groupedData)?.length === 0 || !getKeys(groupedData)">
    <img src="./assets/images/norecordfound.png" alt="Your list is empty!">
    <h5 class="h1 text-accent m-t-30 m-b-0">No Saved Messages found!!!</h5>
  </div>
  <ng-container *ngFor="let dtsequence of getKeys(groupedData)">
    <div class="msgGroup">
      <!-- Display the content of the first dataset in the question div -->
      <div class="question">{{ groupedData[dtsequence][0].content }}</div>

      <!-- Display the content of the subsequent datasets in the card content -->
      <mat-card class="chatMessage" *ngFor="let row of groupedData[dtsequence].slice(1)"
        [class.mat-elevation-z8]="true">
        <mat-card-content>
          <div [innerHTML]="row.content"></div>
        </mat-card-content>
        <mat-card-actions class="b-t-1">
          <ul class="buttonWrap">
            <li>
              <button class="btn btn-danger outline rounded" type="button" matRipple (click)="deleteChat(row)">
                <i class="i i-delete"></i>
                <span>Remove</span>
              </button>
            </li>
            <li>
              <button class="btn btn-primary outline rounded" type="button" matRipple
                (click)="ai.copyText(ai.htmlToListText(row.content))">
                <i class="i i-copy"></i>
                <span>Copy</span>
              </button>
            </li>
          </ul>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>


</div>