<div class="container-fluid d-flex h-100">
  <div class="wrapper" [ngClass]="!ai.display ? 'active' : 'hidden'">
    <app-sub-header [data]="header" (aiEvent1)="ai.smartDev(1,kp.view, ssp.getProject())"  (aiEvent2)="ai.smartDev(2,kp.view, ssp.getProject())"  (aiEvent3)="ai.smartDev(3,kp.view, ssp.getProject())"  (aiEvent4)="ai.smartDev(4,kp.view, ssp.getProject())"
      (aiFav)="ai.wishList(kp.view, ssp.getProject(), '1')"></app-sub-header>

    <mat-tab-group mat-stretch-tabs class="pageTabs mat-elevation-z4 w-100" [ngClass]="!ai.display ? 'active' : 'hidden'">

      <mat-tab label="Overview">
        <div class="conArea">
          <ol class="dashboard">
            <li class="merge-2 p-x-0 b-r-0">
              <h1 class="caption">Planning</h1>
              <ol class="divisions">
                <li>
                  <h2 class="caption">Discovery</h2>
                  <ul class="tree-cards disabled o-30">
                    <li class="type-theme">
                      <a [routerLink]="['/discovery']">
                        <figure>
                          <i class="i i-stakeholder"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                aria-valuemax="100" style="width: 100%;"></div>
                            </div>
                            <span class="value">100%</span> -->
                          </div>
                          <div class="badge">{{data?.keyplayerCount}}</div>
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Keyplayer</div>
                        </div>
                      </a>
                    </li>
                    <li class="type-theme">
                      <a [routerLink]="['/discovery']">
                        <figure>
                          <i class="i i-interview"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                aria-valuemax="75" style="width: 75%;"></div>
                            </div>
                            <span class="value">75%</span> -->
                          </div>
                          <div class="badge">{{data?.observationCount}}</div>
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Insights</div>
                        </div>
                      </a>
                    </li>
                    <li class="type-theme">
                      <a [routerLink]="['/discovery']">
                        <figure>
                          <i class="i i-requirement"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                                aria-valuemax="50" style="width: 50%;"></div>
                            </div>
                            <span class="value">50%</span> -->
                          </div>
                          <div class="badge">{{data?.requirementCount}}</div>
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Requirements</div>
                        </div>
                      </a>
                    </li>
                    <li class="type-theme">
                      <a [routerLink]="['/discovery']">
                        <figure>
                          <i class="i i-testing"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                                aria-valuemax="40" style="width: 40%;"></div>
                            </div>
                            <span class="percentage">40%</span> -->
                          </div>
                          <div class="badge">{{data?.testingCount}}</div>
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Testing</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 class="caption">Concept</h2>
                  <div class="card t-c connected" *ngIf="data?.concept.length > 0">
                    <div *ngFor="let dc of data?.concept | slice:page.from:page.to; index as i">
                      <div class="cardHeader materialBg">{{dc}}</div>
                      <div class="cardContent p-a-0">
                        <ul class="tree-cards disabled o-30">
                          <li class="type-theme">
                            <a [routerLink]="['/concept']">
                              <figure>
                                <i class="i i-concept"></i>
                                <div class="percentage">
                                  <!-- <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                      aria-valuemax="100" style="width: 100%;"></div>
                                  </div>
                                  <span class="percentage">100%</span> -->
                                </div>
                              </figure>
                              <div class="cardFooter">
                                <span class="title">Design</span>
                              </div>
                            </a>
                          </li>
                          <li class="type-theme">
                            <a [routerLink]="['/concept']">
                              <figure>
                                <i class="i i-process"></i>
                                <div class="percentage">
                                  <!-- <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                      aria-valuemax="75" style="width: 75%;"></div>
                                  </div>
                                  <span class="percentage">75%</span> -->
                                </div>
                              </figure>
                              <div class="cardFooter">
                                <span class="title">Process</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="cardFooter b-t-1 p-a-0">
                      <ul class="controls">
                        <li>
                          <a href="javascript:;" class="btn btn-mtl btn-link btn-primary" (click)="prev()">
                            <i class="i i-keyboard_arrow_left i-2x"></i>
                          </a>
                        </li>
                        <li>{{page.from + 1}} / {{page.total}}</li>
                        <li>
                          <a href="javascript:;" class="btn btn-mtl btn-link btn-primary" (click)="next()">
                            <i class="i i-keyboard_arrow_right i-2x"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Concept Not Yet Created -->
                  <div class="card connected disabled" *ngIf="data?.concept.length <= 0">
                    <div class="cardHeader materialBg">Not Yet Created! 😕</div>
                    <div class="cardContent p-a-0">
                      <ul class="tree-cards">
                        <li class="type-theme o-30">
                          <a href="javascript:;">
                            <figure>
                              <i class="i i-concept"></i>
                              <div class="percentage">
                                <!-- <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                      aria-valuemax="100" style="width: 100%;"></div>
                                  </div>
                                  <span class="percentage">100%</span> -->
                              </div>
                            </figure>
                            <div class="cardFooter">
                              <span class="title">Design</span>
                            </div>
                          </a>
                        </li>
                        <li class="type-theme o-30">
                          <a href="javascript:;">
                            <figure>
                              <i class="i i-process"></i>
                              <div class="percentage">
                                <!-- <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                      aria-valuemax="75" style="width: 75%;"></div>
                                  </div>
                                  <span class="percentage">75%</span> -->
                              </div>
                            </figure>
                            <div class="cardFooter">
                              <span class="title">Process</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="cardFooter b-t-1 p-a-0 o-30">
                      <ul class="controls">
                        <li>
                          <a href="javascript:;" class="btn btn-mtl btn-link btn-primary" (click)="prev()">
                            <i class="i i-keyboard_arrow_left i-2x"></i>
                          </a>
                        </li>
                        <li>0 / 0</li>
                        <li>
                          <a href="javascript:;" class="btn btn-mtl btn-link btn-primary" (click)="next()">
                            <i class="i i-keyboard_arrow_right i-2x"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ol>
            </li>
            <li class="">
              <h1 class="caption">Execution</h1>
              <ol class="o-30 disabled">
                <li>
                  <h2 class="caption node connected">
                    <span>{{designConcept}}</span>
                  </h2>
                  <ul class="tree-cards">
                    <li class="type-execution">
                      <a [routerLink]="['/risk-analysis']">
                        <figure>
                          <i class="i i-riskAnalysis"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                aria-valuemax="100" style="width: 100%;"></div>
                            </div>
                            <span class="value">100%</span> -->
                          </div>
                          <div class="status top-left">
                            <div class="title">Features</div>
                            <div class="numeric">
                              <span class="cell">
                                <i class="i i-flag text-muted"></i>
                              </span>
                              <span class="cell">0</span>
                            </div>
                          </div>
                          <div class="status top-right">
                            <div class="title">Process</div>
                            <div class="numeric">
                              <span class="cell">
                                <i class="i i-flag text-warning"></i>
                              </span>
                              <span class="cell">0</span>
                            </div>
                          </div>
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Risk Analysis</div>
                        </div>
                      </a>
                    </li>
                    <li class="type-execution">
                      <a [routerLink]="['/detailed-design']">
                        <figure>
                          <i class="i i-detaildDesign"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                aria-valuemax="75" style="width: 75%;"></div>
                            </div>
                            <span class="value">75%</span> -->
                          </div>
                          <!-- <div class="badge">8</div> -->
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Detailed Design</div>
                        </div>
                      </a>
                    </li>
                    <li class="type-execution">
                      <a [routerLink]="['/virtual-testing']">
                        <figure>
                          <i class="i i-virtualTesting"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                                aria-valuemax="50" style="width: 50%;"></div>
                            </div>
                            <span class="value">50%</span> -->
                          </div>
                          <!-- <div class="badge">8</div> -->
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Virtual Testing</div>
                        </div>
                      </a>
                    </li>
                    <li class="type-execution">
                      <a [routerLink]="['/prototype']">
                        <figure>
                          <i class="i i-prototype"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                                aria-valuemax="40" style="width: 40%;"></div>
                            </div>
                            <span class="value">40%</span> -->
                          </div>
                          <!-- <div class="badge">8</div> -->
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Prototyping</div>
                        </div>
                      </a>
                    </li>
                    <li class="type-execution">
                      <a [routerLink]="['/product']">
                        <figure>
                          <i class="i i-product"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                                aria-valuemax="40" style="width: 40%;"></div>
                            </div>
                            <span class="value">40%</span> -->
                          </div>
                          <!-- <div class="badge">8</div> -->
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Product Build</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li class="">
              <h2 class="caption">Review</h2>
              <ol class="o-30 disabled">
                <li>
                  <h2 class="caption node">
                    <span></span>
                  </h2>
                  <ul class="tree-cards">
                    <li class="type-review">
                      <a [routerLink]="['/feedback']">
                        <figure>
                          <i class="i i-feedback"></i>
                          <div class="percentage">
                            <!-- <div class="progress">
                              <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                aria-valuemax="100" style="width: 100%;"></div>
                            </div>
                            <span class="value">100%</span> -->
                          </div>
                          <div class="badge">3</div>
                        </figure>
                        <div class="cardFooter">
                          <div class="title">Feedback</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </mat-tab>
      <mat-tab label="Messages" [disabled]="true">
        <div class="conArea">
          Under Construction...
        </div>
      </mat-tab>
      <mat-tab label="Files" [disabled]="true">
        <div class="conArea">
          Under Construction...
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="wrapper" [ngClass]="ai.display ? 'active' : 'hidden'">
    <app-ai [ai]="ai" class="w-100"></app-ai>
  </div>
</div>