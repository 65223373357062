import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-reportviewer',
  templateUrl: './reportviewer.component.html',
  styleUrls: ['./reportviewer.component.scss']
})
export class ReportviewerComponent {
  public Editor = ClassicEditor;
  public editorForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ReportviewerComponent>
  ) {
    this.editorForm = this.fb.group({
      content: ['']
    });
    this.editorForm.patchValue({ content: this.data.content });
  }

  backtoPage(): void {
    this.dialogRef.close();
  }

  downloadContent() {
    const htmlContent = this.editorForm.get('content')?.value;
    const element = document.createElement('a');
    const file = new Blob([htmlContent], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    element.href = URL.createObjectURL(file);
    element.download = this.data.title + '.docx';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.dialogRef.close();
  }
}
