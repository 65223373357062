import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appFocusNext]'
})
export class FocusNextDirective {
  @Input() appFocusNext: any;
  @Input() blanks: boolean;
  // focusables = document.querySelectorAll('INPUT, SELECT, BUTTON, TEXTAREA');
  constructor() {
    this.blanks = (this.blanks) ? this.blanks : true;
  }
  @HostListener('keydown', ['$event']) onKeyDown(e: any) {
    if (e.which === 13 || e.keyCode === 13) {
      e.preventDefault();
      // console.log(this.appFocusNext);
      if (this.appFocusNext === undefined) {
        return;
      } else if (this.appFocusNext.focus === false) {
        return;
      }
      if (this.blanks) {
        this.appFocusNext.focus();
      } else {
        if (e.target.value.trim() !== '') {
          this.appFocusNext.focus();
        }
        return;
      }
    }
  }

  // valid(control = null) {
  //   console.log(control.form);
  //   if (control !== null) {
  //     const field = control.form.controls[control];
  //     return ((control.form.touched || control.form.dirty) && (field.errors !== null)) ? false : true;
  //   }
  //   return control.form.valid;
  // }

  // private isFocusable(control: any) {
  //   return (!control.hidden) &&
  //     (control.nodeName === 'INPUT' ||
  //       control.nodeName === 'SELECT' ||
  //       control.nodeName === 'BUTTON' ||
  //       control.nodeName === 'TEXTAREA');
  // }
}
