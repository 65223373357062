<lib-snackbar></lib-snackbar>
<div class="tagCloud">
  <ul [ngClass]="{chips: type === 'chips'}">
    <li *ngFor="let tag of data" class="tc-weight-{{tag.weight}}" style="transform: rotate(0deg)">
      <a [href]="tag.link" *ngIf="tag.link !== '' && tag.link !== undefined" target="_blank" (click)="linkHandler(tag)"
        class="{{tag.styleClass ? tag.styleClass : 'tag-default'}}">{{tag.label}}</a>

      <a *ngIf="tag.link === '' || tag.link === undefined" (click)="linkHandler(tag)"
        class="{{tag.styleClass ? tag.styleClass : 'tag-default'}}">{{tag.label}}</a>
    </li>
  </ul>
</div>