import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {

  @Input() data: any;
  @Output() aiEvent1 = new EventEmitter<any>();
  @Output() aiEvent2 = new EventEmitter<any>();
  @Output() aiEvent3 = new EventEmitter<any>();
  @Output() aiEvent4 = new EventEmitter<any>();
  @Output() aiFav = new EventEmitter<any>();
  constructor() {
  }

  ngOnInit() {
  }

  aiEventHandler1() {
    this.aiEvent1.emit([]);
  }
  aiEventHandler2() {
    this.aiEvent2.emit([]);
  }
  aiEventHandler3() {
    this.aiEvent3.emit([]);
  }
  aiEventHandler4() {
    this.aiEvent4.emit([]);
  }
  aiFavEventHandler() {
    this.aiFav.emit([]);
  }
}
