<div class="appNotWorks">
  <div class="popBox">
    <figure class="m-b-20">
      <div>
        <img src="./assets/images/icon-insiq.png" alt="insiq">
      </div>
      <!-- <div>
        <img src="./assets/images/insiq.png" alt="dev2m">
      </div> -->
    </figure>
    <p>
      Your seem to be using an<br>
      unsupported browser.
    </p>
    <p>
      To use InsIQ, please update your browser to the latest version.
    </p>
    <div>
      <a href="https://www.google.com/chrome/" target="_blank" mat-icon-button color="warn">
        <mat-icon fontSet="i" fontIcon="i-googlechrome" class="i-1_7x" aria-label="Google Chrome"></mat-icon>
      </a>
      <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" mat-icon-button color="warn">
        <mat-icon fontSet="i" fontIcon="i-mozillafirefox" class="i-1_7x" aria-label="Mozilla Firefox">
        </mat-icon>
      </a>
    </div>
  </div>
</div>