import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { View, Config, FormDefault } from '../../config';
import { SharedService } from './shared.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';
import { SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class RequirementView {

  discoveryRequirements;
  selectedRequirements: any[];
  requirementTypeItemCodes;
  discoveryKeyplayers;
  url = 'conceptrequirement';

 // importance: number;
 //importanceTimeout: any;

  isValid() {
    return this.selectedRequirements && this.selectedRequirements.length > 0;
  }

  public view: View;
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    public ss: SharedService) {
    this.view = new View(http, router, route, alertService);
    this.ss.setRequirementView(this);
    this.view.refreshList = false;
    this.setForm();

  }

  setForm() {
    this.initForm();
    this.discoveryRequirements = null;
    this.selectedRequirements = [];
    this.view.state = 'list';
    this.view.delete = ((id) => {
      return this.ss.getRequirementView().delete(id);
    });
  }
  initForm() {
    this.view.initForm(this.url, this.getForm(), null, false);
    Config.isProjectSelected = true;
  }

  newform(load = true) {
  //  this.importance = null;
     this.view.get(`discoveryrequirement/concept/${this.ss.getProjectId()}/${this.ss.concept.id}`).subscribe(res => {
        this.discoveryRequirements = res;
      });
    if (load) {     
      this.view.get(`discoverykeyplayer/project/${this.ss.getProjectId()}`).subscribe(res => {
        this.discoveryKeyplayers = res;
        for (let x = 0; x < res.length; x++) {
          const item = this.discoveryKeyplayers[x];
          item['value'] = item['keyplayer'];
        }
      });
      this.view.get(`requirementtypeitem/all`).subscribe(res => {
        this.requirementTypeItemCodes = res;//= [];
        for (let x = 0; x < res.length; x++) {
          const item = this.requirementTypeItemCodes[x];
          item['value'] = item['id'];
        }
      });
    }
    this.view.newform();
  }

  edit(row) {
    if (this.discoveryRequirements === null) {
      this.newform();
    }
    this.view.reset();
    for (const item of this.view.formFields) {
      this.view.field(item).setValue(row[item]);
    }
    this.view.state = 'edit';
    this.view.active = row;
  }
  delete(id) {
    const list = this.view.list.filter(u => u.id === id);
    let item = null;
    for (let x = 0; x < list.length; x++) {
      item = list[x];
    }
    return this.view._delete(id, null, null, null, true, () => {
      //console.log(item.discoveryRequirementId);
      if (item !== null) {
        this.discoveryRequirements.push(item.discoveryRequirementId);
      }
      // this.ss.getRequirementTypeView().getList();
    });
  }
  save() {
    const n = this.selectedRequirements.length;
    let c = 0;
    for (let x = 0; x < n; x++) {
      const item = this.selectedRequirements[x];
      this.view.set('id', null);
      this.view.set('conceptId', this.ss.concept);
      this.view.set('discoveryRequirementId', item);
      this.view.saveCallback(() => {
        this.view.state = 'new';
        c++;
      //  console.log(c, n);
        if (c === n) {
          this.selectedRequirements = [];
          this.newform(false);
          this.getList();
        }
      });
    }
  }

  saveAndAdd() {
    //this.view.set('label', this.view.value('requirement'));
    this.save();
  }

  cancel() {
    this.ss.getRequirementTypeView().getList();
    //  this.getList();
    this.view.cancel();
    //   this.selectedTab = 0;
  }

  getList() {
    this.view.get(`${this.url}/all/${this.ss.concept.id}/${this.ss.requirementType.id}`).subscribe(res => {
      this.view.list = res;
    });
  }

  getForm() {
    return this.fb.group({
      id: [''],
      conceptId: ['', Validators.required],
      label: [''],
      importanceChange: [''], //Dummy to just show the change
      discoveryRequirementId: ['', Validators.required]
    });
  }

//  onImportanceChange(event, dt) {
//    if (this.importanceTimeout) {
//      clearTimeout(this.importanceTimeout);
//    }
//    this.importanceTimeout = setTimeout(() => {
//      console.log(event.value);
//      dt.filter(event.value, 'importanceId', 'in');
//     // this.importance = event.value;
//    }, 250);
//  }

  filterRTI(event, dt) {
   // console.log(event);
    dt.filter(event.value, 'requirementTypeItemCode.description', 'in')
  }

}
