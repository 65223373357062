<div>
  <div class="d-block p-a-0">
    <h4 class="text-accent">
      <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" (click)="backtoPage()" title="Close">
        <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
      </button>
    </h4>
    <mat-card-header>
      <div mat-card-avatar class="b-a-1 t-c">
        <i class="i i-sentiment_very_satisfied i-2_5x text-primary"></i>
      </div>
      <mat-card-title>Product and Competitor Sentiment</mat-card-title>
      <mat-card-subtitle>{{data.chartTitle}}</mat-card-subtitle>
    </mat-card-header>
  </div>
  <div class="col-sm-24 t-l p-y-10 p-x-5">
    <mat-card class="h-50px p-a-10" color="accent">
      <h1 class="smartTitle h4 text-accent">
        <i class="i i-filter-o ib-m"></i>
        <span class="ib-m">
          <small class="ib-m">Insights filtered by:</small>
          <b class="ib-m text-primary p-x-5 m-l-5">{{data.filter}}</b>
        </span>
      </h1>
    </mat-card>
  </div>
  <!-- <div class="col-sm-24 t-l p-y-10 p-x-5">
    <mat-card class="h-50px p-a-10" color="accent" style="border-radius: 28px;">
      <div class="d-flex">
        <div class="ib-m">
          <mat-chip-list aria-label="Insight categories">
            <mat-chip color="primary" style="cursor: pointer;" 
              (click)="categoryInsight(row, i)" matTooltipPosition="below" title="{{row}}"
              *ngFor="let row of catfilter(); index as i;">
              <mat-icon fontSet="i" class="i-idea i-1_5x ib-m" *ngIf="row=='InsIQ Curated'"></mat-icon>
            {{row}}
            </mat-chip>
          </mat-chip-list>
        </div>
          <div class="ib-m m-l-auto">
          <mat-chip-list aria-label="Insight categories">
            <mat-chip color="primary" style="cursor:pointer" (click)="ai.dateSorter(1)">
              Newest First
            </mat-chip>
            <mat-chip color="primary" style="cursor:pointer" (click)="ai.dateSorter(0)">
              Oldest First
            </mat-chip>
          </mat-chip-list>
        </div> 
      </div>
    </mat-card>

  </div>-->
  <div class="d-flex align-items-center justify-content-end">
    <button type="button" matRipple (click)="aiv.toggleView()" mat-mini-fab class="ib-m m-x-5 m-b-20"
      title="{{aiv.listViewInsight?'Switch to Grid View':'Switch to List View'}}">
      <i *ngIf="aiv.listViewInsight" class="i i-grid"></i>
      <i *ngIf="!aiv.listViewInsight" class="i i-list"></i>
    </button>
    <!-- Add more buttons or elements here if needed -->
  </div>
  <div class="alertdiv">
    <mat-card class="tableCard" *ngIf="aiv.listViewInsight">
      <p-table [value]="data.insightDetails">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols">{{ col.header }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>

            <td><button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="aiDroppWrap">
                <div class="menuInner">
                  <div class="topBtn">
                    <button mat-menu-item type="button" [matMenuTriggerFor]="deepDiveMenu" matRipple
                      matTooltipClass="md-fs" title="Deep dive for related insights" matTooltipPosition="below"
                      class="btn btn-primary btn-lg t-c ">
                      Deep dive for related insights
                    </button>
                  </div>
                  <div class="lineBtm">

                    <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
              active: row.aiData.liked
            }" (click)="likeOrUnlike(row.aiData, 1)" title="{{row.aiData.liked?'Remove Favorite':'Mark as Favorite'}}"
                      *ngIf="(!aiv.showWish||aiv.inddeep) && (!row.aiData.liked || !(row.aiData.liked?.likeon == '0'))">
                      <i class="i i-favourite"></i> {{row.liked?'Remove favorite':'Mark as favorite'}}
                    </button>
                    <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
              active: row.aiData.report == '1'
             }" title="{{row?.aiData.report == '1'?' Remove':'Add to Report'}}" (click)="addremoveReport(row.aiData)">
                      <i class="i i-ai_report"></i> {{row?.aiData.report == '1'?' Remove from report':'Add to Report'}}
                    </button>
                    <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
                      active: row?.aiData.notesId!==null
                     }" title="{{row?.aiData.notesId!==null?trimtooltip(row.aiData.noteAdd):'Add Notes'}}"
                      (click)="testfn(row.aiData)">
                      <i class="i i-notes"></i> {{row?.aiData.notesId!==null?'Edit Notes':'Add
                      Notes'}}
                    </button>
                    <button mat-menu-item type="button" class="btn btn-link leftBtn"
                      (click)="copyText(row.aiData.aiurl)" title="Copy" aria-label="Copy">
                      <i class="i i-copy"></i>Copy
                    </button>

                    <button mat-menu-item type="button" class="btn btn-link leftBtn" title="Share Insight"
                      [matMenuTriggerFor]="shareMenu">
                      <i class="i i-share"></i>Share Insight
                    </button>
                  </div>
                </div>
              </mat-menu>

              <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">
                <!-- <div class="rel infoHover" tabindex="0">
                <div class="infoTip" *ngIf="costsTime">This might take a little longer</div>
                <button mat-menu-item (click)="ai.insightDeep(row.aiId,'1')" (mouseenter)="costsTime=true"
                  (mouseout)="costsTime=false">
                  All Insights
                </button>
              </div> -->
                <h2 class="subTitle">Market Intelligence</h2>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'31')">{{aiv.catName('31',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'6')">{{aiv.catName('6',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'10')">{{aiv.catName('10',
                  aiv.view)}}</button>

                <!-- <h2 class="subTitle">Competitive Intelligence</h2> -->

                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'2')">{{aiv.catName('2',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'32')">{{aiv.catName('32',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'8')">
                  {{aiv.catName('8', aiv.view)}}</button>
                <h2 class="subTitle">Product R&D Intelligence</h2>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'11')">{{aiv.catName('11',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'7')">{{aiv.catName('7',
                  aiv.view)}}</button>

                <!-- <h2 class="subTitle">Supply Chain Insights</h2> -->
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'5')">{{aiv.catName('5',
                  aiv.view)}}</button>
                <!-- <h2 class="subTitle">People Related Insights</h2> -->



                <!-- <h2 class="subTitle">Intellectual Property(IP) Insights</h2> -->

                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'4')">{{aiv.catName('4',
                  aiv.view)}}</button>


                <h2 *ngIf="aiv.featureChecker(18)" class="subTitle">Business Intelligence</h2>

                <button *ngIf="aiv.featureChecker(18)" mat-menu-item
                  (click)="insightDeep(row.aiData.aiId,'9')">{{aiv.catName('9', aiv.view)}}</button>
              </mat-menu>
              <mat-menu #shareMenu="matMenu" xPosition="after" yPosition="above" [overlapTrigger]="false">
                <ng-template matMenuContent>
                  <!-- <h2 class="subTitle">Heading 6</h2> -->
                  <button type="button" mat-icon-button color="accent"
                    (click)="linkedinshre($event,row.aiData.aiurl,row.aiData.aiTitle)" title="LinkedIn Share"
                    [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-linkedin"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiData.aiurl)"
                    title="Twitter Share" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-twitter"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiData.aiurl)"
                    [matTooltipClass]="'md-fs'" title="Facebook Share" matTooltipPosition="below">
                    <i class="i i-facebook"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent"
                    (click)="watzappshare($event,row.aiData.aiurl,row.aiData.aiTitle)" title="Whatsapp Share"
                    [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-whatsapp"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" *ngIf="aiv.msstatus&&aiv.mschannelid!= null"
                    (click)="aiv.teamshare(row.aiData.aiNameTemp,row.aiData.aiurl,row.aiData.aiTitle,row.aiData.aiText)"
                    title="Post in MS Teams Channel" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-ms-team"></i>
                  </button>
                </ng-template>
              </mat-menu>
            </td>
            <td *ngIf="row.aiData.aiImages">

              <a [innerHTML]="aiv.titleManager(splitKeywordsAndTitle(row.aiData.aiTitle).title)"
                (click)="openLinkInNewTab(row.aiData.aiurl)"></a>
            </td>
            <td *ngIf="!row.aiData.aiImages">

              <a [innerHTML]="aiv.titleManager(row.aiData.aiTitle)" (click)="openLinkInNewTab(row.aiData.aiurl)"></a>
            </td>


            <td>

              <div class="showTooltip">


                <ng-container>
                  <div [innerHTML]="aiv.aiTexttrim(splitKeywordsAndDescription(row.aiData.aiText).description)"></div>
                </ng-container>

                <div class="overlayTooltip"
                  *ngIf="aiv.aiTexttrim(splitKeywordsAndDescription(row.aiData.aiText).description)!==splitKeywordsAndDescription(row.aiData.aiText).description">
                  <div [innerHTML]="splitKeywordsAndDescription(row.aiData.aiText).description"></div>


                </div>
              </div>
            </td>


            <td>
              <div class="showTooltip">


                <ng-container>
                  <div [innerHTML]="truncateText(splitKeywordsAndDescription(row.aiData.aiText)?.keywords)"></div>
                </ng-container>

                <div class="overlayTooltip"
                  *ngIf="splitKeywordsAndDescription(row.aiData.aiText)?.keywords!==truncateText(splitKeywordsAndDescription(row.aiData.aiText)?.keywords)">
                  <div [innerHTML]="splitKeywordsAndDescription(row.aiData.aiText)?.keywords"></div>


                </div>
              </div>

            </td>


            <td>{{row.aiData.aiDate}}

          </tr>
        </ng-template>
      </p-table>
    </mat-card>
    <div class="mg p-b-30" *ngIf="!aiv.listViewInsight">
      <div class="mg-brick" *ngFor="let row of data.insightDetails">
        <mat-card class="aiCard m-b-10 privilaged b-patent">
          <mat-card-actions class="b-t-1">
            <ul class="buttonWrap">

              <!-- (click)="ai.insightDeep(row.aiId)" -->
              <!-- <button type="button" mat-mini-fab [matMenuTriggerFor]="deepDiveMenu" color="accent"
                [matTooltipClass]="'md-fs'" title="Deep dive for related insights" matTooltipPosition="below"
                class="m-r-5">
                <mat-icon fontSet="i" class="i-deep_dive i-1_7x ib-m"></mat-icon>
              </button>
              <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">
                <div class="rel infoHover" tabindex="0">
                  <div class="infoTip" *ngIf="costsTime">Require 60 secs</div>
                  <button mat-menu-item (click)="insightDeep(row.aiId,'1')" (mouseenter)="costsTime=true"
                    (mouseout)="costsTime=false">
                    All Insights
                  </button>
                </div>
                <button mat-menu-item (click)="insightDeep(row.aiId,'2')">Similar Products or Projects</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'3')">Consumer Information</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'4')">Patents and Trademarks</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'5')">Suppliers and Manufacturers</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'6')">Product Reviews</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'7')">Regulatory and Compliance</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'8')">Key Influencers,
                  Professionals</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'9')">B2B</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'10')">Product News</button>
              </mat-menu>-->
              <!-- <button type="button" mat-icon-button (click)="ai.likeOrUnlike(row, 0)"
                title="Not Useful" matTooltipPosition="below"
                *ngIf="!ai.showWish && (!row.liked || !(row.liked?.likeon == '1'))">
                <mat-icon class="i-1_7x" color="{{row.liked?.likeon == '0'?'warn':''}}">thumb_down
                </mat-icon>
              </button>-->

              <!-- <div class="spacer"></div> -->
              <li>
                <button type="button" class="btn btn-link"
                  title="{{row.aiData.liked ?'Remove Favorite':'Mark as Favorite'}}" matTooltipPosition="below"
                  [ngClass]="{
                    active: row.aiData?.liked
                  }" (click)="likeOrUnlike(row.aiData, 1)">
                  <!-- <mat-icon class="i-2x" color="{{row.aiData.liked ?'primary':''}}">star</mat-icon> -->
                  <i class="i i-favourite"></i>
                </button>
              </li>
              <li>

                <button type="button" class="btn btn-link"
                  title="{{row.aiData?.report == '1'?' Remove':'Add to Report'}}" matTooltipPosition="below" [ngClass]="{
                    active: row.aiData?.report == '1'
                  }" [matTooltipClass]="'md-fs'" (click)="addremoveReport(row.aiData)">
                  <i class="i i-ai_report"></i>
                  <!-- <mat-icon class="i-ai_report i-2x" color="{{row.aiData.report == '1'?'primary':''}}"></mat-icon> -->
                </button>
              </li>

              <!-- <button type="button" mat-icon-button title="{{row?.report == '1'?' Remove':'Add to Executive Report'}}" class="reportIndicator"
              matTooltipPosition="below" [matTooltipClass]="'md-fs'" (click)="addremoveReport(row)">
                <mat-icon *ngIf="row?.report == '0'||row?.report == undefined" class="i-ai_report" color="accent"></mat-icon>
                <mat-icon *ngIf="row?.report == '1'" class="i-playlist_add_check" color="{{row?.report == '1'?'primary':''}}"></mat-icon>
              </button> -->
              <li>

                <button type="button" class="btn btn-link" (click)="testfn(row.aiData)" [matTooltipClass]="'md-fs'"
                  [ngClass]="{
                  active: row.aiData?.notesId!==null
                }"
                  [title]="(row.aiData?.notesId===null||row.aiData?.notesId === undefined)?'Add Notes':trimtooltip(row.aiData.noteAdd)">
                  <!-- <mat-icon class="i-1_7x" *ngIf="row.aiData?.notesId===null||row.aiData?.notesId === undefined">note_add
                  </mat-icon>
                  <mat-icon class="i-1_7x" *ngIf="row.aiData.noteAdd" color="primary">speaker_notes
                  </mat-icon> -->
                  <i class="i i-notes"></i>
                </button>
              </li>
              <!--color="{{!row?.notesId || row?.notesId == null || row?.notesId == '' ? 'accent' : ''}}"-->
              <!-- <button *ngIf="!ai.showWish" type="button" mat-icon-button (click)="ai.addOrEditNotes(row)"
              title="{{row?.notesId!==null?trimtooltip(row.noteAdd):'Add Notes'}}"
              matTooltipPosition="below">
              <mat-icon class="i-1_7x" *ngIf="row?.notesId===null">note_add</mat-icon>
              <mat-icon class="i-1_7x" *ngIf="row?.notesId!==null" color="primary">speaker_notes
              </mat-icon>
            </button>-->
              <!--color="{{!row?.notesId || row?.notesId == null || row?.notesId == '' ? 'accent' : ''}}"-->
              <!-- <button *ngIf="ai.showWish && row && row.notesId && row.notesId != null && row.notesId != ''"
              type="button" mat-icon-button (click)="ai.addOrEditNotes(row)" title="Notes" matTooltipPosition="below">
              <mat-icon class="i-1_7x" ngClass="mat-accent : !row?.notesId || row?.notesId == null || row?.notesId == ''">thumb_down</mat-icon>
            </button> -->
              <li>

                <button type="button" class="btn btn-link" *ngIf="row.aiData.aiUrl !== ''"
                  (click)="aiv.copyText(row.aiData.aiUrl)" title="Copy" matTooltipPosition="below">
                  <!-- <mat-icon class="i-1_7x">content_copy</mat-icon> -->
                  <i class="i i-copy"></i>
                </button>
              </li>
              <!-- <button type="button" mat-icon-button *ngIf="row.aiUrl !== ''" color="accent"
              (click)="ai.onNavigate(row.aiUrl)" title="Open in New Tab" matTooltipPosition="below">
              <mat-icon class="i-1_7x">open_in_new</mat-icon>
            </button> -->
              <li>
                <button type="button" class="btn btn-link" title="Share Insight" [matMenuTriggerFor]="helpMenu">
                  <!-- <mat-icon class="i-1_7x">share
                </mat-icon> -->
                  <i class="i i-share"></i>
                </button>
                <mat-menu #helpMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
                  <ng-template matMenuContent>
                    <button type="button" mat-icon-button color="accent"
                      (click)="linkedinshre($event,row.aiData.aiurl,row.aiData.aiTitle)" title="LinkedIn Share"
                      matTooltipPosition="below">

                      <i class="i i-linkedin"></i>
                    </button>
                    <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiData.aiurl)"
                      title="Twitter Share" matTooltipPosition="below">

                      <i class="i i-twitter"></i>
                    </button>
                    <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiData.aiurl)"
                      title="Facebook Share" matTooltipPosition="below">

                      <i class="i i-facebook"></i>
                    </button>
                    <button type="button" mat-icon-button color="accent"
                      (click)="watzappshare($event,row.aiData.aiurl,row.aiData.aiTitle)" title="Whatsapp Share"
                      matTooltipPosition="below">
                      <i class="i i-whatsapp"></i>
                    </button>
                    <button type="button" mat-icon-button color="accent" *ngIf="aiv.msstatus&&aiv.mschannelid!= null"
                      (click)="aiv.teamshare(row.aiData.aiName,row.aiData.aiurl,row.aiData.aiTitle,row.aiData.aiText)"
                      title="Post in MS Teams Channel" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                      <i class="i i-ms-team"></i>
                    </button>
                  </ng-template>
                </mat-menu>
              </li>
            </ul>
          </mat-card-actions>
          <!-- <div class="report">
            {{row?.report}}
            <button type="button" mat-icon-button title="Add to Executive Report" matTooltipPosition="below"
              [matTooltipClass]="'md-fs'" (click)="addremoveReport(row)">
              <mat-icon class="i-2x" color="{{row?.report == '1'?'primary':''}}">files</mat-icon>
            </button>
          </div> -->
          <!-- <div class="badge">
            <mat-icon fontSet="i" attr.aria-label="{{row.aiType}}" style="color: #062fc9 !important;"
              class="i i-{{getScoreEmoji(row.aiType)}}" title="{{row.aiType}} Review" matTooltipPosition="below">
            </mat-icon>
          </div>-->

          <div class="badgelabel">
            <!-- <mat-icon fontSet="i" attr.aria-label="{{row.aiType}}"
              class="i i-{{getScoreEmoji(row.aiType)}} {{getScoreColor(row.aiType)}}" title="{{row.aiType}} Content"
              matTooltipPosition="below">
            </mat-icon> -->
            <span>{{row.aiType}}</span>
          </div>

          <mat-card-content>
            <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="row.aiData.aiDate">
              <i class="i i-calendar i-1_5x ib-m"></i>
              <!-- <mat-icon fontSet="i" class="i-date_range i-1_5x ib-m"></mat-icon> -->
              <span class="ib-m">{{row.aiData.aiDate}}</span>
            </div>
            <div class="w-100">
              <!-- <div class="m-b-5">{{row.aiNameTemp}}</div>-->
              <div class="m-b-5" [innerHTML]="row.aiData.aiTitle"></div>
              <a [href]="row.aiData.aiUrl" target="_blank" title="Open in New Tab" matTooltipPosition="below">
                <div class="text-accent">{{row.aiData.aiUrl}}</div>
              </a>
              <div class="m-t-5" [innerHTML]="row.aiData.aiText"></div>
              <div class="ib-m"></div>
            </div>
            <!-- <div *ngIf="ai.notesviewflag" href="javascript:;" (click)="ai.addOrEditNotes(row)"
            title="Click to Add/Edit Note" matTooltipPosition="below" class="notes">
            {{row.noteAdd}}
            </div> -->
            <div class="btnWrap">
              <button type="button" [matMenuTriggerFor]="deepDiveMenu" matRipple matTooltipClass="md-fs"
                title="Deep dive for related insights" matTooltipPosition="below" class="btn btn-primary btn-lg t-c">
                Deep dive for related insights
              </button>

              <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">
                <!-- <div class="rel infoHover" tabindex="0">
                  <div class="infoTip" *ngIf="costsTime">This might take a little longer</div>
                  <button mat-menu-item (click)="ai.insightDeep(row.aiId,'1')" (mouseenter)="costsTime=true"
                    (mouseout)="costsTime=false">
                    All Insights
                  </button>
                </div> -->
                <h2 class="subTitle">Market Intelligence</h2>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'31')">{{catName('31')}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'6')">{{catName('6')}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'10')">{{catName('10')}}</button>

                <!-- <h2 class="subTitle">Competitive Intelligence</h2> -->

                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'2')">{{catName('2')}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'32')">{{catName('32')}}</button>

                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'8')">
                  {{catName('8')}}</button>
                <h2 class="subTitle">Product R&D Intelligence</h2>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'11')">{{catName('11')}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'7')">{{catName('7')}}</button>

                <!-- <h2 class="subTitle">Supply Chain Insights</h2> -->
                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'5')">{{catName('5')}}</button>
                <!-- <h2 class="subTitle">People Related Insights</h2> -->


                <h2 class="subTitle">Business Intelligence</h2>

                <button mat-menu-item (click)="insightDeep(row.aiData.aiId,'4')">{{catName('4')}}</button>


                <h2 *ngIf="aiv.featureChecker(18)" class="subTitle">Proprietary Data Insights</h2>

                <button *ngIf="aiv.featureChecker(18)" mat-menu-item
                  (click)="insightDeep(row.aiData.aiId,'9')">{{catName('9')}}</button>
              </mat-menu>
            </div>
          </mat-card-content>

        </mat-card>
      </div>
      <!-- Bricks for Support -->
      <!-- <div class="mg-brick h-120px"></div>
      <div class="mg-brick h-120px"></div>
      <div class="mg-brick h-120px"></div> -->
      <!-- Bricks for Support -->
    </div>
  </div>
</div>

<ng-template #secondDialog>
  <form [formGroup]="aiv.view.form">
    <div class="h-100 scrollY b-patent" style="width:800px;">
      <mat-card class="m-a-10">
        <mat-card-content>
          <div class="w-100">
            <div class="t-c">
              <div class="d-flex">
                <label class="bigLabel">Capture your notes</label>
              </div>
              <mat-form-field class="fw m-b-10 sm" floatLabel="never" appearance="outline" title=""
                matTooltipPosition="above">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="notes"
                  autocomplete="off" placeholder="Record your Notes" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"
                  title="Record your Notes Here" (keyup)="aiv.txtchange($event.target.value)"></textarea>
                <!-- <mat-error *ngIf="view.invalid('notes')">{{view.error('notes')}}</mat-error>-->
              </mat-form-field>
              <div class="m-t-15">
                <button type="button" title="Delete the Notes Entered" matTooltipPosition="above"
                  *ngIf="aiv.view.value('notesId')" matRipple (click)="deleteNote()"
                  class="btn-lg btn-danger lighten m-x-5">
                  <!-- <mat-icon fontSet="i" class="i-delete i-1_5x ib-m"></mat-icon> -->
                  <span class="ib-m-l-5">Delete Note</span>
                </button>
                <button type="button" (click)="cancelNote()" matRipple
                  class="m-x-5 btn-cancel btn btn-lg btn-default lighten">Cancel</button>


                <button type="button" (click)="saveNote()" matRipple class="btn btn-lg btn-primary m-x-5"
                  [disabled]="!aiv.newnotesave">Save</button>
                <div class="ib-m">
                  <button type="button" title="Save and Post to MS Teams Channel" matTooltipPosition="above"
                    [disabled]="!aiv.newnotesave" *ngIf="aiv.msstatus&&aiv.mschannelid!= null" matRipple
                    (click)="saveNotems()" class="ib-m m-r-15 t-normal f-400 btn btn-lg btn-primary">
                    <i class="i i-ms-team"></i>
                    <!-- <mat-icon fontSet="i" class="i-ms-team i-1_5x ib-m"></mat-icon> -->
                    <span class="ib-m-l-5">Save and Post to MS Teams</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</ng-template>