<div class="d-block p-a-0">
  <h4 class="text-accent">
    <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" [mat-dialog-close]="true" title="Close">
      <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
    </button>
  </h4>
  <mat-card-header>
    <div mat-card-avatar class="b-a-1 t-c">
      <i class="i i-search i-2_5x text-primary"></i>
    </div>
    <mat-card-title>InsIQ Insights Search : {{ai.fixaitypeName}}</mat-card-title>
    <mat-card-subtitle>{{ai.charttitle}}</mat-card-subtitle>
  </mat-card-header>

  <div class="searchBar b-t-3 p-t-10 p-b-5">
    <div class="row align-items-center">

      <div class="col w-100">
        <mat-form-field appearance="outline" class="w-100 m-b--10 sm">
          <!-- <mat-label>InsIQ AI Report Title</mat-label> -->
          <input matInput placeholder="Enter Keywords to search Insights" #Aikeyword
            (keypress)="ai.omit_special_char($event,Aikeyword.value.trim(),ai.fixaitypeId)" id="newHero">
          <!-- <mat-hint>Modify here to view AI Insights</mat-hint> -->
        </mat-form-field>
      </div>
      <!-- <div class="deepDiv"><span>Deep Dive </span>
        <mat-slide-toggle
          class="tp-margin" (change)="ai.deepdive(view, $event)"        
          [checked]="ai.deepdiveflag"
          [disabled]="disabled">
        </mat-slide-toggle>
      </div> -->

      <div class="col-auto p-l-0 m-b-5">
        <div>
          <button matRipple class="t-normal btn btn-lg btn-primary"
            (click)="ai.searchApiCall(Aikeyword.value.trim(),ai.fixaitypeId)" [disabled]="Aikeyword.value.trim()==''"
            #search>
            <i class="i i-ai_results i-1_5x"></i>
            <!-- <mat-icon class="i-1_5x ib-m">AI</mat-icon> -->
            <span class="ib-m">Search Insights</span>
            <i class="i i-2x">serach</i>
          </button>

          <!-- <mat-button-toggle-group class="sm">
            <mat-button-toggle>
              <mat-icon class="i-1_5x ib-m">AI</mat-icon>
              <span class="ib-m">Ask TOM</span>
            </mat-button-toggle>
            <mat-button-toggle color="accent">
              <mat-icon>arrow_drop_down</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group> -->
        </div>
        <!-- <div class="action align-self-center">
          <button mat-icon-button color="accent" class="m-b-20" title="Update Product"
            matTooltipPosition="below" (click)="ai.smartSave()">
            <mat-icon class="i-2x ib-m">save</mat-icon>
          </button>
        </div> -->
      </div>
    </div>
  </div>

</div>
<div class="d-flex align-items-center justify-content-end" *ngIf="ai.searchSmartlistItemDetailpaginator">
  <button type="button" matRipple (click)="ai.toggleView()" mat-mini-fab class="ib-m m-x-5 m-b-20"
    title="{{ai.listViewInsight?'Switch to Grid View':'Switch to List View'}}">
    <i *ngIf="ai.listViewInsight" class="i i-grid"></i>
    <i *ngIf="!ai.listViewInsight" class="i i-list"></i>
  </button>
  <!-- Add more buttons or elements here if needed -->
</div>
<mat-card class="tableCard" *ngIf="ai.listViewInsight&&ai.searchSmartlistItemDetailpaginator">
  <p-table [value]="ai.searchSmartlistItemDetailpaginator">
    <ng-template pTemplate="header">
      <tr *ngIf="!(ai.showWish||ai.notesviewflag||ai.reportviewflag||ai.insightsearch)">
        <th *ngFor="let col of cols">{{ col.header }}</th>
      </tr>
      <tr *ngIf="ai.showWish||ai.reportviewflag||ai.insightsearch">
        <th *ngFor="let col of colsFav">{{ col.header }}</th>
      </tr>
      <tr *ngIf="ai.notesviewflag">
        <th *ngFor="let col of colsNotes">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>

        <td><button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="aiDroppWrap">
            <div class="menuInner">
              <div class="topBtn">
                <button mat-menu-item type="button" [matMenuTriggerFor]="deepDiveMenu" matRipple
                  *ngIf="!ai.desiagnApiCall" matTooltipClass="md-fs" title="Deep dive for related insights"
                  matTooltipPosition="below" class="btn btn-primary btn-lg t-c ">
                  Deep dive for related insights
                </button>
              </div>
              <div class="lineBtm">

                <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
          active: row.liked
        }" (click)="ai.likeOrUnlikecat(row, 1,ai.fixaitypeName)"
                  title="{{row.liked?'Remove Favorite':'Mark as Favorite'}}"
                  *ngIf="(!ai.showWish||ai.inddeep) && (!row.liked || !(row.liked?.likeon == '0'))">
                  <i class="i i-favourite"></i> {{row.liked?'Remove favorite':'Mark as favorite'}}
                </button>
                <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
          active: row.report == '1'
         }" title="{{row?.report == '1'?' Remove':'Add to Report'}}" (click)="addremoveReport(row)">
                  <i class="i i-ai_report"></i> {{row?.report == '1'?' Remove from report':'Add to Report'}}
                </button>




                <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
          active: row?.notesId!==null
         }" title="{{row?.notesId!==null?trimtooltip(row.noteAdd):'Add Notes'}}" (click)="testfn(row)">
                  <i class="i i-notes"></i> {{row?.notesId!==null?'Edit Notes':'Add Notes'}}
                </button>
                <button mat-menu-item type="button" class="btn btn-link leftBtn" (click)="ai.copyText(row.aiUrl)"
                  title="Copy" aria-label="Copy">
                  <i class="i i-copy"></i>Copy
                </button>

                <button mat-menu-item type="button" class="btn btn-link leftBtn" title="Share Insight"
                  [matMenuTriggerFor]="shareMenu">
                  <i class="i i-share"></i>Share Insight
                </button>
              </div>
            </div>
          </mat-menu>

          <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">
            <!-- <div class="rel infoHover" tabindex="0">
            <div class="infoTip" *ngIf="costsTime">This might take a little longer</div>
            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'1')" (mouseenter)="costsTime=true"
              (mouseout)="costsTime=false">
              All Insights
            </button>
          </div> -->
            <h2 class="subTitle">Market Intelligence</h2>
            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'31')">{{ai.catName('31',
              ai.view)}}</button>
            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'6')">{{ai.catName('6',
              ai.view)}}</button>
            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'10')">{{ai.catName('10',
              ai.view)}}</button>

            <!-- <h2 class="subTitle">Competitive Intelligence</h2> -->

            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'2')">{{ai.catName('2',
              ai.view)}}</button>
            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'32')">{{ai.catName('32',
              ai.view)}}</button>
            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'8')">
              {{ai.catName('8', ai.view)}}</button>
            <h2 class="subTitle">Product R&D Intelligence</h2>
            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'11')">{{ai.catName('11',
              ai.view)}}</button>
            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'7')">{{ai.catName('7',
              ai.view)}}</button>

            <!-- <h2 class="subTitle">Supply Chain Insights</h2> -->
            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'5')">{{ai.catName('5',
              ai.view)}}</button>
            <!-- <h2 class="subTitle">People Related Insights</h2> -->



            <!-- <h2 class="subTitle">Intellectual Property(IP) Insights</h2> -->

            <button mat-menu-item (click)="ai.insightDeep(row.aiId,'4')">{{ai.catName('4',
              ai.view)}}</button>


            <h2 *ngIf="ai.featureChecker(18)" class="subTitle">Business Intelligence</h2>

            <button *ngIf="ai.featureChecker(18)" mat-menu-item (click)="ai.insightDeep(row.aiId,'9')">{{ai.catName('9',
              ai.view)}}</button>
          </mat-menu>
          <mat-menu #shareMenu="matMenu" xPosition="after" yPosition="above" [overlapTrigger]="false">
            <ng-template matMenuContent>
              <!-- <h2 class="subTitle">Heading 6</h2> -->
              <button type="button" mat-icon-button color="accent" (click)="linkedinshre($event,row.aiUrl,row.aiTitle)"
                title="LinkedIn Share" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                <i class="i i-linkedin"></i>
              </button>
              <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiUrl)"
                title="Twitter Share" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                <i class="i i-twitter"></i>
              </button>
              <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiUrl)"
                [matTooltipClass]="'md-fs'" title="Facebook Share" matTooltipPosition="below">
                <i class="i i-facebook"></i>
              </button>
              <button type="button" mat-icon-button color="accent" (click)="watzappshare($event,row.aiUrl,row.aiTitle)"
                title="Whatsapp Share" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                <i class="i i-whatsapp"></i>
              </button>
              <button type="button" mat-icon-button color="accent" *ngIf="ai.msstatus&&ai.mschannelid!= null"
                (click)="ai.teamshare(row.aiNameTemp,row.aiUrl,row.aiTitle,row.aiText)" title="Post in MS Teams Channel"
                [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                <i class="i i-ms-team"></i>
              </button>
            </ng-template>
          </mat-menu>
        </td>
        <td *ngIf="(ai.showWish||ai.notesviewflag||ai.reportviewflag||ai.insightsearch)">
          <div class="badgelabel">
            <span class="">{{getTooltip(ai.catId(row.aiName),row.aiTitle,ai.desiagnApiCall)}}</span>
          </div>
        </td>
        <td *ngIf="row.aiImages">

          <a [innerHTML]="ai.titleManager(splitKeywordsAndTitle(row.aiTitle).title)"
            (click)="openLinkInNewTab(row.aiUrl)"></a>
        </td>
        <td *ngIf="!row.aiImages">

          <a [innerHTML]="ai.titleManager(row.aiTitle)" (click)="openLinkInNewTab(row.aiUrl)"></a>
        </td>
        <!-- <td [innerHTML]="ai.aiTexttrim(row.aiText)" title="{{row.aiText}}"></td> -->
        <td>
          <div class="d-flex align-items-center">
            <i *ngIf="row.aiVideos !== '' && row.aiVideos" class="i i-ondemand_video m-r-10"
              style="font-size: 60px; cursor: pointer;"
              (click)="ai.openVideoPopup(transform(ai.getVideoembed(row.aiVideos)))"></i>
            <div class="showTooltip">

              <ng-container *ngIf="row.aiVideos; else showTextOrImage">



                <!-- Use iframe to embed YouTube video -->

                <div *ngIf="ai.showVideoPopup" class="popupImg">
                  <div class="popup-contentImg">
                    <span class="closeImg" (click)="ai.closeVideoPopup()">&times;</span>
                    <iframe width="250" height="150"
                      [src]="transform(ai.getVideoembed(row.aiVideos)) | safe: 'resourceUrl'" frameborder="0"
                      allowfullscreen></iframe>
                  </div>


                </div>
                <div [innerHTML]="ai.aiTexttrim(splitKeywordsAndDescription(row.aiText).description)"></div>
              </ng-container>
              <ng-template #showTextOrImage>
                <ng-container *ngIf="!row.aiImages; else showImage">
                  <div [innerHTML]="ai.aiTexttrim(splitKeywordsAndDescription(row.aiText).description)"></div>
                </ng-container>
                <ng-template #showImage>
                  <!-- Display the image placeholder here -->
                  <i class="i i-photo_size_select_actual" style="font-size: 60px; cursor: pointer;"
                    (click)="ai.openPopup(transform(row.aiImages))"></i>

                  <!-- Pop-up/modal for displaying the actual image -->
                  <div *ngIf="ai.showPopup" class="popupImg">
                    <div class="popup-contentImg">
                      <span class="closeImg" (click)="ai.closePopup()">&times;</span>
                      <img [src]="ai.selectedImageUrl | safe: 'url'" alt="Actual Image" />
                    </div>
                  </div>
                </ng-template>




              </ng-template>
              <div class="overlayTooltip"
                *ngIf="ai.aiTexttrim(splitKeywordsAndDescription(row.aiText).description)!==splitKeywordsAndDescription(row.aiText).description">
                <div [innerHTML]="splitKeywordsAndDescription(row.aiText).description"></div>
                <div (click)="ai.patentModal(row.aiId)" class="m-t-10 readmore t-r"
                  *ngIf="(getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)==='Patents & Trademarks'||getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)==='Patents')&&!ai.showWish&&(row.aiCountry !== '' || row.aiTechDomain!== ''||row.aiClaim!== '')&&(row.aiCountry !== undefined||row.aiTechDomain!== undefined||row.aiClaim!== undefined)&&(row.aiCountry||row.aiTechDomain||row.aiClaim)&&(row.aiCountry!==null||row.aiTechDomain!==null||row.aiClaim!==null)">
                  Read More...
                </div>

              </div>
            </div>
          </div>
        </td>


        <td *ngIf="!row.aiImages">
          <div class="showTooltip">
            <div [innerHTML]="truncateText(splitKeywordsAndDescription(row.aiText)?.keywords)"></div>
            <div class="overlayTooltip"
              *ngIf="truncateText(splitKeywordsAndDescription(row.aiText)?.keywords)!==splitKeywordsAndDescription(row.aiText)?.keywords">
              <div [innerHTML]="splitKeywordsAndDescription(row.aiText)?.keywords"></div>

            </div>
          </div>
        </td>
        <td *ngIf="row.aiImages">
          <div class="showTooltip">
            <div [innerHTML]="truncateText(splitKeywordsAndTitle(row.aiTitle)?.keywords)"></div>
            <div class="overlayTooltip"
              *ngIf="truncateText(splitKeywordsAndTitle(row.aiTitle)?.keywords)!=splitKeywordsAndTitle(row.aiTitle)?.keywords">
              <div [innerHTML]="splitKeywordsAndTitle(row.aiTitle)?.keywords"></div>
            </div>
          </div>

        </td>

        <!-- <td>hh</td> -->
        <td *ngIf="!(ai.showWish||ai.notesviewflag||ai.reportviewflag||ai.insightsearch)">{{row.aiDate}}
        </td>
        <td *ngIf="ai.notesviewflag">
          <div *ngIf="row?.notesId!==null&&ai.notesviewflag" tabindex="0" (click)="ai.addOrEditNotes(row)"
            [matTooltipClass]="'md-fs'" title="Click to Add/Edit Note" matTooltipPosition="below" class="notes">
            {{row.noteAdd}}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</mat-card>
<!-- <h1 style="height:1000px">A Tall Custom Modal!</h1> -->
<div class="mg mg-3 mg-2 p-b-20" *ngIf="!ai.listViewInsight">
  <!-- <div class="h-100 scrollY" style="width:1700px;">
    <div class="t-c p-y-30" *ngIf="ai.searchinsightDetails==null">
      <img class="w-200px" src="./assets/images/searchinsights.png" alt="Search Keywords!!!">
      <h1 class="h1 text-accent m-t-30 m-b-0">Search Using Keywords to get related Insights!</h1>
    </div>
  </div> -->

  <div class="mg-brick" *ngFor="let row of ai.searchSmartlistItemDetailpaginator">
    <mat-card class="aiCard m-b-30 privilaged" [ngClass]="'c-' + getClass(row.aiName)" [class.mat-elevation-z8]="true">

      <mat-card-actions class="b-t-1">
        <ul class="buttonWrap">

          <li *ngIf="ai.showWish&&!ai.inddeep">
            <button type="button" mat-icon-button title="Remove From Favorite" (click)="ai.removefav(row)"
              matTooltipPosition="below">
              <mat-icon class="i-1_7x">delete
              </mat-icon>
            </button>
          </li>
          <li *ngIf="(!ai.showWish||ai.inddeep) && (!row.liked || !(row.aiLikeon == '0'))">
            <button type="button" class="mat-menu-trigger btn btn-link" [ngClass]="{
              active: row.liked
            }" title="{{row.liked ?'Remove Favorite':'Mark as Favorite'}}" matTooltipPosition="below"
              (click)="ai.likeOrUnlikecat(row, 1,ai.fixaitypeName)">
              <!-- <mat-icon class="i-2x" color="{{row.liked ?'primary':''}}">star
              </mat-icon> -->
              <i class="i i-favourite i-1_5x "></i>
            </button>
          </li>
          <li>
            <button type="button" class="mat-menu-trigger btn btn-link"
              title="{{row?.report == '1'?' Remove':'Add to Report'}}" matTooltipPosition="below" [ngClass]="{
                active: row.report == '1'
              }" [matTooltipClass]="'md-fs'" (click)="addremoveReport(row)">
              <!-- <mat-icon class="i-ai_report i-2x" color="{{row.report == '1'?'primary':''}}"></mat-icon> -->
              <i class="i i-ai_report i-1_5x"></i>
            </button>
          </li>
          <li> <button type="button" class="mat-menu-trigger btn btn-link" (click)="testfn(row)" [ngClass]="{
            active: row?.notesId!==null
          }" [matTooltipClass]="'md-fs'" [title]="row?.noteAdd?trimtooltip(row.noteAdd):'Add Notes'"
              matTooltipPosition="below">
              <!-- <mat-icon class="i-1_7x" *ngIf="row?.notesId===null||row?.notesId === undefined">note_add
              </mat-icon> -->
              <!-- <mat-icon class="i-1_7x" *ngIf="row.noteAdd" color="primary">speaker_notes
              </mat-icon> -->
              <i class="i i-notes i-1_5x"></i>
            </button></li>
          <li *ngIf="row.aiUrl !== ''">
            <button type="button" class="mat-menu-trigger btn btn-link" (click)="ai.copyText(row.aiUrl)" title="Copy"
              matTooltipPosition="below">
              <i class="i i-copy i-1_5x"></i>
            </button>
          </li>
          <li>
            <button type="button" class="mat-menu-trigger btn btn-link" title="Share Insight"
              [matMenuTriggerFor]="helpMenu">
              <i class="i i-share i-1_5x"></i>
            </button>
            <mat-menu #helpMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
              <ng-template matMenuContent>
                <button type="button" mat-icon-button color="accent"
                  (click)="linkedinshre($event,row.aiUrl,row.aiTitle)" title="LinkedIn Share"
                  matTooltipPosition="below">

                  <i class="i i-linkedin "></i>
                </button>
                <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiUrl)"
                  title="Twitter Share" matTooltipPosition="below">

                  <i class="i i-twitter"></i>
                </button>
                <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiUrl)"
                  title="Facebook Share" matTooltipPosition="below">

                  <i class="i i-facebook"></i>
                </button>
                <button type="button" mat-icon-button color="accent"
                  (click)="watzappshare($event,row.aiUrl,row.aiTitle)" title="Whatsapp Share"
                  matTooltipPosition="below">
                  <i class="i i-whatsapp"></i>
                </button>
                <button type="button" mat-icon-button color="accent" *ngIf="ai.msstatus&&ai.mschannelid!= null"
                  (click)="ai.teamshare(row.aiName,row.aiUrl,row.aiTitle,row.aiText)" title="Post in MS Teams Channel"
                  [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                  <i class="i i-ms-team"></i>
                </button>
              </ng-template>
            </mat-menu>
          </li>

        </ul>

        <!-- (click)="ai.insightDeep(row.aiId)" -->

        <!-- <button type="button" mat-icon-button (click)="ai.likeOrUnlike(row, 0)"
                title="Not Useful" matTooltipPosition="below"
                *ngIf="!asi.showWish && (!row.liked || !(row.liked?.likeon == '1'))">
                <mat-icon class="i-1_7x" color="{{row.liked?.likeon == '0'?'warn':''}}">thumb_down
                </mat-icon>
              </button>-->







        <!-- <button type="button" mat-icon-button title="{{row?.report == '1'?' Remove':'Add to Executive Report'}}" class="reportIndicator"
            matTooltipPosition="below" [matTooltipClass]="'md-fs'" (click)="addremoveReport(row)">
              <mat-icon *ngIf="row?.report == '0'||row?.report == undefined" class="i-ai_report" color="accent"></mat-icon>
              <mat-icon *ngIf="row?.report == '1'" class="i-playlist_add_check" color="{{row?.report == '1'?'primary':''}}"></mat-icon>
            </button>-->

        <!--color="{{!row?.notesId || row?.notesId == null || row?.notesId == '' ? 'accent' : ''}}"-->
        <!-- <button *ngIf="!ai.showWish" type="button" mat-icon-button (click)="ai.addOrEditNotes(row)"
                title="{{row?.notesId!==null?trimtooltip(row.noteAdd):'Add Notes'}}"
                matTooltipPosition="below">
                <mat-icon class="i-1_7x" *ngIf="row?.notesId===null">note_add</mat-icon>
                <mat-icon class="i-1_7x" *ngIf="row?.notesId!==null" color="primary">speaker_notes
                </mat-icon>
              </button>-->
        <!--color="{{!row?.notesId || row?.notesId == null || row?.notesId == '' ? 'accent' : ''}}"-->
        <!-- <button *ngIf="ai.showWish && row && row.notesId && row.notesId != null && row.notesId != ''"
          type="button" mat-icon-button (click)="ai.addOrEditNotes(row)" title="Notes" matTooltipPosition="below">
          <mat-icon class="i-1_7x" ngClass="mat-accent : !row?.notesId || row?.notesId == null || row?.notesId == ''">thumb_down</mat-icon>
        </button> -->

        <!-- <button type="button" mat-icon-button *ngIf="row.aiUrl !== ''" color="accent"
          (click)="ai.onNavigate(row.aiUrl)" title="Open in New Tab" matTooltipPosition="below">
          <mat-icon class="i-1_7x">open_in_new</mat-icon>
        </button> -->
      </mat-card-actions>

      <!-- <div class="report">
        {{row?.report}}
        <button type="button" mat-icon-button title="Add to Executive Report" matTooltipPosition="below"
          [matTooltipClass]="'md-fs'" (click)="ai.addremoveReport(row)">
          <mat-icon class="i-2x" color="{{row?.report == '1'?'primary':''}}">files</mat-icon>
        </button>
      </div> -->

      <div class="badgelabel" *ngIf="!ai.showWish">
        <mat-icon fontSet="i" attr.aria-label="{{getDeepcat(ai.deepcat)}}" class="i i-{{getIcon(ai.fixaitypeId)}}"
          title="{{getTooltip(ai.fixaitypeId)}}" matTooltipPosition="below"
          *ngIf="ai.tagcloudclick||!ai.showWish||ai.notesviewflag">
        </mat-icon>
        <!-- <span class="i-stack" *ngIf="starflag(row.aiTitle)">
            <i class="i i-{{getIcon(row.aiNameTemp,row.aiTitle)}}" title="{{row.aiNameTemp}}"
              matTooltipPosition="below"></i>
            <i class="i i-star"></i>
          </span> -->
        <!-- <mat-icon fontSet="i" attr.aria-label="{{ai.aiNameTemp}}"
            class="i i-{{getIcon(ai.aiNameTemp)}}" title="{{ai.aiNameTemp}}"
            matTooltipPosition="below" *ngIf="!ai.tagcloudclick||!ai.showWish||!ai.notesviewflag"></mat-icon> -->
      </div>

      <div class="badgelabel" *ngIf="ai.showWish">
        <mat-icon fontSet="i" attr.aria-label="{{getDeepcat(ai.deepcat)}}" class="i i-{{getIcon(ai.fixaitypeName)}}"
          title="{{getTooltip(ai.fixaitypeName,'ff')}}" matTooltipPosition="below">
        </mat-icon>
      </div>

      <!-- <img mat-card-image [src]="row.aiImages | safe: 'url'" [alt]="row.aiTitle" *ngIf="row.aiImages !== ''">
      <div mat-card-image *ngIf="row.aiVideos !== ''">
          <iframe frameborder="0" width="100%" [src]="row?.aiVideos | safe: 'url'"></iframe>
      </div> -->
      <mat-card-content>
        <div class="w-100" *ngIf="!ai.showWish">
          <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="row.aiDate !== ''||row.aiDate!== undefined">
            <i class="i i-calendar i-1_5x ib-m" *ngIf="row.aiDate.length!=0"></i>
            <!-- <mat-icon *ngIf="row.aiDate.length!=0" fontSet="i" class="i-date_range i-1_5x ib-m"></mat-icon> -->
            <span class="ib-m">{{row.aiDate}}</span>
          </div>
        </div>

        <div class="w-100">
          <!-- <div class="m-b-5">{{row.aiNameTemp}}</div>-->
          <div class="m-b-5" [innerHTML]="ai.titleManager(row.aiTitle)"></div>

          <!-- <div class="m-b-5">{{}}</div> -->
          <a [href]="row.aiUrl" target="_blank">
            <div class="text-accent" title="Open in New Tab" matTooltipPosition="below">{{row.aiUrl}}</div>
          </a>
          <div class="m-t-5" [innerHTML]="row.aiText"></div>
          <div class="ib-m"></div>
        </div>
        <div class="m-t-10 readmore t-r"
          *ngIf="(row.aiTechDomain!== ''||row.aiClaim!== '')&&(row.aiTechDomain!== undefined||row.aiClaim!== undefined)"
          (click)="patentModal(row)">
          Read More...
        </div>
        <div href="javascript:;" (click)="testfn(row)" *ngIf="row.noteAdd" title="Click to Add/Edit Note"
          matTooltipPosition="below" class="notes">{{row.noteAdd}}
        </div>
      </mat-card-content>

      <div class="btnWrap">

        <button type="button" matripple class="btn btn-primary btn-lg t-c" [matMenuTriggerFor]="deepDiveMenu"
          matTooltipClass="md-fs" title="Deep dive for related insights" matTooltipPosition="below">
          Deep dive for related insights
        </button>
        <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">
          <!-- <div class="rel infoHover" tabindex="0">
            <div class="infoTip" *ngIf="costsTime">Require 60 secs</div>
            <button mat-menu-item (click)="insightDeep(row.aiId,'1')" (mouseenter)="costsTime=true"
              (mouseout)="costsTime=false">
              All Insights
            </button>
          </div> -->
          <!-- <button mat-menu-item (click)="insightDeep(row.aiId,'2')">{{ai.catName('2',ai.view)}}</button>
        <button mat-menu-item (click)="insightDeep(row.aiId,'3')">{{ai.catName('3',ai.view)}}</button>
        <button mat-menu-item (click)="insightDeep(row.aiId,'10')">{{ai.catName('10',ai.view)}}</button>
  
        <button mat-menu-item (click)="insightDeep(row.aiId,'5')">{{ai.catName('5',ai.view)}}</button>
        <button mat-menu-item (click)="insightDeep(row.aiId,'8')">
          {{ai.catName('8',ai.view)}}</button>
        <button mat-menu-item (click)="insightDeep(row.aiId,'6')">{{ai.catName('6',ai.view)}}</button>
        <button mat-menu-item (click)="insightDeep(row.aiId,'7')">{{ai.catName('7',ai.view)}}</button>
  
        <button mat-menu-item (click)="insightDeep(row.aiId,'4')">{{ai.catName('4',ai.view)}}</button>
        <button mat-menu-item (click)="insightDeep(row.aiId,'11')">{{ai.catName('11',ai.view)}}</button>
  
        <button mat-menu-item (click)="insightDeep(row.aiId,'9')"
          *ngIf="ai.featureChecker(18)">{{ai.catName('9',ai.view)}}</button> -->



          <h2 class="subTitle">Market Intelligence</h2>
          <button mat-menu-item (click)="insightDeep(row.aiId,'31')">{{ai.catName('31',ai.view)}}</button>
          <button mat-menu-item (click)="insightDeep(row.aiId,'6')">{{ai.catName('6',ai.view)}}</button>
          <button mat-menu-item (click)="insightDeep(row.aiId,'10')">{{ai.catName('10',ai.view)}}</button>

          <!-- <h2 class="subTitle">Competitive Intelligence</h2> -->
          <button mat-menu-item (click)="insightDeep(row.aiId,'2')">{{ai.catName('2',ai.view)}}</button>
          <button mat-menu-item (click)="insightDeep(row.aiId,'32')">{{ai.catName('32',ai.view)}}</button>
          <button mat-menu-item (click)="insightDeep(row.aiId,'8')">{{ai.catName('8',ai.view)}}</button>

          <h2 class="subTitle">Product R&D Intelligence</h2>
          <button mat-menu-item (click)="insightDeep(row.aiId,'11')">{{ai.catName('11',ai.view)}}</button>
          <button mat-menu-item (click)="insightDeep(row.aiId,'7')">{{ai.catName('7',ai.view)}}</button>

          <!-- <h2 class="subTitle">Supply Chain Insights</h2> -->
          <button mat-menu-item (click)="insightDeep(row.aiId,'5')">{{ai.catName('5',ai.view)}}</button>

          <!-- <h2 class="subTitle">People Related Insights</h2> -->

          <!-- <h2 class="subTitle">Intellectual Property(IP) Insights</h2> -->
          <button mat-menu-item (click)="insightDeep(row.aiId,'4')">{{ai.catName('4',ai.view)}}</button>

          <h2 class="subTitle">Business Intelligence</h2>

          <button mat-menu-item (click)="insightDeep(row.aiId,'9')"
            *ngIf="ai.featureChecker(18)">{{ai.catName('9',ai.view)}}</button>

        </mat-menu>
      </div>

    </mat-card>

  </div>

  <!-- Bricks for Support -->
  <!-- <div class="mg-brick h-120px"></div>
    <div class="mg-brick h-120px"></div>
    <div class="mg-brick h-120px"></div> -->
  <!-- Bricks for Support -->
</div>
<div class="t-c m-y-20 d-flex justify-content-center" *ngIf="ai.searchinsightDetails">
  <button matRipple (click)="ai.searchPrevclick()" [disabled]="ai.searchPageno<=ai.searchPageSize"
    class="btn btn-lg btn-primary m-r-10">
    <mat-icon class="i-1_7x" fontSet="i" aria-label="Previous">keyboard_arrow_left</mat-icon>
    <span class="ib-m p-l-10">Previous</span>
  </button>
  <button matRipple (click)="ai.searchNextclick()" [disabled]="ai.searchinsightDetails?.length<=ai.searchPagemax"
    class="btn btn-lg btn-primary m-r-10">
    <span class="ib-m p-l-5">Next</span>
    <mat-icon class="i-1_7x" fontSet="i" aria-label="Next">keyboard_arrow_right</mat-icon>
  </button>
</div>

<ng-template #secondDialog>
  <form [formGroup]="ai.view.form">
    <div class="h-100 scrollY b-patent" style="width:800px;">
      <mat-card class="m-a-10">
        <mat-card-content>
          <div class="w-100">
            <div class="t-c">
              <div class="d-flex">
                <label class="bigLabel">Capture your notes</label>
              </div>
              <mat-form-field class="fw m-b-10 sm" floatLabel="never" appearance="outline" title=""
                matTooltipPosition="above">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="notes"
                  autocomplete="off" placeholder="Record your Notes" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"
                  title="Record your Notes Here" (keyup)="ai.txtchange($event.target.value)"></textarea>
                <!-- <mat-error *ngIf="view.invalid('notes')">{{view.error('notes')}}</mat-error>-->
              </mat-form-field>
              <div class="m-t-15">
                <button type="button" title="Delete the Notes Entered" matTooltipPosition="above"
                  *ngIf="ai.view.value('notesId')" matRipple (click)="deleteNote()"
                  class="btn btn-lg btn-danger lighten m-x-5">
                  <!--                  <mat-icon fontSet="i" class="i-delete i-1_5x ib-m"></mat-icon>-->
                  <span class="ib-m-l-5">Delete Note</span>
                </button>
                <button type="button" (click)="cancelNote()" matRipple
                  class="m-x-5 btn-cancel btn btn-lg btn-default lighten">Cancel</button>
                <button type="button" (click)="saveNote()" matRipple class="btn btn-lg btn-primary m-x-5"
                  [disabled]="!ai.newnotesave">Save</button>
                <div class="ib-m">
                  <button type="button" title="Save and Post to MS Teams Channel" matTooltipPosition="above"
                    [disabled]="!ai.newnotesave" *ngIf="ai.msstatus&&ai.mschannelid!= null" matRipple
                    (click)="saveNotems()" class="ib-m m-r-15 t-normal f-400 btn btn-lg btn-primary">
                    <i class="i i-ms-team"></i>
                    <!-- <mat-icon fontSet="i" class="i-ms-team i-1_5x ib-m"></mat-icon> -->
                    <span class="ib-m-l-5">Save and Post to MS Teams</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</ng-template>