<div>
  <div class="d-block p-a-0">
    <h4 class="text-accent">
      <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" (click)="backtoPage()" title="Close">
        <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
      </button>
    </h4>
    <mat-card-header>
      <div mat-card-avatar class="b-a-1 t-c"><i class="i i-trending_up i-2_5x text-primary"></i></div>
      <mat-card-title>InsIQ Trends Interest over Time</mat-card-title>
      <mat-card-subtitle>{{data.charttitle}}</mat-card-subtitle>
    </mat-card-header>

    <div class="w-100">
      <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="data.trendyr !== ''">
        <mat-icon fontSet="i" class="i-poll i-1_5x ib-m"></mat-icon>
        <span class="ib-m">{{data.trendyr}}</span>
      </div>
      <!-- <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="ai.trendcountry !== ''">
        <mat-icon fontSet="i" class="i-pin_drop i-1_5x ib-m"></mat-icon>
        <span class="ib-m">{{ai.trendcountry}}</span>
      </div> -->
      <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="data.trenddate !== ''">
        <i class="i i-calendar i-1_5x ib-m"></i>
        <!-- <mat-icon fontSet="i" class="i-date_range i-1_5x ib-m"></mat-icon> -->
        <span class="ib-m">{{data.trenddate}}</span>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-end">
    <button type="button" matRipple (click)="aiv.toggleView()"
      title="{{aiv.listViewInsight?'Switch to Grid View':'Switch to List View'}}" mat-mini-fab
      class="ib-m m-x-5 m-b-20">
      <i *ngIf="aiv.listViewInsight" class="i i-grid"></i>
      <i *ngIf="!aiv.listViewInsight" class="i i-list"></i>
    </button>
    <!-- Add more buttons or elements here if needed -->
  </div>

  <div class="alertdiv">
    <mat-card class="tableCard" *ngIf="aiv.listViewInsight">
      <p-table [value]="data.trendDetails">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols">{{ col.header }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>

            <td><button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="aiDroppWrap">
                <div class="menuInner">
                  <div class="topBtn">
                    <button mat-menu-item type="button" [matMenuTriggerFor]="deepDiveMenu" matRipple
                      matTooltipClass="md-fs" title="Deep dive for related insights" matTooltipPosition="below"
                      class="btn btn-primary btn-lg t-c ">
                      Deep dive for related insights
                    </button>
                  </div>
                  <div class="lineBtm">

                    <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
              active: row.liked
            }" (click)="likeOrUnlike(row, 1)" title="{{row.liked?'Remove Favorite':'Mark as Favorite'}}"
                      *ngIf="(!aiv.showWish||aiv.inddeep) && (!row.liked || !(row.liked?.likeon == '0'))">
                      <i class="i i-favourite"></i> {{row.liked?'Remove favorite':'Mark as favorite'}}
                    </button>
                    <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
              active: row.report == '1'
             }" title="{{row?.report == '1'?' Remove':'Add to Report'}}" (click)="addremoveReport(row)">
                      <i class="i i-ai_report"></i> {{row?.report == '1'?' Remove from report':'Add to Report'}}
                    </button>
                    <button mat-menu-item type="button" class="btn btn-link leftBtn" [ngClass]="{
                      active: row?.notesId!==null
                     }" title="{{row?.notesId!==null?trimtooltip(row.noteAdd):'Add Notes'}}" (click)="testfn(row)">
                      <i class="i i-notes"></i> {{row?.notesId!==null?'Edit Notes':'Add Notes'}}
                    </button>
                    <button mat-menu-item type="button" class="btn btn-link leftBtn" (click)="copyText(row.aiurl)"
                      title="Copy" aria-label="Copy">
                      <i class="i i-copy"></i>Copy
                    </button>

                    <button mat-menu-item type="button" class="btn btn-link leftBtn" title="Share Insight"
                      [matMenuTriggerFor]="shareMenu">
                      <i class="i i-share"></i>Share Insight
                    </button>
                  </div>
                </div>
              </mat-menu>

              <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">
                <!-- <div class="rel infoHover" tabindex="0">
                <div class="infoTip" *ngIf="costsTime">This might take a little longer</div>
                <button mat-menu-item (click)="ai.insightDeep(row.aiId,'1')" (mouseenter)="costsTime=true"
                  (mouseout)="costsTime=false">
                  All Insights
                </button>
              </div> -->
                <h2 class="subTitle">Market Intelligence</h2>
                <button mat-menu-item (click)="insightDeep(row.aiId,'31')">{{aiv.catName('31',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'6')">{{aiv.catName('6',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'10')">{{aiv.catName('10',
                  aiv.view)}}</button>

                <!-- <h2 class="subTitle">Competitive Intelligence</h2> -->

                <button mat-menu-item (click)="insightDeep(row.aiId,'2')">{{aiv.catName('2',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'32')">{{aiv.catName('32',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'8')">
                  {{aiv.catName('8', aiv.view)}}</button>
                <h2 class="subTitle">Product R&D Intelligence</h2>
                <button mat-menu-item (click)="insightDeep(row.aiId,'11')">{{aiv.catName('11',
                  aiv.view)}}</button>
                <button mat-menu-item (click)="insightDeep(row.aiId,'7')">{{aiv.catName('7',
                  aiv.view)}}</button>

                <!-- <h2 class="subTitle">Supply Chain Insights</h2> -->
                <button mat-menu-item (click)="insightDeep(row.aiId,'5')">{{aiv.catName('5',
                  aiv.view)}}</button>
                <!-- <h2 class="subTitle">People Related Insights</h2> -->



                <!-- <h2 class="subTitle">Intellectual Property(IP) Insights</h2> -->

                <button mat-menu-item (click)="insightDeep(row.aiId,'4')">{{aiv.catName('4',
                  aiv.view)}}</button>


                <h2 *ngIf="aiv.featureChecker(18)" class="subTitle">Business Intelligence</h2>

                <button *ngIf="aiv.featureChecker(18)" mat-menu-item
                  (click)="insightDeep(row.aiId,'9')">{{aiv.catName('9', aiv.view)}}</button>
              </mat-menu>
              <mat-menu #shareMenu="matMenu" xPosition="after" yPosition="above" [overlapTrigger]="false">
                <ng-template matMenuContent>
                  <!-- <h2 class="subTitle">Heading 6</h2> -->
                  <button type="button" mat-icon-button color="accent"
                    (click)="linkedinshre($event,row.aiurl,row.aiTitle)" title="LinkedIn Share"
                    [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-linkedin"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiurl)"
                    title="Twitter Share" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-twitter"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiurl)"
                    [matTooltipClass]="'md-fs'" title="Facebook Share" matTooltipPosition="below">
                    <i class="i i-facebook"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent"
                    (click)="watzappshare($event,row.aiurl,row.aiTitle)" title="Whatsapp Share"
                    [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-whatsapp"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" *ngIf="aiv.msstatus&&aiv.mschannelid!= null"
                    (click)="aiv.teamshare(row.aiNameTemp,row.aiurl,row.aiTitle,row.aiText)"
                    title="Post in MS Teams Channel" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-ms-team"></i>
                  </button>
                </ng-template>
              </mat-menu>
            </td>
            <td *ngIf="row.aiImages">

              <a [innerHTML]="aiv.titleManager(splitKeywordsAndTitle(row.aiTitle).title)"
                (click)="openLinkInNewTab(row.aiurl)"></a>
            </td>
            <td *ngIf="!row.aiImages">

              <a [innerHTML]="aiv.titleManager(row.aiTitle)" (click)="openLinkInNewTab(row.aiurl)"></a>
            </td>
            <!-- <td [innerHTML]="ai.aiTexttrim(row.aiText)" title="{{row.aiText}}"></td> -->
            <td>

              <div class="showTooltip">
                <ng-container *ngIf="row.aiVideos; else showTextOrImage">
                  <div mat-card-image *ngIf="row.aiVideos !== '' && row.aiVideos" class="m-t-10 m-l-30 m-b-20">
                    <!-- Use iframe to embed YouTube video -->
                    <iframe width="250" height="150"
                      [src]="transform(aiv.getVideoembed(row.aiVideos)) | safe: 'resourceUrl'" frameborder="0"
                      allowfullscreen></iframe>
                  </div>
                  <div [innerHTML]="row.aiText"></div>
                </ng-container>
                <ng-template #showTextOrImage>
                  <ng-container *ngIf="!row.aiImages; else showImage">
                    <div [innerHTML]="row.aiText"></div>
                  </ng-container>
                  <ng-template #showImage>
                    <!-- Display the image placeholder here -->
                    <img [src]="transform(row.aiImages) | safe: 'url'" alt="{{ aiv.titleManager(row.aiTitle)}}"
                      width="100" height="100">
                  </ng-template>
                </ng-template>
                <div class="overlayTooltip" *ngIf="aiv.aiTexttrim(row.aiText)!==row.aiText">
                  <div [innerHTML]="row.aiText"></div>
                </div>
              </div>
            </td>



          </tr>
        </ng-template>
      </p-table>
    </mat-card>
    <div class="mg p-b-30" *ngIf="!aiv.listViewInsight">
      <div class="mg-brick" *ngFor="let row of data.trendDetails">
        <mat-card class="aiCard m-b-10 privilaged" [class.mat-elevation-z8]="true">
          <!-- <div class="report">
                <mat-icon fontSet="i" attr.aria-label="Add to Executive Report"
                  class="i i-files"
                  title="Add to Executive Report" matTooltipPosition="below">
                </mat-icon>
              </div> -->
          <!-- <div class="report">
                {{row?.report}}
              </div> -->

          <!-- *ngIf="true" -->
          <!-- <div class="verified"
            title="Insight Source Reputation">  
          </div> -->
          <!-- <div class="badge" *ngIf="!ai.showWish">
                <mat-icon fontSet="i" attr.aria-label="{{row.aiNameIdTemp}}"
                  class="i i-{{getIcon(row.aiNameIdTemp,row.aiTitle)}}"
                  title="{{getTooltip(row.aiNameIdTemp,row.aiTitle,ai.desiagnApiCall)}}"
                  matTooltipPosition="below" *ngIf="ai.tagcloudclick||!ai.showWish||ai.notesviewflag">
                </mat-icon>
               <span class="i-stack" *ngIf="starflag(row.aiTitle)">
                  <i class="i i-{{getIcon(row.aiNameTemp,row.aiTitle)}}" title="{{row.aiNameTemp}}"
                    matTooltipPosition="below"></i>
                  <i class="i i-star"></i>
                </span>
                 <mat-icon fontSet="i" attr.aria-label="{{ai.aiNameTemp}}"
                class="i i-{{getIcon(ai.aiNameTemp)}}" title="{{ai.aiNameTemp}}"
                matTooltipPosition="below" *ngIf="!ai.tagcloudclick||!ai.showWish||!ai.notesviewflag"></mat-icon> 
              </div> -->
          <mat-card-actions class="b-t-1">
            <ul class="buttonWrap">
              <li>
                <button type="button" mat-icon-button title="{{row.liked ? 'Remove Favorite' : 'Mark as Favorite'}}"
                  matTooltipPosition="below" [matTooltipClass]="'md-fs'" [ngClass]="{
                    active: row.liked
                  }" *ngIf="(!showWish||aiv.inddeep) && (!row.liked || !(row.liked?.likeon == '0'))"
                  (click)="likeOrUnlike(row, 1)">
                  <i class="i i-favourite"></i>
                  <!-- <mat-icon class="i-2x" color="{{row.liked ?'primary':''}}">star</mat-icon> -->
                </button>
              </li>
              <li>
                <button type="button" mat-icon-button title="{{row?.report == '1' ? ' Remove' : 'Add to Report'}}"
                  matTooltipPosition="below" [matTooltipClass]="'md-fs'" (click)="addremoveReport(row)" [ngClass]="{
                  active: row.report == '1'
                }">
                  <i class="i i-ai_report"></i>
                  <!-- <mat-icon class="i-ai_report i-2x" color="{{row.report == '1'?'primary':''}}"></mat-icon> -->
                </button>
              </li>
              <li>
                <button type="button" mat-icon-button (click)="testfn(row)" [matTooltipClass]="'md-fs'"
                  [title]="row?.notesId!==null?trimtooltip(row.noteAdd):'Add Notes'" [ngClass]="{
                  active: row?.notesId!==null
                }" [title]="row?.notesId?trimtooltip(row.noteAdd):'Add Notes'">
                  <i class="i i-notes"></i>
                  <!-- <mat-icon class="i-1_7x" *ngIf="!row?.notesId">note_add</mat-icon>
                  <mat-icon class="i-1_7x" *ngIf="row?.notesId" color="primary">speaker_notes</mat-icon> -->
                </button>
              </li>
              <li>
                <button type="button" mat-icon-button *ngIf="row.aiurl !== ''" (click)="aiv.copyText(row.aiurl)"
                  title="Copy" matTooltipPosition="below">
                  <i class="i i-copy"></i>
                  <!-- <mat-icon class="i-1_7x">content_copy</mat-icon> -->
                </button>
              </li>
              <li>
                <button type="button" mat-icon-button title="Share Insight" [matMenuTriggerFor]="helpMenu">
                  <i class="i i-share"></i>
                  <!-- <mat-icon class="i-1_7x">share</mat-icon> -->
                </button>

              </li>
            </ul>

            <div class="d-flex align-items-center">
              <!-- (click)="ai.insightDeep(row.aiId)" -->
              <!-- <button type="button" mat-mini-fab [matMenuTriggerFor]="deepDiveMenu1" color="accent"
                    matTooltipClass="md-fs" title="Deep dive for related insights"
                    matTooltipPosition="below" class="m-r-5" *ngIf="ai.desiagnApiCall">
                    <mat-icon fontSet="i" class="i-deep_dive i-1_7x ib-m"></mat-icon>
                  </button>
                  <mat-menu #deepDiveMenu1="matMenu" yPosition="above" xPosition="after">
                    <div class="rel infoHover" tabindex="0">
                      <div class="infoTip" *ngIf="costsTime">This might take a little longer</div>
                      <button mat-menu-item (click)="ai.insightDeep(row.aiId,'1')" (mouseenter)="costsTime=true"
                        (mouseout)="costsTime=false">
                        All Design Insights
                      </button>
                    </div>
                    <button mat-menu-item (click)="ai.insightDeep(row.aiId,'2')">{{ai.designcatName('2')}}</button>
                    <button mat-menu-item (click)="ai.insightDeep(row.aiId,'3')">{{ai.designcatName('3')}}</button>
                    <button mat-menu-item (click)="ai.insightDeep(row.aiId,'10')">{{ai.designcatName('4')}}</button>

                    <button mat-menu-item (click)="ai.insightDeep(row.aiId,'5')">{{ai.designcatName('5')}}</button>
                    <button mat-menu-item (click)="ai.insightDeep(row.aiId,'8')">
                      {{ai.catName('8', view)}}</button>
                    <button mat-menu-item (click)="ai.insightDeep(row.aiId,'6')">{{ai.designcatName('6', view)}}</button>
                    <button mat-menu-item (click)="ai.insightDeep(row.aiId,'7')">{{ai.designcatName('7', view)}}</button>

                    <button mat-menu-item (click)="ai.insightDeep(row.aiId,'4')">{{ai.designcatName('8', view)}}</button>
                    <button *ngIf="config.currentUser?.isbtob==1" mat-menu-item
                      (click)="ai.insightDeep(row.aiId,'9')">{{ai.catName('9', view)}}</button>
                  </mat-menu>-->
              <!-- <button type="button" mat-icon-button (click)="ai.likeOrUnlike(row, 0)"
                    title="Not Useful" matTooltipPosition="below"
                    *ngIf="!ai.showWish && (!row.liked || !(row.liked?.likeon == '1'))">
                    <mat-icon class="i-1_7x" color="{{row.liked?.likeon == '0'?'warn':''}}">thumb_down
                    </mat-icon>
                  </button> -->
              <!-- <div class="spacer"></div> -->
              <!-- <button type="button" mat-icon-button class="reportIndicator"
                    matTooltipPosition="below" [matTooltipClass]="'md-fs'" >
                    <mat-icon  class="i-ai_report" color="accent">
                    </mat-icon>
                    <mat-icon *ngIf="row?.report == '1'" class="i-playlist_add_check"
                      color="{{row?.report == '1'?'primary':''}}"></mat-icon>
                  </button> -->
              <!-- color="{{!row?.notesId || row?.notesId == null || row?.notesId == '' ? 'accent' : ''}}" -->
              <!-- color="{{!row?.notesId || row?.notesId == null || row?.notesId == '' ? 'accent' : ''}}"-->
              <!-- <button *ngIf="ai.showWish && row && row.notesId && row.notesId != null && row.notesId != ''"
                    type="button" mat-icon-button
                    (click)="ai.addOrEditNotes(row)" title="Notes" matTooltipPosition="below">
                    <mat-icon class="i-1_7x" ngClass="mat-accent : !row?.notesId || row?.notesId == null || row?.notesId == ''">thumb_down</mat-icon>
                  </button> -->
              <!-- <button type="button" mat-icon-button *ngIf="row.aiUrl !== ''" color="accent"
                    (click)="ai.onNavigate(row.aiUrl)" title="Open in New Tab" matTooltipPosition="below">
                    <mat-icon class="i-1_7x">open_in_new</mat-icon>
                  </button> -->
              <!-- <button type="button" mat-icon-button title="Remove Notes"
                (click)="ai.removenote(row)" [matTooltipClass]="'md-fs'" matTooltipPosition="below"
                *ngIf="ai.notesviewflag">
                <mat-icon class="i-1_7x">delete</mat-icon>
              </button> -->
              <mat-menu #helpMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
                <ng-template matMenuContent>
                  <button (click)="linkedinshre($event,row.aiurl,row.aiTitle)" type="button" mat-icon-button
                    color="accent" title="LinkedIn Share" matTooltipPosition="below">
                    <i class="i i-linkedin"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" (click)="twittershare($event,row.aiurl)"
                    title="Twitter Share" matTooltipPosition="below">
                    <i class="i i-twitter"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" (click)="fbshare($event,row.aiurl)"
                    title="Facebook Share" matTooltipPosition="below">
                    <i class="i i-facebook"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent"
                    (click)="watzappshare($event,row.aiurl,row.aiTitle)" title="Whatsapp Share"
                    matTooltipPosition="below">
                    <i class="i i-whatsapp"></i>
                  </button>
                  <button type="button" mat-icon-button color="accent" *ngIf="aiv.msstatus&&aiv.mschannelid!= null"
                    (click)="aiv.teamshare(row.aiName,row.aiurl,row.aiTitle,row.aiText)"
                    title="Post in MS Teams Channel" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                    <i class="i i-ms-team"></i>
                  </button>
                </ng-template>
              </mat-menu>
            </div>
          </mat-card-actions>
          <!-- <div class="badgelabel icoBagde"
            *ngIf="ai.hasRecord == 'true' && ai.dashboard !== true && ai.smartlist &&!ai.InsIQclickflg&&!ai.chartReports&&!ai.sentimentclickflg&&!ai.averagePrice&&!ai.partnerclickflg&&!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch">
            <i class="i i-shield_cancel" *ngIf="(row.airank===0||row.airank=== ''||row.airank=== undefined)"
              title="Insight Source Reputation"></i>
            <i class="i i-shield_yes active _verified" *ngIf="(row.airank===1)"
              title="Insight Source Reputation"></i>
            <i class="i i-doc_na" *ngIf="!row?.title?.includes('Curated')" title="InsIQ Curated"></i>
            <i class="i i-doc_star active _bookMark" *ngIf="row?.title?.includes('Curated')"
              title="InsIQ Curated"></i>
          </div> -->
          <!-- <div class="badgelabel"
            *ngIf="ai.hasRecord == 'true' && ai.dashboard !== true && ai.smartlist &&!ai.InsIQclickflg&&!ai.chartReports&&!ai.sentimentclickflg&&!ai.averagePrice&&!ai.partnerclickflg&&!ai.showWish&&!ai.notesviewflag&&!ai.reportviewflag&&!ai.insightsearch">
            <span class="">Insight Source Reputation</span> -->
          <!-- <i
                  class="i i-unverified_rounded"
                  *ngIf="(row.airank===0||row.airank=== ''||row.airank=== undefined)"></i>
                <i class="i i-verified_rounded text-success" *ngIf="(row.airank===1)"></i> -->
          <!-- </div> -->
          <div class="badgelabel">
            <span class="">Trends Interest over Time</span>
          </div>

          <!-- <div class="badge" *ngIf="ai.showWish">
            <mat-icon fontSet="i" attr.aria-label="{{ai.catId(row.aiName)}}"
              class="i i-{{getIcon(ai.catId(row.aiName),row.aiTitle)}}"
              title="{{getTooltip(ai.catId(row.aiName),row.aiTitle,ai.desiagnApiCall)}}"
              matTooltipPosition="below">
            </mat-icon>
          </div> -->
          <!-- <img mat-card-image [src]="transform(row.aiImages) | safe: 'url'" [alt]="row.aiTitle" class="m-t-10"
            *ngIf="row.aiImages && row.aiImages !== ''">
          <div mat-card-image *ngIf="row.aiVideos !== '' && row.aiVideos" class="m-t-10">
            <video frameborder="0" width="100%"
              [src]="transform(ai.getVideoembed(row.aiVideos)) |safe:'resourceUrl'"></video>

          </div> -->
          <mat-card-content>
            <!-- <div class="w-100 m-b-10" *ngIf="!ai.showWish" title="Insight Source Reputation">
                  <mat-icon class="i-verified_rounded i-2_5x ib-m fleshicon" *ngIf="row.airank==0"></mat-icon>
                  <mat-icon class="i-verified_rounded i-2_5x ib-m" color="primary" *ngIf="row.airank==1"></mat-icon>
                </div> -->

            <!-- <div class="w-100 dateWrap" *ngIf="!ai.showWish">
              <div class="ib-m m-r-15 curved text-accent t-normal f-400" *ngIf="row.aiDate">
                <mat-icon fontSet="i" class="i-date_range i-1_5x ib-m"></mat-icon>
                <span class="ib-m">{{row.aiDate}}</span>
              </div>
            </div> -->
            <div class="w-100">
              <!-- <div class="m-b-5">{{row.aiNameTemp}}</div>-->
              <div class="title" [innerHTML]="row.aiTitle"></div>
              <a [href]="row.aiurl" target="_blank">
                <div class="linkText" title="Open in New Tab" [matTooltipClass]="'md-fs'" matTooltipPosition="below">
                  {{row.aiurl}}</div>
              </a>
              <!-- <div class="ib-m m-r-15 curved text-primary t-normal f-400" *ngIf="row.aiPrice">
                    <mat-icon fontSet="i" class="i-price_tag i-1_5x ib-m"></mat-icon>
                    <span class="ib-m">{{row.aiPrice}}</span>
                  </div> -->

              <div class="m-t-5 aiTextWrap" [innerHTML]="row.aiText"></div>

              <!-- <div class="m-t-5" innerHTML="{{row.aiCountry}}"></div>
                  <div class="m-t-5" innerHTML="{{row.aiTechDomain}}"></div>
                  <div class="m-t-5" innerHTML="{{row.aiClaim}}"></div> -->

            </div>

            <div *ngIf="row?.notesId!==null&&aiv.notesviewflag" tabindex="0" (click)="aiv.addOrEditNotes(row)"
              [matTooltipClass]="'md-fs'" title="Click to Add/Edit Note" matTooltipPosition="below" class="notes">
              {{row.noteAdd}}
            </div>
          </mat-card-content>

          <!-- <button type="button" mat-mini-fab [matMenuTriggerFor]="deepDiveMenu" color="accent"
                matTooltipClass="md-fs" title="Deep dive for related insights" matTooltipPosition="below"
                class="m-r-5" *ngIf="!ai.desiagnApiCall">
                <mat-icon fontSet="i" class="i-deep_dive i-1_7x ib-m"></mat-icon>
              </button> -->
          <div class="btnWrap">
            <button type="button" [matMenuTriggerFor]="deepDiveMenu" matRipple matTooltipClass="md-fs"
              title="Deep dive for related insights" matTooltipPosition="below" class="btn btn-primary btn-lg t-c">
              Deep dive for related insights
            </button>
          </div>
          <mat-menu #deepDiveMenu="matMenu" yPosition="above" xPosition="after">
            <!-- <div class="rel infoHover" tabindex="0">
                <div class="infoTip" *ngIf="costsTime">This might take a little longer</div>
                <button mat-menu-item (click)="ai.insightDeep(row.aiId,'1')" (mouseenter)="costsTime=true"
                  (mouseout)="costsTime=false">
                  All Insights
                </button>
              </div> -->
            <h2 class="subTitle">Market Intelligence</h2>
            <button mat-menu-item (click)="insightDeep(row.aiId,'31')">{{aiv.catName('31',
              aiv.view)}}</button>
            <button mat-menu-item (click)="insightDeep(row.aiId,'6')">{{aiv.catName('6',
              aiv.view)}}</button>
            <button mat-menu-item (click)="insightDeep(row.aiId,'10')">{{aiv.catName('10',
              aiv.view)}}</button>

            <!-- <h2 class="subTitle">Competitive Intelligence</h2> -->

            <button mat-menu-item (click)="insightDeep(row.aiId,'2')">{{aiv.catName('2',
              aiv.view)}}</button>
            <button mat-menu-item (click)="insightDeep(row.aiId,'32')">{{aiv.catName('32',
              aiv.view)}}</button>
            <button mat-menu-item (click)="insightDeep(row.aiId,'8')">
              {{aiv.catName('8', aiv.view)}}</button>
            <h2 class="subTitle">Product R&D Intelligence</h2>
            <button mat-menu-item (click)="insightDeep(row.aiId,'11')">{{aiv.catName('11',
              aiv.view)}}</button>
            <button mat-menu-item (click)="insightDeep(row.aiId,'7')">{{aiv.catName('7',
              aiv.view)}}</button>

            <!-- <h2 class="subTitle">Supply Chain Insights</h2> -->
            <button mat-menu-item (click)="insightDeep(row.aiId,'5')">{{aiv.catName('5',
              aiv.view)}}</button>
            <!-- <h2 class="subTitle">People Related Insights</h2> -->



            <!-- <h2 class="subTitle">Intellectual Property(IP) Insights</h2> -->

            <button mat-menu-item (click)="insightDeep(row.aiId,'4')">{{aiv.catName('4',
              aiv.view)}}</button>


            <h2 class="subTitle">Business Intelligence</h2>

            <button mat-menu-item (click)="insightDeep(row.aiId,'9')">{{aiv.catName('9',
              aiv.view)}}</button>
          </mat-menu>

        </mat-card>
      </div>

      <!-- Bricks for Support -->
      <!-- <div class="mg-brick h-120px"></div>
      <div class="mg-brick h-120px"></div>
      <div class="mg-brick h-120px"></div> -->
      <!-- Bricks for Support -->
    </div>
  </div>
</div>

<ng-template #secondDialog>
  <form [formGroup]="aiv.view.form">
    <div class="h-100 scrollY b-patent" style="width:800px;">
      <mat-card class="m-a-10">
        <mat-card-content>
          <div class="w-100">
            <div class="t-c">
              <div class="d-flex">
                <label class="bigLabel">Capture your notes</label>
              </div>
              <mat-form-field class="fw m-b-10 sm" floatLabel="never" appearance="outline" title=""
                matTooltipPosition="above">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="notes"
                  autocomplete="off" placeholder="Record your Notes" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"
                  title="Record your Notes Here" (keyup)="aiv.txtchange($event.target.value)"></textarea>
                <!-- <mat-error *ngIf="view.invalid('notes')">{{view.error('notes')}}</mat-error>-->
              </mat-form-field>
              <div class="m-t-15">
                <button type="button" title="Delete the Notes Entered" matTooltipPosition="above"
                  *ngIf="aiv.view.value('notesId')" matRipple (click)="deleteNote()"
                  class="btn btn-lg btn-danger lighten m-x-5">
                  <!-- <mat-icon fontSet="i" class="i-delete i-1_5x ib-m"></mat-icon> -->
                  <span class="ib-m-l-5">Delete Note</span>
                </button>
                <button type="button" (click)="cancelNote()" matRipple
                  class="btn btn-lg btn-default lighten m-x-5 btn-cancel">Cancel</button>





                <button type="button" (click)="saveNote()" matRipple class="btn btn-lg btn-primary m-x-5"
                  [disabled]="!aiv.newnotesave">Save</button>
                <div class="ib-m">
                  <button type="button" title="Save and Post to MS Teams Channel" matTooltipPosition="above"
                    [disabled]="!aiv.newnotesave" *ngIf="aiv.msstatus&&aiv.mschannelid!= null" matRipple
                    (click)="saveNotems()" class="ib-m m-r-15 t-normal f-400 btn btn-lg btn-primary">
                    <i class="i i-ms-team"></i>
                    <!-- <mat-icon fontSet="i" class="i-ms-team i-1_5x ib-m"></mat-icon> -->
                    <span class="ib-m-l-5">Save and Post to MS Teams</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</ng-template>