import { Router } from '@angular/router';
import { AIView } from '../ai/insight.view';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../notesalerts/notes-dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { Component, Inject, TemplateRef, OnInit, Optional, ViewChild } from '@angular/core';
import { DeepdiveDialogComponent } from '../deepdive-dialog/deepdive-dialog.component';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit {
  notesDialogRef: MatDialogRef<NotesDialogComponent, any>;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  favStat = false;
  cols;
  constructor(
    public ai: AIView,
    public router: Router,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<DeepdiveDialogComponent>
  ) {
    this.layout = this.data;
    this.favStat = false;
    // console.log('test');
  }
  @ViewChild('secondDialog', { static: true }) STAT_POPUP: TemplateRef<any>;
  layout: TemplateRef<any>;
  ngOnInit() {
    this.cols = [
      { field: 'actions', header: 'Actions' },
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'keywords', header: 'Keywords' },
      { field: 'date', header: 'Date' }
    ];
  }

  backtoPage() {
    this.dialogRef.close();
  }
  openLinkInNewTab(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
  // transformSafe(url) {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  // }
  transform(url: string) {
    //  console.log(url);
    if (!url) { return '/'; }
    url = url.replace('/watch?v=', '/embed/');
    // console.log(url);
    return url; // this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getcols() {
    return this.cols;
  }
  truncateText(inputText: string): string {
    const maxLength = 50;

    if (inputText.length <= maxLength) {
      return inputText;
    } else {
      return inputText.substring(0, maxLength) + '...';
    }
  }
  splitKeywordsAndDescription(aiText: any): { keywords: string; description: string } {
    if (!aiText) {
      return { keywords: '', description: '' };
    }

    const splitText = aiText.split('<br /><br />');
    const keywords = splitText[0].replace('Keywords: ', '').trim(); // Trim to remove leading and trailing spaces
    const description = splitText[1] || '';
    return { keywords, description };
  }

  splitTextPricing(text) {
    const regex = /Keywords:\s*([^<]*)<br\s*\/>\s*(.*)/i;
    const match = text.match(regex);

    if (match) {
      const keywords = match[1].trim();
      const description = match[2].trim();
      return {
        keywords: keywords,
        description: description
      };
    } else {
      return {
        keywords: '',
        description: text.trim()
      };
    }
  }

  splitKeywordsAndTitle(aiText: any): { keywords: string; title: string } {
    if (!aiText) {
      return { keywords: '', title: '' };
    }

    const splitText = aiText.split('<br />');
    const keywords = splitText[1]?.replace('Keywords: ', '').trim(); // Trim to remove leading and trailing spaces
    const title = splitText[0] || '';
    return { keywords, title };
  }
  backtoHome() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }

  getborder(deep: string) {
    if (deep === 'Yes') {
      return 'b-accent';
    } else if (deep === 'No') {
      return 'unknownbd';
    } else if (deep === null || typeof deep === 'undefined') {
      return 'unknownbd';
    } else {
      return 'b-primary';
    }
  }


  getClass(s: string) {
    try {
      if (s === undefined) {
        return 'b2b_star';
      } else {
        switch (s) {
          case 'AISimilarProducts':

            return 'product_box';

          case 'Consumer Information':


            return 'trends';

          case 'Competitors':


            return 'trends';


          case 'Product News':

            return 'news';


          case 'Supply Chain':

            return 'factory';

          case 'Professionals':

            return 'professional';

          case 'Blogs and Reviews':

            return 'review';

          case 'deep dive':
            return 'deep_dive';
          case 'Product Safety':

            return 'regulatory';

          case 'Patents And Trademarks':

            return 'patent';

          case 'b2b':

            return 'b2b';

          case 'deep_dive':

            return 'deep_dive';

          case 'trend insights':

            return 'trending_up';
          default:

            return 'b2b';

        }
      }
    } catch (exception) {
    }
  }
  testfn(row) {
    if (this.ai.featureChecker(15)) {
      this.testfn1(row);
    } else {
      this.ai.featurealert('Notes');
    }
  }
  testfn1(row) {
    this.ai.newnotesave = false;

    if (row?.liked?.aiLikeon === 1) {
      this.favStat = true;
    } else {
      this.favStat = false;
    }
    // console.log(row);
    // console.log(row.aiNotes);
    this.ai.notesRow = row;
    this.ai.view.set('notes', row.noteAdd);
    this.ai.noteOld = row.noteAdd;
    if (!this.ai.noteOld) {
      this.ai.view.set('notesId', null);
    } else {
      this.ai.view.set('notesId', 1);
    }
    //  console.log(this.STAT_POPUP);
    this.notesDialogRef = this.dialog.open(NotesDialogComponent, {
      data: this.STAT_POPUP
    });
  }

  cancelNote() {
    this.notesDialogRef.close();
  }
  insightDeep(aiId, fl) {
    this.ai.insightDeep(aiId, fl);
    // this.dialogRef.close();
  }


  saveNote() {
    this.ai.saveNotedeep(this.ai.fixaitypeName);
    this.notesDialogRef.close();
  }

  openConfirmationDialog() {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion', message: 'Are you sure want to delete this note ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data == 'confirm') {
        this.ai.deleteNotedeep();
        this.notesDialogRef.close();
      }
    });
  }

  getLabel() {
    let label: string;
    if (this.ai.projcatName == 1) {
      label = 'Consumer Information';
    } else if (this.ai.projcatName == 2) {
      label = 'Competitors';
    }

    return label;

  }
  addremoveReport(row) {
    let cat = this.getTooltip(this.ai.fixaitypeId);

    row.aiNameTemp = cat;


    this.ai.addremoveReport(row);
  }
  deleteNote() {
    this.openConfirmationDialog();
    //  this.ai.deleteNotedeep();
    //  this.notesDialogRef.close();
  }
  patentModal(aiId) {

    this.ai.deeppatentModal(aiId.aiTechDomain, aiId.aiClaim);
  }
  saveNotems() {
    this.ai.saveNotemsdeep();
    this.notesDialogRef.close();
  }

  trimtooltip(noteAdd: string) {
    try {
      if (noteAdd.length > 5) {
        return noteAdd.split(' ').slice(0, 5).join(' ') + '...';
      } else {
        return noteAdd;
      }
    } catch (exception) {
    }
  }

  getIcon(s) {
    //console.log(s);
    // console.log(title);

    // console.log(s.toLowerCase());
    try {
      if (s === undefined) {
        return 'deep_dive';
      } else {
        switch (s) {
          case '2':

            return 'product_box';

          case '31':


            return 'trends';

          case '32':


            return 'trends';


          case '10':

            return 'news';


          case '5':

            return 'factory';

          case '8':

            return 'professional';

          case '6':

            return 'review';

          case 'deep dive':
            return 'deep_dive';
          case 'Product Safety':

            return 'regulatory';

          case '7':

            return 'patent';
          case '11':

            return 'research';
          case 'b2b':

            return 'b2b';

          case 'deep_dive':

            return 'deep_dive';

          case 'trend insights':

            return 'trending_up';
          default:

            return 'b2b';

        }
      }
    } catch (exception) {
    }
  }
  //      getLabel() {
  //
  //    let label: string;
  //    if (this.ai.view.value('productNewOrExisting') == 1) {
  //      label = 'Consumer Information';
  //    } else if (this.ai.view.value('productNewOrExisting') == 2) {
  //      label = 'Competitors';
  //    }
  //
  //    return label;
  //
  //  }

  getDeepcat(s: string) {

    try {
      if (s === '1') {
        return 'deep_dive';
      } else {
        switch (s.trim().toLowerCase()) {
          case '2':

            return 'AISimilarProducts';

          case '3':

            return this.getLabel();


          case '10':

            return 'Product News';


          case '5':

            return 'Supply Chain';

          case '8':

            return 'Professionals';

          case '6':

            return 'Blogs and Reviews';


          case '7':

            return 'Product Safety';

          case '4':


            return 'Patents And Trademarks';
          case '9':


            return 'b2b';




          default:

            return 'deep_dive';

        }
      }
    } catch (exception) {
    }
  }
  getTooltip(s) {

    try {
      if (s === undefined) {
        return 'Deep Dive';
      } else {
        switch (s) {
          case '2':

            return 'Similar Products or Projects';

          case '31':

            return 'Consumer Information';

          case '32':

            return 'Competitors';


          case '10':

            return 'Product News';


          case '5':

            return 'Supply Chain';

          case '8':

            return 'Professionals';

          case '6':

            return 'Blogs and Reviews';

          case '7':

            return 'Product Safety';

          case '4':

            return 'Patents and Trademarks';

          case 'deep_dive':

            return 'Deep Dive';
          case 11:
            return 'Research';

          case 'trend insights':

            return 'Trend Insight';

          default:

            return 'b2b';

        }
      }
    } catch (exception) {
    }
  }


  fbshare(e, urls) {
    e.preventDefault();
    const facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php' + urls
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }
  linkedinshre(e, urls, aiTitle) {
    const url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + urls + '&title=' + aiTitle + '&summary=My%20favorite%20developer%20program&source=insiq';
    e.preventDefault();
    const linkedinWindow = window.open(url);
    if (linkedinWindow.focus) {
      linkedinWindow.focus();
    }
    return false;
  }

  twittershare(e, urls) {
    const text = 'InsIQ';
    const hashtag = 'insiq';
    e.preventDefault();
    const twitterWindow = window.open(
      'http://twitter.com/share?text=' + text + '&url=' + urls + '&hashtags=' + hashtag
    );
    if (twitterWindow.focus) {
      twitterWindow.focus();
    }
    return false;
  }
  watzappshare(e, urls, aiTitle) {
    e.preventDefault();
    const watzappWindow = window.open(
      'https://web.whatsapp.com/send?text=InsIQ AI Insight:  ' + aiTitle + '  Visit : ' + urls + ' for more info  #insiqai,#insiq,#aipowered,#devtominsights'
    );
    if (watzappWindow.focus) {
      watzappWindow.focus();
    }
    return false;
  }
  //  trimtooltip(noteAdd) {
  //    if (noteAdd?.length > 5) {
  //      return noteAdd.split(' ').slice(0, 5).join(' ') + '...';
  //    } else {
  //      return noteAdd;
  //    }
  //  }
}
