import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Config } from '../../config';
import { SharedService } from './shared.service';
import { SharedProjectService } from '../project/sharedproject.service';
import { TreeData } from '../../_models/treedata';
import { KeyplayerView } from './keyplayer.view';
import { RequirementView } from './requirement.view';
import { ObservationView } from './observation.view';
import { RequirementTypeView } from './requirementtype.view';
import { RequirementTestingView } from './requirement-testing.view';
import { AIView } from '../ai/insight.view';

enum Discovery {
  keyplayer,
  requirementType,
  observation,
  requirement,
  testing
}

@Component({
  selector: 'app-discovery',
  templateUrl: './discovery.component.html',
  styleUrls: ['./discovery.component.scss']
})
export class DiscoveryComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  data = new Array;
  header: any;
  active: any;
  observations;
  importances;

  filterOpened: boolean;

  constructor(
    public ai: AIView,
    public config: Config,
    public ss: SharedService,
    public ssp: SharedProjectService,
    public kp: KeyplayerView,
    public ob: ObservationView,
    public re: RequirementView,
    public rt: RequirementTypeView,
    public te: RequirementTestingView
  ) {
    this.filterOpened = false;

    kp.view.get('importance/all').subscribe(res => {
      this.importances = res;
      //  for (let x = 0; x < res.length; x++) {
      //   let item = this.keyplayerCategoryCodes[x];
      //   item['value'] = item['id'];
      //  }
    });
    ai.display = false;
    this.kp.setForm();
    this.rt.setForm();
    // this.rg.setForm();
    // console.log(Discovery);

    this.header = {
      percentage: 10,
      title: 'Discovery',
      projectName: this.ssp.project.productName
    };

    // console.log(Discovery.keyplayer);
    // console.log(Discovery[Discovery.reqGroup]);

    this.data[Discovery.keyplayer] = new TreeData(this.ss.getKeyPlayerView(), {
      name: 'KeyPlayer',
      key: 'keyplayer',
      children: 'requirementType',
      pagination: true, // false
      disabled: false
    });

    this.data[Discovery.requirementType] = new TreeData(this.ss.getRequirementTypeView(), {
      name: 'Requirement Type',
      key: 'requirementType',
      childId: 'insight',
      childName: 'KeyPlayer Insights',
      children: 'insight,requirement',
      new: false,
      edit: false,
      multi: true,
      import: false,
      nodeIcon: true,
      nodeImport: false,
      percentage: false
    });

    this.data[Discovery.observation] = new TreeData(this.ss.getObservationView(), {
      class: 'groupConnect',
      name: 'KeyPlayer Insights',
      key: 'insight',
      children: ''
    });

    this.data[Discovery.requirement] = new TreeData(this.ss.getRequirementView(), {
      name: 'Requirements',
      key: 'requirement',
      children: 'testing'
    });

    this.data[Discovery.testing] = new TreeData(this.te, {
      name: 'Testing',
      key: 'testing',
      children: ''
    });
  }

  ngOnInit() {
    // Init page to TreeView by default
    this.kp.view.state = 'list';
    this.ob.view.state = 'list';
    this.re.view.state = 'list';
    this.rt.view.state = 'list';
    this.te.view.state = 'list';
  }

  toggleFilter() {
    this.filterOpened = !this.filterOpened;
    console.log(this.filterOpened);
  }

  isList() {
    return !(
      this.kp.view.isForm() ||
      this.ob.view.isForm() ||
      this.re.view.isForm() ||
      this.te.view.isForm()
    );
  }

  nodeCloseManager(key, data) {
    console.log(key);
    switch (key) {
      case 'keyplayer':
        this.rt.view.list = null;
        this.ob.view.list = null;
        this.re.view.list = null;
        this.te.view.list = null;
        data[Discovery.requirementType].activeNode = null;
        data[Discovery.observation].activeNode = null;
        data[Discovery.requirement].activeNode = null;
        data[Discovery.testing].activeNode = null;
        break;
      case 'requirementType':
        // this.rg.view.list = null;
        this.ob.view.list = null;
        this.re.view.list = null;
        this.te.view.list = null;
        data[Discovery.observation].activeNode = null;
        data[Discovery.requirement].activeNode = null;
        data[Discovery.testing].activeNode = null;
        break;
      case 'insight':
      case 'observation':
        this.re.view.list = null;
        this.te.view.list = null;
        data[Discovery.requirement].activeNode = null;
        data[Discovery.testing].activeNode = null;
        break;
      case 'requirement':
        this.te.view.list = null;
        data[Discovery.testing].activeNode = null;
        break;
      default:
        console.log('here');
        this.rt.view.list = null;
        this.ob.view.list = null;
        // this.rg.view.list = null;
        this.re.view.list = null;
        this.te.view.list = null;
        data[Discovery.requirementType].activeNode = null;
        data[Discovery.observation].activeNode = null;
        data[Discovery.requirement].activeNode = null;
        data[Discovery.testing].activeNode = null;
        break;
    }
  }

  filterKeyPlayer(e) {
    // console.log('Filter', e);
    const gTree = document.querySelectorAll('.smartTree > li');
    for (let i = 1; i <= 4; i++) {
      gTree[i].classList.add('disabled');
    }
    this.ss.keyPlayer = e.value;
    this.nodeCloseManager('keyplayer', this.data);
    this.data[Discovery.keyplayer].activeNode = null;
    this.reloadFilter(true);
  }

  filterRequirmentType(e) {
    // console.log('Filter', e);
    const gTree = document.querySelectorAll('.smartTree > li');
    for (let i = 2; i <= 4; i++) {
      gTree[i].classList.add('disabled');
    }
    this.ss.requirementType = e.value;
    this.data[Discovery.requirementType].activeNode = null;
    this.nodeCloseManager('requirementType', this.data);
    this.reloadFilter(false);
  }

  private reloadFilter(reload) {
    this.re.initForm();
    this.re.newform(reload);
    this.re.getList();
  }

  onNodeOpen(e) {
    const [/*target*/, key, node, /* data */] = e;
    // console.log(key);
    if (key === 'keyplayer') {
      this.ss.keyPlayer = node;
      this.ss.getRequirementTypeView().getList();
    } else if (key === 'requirementType') {
      this.ss.requirementType = node;
      this.ss.getObservationView().setForm();
      this.ss.getObservationView().getList();
      this.ss.getRequirementView().setForm();
      this.ss.getRequirementView().getList();
    } else if (key === 'requirement') {
      this.ss.requirement = node;
      this.ss.getRequirementTestingView().setForm();
      this.ss.getRequirementTestingView().getList();
    } else if (key === 'testing') {
      // console.log('Testing');
      // this.re.view.active = node;
      // this.ss.getRequirementTesting().setForm();
      // this.ss.getRequirementTesting().getList();
    }
  }

  onNodeClose(e) {
    const [/*target*/, key, /* node */, data] = e;
    this.nodeCloseManager(key, data);
  }

  newConnectNode(e) {
    let [node, key] = e;
    node = (node) ? node : '';
    key = key.toString().toLowerCase();
    // console.log(key);
    if (key === 'observation' || key === 'insight') {
      this.ss.requirementType = node;
      this.ss.getObservationView().getList();
      this.ss.getObservationView().setForm();
      this.ss.getObservationView().newform();
    } else if (key === 'requirement') {
      this.ss.getRequirementView().getList();
      this.ss.getRequirementView().setForm();
      this.ss.getRequirementView().newform();
    } else if (key === 'testing') {
      // this.ss.requirementId = id;
      this.ss.requirement = node;
      this.ss.getRequirementTestingView().getList();
      this.ss.getRequirementTestingView().setForm();
      this.ss.getRequirementTestingView().newform();
    }
    // return key;
  }
}
