import {
  Directive,
  ViewChild,
  Injectable,
  TemplateRef,
  ChangeDetectorRef
} from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormControl
} from '@angular/forms';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  HttpEvent,
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import {
  ChartType,
  ChartOptions,
  ChartDataSets
} from 'chart.js';
import {
  Label,
  SingleDataSet,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip
} from 'ng2-charts';

import * as Chart from 'chart.js';
import { throwError } from 'rxjs';
import * as showdown from 'showdown';
import { Observable } from 'rxjs/Rx';
import * as Msal from '@azure/msal-browser';

import {
  View,
  Config,
  JsonRequest
} from '../../config';
import { OAuthSettings } from './oauth';
import { AlertService } from '../../_services';
import { map, startWith } from 'rxjs/operators';
import { Project } from '../../_models/project';
import { ModalService } from '../_modal/modal.service';
import { AlertsService } from '../profile/alerts.service';
import { Client } from '@microsoft/microsoft-graph-client';
import { TrendDialogComponent } from '../trendalerts/trend-dialog';
import { SavedchatComponent } from '../savedchat/savedchat.component';
import { PatentDialogComponent } from '../patentalerts/patent-dialog';
import { SharedProjectService } from '../project/sharedproject.service';
import { ConfirmationDialogComponent } from '../alerts/confirmation-dialog';
import { UpgradePlanComponent } from '../upgrade-plan/upgrade-plan.component';
import { CloneprojectComponent } from '../cloneproject/cloneproject.component';
import { TeammessageDialogComponent } from '../teammessage/teammessage-dialog';
import { SearchDialogComponent } from '../search-dialog/search-dialog.component';
import { NavigationService } from 'projects/tag-cloud/src/lib/navigation-service';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { SummaryDialogComponent } from '../summary-dialog/summary-dialog.component';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { KeywordsDialogComponent } from '../keywords-dialog/keywords-dialog.component';
import { DeepdiveDialogComponent } from '../deepdive-dialog/deepdive-dialog.component';
import { SentimentdialogComponent } from '../sentimentdialog/sentimentdialog.component';
import { environment } from 'src/environments/environment';
import { SafeUrl } from '@angular/platform-browser';
import { ReportviewerComponent } from '../reportviewer/reportviewer.component';

const msalConfig = {
  auth: {
    clientId: "fec2ff3d-3c5b-4573-8011-730a3af0b708",

  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};

export class TagCloud {
  label: any;
  weight: number;
  styleClass?: string;
}

export class PieChart {
}

const tempIntArr = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
const tempStrArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
interface Message {
  user: {
    name: string;
    icon: string;
  };
  content: string;
}
@Directive()
@Injectable({
  providedIn: 'root'
})
export class AIView {
  timer: NodeJS.Timeout;
  progress = 0;

  public stackChartOptions: ChartOptions;

  public stackChartType: ChartType = 'bar';
  public stackChartLegend = true;
  public stackChartPlugins = [];
  public stackChartLabels0: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  public stackChartData0: ChartDataSets[] = [];
  public stackChartData1: ChartDataSets[] = [];
  public stackChartData2: ChartDataSets[] = [];
  public stackChartData3: ChartDataSets[] = [];
  public stackChartData4: ChartDataSets[] = [];
  public stackChartData5: ChartDataSets[] = [];
  public stackChartData6: ChartDataSets[] = [];
  public stackChartData7: ChartDataSets[] = [];
  public stackChartData8: ChartDataSets[] = [];
  public stackChartData00: ChartDataSets[] = [];

  public spieChartOptions: ChartOptions;
  hidePaginator: boolean = true; // Set it to true when you want to hide the paginator

  public sentimentTag = [];
  public spieChartLabels: Label[] = [];
  public spieChartData: number[] = [];
  public spieChartType: ChartType = 'doughnut';
  public spieChartLegend = true;
  public spieChartPlugins = [];
  public spieChartColors = [{
    backgroundColor: [
      '#2B6FB1',
      '#6A9CFF',
      '#D3E6FF',
      // Add more shades of blue color codes here
    ],
  }];

  public sbarChartOptions: ChartOptions;
  public sbarChartLabels: Label[] = ['P N RatioPercent'];
  public sbarChartType: ChartType = 'bar';
  public sbarChartLegend = true;
  // public sbarChartPlugins = [pluginDataLabels];
  public sbarChartData: ChartDataSets[] = [];
  newMessageControl = new FormControl('');
  public pieChartOptions0: ChartOptions;
  public pieChartOptions1: ChartOptions;
  public pieChartOptions2: ChartOptions;
  public pieChartOptions3: ChartOptions;
  public pieChartOptions4: ChartOptions;
  public pieChartOptions5: ChartOptions;
  public pieChartOptions6: ChartOptions;
  public pieChartOptions7: ChartOptions;
  public pieChartOptions8: ChartOptions;
  public catpieChartOptions0: ChartOptions;

  public trendChartOptions: ChartOptions;
  public sentimentTrendChartOptions: ChartOptions;
  public catpieChartOptions1: ChartOptions;
  public catpieChartOptions2: ChartOptions;
  public catpieChartOptions3: ChartOptions;
  public catpieChartOptions4: ChartOptions;
  public catpieChartOptions5: ChartOptions;
  public catpieChartOptions6: ChartOptions;
  public catpieChartOptions7: ChartOptions;
  public catpieChartOptions8: ChartOptions;
  public sentimentTrendChartLabels: Label[] = ['positive', 'negative', 'neutral'];
  public trendChartLabels: Label[] = tempStrArray;
  public trendChartData: SingleDataSet = tempIntArr;
  public sentimentTrendChartData: SingleDataSet = tempIntArr;
  charttitle: any;
  projcatName: any;
  public pieChartLabels0: Label[] = tempStrArray;
  public pieChartLabels00: Label[] = tempStrArray;

  public pieChartLabels1: Label[] = tempStrArray;
  public pieChartLabels11: Label[] = tempStrArray;

  messages;

  newMessageForm: FormGroup;

  public pieChartLabels2: Label[] = tempStrArray;
  public pieChartLabels3: Label[] = tempStrArray;
  public pieChartLabels4: Label[] = tempStrArray;
  public pieChartLabels5: Label[] = tempStrArray;
  public pieChartLabels6: Label[] = tempStrArray;
  public pieChartLabels7: Label[] = tempStrArray;
  public pieChartLabels8: Label[] = tempStrArray;
  public pieChartLabels22: Label[] = tempStrArray;
  public pieChartLabels33: Label[] = tempStrArray;
  public pieChartLabels44: Label[] = tempStrArray;
  public pieChartLabels55: Label[] = tempStrArray;
  public pieChartLabels66: Label[] = tempStrArray;
  public pieChartLabels77: Label[] = tempStrArray;
  public pieChartLabels88: Label[] = tempStrArray;
  public pieChartData0: SingleDataSet = tempIntArr;
  public pieChartData1: SingleDataSet = tempIntArr;
  public pieChartData2: SingleDataSet = tempIntArr;
  public pieChartData3: SingleDataSet = tempIntArr;
  public pieChartData4: SingleDataSet = tempIntArr;
  public pieChartData5: SingleDataSet = tempIntArr;
  public pieChartData6: SingleDataSet = tempIntArr;
  public pieChartData7: SingleDataSet = tempIntArr;
  public pieChartData8: SingleDataSet = tempIntArr;

  public catpieChartLabels0: Label[] = tempStrArray;
  public catpieChartLabels1: Label[] = tempStrArray;
  public catpieChartLabels2: Label[] = tempStrArray;
  public catpieChartLabels3: Label[] = tempStrArray;
  public catpieChartLabels4: Label[] = tempStrArray;
  public catpieChartLabels5: Label[] = tempStrArray;
  public catpieChartLabels6: Label[] = tempStrArray;
  public catpieChartLabels7: Label[] = tempStrArray;
  public catpieChartLabels8: Label[] = tempStrArray;

  catpieChartData0 = [];
  catpieChartData1 = [];
  catpieChartData2 = [];
  catpieChartData3 = [];
  catpieChartData4 = [];
  catpieChartData5 = [];
  catpieChartData6 = [];
  catpieChartData7 = [];
  catpieChartData8 = [];

  // public catpieChartData0: SingleDataSet =  tempIntArr;
  // public catpieChartData1: SingleDataSet =  tempIntArr;
  // public catpieChartData2: SingleDataSet =  tempIntArr;
  // public catpieChartData3: SingleDataSet =  tempIntArr;
  // public catpieChartData4: SingleDataSet =  tempIntArr;
  // public catpieChartData5: SingleDataSet =  tempIntArr;
  // public catpieChartData6: SingleDataSet =  tempIntArr;
  // public catpieChartData7: SingleDataSet =  tempIntArr;
  // public catpieChartData8: SingleDataSet =  tempIntArr;
  newQuerry;
  sourceQuerry;
  turboMode = 0;
  public pieChartLegend = false;
  public catpieChartLegend = true;
  public trendChartLegend = false;
  allSelected: boolean = true; // Add this line
  public sentimentTrendChartLegend = false;
  filterMenuOpen: boolean = false;
  tabNumber = 0;
  showSuggestions = false;
  suggestedQuestions: string[] = [];
  sourceId;
  reportText;
  activeTabIndex = 0;
  turboRerunFl = 0;
  chatLoaderText = '';
  turboSourceMsg = 'To get the details, please click the source against each chat query.';
  menuClosedFlag: boolean = true;
  isTyping = false;
  public trendChartType: ChartType = 'line';
  public pieChartType1: ChartType = 'doughnut';
  croppedImage: SafeUrl;

  public pieChartPlugins = [];
  public stackPlugins = [{
    beforeInit: function (chart: any) {
      chart.data.labels.forEach(function (value, index, array) {
        const a = [];
        a.push(value.slice(0, 5));
        let i = 1;
        while (value.length > (i * 5)) {
          a.push(value.slice(i * 5, (i + 1) * 5));
          i++;
        }
        array[index] = a;
      });
    }
  }];

  breakpoint = 10; // to adjust to screen
  // MatPaginator Inputs
  length = 10;
  pageSize = 3; // displaying three cards each row
  searchPageSize = 3; // displaying three cards each row

  pageSizeOptions: number[] = [3, 6, 9, 12];

  public pieChartColors1 = [
    {
      backgroundColor: [
        '#2B6FB1',
        '#6A9CFF',
        '#D3E6FF',
        // Add more shades of blue color codes here
      ]
    }
  ];
  public pieChartColors2 = [
    {
      backgroundColor: [
        '#2B6FB1',
        '#6A9CFF',
        '#D3E6FF',
        // Add more shades of blue color codes here
      ]
    }
  ];
  public pieChartColors = [
    {
      backgroundColor: [
        '#2B6FB1',
        '#6A9CFF',
        '#D3E6FF',
        // Add more shades of blue color codes here
      ]
    }
  ];
  compStatus = false;
  blogStatus = false;

  compStatusDate = false;
  blogStatusDate = false;
  compRequired = false;
  averagePrice = false;
  filteredBrands: any[];
  smartlistItem: any;
  smartlistItemDetail = [];
  smartreportlist = [];
  smartlistItemDetailtip = [];
  smartKeywordSuggestion;
  keywordProgress = false;
  selectedKeywordSuggestion = [];
  searchSmartlistItemDetailpaginator = [];
  ailist = [];
  smartlistItemDetailpaginator = [];
  deepdiveDetails = [];
  searchinsightDetails = [];
  trenddate;
  trendcountry;
  averagePriceRes: any;
  insightsSummary: any;
  showPopup = false;
  selectedCategories: boolean[] = new Array(this.catfilter().length).fill(false); selectedVideoUrl: string | null = null;
  showVideoPopup = false;
  selectedImageUrl: string | null = null;
  averagePriceResTemp: any;
  averagePriceResFinal: any;

  trendyr;
  teammessage;
  patentCountry;
  patentDomain;
  patentClaim;
  projSetting = false;
  msstatus = false;
  mschannelid: string;
  trendDetails = [];
  pagemin;
  pagemax;
  pageno;
  pagesize = 8;
  keywordCat = false;
  searchPagemin;
  searchPagemax;
  searchPageno;
  searchPagesize = 6;
  projectFavFlag = false;
  domainname = location.host;
  barchartData0 = [];
  barchartData1 = [];
  barchartData2 = [];
  barchartData3 = [];
  barchartData4 = [];
  barchartData5 = [];
  barchartData6 = [];
  barchartData7 = [];
  barchartData8 = [];
  chartSel;
  yrbarchartData0 = [];
  yrbarchartData1 = [];
  yrbarchartData2 = [];
  yrbarchartData3 = [];
  yrbarchartData4 = [];
  yrbarchartData5 = [];
  yrbarchartData6 = [];
  yrbarchartData7 = [];
  yrbarchartData8 = [];

  catbarchartData0 = [];
  catbarchartData1 = [];
  catbarchartData2 = [];
  catbarchartData3 = [];
  catbarchartData4 = [];
  catbarchartData5 = [];
  catbarchartData6 = [];
  catbarchartData7 = [];
  catbarchartData8 = [];

  yrsortelement0 = [];
  yrmainsortelement0 = [];

  sortelement0 = [];
  mainsortelement0 = [];

  sortelement1 = [];
  mainsortelement1 = [];

  sortelement2 = [];
  mainsortelement2 = [];

  sortelement3 = [];
  mainsortelement3 = [];

  sortelement4 = [];
  mainsortelement4 = [];

  sortelement5 = [];
  mainsortelement5 = [];

  sortelement6 = [];
  mainsortelement6 = [];

  sortelement7 = [];
  mainsortelement7 = [];

  sortelement8 = [];
  mainsortelement8 = [];

  catsortelement0 = [];
  catmainsortelement0 = [];

  catsortelement1 = [];
  catmainsortelement1 = [];

  catsortelement2 = [];
  catmainsortelement2 = [];

  catsortelement3 = [];
  catmainsortelement3 = [];

  catsortelement4 = [];
  catmainsortelement4 = [];

  catsortelement5 = [];
  catmainsortelement5 = [];

  catsortelement6 = [];
  catmainsortelement6 = [];

  catsortelement7 = [];
  catmainsortelement7 = [];

  catsortelement8 = [];
  catmainsortelement8 = [];
  tempStorer;

  cacheClear = false;
  insight0 = false;
  insight1 = false;
  insight2 = false;
  insight3 = false;
  insight4 = false;
  insight5 = false;
  insight6 = false;
  insight7 = false;
  insight8 = false;
  insighttemp = false;

  insightC0;
  insightC1;
  insightC2;
  insightC3;
  insightC4;
  insightC5;
  insightC6;
  insightC7;
  insightC8;
  insightTR;
  insightSA;
  insightC0N;
  insightC1N;
  insightC2N;
  insightC3N;
  insightC4N;
  insightC5N;
  insightC6N;
  insightC7N;
  insightC8N;
  insightTRN;
  insightSAN;

  insightSANR;
  insightSAN10;
  insightSAN20;

  insightSAN30;
  insightSAN40;
  insightSAN50;
  insightSAN60;


  insightSAR;
  insightSA10;
  insightSA20;
  insightSA30;
  insightSA40;
  insightSA50;
  insightSA60;

  insightSART;
  insightSA10T;
  insightSA20T;
  insightSA30T;
  insightSA40T;
  insightSA50T;
  insightSA60T;

  trendReportId;

  trendNotes;
  imageC0N;
  imageC1N;
  imageC2N;
  imageC3N;
  imageC4N;
  imageC5N;
  imageC6N;
  imageC7N;
  imageC8N;
  imageTRN;
  imageSAN;
  imageSANR;
  imageSAN10;
  imageSAN20;
  imageSAN30;
  imageSAN40;
  imageSAN50;
  imageSAN60;
  trendchart = false;
  newnotesave = false;
  newnotesaveC = false;
  inddeep = false;
  smartTitle = 'Patents';
  modeval = 0;
  myurl: string;
  smartlist: any;
  trendlist: any;
  deepdivelist = [];
  smartlist1 = [];
  likeval: number;
  notesadd: boolean;
  deepdiveflag = false;
  clearInsights: boolean;
  chatSequence: number = 0;
  likes = [];
  chatPrompt;
  uniquePromptArray;
  promptTextSet: any;
  existingcomments = [];
  reportinsights = [];
  chartreportinsights = [];
  sentimentCharts = [];
  aitype: string;
  display = false;
  displayReq = false;
  showWish = false;
  dashFav = false;
  keywordSaveFlag = true;
  insightwish = false;
  tagcloudclick = false;
  desiagnApiCall = false;
  InsIQclickflg = false;
  chartReports = false;
  sentimentapistat = false;
  sentimentclickflg = false;
  partnerclickflg = false;
  public authenticated: boolean;
  notesviewflag = false;
  reportviewflag = false;
  insightsearch = false;
  allcatflag = false;
  public dashboard = true;
  public listViewInsight = true;

  public project;
  public view: View;
  private projView: View;
  public notesView: View;
  private wishListAll: any[];
  productTypes: any;
  apiLanguage: any;
  selectedLanguage: any;
  sentimentscore: any;
  // sentimentquery: any;
  filteredPrompts: Observable<any[]>;
  sentimentResp: any;
  sentimentSelected: any;
  dsSentimentDetail: any;
  nrs: any;
  hasRecord: string | boolean;
  filtervalue: string;
  reporttype: any;
  selectedSort;
  flValSelect: any;
  fixaitypeId;
  fixaitypeName;
  aiidset: any;
  chartnote;
  noteOld;
  chartCode: any;
  notesRow;
  productFlag;
  deepcat: string;
  ExtractedConceptsTier1: any;
  ExtractedConceptsTier2: any;
  Word2Vec: any;
  public config: Config;
  tagCloud = [];
  ptagCloud = [];
  priceFilter;
  priceCloud;
  aiNameTemp: any;
  aiNameIdTemp: any;
  selCat = 0;
  app;
  flSelected = 199;
  keywordsel = 199;
  sortBy = {
    date: {
      new: true,
      old: false
    }
  };


  @ViewChild('secondDialog', { static: true }) STAT_POPUP: TemplateRef<any>;
  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;

  aiChatScrollViewer: any;


  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  deepDiveDialogRef: MatDialogRef<DeepdiveDialogComponent, any>;

  searchDialogRef: MatDialogRef<SearchDialogComponent, any>;
  summaryDialogRef: MatDialogRef<SummaryDialogComponent, any>;
  keywordDialogRef: MatDialogRef<KeywordsDialogComponent, any>;
  savedChatDialogRef: MatDialogRef<SavedchatComponent, any>;

  cloneDialogRef: MatDialogRef<CloneprojectComponent, any>;
  currentAiTabIndex: number = 0;
  // @ViewChild('titleInput') inputName; // accessing the reference element
  constructor(
    public router: Router,
    private fb: FormBuilder,
    public http: HttpClient,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public ssp: SharedProjectService,
    public deepDiveDialog: MatDialog,
    public searchDialog: MatDialog,
    public cloneDialog: MatDialog,
    private httpClient: HttpClient,
    public keyworsDialog: MatDialog,
    public savedChatDialog: MatDialog,

    public summaryDialog: MatDialog,

    public alertService: AlertService,
    private modalService: ModalService,
    private alertsService: AlertsService,
    public changeDetectorRef: ChangeDetectorRef,
    public commonDialogRef: MatDialogRef<CommonDialogComponent>,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService

  ) {
    this.turboSourceMsg = 'To get the details, please click the source against each chat query.';


    this.newMessageForm = this.fb.group({
      newMessage: ['']
    });
    this.filteredPrompts = this.newMessageForm.get('newMessage').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterPrompts(value))
      );
    this.keywordCat = false;
    this.isTyping = false;
    this.flSelected = 199;
    this.app = new Msal.PublicClientApplication(msalConfig)
    this.projectFavFlag = false;
    this.sourceQuerry = '';
    this.turboSourceMsg = 'To get the details, please click the source against each chat query.';

    this.hasRecord = 'none';
    this.sortBy.date.new = true;
    this.sortBy.date.old = false;
    // Chart.plugins.register(this.chartPlugin_SaveToPNG);
    Chart.plugins.register(this.chartPlugin_RangeHighlight);

    this.stackChartOptions = {
      plugins: {
        // savetopng: {
        //   filename: 'chart1.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart1.png');
      //   }
      // },
      responsive: true
    };

    this.spieChartOptions = {
      plugins: {
        // savetopng: {
        //   filename: 'chart2.png'
        // },
        datalabels: {
          formatter: (_value, ctx: any) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
        },
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart2.png');
      //   }
      // },
      responsive: true,
      legend: {
        position: 'top',
      }
    };

    this.sbarChartOptions = {
      plugins: {
        // savetopng: {
        //   filename: 'chart3.png'
        // },
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart3.png');
      //   }
      // },
      responsive: true,
      // We use these empty structures as placeholders for dynamic theming.
      scales: { xAxes: [{}], yAxes: [{}] }
    };

    this.pieChartOptions0 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart4.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart4.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            display: false
          },
        }],
        yAxes: [{
          stacked: true,
        }],
      }
    };

    this.pieChartOptions1 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart5.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart5.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    this.pieChartOptions2 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart6.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart6.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    this.pieChartOptions3 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart7.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart7.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    this.pieChartOptions4 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart8.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart8.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    this.pieChartOptions5 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart9.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart9.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    this.pieChartOptions6 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart10.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart10.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    this.pieChartOptions7 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart11.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart11.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    this.pieChartOptions8 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart12.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart12.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    };

    this.catpieChartOptions0 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart13.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart13.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      }
    };

    this.sentimentTrendChartOptions = {
      scales: {
        yAxes: [{
          ticks: {
            min: 0,
            max: 100,
            beginAtZero: true
          }
        }]
      },
      elements: {
        line: {
          fill: false,
          borderColor: '#0c448b'
        }
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            pointStyle: 'cross',
            color: 'rgba(12, 68, 139, 1)',
            generateLabels: [
              {
                text: 'Positive'
              }
            ]
          }
        },
        rangeHighlight: {
          ranges: [
            {
              range: [0, 39.99],
              color: '#D3E6FF'
            },
            {
              range: [40, 50],
              color: '#6A9CFF'
            },
            {
              range: [50.01, 100],
              color: '#578EFD'
              // color: 'rgba(25, 184, 32, 0.8)'
            }
          ]
        }
      }
    };

    this.trendChartOptions = {
      elements: {
        line: {
          fill: "bottom",
          backgroundColor: '#0c448b',
          borderColor: 'rgb(12, 68, 139)'
        }
      },
      plugins: {
        // savetopng: {
        //   filename: 'chart14.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart14.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      },
    };

    this.catpieChartOptions1 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart15.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart15.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      }
    };

    this.catpieChartOptions2 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart16.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart16.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      }
    };

    this.catpieChartOptions3 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart17.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart17.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      }
    };

    this.catpieChartOptions4 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart18.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart18.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      }
    };

    this.catpieChartOptions5 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart19.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart19.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      }
    };

    this.catpieChartOptions6 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart20.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart20.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      }
    };

    this.catpieChartOptions7 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart21.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart21.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      }
    };

    this.catpieChartOptions8 = {
      plugins: {
        // savetopng: {
        //   filename: 'chart22.png'
        // }
      },
      // animation: {
      //   onComplete: (e) => {
      //     this.setChartOptions(e, 'chart22.png');
      //   }
      // },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: '',
        fontSize: 18,
        display: true
      }
    };


    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
    this.view = new View(http, router, route, alertService);
    this.projView = new View(http, router, route, alertService);
    this.notesView = new View(http, router, route, alertService);
    this.view.initForm('aiinsight/favorite', this.getForm(), null, true);
    this.projView.initForm('project', this.getProjectForm(), null, false);
    this.notesView.initForm('aiinsight/notes', this.getForm(), null, false);
    this.setAIView(false);
    this.pageSize = 8;
    this.searchPageSize = 6;
    this.view.refreshList = false;
    this.projView.refreshList = false;
    this.notesView.refreshList = false;
    Config.isProjectSelected = true;
    this.tagcloudclick = false;
    this.InsIQclickflg = false;
    this.selectedSort = '3';
    this.flValSelect = 199;
    this.chartReports = false;
    this.desiagnApiCall = false;
    this.sentimentapistat = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;
    this.partnerclickflg = false;
    this.msstatus = false;
    this.projSetting = false;
    this.mschannelid = '';
    this.inddeep = false;
    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;
    this.keywordSaveFlag = true;
    this.allcatflag = false;
    this.deepdiveflag = false;
    this.selCat = 0;
    this.filtervalue = '1';
    this.reporttype = 0;
    this.aiidset = '';
    this.deepcat = '';
    this.dashboard = true;
    this.listViewInsight = true;
    this.trendChartLabels = [];
    this.trendChartData = [];
    this.selectedKeywordSuggestion = [];

    this.sentimentTrendChartData = [];

    this.sentimentTrendChartLabels = [];

    this.pieChartLabels0 = [];
    this.pieChartLabels00 = [];
    this.pieChartLabels1 = [];
    this.pieChartLabels11 = [];
    this.pieChartLabels2 = [];
    this.pieChartLabels3 = [];
    this.pieChartLabels4 = [];
    this.pieChartLabels5 = [];
    this.pieChartLabels6 = [];
    this.pieChartLabels7 = [];
    this.pieChartLabels8 = [];
    this.pieChartLabels22 = [];
    this.pieChartLabels33 = [];
    this.pieChartLabels44 = [];
    this.pieChartLabels55 = [];
    this.pieChartLabels66 = [];
    this.pieChartLabels77 = [];
    this.pieChartLabels88 = [];

    this.pieChartData0 = [];
    this.pieChartData1 = [];
    this.pieChartData2 = [];
    this.pieChartData3 = [];
    this.pieChartData4 = [];
    this.pieChartData5 = [];
    this.pieChartData6 = [];
    this.pieChartData7 = [];
    this.pieChartData8 = [];

    this.sortelement0 = [];
    this.sortelement1 = [];
    this.sortelement2 = [];
    this.sortelement3 = [];
    this.sortelement4 = [];
    this.sortelement5 = [];
    this.sortelement6 = [];
    this.sortelement7 = [];
    this.sortelement8 = [];

    this.mainsortelement0 = [];
    this.mainsortelement1 = [];
    this.mainsortelement2 = [];
    this.mainsortelement3 = [];
    this.mainsortelement4 = [];
    this.mainsortelement5 = [];
    this.mainsortelement6 = [];
    this.mainsortelement7 = [];
    this.mainsortelement8 = [];

    this.yrsortelement0 = [];

    this.yrmainsortelement0 = [];

    this.barchartData0 = [];
    this.barchartData1 = [];
    this.barchartData2 = [];
    this.barchartData3 = [];
    this.barchartData4 = [];
    this.barchartData5 = [];
    this.barchartData6 = [];
    this.barchartData7 = [];
    this.barchartData8 = [];


    this.yrbarchartData0 = [];
    this.yrbarchartData1 = [];
    this.yrbarchartData2 = [];
    this.yrbarchartData3 = [];
    this.yrbarchartData4 = [];
    this.yrbarchartData5 = [];
    this.yrbarchartData6 = [];
    this.yrbarchartData7 = [];
    this.yrbarchartData8 = [];

    this.newnotesave = false;
    this.newnotesaveC = false;
    this.insight0 = false;
    this.insight1 = false;
    this.insight2 = false;
    this.insight3 = false;
    this.insight4 = false;
    this.insight5 = false;
    this.insight6 = false;
    this.insight7 = false;
    this.insight8 = false;

    this.trendchart = false;
    this.insighttemp = false;
    // this.view.set('mode', 'Research Mode');

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    router.events.subscribe(event => {
      this.setAIView(false);
    });
  }

  chartPlugin_RangeHighlight = {
    id: 'rangeHighlight',
    beforeDraw: function (chart, easing, options) {
      const ctx = chart.ctx;
      const ranges = options.ranges;

      if (ranges) {

        // draw
        // console.log(ranges);

        for (const r in ranges) {
          if (r) {
            const range = ranges[r];
            const yRangeBegin = range.range[0];
            const yRangeEnd = range.range[1];

            const xaxis = chart.scales['x-axis-0'];
            const yaxis = chart.scales['y-axis-0'];

            const yRangeBeginPixel = yaxis.getPixelForValue(yRangeBegin);
            const yRangeEndPixel = yaxis.getPixelForValue(yRangeEnd);

            ctx.save();

            // The fill style of the rectangle we are about to fill.
            ctx.fillStyle = range.color; // 'rgba(0, 255, 0, 0.3)';
            // Fill the rectangle that represents the highlight region.
            // The parameters are the closest-to-starting-point pixel's x-coordinate,
            // the closest-to-starting-point pixel's y-coordinate,
            // the width of the rectangle in pixels, and the height of the rectangle in pixels, respectively.
            // ctx.fillRect(0, 0, 300, 300);

            ctx.fillRect(
              xaxis.left,
              Math.min(yRangeBeginPixel, yRangeEndPixel),
              xaxis.right - xaxis.left,
              Math.max(yRangeBeginPixel, yRangeEndPixel) - Math.min(yRangeBeginPixel, yRangeEndPixel)
            );

            ctx.restore();
          }
        }

      }
    }
  };

  // chartPlugin_SaveToPNG = {
  //   id: 'savetopng',
  //   afterRender: (obj: any, options: { filename: any; }) => {
  //     const filename = options.filename;
  //     if (!obj.$rendered) {
  //       obj.$rendered = true;
  //       console.log('Chart Rendered');
  //       console.log(filename);
  //       // do whatever you want for the first render ...
  //       if (filename) {
  //         this.setChartOptions(obj.chart.canvas, filename);
  //       } else {
  //         this.setChartOptions(obj.chart.canvas, 'Chart.png');
  //       }
  //     }
  //   }
  // };

  setConfig(config: Config) {
    this.config = config;
  }
  setChartOptions(key: any, aiCode, charTtype = null, name = null, dashChart = null, index = 0) {

    if (this.featureChecker(17)) {

      this.setChartOptions1(key, aiCode, charTtype, name, dashChart, index);

    } else {
      this.featurealert('Report');

    }
  }
  setChartOptions1(key: any, aiCode, charTtype = null, name = null, dashChart = null, index = 0) {
    // console.log(key);
    let canvas;

    if (aiCode === 1) {
      canvas = <HTMLCanvasElement>document.querySelector(`#${key}`);
    } else if (aiCode === 99 || aiCode === 991) {
      canvas = key;
    } else {
      canvas = <HTMLCanvasElement>document.querySelector(`#${key}${aiCode}`);
    }

    let reportId;
    if (aiCode === 1) {
      reportId = this.trendlist[0]?.reportId;
    } else if ((aiCode !== 99 || aiCode !== 991) && dashChart !== null) {

      reportId = dashChart.reportId;

    } else {
      if (aiCode === 99) {
        reportId = this.sentimentSelected?.reportId;
      } else if (aiCode === 991) {
        reportId = this.sentimentSelected?.trendreportId;
      }
    }
    // console.log(reportId);

    if (reportId === 0 || reportId === null || reportId === '' || reportId === -1 || !reportId) {
      Config.loading = true;

      setTimeout(() => {
        Config.loading = false;
      }, 2000);
      // console.log(canvas);
      // if (dashChart !== null) {
      //   dashChart.reportId = -1;
      // }
      // if (aiCode === 99) {
      //   this.sentimentSelected.reportId = -1;
      // }
      // if (aiCode === 1) {
      //   this.trendlist.reportId = -1;
      // }
      // Config.loading = true;
      // Config.setLoaderMessage('InsIQ is adding the Charts to your Ai Report');
      const image = new Image();
      image.src = canvas?.toDataURL('image/png');
      // console.log(image.src);
      canvas.toBlob((blob: string | Blob) => {
        let aitypeVal;
        if (!this.desiagnApiCall) {

          aitypeVal = '1';
        } else {
          aitypeVal = '2';
        }

        //  Config.loading = true;
        this.fileUpload(blob, aiCode, charTtype, name).subscribe(() => {
          // this.getReports();



        }, () => {
          // console.log('newone');
          this.view.get(`aireport/chartreport/${this.project.id}/${aitypeVal}/${name}/${aiCode}/${charTtype}`).subscribe(res => {
            // console.log(res);
            if (aiCode === 99) {
              this.sentimentSelected.reportId = res.id;

            } else if (aiCode === 991) {
              this.sentimentSelected.trendreportId = res.id;

            } else if (aiCode === 1) {
              this.insightTR = true;
              this.trendReportId = res.id;
              this.trendlist[0].reportId = res.id;
            } else {
              dashChart.reportId = res.id;

              this.dashCharts[index] = dashChart;
            }
            //  Config.loading = false;
            this.changeDetectorRef.detectChanges();
            const element: HTMLElement = document.getElementById(canvas) as HTMLElement;
            element?.click();
          });
        });
      });
    } else {

      this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
        data: {
          title: 'Confirm Action',
          message: 'Are you sure want to remove from Report ?',
          cancelbtn: 'No',
          okbtn: 'Yes'
        },
      });
      this.warningDialogRef.afterClosed().subscribe(result => {
        if (result.data === 'confirm') {
          // console.log(aiCode);
          //  this.toggleChart(false, aiCode);
          this.deleteChart(aiCode, charTtype, name);

          this.chartnote = '';
        }
      });
    }

    // console.log('entryyyy');
    // this.sentimentSelected.report = this.insightSA;
    // this.sentimentSelected.noteflag = this.insightSAN;
    // this.sentimentSelected.note = '';
  }

  getSentimnent(mode) {
    // Todo replace with new logic
    if (mode === 2) {
      return 'sentiment_very_satisfied';
    } else if (mode === 10) {
      return 'factory_star';
    } else if (mode === 20) {

      return 'product_box';
    }
  }
  repviewIcon(row) {
    if (row.aiCode === 99 || row.aiCode === 991) {
      return this.getSentimnent(row.aiSubcode);
    } else {
      return 'product_box';
    }
  }

  // row.aiCode===99?'sentiment_very_satisfied':'product_box'
  deletesentiment(aiCode) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Action', message: 'Are you sure want to remove from Report ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        //  console.log(aiCode);

        this.deleteChart(aiCode, '');


        this.chartnote = '';

      }
    });
    // console.log('entryyyy');
  }

  deleteChart(aiCode, charTtype, name = null) {
    // console.log(aiCode);
    //console.log(charTtype);
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);

    if (aiCode === 99) {
      for (var i = 0; i < this.sentimentResp.length; i++) {
        if (charTtype === 2) {
          if (this.sentimentResp[i].Mode === charTtype) {

            this.sentimentResp[i].reportId = '';
            this.sentimentResp[i].note = '';
          }
        } else {
          if (this.sentimentResp[i].Mode === charTtype && this.sentimentResp[i].cn === name) {

            this.sentimentResp[i].reportId = '';
            this.sentimentResp[i].note = '';
          }
        }

      }
      // this.sentimentResp
      //  this.sentimentSelected.reportId = "";
      //  this.sentimentSelected.note = "";

    } else if (aiCode === 991) {
      for (var i = 0; i < this.sentimentResp.length; i++) {
        if (charTtype === 2) {
          if (this.sentimentResp[i].Mode === charTtype) {

            this.sentimentResp[i].trendreportId = '';
            this.sentimentResp[i].trendnote = '';
          }
        } else {
          if (this.sentimentResp[i].Mode === charTtype && this.sentimentResp[i].cn === name) {

            this.sentimentResp[i].trendreportId = '';
            this.sentimentResp[i].trendnote = '';
          }
        }

      }
      // this.sentimentResp
      //  this.sentimentSelected.reportId = "";
      //  this.sentimentSelected.note = "";

    } else if (aiCode === 1) {
      this.insightTR = false;
      this.insightTRN = false;
      this.trendlist[0].reportId = '';
      this.trendlist[0].note = '';
    } else {

      for (var i = 0; i < this.dashCharts.length; i++) {
        if (this.dashCharts[i].aiTypeID === aiCode) {
          this.dashCharts[i].reportId = 0;
          this.dashCharts[i].note = '';
        }
      }

    }
    // Config.loading = true;

    // console.log('55555');



    this.deleteCharts(aiCode, charTtype, name).subscribe(() => {

      // this.getReports();

      // Config.loading = true;
    }, () => {


    });


  }
  deleteCharts(chartCode, charTtype = null, name = null): Observable<HttpEvent<any>> {
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }

    const formData: FormData = new FormData();




    formData.append('chartCode', chartCode);
    formData.append('userId', this.config.currentUser.id);
    formData.append('chartTitle', name);
    formData.append('subcode', charTtype);
    const req = new HttpRequest('POST', `${Config.apiUrl}/aireport/deletechart/${this.project.id}/${aitypeVal}`, formData, {
      responseType: 'json'
    });
    return this.http.request(req);
  }
  fileUpload(file: string | Blob, filename, type = null, name = null): Observable<HttpEvent<any>> {
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('aiCode', filename);
    formData.append('name', name);
    formData.append('type', type);
    formData.append('userId', this.config.currentUser.id);
    const req = new HttpRequest('POST', `${Config.apiUrl}/aireport/imageupload/${this.project.id}/${aitypeVal}`, formData, {
      responseType: 'json'
    });
    return this.http.request(req);
  }

  val(value: string | number, defaultVal = '') {
    if (value === null) {
      return defaultVal;
    } else {
      return value;
    }
  }
  cacheList(view: View, p: Project = null, aitype = '1') {

    if (view.value('msteam') == 1) {
      this.msstatus = true;
      this.mschannelid = view.value('msid');
    } else {
      this.msstatus = false;
    }
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.insightwish = false;
    this.setAIView(false);
    this.smartlistItemDetail = null;
    this.smartreportlist = null;
    this.smartlistItemDetailpaginator = null;
    this.searchSmartlistItemDetailpaginator = null;
    this.deepdiveDetails = null;
    this.searchinsightDetails = null;
    this.trendDetails = null;
    this.smartlistItemDetailtip = null;
    this.smartlistItem = null;
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    Config.loading = true;
    // Config.setLoaderMessage('InsIQ is consulting internal and external datasets, this can take 12-15 secs');
    this.dashboard = false;
    this.listViewInsight = true;

    this.cacheClear = false;
    this.InsIQclickflg = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';
    this.partnerclickflg = false;
    this.inddeep = false;
    this.clearInsights = false;
    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;

    this.selCat = 0;
    this.hasRecord = 'none';

    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = aitype;
    } else {
      aitypeVal = '2';
    }
    this.aitype = aitypeVal; // for insight
    this.project = p === null ? view.form.value : p;
    this.showWish = true;
    this.dashFav = false;
    this.setAIView(true);
    this.smartTitle = '';
    this.modeval = null;
    this.getComments();
    this.getReports();
    view.get(`aiinsights/cachelist/${this.aitype}/${this.project.id}`).subscribe(res => {
      Config.loading = false;
      this.wishListAll = res;

      if (this.wishListAll && this.wishListAll.length > 0) {
        view.get(`aiinsight/ainames/${this.aitype}/${this.project.id}`).subscribe(r => {
          this.smartlistItem = r;
        });
        this.smartlistItemDetail = this.wishListAll;
        this.smartlistItemDetailtip = this.smartlistItemDetail;
        this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
        this.smartTitle = 'All';
        this.setHighLight(this.wishListAll, false, (this.existingcomments && this.existingcomments.length > 0));
        this.sourceQuerry = this.getsourceQuerry();

      }
      this.setHasRecord();
    });
  }
  setAIView(v: boolean) {
    this.display = v;
    if (v) {
      document.querySelector('html').classList.add('compactView');
    } else {
      document.querySelector('html').classList.remove('compactView');
    }
  }


  deleteReport(row) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Action', message: 'Are you sure want to remove from Report ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.chartReportController(row, true);
        this.view.get(`aireport/deletereport/${row.id}`).subscribe(r => {
          this.chartreportinsights = this.chartreportinsights.filter(u => u.id !== row.id);
        });

      }
    });

  }
  chartReportController(row, report) {
    if (row.aiCode === 1) {
      this.trendlist[0].reportId = '';
      this.trendlist[0].note = '';

    } else if (row.aiCode === 99) {

      for (let i = 0; i < this.sentimentResp?.length; i++) {

        // console.log('sen',this.sentimentResp[i].reportId);

        // console.log('cur', row.id);
        if (this.sentimentResp[i].reportId == row.id) {
          //  console.log('found');
          let selectedChart = this.sentimentResp[i];
          if (report) {
            selectedChart.reportId = '';
            selectedChart.note = '';
            break;
          }

        }
      }
    } else if (row.aiCode === 991) {

      for (let i = 0; i < this.sentimentResp?.length; i++) {

        // console.log('sen',this.sentimentResp[i].reportId);

        // console.log('cur', row.id);
        if (this.sentimentResp[i].trendreportId == row.id) {
          //  console.log('found');
          let selectedChart = this.sentimentResp[i];
          if (report) {
            selectedChart.trendreportId = '';
            selectedChart.trendnote = '';
            break;
          }

        }
      }
    } else {
      for (let i = 0; i < this.dashCharts.length; i++) {
        if (this.dashCharts[i].reportId === row.id) {
          let selectedChart = this.dashCharts[i];
          if (report) {
            selectedChart.reportId = 0;
            selectedChart.note = '';
            break;
          }

        }
      }
    }

  }
  SentiwishList() {

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.insightwish = false;
    this.setAIView(false);
    this.smartlistItemDetail = null;
    this.smartreportlist = null;
    this.smartlistItemDetailpaginator = null;
    this.deepdiveDetails = null;
    this.searchinsightDetails = null;
    this.trendDetails = null;
    this.smartlistItemDetailtip = null;
    this.smartlistItem = null;
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    Config.loading = true;
    // Config.setLoaderMessage('InsIQ is consulting internal and external datasets, this can take 12-15 secs');
    this.dashboard = false;
    this.listViewInsight = true;

    this.cacheClear = false;
    this.InsIQclickflg = false;
    this.chartReports = false;
    // this.sentimentclickflg = false;

    this.partnerclickflg = false;
    this.inddeep = false;

    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;

    this.selCat = 0;
    this.hasRecord = 'none';


    this.setAIView(true);
    // this.smartTitle = 'Sentiment Analysis';


  }
  wishList(view: View, p: Project = null, aitype = '1') {
    // this.view.get('producttype/all/sorted').subscribe(res => {
    //   this.productTypes = res;
    // });
    this.view.get('producttype/all/sorted').subscribe(res => {
      const result = [];
      const map = new Map();
      for (const item of res) {
        if (!map.has(item.title.toLowerCase().trim())) {
          map.set(item.title.toLowerCase().trim(), true);    // set any value to Map
          result.push({
            title: item.title,

          });
        }
      }

      this.productTypes = result;
    });
    if (this.featureChecker(16)) {
      this.wishList1(view, p, aitype);
    } else {
      this.featurealert('Favorite');

    }
  }
  wishList1(view: View, p: Project = null, aitype = '1') {
    if (view.value('msteam') == 1) {
      this.msstatus = true;
      this.mschannelid = view.value('msid');
    } else {
      this.msstatus = false;
    }
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.insightwish = false;
    this.setAIView(false);
    this.smartlistItemDetail = null;
    this.smartreportlist = null;
    this.smartlistItemDetailpaginator = null;
    this.deepdiveDetails = null;
    this.searchinsightDetails = null;
    this.trendDetails = null;
    this.smartlistItemDetailtip = null;
    this.smartlistItem = null;
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    Config.loading = true;
    // Config.setLoaderMessage('InsIQ is consulting internal and external datasets, this can take 12-15 secs');
    this.dashboard = false;
    this.listViewInsight = true;

    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.partnerclickflg = false;
    this.inddeep = false;

    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;

    this.selCat = 0;
    this.hasRecord = 'none';

    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = aitype;
    } else {
      aitypeVal = '2';
    }
    this.aitype = aitypeVal; // for insight

    this.project = p === null ? view.form.value : p;

    this.view.set('productDescription', this.project.productDescription);
    this.view.set('productName', this.project.productName);

    this.view.set('brandName', this.project.brandName);

    this.view.set('problemSolve', this.project.productDescription);

    this.view.set('productEnviornment', this.project.productEnviornment);

    this.view.set('apiLanguage', this.project.apiLanguage);
    this.view.set('productTypeId', this.project.productTypeId);
    this.view.set('keyword31', this.project.keyword31);
    this.view.set('keyword6', this.project.keyword6);
    this.view.set('keyword10', this.project.keyword10);
    this.view.set('keyword2', this.project.keyword2);
    this.view.set('keyword32', this.project.keyword32);
    this.view.set('keyword8', this.project.keyword8);
    this.view.set('keyword11', this.project.keyword11);
    this.view.set('keyword7', this.project.keyword7);
    this.view.set('keyword5', this.project.keyword5);
    this.view.set('keyword4', this.project.keyword4);
    this.view.set('keyword9', this.project.keyword9);


    this.showWish = true;
    this.dashFav = true;

    this.setAIView(true);
    this.smartTitle = '';
    this.modeval = null;
    this.getComments();
    this.getReports();
    view.get(`aiinsight/favoritelist/${this.aitype}/${this.project.id}`).subscribe(res => {
      Config.loading = false;
      this.wishListAll = res;
      if (this.wishListAll && this.wishListAll.length > 0) {
        view.get(`aiinsight/ainames/${this.aitype}/${this.project.id}`).subscribe(r => {
          this.smartlistItem = r;
        });
        this.smartlistItemDetail = this.wishListAll;
        this.smartlistItemDetailtip = this.smartlistItemDetail;
        this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
        this.smartTitle = 'All';
        // console.log(this.wishListAll);

        this.smartlist = this.wishListAll;
        this.setHighLight(this.smartlist, false, (this.existingcomments && this.existingcomments.length > 0));
        // console.log(this.smartlist);
      }
      this.setHasRecord();
    });
  }
  wishListInsight(view: View, p: Project = null, aitype = '1') {
    if (this.featureChecker(16)) {
      this.wishListInsight1(view, p, aitype);
    } else {
      this.featurealert('Favorite');

    }
  }
  wishListInsight1(view: View, p: Project = null, aitype = '1') {
    this.allcatflag = true;
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.insightwish = true;
    this.setAIView(false);
    // this.smartlistItemDetail = null;
    // this.smartlistItem = null;
    Config.loading = true;
    // Config.setLoaderMessage('InsIQ is consulting internal and external datasets, this can take 12-15 secs');
    this.dashboard = false;
    this.listViewInsight = true;

    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.partnerclickflg = false;
    this.inddeep = false;
    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;

    this.selCat = 0;
    this.hasRecord = 'none';
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = aitype;
    } else {
      aitypeVal = '2';
    }
    this.aitype = aitypeVal; // for insight
    this.project = p === null ? view.form.value : p;
    this.showWish = true;
    // this.dashFav=false;

    this.setAIView(true);
    this.smartTitle = '';
    this.modeval = null;
    this.getComments();
    this.getReports();
    view.get(`aiinsight/favoritelist/${this.aitype}/${this.project.id}`).subscribe(res => {

      Config.loading = false;
      this.wishListAll = res;
      if (this.wishListAll && this.wishListAll.length > 0) {

        view.get(`aiinsight/ainames/${this.aitype}/${this.project.id}`).subscribe(r => {

          this.smartlistItem = r;
          // console.log(this.smartlistItem);
        });
        // console.log(this.wishListAll);

        this.smartlistItemDetail = this.wishListAll;
        this.smartlistItemDetailtip = this.smartlistItemDetail;
        this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
        this.modeval = null;
        this.smartTitle = 'All';
        this.smartlist = this.wishListAll;
        // this.smartlist1=this.smartlist;
        this.setHighLight(this.smartlist, false, (this.existingcomments && this.existingcomments.length > 0));

      } else {
        this.smartlistItemDetail = null;
        this.smartlistItemDetailpaginator = null;
        this.deepdiveDetails = null;
        this.searchinsightDetails = null;
        this.trendDetails = null;

        this.smartlistItemDetailtip = null;
        this.smartTitle = 'All';
        this.smartlistItem = null;
      }
      this.setHasRecord();
    });

  }
  designsmartDevSearch(fl: string) {
    this.trendchart = false;
    this.sentimentapistat = false;
    this.InsIQclickflg = false;

    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.partnerclickflg = false;
    this.inddeep = false;

    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;

    this.selCat = 0;
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    try {
      if (this.ssp.pro.isFormValid(false, this.view)) {
        // console.log(this.project.productTypeId);
        // console.log(this.view.value('productTypeId'));
        // console.log(typeof this.project.productName, '===>>>:::', this.project.productName !== this.view.value('productName').trim());

        // console.log(typeof this.project.problemSolve, this.project.problemSolve, '===>>>:::', this.project.problemSolve === this.view.value('problemSolve').trim());
        // console.log(typeof this.project.productEnviornment, this.project.productEnviornment, '===>>>:::', this.project.productEnviornment === this.view.value('productEnviornment').trim());

        this.filtervalue = fl;
        if (
          this.project.problemSolve?.trim() !== this.view.value('problemSolve')?.trim() || this.project.productEnviornment?.trim() !== this.view.value('productEnviornment')?.trim()
        ) {
          this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
            data: {
              title: 'Project Update Found',
              message: 'You have made changes to the project input, Please use ‘Clear Insights’ and re-run to get the most updated results.',
              cancelbtn: 'PROCEED WITHOUT CLEARING',
              okbtn: 'CLEAR INSIGHTS AND PROCEED'
            },
          });
          this.warningDialogRef.afterClosed().subscribe(result => {
            if (result?.data === 'confirm') {
              this.clearCache1(this.view, '0', this.smartlistItem);
              this.clearChatTurbo();

              this.designsmartSave(fl);
            } else if (result?.data === 'close') {
              this.designsmartSave(fl);
            }
          });

        } else {
          this.project.productName = this.view.value('productName');
          this.project.productDescription = this.view.value('productDescription');
          this.project.brandName = this.view.value('brandName');

          this.project.problemSolve = this.view.value('problemSolve');
          this.project.productEnviornment = this.view.value('productEnviornment');
          this.project.apiLanguage = this.view.value('apiLanguage');

          this.project.productTypeId = this.view.value('productTypeId');
          this.designsmartDev(fl, this.view, this.project);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  commonFlag1() {

    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.partnerclickflg = false;
    this.inddeep = false;

    //this.notesviewflag = false;
    //this.reportviewflag = false;
    this.insightsearch = false;

    this.selCat = 0;
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
  }

  public isFormValid(brand = true, projectView = this.view) {
    // projectView.alertService.success('TEST: success ', false, 800 * 1000);
    // projectView.alertService.error('TEST: ERROR ', false, 800 * 1000);
    // return false;

    // this.s = this.view.value('productName');
    // console.log(this.view.value('productName')?.trim().split(' ').length);
    // if (projectView.value('productName') !== null) {
    //   if (projectView.value('productName').replace(/  +/g, ' ').trim().split(' ').length < 2) {
    //     projectView.alertService.error('Short Description about your Product / Idea should have 2 words');
    //     return false;
    //   } else if (projectView.value('productName').replace(/  +/g, ' ').trim().split(' ').length > 10) {
    //     projectView.alertService.error('Short Description about your Product / Idea is limited to 10 words');
    //     return false;
    //   }
    // }
    //   console.log(projectView.value('productName'));
    if (projectView.value('productName') == null || projectView.value('productName')?.toString()?.trim() === '') {
      projectView.alertService.error('Please Fill your Product Description');
      return false;
    }
    if (projectView.value('productTypeId') == null || projectView.value('productTypeId')?.toString()?.trim() === '') {
      projectView.alertService.error('Please Fill Product Category about your Product / Idea');
      return false;
    }

    if (projectView.value('productName') !== null && projectView.value('productName').length > 256) {
      projectView.alertService.error('The project description must be less than 256 characters.');
      return false;
    }
    // if (projectView.value('productDescription') !== null) {
    //   if (projectView.value('productDescription').replace(/  +/g, ' ').trim().split(' ').length < 10) {
    //     projectView.alertService.error('Detailed Description about your Product / Idea should have 10 words');
    //     return false;
    //   } else if (projectView.value('productDescription').replace(/  +/g, ' ').trim().split(' ').length > 40) {
    //     projectView.alertService.error('Detailed Description about your Product / Idea is limited to 40 words');
    //     return false;
    //   }
    // }
    if ((projectView.value('productDescription') == null || projectView.value('productDescription')?.toString()?.trim() === '')) {
      if (projectView.value('mode') == 'Turbo Mode') {
        projectView.alertService.error('Please Fill What do you need to do of your Product / Idea');

      } else {
        projectView.alertService.error('Please Fill Keywords of your Product / Idea');

      }
      return false;
    }

    if ((projectView.value('brandName') == null || projectView.value('brandName')?.toString()?.trim() === '')) {
      if (projectView.value('mode') === 'Turbo Mode') {
        projectView.alertService.error('Please Fill Project Name');

      } else {
        projectView.alertService.error('Please Fill Product Name');

      }
      return false;
    }

    if (projectView.value('competitors') != null) {
      const temp = projectView.value('competitors');
      const count = (temp.match(/,/g) || []).length;
      if (count > 2) {

        projectView.alertService.error('Only 3 competitors allowed!');
        return false;
      }
    }



    if (projectView.value('competitorspdt') != null) {
      const temp1 = projectView.value('competitorspdt');
      const count1 = (temp1.match(/,/g) || []).length;
      if (count1 > 2) {

        projectView.alertService.error('Only 3 competitor products allowed!');
        return false;
      }
    }
    if (projectView.value('brandName') !== null) {
      if (projectView.value('brandName').replace(/  +/g, ' ').trim().split(' ').length > 5) {
        projectView.alertService.error('Product Name is limited to 5 words');
        return false;
      }
    }
    try {
      projectView.set('productTypeId', projectView.value('productTypeId').trim());
    } catch (error) {
      // console.error(error);
    }
    return true;
  }
  commonFlag() {

    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.partnerclickflg = false;
    this.inddeep = false;

    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;

    this.selCat = 0;
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
  }
  smartDevSearch(fl: string) {
    //    console.log('enterrr');
    //   console.log(fl);
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    this.view.get(`aiinsights/cachestatus/${aitypeVal}/${this.project.id}`).subscribe(res => {

      this.compStatus = res.compStatus;
      this.blogStatus = res.blogStatus;

      this.compStatusDate = res.compStatusDate;
      this.blogStatusDate = res.blogStatusDate;
      this.compRequired = res.compRequired;
      try {
        if (this.ssp.pro.isFormValid(false, this.view)) {

          this.filtervalue = fl;

          // if (this.keywordCat) {
          //   console.log('hereeeee')
          //   this.project.keyword31 = this.view.value('keyword31');
          //   this.project.keyword6 = this.view.value('keyword6');
          //   this.project.keyword10 = this.view.value('keyword10');
          //   this.project.keyword2 = this.view.value('keyword2');
          //   this.project.keyword32 = this.view.value('keyword32');
          //   this.project.keyword8 = this.view.value('keyword8');
          //   this.project.keyword11 = this.view.value('keyword11');
          //   this.project.keyword7 = this.view.value('keyword7');
          //   this.project.keyword5 = this.view.value('keyword5');
          //   this.project.keyword4 = this.view.value('keyword4');
          //   this.project.keyword9 = this.view.value('keyword9');
          //   this.commonFlag();
          //   this.smartSave(fl);
          // }
          // if (
          //   (this.project.keyword31?.trim() !== this.view.value('keyword31')?.toString()?.trim() && this.project.keyword31 !== null && this.view.value('keyword31') !== null) ||
          //   (this.project.keyword6?.trim() !== this.view.value('keyword6')?.toString()?.trim() && this.project.keyword6 !== null && this.view.value('keyword6') !== null) ||
          //   (this.project.keyword10?.trim() !== this.view.value('keyword10')?.toString()?.trim() && this.project.keyword10 !== null && this.view.value('keyword10') !== null) ||
          //   (this.project.keyword2?.trim() !== this.view.value('keyword2')?.toString()?.trim() && this.project.keyword2 !== null && this.view.value('keyword2') !== null) ||
          //   (this.project.keyword32?.trim() !== this.view.value('keyword32')?.toString()?.trim() && this.project.keyword32 !== null && this.view.value('keyword32') !== null) ||
          //   (this.project.keyword8?.trim() !== this.view.value('keyword8')?.toString()?.trim() && this.project.keyword8 !== null && this.view.value('keyword8') !== null) ||
          //   (this.project.keyword11?.trim() !== this.view.value('keyword11')?.toString()?.trim() && this.project.keyword11 !== null && this.view.value('keyword11') !== null) ||
          //   (this.project.keyword7?.trim() !== this.view.value('keyword7')?.toString()?.trim() && this.project.keyword7 !== null && this.view.value('keyword7') !== null) ||
          //   (this.project.keyword5?.trim() !== this.view.value('keyword5')?.toString()?.trim() && this.project.keyword5 !== null && this.view.value('keyword5') !== null) ||
          //   (this.project.keyword4?.trim() !== this.view.value('keyword4')?.toString()?.trim() && this.project.keyword4 !== null && this.view.value('keyword4') !== null) ||
          //   (this.project.keyword9?.trim() !== this.view.value('keyword9')?.toString()?.trim() && this.project.keyword9 !== null && this.view.value('keyword9') !== null)


          // ) {
          //   this.project.keyword31 = this.view.value('keyword31');
          //   this.project.keyword6 = this.view.value('keyword6');
          //   this.project.keyword10 = this.view.value('keyword10');
          //   this.project.keyword2 = this.view.value('keyword2');
          //   this.project.keyword32 = this.view.value('keyword32');
          //   this.project.keyword8 = this.view.value('keyword8');
          //   this.project.keyword11 = this.view.value('keyword11');
          //   this.project.keyword7 = this.view.value('keyword7');
          //   this.project.keyword5 = this.view.value('keyword5');
          //   this.project.keyword4 = this.view.value('keyword4');
          //   this.project.keyword9 = this.view.value('keyword9');
          //   this.commonFlag();
          //   this.smartSave(fl);
          // }
          if (
            (this.project.productName?.trim() !== this.view.value('productName')?.toString()?.trim() && this.project.productName !== null && this.view.value('productName') !== null) ||
            (this.project.productDescription !== this.view.value('productDescription')?.toString() && this.project.productDescription !== null && this.view.value('productDescription') !== null) ||
            (this.project.brandName?.trim() !== this.view.value('brandName')?.toString()?.trim() && this.project.brandName !== null && this.view.value('brandName') !== null) ||
            (this.project.productTypeId?.title?.trim() !== this.view.value('productTypeId')?.title?.trim() && this.project.productTypeId?.title !== null && this.view.value('productTypeId')?.title !== null) ||
            (this.project.apiLanguage?.trim() !== this.view.value('apiLanguage')?.toString()?.trim() && this.project.apiLanguage !== null && this.view.value('apiLanguage') !== null) ||
            (this.project.customer !== this.view.value('customer')?.toString() && this.project.customer !== null && this.view.value('customer') !== null) ||
            (this.project.targetRegion?.trim() !== this.view.value('targetRegion')?.toString()?.trim() && this.project.targetRegion !== null && this.view.value('targetRegion') !== null) ||
            (this.project.competitors?.trim() !== this.view.value('competitors')?.trim() && this.project.competitors !== null && this.view.value('competitors') !== null) ||
            (this.project.competitorspdt !== this.view.value('competitorspdt')?.toString() && this.project.competitorspdt !== null && this.view.value('competitorspdt') !== null) ||
            (this.project.companyname?.trim() !== this.view.value('companyname')?.toString()?.trim() && this.project.companyname !== null && this.view.value('companyname') !== null)


          ) {
            // console.log('hereee 11111');
            if ((this.smartlist || this.sentimentResp.length != 0) && this.view.value('mode') == 'Research Mode') {
              this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
                data: {
                  title: 'Project Update Found',
                  message: 'You have made changes to the project input, Please use ‘Clear Insights’ and re-run to get the most updated results.',
                  cancelbtn: 'PROCEED WITHOUT CLEARING',
                  okbtn: 'CLEAR INSIGHTS AND PROCEED'
                },
              });
              this.warningDialogRef.afterClosed().subscribe(result => {
                if (result?.data === 'confirm') {
                  this.clearInsights = true;
                  this.clearChatTurbo();

                  this.dashCharts = [];
                  // this.showDashboard();
                  this.clearCache1(this.view, '0', this.smartlistItem);
                  this.project.productTypeId = this.view.value('productTypeId');
                  if (this.sentimentResp.length != 0) {
                    this.clearInsights = true;

                    this.dashCharts = [];
                    //   this.showDashboard();
                    this.clearCache1(this.view, '99', this.smartlistItem);
                    this.project.productTypeId = this.view.value('productTypeId');
                  }
                  this.commonFlag();
                  // if (this.val(this.project.productNewOrExisting) == 1) {
                  //   this.blogStatus = false;
                  // } else if (this.val(this.project.productNewOrExisting) == 2) {
                  this.blogStatus = false;
                  if (this.val(this.project.competitors) == '' || this.val(this.project.competitorspdt) == '') {
                    this.compStatus = true;
                    this.compStatusDate = true;
                  } else {
                    this.compStatus = false;
                  }


                  //}


                  if ((fl === '0' || fl == '0') && this.view.value('mode') == 'Research Mode') {
                    this.fixaitypeId = '0';
                    this.dashCharts = [];
                    // if (!this.compStatus || !this.blogStatus) {
                    //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
                    //     data: {
                    //       title: 'Insight Call Required',
                    //       message: 'Please run ' + this.getLabels() + ' before running Sentiment Analysis.',
                    //       cancelbtn: 'Cancel',

                    //       okbtn: 'OK',
                    //       btnflg: false
                    //     },
                    //   });
                    //   this.warningDialogRef.afterClosed().subscribe(result => {
                    //     if (result?.data === 'confirm') {
                    //       this.commonFlag();
                    //       this.showDashboard();
                    //       //this.smartSave(fl);

                    //       // if (this.val(this.project.productNewOrExisting) == 1) {
                    //       //   this.blogStatus = false;
                    //       // } else if (this.val(this.project.productNewOrExisting) == 2) {
                    //       this.blogStatus = false;
                    //       if (this.val(this.project.competitors) == '' || this.val(this.project.competitorspdt) == '') {
                    //         this.compStatus = true;
                    //         this.compStatusDate = true;
                    //       }                    //       else {
                    //         this.compStatus = false;
                    //       }
                    //       //   }

                    //     }
                    //   });

                    // } else if (!this.compStatusDate || !this.blogStatusDate) {
                    //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
                    //     data: {


                    //       title: 'New Insight Call Recommended',
                    //       message: 'Please refresh ' + this.getLabelsDate() + ' to get the most recent  Sentiment Analysis data.',
                    //       cancelbtn: 'CANCEL',

                    //       okbtn: 'CONTINUE'
                    //     },
                    //   });
                    //   this.warningDialogRef.afterClosed().subscribe(result => {
                    //     if (result?.data === 'confirm') {
                    //       // this.commonFlag();
                    //       this.smartSave(fl);
                    //     }
                    //   });
                    // }
                    // else {
                    //this.commonFlag();
                    this.smartSave(fl);

                    //}
                    // if (this.val(this.project.productNewOrExisting) == 1) {
                    //   this.blogStatus = false;
                    // } else if (this.val(this.project.productNewOrExisting) == 2) {
                    this.blogStatus = false;
                    if (this.val(this.project.competitors) == '' || this.val(this.project.competitorspdt) == '') {
                      this.compStatus = true;
                      this.compStatusDate = true;
                    } else {
                      this.compStatus = false;
                    }
                    // }


                  } else {
                    // this.commonFlag();
                    this.smartSave(fl);
                  }
                } else if (result?.data === 'close') {
                  this.commonFlag();
                  // if (this.val(this.project.productNewOrExisting) == 1) {
                  //   this.blogStatusDate = false;
                  // } else if (this.val(this.project.productNewOrExisting) == 2) {
                  this.blogStatusDate = false;
                  this.compStatusDate = false;
                  //  }

                  if ((fl === '0' || fl == '0') && this.view.value('mode') == 'Research Mode') {

                    // if (!this.compStatus || !this.blogStatus) {
                    //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
                    //     data: {
                    //       title: 'Insight Call Required',
                    //       message: 'Please run ' + this.getLabels() + ' before running Sentiment Analysis.',
                    //       cancelbtn: 'Cancel',

                    //       okbtn: 'OK',
                    //       btnflg: false
                    //     },
                    //   });
                    //   this.warningDialogRef.afterClosed().subscribe(result => {
                    //     if (result?.data === 'confirm') {
                    //       this.commonFlag();
                    //       this.showDashboard();
                    //       // this.smartSave(fl);
                    //     }
                    //   });

                    // } else if (!this.compStatusDate || !this.blogStatusDate) {
                    //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
                    //     data: {
                    //       title: 'New Insight Call Recommended',
                    //       message: 'Please refresh ' + this.getLabelsDate() + ' to get the most recent  Sentiment Analysis data.',
                    //       cancelbtn: 'CANCEL',

                    //       okbtn: 'CONTINUE'
                    //     },
                    //   });
                    //   this.warningDialogRef.afterClosed().subscribe(result => {
                    //     if (result?.data === 'confirm') {
                    //       this.commonFlag();
                    //       this.smartSave(fl);
                    //     }
                    //   });
                    // }
                    // else {
                    this.commonFlag();
                    this.smartSave(fl);

                    // }


                  } else {
                    this.commonFlag();
                    this.smartSave(fl);
                  }
                }
              });
            } else {

              this.commonFlag();

              if ((fl === '0' || fl == '0') && this.view.value('mode') == 'Research Mode') {

                // if (!this.compStatus || !this.blogStatus) {
                //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
                //     data: {
                //       title: 'Insight Call Required',
                //       message: 'Please run ' + this.getLabels() + ' before running Sentiment Analysis.',
                //       cancelbtn: 'Cancel',

                //       okbtn: 'OK',
                //       btnflg: false
                //     },
                //   });
                //   this.warningDialogRef.afterClosed().subscribe(result => {
                //     if (result?.data === 'confirm') {
                //       this.commonFlag();
                //       //this.smartSave(fl);
                //       this.showDashboard();

                //       // if (this.val(this.project.productNewOrExisting) == 1) {
                //       //   this.blogStatus = false;
                //       // } else if (this.val(this.project.productNewOrExisting) == 2) {
                //       this.blogStatus = false;
                //       if (this.val(this.project.competitors) == '' || this.val(this.project.competitorspdt) == '') {
                //         this.compStatus = true;
                //         this.compStatusDate = true;
                //       }                //       else {
                //         this.compStatus = false;
                //       }
                //       // }

                //     }
                //   });

                // } else if (!this.compStatusDate || !this.blogStatusDate) {
                //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
                //     data: {
                //       title: 'New Insight Call Recommended',
                //       message: 'Please refresh ' + this.getLabelsDate() + ' to get the most recent  Sentiment Analysis data.',
                //       cancelbtn: 'CANCEL',

                //       okbtn: 'CONTINUE'
                //     },
                //   });
                //   this.warningDialogRef.afterClosed().subscribe(result => {
                //     if (result?.data === 'confirm') {
                //       // this.commonFlag();
                //       this.smartSave(fl);
                //     }
                //   });
                // }
                // else {
                //this.commonFlag();
                this.smartSave(fl);

                // }
                // if (this.val(this.project.productNewOrExisting) == 1) {
                //   this.blogStatus = false;
                // } else if (this.val(this.project.productNewOrExisting) == 2) {
                this.blogStatus = false;
                if (this.val(this.project.competitors) == '' || this.val(this.project.competitorspdt) == '') {
                  this.compStatus = true;
                  this.compStatusDate = true;
                } else {
                  this.compStatus = false;
                }
                // }


              } else {
                // this.commonFlag();
                this.smartSave(fl);
              }

            }

          } else {

            //   console.log(fl);
            this.project.productName = this.view.value('productName');
            this.project.productDescription = this.view.value('productDescription');
            this.project.brandName = this.view.value('brandName');
            this.project.apiLanguage = this.view.value('apiLanguage');
            this.project.problemSolve = this.view.value('problemSolve');
            this.project.productEnviornment = this.view.value('productEnviornment');
            this.project.productTypeId = this.view.value('productTypeId');


            this.project.customer = this.view.value('customer');
            this.project.targetRegion = this.view.value('targetRegion');
            this.project.competitors = this.view.value('competitors');
            this.project.competitorspdt = this.view.value('competitorspdt');
            this.project.companyname = this.view.value('companyname');
            this.project.companyname = this.view.value('companyname');
            this.project.projectOwner = this.view.value('projectOwner');

            this.project.keyword31 = this.view.value('keyword31');
            this.project.keyword6 = this.view.value('keyword6');
            this.project.keyword10 = this.view.value('keyword10');
            this.project.keyword2 = this.view.value('keyword2');
            this.project.keyword32 = this.view.value('keyword32');
            this.project.keyword8 = this.view.value('keyword8');
            this.project.keyword11 = this.view.value('keyword11');
            this.project.keyword7 = this.view.value('keyword7');
            this.project.keyword5 = this.view.value('keyword5');
            this.project.keyword4 = this.view.value('keyword4');
            this.project.keyword9 = this.view.value('keyword9');

            // smartDev(fl: any, view: View, p: Project = null, pdf = false, flag = false) {
            if ((fl === '0' || fl == '0') && this.view.value('mode') == 'Research Mode') {

              // if (!this.compStatus || !this.blogStatus) {
              //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
              //     data: {
              //       title: 'Insight Call Required',
              //       message: 'Please run ' + this.getLabels() + ' before running Sentiment Analysis.',
              //       cancelbtn: 'Cancel',

              //       okbtn: 'OK',
              //       btnflg: false
              //     },
              //   });

              // } else if (!this.compStatusDate || !this.blogStatusDate) {
              //   this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
              //     data: {
              //       title: 'New Insight Call Recommended',
              //       message: 'Please refresh ' + this.getLabelsDate() + ' to get the most recent  Sentiment Analysis data.',
              //       cancelbtn: 'CANCEL',

              //       okbtn: 'CONTINUE'
              //     },
              //   });
              //   this.warningDialogRef.afterClosed().subscribe(result => {
              //     if (result?.data === 'confirm') {
              //       this.sentimentapi(this.view, this.project);

              //     }
              //   });
              // }
              // else {
              this.sentimentapi(this.view, this.project);

              // }


            } else if (fl == '101' || fl === '101') {
              //  console.log('keriiii');
              this.averagePriceApi(this.view);
            } else {
              //this.smartDev(fl, this.view, this.project);
              this.smartSave(fl);

            }
            this.commonFlag();
          }
        }
      } catch (error) {
        console.error(error);
      }

    });

  }
  sortChangeClick(sortOrder) {

    // console.log(event);
    // let sortOrder = event.value;
    switch (sortOrder) {
      case "1": this.dateSorter(1);
        break;
      case "2": this.dateSorter(0);
        break;
      case "3": this.relevanceSorter(1);
        break;
      case "4": this.relevanceSorter(0);
        break;
    }
  }
  sortChange(event) {

    // console.log(event);
    let sortOrder = event.value;
    switch (sortOrder) {
      case "1": this.dateSorter(1);
        break;
      case "2": this.dateSorter(0);
        break;
      case "3": this.relevanceSorter(1);
        break;
      case "4": this.relevanceSorter(0);
        break;
    }
  }
  relevanceSorter(typ) {
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;

    // this.sortBy.date.new = false;
    // this.sortBy.date.old = false;

    if (typ === 1) {
      //  this.sortBy.date.new = true;
      this.smartlistItemDetail = this.smartlistItemDetail?.sort((a, b) => b.aiScore - a.aiScore);
      this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
    }
    if (typ === 0) {
      // this.sortBy.date.old = true;
      this.smartlistItemDetail = this.smartlistItemDetail.sort((a, b) => a.aiScore - b.aiScore);
      this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
    }
  }
  insightGenerator() {
    this.dashCharts = [];

    // console.log(this.flValSelect)
    if (this.isFormValid()) {
      if (this.view.value('mode') == 'Turbo Mode') {

        this.flValSelect = '199';
        this.flSelected = 199;
        this.smartDevSearch(this.flValSelect);


      } else {
        this.smartDevSearch(this.flValSelect);

      }

    }
  }
  projSave() {
    if (this.isFormValid()) {
      Config.loading = true;
      Config.inlineLoading = true;

      this.smartSaveProj(this.flValSelect);

    }
  }
  getLabels() {
    if (this.compRequired && !this.compStatus && !this.blogStatus) {
      return 'Blogs & Reviews and Competitors';
    } else if (this.compRequired && !this.compStatus) {
      return 'Competitors';
    } else if (this.compRequired && !this.blogStatus) {
      return 'Blogs & Reviews';
    } else if (!this.compStatus) {
      return 'Competitors';
    } else if (!this.blogStatus) {
      return 'Blogs & Reviews';
    }
  }
  getLabelsDate() {
    if (this.compRequired && !this.compStatusDate && !this.blogStatusDate) {
      return 'Blogs & Reviews and Competitors';
    } else if (this.compRequired && !this.compStatusDate) {
      return 'Competitors';
    } else if (this.compRequired && !this.blogStatusDate) {
      return 'Blogs & Reviews';
    } else if (!this.compStatusDate) {
      return 'Competitors';
    } else if (!this.blogStatusDate) {
      return 'Blogs & Reviews';
    }
  }
  updatesentimentflag() {
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    this.view.get(`aiinsights/cachestatus/${aitypeVal}/${this.project.id}`).subscribe(res => {

      this.compStatus = res.compStatus;
      this.blogStatus = res.blogStatus;

      this.compStatusDate = res.compStatusDate;
      this.blogStatusDate = res.blogStatusDate;
      this.compRequired = res.compRequired;


    });
  }
  sentimentclick(sentimentSelected = null) {
    // console.log(keyvalue?.mode);
    // let index;
    this.sentimentSelected = sentimentSelected;
    // console.log(sentimentSelected);
    this.insightSA = this.sentimentSelected.report;
    this.insightSAN = this.sentimentSelected.noteflag;
    // if (sentimentSelected === null || sentimentSelected === undefined || sentimentSelected === '') {
    //   // console.log('found');
    //   index = 0;
    // }
    // else {
    //   for (let i = 0; i < this.sentimentResp.length; i++) {
    //     if (sentimentSelected?.mode === this.sentimentResp[i].mode) {
    //       index = i;
    //     }
    //   }
    // }
    this.setSentiment();
    this.dashboard = false;
    this.listViewInsight = true;

    this.fixaitypeId = '0';

    this.tagcloudclick = false;
    // this.smartTitle = 'Sentiment Analysis';
    this.partnerclickflg = false;
    this.inddeep = false;

    // this.notesviewflag=false;
    this.selCat = 0;
    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = true;
    this.averagePrice = false;

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
  }
  InsIQclick() {
    this.dashboard = false;
    this.listViewInsight = true;

    this.tagcloudclick = false;
    this.smartTitle = 'What is InsIQ?';
    this.modeval = null;
    this.partnerclickflg = false;
    this.inddeep = false;

    // this.notesviewflag=false;
    this.selCat = 0;
    this.InsIQclickflg = true;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
  }

  teamshare(type: string = '', url: string = '', title: string = '', text: string = '', note: string = '') {
    if (type == null || type === undefined) {
      type = 'InsIQ Insight';
    } else if (this.showWish) {
      type = this.notesRow.aiName;
    }
    this.teammessage = `Insight from InsIQ Ai#Category: ${type} #${title}\n${text?.replace(/<[^>]*>/g, '')}#For more Info follow: ${url}${note}`;
    // console.log(this.mschannelid);
    // console.log('TeammessageDialogComponent');
    const dialogRef = this.dialog.open(TeammessageDialogComponent, {
      width: '806px',
      maxWidth: '65vw',
      data: {
        teammessage: this.teammessage
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'post') {
        this.teamshare1(result.data);
      }
    });
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
  teamshare1(message: string) {
    this.view.get('msaccount/msaccountvalid').subscribe(async res => {
      // console.log(res);
      if (res !== null) {
        const result = this.app.loginPopup(OAuthSettings);

        if (result) {
          this.authenticated = true;
          // console.log('Trooooo'+this.view.value('id'));
          this.getachannel(res.teamId, message);
        }
      }
    });
  }
  async getachannel(teamId: any, messagetosent: any) {
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    Config.loading = true;
    Config.setLoaderMessage('Posting AI Insight to your MS Teams Channel');
    if (!this.authenticated) { return null; }

    const graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        const token = await this.getAccessToken().catch((reason) => {
          done(reason, null);
        });

        if (token) {
          done(null, token);
        } else {
          done('Could not get an access token', null);
        }
      }
    });

    try {
      const chatMessage = { body: { content: messagetosent } };
      const res = await graphClient.api(`/teams/${teamId}/channels/${this.mschannelid}/messages`).post(chatMessage);

      if (res.id !== '') {
        Config.loading = false;
        this.alertService.success('AI Posted to MS Teams Channel Successfully !!!', true, 10 * 1000);
        // this.goforanupdate(projid,msid,res.id,res.displayName, res.description, res.webUrl);
      } else {
        Config.loading = false;
        this.alertService.error(
          'InsIQ failed to post AI to your MS Teams Channel Please try later !!!',
          true, 10 * 1000);
      }
    } catch (error) {

      Config.loading = false;
      this.alertService.error(
        'InsIQ failed to post AI to your MS Teams Channel...' + error?.message + '',
        true, 20 * 1000);
    }
  }
  async getAccessToken(): Promise<string> {
    let result;
    {

      try {
        let tokenResponse = await this.app.handleRedirectPromise();

        let accountObj;
        if (tokenResponse) {
          accountObj = tokenResponse.account;
        } else {
          accountObj = this.app.getAllAccounts()[0];
        }

        if (accountObj && tokenResponse) {
          console.log("[AuthService.init] Got valid accountObj and tokenResponse")
        } else if (accountObj) {
          console.log("[AuthService.init] User has logged in, but no tokens.");
          try {
            tokenResponse = await this.app.acquireTokenSilent({
              account: this.app.getAllAccounts()[0],
              scopes: ["user.read"]
            })
          } catch (err) {
            await this.app.acquireTokenRedirect({ scopes: ["user.read"] });
          }
        } else {
          console.log("[AuthService.init] No accountObject or tokenResponse present. User must now login.");
          await this.app.loginRedirect({ scopes: ["user.read"] })
        }
        // console.log('fffToken', tokenResponse.accessToken);
        result = tokenResponse.accessToken;

      } catch (error) {
        console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
      }

    }
    if (result) {
      return result;
    }
    return null;
  }

  chartReport() {
    this.listViewInsight = true;

    this.dashboard = false;
    this.modeval = null;
    this.tagcloudclick = false;
    this.smartTitle = 'AI Charts';
    this.partnerclickflg = false;
    this.inddeep = false;

    // this.notesviewflag=false;
    this.selCat = 0;
    this.InsIQclickflg = false;
    this.chartReports = true;
    this.cacheClear = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
  }

  partnerclick() {
    this.dashboard = false;
    this.listViewInsight = true;

    this.tagcloudclick = false;
    this.smartTitle = 'Partners and Services';
    this.partnerclickflg = true;
    this.inddeep = false;
    this.modeval = null;
    // this.notesviewflag=false;
    this.selCat = 0;
    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
  }

  // OnPageChange(event: PageEvent) {
  //   const startIndex = event.pageIndex * event.pageSize;
  //   let endIndex = startIndex + event.pageSize;
  //   if (endIndex > this.length) {
  //     endIndex = this.length;
  //   }
  //   this.smartlistItem = this.smartlistItem.slice(startIndex, endIndex);
  // }

  onResize(event: { target: { innerWidth: number; }; }) { // to adjust to screen size
    this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 3;
  }

  getCurrentTab() {
    return this.currentAiTabIndex;
  }

  tagManager(e, aiTypeID) {
    this.chartSel = aiTypeID;
    // console.log(aiTypeID);
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    const [tag, data] = e;
    // console.log(tag.label);
    const list = new Array();
    // this.smartlistItemDetail = this.wishListAll.filter(u => u.aiName === aiName);
    // console.log(this.smartlistItem.length);
    for (let i = 0; i < this.smartlistItem.length; i++) {
      //  console.log(this.smartlistItem[i]);
      for (let j = 0; j < this.smartlistItem[i].aiLink.length; j++) {
        //  console.log(this.smartlistItem[i].aiTypeID);
        if (this.smartlistItem[i].aiTypeID === aiTypeID) {
          //  console.log('found');
          let aiLink = this.smartlistItem[i].aiLink[j];
          // console.log(aiLink.aiConcepts.split(','));
          // this.smartlistItem[i]=  this.smartlistItem[i].filter(u => u.aiTypeID === 2);
          if (aiLink.aiConcepts.split(',').includes(tag.label.trim())) {
            // console.log('insights', tag.label.trim());
            aiLink.aiNameTemp = this.smartlistItem[i].aiName;

            aiLink.aiNameIdTemp = this.smartlistItem[i].aiTypeID;

            // console.log('==' + this.smartlistItem[i].aiLink[j].aiNameTemp + '==');
            list.push(aiLink);
          }
        }
      }
    }
    if (list.length > 0) {
      this.smartlistItemDetail = list;
      this.smartlistItemDetailtip = this.smartlistItemDetail;
      this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
      this.dashboard = false;
      this.listViewInsight = true;

      this.InsIQclickflg = false;
      this.cacheClear = false;
      this.chartReports = false;
      this.sentimentclickflg = false;
      this.averagePrice = false;

      this.sentimentSelected = '';

      this.partnerclickflg = false;
      this.inddeep = false;

      this.notesviewflag = false;
      this.reportviewflag = false;
      this.insightsearch = false;

      this.selCat = 0;
      this.setLiked(list);
      this.smartTitle = 'Insights filtered by: ' + tag.label;
      this.modeval = null;
      this.tagcloudclick = true;
      if (this.view.value('mode') == 'Turbo Mode') {
        this.tabNumber = 2;
      }
    } else {
      this.openSnackBar({
        type: 'simple',
        message: 'No Relation Found!',
        action: 'CLOSE',
        duration: 3000
      });
      // this.view.openSnackBar({
      //   type: 'custom',
      //   message: 'No Relation Found!',
      //   action: 'CLOSE',
      //   duration: 3000,
      //   component: SnackbarComponent
      // });
    }

  }

  openSnackBar(s: any) {
    s = (s === undefined || typeof s !== 'object') ? {
      duration: 3,
      type: 'simple',
      action: 'CLOSE',
      message: 'No Relation Found!',
      verticalPosition: 'top'
    } : s;

    s.duration = s.duration === undefined ? 2000 : s.duration;
    s.message = s.message === undefined ? 'InsIQ Message' : s.message;
    s.action = s.action === undefined ? 'ok' : s.action;

    if (s.type === 'custom') {
      this._snackBar.openFromComponent(s.component, {
        duration: s.duration * 1000,
        panelClass: 'InsIQ',
        verticalPosition: 'top'
      });
    } else {
      this._snackBar.open(s.message, s.action, {
        duration: s.duration,
        panelClass: 'InsIQ',
        verticalPosition: 'top'
      });
    }
  }

  showDashboard() {
    //console.log('dashhh')
    this.projectFavFlag = false;

    this.flSelected = 199;
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.dashboard = true;
    this.listViewInsight = true;

    this.smartTitle = 'My Insights';
    this.tagcloudclick = false;
    this.notesadd = false;
    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.partnerclickflg = false;
    this.inddeep = false;

    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;
    this.showWish = false;

    this.modeval = null;
    this.selCat = 0;
    this.getLiked();
    this.getComments();
    this.getReports();
    this.smartlist = this.smartlist1;
    this.smartlistItem = this.smartlist?.aiInsightsLink;
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;


    this.smartlistItemDetail = this.smartlistItem;
    this.smartlistItemDetailtip = this.smartlistItemDetail;
    this.setHasRecord();

    this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);
    if (this.view.value('mode') == 'Turbo Mode') {
      this.tabNumber = 1;
    }
  }
  getCropped() {
    // Retrieve image Blob URL from localStorage
    let storedData;

    const storedData1 = localStorage.getItem('currentUserStore');


    const data = JSON.parse(storedData1);
    storedData = data?.imageurl;


    // Check if storedData exists and parse it
    if (storedData) {
      try {
        //const data = JSON.parse(storedData);
        const imageDataUrl = storedData;

        // console.log({ imageDataUrl });


        // Sanitize the URL before using it (bypass security check)
        let currentTimestampInSeconds: number = Math.floor(Date.now() / 1000);


        this.croppedImage = environment.avatarUrl + imageDataUrl + '?a=' + currentTimestampInSeconds;
        return this.croppedImage; // Return the sanitized URL

      } catch (error) {
        // console.error('Error parsing stored data or creating image URL:', error);
        return '';
      }
    }
    else {
      return '';

    }

  }
  sendMessage() {
    this.showSuggestions = false;
    this.suggestedQuestions = [];
    const newMessage = this.newMessageForm.get('newMessage').value.trim();
    if (newMessage !== '') {
      this.newQuerry = newMessage;
      const iconValue = this.getCropped() !== '' ? true : false;
      this.messages.push({
        user: {
          name: this.config.currentUser?.name,
          icon: iconValue
        },
        content: newMessage
      });
      this.newMessageForm.patchValue({
        newMessage: ''
      });

    }

    setTimeout(() => {
      // Desktop View Scrollbar
      this.scrollTo({
        parent: this.aiChatScrollViewer,
        child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
      });
      // Mobile View Scrollbar
      this.scrollTo({
        parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
        child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
      });

      // AI chat window Scrollbar
      this.scrollTo({
        align: "bottom",
        parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
        child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
      });
    }, 0);
    this.chatSequence = this.getHighestDtSequence() + 1;

    this.turbochat(this.project.id, newMessage, 1, 0);
  }

  designsmartDev(fl: any, view: View, p: Project = null, pdf = false, flag = false) {
    this.desiagnApiCall = true;
    this.trendchart = false;
    this.sentimentapistat = false;
    this.insightC0 = false;
    this.insightC1 = false;
    this.insightC2 = false;
    this.insightC3 = false;
    this.insightC4 = false;
    this.insightC5 = false;
    this.insightC6 = false;
    this.insightC7 = false;
    this.insightC8 = false;
    this.insightTR = false;
    this.insightSA = false;

    this.insightC0N = false;
    this.insightC1N = false;
    this.insightC2N = false;
    this.insightC3N = false;
    this.insightC4N = false;
    this.insightC5N = false;
    this.insightC6N = false;
    this.insightC7N = false;
    this.insightC8N = false;
    this.insightTRN = false;
    this.insightSAN = false;
    if (view.value('msteam') == 1) {
      this.msstatus = true;
      this.mschannelid = view.value('msid');
    } else {
      this.msstatus = false;
    }
    // this.aiidset='';
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.partnerclickflg = false;
    this.inddeep = false;

    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;

    this.selCat = 0;
    try {
      this.filtervalue = fl;
      this.view.get('producttype/all/sorted').subscribe(res => {
        const result = [];
        const map = new Map();
        for (const item of res) {
          if (!map.has(item.title.toLowerCase().trim())) {
            map.set(item.title.toLowerCase().trim(), true);    // set any value to Map
            result.push({
              title: item.title,

            });
          }
        }

        this.productTypes = result;
      });
      this.progress = 0;
      this.config.setLoaderPercentage(this.progress);

      // if (pdf) {
      //   Config.setLoaderMessage('InsIQ is preparing your executive report, this can take a few seconds');
      // }
      if (!flag) {
        // Config.setLoaderImage('./assets/images/insiq_ai.png');
        Config.setLoaderImage('./assets/images/insiq_ai_insights.gif');
        Config.setLoaderMessage('InsIQ is finding the perfect insights for you, this can take a few seconds');
      }
      Config.loading = true;

      this.hasRecord = 'none';
      this.aitype = '1'; // for insight
      this.project = p === null ? view.form.value : p;
      this.setAIView(true);
      this.displayReq = false;
      this.showWish = false;
      this.dashFav = false;
      this.modeval = null;
      this.smartTitle = '';
      this.getLiked();
      this.getComments();
      this.getReports();
      let AI_URL: string;
      // console.log(location.host);
      if (location.host.includes(Config.MAINPROD_URL)) {
        AI_URL = this.getDesignParams('AIDDesign', view, p);
      } else {
        AI_URL = this.getDesignParams('AIDDesign', view, p);
      }

      // let TREND_URL: string;
      // // console.log(location.host);
      // if (location.host.includes(Config.MAINPROD_URL)) {
      //   TREND_URL = this.getParams('trends', view, p);
      // } else {
      //   TREND_URL = this.getParams('trends', view, p);
      // }
      this.callDesignApi(view, AI_URL, pdf, flag);
    } catch (error) {
      console.error(error);
    }
  }

  private callDesignApi(view: View, AI_URL: string, pdf = false, cache = false) {
    this.trendChartLabels = [];
    this.trendChartData = [];
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    Config.loading = true;

    const querryUrlParams = AI_URL.split('?');
    let flag: number;
    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    if (cache) {
      this.config.setProgressVisibility(false);

      this.view.get(`aiinsights/cachelist/2/${this.project.id}`).subscribe(res => {
        this.setRecord(res, pdf);
      });
    } else {
      this.config.setProgressVisibility(true);

      this.view.get(`aiinsights/designapi/${flag}/${querryUrlParams[1]}`).subscribe(res => {
        this.setRecord(res, pdf);
      });
      this.progressManager();
    }
    this.aiidset = '';
    this.deepdiveflag = false;
  }
  setRecord(res, pdf) {
    Config.loading = false;
    // console.log(res);

    this.smartlist = res;
    this.smartlist1 = this.smartlist;
    //  console.log( this.smartlist);
    if (this.smartlist.NumberOfResults === 0) {

      this.hasRecord = 'false';
      this.insight0 = false;
      this.insight1 = false;
      this.insight2 = false;
      this.insight3 = false;
      this.insight4 = false;
      this.insight5 = false;
      this.insight6 = false;
      this.insight7 = false;
      this.insight8 = false;
    }
    this.insightCall(pdf);
  }
  private getDesignParams(url, view: View, p: Project = null) {


    // console.log(p);
    // console.log(config.currentUser.aiid);
    this.setProject();
    this.charttitle = this.getcharttitle(view.value('productNewOrExisting', ''), view);
    this.projcatName = view.value('productNewOrExisting', '');
    const epf = this.getepf(view.value('productNewOrExisting', ''));
    this.productFlag = epf;
    const epc = this.getepc(view.value('brandName', ''), view.value('productNewOrExisting', ''));
    if (p === null) {
      // console.log()
      return `${url}?t=${encodeURIComponent(this.transform(view.value('productName', '')))}`
        + `&q=${encodeURIComponent(this.transform(view.value('productDescription', '')))}`
        + `&q3=${encodeURIComponent(this.transform(view.value('productTypeId', '').title))}`
        + `&q4=${encodeURIComponent(this.transform(view.value('customer', '')))}`
        + `&pn=${encodeURIComponent(this.transform(view.value('brandName', '')))}`
        + `&cc=${encodeURIComponent(this.transform(view.value('targetRegion', '')))}`
        + `&rn=${encodeURIComponent(view.value('id', ''))}`
        + `&epf=${encodeURIComponent(epf)}`
        + `&lang=${encodeURIComponent(this.transform(view.value('apiLanguage', '')))}`
        + `&epc=${encodeURIComponent(this.transform(view.value('companyname', '')))}`
        + `&epcomp=${encodeURIComponent(this.transform(view.value('competitors', '')))}`
        + `&epprod=${encodeURIComponent(this.transform(view.value('competitorspdt', '')))}`
        + `&fl=${encodeURIComponent(this.filtervalue)}`
        + `&rt=${encodeURIComponent(this.reporttype)}`
        + `&dd=${this.aiidset}`
        + `&ps=${encodeURIComponent(this.transform(view.value('problemSolve', '')))}`
        + `&env=${encodeURIComponent(this.transform(view.value('productEnviornment', '')))}`
        + `&ch=${encodeURIComponent(this.transform(view.value('productChannel', '')))}`
        + `&vl=${encodeURIComponent(this.transform(view.value('prodnVol', '')))}`
        + `&mn=${encodeURIComponent(this.transform(view.value('manufacturer', '')))}`
        + `&rg=${encodeURIComponent(this.transform(view.value('productRegulation', '')))}`
        + `&pr=${encodeURIComponent(this.transform(view.value('sellRate', '')))}`;
      // + `&q3=${view.value('productTypeId', '').title}`
      // + `&q4=${view.value('customer', '')}`
      // + `&q5=${view.value('productBenifit', '')}`
      // + `&q6=${view.value('targetRegion', '')}`
      // + `&q7=${view.value('similarProduct', '')}`
      // + `&q8=${view.value('inspiration', '')}`
      // + `&bn=${view.value('brandName', '')}`
      // + `&cc=${view.value('targetRegion', '')}&key=${Config.AI_API_KEY}`;
    } else {
      // tslint:disable-next-line:max-line-length
      const epf1 = this.getepf(this.val(p.productNewOrExisting));

      if (this.val(p.productNewOrExisting) == 1) {
        this.charttitle = this.val(p.productName);
      } else if (this.val(p.productNewOrExisting) == 2) {
        this.charttitle = this.val(p.brandName);
      }
      this.productFlag = epf1;

      // let epc1 = this.getepc(this.val(p.brandName), this.val(p.productNewOrExisting));
      return `${url}?t=${encodeURIComponent(this.transform(this.val(p.productName)))}&q=${encodeURIComponent(this.transform(this.val(p.productDescription)))}&epcomp=${encodeURIComponent(this.transform(this.val(p.competitors)))}&epprod=${encodeURIComponent(this.transform(this.val(p.competitorspdt)))}&q3=${encodeURIComponent(this.transform(this.val(p.productTypeId.title)))}&q4=${encodeURIComponent(this.transform(this.val(p.customer)))}&pn=${encodeURIComponent(this.transform(this.val(p.brandName)))}&cc=${encodeURIComponent(this.transform(this.val(p.targetRegion)))}&rn=${encodeURIComponent(this.val(p.id))}&fl=${encodeURIComponent(this.filtervalue)}&rt=${encodeURIComponent(this.reporttype)}&dd=${this.aiidset}&epc=${encodeURIComponent(this.transform(this.val(p.companyname)))}&epf=${epf1}&lang=${encodeURIComponent(this.transform(this.val(p.apiLanguage)))}&ps=${encodeURIComponent(this.transform(this.val(p.problemSolve)))}&env=${encodeURIComponent(this.transform(this.val(p.productEnviornment)))}&ch=${encodeURIComponent(this.transform(this.val(p.productChannel)))}&vl=${encodeURIComponent(this.transform(this.val(p.prodnVol)))}&mn=${encodeURIComponent(this.transform(this.val(p.manufacturer)))}&rg=${encodeURIComponent(this.transform(this.val(p.productRegulation)))}&pr=${encodeURIComponent(this.transform(this.val(p.sellRate)))}`;
      // return `${url}?t=${this.val(p.productName)}&q=${this.val(p.productDescription)}&q3=${this.val(p.productTypeId.title)}&q4=${this.val(p.customer)}&q5=${this.val(p.productBenifit)}&q6=${this.val(p.targetRegion)}&q7=${this.val(p.similarProduct)}&q8=${this.val(p.inspiration)}&bn=${this.val(p.brandName)}&cc=${this.val(p.targetRegion)}&key=${Config.AI_API_KEY}`;
    }
  }

  // sentiment(view: View) {
  //   this.sentimentapi(view);
  //   this.sentimentclickflg = true;
  //   this.sentimentapistat = true;
  //   // this.smartTitle = 'Sentiment Analysis';
  //   //this.sentimentclick();
  //   this.SentiwishList();

  // }


  smartDev(fl: any, view: View, p: Project = null, pdf = false, flag = false) {

    this.desiagnApiCall = false;
    this.insightC0 = false;
    this.insightC1 = false;
    this.insightC2 = false;
    this.insightC3 = false;
    this.insightC4 = false;
    this.insightC5 = false;
    this.insightC6 = false;
    this.insightC7 = false;
    this.insightC8 = false;
    this.insightTR = false;
    this.insightSA = false;


    this.insightSAR = false;
    this.insightSA10 = false;
    this.insightSA20 = false;
    this.insightSA30 = false;
    this.insightSA40 = false;
    this.insightSA50 = false;
    this.insightSA60 = false;


    this.insightC0N = false;
    this.insightC1N = false;
    this.insightC2N = false;
    this.insightC3N = false;
    this.insightC4N = false;
    this.insightC5N = false;
    this.insightC6N = false;
    this.insightC7N = false;
    this.insightC8N = false;
    this.insightTRN = false;
    this.insightSAN = false;


    this.insightSANR = false;
    this.insightSAN10 = false;
    this.insightSAN20 = false;
    this.insightSAN30 = false;
    this.insightSAN40 = false;
    this.insightSAN50 = false;
    this.insightSAN60 = false;
    if (view.value('msteam') == 1) {
      this.msstatus = true;
      this.mschannelid = view.value('msid');
    } else {
      this.msstatus = false;
    }
    // this.aiidset='';
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.partnerclickflg = false;
    this.inddeep = false;

    this.notesviewflag = false;
    this.reportviewflag = false;
    this.insightsearch = false;

    this.selCat = 0;
    try {
      this.filtervalue = fl;
      this.view.get('producttype/all/sorted').subscribe(res => {
        const result = [];
        const map = new Map();
        for (const item of res) {
          if (!map.has(item.title.toLowerCase().trim())) {
            map.set(item.title.toLowerCase().trim(), true);    // set any value to Map
            result.push({
              title: item.title,

            });
          }
        }

        this.productTypes = result;
      });
      if (view.value('mode') == 'Research Mode') {
        this.progress = 0;
        this.config.setLoaderPercentage(this.progress);
      }
      if (view.value('mode') == 'Turbo Mode' && !flag && this.turboMode == 0) {
        if (this.turboRerunFl != 0) {
          this.chatLoaderText = 'Researching and Curating ' + this.catName('' + this.turboRerunFl, view) + ' data for your project';

        } else {
          this.chatLoaderText = 'Understanding your project';

        }
        // Config.loading = false;
        Config.loading = true;
        this.isTyping = true;
        Config.transparent = true;
        const lastMessage = this.messages?.[this.messages.length - 1];
        this.suggestedQuestions = [];

        if (lastMessage && (lastMessage.suggestedQuestion1 || lastMessage.suggestedQuestion2 || lastMessage.suggestedQuestion3)) {
          this.showSuggestions = true;
          // Add the suggested questions to the array
          if (lastMessage.suggestedQuestion1) {
            this.suggestedQuestions.push(lastMessage.suggestedQuestion1);
          }
          if (lastMessage.suggestedQuestion2) {
            this.suggestedQuestions.push(lastMessage.suggestedQuestion2);
          }
          if (lastMessage.suggestedQuestion3) {
            this.suggestedQuestions.push(lastMessage.suggestedQuestion3);
          }
        }
        setTimeout(() => {
          // Desktop View Scrollbar
          this.scrollTo({
            parent: this.aiChatScrollViewer,
            child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
          });
          // Mobile View Scrollbar
          this.scrollTo({
            parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
            child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
          });

          // AI chat window Scrollbar
          this.scrollTo({
            align: "bottom",
            parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
            child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
          });
        }, 0);
      } else {
        Config.loading = true;


      }

      // if (pdf) {
      //   Config.setLoaderMessage('InsIQ is preparing your executive report, this can take a few seconds');
      // }
      if (!flag && view.value('mode') == 'Research Mode') {
        // Config.setLoaderImage('./assets/images/insiq_ai.png');
        Config.setLoaderImage('./assets/images/insiq_ai_insights.gif');
        Config.setLoaderMessage('InsIQ is finding the perfect insights for you, this can take a few seconds');

      }
      //      this.view.get('apilanguage/all').subscribe(res => {
      //        this.apiLanguage = res;
      //        for (var i = 0; i < this.apiLanguage.length; i++) {
      //          if (this.apiLanguage[i].defaultFlag === 1) {
      //
      //            if (sessionStorage.getItem('apiLanguage') == '') {
      //              sessionStorage.setItem('apiLanguage', this.apiLanguage[i].languageCode);
      //            }
      //          }
      //        }
      //
      //        this.selectedLanguage = sessionStorage.getItem('apiLanguage');
      //
      //      });

      this.hasRecord = 'none';
      this.aitype = '1'; // for insight
      this.project = p === null ? view.form.value : p;
      this.setAIView(true);
      this.displayReq = false;
      this.showWish = false;
      this.dashFav = false;
      this.modeval = null;
      this.smartTitle = '';
      this.getLiked();
      this.getComments();
      this.getReports();
      let AI_URL: string;
      // console.log(location.host);
      if (location.host.includes(Config.MAINPROD_URL)) {
        AI_URL = this.getParams('initialaiinsights', view, p);
      } else {
        AI_URL = this.getParams('testinitialaiinsights', view, p);
      }

      let TREND_URL: string;
      // console.log(location.host);
      if (location.host.includes(Config.MAINPROD_URL)) {
        TREND_URL = this.getParams('trends', view, p);
      } else {
        TREND_URL = this.getParams('trends', view, p);
      }

      this.view.get(`aiinsights/chatprompt`).subscribe(res => {
        //   console.log(res);
        this.chatPrompt = res;
      });
      this.loadAiLiked(view, AI_URL, pdf, TREND_URL, flag, fl);
      //        this.getLiked();
      //      this.getComments();
      //      this.getReports();
    } catch (error) {
      console.error(error);
    }
  }
  //  pdfreport(fl, view: View, p: Project = null) {
  //   this.smartDev(fl, view, p, true);
  //  }
  openpdf(fl: string, rt: any) {

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    // this.smartDevSearch('1');
    if (this.ssp.pro.isFormValid(false, this.view)) {
      this.filtervalue = fl;
      this.reporttype = rt;
      if (
        this.project.productName !== this.view.value('productName') ||
        this.project.productDescription !== this.view.value('productDescription') ||
        this.project.productTypeId !== this.view.value('productTypeId') ||
        this.project.brandName !== this.view.value('brandName') || this.project.problemSolve !== this.view.value('problemSolve') || this.project.productEnviornment !== this.view.value('productEnviornment')
      ) {

        this.smartSave(fl, true);
      } else {
        this.project.productName = this.view.value('productName');
        this.project.productDescription = this.view.value('productDescription');
        this.project.brandName = this.view.value('brandName');
        this.project.apiLanguage = this.view.value('apiLanguage');
        this.project.problemSolve = this.view.value('problemSolve');
        this.project.productEnviornment = this.view.value('productEnviornment');

        this.project.productTypeId = this.view.value('productTypeId');
        this.smartDev(fl, this.view, this.project, true);
      }
    }
  }
  reportGenerator(reporttitle, projectId) {
    if (this.featureChecker(17)) {
      this.reportGenerator1(reporttitle, projectId);
    } else {
      this.featurealert('Report');

    }
  }

  reportGenerator1(reporttitle, projectId) {
    if (reporttitle == '') {
      reporttitle = (<HTMLInputElement>document.getElementById('reporttitle')).value;
    }

    reporttitle = reporttitle.replace('%', 'g1g');
    reporttitle = reporttitle.replace('/', 'g2g');
    reporttitle = reporttitle.replace('\'\\\'', 'g3g');

    // console.log(reporttitle);
    if (projectId == '') {
      projectId = this.project.id;
    }
    //console.log(reporttitle.length);
    if (reporttitle.length !== 0) {
      let aitypeVal;
      if (!this.desiagnApiCall) {

        aitypeVal = '1';
      } else {
        aitypeVal = '2';
      }
      this.progress = 0;
      this.config.setLoaderPercentage(this.progress);
      Config.loading = true;
      Config.setLoaderMessage('InsIQ is generating your Executive Report.');
      const url = `${Config.apiUrl}/aireport/generateReport/${projectId}/${encodeURIComponent(reporttitle)}/${aitypeVal}`;
      const requestOption = JsonRequest.getHeader('');
      // requestOption.responseType='blob';
      this.http.get(url, {
        headers: requestOption.headers,
        params: requestOption.params,
        responseType: 'blob'
      }).subscribe((data: Blob) => {
        const file = new Blob([data], { type: 'application/octet-stream' });
        const fileURL = URL.createObjectURL(file);
        // Config.loading = false;
        // if you want to open PDF in new tab
        // window.open(fileURL);
        const a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'InsIQExecutiveReport_' + projectId + '.docx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        Config.loading = false;
      }, (error) => {
        console.log('Something went Wrong', error);
        Config.loading = false;
      });
    } else {
      this.alertService.error(
        'Report Title is Mandatory to generate report',
        true, 20 * 1000
      );
    }
  }
  //  onLangChange(event) {
  //
  //    sessionStorage.setItem('apiLanguage', event.value);
  //    this.selectedLanguage = sessionStorage.getItem('apiLanguage');
  //  }
  getConfigLoad() {
    // console.log('Config.loading', Config.loading)
    if (Config.loading) {
      return true;
    } else { return false; }
  }
  testclick() {
    this.sentimentclick(this.sentimentResp[0]);
    this.flSelected = 0;
    this.flValSelect = 0
  }
  designsmartSave(fl: string, pdf = false) {

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    try {
      if (this.ssp.pro.isFormValid(false, this.view)) {
        this.projView.set('id', this.project.id);
        this.projView.set('productName', this.view.value('productName'));
        this.projView.set('brandName', this.view.value('brandName'));
        // this.projView.set('apiLanguage', this.view.value('apiLanguage'));
        this.projView.set('productDescription', this.view.value('productDescription'));
        this.projView.set('productTypeId', this.view.value('productTypeId'));
        this.project.productName = this.view.value('productName');
        this.project.brandName = this.view.value('brandName');
        this.project.apiLanguage = this.view.value('apiLanguage');
        this.project.problemSolve = this.view.value('problemSolve');
        this.project.productEnviornment = this.view.value('productEnviornment');

        this.project.productDescription = this.view.value('productDescription');
        this.project.productTypeId = this.view.value('productTypeId');
        this.projView.state = 'edit';
        const v = this.view;
        const pv = this.projView;
        const pro = this.project;
        const p = this.ssp.pro;
        const ai = this;

        //   this.ssp.project.productName = this.view.value('productName');
        this.projView.saveSetAndAddCallback(function (data) {
          // this.projView.set('productTypeId', v.value('productTypeId'));
          // console.log(v.value('productTypeId'));
          // console.log(p.value('productTypeId'));
          // this.projView.set('productTypeId', v.value('productTypeId'));
          p.view.set('productName', v.value('productName'));
          p.view.set('productDescription', v.value('productDescription'));
          p.view.set('brandName', v.value('brandName'));
          p.view.set('problemSolve', v.value('problemSolve'));
          p.view.set('productEnviornment', v.value('productEnviornment'));

          p.view.set('apiLanguage', v.value('apiLanguage'));
          p.getList();
          //  console.log(data);
          pro.productTypeId = data.productTypeId;
          v.set('productTypeId', data.productTypeId);
          p.view.set('productTypeId', data.productTypeId);
          // console.log(pro,p.view, v);
          ai.designsmartDev(fl, v, pro, pdf);
        }, ['productTypeId'], 'project/updatebyai', '', null, false);

        // this.view.saveSet(['productName','productDescription','productTypeId']);
      }
    } catch (error) {
      console.error(error);
    }
  }
  smartSave(fl: string, pdf = false) {
    function formatTextWithSpaces(text) {
      if (text && typeof text === 'string') {
        return text.replace(/,\s*/g, ', ');
      }
      return text;
    }
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    try {
      // console.log('HEEEEE', this.view.value('productName'));
      if (this.ssp.pro.isFormValid(false, this.view)) {
        this.projView.set('id', this.project.id);
        this.projView.set('productName', this.view.value('productName').trim() ?? undefined);
        this.projView.set('brandName', this.view.value('brandName') ?? undefined);
        this.projView.set('apiLanguage', this.view.value('apiLanguage') ?? undefined);
        this.projView.set('productTypeId', this.view.value('productTypeId') ?? undefined);
        this.projView.set('customer', this.view.value('customer') ?? undefined);
        this.projView.set('targetRegion', this.view.value('targetRegion') ?? undefined);
        this.projView.set('competitors', formatTextWithSpaces(this.view.value('competitors')) ?? undefined);
        this.projView.set('competitorspdt', formatTextWithSpaces(this.view.value('competitorspdt')) ?? undefined);
        this.projView.set('companyname', this.view.value('companyname') ?? undefined);
        this.projView.set('projectOwner', this.view.value('projectOwner') ?? undefined);

        this.projView.set('keyword31', formatTextWithSpaces(this.view.value('keyword31')) ?? undefined);
        this.projView.set('keyword6', formatTextWithSpaces(this.view.value('keyword6')) ?? undefined);

        this.projView.set('keyword10', formatTextWithSpaces(this.view.value('keyword10')) ?? undefined);
        this.projView.set('keyword2', formatTextWithSpaces(this.view.value('keyword2')) ?? undefined);
        this.projView.set('keyword32', formatTextWithSpaces(this.view.value('keyword32')) ?? undefined);
        this.projView.set('keyword8', formatTextWithSpaces(this.view.value('keyword8')) ?? undefined);
        this.projView.set('keyword11', formatTextWithSpaces(this.view.value('keyword11')) ?? undefined);
        this.projView.set('keyword7', formatTextWithSpaces(this.view.value('keyword7')) ?? undefined);
        this.projView.set('keyword5', formatTextWithSpaces(this.view.value('keyword5')) ?? undefined);
        this.projView.set('keyword4', formatTextWithSpaces(this.view.value('keyword4')) ?? undefined);
        this.projView.set('keyword9', formatTextWithSpaces(this.view.value('keyword9')) ?? undefined);






        this.project.productName = this.view.value('productName').trim();
        this.project.brandName = this.view.value('brandName');
        this.project.problemSolve = this.view.value('problemSolve');
        this.project.productEnviornment = this.view.value('productEnviornment');
        this.project.apiLanguage = this.view.value('apiLanguage');
        this.project.productTypeId = this.view.value('productTypeId');
        this.project.customer = this.view.value('customer');

        this.project.targetRegion = this.view.value('targetRegion');
        this.project.competitors = formatTextWithSpaces(this.view.value('competitors'));
        this.project.competitorspdt = formatTextWithSpaces(this.view.value('competitorspdt'));
        this.project.companyname = this.view.value('companyname');
        this.project.projectOwner = this.view.value('projectOwner');

        this.project.keyword31 = formatTextWithSpaces(this.view.value('keyword31'));
        this.project.keyword6 = formatTextWithSpaces(this.view.value('keyword6'));
        this.project.keyword10 = formatTextWithSpaces(this.view.value('keyword10'));
        this.project.keyword2 = formatTextWithSpaces(this.view.value('keyword2'));
        this.project.keyword8 = formatTextWithSpaces(this.view.value('keyword8'));
        this.project.keyword11 = formatTextWithSpaces(this.view.value('keyword11'));
        this.project.keyword7 = formatTextWithSpaces(this.view.value('keyword7'));
        this.project.keyword5 = formatTextWithSpaces(this.view.value('keyword5'));
        this.project.keyword4 = formatTextWithSpaces(this.view.value('keyword4'));
        this.project.keyword9 = formatTextWithSpaces(this.view.value('keyword9'));
        this.project.keyword32 = formatTextWithSpaces(this.view.value('keyword32'));


        //  console.log('jjjjjjjjjjjjjjjj', this.flSelected)

        if (this.flSelected !== 0 && this.flSelected !== 199 && this.flSelected !== 101) {
          //console.log('hereeeee')
          if (this.flSelected == 31) {
            if (this.view.value('keyword31') === '' || this.view.value('keyword31') === null || this.view.value('keyword31') === 'null') {

              this.projView.set('keyword31', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword31 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword31', formatTextWithSpaces(this.view.value('keyword31')) ?? undefined);
              this.project.keyword31 = formatTextWithSpaces(this.view.value('keyword31'));

            }


          } else if (this.flSelected == 6) {

            if (this.view.value('keyword6') === '' || this.view.value('keyword6') === null || this.view.value('keyword6') === 'null') {

              this.projView.set('keyword6', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword6 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword6', formatTextWithSpaces(this.view.value('keyword6')) ?? undefined);
              this.project.keyword6 = formatTextWithSpaces(this.view.value('keyword6'));

            }


          } else if (this.flSelected == 10) {

            if (this.view.value('keyword10') === '' || this.view.value('keyword10') === null || this.view.value('keyword10') === 'null') {

              this.projView.set('keyword10', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword10 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword10', formatTextWithSpaces(this.view.value('keyword10')) ?? undefined);
              this.project.keyword10 = formatTextWithSpaces(this.view.value('keyword10'));

            }

          } else if (this.flSelected == 2) {

            if (this.view.value('keyword2') === '' || this.view.value('keyword2') === null || this.view.value('keyword2') === 'null') {

              this.projView.set('keyword2', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword2 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword2', formatTextWithSpaces(this.view.value('keyword2')) ?? undefined);
              this.project.keyword2 = formatTextWithSpaces(this.view.value('keyword2'));

            }

          } else if (this.flSelected == 32) {

            if (this.view.value('keyword32') === '' || this.view.value('keyword32') === null || this.view.value('keyword32') === 'null') {

              this.projView.set('keyword32', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword32 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword32', formatTextWithSpaces(this.view.value('keyword32')) ?? undefined);
              this.project.keyword32 = formatTextWithSpaces(this.view.value('keyword32'));

            }

          } else if (this.flSelected == 8) {

            if (this.view.value('keyword8') === '' || this.view.value('keyword8') === null || this.view.value('keyword8') === 'null') {

              this.projView.set('keyword8', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword8 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword8', formatTextWithSpaces(this.view.value('keyword8')) ?? undefined);
              this.project.keyword8 = formatTextWithSpaces(this.view.value('keyword8'));

            }

          } else if (this.flSelected == 11) {

            if (this.view.value('keyword11') === '' || this.view.value('keyword11') === null || this.view.value('keyword11') === 'null') {

              this.projView.set('keyword11', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword11 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword11', formatTextWithSpaces(this.view.value('keyword11')) ?? undefined);
              this.project.keyword11 = formatTextWithSpaces(this.view.value('keyword11'));

            }

          } else if (this.flSelected == 7) {

            if (this.view.value('keyword7') === '' || this.view.value('keyword7') === null || this.view.value('keyword7') === 'null') {

              this.projView.set('keyword7', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword7 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword7', formatTextWithSpaces(this.view.value('keyword7')) ?? undefined);
              this.project.keyword7 = formatTextWithSpaces(this.view.value('keyword7'));

            }

          } else if (this.flSelected == 5) {

            if (this.view.value('keyword5') === '' || this.view.value('keyword5') === null || this.view.value('keyword5') === 'null') {

              this.projView.set('keyword5', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword5 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword5', formatTextWithSpaces(this.view.value('keyword5')) ?? undefined);
              this.project.keyword5 = formatTextWithSpaces(this.view.value('keyword5'));

            }

          } else if (this.flSelected == 4) {

            if (this.view.value('keyword4') === '' || this.view.value('keyword4') === null || this.view.value('keyword4') === 'null') {

              this.projView.set('keyword4', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword4 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword4', formatTextWithSpaces(this.view.value('keyword4')) ?? undefined);
              this.project.keyword4 = formatTextWithSpaces(this.view.value('keyword4'));

            }

          } else if (this.flSelected == 9) {

            if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

              this.projView.set('keyword9', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword9 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword9', formatTextWithSpaces(this.view.value('keyword9')) ?? undefined);
              this.project.keyword9 = formatTextWithSpaces(this.view.value('keyword9'));

            }

          }
          this.projView.set('productDescription', formatTextWithSpaces(this.project.productDescription).trim() ?? undefined);
        }
        else {
          this.projView.set('productDescription', formatTextWithSpaces(this.view.value('productDescription').trim()) ?? undefined);
          this.project.productDescription = formatTextWithSpaces(this.view.value('productDescription').trim());

        }


        this.projView.state = 'edit';
        const v = this.view;
        const pv = this.projView;
        const pro = this.project;
        const p = this.ssp.pro;
        const ai = this;



        //   this.ssp.project.productName = this.view.value('productName');
        this.projView.saveSetAndAddCallback(function (data) {
          // this.projView.set('productTypeId', v.value('productTypeId'));
          // console.log(v.value('productTypeId'));
          // console.log(p.value('productTypeId'));
          // this.projView.set('productTypeId', v.value('productTypeId'));
          p.view.set('productName', v.value('productName').trim());
          p.view.set('brandName', v.value('brandName'));
          p.view.set('problemSolve', v.value('problemSolve'));

          p.view.set('customer', v.value('customer'));
          p.view.set('productEnviornment', v.value('productEnviornment'));

          p.view.set('apiLanguage', v.value('apiLanguage'));

          p.view.set('targetRegion', v.value('targetRegion'));
          p.view.set('competitors', formatTextWithSpaces(v.value('competitors')));

          p.view.set('competitorspdt', formatTextWithSpaces(v.value('competitorspdt')));
          p.view.set('companyname', v.value('companyname'));

          p.view.set('projectOwner', v.value('projectOwner'));

          p.view.set('keyword31', formatTextWithSpaces(v.value('keyword31')));

          p.view.set('keyword6', formatTextWithSpaces(v.value('keyword6')));
          p.view.set('keyword10', formatTextWithSpaces(v.value('keyword10')));
          p.view.set('keyword2', formatTextWithSpaces(v.value('keyword2')));
          p.view.set('keyword32', formatTextWithSpaces(v.value('keyword32')));
          p.view.set('keyword8', formatTextWithSpaces(v.value('keyword8')));
          p.view.set('keyword11', formatTextWithSpaces(v.value('keyword11')));
          p.view.set('keyword7', formatTextWithSpaces(v.value('keyword7')));
          p.view.set('keyword5', formatTextWithSpaces(v.value('keyword5')));
          p.view.set('keyword4', formatTextWithSpaces(v.value('keyword4')));
          p.view.set('keyword9', formatTextWithSpaces(v.value('keyword9')));


          // console.log(this.flSelected)

          if (fl !== '0' && fl !== '199' && fl !== '101') {
            //console.log('hereeeee')
            if (fl == '31') {
              if (v.value('keyword31') === '' || v.value('keyword31') === null || v.value('keyword31') === 'null') {

                p.view.set('keyword31', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword31', formatTextWithSpaces(v.value('keyword31')));

              }


            } else if (fl == '6') {

              if (v.value('keyword6') === '' || v.value('keyword6') === null || v.value('keyword6') === 'null') {

                p.view.set('keyword6', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword6', formatTextWithSpaces(v.value('keyword6')));

              }

            } else if (fl == '10') {

              if (v.value('keyword10') === '' || v.value('keyword10') === null || v.value('keyword10') === 'null') {

                p.view.set('keyword10', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword10', formatTextWithSpaces(v.value('keyword10')));

              }

            } else if (fl == '2') {

              if (v.value('keyword2') === '' || v.value('keyword2') === null || v.value('keyword2') === 'null') {

                p.view.set('keyword2', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword2', formatTextWithSpaces(v.value('keyword2')));

              }

            } else if (fl == '32') {

              if (v.value('keyword32') === '' || v.value('keyword32') === null || v.value('keyword32') === 'null') {

                p.view.set('keyword32', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword32', formatTextWithSpaces(v.value('keyword32')));

              }

            } else if (fl == '8') {

              if (v.value('keyword8') === '' || v.value('keyword8') === null || v.value('keyword8') === 'null') {

                p.view.set('keyword8', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword8', formatTextWithSpaces(v.value('keyword8')));

              }

            } else if (fl == '11') {

              if (v.value('keyword11') === '' || v.value('keyword11') === null || v.value('keyword11') === 'null') {

                p.view.set('keyword11', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword11', formatTextWithSpaces(v.value('keyword11')));

              }

            } else if (fl == '7') {

              if (v.value('keyword7') === '' || v.value('keyword7') === null || v.value('keyword7') === 'null') {

                p.view.set('keyword7', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword7', formatTextWithSpaces(v.value('keyword7')));

              }

            } else if (fl == '5') {

              if (v.value('keyword5') === '' || v.value('keyword5') === null || v.value('keyword5') === 'null') {

                p.view.set('keyword5', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword5', formatTextWithSpaces(v.value('keyword5')));

              }
            } else if (fl == '4') {

              if (v.value('keyword4') === '' || v.value('keyword4') === null || v.value('keyword4') === 'null') {

                p.view.set('keyword4', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword4', formatTextWithSpaces(v.value('keyword4')));

              }

            } else if (fl == '9') {

              if (v.value('keyword9') === '' || v.value('keyword9') === null || v.value('keyword9') === 'null') {

                p.view.set('keyword9', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword9', formatTextWithSpaces(v.value('keyword9')));

              }

            }
            p.view.set('productDescription', formatTextWithSpaces(v.value('productDescription').trim()));
          }
          else {
            p.view.set('productDescription', formatTextWithSpaces(v.value('productDescription').trim()));


          }


          p.getList();
          //  console.log(data);
          pro.productTypeId = data.productTypeId;
          v.set('productTypeId', data.productTypeId);
          p.view.set('productTypeId', data.productTypeId);
          // console.log(pro,p.view, v);
          if (fl === '0' && this.view.value('mode') == 'Research Mode') {
            ai.sentimentapi(v, pro);
          } else if (fl === '0009') {
            //ai.sentimentapi(v, pro);
            Config.loading = false;

          } else {
            ai.smartDev(fl, v, pro, pdf);
          }
        }, ['productTypeId'], 'project/updatebyai', '', null, false);

        // this.view.saveSet(['productName','productDescription','productTypeId']);
      }
    } catch (error) {
      console.error(error);
    }
  }
  smartSaveProj(fl: string, pdf = false) {
    function formatTextWithSpaces(text) {
      if (text && typeof text === 'string') {
        return text.replace(/,\s*/g, ', ');
      }
      return text;
    }
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    try {
      // console.log('HEEEEE', this.view.value('productName'));
      if (this.ssp.pro.isFormValid(false, this.view)) {
        this.projView.set('id', this.project.id);
        this.projView.set('productName', this.view.value('productName').trim() ?? undefined);
        this.projView.set('brandName', this.view.value('brandName') ?? undefined);
        this.projView.set('apiLanguage', this.view.value('apiLanguage') ?? undefined);
        this.projView.set('productTypeId', this.view.value('productTypeId') ?? undefined);
        this.projView.set('customer', this.view.value('customer') ?? undefined);
        this.projView.set('targetRegion', this.view.value('targetRegion') ?? undefined);
        this.projView.set('competitors', formatTextWithSpaces(this.view.value('competitors')) ?? undefined);
        this.projView.set('competitorspdt', formatTextWithSpaces(this.view.value('competitorspdt')) ?? undefined);
        this.projView.set('companyname', this.view.value('companyname') ?? undefined);
        this.projView.set('projectOwner', this.view.value('projectOwner') ?? undefined);

        this.projView.set('keyword31', formatTextWithSpaces(this.view.value('keyword31')) ?? undefined);
        this.projView.set('keyword6', formatTextWithSpaces(this.view.value('keyword6')) ?? undefined);

        this.projView.set('keyword10', formatTextWithSpaces(this.view.value('keyword10')) ?? undefined);
        this.projView.set('keyword2', formatTextWithSpaces(this.view.value('keyword2')) ?? undefined);
        this.projView.set('keyword32', formatTextWithSpaces(this.view.value('keyword32')) ?? undefined);
        this.projView.set('keyword8', formatTextWithSpaces(this.view.value('keyword8')) ?? undefined);
        this.projView.set('keyword11', formatTextWithSpaces(this.view.value('keyword11')) ?? undefined);
        this.projView.set('keyword7', formatTextWithSpaces(this.view.value('keyword7')) ?? undefined);
        this.projView.set('keyword5', formatTextWithSpaces(this.view.value('keyword5')) ?? undefined);
        this.projView.set('keyword4', formatTextWithSpaces(this.view.value('keyword4')) ?? undefined);
        this.projView.set('keyword9', formatTextWithSpaces(this.view.value('keyword9')) ?? undefined);






        this.project.productName = this.view.value('productName').trim();
        this.project.brandName = this.view.value('brandName');
        this.project.problemSolve = this.view.value('problemSolve');
        this.project.productEnviornment = this.view.value('productEnviornment');
        this.project.apiLanguage = this.view.value('apiLanguage');
        this.project.productTypeId = this.view.value('productTypeId');
        this.project.customer = this.view.value('customer');

        this.project.targetRegion = this.view.value('targetRegion');
        this.project.competitors = formatTextWithSpaces(this.view.value('competitors'));
        this.project.competitorspdt = formatTextWithSpaces(this.view.value('competitorspdt'));
        this.project.companyname = this.view.value('companyname');
        this.project.projectOwner = this.view.value('projectOwner');

        this.project.keyword31 = formatTextWithSpaces(this.view.value('keyword31'));
        this.project.keyword6 = formatTextWithSpaces(this.view.value('keyword6'));
        this.project.keyword10 = formatTextWithSpaces(this.view.value('keyword10'));
        this.project.keyword2 = formatTextWithSpaces(this.view.value('keyword2'));
        this.project.keyword8 = formatTextWithSpaces(this.view.value('keyword8'));
        this.project.keyword11 = formatTextWithSpaces(this.view.value('keyword11'));
        this.project.keyword7 = formatTextWithSpaces(this.view.value('keyword7'));
        this.project.keyword5 = formatTextWithSpaces(this.view.value('keyword5'));
        this.project.keyword4 = formatTextWithSpaces(this.view.value('keyword4'));
        this.project.keyword9 = formatTextWithSpaces(this.view.value('keyword9'));
        this.project.keyword32 = formatTextWithSpaces(this.view.value('keyword32'));




        if (this.flSelected !== 0 && this.flSelected !== 199 && this.flSelected !== 101) {
          //console.log('hereeeee')
          if (this.flSelected == 31) {
            if (this.view.value('keyword31') === '' || this.view.value('keyword31') === null || this.view.value('keyword31') === 'null') {

              this.projView.set('keyword31', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword31 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword31', formatTextWithSpaces(this.view.value('keyword31')) ?? undefined);
              this.project.keyword31 = formatTextWithSpaces(this.view.value('keyword31'));

            }


          } else if (this.flSelected == 6) {

            if (this.view.value('keyword6') === '' || this.view.value('keyword6') === null || this.view.value('keyword6') === 'null') {

              this.projView.set('keyword6', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword6 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword6', formatTextWithSpaces(this.view.value('keyword6')) ?? undefined);
              this.project.keyword6 = formatTextWithSpaces(this.view.value('keyword6'));

            }


          } else if (this.flSelected == 10) {

            if (this.view.value('keyword10') === '' || this.view.value('keyword10') === null || this.view.value('keyword10') === 'null') {

              this.projView.set('keyword10', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword10 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword10', formatTextWithSpaces(this.view.value('keyword10')) ?? undefined);
              this.project.keyword10 = formatTextWithSpaces(this.view.value('keyword10'));

            }

          } else if (this.flSelected == 2) {

            if (this.view.value('keyword2') === '' || this.view.value('keyword2') === null || this.view.value('keyword2') === 'null') {

              this.projView.set('keyword2', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword2 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword2', formatTextWithSpaces(this.view.value('keyword2')) ?? undefined);
              this.project.keyword2 = formatTextWithSpaces(this.view.value('keyword2'));

            }

          } else if (this.flSelected == 32) {

            if (this.view.value('keyword32') === '' || this.view.value('keyword32') === null || this.view.value('keyword32') === 'null') {

              this.projView.set('keyword32', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword32 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword32', formatTextWithSpaces(this.view.value('keyword32')) ?? undefined);
              this.project.keyword32 = formatTextWithSpaces(this.view.value('keyword32'));

            }

          } else if (this.flSelected == 8) {

            if (this.view.value('keyword8') === '' || this.view.value('keyword8') === null || this.view.value('keyword8') === 'null') {

              this.projView.set('keyword8', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword8 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword8', formatTextWithSpaces(this.view.value('keyword8')) ?? undefined);
              this.project.keyword8 = formatTextWithSpaces(this.view.value('keyword8'));

            }

          } else if (this.flSelected == 11) {

            if (this.view.value('keyword11') === '' || this.view.value('keyword11') === null || this.view.value('keyword11') === 'null') {

              this.projView.set('keyword11', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword11 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword11', formatTextWithSpaces(this.view.value('keyword11')) ?? undefined);
              this.project.keyword11 = formatTextWithSpaces(this.view.value('keyword11'));

            }

          } else if (this.flSelected == 7) {

            if (this.view.value('keyword7') === '' || this.view.value('keyword7') === null || this.view.value('keyword7') === 'null') {

              this.projView.set('keyword7', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword7 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword7', formatTextWithSpaces(this.view.value('keyword7')) ?? undefined);
              this.project.keyword7 = formatTextWithSpaces(this.view.value('keyword7'));

            }

          } else if (this.flSelected == 5) {

            if (this.view.value('keyword5') === '' || this.view.value('keyword5') === null || this.view.value('keyword5') === 'null') {

              this.projView.set('keyword5', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword5 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword5', formatTextWithSpaces(this.view.value('keyword5')) ?? undefined);
              this.project.keyword5 = formatTextWithSpaces(this.view.value('keyword5'));

            }

          } else if (this.flSelected == 4) {

            if (this.view.value('keyword4') === '' || this.view.value('keyword4') === null || this.view.value('keyword4') === 'null') {

              this.projView.set('keyword4', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword4 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword4', formatTextWithSpaces(this.view.value('keyword4')) ?? undefined);
              this.project.keyword4 = formatTextWithSpaces(this.view.value('keyword4'));

            }

          } else if (this.flSelected == 9) {

            if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

              this.projView.set('keyword9', formatTextWithSpaces(this.view.value('productDescription')) ?? undefined);
              this.project.keyword9 = formatTextWithSpaces(this.view.value('productDescription'));

            } else {
              this.projView.set('keyword9', formatTextWithSpaces(this.view.value('keyword9')) ?? undefined);
              this.project.keyword9 = formatTextWithSpaces(this.view.value('keyword9'));

            }

          }
          this.projView.set('productDescription', formatTextWithSpaces(this.project.productDescription).trim() ?? undefined);
        }
        else {
          this.projView.set('productDescription', formatTextWithSpaces(this.view.value('productDescription')).trim() ?? undefined);
          this.project.productDescription = formatTextWithSpaces(this.view.value('productDescription').trim());

        }


        this.projView.state = 'edit';
        const v = this.view;
        const pv = this.projView;
        const pro = this.project;
        const p = this.ssp.pro;
        const ai = this;



        //   this.ssp.project.productName = this.view.value('productName');
        this.projView.saveSetAndAddCallback(function (data) {
          // this.projView.set('productTypeId', v.value('productTypeId'));
          // console.log(v.value('productTypeId'));
          // console.log(p.value('productTypeId'));
          // this.projView.set('productTypeId', v.value('productTypeId'));
          p.view.set('productName', v.value('productName').trim());
          p.view.set('brandName', v.value('brandName'));
          p.view.set('problemSolve', v.value('problemSolve'));

          p.view.set('customer', v.value('customer'));
          p.view.set('productEnviornment', v.value('productEnviornment'));

          p.view.set('apiLanguage', v.value('apiLanguage'));

          p.view.set('targetRegion', v.value('targetRegion'));
          p.view.set('competitors', formatTextWithSpaces(v.value('competitors')));

          p.view.set('competitorspdt', formatTextWithSpaces(v.value('competitorspdt')));
          p.view.set('companyname', v.value('companyname'));

          p.view.set('projectOwner', v.value('projectOwner'));

          p.view.set('keyword31', formatTextWithSpaces(v.value('keyword31')));

          p.view.set('keyword6', formatTextWithSpaces(v.value('keyword6')));
          p.view.set('keyword10', formatTextWithSpaces(v.value('keyword10')));
          p.view.set('keyword2', formatTextWithSpaces(v.value('keyword2')));
          p.view.set('keyword32', formatTextWithSpaces(v.value('keyword32')));
          p.view.set('keyword8', formatTextWithSpaces(v.value('keyword8')));
          p.view.set('keyword11', formatTextWithSpaces(v.value('keyword11')));
          p.view.set('keyword7', formatTextWithSpaces(v.value('keyword7')));
          p.view.set('keyword5', formatTextWithSpaces(v.value('keyword5')));
          p.view.set('keyword4', formatTextWithSpaces(v.value('keyword4')));
          p.view.set('keyword9', formatTextWithSpaces(v.value('keyword9')));


          // console.log(this.flSelected)

          if (fl !== '0' && fl !== '199' && fl !== '101') {
            //console.log('hereeeee')
            if (fl == '31') {
              if (v.value('keyword31') === '' || v.value('keyword31') === null || v.value('keyword31') === 'null') {

                p.view.set('keyword31', formatTextWithSpaces(v.value('productDescription').trim()));

              } else {
                p.view.set('keyword31', formatTextWithSpaces(v.value('keyword31')));

              }


            } else if (fl == '6') {

              if (v.value('keyword6') === '' || v.value('keyword6') === null || v.value('keyword6') === 'null') {

                p.view.set('keyword6', formatTextWithSpaces(v.value('productDescription').trim()));

              } else {
                p.view.set('keyword6', formatTextWithSpaces(v.value('keyword6')));

              }

            } else if (fl == '10') {

              if (v.value('keyword10') === '' || v.value('keyword10') === null || v.value('keyword10') === 'null') {

                p.view.set('keyword10', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword10', formatTextWithSpaces(v.value('keyword10')));

              }

            } else if (fl == '2') {

              if (v.value('keyword2') === '' || v.value('keyword2') === null || v.value('keyword2') === 'null') {

                p.view.set('keyword2', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword2', formatTextWithSpaces(v.value('keyword2')));

              }

            } else if (fl == '32') {

              if (v.value('keyword32') === '' || v.value('keyword32') === null || v.value('keyword32') === 'null') {

                p.view.set('keyword32', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword32', formatTextWithSpaces(v.value('keyword32')));

              }

            } else if (fl == '8') {

              if (v.value('keyword8') === '' || v.value('keyword8') === null || v.value('keyword8') === 'null') {

                p.view.set('keyword8', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword8', formatTextWithSpaces(v.value('keyword8')));

              }

            } else if (fl == '11') {

              if (v.value('keyword11') === '' || v.value('keyword11') === null || v.value('keyword11') === 'null') {

                p.view.set('keyword11', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword11', formatTextWithSpaces(v.value('keyword11')));

              }

            } else if (fl == '7') {

              if (v.value('keyword7') === '' || v.value('keyword7') === null || v.value('keyword7') === 'null') {

                p.view.set('keyword7', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword7', formatTextWithSpaces(v.value('keyword7')));

              }

            } else if (fl == '5') {

              if (v.value('keyword5') === '' || v.value('keyword5') === null || v.value('keyword5') === 'null') {

                p.view.set('keyword5', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword5', formatTextWithSpaces(v.value('keyword5')));

              }
            } else if (fl == '4') {

              if (v.value('keyword4') === '' || v.value('keyword4') === null || v.value('keyword4') === 'null') {

                p.view.set('keyword4', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword4', formatTextWithSpaces(v.value('keyword4')));

              }

            } else if (fl == '9') {

              if (v.value('keyword9') === '' || v.value('keyword9') === null || v.value('keyword9') === 'null') {

                p.view.set('keyword9', formatTextWithSpaces(v.value('productDescription')));

              } else {
                p.view.set('keyword9', formatTextWithSpaces(v.value('keyword9')));

              }

            }
            p.view.set('productDescription', formatTextWithSpaces(v.value('productDescription')));
          } else {
            p.view.set('productDescription', formatTextWithSpaces(v.value('productDescription')));


          }


          p.getList();
          //  console.log(data);
          pro.productTypeId = data.productTypeId;
          v.set('productTypeId', data.productTypeId);
          p.view.set('productTypeId', data.productTypeId);
          // console.log(pro,p.view, v);
          Config.loading = false;
          Config.inlineLoading = false;
          let mSuccess = 'Successfully Saved';
          ai.view.alertService.success(mSuccess, true);
        }, ['productTypeId'], 'project/updatebyai', '', null, false);

        // this.view.saveSet(['productName','productDescription','productTypeId']);
      }
    } catch (error) {
      console.error(error);
    }
  }

  smartDevReq(view: View, p: Project = null, kp, reqType, inView: View) {
    this.initAI(view, p, '2'); // 2 for keyplayer requirment
    let insights = '';
    if (inView.list && inView.list !== null) {
      for (let x = 0; x < inView.list.length; x++) {
        if (x > 0) { insights += ','; }
        insights += inView.list[x].observation;
      }
    }
    const AI_URL = this.getParams(
      'keyplayerrequirements', view, p
    ) + `&kp=${kp.keyplayer}&kpt=${kp.keyplayerCategoryCode.description}&rt=${reqType.label}${this.getRtItem(view)}&kpi=${insights}`;

    this.loadAiLiked(view, AI_URL);
  }

  private getRtItem(view: View) {
    let rtitem: any;
    if (view && view !== null) {
      rtitem = view.value('requirementTypeItemCode');
      return this.getRtValue(rtitem);
    }
  }
  private getRtValue(rtitem: { label: string; }) {
    if (rtitem && rtitem !== null && rtitem.label !== '') {
      return ',' + rtitem.label;
    } else {
      return '';
    }
  }

  smartDevKpInsight(view: View, p: Project = null, reqType, kp) {
    this.initAI(view, p, '5'); // 3 for keyplayer Insight
    const AI_URL = this.getParams(
      'keyplayerinsights', view, p
    ) + `&kp=${kp.keyplayer}&rt=${reqType.label}${this.getRtItem(view)}&kpt=${kp.keyplayerCategoryCode.description}`;
    this.loadAiLiked(view, AI_URL);
  }
  smartDevTest(view: View, p: Project = null, kp, reqType, req) {
    this.initAI(view, p, '4'); // 4 for test
    const rtitem = this.getRtValue(req.requirementTypeItemCode);
    const AI_URL = this.getParams(
      'testing', view, p
    ) + `&kp=${kp.keyplayer}&kpt=${kp.keyplayerCategoryCode.description}&rt=${reqType.label}${rtitem}&rq=${req.requirement}`;
    this.loadAiLiked(view, AI_URL);
  }

  private initAI(view: View, p: Project = null, aiType) {

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.notesadd = false;
    Config.setLoaderMessage('InsIQ is consulting internal and external datasets, this can take 12-15 secs');
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    Config.loading = true;
    this.hasRecord = 'none';
    this.aitype = aiType;
    this.project = p === null ? view.form.value : p;
    this.setAIView(true);
    this.displayReq = true;
    this.showWish = false;
    this.dashFav = false;
    this.modeval = null;
    this.smartTitle = '';
    this.getLiked();
    this.getComments();
    this.getReports();
  }

  private loadAiLiked(view: View, AI_URL: string, pdf = false, TREND_URL = '', cache = false, fl = '0') {
    // console.log('found');
    this.trendChartLabels = [];
    this.trendChartData = [];
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    if (view.value('mode') == 'Research Mode') {
      this.progress = 0;

      this.config.setLoaderPercentage(this.progress);
    }
    if (view.value('mode') == 'Turbo Mode' && !cache && this.turboMode == 0) {
      // Config.loading = false;
      Config.loading = true;
      this.isTyping = true;
      if (this.turboRerunFl != 0) {
        this.chatLoaderText = 'Researching and Curating ' + this.catName('' + this.turboRerunFl, view) + ' data for your project';

      } else {
        this.chatLoaderText = 'Understanding your project';

      }

      Config.transparent = true;
      const lastMessage = this.messages?.[this.messages.length - 1];
      this.suggestedQuestions = [];

      if (lastMessage && (lastMessage.suggestedQuestion1 || lastMessage.suggestedQuestion2 || lastMessage.suggestedQuestion3)) {
        this.showSuggestions = true;
        // Add the suggested questions to the array
        if (lastMessage.suggestedQuestion1) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion1);
        }
        if (lastMessage.suggestedQuestion2) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion2);
        }
        if (lastMessage.suggestedQuestion3) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion3);
        }
      }
      setTimeout(() => {
        // Desktop View Scrollbar
        this.scrollTo({
          parent: this.aiChatScrollViewer,
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });
        // Mobile View Scrollbar
        this.scrollTo({
          parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });

        // AI chat window Scrollbar
        this.scrollTo({
          align: "bottom",
          parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });
      }, 0);
    } else {
      Config.loading = true;
      if (this.sourceQuerry && view.value('mode') == 'Turbo Mode') {
        Config.setLoaderImage('./assets/images/insiq_ai.gif');
        // Config.setLoaderMessage('InsIQ is collecting the sources, this can take a few seconds');
      }

      this.isTyping = false;

    }

    const querryUrlParams = AI_URL.split('?');
    let filterValue = AI_URL.split('fl=').pop().split('&')[0];
    //console.log({ filterValue });
    let flag: number;
    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    // if (filterValue === '0') {
    //   //       this.sentimentapi(view);
    //   //  this.sentimentclickflg=true;
    //   // this.sentimentapistat=true;
    //   // this.smartTitle = 'Sentiment Analysis';
    //   // this.sentimentclick();
    //   // this.SentiwishList();
    // } else {

    // console.log('cachehere', cache);
    if (cache) {
      //  console.log('here');
      this.config.setProgressVisibility(false);



      this.view.get(`aiinsights/cachelist/${this.aitype}/${this.project.id}`).subscribe(res => {
        //  console.log('fdtg');
        //  console.log(res);
        Config.loading = false;
        this.sentimentResp = res?.sentimentapi;
        this.averagePriceRes = res?.pricingapi[0];
        this.averagePriceResFinal = this.averagePriceRes?.sResponse;
        if (this.averagePriceRes != null) {
          this.setHighLightPrice(this.averagePriceRes, false, (this.existingcomments && this.existingcomments.length > 0));

        }

        //  console.log(this.averagePriceRes);
        // console.log(this.sentimentResp[0]);
        if (this.sentimentResp && this.sentimentResp.length !== 0) {
          // console.log('heree');
          this.sentimentclick(this.sentimentResp[0]);
          this.sentimentapistat = true;
          // this.sentimentapi(view, this.project);
        }

        this.smartlist = res;
        this.smartlist1 = this.smartlist;
        this.sourceQuerry = this.getsourceQuerry();

        // console.log(this.smartlist1);
        if (!this.smartlist) {
          view.get(`aiinsights/getsentiment/${this.project.id}/0/${this.aitype}`).subscribe(res1 => {
            // console.log('here');
            this.sentimentResp = res1;
            this.cacheClear = true;
            this.dashboard = true;
            this.listViewInsight = true;

            this.trendchart = false;
            this.insightCall(pdf, fl, cache);

          });
          view.get(`aiinsights/getpricing/${this.project.id}`).subscribe(res2 => {
            // console.log('here');
            this.averagePriceRes = res2[0];
            this.averagePriceResFinal = this.averagePriceRes?.sResponse;
            if (this.averagePriceRes != null) {
              this.setHighLightPrice(this.averagePriceRes, false, (this.existingcomments && this.existingcomments.length > 0));
            }
            this.cacheClear = true;
            this.dashboard = true;
            this.listViewInsight = true;

            this.trendchart = false;
            this.insightCall(pdf, fl, cache);

          });

          this.hasRecord = 'false';
          this.insight0 = false;
          this.insight1 = false;
          this.insight2 = false;
          this.insight3 = false;
          this.insight4 = false;
          this.insight5 = false;
          this.insight6 = false;
          this.insight7 = false;
          this.insight8 = false;

        } else {
          //   this.smartlistItemDetail=0;
          this.hasRecord = 'true';
          // console.log( this.smartlistItemDetail);
          if (filterValue != '0' && view.value('mode') == 'Research Mode') {
            this.trendcall(view, TREND_URL);
          }

        }

        // console.log(filterValue);
        this.insightCall(pdf, fl, cache);
        if (view.value('mode') == 'Turbo Mode') {
          this.turbochatcache(view.value('id', ''));

        }
        if (filterValue === '0' && view.value('mode') == 'Research Mode') {


          this.sentimentapi(view, this.project);




        } else if (filterValue === '101') {
          //  console.log('entery');

          this.averagePriceApi(view);




        } else {
          this.sentimentapistat = false;
        }
      });
    } else if (filterValue === '101') {
      // console.log('entery');

      // this.averagePriceApi(view);


      this.view.get(`aiinsights/cachelist/${this.aitype}/${this.project.id}`).subscribe(res => {
        //  console.log('fdtg');
        //  console.log(res);
        Config.loading = false;
        this.sentimentResp = res?.sentimentapi;
        this.averagePriceRes = res?.pricingapi[0];
        this.averagePriceResFinal = this.averagePriceRes?.sResponse;
        if (this.averagePriceRes != null) {
          this.setHighLightPrice(this.averagePriceRes, false, (this.existingcomments && this.existingcomments.length > 0));
        }
        //  console.log(this.averagePriceRes);
        // console.log(this.sentimentResp[0]);
        if (this.sentimentResp && this.sentimentResp.length !== 0) {
          // console.log('heree');
          this.sentimentclick(this.sentimentResp[0]);
          this.sentimentapistat = true;
          // this.sentimentapi(view, this.project);
        }

        this.smartlist = res;
        this.smartlist1 = this.smartlist;
        this.sourceQuerry = this.getsourceQuerry();

        // console.log(this.smartlist1);
        if (!this.smartlist) {
          view.get(`aiinsights/getsentiment/${this.project.id}/0/${this.aitype}`).subscribe(res1 => {
            // console.log('here');
            this.sentimentResp = res1;
            this.cacheClear = true;
            this.dashboard = true;
            this.listViewInsight = true;

            this.trendchart = false;
            this.insightCall(pdf, fl, cache);

          });
          view.get(`aiinsights/getpricing/${this.project.id}`).subscribe(res2 => {
            // console.log('here');
            this.averagePriceRes = res2[0];
            this.averagePriceResFinal = this.averagePriceRes?.sResponse;
            if (this.averagePriceRes != null) {
              this.setHighLightPrice(this.averagePriceRes, false, (this.existingcomments && this.existingcomments.length > 0));
            }
            this.cacheClear = true;
            this.dashboard = true;
            this.listViewInsight = true;

            this.trendchart = false;
            this.insightCall(pdf, fl, cache);

          });
          this.hasRecord = 'false';
          this.insight0 = false;
          this.insight1 = false;
          this.insight2 = false;
          this.insight3 = false;
          this.insight4 = false;
          this.insight5 = false;
          this.insight6 = false;
          this.insight7 = false;
          this.insight8 = false;

        } else {
          console.log({ filterValue });

          //   this.smartlistItemDetail=0;
          this.hasRecord = 'true';
          // console.log( this.smartlistItemDetail);
          if (filterValue != '0' && view.value('mode') == 'Research Mode') {
            this.trendcall(view, TREND_URL);
          }

        }

        // console.log(filterValue);
        this.insightCall(pdf, fl, cache);

        if (filterValue === '0' && view.value('mode') == 'Research Mode') {


          this.sentimentapi(view, this.project);




        } else if (filterValue === '101') {
          //  console.log('entery');

          this.averagePriceApi(view);




        } else {
          this.sentimentapistat = false;
        }
      });


    } else {
      if (this.view.value('mode') == 'Research Mode') {
        this.config.setProgressVisibility(true);
      } else {
        this.config.setProgressVisibility(false);

      }
      //  console.log('res');
      this.view.get(`aiinsights/insightsapi/${flag}/${querryUrlParams[1]}`).subscribe(res => {
        // this.view.get(`aiinsights/cachelist/${this.aitype}/${this.project.id}`).subscribe(res => {
        //  console.log('fdtg');
        // console.log(res);
        Config.loading = false;
        this.config.apiUsage();

        this.sentimentResp = res?.sentimentapi;
        this.averagePriceRes = res?.pricingapi[0];
        this.averagePriceResFinal = this.averagePriceRes?.sResponse;
        if (this.averagePriceRes != null) {
          this.setHighLightPrice(this.averagePriceRes, false, (this.existingcomments && this.existingcomments.length > 0));

        }

        // console.log(this.sentimentResp[0]);
        if (this.sentimentResp && this.sentimentResp.length !== 0) {
          // console.log('heree');
          // this.sentimentclick(this.sentimentResp[0]);
          this.sentimentapistat = true;
          // this.sentimentapi(view, this.project);
        }
        this.smartlist = res;
        this.smartlist1 = this.smartlist;
        // console.log( this.smartlist1);
        this.sourceQuerry = this.getsourceQuerry();

        if (!this.smartlist) {
          view.get(`aiinsights/getsentiment/${this.project.id}/0/${this.aitype}`).subscribe(res1 => {
            // console.log('here');
            this.sentimentResp = res1;
            this.cacheClear = true;
            this.dashboard = true;
            this.listViewInsight = true;

            this.trendchart = false;
            this.insightCall(pdf, fl, cache);


          });
          view.get(`aiinsights/getpricing/${this.project.id}`).subscribe(res2 => {
            // console.log('here');
            this.averagePriceRes = res2[0];
            this.averagePriceResFinal = this.averagePriceRes?.sResponse;
            if (this.averagePriceRes != null) {
              this.setHighLightPrice(this.averagePriceRes, false, (this.existingcomments && this.existingcomments.length > 0));
            }
            this.cacheClear = true;
            this.dashboard = true;
            this.listViewInsight = true;

            this.trendchart = false;
            this.insightCall(pdf, fl, cache);

          });
          this.hasRecord = 'false';
          this.insight0 = false;
          this.insight1 = false;
          this.insight2 = false;
          this.insight3 = false;
          this.insight4 = false;
          this.insight5 = false;
          this.insight6 = false;
          this.insight7 = false;
          this.insight8 = false;

        }
        this.isTyping = true;
        if (view.value('mode') == 'Turbo Mode' && this.turboMode == 0) {
          Config.loading = true;
          Config.transparent = true;
          Config.setLoaderMessage('InsIQ is processing your query');
          Config.setLoaderImage('./assets/images/insiq_ai.png');
          this.config.setProgressVisibility(false);
          this.turbochatcacheLoop(this.project.id);
          Config.loading = true;
          this.isTyping = true;
          Config.transparent = true;
          this.chatLoaderText = 'Researching and Curating data for your project';
          const lastMessage = this.messages?.[this.messages.length - 1];
          this.suggestedQuestions = [];

          if (lastMessage && (lastMessage.suggestedQuestion1 || lastMessage.suggestedQuestion2 || lastMessage.suggestedQuestion3)) {
            this.showSuggestions = true;
            // Add the suggested questions to the array
            if (lastMessage.suggestedQuestion1) {
              this.suggestedQuestions.push(lastMessage.suggestedQuestion1);
            }
            if (lastMessage.suggestedQuestion2) {
              this.suggestedQuestions.push(lastMessage.suggestedQuestion2);
            }
            if (lastMessage.suggestedQuestion3) {
              this.suggestedQuestions.push(lastMessage.suggestedQuestion3);
            }
          }
          setTimeout(() => {
            // Desktop View Scrollbar
            this.scrollTo({
              parent: this.aiChatScrollViewer,
              child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
            });
            // Mobile View Scrollbar
            this.scrollTo({
              parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
              child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
            });

            // AI chat window Scrollbar
            this.scrollTo({
              align: "bottom",
              parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
              child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
            });
          }, 0);

          setTimeout(() => {
            this.turbochat(this.project.id, view.value('productDescription'), 0, 0);
          }, 4000); // 10000 milliseconds = 10 seconds
        }
        else {
          this.activeTabIndex = 2;

          this.handleTabClick();
          this.turboMode = 0;
          this.turboRerunFl = 0;
          this.isTyping = false;
          this.view.get(`aiinsights/viewsource/1/${this.project.id}/${this.sourceId}`).subscribe(res => {
            // this.setRecord(res, pdf);
          });
        }

        this.insightCall(pdf, fl, cache);
        if (view.value('mode') == 'Research Mode') {
          this.trendcall(view, TREND_URL);

        }
        if (filterValue === '1') {
          //  this.sentimentapi();
        } else {
          this.sentimentapistat = false;
        }

      });

      if (view.value('mode') == 'Research Mode') {
        this.progressManager();
      }

    }

    this.aiidset = '';
    this.deepdiveflag = false;
    if (this.view.value('mode') == 'Turbo Mode') {
      this.tabNumber = 0;
      this.activeTabIndex = 0;
      this.newMessageForm.get('newMessage')?.setValue('');

    }
  }
  progressManagerkey(view) {
    // console.log('heree')
    setInterval(() => {

      if (Config.loading) {

        this.apiProgressUpdate1(view);
      }
    }, 5000);
  }
  analysisprogressManagerkey(view) {
    // console.log('heree')
    setInterval(() => {

      if (Config.loading) {

        this.analysisProgressUpdate(view);
      }
    }, 2000);
  }
  analysisProgressUpdate(view) {
    let flag;
    const querryUrlParams = 'rn=' + view + '&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/analysisprogress/${flag}/${querryUrlParams}`).subscribe(res => {
      this.progress = res[0]?.sPercent;
      this.config.loadingProgress.next(this.progress);
    });

  }
  apiProgressUpdate1(view) {
    let flag;
    const querryUrlParams = 'rn=' + view + '&ts=0&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/apiprogress/${flag}/${querryUrlParams}`).subscribe(res => {
      this.progress = res[0]?.sPercent;
      this.config.loadingProgress.next(this.progress);
    });

  }
  getHighestDtSequence(): number {
    if (!this.messages || this.messages.length === 0) {
      return -1; // Return -1 if messages is undefined, null, or empty
    }

    let highestSequence = -1;
    for (const message of this.messages) {
      if (message.dtsequence > highestSequence) {
        highestSequence = message.dtsequence;
      }
    }
    return highestSequence;
  }
  getHighestDtSequenceLoop(messages): number {
    if (!messages || messages.length === 0) {
      return -1; // Return -1 if messages is undefined, null, or empty
    }

    let highestSequence = -1;
    for (const message of messages) {
      if (message.dtsequence > highestSequence) {
        highestSequence = message.dtsequence;
      }
    }
    return highestSequence;
  }
  turbochatcacheLoop(id) {


    this.view.get(`aiinsights/turbochatcache/${id}`).subscribe(res => {
      //  console.log(res);
      //  this.messages = res;
      this.chatSequence = this.getHighestDtSequenceLoop(res) + 1; // Update chatSequence


    });



  }
  turbochatcache(id) {


    this.view.get(`aiinsights/chatprompt`).subscribe(res => {
      //   console.log(res);
      this.chatPrompt = res;
    });
    this.view.get(`aiinsights/turbochatcache/${id}`).subscribe(res => {
      //  console.log(res);
      this.messages = res;
      this.chatSequence = this.getHighestDtSequence() + 1; // Update chatSequence
      this.isTyping = false;
      // console.log('here...');
      const lastMessage = this.messages?.[this.messages.length - 1];
      this.suggestedQuestions = [];

      if (lastMessage && (lastMessage.suggestedQuestion1 || lastMessage.suggestedQuestion2 || lastMessage.suggestedQuestion3)) {
        this.showSuggestions = true;
        // Add the suggested questions to the array
        if (lastMessage.suggestedQuestion1) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion1);
        }
        if (lastMessage.suggestedQuestion2) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion2);
        }
        if (lastMessage.suggestedQuestion3) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion3);
        }
      }
      setTimeout(() => {
        if (this.aiChatScrollViewer) {
          // Desktop View Scrollbar
          this.scrollTo({
            parent: this.aiChatScrollViewer,
            child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
          });
        }
        // Mobile View Scrollbar
        this.scrollTo({
          parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });

        // AI chat window Scrollbar
        this.scrollTo({
          align: "bottom",
          parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });
      }, 1000);

    });



  }
  deleteChat(message) {
    Config.loading = true;
    Config.inlineLoading = true;
    Config.setLoaderMessage('Processing...');

    let flag;
    const querryUrlParams = 'rn=' + this.project.id + '&dtsequence=' + message.dtsequence + '&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/chatdelete/${flag}/${querryUrlParams}`).subscribe(res => {
      //  console.log(res);
      this.messages = res;
      // this.config.apiUsage();
      // this.isTyping = false;
      Config.loading = false;
      Config.inlineLoading = false;

    });

  }
  saveChat(message) {
    Config.loading = true;
    Config.inlineLoading = true;
    Config.setLoaderMessage('Processing...');


    let flag;
    const querryUrlParams = 'rn=' + this.project.id + '&dtsequence=' + message.dtsequence + '&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/chatsave/${flag}/${querryUrlParams}`).subscribe(res => {
      //  console.log(res);
      this.messages = res;
      // this.config.apiUsage();
      // this.isTyping = false;
      Config.loading = false;
      Config.inlineLoading = false;

      //    console.log('turbochat');
      const lastMessage = this.messages?.[this.messages.length - 1];
      this.suggestedQuestions = [];

      if (lastMessage && (lastMessage.suggestedQuestion1 || lastMessage.suggestedQuestion2 || lastMessage.suggestedQuestion3)) {
        this.showSuggestions = true;
        // Add the suggested questions to the array
        if (lastMessage.suggestedQuestion1) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion1);
        }
        if (lastMessage.suggestedQuestion2) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion2);
        }
        if (lastMessage.suggestedQuestion3) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion3);
        }
      }
      setTimeout(() => {
        // Desktop View Scrollbar
        this.scrollTo({
          parent: this.aiChatScrollViewer,
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });
        // Mobile View Scrollbar
        this.scrollTo({
          parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });

        // AI chat window Scrollbar
        this.scrollTo({
          align: "bottom",
          parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });
      }, 0);
      // this.chatSequence++;
    });

  }
  generateReport(inputTextarea): void {
    // Call your report generation method
    this.reportCall(inputTextarea);

    // Clear the input fields
    //inputTextarea.value = '';
    // reportTypeTextarea.value = '';

  }
  reportCall(textareaValue: string) {
    // console.log('555555');
    let reportTypeText = "";
    this.chatSequence = this.getHighestDtSequence() + 1; // Update chatSequence
    if (!this.newQuerry) {
      this.newQuerry = this.project.productDescription;
    }
    let flag;
    const querryUrlParams = 'rn=' + this.project.id + '&dtquery=&dtReportType=' + reportTypeText + '&dtsequence=' + this.chatSequence + '&dtReport=1&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    Config.loading = true;

    this.config.setProgressVisibility(false);

    Config.setLoaderImage('./assets/images/turboreport.gif');

    // Config.setLoaderImage('./assets/images/insiq_ai.png');
    Config.setLoaderMessage('InsIQ is preparing your Report');
    this.view.get(`aiinsights/reportcounter`).subscribe(res => {
      //console.log(res)
      if (res) {
        //console.log('hhhhhh')
        this.httpClient.get(`${Config.apiUrl}/aiinsights/turbochatReport/${flag}/${querryUrlParams}`, {
          responseType: 'text'
        }).subscribe(response => {
          Config.loading = false;
          this.reportText = response;

          const dialogRef = this.dialog.open(ReportviewerComponent, {
            data: { title: this.view.value('brandName') + '_InsIQ_Report', content: this.reportText }
          });

        }, error => {
          Config.loading = false;
          console.error('Error downloading the document:', error);
        });

      }
    });
  }
  savedChat() {
    const newArray = this.messages.filter(message => message.saved === 1);
    //console.log(newArray)
    this.savedChatDialogRef = this.savedChatDialog.open(SavedchatComponent, {
      width: '800px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: newArray,
      panelClass: 'savedChatDialog'
    });
  }
  clearChat() {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Clear chat?', message: 'All your previous responses will be discarded.Are you sure want to proceed ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.turboMode = 0;
        this.turboRerunFl = 0;
        this.sourceQuerry = '';
        this.turboSourceMsg = 'To get the details, please click the source against each chat query.';
        this.showSuggestions = false;
        this.view.get(`aiinsights/deleteChatData/${this.project.id}`).subscribe(res => {
          let aitypeVal;
          if (!this.desiagnApiCall) {

            aitypeVal = '1';
          } else {
            aitypeVal = '2';
          }
          let event = 0;
          this.view.get(`aiinsights/clearcache/${this.project.id}/${event}/${aitypeVal}`).subscribe(res => {
            this.dashCharts = [];


            this.trendchart = false;
            this.cacheClear = true;
            this.smartlist = null;
            this.smartlist1 = null;
            this.smartlistItem = null;
            this.hasRecord = 'false';
            this.insight0 = false;
            this.insight1 = false;
            this.insight2 = false;
            this.insight3 = false;
            this.insight4 = false;
            this.insight5 = false;
            this.insight6 = false;
            this.insight7 = false;
            this.insight8 = false;

          });          //  console.log(res);
          this.messages = [];
          this.chatSequence = 0;
          this.isTyping = false;
        });
      }
    });
  }
  projectOwnerCheck() {
    if (this.project?.projectOwner?.name.trim() !== this.config.currentUser?.name.trim()) {
      return false;
    }
    else {
      return true;
    }
  }
  turbochat(id, dtquery, dtsequence, dtReport) {
    if (this.tabNumber === 1) {
      this.showDashboard();
    }
    this.isTyping = true;
    Config.loading = true;
    Config.transparent = true;
    Config.setLoaderMessage('InsIQ is processing your query');
    Config.setLoaderImage('./assets/images/insiq_ai.png');
    if (this.turboRerunFl == 0) {
      this.chatLoaderText = 'Understanding your question';

    } else {
      this.chatLoaderText = 'Understanding your project';

    }
    setTimeout(() => {
      this.chatLoaderText = 'Creating the response';
    }, 2000);
    this.turboRerunFl = 0;
    const lastMessage = this.messages?.[this.messages.length - 1];
    this.suggestedQuestions = [];

    if (lastMessage && (lastMessage.suggestedQuestion1 || lastMessage.suggestedQuestion2 || lastMessage.suggestedQuestion3)) {
      this.showSuggestions = true;
      // Add the suggested questions to the array
      if (lastMessage.suggestedQuestion1) {
        this.suggestedQuestions.push(lastMessage.suggestedQuestion1);
      }
      if (lastMessage.suggestedQuestion2) {
        this.suggestedQuestions.push(lastMessage.suggestedQuestion2);
      }
      if (lastMessage.suggestedQuestion3) {
        this.suggestedQuestions.push(lastMessage.suggestedQuestion3);
      }
    }
    setTimeout(() => {
      // Desktop View Scrollbar
      this.scrollTo({
        parent: this.aiChatScrollViewer,
        child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
      });
      // Mobile View Scrollbar
      this.scrollTo({
        parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
        child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
      });

      // AI chat window Scrollbar
      this.scrollTo({
        align: "bottom",
        parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
        child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
      });
    }, 0);
    this.config.setProgressVisibility(false);
    let flag;
    let sanitizedDtQuery = dtquery.replace(/[\[\];%\\|/]/g, '');
    // Encode sanitized dtquery parameter
    const encodedDtQuery = encodeURIComponent(sanitizedDtQuery);
    const querryUrlParams = 'rn=' + id + '&dtquery=' + encodedDtQuery + '&dtsequence=' + this.chatSequence + '&dtReport=' + dtReport + '&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/turbochat/${flag}/${querryUrlParams}`).subscribe(res => {
      //  console.log(res);
      this.isTyping = false;
      Config.loading = false;

      let rerun = 0;
      for (let i = 0; i < res.length; i++) {
        if (res[i].rerun != 0) {
          rerun = res[i].rerun;
          this.turboRerunFl = rerun;
        }
      }
      if (rerun != 0) {

        Config.loading = true;
        this.isTyping = true;
        Config.transparent = true;
        this.chatLoaderText = 'Researching and Curating ' + this.catName('' + this.turboRerunFl, this.view) + 'data for your project';

        setTimeout(() => {
          // Desktop View Scrollbar
          this.scrollTo({
            parent: this.aiChatScrollViewer,
            child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
          });
          // Mobile View Scrollbar
          this.scrollTo({
            parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
            child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
          });

          // AI chat window Scrollbar
          this.scrollTo({
            align: "bottom",
            parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
            child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
          });
        }, 0);

        // this.chatSequence = this.chatSequence - 1;
        this.view.get(`aiinsights/deleteChatDataSequence/${this.project.id}/${this.chatSequence}`).subscribe(res => {
          // console.log(this.messages);
          this.messages = this.messages.filter(obj => obj.dtsequence !== this.chatSequence);
          this.projView.set('productDescription', dtquery);
          this.view.set('productDescription', dtquery);

          this.project.productDescription = dtquery;
          this.insightGenerator();


        });


      } else {
        this.messages = res;
        const lastMessage = this.messages?.[this.messages.length - 1];
        this.suggestedQuestions = [];

        if (lastMessage && (lastMessage.suggestedQuestion1 || lastMessage.suggestedQuestion2 || lastMessage.suggestedQuestion3)) {
          this.showSuggestions = true;
          // Add the suggested questions to the array
          if (lastMessage.suggestedQuestion1) {
            this.suggestedQuestions.push(lastMessage.suggestedQuestion1);
          }
          if (lastMessage.suggestedQuestion2) {
            this.suggestedQuestions.push(lastMessage.suggestedQuestion2);
          }
          if (lastMessage.suggestedQuestion3) {
            this.suggestedQuestions.push(lastMessage.suggestedQuestion3);
          }
        }
      }
      this.config.apiUsage();
      this.isTyping = false;

      //    console.log('turbochat');

      setTimeout(() => {
        // Desktop View Scrollbar
        this.scrollTo({
          parent: this.aiChatScrollViewer,
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });
        // Mobile View Scrollbar
        this.scrollTo({
          parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });

        // AI chat window Scrollbar
        this.scrollTo({
          align: "bottom",
          parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
          child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
        });
      }, 0);
      // this.chatSequence++;
    });



  }

  yotubeClick() {
    let myurl = 'https://www.youtube.com/@InsIQ_AI/videos';
    window.open(myurl, '_blank');
  }

  acdemyClick() {
    let myurl = 'https://academy.insiq.io/course/elevate-your-product-strategy';
    window.open(myurl, '_blank');
  }
  progressManager() {
    //  console.log('heree')
    setInterval(() => {

      if (Config.loading) {

        this.apiProgressUpdate();
      }
    }, 10000);
  }
  SentimentprogressManager() {

    setInterval(() => {

      if (Config.loading) {

        if ((this.filtervalue === '0' || this.filtervalue == '0') && !this.keywordProgress && this.view.value('mode') == 'Research Mode') {
          this.SentimentapiProgressUpdate();
        }
      }
    }, 5000);
  }

  apiProgressUpdate() {
    let flag;
    const querryUrlParams = 'rn=' + this.project.id + '&ts=0&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/apiprogress/${flag}/${querryUrlParams}`).subscribe(res => {
      this.progress = res[0]?.sPercent;
      this.config.loadingProgress.next(this.progress);
    });

  }

  SentimentapiProgressUpdate() {

    let flag;
    const querryUrlParams = 'rn=' + this.project.id + '&ts=0';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/sentimentprogress/${flag}/${querryUrlParams}`).subscribe(res => {
      this.progress = res.sPercent;



      // Config.setLoaderImage('./assets/images/insiq_ai.png');
      if (res.compCall && !res.blogCall) {
        Config.setLoaderMessage('InsIQ is now processing Competitors');
        this.config.setProgressVisibility(true);
      } else if (!res.compCall && res.blogCall) {
        Config.setLoaderMessage('InsIQ is now processing Blogs & Reviews');
        this.config.setProgressVisibility(true);
      } else if (!res.compCall && !res.blogCall) {
        Config.setLoaderMessage('InsIQ is now processing Sentiment Analysis');
        this.config.setProgressVisibility(true);
      } else {
        this.config.setProgressVisibility(false);

      }



      this.config.loadingProgress.next(this.progress);

      // setTimeout(() => {

      // console.log(res);
      //return res;
    });

  }
  trendcall(view: View, TREND_URL = '') {
    let flag: number;
    const querryUrlParams = TREND_URL.split('?');

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }

    this.view.get(`aiinsights/trendsapi/${flag}/${querryUrlParams[1]}`).subscribe(res => {
      //Config.loading = false;
      this.trendlist = res;
      if (res !== null) {
        if (this.trendlist[0]?.NumberOfResults === 0) {
          this.trendchart = false;
        } else {
          if (this.trendlist[0].aiTrendsValuePair[0].aiValues?.length === 0) {
            this.trendchart = false;
          } else {
            this.trendchart = true;
          }

          for (let i = 0; i < this.trendlist[0].aiTrendsValuePair[0].aiValues.length; i++) {
            this.trendChartLabels.push(this.trendlist[0].aiTrendsValuePair[0].aiValues[i].aiYear);
            this.trendChartData.push(this.trendlist[0].aiTrendsValuePair[0].aiValues[i].aiInterest);
          }
        }
      }
    });
  }
  getsourceQuerry() {
    // Find the first message with the specified dtsequence
    const firstMessage = this.messages?.find(msg => msg.chatId === this.smartlist?.chat);

    // Return the content of the first message if found, otherwise null
    if (this.sourceQuerry) {
      return this.sourceQuerry;
    }
    else {
      return firstMessage ? firstMessage.content : null;

    }
  }
  insightCall(pdf: boolean, fl = '0', cache = false) {
    // console.log( 'this.smartlist.aiInsightsLink');
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    //    if (this.smartlist && this.smartlist.length > 0) {
    //      if (this.aitype === '1') {
    //   console.log('here');
    this.smartlistItem = this.smartlist?.aiInsightsLink;
    // }    //      else if (this.aitype === '2' || this.aitype === '5') {
    //        this.smartlistItem = this.smartlist.aiRequirementsLink;
    //      } else if (this.aitype === '4') {
    //        this.smartlistItem = this.smartlist.aiTestingLink;
    //      }
    this.modeval = null;
    if (this.smartlistItem && this.smartlistItem.length > 0) {
      this.smartTitle = this.smartlistItem?.aiName;
      this.setLiked(this.smartlistItem?.aiLink);
    }

    this.setHighLight(this.smartlistItem, false, (this.existingcomments && this.existingcomments.length > 0));
    // }



    if (pdf) {
      const pdfurl = this.smartlist.Report;
      // console.log(this.smartlist.Report);
      this.onNavigate(this.smartlist.Report);
    }

    this.setHasRecord();
    this.Word2Vec = this.smartlist?.Word2Vec;


    this.ExtractedConceptsTier1 = this.smartlist?.ExtractedConceptsTier1;
    this.ExtractedConceptsTier2 = this.smartlist?.ExtractedConceptsTier2;
    try {
      // this.settagCloud();
      // this.setbarchrt();
      // this.setcatpiechrt();
      // this.showDashboard();
      if (this.smartlist) {
        this.buildChart();
      }
      //  console.log(fl);
      let temp = 0;
      if (!cache) {
        for (let k = 0; k < this.smartlistItem?.length; k++) {
          if (this.smartlistItem[k].aiTypeID == fl) {
            this.showSmartItem(this.smartlistItem[k]?.aiLink, this.smartlistItem[k]?.aiName, this.smartlistItem[k].aiTypeID);
            temp++;
            break;
          }
        }
        if (temp === 0 && fl != '101') {
          // this.openSnackBar({
          //   type: 'simple',
          //   message: 'No Insights Available in this category!',
          //   action: 'CLOSE',
          //   duration: 3000
          // });
          this.showDashboard();
          if (!this.clearInsights && this.view.value('mode') == 'Research Mode') {
            this.openSnackBar({
              type: 'simple',
              message: 'No Insights Available!',
              action: 'CLOSE',
              duration: 3000,
              verticalPosition: 'top'
            });
          }

        }
      } else {
        // console.log(this.sentimentResp);
        if (this.smartlist) {
          this.showDashboard();
        } else {
          if (this.sentimentResp && this.sentimentResp.length !== 0) {
            this.sentimentclick(this.sentimentResp[0]);
          }
        }
      }
      this.updatesentimentflag();


    } catch (Error) {
      console.log(Error);
    }
  }

  handleTabClick(): void {
    // Perform your desired logic here
    if (this?.smartlistItem) {
      this.showSmartItem(this.getailink('808'), this?.smartlistItem[0]?.aiName, '31');
    }
    if (this.smartlistItem) {
      this.hidePaginator = false;

    }

  }

  tabChanged(event: MatTabChangeEvent, focusElement?: any) {
    // Handle tab change event here
    // console.log('Tab changed:', event);
    // console.log(event);
    focusElement?.focus();

    this.tabNumber = event.index;

    this.currentAiTabIndex = event.index;

    if (event.index === 0) {
      this.activeTabIndex = 0;
      this.turboSourceMsg = 'To get the details, please click the source against each chat query.';

      this.newMessageForm.get('newMessage')?.setValue('');
      const lastMessage = this.messages?.[this.messages.length - 1];
      this.suggestedQuestions = [];

      if (lastMessage && (lastMessage.suggestedQuestion1 || lastMessage.suggestedQuestion2 || lastMessage.suggestedQuestion3)) {
        this.showSuggestions = true;
        // Add the suggested questions to the array
        if (lastMessage.suggestedQuestion1) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion1);
        }
        if (lastMessage.suggestedQuestion2) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion2);
        }
        if (lastMessage.suggestedQuestion3) {
          this.suggestedQuestions.push(lastMessage.suggestedQuestion3);
        }
      }
      // Desktop View Scrollbar
      this.scrollTo({
        parent: this.aiChatScrollViewer,
        child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
      });
      // Mobile View Scrollbar
      this.scrollTo({
        parent: <HTMLElement>document.querySelectorAll('app-project .wrapper.active')[0],
        child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
      });

      // AI chat window Scrollbar
      this.scrollTo({
        align: "bottom",
        parent: <HTMLElement>document.querySelectorAll('.scrollViewer')[0],
        child: <HTMLElement>document.querySelectorAll('.card.aiChat')[0]
      });
      // this.tabNumber = 0;
      this.dashboard = true;
      this.hidePaginator = true;

    } else if (event.index === 1) {

      // this.dashboard = true;
      this.activeTabIndex = 1;
      this.newMessageForm.get('newMessage')?.setValue('');
      this.hidePaginator = true;

      this.showDashboard();
    } else if (event.index === 2) {
      // console.log('kkkkk')
      // this.dashboard = false;
      // this.tabNumber = 2;
      // console.log(this.smartTitle)
      this.activeTabIndex = 2;
      this.newMessageForm.get('newMessage')?.setValue('');

      this.handleTabClick();
    }
  }

  scrollTo(op: {
    child: any;
    parent: any;
    align?: 'top' | 'bottom'
  }) {
    op.align = op.align || 'top';
    if (op.parent) {
      // console.log(op.parent, op.parent.scrollHeight);

      op.parent.scrollTo({
        top: op.align === 'top' ? ((op.child) ? op.child.offsetTop + op.child.offsetHeight : 0) : op.parent.scrollHeight,
        behavior: 'smooth'
      });
    }
  }

  setDash() {
    this.dashboard = true
  }
  remDash() {
    this.dashboard = false
  }
  getailink(aiTypeID: string) {
    //console.log('llll', this.smartlistItem[0]?.aiLink)
    let demo = [];
    demo = this.smartlistItem ? this.smartlistItem : [];
    //  console.log(demo)
    let newArray: any[];
    if (aiTypeID === '808') {
      //  console.log('kkkkkkkk')
      newArray = demo;
    } else {
      newArray = demo.filter(function (el) {
        return el.aiTypeID == aiTypeID;
      }
      );
    }
    let retval = [];
    if (aiTypeID === '808') {

      newArray.forEach(obj => {
        if (obj.aiLink) {
          retval = retval.concat(obj.aiLink);
        }
      });

    } else {
      retval = newArray[0]?.aiLink;
    }
    //  console.log(retval)
    return retval;
  }
  getcharttitle(pn, view: View) {
    let pc;
    if (pn == 1) {
      pc = view.value('productName');
    } else if (pn == 2) {
      pc = view.value('brandName');
    }
    return pc;
  }
  getfieldKeywordset(p) {
    let val;
    if (this.filtervalue == '31') {

      if (p.keyword31 === '' || p.keyword31 === null || p.keyword31 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword31)))
      }

    } else if (this.filtervalue == '6') {

      if (p.keyword6 === '' || p.keyword6 === null || p.keyword6 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword6)))
      }

    } else if (this.filtervalue == '10') {

      if (p.keyword10 === '' || p.keyword10 === null || p.keyword10 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword10)))
      }

    } else if (this.filtervalue == '2') {

      if (p.keyword2 === '' || p.keyword2 === null || p.keyword2 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword2)))
      }

    } else if (this.filtervalue == '32') {

      if (p.keyword32 === '' || p.keyword32 === null || p.keyword32 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword32)))
      }

    } else if (this.filtervalue == '8') {

      if (p.keyword8 === '' || p.keyword8 === null || p.keyword8 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword8)))
      }

    } else if (this.filtervalue == '11') {

      if (p.keyword11 === '' || p.keyword11 === null || p.keyword11 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword11)))
      }

    } else if (this.filtervalue == '7') {

      if (p.keyword7 === '' || p.keyword7 === null || p.keyword7 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword7)))
      }

    } else if (this.filtervalue == '5') {

      if (p.keyword5 === '' || p.keyword5 === null || p.keyword5 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword5)))
      }

    } else if (this.filtervalue == '4') {

      if (p.keyword4 === '' || p.keyword === null || p.keyword4 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword4)))
      }

    } else if (this.filtervalue == '9') {

      if (p.keyword9 === '' || p.keyword9 === null || p.keyword9 === 'null') {

        val = encodeURIComponent(this.transform(this.val(p.productDescription)))
      } else {

        val = encodeURIComponent(this.transform(this.val(p.keyword9)))
      }

    }
    return val;
  }
  getfieldKeyword(view: View) {
    let val;
    //console.log(this.filtervalue)
    if (this.filtervalue == '1') {
      this.filtervalue = '199';
    }
    if (this.keywordsel == 31) {

      // if (this.view.value('keyword31') === '' || this.view.value('keyword31') === null || this.view.value('keyword31') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword31', '')))
      //  }

    } else if (this.keywordsel == 6) {
      //   console.log('keriiiiiii')
      // if (this.view.value('keyword6') === '' || this.view.value('keyword6') === null || this.view.value('keyword6') === 'null') {
      //   // console.log('******')
      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {
      //  console.log('@@@@@@')
      val = encodeURIComponent(this.transform(view.value('keyword6', '')))
      // console.log('@@@@@@', val)

      //   }

    } else if (this.keywordsel == 10) {

      // if (this.view.value('keyword10') === '' || this.view.value('keyword10') === null || this.view.value('keyword10') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword10', '')))
      //  }

    } else if (this.keywordsel == 2) {

      // if (this.view.value('keyword2') === '' || this.view.value('keyword2') === null || this.view.value('keyword2') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword2', '')))
      // }

    } else if (this.keywordsel == 32) {

      // if (this.view.value('keyword32') === '' || this.view.value('keyword32') === null || this.view.value('keyword32') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword32', '')))
      //   }

    } else if (this.keywordsel == 8) {

      // if (this.view.value('keyword8') === '' || this.view.value('keyword8') === null || this.view.value('keyword8') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword8', '')))
      // }

    } else if (this.keywordsel == 11) {

      // if (this.view.value('keyword11') === '' || this.view.value('keyword11') === null || this.view.value('keyword11') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword11', '')))
      // }

    } else if (this.keywordsel == 7) {

      // if (this.view.value('keyword7') === '' || this.view.value('keyword7') === null || this.view.value('keyword7') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword7', '')))
      //}

    } else if (this.keywordsel == 5) {

      // if (this.view.value('keyword5') === '' || this.view.value('keyword5') === null || this.view.value('keyword5') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword5', '')))
      // }

    } else if (this.keywordsel == 4) {

      // if (this.view.value('keyword4') === '' || this.view.value('keyword4') === null || this.view.value('keyword4') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword4', '')))
      // }

    } else if (this.keywordsel == 9) {

      // if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('keyword9', '')))
      //}

    } else if (this.keywordsel == 0) {

      // if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('productDescription', '')))
      //}

    } else if (this.keywordsel == 199) {
      // console.log('enterrrr')
      // if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      //  val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      const productDescriptionValue = view.value('productDescription', '');

      // Transform the value and handle the case where it's undefined
      const transformedValue = this.transform(productDescriptionValue !== undefined ? productDescriptionValue : '');

      // Encode the transformed value
      val = encodeURIComponent(transformedValue);

      //}

    } else if (this.keywordsel == 101) {

      // if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

      //   val = encodeURIComponent(this.transform(this.view.value('productDescription', '')))
      // }
      // else {

      val = encodeURIComponent(this.transform(view.value('productDescription', '')))
      //}

    }
    return val;
  }
  private getParams(url, view: View, p: Project = null) {

    // console.log(this.filtervalue)

    // console.log(config.currentUser.aiid);
    this.setProject();
    this.charttitle = this.getcharttitle(view.value('productNewOrExisting', ''), view);
    this.projcatName = view.value('productNewOrExisting', '');
    const epf = this.getepf(view.value('productNewOrExisting', ''));
    this.productFlag = epf;
    const epc = this.getepc(view.value('brandName', ''), view.value('productNewOrExisting', ''));
    if (p === null) {
      // console.log()
      if (view.value('mode', '') == 'Research Mode') {
        return `${url}?t=${encodeURIComponent(this.transform(view.value('productName', '')))}`
          + `&q=${this.getfieldKeyword(view)}`
          + `&q3=${encodeURIComponent(this.transform(view.value('productTypeId', '').title))}`
          + `&q4=${encodeURIComponent(this.transform(view.value('customer', '')))}`
          + `&pn=${encodeURIComponent(this.transform(view.value('brandName', '')))}`
          + `&cc=${encodeURIComponent(this.transform(view.value('targetRegion', '')))}`
          + `&rn=${encodeURIComponent(view.value('id', ''))}`
          + `&epf=${encodeURIComponent(epf)}`
          + `&lang=${encodeURIComponent(this.transform(view.value('apiLanguage', '')))}`
          + `&epc=${encodeURIComponent(this.transform(view.value('companyname', '')))}`
          + `&epcomp=${encodeURIComponent(this.transform(view.value('competitors', '')))}`
          + `&epprod=${encodeURIComponent(this.transform(view.value('competitorspdt', '')))}`
          + `&fl=${encodeURIComponent(url === 'trends' ? '0' : this.filtervalue)}`
          + `&rt=${encodeURIComponent(this.reporttype)}`
          + `&dd=${this.aiidset}`
          + `&mode=1`;
      } else {
        // Construct the URL with parameters
        return `${url}?dtDesc=${encodeURIComponent(this.transform(view.value('productName', '')))}`
          + `&dtIntent=${encodeURIComponent(this.turboMode === 0 ? this.transform(view.value('productDescription', '')) : this.sourceQuerry)}`
          + `&fl=${encodeURIComponent(this.turboRerunFl)}`
          + `&dtTitle=${encodeURIComponent(this.transform(view.value('brandName', '')))}`
          + `&rn=${encodeURIComponent(view.value('id', ''))}`
          + `&pn=${encodeURIComponent(this.transform(view.value('brandName', '')))}`
          + `&dtMode=1&mode=${this.turboMode}`;

      }

      // return `${url}?t=${view.value('productName', '')}`
      // + `&q=${view.value('productDescription', '')}`
      // + `&q3=${view.value('productTypeId', '').title}`
      // + `&q4=${view.value('customer', '')}`
      // + `&q5=${view.value('productBenifit', '')}`
      // + `&q6=${view.value('targetRegion', '')}`
      // + `&q7=${view.value('similarProduct', '')}`
      // + `&q8=${view.value('inspiration', '')}`
      // + `&bn=${view.value('brandName', '')}`
      // + `&cc=${view.value('targetRegion', '')}&key=${Config.AI_API_KEY}`;
    } else {
      // tslint:disable-next-line:max-line-length
      const epf1 = this.getepf(this.val(p.productNewOrExisting));

      if (this.val(p.productNewOrExisting) == 1) {
        this.charttitle = this.val(p.productName);
      } else if (this.val(p.productNewOrExisting) == 2) {
        this.charttitle = this.val(p.brandName);
      }
      this.productFlag = epf1;

      // let epc1 = this.getepc(this.val(p.brandName), this.val(p.productNewOrExisting));
      if (this.val(p.mode) == 'Research Mode') {
        return `${url}?t=${encodeURIComponent(this.transform(this.val(p.productName)))}&q=${this.getfieldKeywordset(p)}&epcomp=${encodeURIComponent(this.transform(this.val(p.competitors)))}&epprod=${encodeURIComponent(this.transform(this.val(p.competitorspdt)))}&q3=${encodeURIComponent(this.transform(this.val(p.productTypeId.title)))}&q4=${encodeURIComponent(this.transform(this.val(p.customer)))}&pn=${encodeURIComponent(this.transform(this.val(p.brandName)))}&cc=${encodeURIComponent(this.transform(this.val(p.targetRegion)))}&rn=${encodeURIComponent(this.val(p.id))}&fl=${encodeURIComponent(url === 'trends' ? '0' : this.filtervalue)}&rt=${encodeURIComponent(this.reporttype)}&dd=${this.aiidset}&epc=${encodeURIComponent(this.transform(this.val(p.companyname)))}&epf=${epf1}&mode=1&lang=${encodeURIComponent(this.transform(this.val(p.apiLanguage)))}`;

      } else {
        // Construct the URL with parameters
        return `${url}?dtDesc=${encodeURIComponent(this.transform(this.val(p.productName)))}`
          + `&dtIntent=${encodeURIComponent(this.turboMode === 0 ? this.transform(this.val(p.productDescription)) : this.transform(this.val(this.sourceQuerry)))}` + `&pn=${encodeURIComponent(this.transform(this.val(p.brandName)))}`
          + `&fl=${encodeURIComponent(this.turboRerunFl)}`
          + `&dtTitle=${encodeURIComponent(this.transform(this.val(p.brandName)))}`
          + `&rn=${encodeURIComponent(this.val(p.id))}`
          + `&dtMode=1&mode=${this.turboMode}`;

      }
      // return `${url}?t=${this.val(p.productName)}&q=${this.val(p.productDescription)}&q3=${this.val(p.productTypeId.title)}&q4=${this.val(p.customer)}&q5=${this.val(p.productBenifit)}&q6=${this.val(p.targetRegion)}&q7=${this.val(p.similarProduct)}&q8=${this.val(p.inspiration)}&bn=${this.val(p.brandName)}&cc=${this.val(p.targetRegion)}&key=${Config.AI_API_KEY}`;
    }
  }
  getFirstContentByDtSequence(dtSequence) {
    // Find the first message with the specified dtsequence
    const firstMessage = this.messages.find(msg => msg.dtsequence === dtSequence);

    // Return the content of the first message if found, otherwise null
    return firstMessage ? firstMessage.content : null;
  }
  toggleSource(message: any) {
    // Toggle isSelected for the clicked message
    message.isSelected = !message.isSelected;

    // Optionally, reset isSelected for other messages
    this.messages.forEach(m => {
      if (m !== message) {
        m.isSelected = false;
      }
    });
    this.sourceId = message.chatId;
    // Call your sourceChecker or other relevant methods here
    this.sourceChecker(message.dtsequence, message.fl);
    this.isSourceQuerryPresent(message.dtsequence);
  }
  isSourceQuerryPresent(dtsequence: number): boolean {
    // console.log('dtsequence', dtsequence);

    let dtSequenceValue: number | undefined;
    let returnVal = false;
    //  console.log(this.sourceQuerry)
    for (let message of this.messages) {
      // console.log('message.content', message.content)

      if (message.content === this.sourceQuerry) {

        //   console.log('match')
        dtSequenceValue = message.dtsequence;
        if (dtSequenceValue === dtsequence) {
          returnVal = true;
        }

        break; // Exit loop once we find the matching message
      }
    }

    // console.log('dtSequenceValue', dtSequenceValue);


    return returnVal;
  }
  sourceChecker(dtSequenceToFind, aiTypeID) {
    //console.log(dtSequenceToFind)
    this.sourceQuerry = this.getFirstContentByDtSequence(dtSequenceToFind);
    // console.log(this.sourceQuerry)
    this.turboMode = 1;
    this.turboSourceMsg = 'Sources for the selected chat not found at the moment.Please try later.'
    this.turboRerunFl = aiTypeID;
    this.insightGenerator();

  }
  getSentLabel(QueryUsed) {
    if (this.val(this.project.productNewOrExisting) == 1) {
      return QueryUsed;
    } else if (this.val(this.project.productNewOrExisting) == 2) {
      return QueryUsed;
    }
  }
  getepf(pn) {
    let pc = 1;
    if (pn == 1) {
      pc = 0;
    } else if (pn == 2) {
      pc = 1;
    }
    return pc;
  }
  getepc(rn, pn) {
    let pc;
    if (pn == 1) {
      pc = '';
    } else if (pn == 2) {
      pc = rn;
    }
    return pc;
  }
  pageInitiator() {
    const element = document.querySelector('.p-paginator-first') as HTMLElement;

    if (element) {
      element.click();
    } else {
      //console.error('Element not found with class name: yourClassName');
    }
  }
  clearMenu() {
    this.pageInitiator();
    this.allSelected = true;
    this.cdr.detectChanges();
    this.filterMenuOpen = false;
    this.selectedCategories.fill(false);
    this.hidePaginator = true;
  }
  showSmartItem(list, title, typeId) {
    this.pageInitiator();
    this.allSelected = true;
    this.cdr.detectChanges();
    this.filterMenuOpen = false;
    this.selectedCategories.fill(false);
    this.hidePaginator = true;
    if ((this.view.value('productDescription') == null || this.view.value('productDescription')?.toString()?.trim() === '')) {
      if (this.view.value('mode') == 'Turbo Mode') {
        this.view.alertService.error('Please Fill What do you need to do of your Product / Idea');

      } else {
        this.view.alertService.error('Please Fill Keywords of your Product / Idea before selecting category');
      }
    } else {
      this.view.set('productTypeId', this.project.productTypeId);

      this.flSelected = +typeId;
      this.flValSelect = typeId;
      this.fixaitypeId = typeId;
      this.filtervalue = typeId;
      this.fixaitypeName = title;
      this.selectedSort = '3';

      this.view.set('aiNotes', '');
      this.view.set('notesId', null);
      this.projectFavFlag = false;

      let aitypeVal;
      if (!this.desiagnApiCall) {

        aitypeVal = '1';
      } else {
        aitypeVal = '2';
      }
      this.view.get(`aiinsight/favoritelist/${aitypeVal}/${this.project.id}`).subscribe(res => {

        this.likes = res;
        this.notesView.get(`aiinsight/noteslist/${aitypeVal}/${this.project.id}`).subscribe(res => {
          //   console.log(res);
          this.existingcomments = res;
          this.notesView.get(`aiinsight/reportlistlist/${aitypeVal}/${this.project.id}`).subscribe(res => {

            this.reportinsights = res;
            this.aiNameTemp = title;
            this.notesadd = false;
            this.pagemin = 0;
            this.pagemax = this.pageSize;
            this.pageno = this.pageSize;
            this.InsIQclickflg = false;
            this.cacheClear = false;
            this.chartReports = false;
            this.sentimentclickflg = false;
            this.averagePrice = false;

            this.sentimentSelected = '';

            this.partnerclickflg = false;
            this.inddeep = false;

            this.notesviewflag = false;
            this.reportviewflag = false;
            this.insightsearch = false;

            this.selCat = 0;
            this.dashboard = false;
            this.listViewInsight = true;

            // this.smartlistItemDetail = list;
            this.setLiked(list);
            this.smartTitle = title;
            this.tagcloudclick = false;
            this.modeval = null;
          });
        });
      });
      const productDescriptionValue = this.view.value('productDescription')?.trim() ?? undefined;

      if (typeId !== 0 && typeId !== 199 && typeId !== 101) {
        //console.log('hereeeee')
        if (typeId == 31) {
          if (this.view.value('keyword31') === '' || this.view.value('keyword31') === null || this.view.value('keyword31') === 'null') {

            this.view.set('keyword31', productDescriptionValue === undefined ? null : productDescriptionValue);

          }

        } else if (typeId == 6) {

          if (this.view.value('keyword6') === '' || this.view.value('keyword6') === null || this.view.value('keyword6') === 'null') {

            this.view.set('keyword6', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (typeId == 10) {

          if (this.view.value('keyword10') === '' || this.view.value('keyword10') === null || this.view.value('keyword10') === 'null') {

            this.view.set('keyword10', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (typeId == 2) {

          if (this.view.value('keyword2') === '' || this.view.value('keyword2') === null || this.view.value('keyword2') === 'null') {

            this.view.set('keyword2', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (typeId == 32) {

          if (this.view.value('keyword32') === '' || this.view.value('keyword32') === null || this.view.value('keyword32') === 'null') {

            this.view.set('keyword32', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (typeId == 8) {

          if (this.view.value('keyword8') === '' || this.view.value('keyword8') === null || this.view.value('keyword8') === 'null') {

            this.view.set('keyword8', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (typeId == 11) {

          if (this.view.value('keyword11') === '' || this.view.value('keyword11') === null || this.view.value('keyword11') === 'null') {

            this.view.set('keyword11', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (typeId == 7) {

          if (this.view.value('keyword7') === '' || this.view.value('keyword7') === null || this.view.value('keyword7') === 'null') {

            this.view.set('keyword7', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (typeId == 5) {

          if (this.view.value('keyword5') === '' || this.view.value('keyword5') === null || this.view.value('keyword5') === 'null') {

            this.view.set('keyword5', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (typeId == 4) {

          if (this.view.value('keyword4') === '' || this.view.value('keyword4') === null || this.view.value('keyword4') === 'null') {

            this.view.set('keyword4', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        } else if (typeId == 9) {

          if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

            this.view.set('keyword9', productDescriptionValue === undefined ? null : productDescriptionValue);
          }


        }

      }
      // this.getLiked();
      // this.getComments();
      // this.getReports();
    }

  }

  smartDevclose() {
    this.turboMode = 0;
    this.turboRerunFl = 0;
    this.sourceQuerry = '';
    this.showSuggestions = false;
    this.suggestedQuestions = [];
    this.turboSourceMsg = 'To get the details, please click the source against each chat query.';

    if ((this.insightwish || this.notesviewflag || this.reportviewflag || this.insightsearch) && !this.dashFav) {

      this.wishListAll = null;

      //  console.log( this.insightwish+''+this.notesviewflag +''+this.reportviewflag );
      this.InsIQclickflg = false;
      this.cacheClear = false;
      this.chartReports = false;
      this.sentimentclickflg = false;
      this.averagePrice = false;

      this.averagePrice = false;

      this.sentimentSelected = '';

      this.partnerclickflg = false;
      this.inddeep = false;
      // this.desiagnApiCall=false;
      this.notesviewflag = false;
      this.reportviewflag = false;
      this.insightsearch = false;

      let aitypeVal;
      if (!this.desiagnApiCall) {

        aitypeVal = '1';
      } else {
        aitypeVal = '2';
      }
      this.selCat = 0;
      this.insightwish = false;
      this.showWish = false;
      this.dashFav = false;

      this.setAIView(true);
      //  console.log(this.smartlist1);
      this.smartlist = this.smartlist1;
      this.smartlistItem = this.smartlist?.aiInsightsLink;
      // }      //      else if (this.aitype === '2' || this.aitype === '5') {
      //        this.smartlistItem = this.smartlist.aiRequirementsLink;
      //      } else if (this.aitype === '4') {
      //        this.smartlistItem = this.smartlist.aiTestingLink;
      //      }
      // console.log(this.smartlistItem);
      if (this.smartlistItem && this.smartlistItem.length > 0) {
        this.smartTitle = this.smartlistItem?.aiName;
        this.setLiked(this.smartlistItem?.aiLink);
      }
      //  console.log( this.smartlist);
      this.progress = 0;
      this.config.setLoaderPercentage(this.progress);
      // Config.loading = true;

      // this.notesView.get(`aiinsight/noteslist/${aitypeVal}/${this.project.id}`).subscribe(res => {
      //   //   console.log(res);
      //   this.existingcomments = res;
      //   // this.setLiked(this.smartlist);
      //   // this.setHighLight(this.smartlist, false, (this.existingcomments && this.existingcomments.length > 0));

      //   this.insightCall(false);
      //   //   console.log( this.smartlistItem);
      //   Config.loading = false;
      // });

      this.showDashboard();
      this.dsSentimentDetail = '';
      // }
    } else {
      this.desiagnApiCall = false;
      this.setAIView(false);
      this.smartlistItemDetail = null;
      this.smartlistItemDetailpaginator = null;
      this.deepdiveDetails = null;
      this.searchinsightDetails = null;
      this.trendDetails = null;
      this.smartreportlist = null;
      this.smartlistItemDetailtip = null;
      this.smartlistItem = null;
      this.pagemin = 0;
      this.pagemax = this.pageSize;
      this.pageno = this.pageSize;
      this.sentimentResp = null;
      this.smartlistItem = null;
      this.dashCharts = [];
      this.ssp.pro.view.active = this.project;
      this.ssp.pro.edit(this.project);
      this.ssp.pro.refreshUserDataTable();
    }
    this.config.apiUsage();

  }
  onNavigate(url) {
    //  console.log('dfryhdtsfryh');
    // console.log(url);
    this.myurl = url;
    window.open(this.myurl, '_blank');
    Config.loading = false;
  }

  htmlToMD(s: string) {
    const converter = new showdown.Converter();
    s = converter.makeMarkdown(s);
    // console.log(s);

    return s;
  }

  htmlToListText(html: string): string {
    // Replace <br> tags with newline characters
    // html = html.replace(/<br>/g, '\n\n');
    html = html.replace(/<br[^>]*>/g, '\n');

    // Replace <ol> tags as numbered items
    html = html.replace(/<ol[^>]*>/g, '');
    html = html.replace(/<\/ol>/g, '\n');
    // html = html.replace(/<li[^>]*>/g, (match, offset) => {
    //   const index = offset / match.length + 1;
    //   return `${index}. `;
    // });

    // Replace <ul> tags as bulleted list
    html = html.replace(/<ul[^>]*>/g, '');
    html = html.replace(/<\/ul>/g, '\n');
    html = html.replace(/<li[^>]*>/g, '\n \t• ');

    // Remove other HTML tags while preserving new lines
    html = html.replace(/<\/?(div|p)[^>]*>/g, '\n');
    html = html.replace(/<[^>]*>/g, '');
    html = html.replace(/(^|\n)\s*$/g, ''); // Remove trailing whitespace and new lines

    // console.log(html);

    return html;
  }

  copyText(val: string) {
    if (navigator.clipboard === undefined) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    } else {
      navigator.clipboard.writeText(val);
    }
  }

  getForm() {
    return this.fb.group({
      id: [''],
      aiModule: [''],
      aiApiType: [''],
      aiParentId: [''],
      aiLikeon: [''],
      aiDeepDive: [''],
      aiReporton: [''],
      aiTypeId: [''],
      mode: [''],
      aiNotes: [''],
      // likeon: ['', Validators.required],
      // aiType: ['', Validators.required],
      aiId: ['', Validators.required],
      projectId: ['', Validators.required],
      liked: [''],
      aiName: [''],
      aiPrice: [''],
      aiTitle: [''],
      aiUrl: [''],
      aiImages: [''],
      aiText: [''],
      aiVideos: [''],
      productName: [''],
      productDescription: [''],
      brandName: [''],
      problemSolve: [],
      productEnviornment: [],
      productTypeId: [''],
      apiLanguage: [''],
      productNewOrExisting: [''],
      targetRegion: [],
      competitors: [],
      competitorspdt: [],
      companyname: [],
      customer: [],
      // projectDueDate: ['', Validators.required],
      projectOwner: [''],
      notes: [''], // for notes
      notesId: [''], // for notes
      keyword31: [],
      keyword6: [],
      keyword10: [],
      keyword2: [],
      keyword32: [],
      keyword8: [],
      keyword11: [],
      keyword7: [],
      keyword5: [],
      keyword4: [],
      keyword9: [],

    });
  }
  addChartNotesview(aiCode, flag = false) {
    this.chartCode = aiCode;
    this.chartnote = '';

    // this.chartnote = this.chartnote.aiNote;

    for (let i = 0; i < this.chartreportinsights.length; i++) {
      if (this.chartreportinsights[i].id === aiCode) {
        this.chartnote = this.chartreportinsights[i].aiNote;
      }
    }

    // console.log( this.chartnote );
    this.openModal('Chartnotemodal');
  }

  addChartNotes(aiCode, flag = false, aiSpl = 0) {
    // console.log(aiSpl);
    this.chartCode = aiCode;
    this.chartnote = '';
    // for (var i = 0; i < this.chartreportinsights.length; i++) {
    //   if (this.chartreportinsights[i].aiCode == aiCode) {
    //     // console.log(this.chartreportinsights[i].aiNote);
    //     this.chartnote = this.chartreportinsights[i];
    //     break;
    //   }
    // }
    this.chartnote = '';
    if (this.sentimentclickflg) {
      if (aiSpl === 991) {
        //   console.log('this.sentimentResp[i].note');
        this.chartnote = this.sentimentSelected?.trendnote;
      } else {
        this.chartnote = this.sentimentSelected?.note;
      }
    } else {
      // for (var i = 0; i < this.sentimentResp.length; i++) {
      //   if (aiSpl === 991) {
      //     //   console.log('this.sentimentResp[i].note');
      //     this.chartnote = this.sentimentResp[i]?.trendnote;
      //   }      //   else {
      //     this.chartnote = this.sentimentResp[i]?.note;
      //   }
    }

    // console.log('11111');
    for (var i = 0; i < this.dashCharts.length; i++) {
      if (this.dashCharts[i].reportId === this.chartCode) {
        this.chartnote = this.dashCharts[i].note;
      }
    }

    if (flag) {
      this.insightSAR = true;
      //  this.chartnote = this.trendNotes;
      this.chartnote = this.trendlist[0].note;
    }

    // console.log( 'chartnote',this.chartnote );
    this.openModal('Chartnotemodal');
    let element: HTMLElement = document.getElementById('Chartnotemodal') as HTMLElement;
    element?.click();

  }

  ChartnoteDelete() {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion', message: 'Are you sure want to delete this note ?', cancelbtn: 'No', okbtn: 'Yes' },
    });

    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.chartnote = '';
        for (var i = 0; i < this.sentimentResp.length; i++) {
          if (this.sentimentResp[i].reportId === this.chartCode || this.sentimentResp[i].reportId == this.chartCode) {
            this.sentimentResp[i].note = '';
          }
          if (this.sentimentResp[i]?.trendreportId === this.chartCode || this.sentimentResp[i]?.trendreportId == this.chartCode) {
            this.sentimentResp[i].trendnote = '';
          }
        }

        // if (this.sentimentclickflg) {

        //   if (this.sentimentSelected?.trendreportId === this.chartCode) {
        //     let selectedChart = this.sentimentResp[i];
        //     // selectedChart.reportId = '';
        //     this.sentimentSelected.trendnote = '';
        //   }        //   else if (this.sentimentSelected?.reportId === this.chartCode) {
        //     this.sentimentSelected.note = '';
        //   }
        // } else {
        for (var i = 0; i < this.dashCharts.length; i++) {
          if (this.dashCharts[i].reportId === this.chartCode) {
            // console.log( this.dashCharts[i]);
            this.dashCharts[i].note = '';
          }
        }
        //  }

        if (this.insightTRN) {
          if (this.trendlist[0].reportId === this.chartCode) {
            this.trendNotes = '';
            this.insightTRN = false;
            this.trendlist[0].note = '';
          }
        }

        this.saveNoteChart('', false);
      }
    });
  }
  addOrEditNotes(row) {
    this.newnotesave = false;
    if (this.featureChecker(15)) {
      let aitypeVal;
      if (!this.desiagnApiCall) {

        aitypeVal = '1';
      } else {
        aitypeVal = '2';
      }
      this.notesRow = row;
      if (row.notesId && row.notesId !== null) {

        this.notesView.get(`aiinsight/noteslist/${aitypeVal}/${this.project.id}`).subscribe(res => {
          //  console.log(res);
          for (var i = 0; i < res.length; i++) {
            if (res[i].id === row.notesId) {
              // console.log(res[i].aiNotes);
              this.view.set('aiNotes', res[i].aiNotes);
              this.view.set('notesId', res[i].id);
            }
            this.noteOld = res[i].aiNotes;

            //  console.log(this.view.value('notesId'));
          }
        });
      } else {
        this.view.set('aiNotes', '');
        this.view.set('notesId', null);
        // this.newnotesave = true;
      }
      this.openModal('notemodal');
      // const dialogRef = this.dialog.open(NotesDialogComponent, {
      //   width: '806px',
      //   maxWidth: '65vw',
      //  data: { newnotesave: this.newnotesave},
      // });
      // this.dialog.open(this.secondDialog);
      //   console.log(this.STAT_POPUP);
      //   this.dialog.open(NotesDialogComponent, {
      //   data: this.STAT_POPUP
      // });
    } else {
      this.featurealert('Notes');

    }
    // this.notesadd = true;

    // console.log(row);



  }
  cancelNote() {
    this.notesadd = false;
    this.newnotesave = false;
    this.noteOld = '';
    this.view.set('aiNotes', '');
    this.view.set('notesId', null);
    this.closeModal('notemodal');

  }
  cancelNoteChart() {
    this.chartnote = '';
    this.closeModal('Chartnotemodal');
    this.newnotesaveC = false;
  }

  noteDelete() {
    // console.log(this.notesRow);
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion', message: 'Are you sure want to delete this note ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.noteDeletecnfrm();
      }
    });
  }

  noteDeletecnfrm() {
    // console.log(this.notesRow.notesId);


    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }

    this.newnotesave = false;
    const aitipenote = '077';
    if (this.notesRow.notesId && this.notesRow.notesId !== null) {
      this.notesView.state = 'edit';
      this.notesView.set('id', this.notesRow.notesId);
    } else {
      this.notesView.newform();
    }

    this.notesView.set('projectId', this.project);
    this.notesView.set('aiId', this.notesRow.aiId);
    // his.notesView.set('aiType',aitypeVal);
    this.notesView.set('aiNotes', '');

    this.notesView.set('projectId', this.project);
    //  this.notesView.set('aiLikeon', likeOrUnlike);
    // this.view.set('aiId', row.aiId);
    this.notesView.set('aiModule', aitypeVal);
    this.notesView.set('aiApiType', aitypeVal);
    this.notesView.set('aiParentId', '0');
    this.notesView.set('aiDeepDive', '0');
    this.notesView.set('aiReporton', '0');
    this.notesView.set('aiTypeId', '1');


    if (this.showWish) {
      this.notesView.set('aiName', this.notesRow.aiName);
    } else {
      this.notesView.set('aiName', this.notesRow.aiNameTemp);
    }
    this.notesView.set('aiText', '');

    this.notesView.set('aiTitle', this.notesRow.aiTitle);
    this.notesView.set('aiUrl', this.notesRow.aiUrl);
    this.notesView.set('aiImages', this.notesRow.aiImages);
    this.notesView.set('aiVideos', this.notesRow.aiVideos);
    // this.view.saveAndAdd(); // this should call the create service


    this.notesView.saveCallback(() => {
      this.notesRow.notesId = null;
      this.notesRow.noteAdd = null;
      this.notesadd = false;
      this.getComments();
      this.getReports();
    }, 'aiinsight/notesupdate', '');

    if (this.notesviewflag) {
      this.wishListAll = this.wishListAll.filter(u => u.id !== this.notesView.value('id'));
      //    this.likes = this.likes.filter(u => u.aiId !== row.aiId);
      this.smartlistItemDetail = this.wishListAll;
      this.smartlistItemDetailtip = this.smartlistItemDetail;
      this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
      //    this.getComments();
      //    this.getReports();
      //    this.setHighLight(this.wishListAll, false, (this.existingcomments && this.existingcomments.length > 0));
      //    if (this.allcatflag) {
      //      this.filterWish('');
      //    } else {
      //      this.filterWish(row.aiName);
      //    }
    }
    //    if (location.host.includes(Config.MAINPROD_URL)) {
    //      this.view.http.get<any>(
    //        `${Config.AI_URL}/usernotes` +
    //        `?pid=${this.project.id}` +
    //        `&aiid=${this.notesRow.aiId}` +
    //        `&nt=${this.view.value('aiNotes')}` +
    //        `&mode=2` +
    //        `&key=${environment.ai.key}`
    //      ).subscribe(res => {
    //        this.view.set('projectId', this.project);
    //        this.view.set('productName', this.project.productName);
    //                this.view.set('productNewOrExisting', this.project.productNewOrExisting);
    //
    //        this.view.set('productDescription', this.project.productDescription);
    //                        this.view.set('brandName', this.project.brandName);
    //
    //                          this.view.set('problemSolve', this.project.problemSolve);
    //                            this.view.set('productEnviornment', this.project.productEnviornment);
    //
    //        this.view.set('productTypeId', this.project.productTypeId);
    //
    //      });
    //    } else {
    //      this.view.http.get<any>(
    //        `${Config.AI_URL_PREPROD}/testusernotes` +
    //        `?pid=${this.project.id}` +
    //        `&aiid=${this.notesRow.aiId}` +
    //        `&nt=${this.view.value('aiNotes')}` +
    //        `&mode=2` +
    //        `&key=${environment.ai.key}`
    //      ).subscribe(res => {
    //        //  Config.loading = false;
    //        //  this.setHasRecord();
    //        // console.log(res);
    //        this.view.set('projectId', this.project);
    //        this.view.set('productName', this.project.productName);
    //                this.view.set('brandName', this.project.brandName);
    //                        this.view.set('apiLanguage', this.project.apiLanguage);
    // this.view.set('problemSolve', this.project.problemSolve);
    //                            this.view.set('productEnviornment', this.project.productEnviornment);
    //                this.view.set('productNewOrExisting', this.project.productNewOrExisting);
    //
    //        this.view.set('productDescription', this.project.productDescription);
    //        this.view.set('productTypeId', this.project.productTypeId);
    //      });
    //    }

    //    this.view.delete(`notesdelete/${notesId}/${this.project.id}/${this.notesRow.aiId}/${this.notesRow.aiId}`);
    //
    // this.notesRow.notesId = null;
    // this.notesRow.noteAdd = null;
    // this.notesadd = false;
    //
    //    if (this.notesRow.id === undefined) {
    //    } else {
    //      this.wishListAll = this.wishListAll.filter(u => u.id !== notesId);
    //      this.likes = this.likes.filter(u => u.aiId !== this.notesRow.aiId);
    //      this.smartlistItemDetail = this.wishListAll;
    //      this.smartlistItemDetailtip = this.smartlistItemDetail;
    //      this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
    //      this.setHighLight(this.wishListAll, false, (this.existingcomments && this.existingcomments.length > 0));
    //    }

    this.getComments();
    this.getReports();

    // this.getComments();
    this.setHasRecord();
    this.view.set('aiNotes', '');
    // this.wishListAll = this.wishListAll.filter(u => u.    id !== row.id);
    // this.likes = this.likes.filter(u => u.aiId     !== row.aiId);
    // this.smartlistItemDetail = th    is.wishListAll;
    // this.smartlistItemDetailtip = this.smart    listItemDetail;
    // this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin,     this.pagemax);
    // this    .getComments();
    // this.setHighLight(this.wishListAll, false, (this.existingcomments && this.existingcomment    s.length > 0));
    this.noteOld = '';
    // if (this.notesviewflag) {
    this.alertService.success('Successfully Removed', true, 10 * 1000);


    //}

    this.closeModal('notemodal');

  }

  cnfrmLike() {
    // console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Add to Favorite?', message: 'Would you like to mark this Insight as Favorite also ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.likeOrUnlike(this.notesRow, '1');
      }
    });
  }

  saveNote() {
    // console.log('fffff')
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }

    this.newnotesave = false;
    const aitipenote = '077';
    if (this.notesRow.notesId && this.notesRow.notesId !== null) {
      this.notesView.state = 'edit';
      this.notesView.set('id', this.notesRow.notesId);
    } else {
      this.notesView.newform();
    }

    this.notesView.set('projectId', this.project);
    this.notesView.set('aiId', this.notesRow.aiId);
    // his.notesView.set('aiType',aitypeVal);
    this.notesView.set('aiNotes', this.view.value('aiNotes'));

    this.notesView.set('projectId', this.project);
    //  this.notesView.set('aiLikeon', likeOrUnlike);
    // this.view.set('aiId', row.aiId);
    this.notesView.set('aiModule', aitypeVal);
    this.notesView.set('aiApiType', aitypeVal);
    this.notesView.set('aiParentId', '0');
    this.notesView.set('aiDeepDive', '0');
    this.notesView.set('aiReporton', '0');
    this.notesView.set('aiTypeId', '1');


    if (this.showWish || this.insightsearch) {
      this.notesView.set('aiName', this.notesRow.aiName);
    } else if (this.averagePrice) {
      this.notesView.set('aiName', 'Pricing Insights');
    } else {
      this.notesView.set('aiName', this.notesRow.aiNameTemp);
    }
    let aiUrl;
    let aiTitle, aiPrice;

    if (this.averagePrice) {
      aiUrl = this.notesRow.aiLink;
      aiTitle = this.notesRow.aiName;
      aiPrice = this.notesRow.aiPrice + ' ' + this.averagePriceRes.Currency;

    } else {
      aiUrl = this.notesRow.aiUrl;
      aiTitle = this.notesRow.aiTitle;
    }
    this.notesView.set('aiText', this.notesRow.aiText);

    this.notesView.set('aiTitle', aiTitle);
    this.notesView.set('aiUrl', aiUrl);
    this.notesView.set('aiPrice', aiPrice);
    this.notesView.set('aiImages', this.notesRow.aiImages);
    this.notesView.set('aiVideos', this.notesRow.aiVideos);
    // this.view.saveAndAdd(); // this should call the create service
    //console.log(this.notesRow.notesId);
    if (this.notesRow.notesId === null || this.notesRow.notesId === '') {
      this.notesView.saveCallback(() => {
        this.notesRow.notesId = this.notesView.value('id');
        this.notesRow.noteAdd = this.notesView.value('aiNotes');
        this.notesadd = false;
        this.getComments();
        this.getReports();
      }, this.notesView.url, '');
    } else {

      this.notesView.saveCallback(() => {
        this.notesRow.notesId = this.notesView.value('id');
        this.notesRow.noteAdd = this.notesView.value('aiNotes');
        this.notesadd = false;
        this.getComments();
        this.getReports();
      }, 'aiinsight/notesupdate', '');
    }
    let flag;
    const querryUrlParams = 'pid=$' + this.project.id + '&aiid=' + this.notesRow.aiId + '&nt=' + encodeURIComponent(this.transform(this.view.value('aiNotes'))) + '&mode=2&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/usernotes/${flag}/${querryUrlParams}`).subscribe(res => {
      Config.loading = false;
      this.setHasRecord();
    });


    // if (location.host.includes(Config.MAINPROD_URL)) {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL}/usernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     this.setHasRecord();
    //   });
    // } else {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL_PREPROD}/testusernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     this.setHasRecord();
    //   });
    // }

    // console.log('existing cmnts' + this.existingcomments);
    this.view.set('aiNotes', '');

    this.closeModal('notemodal');

    if (this.notesRow.liked || this.showWish) {

    } else {
      this.cnfrmLike();
    }
  }
  saveNoteChart(title, flag) {
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    Config.loading = true;

    this.newnotesaveC = false;
    // console.log(this.chartCode);
    // console.log(title);
    this.closeModal('Chartnotemodal');

    this.NoteUpdate(title, this.chartCode).subscribe(() => {


      // this.switcherFn(this.chartCode, flag);
      // this.getReports();
      Config.loading = false;


      for (var i = 0; i < this.dashCharts.length; i++) {
        if (this.dashCharts[i].reportId === this.chartCode) {
          this.dashCharts[i].note = title;

        }
      }
      // console.log(this.sentimentclickflg);


      // this.sentimentSelected.note = title;

      for (let i = 0; i < this.sentimentResp?.length; i++) {

        if (this.sentimentResp[i]?.reportId === this.chartCode) {
          let selectedChart = this.sentimentResp[i];


          // selectedChart.reportId = '';
          selectedChart.note = title;


        }
        if (this.sentimentResp[i]?.trendreportId === this.chartCode) {
          let selectedChart = this.sentimentResp[i];


          // selectedChart.reportId = '';
          selectedChart.trendnote = title;


        }
      }

      if (this?.trendlist) {
        if (this?.trendlist[0]?.reportId === this.chartCode) {
          this.insightTRN = true;
          this.trendNotes = title;
          this.trendlist[0].note = title;
        }

      }



      for (let i = 0; i < this.chartreportinsights.length; i++) {


        if (this.chartreportinsights[i].id === this.chartCode) {


          this.chartreportinsights[i].aiNote = title;
        }
      }


    }, () => {
      Config.loading = false;

    });
    this.chartnote = null;
    if (this.reportviewflag) {

      this.chartnote = '';
    }

    // this.inputName.nativeElement.value = ' ';.
    //console.log(this.chartnote);

    // let element: HTMLElement = document.getElementById('titleInput') as HTMLElement;
    // element?.value=' ';
  }
  switcherFn(chartCode, flag) {
    //  console.log(chartCode);
    // console.log(flag);
    switch (chartCode) {
      case '1':


        this.insightTRN = flag;
        break;
      case '2':
        this.insightC0N = flag;
        break;
      case '3':

        this.insightC1N = flag;
        break;
      case '4':
        this.insightC2N = flag;
        break;
      case '5':
        this.insightC3N = flag;
        break;
      case '6':
        this.insightC4N = flag;
        break;
      case '7':
        this.insightC5N = flag;
        break;
      case '8':
        this.insightC6N = flag;
        break;
      case '9':
        this.insightC7N = flag;
        break;
      case '10':
        this.insightC8N = flag;
        break;
      case '99':
        this.insightSAN = flag;
        this.insightSANR = flag;
        break;
      case '99_10':
        this.insightSAN = flag;
        this.insightSAN10 = flag;
        break;
      case '99_20':
        this.insightSAN = flag;
        this.insightSAN20 = flag;
        break;
      case '99_30':
        this.insightSAN = flag;
        this.insightSAN30 = flag;
        break;
      case '99_40':
        this.insightSAN = flag;
        this.insightSAN40 = flag;
        break;
      case '99_50':
        this.insightSAN = flag;
        this.insightSAN50 = flag;
        break;
      case '99_60':
        this.insightSAN = flag;
        this.insightSAN60 = flag;
        break;
    }
    for (let i = 0; i < this.sentimentResp?.length; i++) {
      //   console.log(aiCode.split('_')[1]);
      if (this.sentimentResp[i]?.Mode == chartCode?.split('_')[1]) {
        // console.log('found');
        //     this.sentimentResp[i].report = this.insightSA;
        this.sentimentResp[i].noteflag = this.insightSAN;
      }
    }


  }
  NoteUpdate(note, chartCode): Observable<HttpEvent<any>> {

    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    const formData: FormData = new FormData();
    formData.append('note', note);
    formData.append('chartCode', chartCode);
    formData.append('userId', this.config.currentUser.id);
    const req = new HttpRequest('POST', `${Config.apiUrl}/aireport/chartNote/${this.project.id}/${aitypeVal}`, formData, {
      responseType: 'json'
    });
    return this.http.request(req);
  }
  saveNotems() {
    var type = '';
    if (this.showWish) {
      type = this.notesRow.aiName;
    } else {
      type = this.notesRow.aiNameTemp;

    }
    if (type == null || type == undefined) {
      type = 'InsIQ Insight';
    }
    const note = '.. Additional Note for Info : ' + this.view.value('aiNotes');

    this.saveNote();
    // this.teamshare(type, this.notesRow.aiUrl, this.notesRow.aiTitle, this.notesRow.aiText, note)
    var title = '';
    title = this.notesRow.aiTitle;
    var text = '';
    text = this.notesRow.aiText?.replace(/<[^>]*>/g, '');
    if (text == null || text == undefined) {
      text = '';
    }
    var url = '';
    url = this.notesRow.aiUrl;

    const message = 'Insight from InsIQ Ai'
      + '#Category: ' + type
      + ' #' + title + '\n'
      + text + '#'
      + 'For more Info follow :'
      + url + '' + note;

    this.teamshare1(message);
  }

  deleteNotetrend() {
    this.newnotesave = false;
    const aitipenote = '077';

    if (this.notesRow.notesId && this.notesRow.notesId !== null) {
      this.notesView.state = 'edit';
      this.notesView.set('id', this.notesRow.notesId);
    } else {
      this.notesView.newform();
    }


    this.notesView.set('aiName', 'Trend Insights');
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }

    // this.notesView.set('aiName', type);
    this.notesView.set('aiTitle', this.notesRow.aiTitle);

    this.notesView.set('projectId', this.project);
    this.notesView.set('aiId', this.notesRow.aiId);
    // his.notesView.set('aiType',aitypeVal);
    this.notesView.set('aiNotes', '');

    this.notesView.set('projectId', this.project);
    //  this.notesView.set('aiLikeon', likeOrUnlike);
    // this.view.set('aiId', row.aiId);
    this.notesView.set('aiModule', aitypeVal);
    this.notesView.set('aiApiType', aitypeVal);
    this.notesView.set('aiParentId', '0');
    this.notesView.set('aiDeepDive', '0');
    this.notesView.set('aiReporton', '0');
    this.notesView.set('aiTypeId', '1');

    this.notesView.set('aiUrl', this.notesRow.aiurl);

    //  this.notesView.set('aiUrl', this.notesRow.aiUrl);
    // }
    this.notesView.set('aiImages', '');
    this.notesView.set('aiVideos', '');

    let notesId;
    if (this.notesRow.id === undefined) {
      notesId = this.notesRow.notesId;
    } else {
      notesId = this.notesRow.id;
    }


    // if (location.host.includes(Config.MAINPROD_URL)) {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL}/usernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&mode=2` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     // Config.loading = false;
    //     // this.setHasRecord();

    //   });
    // } else {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL_PREPROD}/testusernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&mode=2` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     //  Config.loading = false;
    //     //  this.setHasRecord();
    //     // console.log(res);
    //   });
    // }

    let flag;
    const querryUrlParams = 'pid=$' + this.project.id + '&aiid=' + this.notesRow.aiId + '&nt=' + encodeURIComponent(this.transform(this.view.value('aiNotes'))) + '&mode=2&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/usernotes/${flag}/${querryUrlParams}`).subscribe(res => {
      Config.loading = false;
      this.setHasRecord();
    });

    this.notesView.saveCallback(() => {
      this.notesRow.notesId = null;
      this.notesRow.noteAdd = null;
      this.notesadd = false;
      this.getComments();
      this.getReports();
    }, 'aiinsight/notesupdate', '');

    this.getComments();
    this.getReports();
    this.setHasRecord();
  }

  deleteNotedeep() {
    this.newnotesave = false;
    const aitipenote = '077';

    if (this.notesRow.notesId && this.notesRow.notesId !== null) {
      this.notesView.state = 'edit';
      this.notesView.set('id', this.notesRow.notesId);
    } else {
      this.notesView.newform();
    }

    if (this.notesRow.notesId && this.notesRow.notesId !== null) {
      this.notesView.state = 'edit';
      this.notesView.set('id', this.notesRow.notesId);
    } else {
      this.notesView.newform();
    }
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }

    this.notesView.set('aiName', 'Deep Dive');
    this.notesView.set('aiTitle', this.notesRow.aiTitle);

    this.notesView.set('projectId', this.project);
    this.notesView.set('aiId', this.notesRow.aiId);
    // his.notesView.set('aiType',aitypeVal);
    this.notesView.set('aiNotes', '');

    this.notesView.set('projectId', this.project);
    //  this.notesView.set('aiLikeon', likeOrUnlike);
    // this.view.set('aiId', row.aiId);
    this.notesView.set('aiModule', aitypeVal);
    this.notesView.set('aiApiType', aitypeVal);
    this.notesView.set('aiParentId', '0');
    this.notesView.set('aiDeepDive', '0');
    this.notesView.set('aiReporton', '0');
    this.notesView.set('aiTypeId', '1');
    //    if(type=='Trend Insights')
    //    {
    //    this.notesView.set('aiUrl', this.notesRow.aiurl);
    //    }    //     else if(type=='Sentiment Analysis')
    //    {
    //    this.notesView.set('aiUrl', this.notesRow.aiUrl);
    //    }
    //    this.notesView.set('aiImages', '');
    //    this.notesView.set('aiVideos', '');
    //    this.notesView.set('projectId', this.project);
    //    this.notesView.set('aiId', this.notesRow.aiId);
    //    this.notesView.set('aiType', this.aitype);
    //    this.notesView.set('aiNotes', this.view.value('aiNotes'));


    // this.view.set('notes', this.view.value('notes'));

    // this.view.saveAndAdd(); // this should call the create service


    this.notesView.saveCallback(() => {
      this.notesRow.notesId = null;
      this.notesRow.noteAdd = null;
      this.notesadd = false;
      this.getComments();
      this.getReports();
      //    this.alertService.success('Successfully Removed Notes', true, 10 * 1000);

    }, 'aiinsight/notesupdate', '');


    // if (location.host.includes(Config.MAINPROD_URL)) {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL}/usernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&mode=2` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     // Config.loading = false;
    //     // this.setHasRecord();

    //   });
    // } else {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL_PREPROD}/testusernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&mode=2` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     //  Config.loading = false;
    //     //  this.setHasRecord();
    //     // console.log(res);
    //   });
    // }
    let flag;
    const querryUrlParams = 'pid=$' + this.project.id + '&aiid=' + this.notesRow.aiId + '&nt=' + encodeURIComponent(this.transform(this.view.value('aiNotes'))) + '&mode=2&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/usernotes/${flag}/${querryUrlParams}`).subscribe(res => {
      Config.loading = false;
      this.setHasRecord();
    });

  }
  saveNotetrend(type = 'Trend Insights') {
    // this.likeOrUnliketrend(this.notesRow, '1');

    this.newnotesave = false;
    const aitipenote = '077';

    if (this.notesRow.notesId && this.notesRow.notesId !== null) {
      this.notesView.state = 'edit';
      this.notesView.set('id', this.notesRow.notesId);
    } else {
      this.notesView.newform();
    }
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }

    this.notesView.set('aiName', type);
    this.notesView.set('aiTitle', this.notesRow.aiTitle);

    this.notesView.set('projectId', this.project);
    this.notesView.set('aiId', this.notesRow.aiId);
    // his.notesView.set('aiType',aitypeVal);
    this.notesView.set('aiNotes', this.view.value('notes'));

    this.notesView.set('projectId', this.project);
    //  this.notesView.set('aiLikeon', likeOrUnlike);
    // this.view.set('aiId', row.aiId);
    this.notesView.set('aiModule', aitypeVal);
    this.notesView.set('aiApiType', aitypeVal);
    this.notesView.set('aiParentId', '0');
    this.notesView.set('aiDeepDive', '0');
    this.notesView.set('aiReporton', '0');
    this.notesView.set('aiTypeId', '1');
    if (type == 'Trend Insights') {
      this.notesView.set('aiUrl', this.notesRow.aiurl);
    } else if (type == 'Sentiment Analysis') {
      this.notesView.set('aiUrl', this.notesRow.aiUrl);
    }
    this.notesView.set('aiText', this.notesRow.aiText);


    this.notesView.set('aiImages', '');
    this.notesView.set('aiVideos', '');
    // this.view.set('notes', this.view.value('notes'));

    // this.view.saveAndAdd(); // this should call the create service
    //    this.notesView.saveCallback(() => {
    //      this.notesRow.notesId = this.notesView.value('id');
    //      this.notesRow.noteAdd = this.notesView.value('aiNotes');
    //      this.notesadd = false;
    //      this.getComments();
    //      this.getReports();
    //    }, this.notesView.url, '');

    //  console.log(this.notesRow.notesId);
    if (type == 'Sentiment Analysis') {
      if (this.notesRow.notesId === null || this.notesRow.notesId === undefined) {
        this.notesView.saveCallback(() => {
          this.notesRow.notesId = this.notesView.value('id');
          this.notesRow.noteAdd = this.notesView.value('aiNotes');
          this.notesadd = false;
          this.getComments();
          this.getReports();
        }, this.notesView.url, '');
      } else {

        this.notesView.saveCallback(() => {
          this.notesRow.notesId = this.notesView.value('id');
          this.notesRow.noteAdd = this.notesView.value('aiNotes');
          this.notesadd = false;
          this.getComments();
          this.getReports();
        }, 'aiinsight/notesupdate', '');
      }
    } else {

      if (this.notesRow.notesId === null || this.notesRow.notesId === '') {
        this.notesView.saveCallback(() => {
          this.notesRow.notesId = this.notesView.value('id');
          this.notesRow.noteAdd = this.notesView.value('aiNotes');
          this.notesadd = false;
          this.getComments();
          this.getReports();
        }, this.notesView.url, '');
      } else {

        this.notesView.saveCallback(() => {
          this.notesRow.notesId = this.notesView.value('id');
          this.notesRow.noteAdd = this.notesView.value('aiNotes');
          this.notesadd = false;
          this.getComments();
          this.getReports();
        }, 'aiinsight/notesupdate', '');
      }
    }
    // if (location.host.includes(Config.MAINPROD_URL)) {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL}/usernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     // this.setHasRecord();
    //   });
    // } else {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL_PREPROD}/testusernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     // this.setHasRecord();
    //   });
    // }
    let flag;
    const querryUrlParams =
      'pid=$' + this.project.id +
      '&aiid=' + this.notesRow.aiId +
      '&nt=' + encodeURIComponent(this.transform(this.view.value('aiNotes'))) +
      '&mode=2&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/usernotes/${flag}/${querryUrlParams}`).subscribe(res => {
      Config.loading = false;
      this.setHasRecord();
    });

    // console.log('existing cmnts' + this.existingcomments);
    // this.closeModal('notemodal');
    // this.cnfrmLike();
  }
  toggleView() {
    this.listViewInsight = !this.listViewInsight;
    if (!this.listViewInsight) {

      this.hidePaginator = false;
    } else {
      this.hidePaginator = true;

    }
  }
  saveNotedeep(aiName = 'Deep Dive') {
    // this.likeOrUnlike(this.notesRow, '1');
    // console.log(this.notesRow.notesId);
    this.newnotesave = false;
    const aitipenote = '077';

    if (this.notesRow.notesId && this.notesRow.notesId !== null) {
      this.notesView.state = 'edit';
      this.notesView.set('id', this.notesRow.notesId);
    } else {
      this.notesView.newform();
    }
    let aitypeVal: string;
    if (!this.desiagnApiCall) {
      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }

    this.notesView.set('aiName', aiName);
    this.notesView.set('aiTitle', this.notesRow.aiTitle);


    this.notesView.set('aiText', this.notesRow.aiText);

    this.notesView.set('aiUrl', this.notesRow.aiUrl);
    this.notesView.set('aiImages', this.notesRow.aiImages);
    this.notesView.set('aiVideos', this.notesRow.aiVideos);

    this.notesView.set('projectId', this.project);
    this.notesView.set('aiId', this.notesRow.aiId);
    // his.notesView.set('aiType',aitypeVal);
    this.notesView.set('aiNotes', this.view.value('notes'));

    this.notesView.set('projectId', this.project);
    //  this.notesView.set('aiLikeon', likeOrUnlike);
    // this.view.set('aiId', row.aiId);
    this.notesView.set('aiModule', aitypeVal);
    this.notesView.set('aiApiType', aitypeVal);
    this.notesView.set('aiParentId', '0');
    this.notesView.set('aiDeepDive', '0');
    this.notesView.set('aiReporton', '0');
    this.notesView.set('aiTypeId', '1');
    //    if(type=='Trend Insights')
    //    {
    //    this.notesView.set('aiUrl', this.notesRow.aiurl);
    //    }    //     else if(type=='Sentiment Analysis')
    //    {
    //    this.notesView.set('aiUrl', this.notesRow.aiUrl);
    //    }
    //    this.notesView.set('aiImages', '');
    //    this.notesView.set('aiVideos', '');
    //    this.notesView.set('projectId', this.project);
    //    this.notesView.set('aiId', this.notesRow.aiId);
    //    this.notesView.set('aiType', this.aitype);
    //    this.notesView.set('aiNotes', this.view.value('aiNotes'));


    // this.view.set('notes', this.view.value('notes'));

    // this.view.saveAndAdd(); // this should call the create service
    if (this.notesRow.notesId === null || this.notesRow.notesId === undefined) {
      this.notesView.saveCallback(() => {
        this.notesRow.notesId = this.notesView.value('id');
        this.notesRow.noteAdd = this.notesView.value('aiNotes');
        this.notesadd = false;
        this.getComments();
        this.getReports();
      }, this.notesView.url, '');
    } else {

      this.notesView.saveCallback(() => {
        this.notesRow.notesId = this.notesView.value('id');
        this.notesRow.noteAdd = this.notesView.value('aiNotes');
        this.notesadd = false;
        this.getComments();
        this.getReports();
      }, 'aiinsight/notesupdate', '');
    }
    // if (location.host.includes(Config.MAINPROD_URL)) {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL}/usernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     // this.setHasRecord();
    //   });
    // } else {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL_PREPROD}/testusernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${this.notesRow.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     // this.setHasRecord();
    //   });
    // }
    let flag: number;
    const querryUrlParams =
      'pid=$' + this.project.id +
      '&aiid=' + this.notesRow.aiId +
      '&nt=' + encodeURIComponent(this.transform(this.view.value('aiNotes'))) +
      '&mode=2&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/usernotes/${flag}/${querryUrlParams}`).subscribe(res => {
      Config.loading = false;
      this.setHasRecord();
    });

    // console.log('existing cmnts' + this.existingcomments);
    // this.closeModal('notemodal');
    if (this.notesRow.liked) {

    } else {
      this.cnfrmLike();
    }
  }
  saveNotemstrend() {
    this.saveNotetrend();
    const type = 'InsIQ Trend Insights';
    //    if (this.showWish) {
    //      type = this.notesRow.aiName;
    //    }    //    else {
    //      type = this.notesRow.aiNameTemp;
    //
    //    }
    var title = '';
    title = this.notesRow.aiTitle;
    var text = '';
    text = this.notesRow.aiText?.replace(/<[^>]*>/g, '');
    if (text == null || text == undefined) {
      text = '';
    }
    let url = '';
    url = this.notesRow.aiurl;
    const note = '.. Additional Note for Info : ' + this.view.value('aiNotes');

    const message = `Insight from InsIQ Ai#Category: ${type} #${title}\n${text}#For more Info follow :${url}${note}`;

    this.teamshare1(message);
  }

  saveNotemsdeep() {
    this.saveNotedeep();
    const type = 'InsIQ AI Deep Dive';
    //    if (this.showWish) {
    //      type = this.notesRow.aiName;
    //    }    //    else {
    //      type = this.notesRow.aiNameTemp;
    //
    //    }
    let title = '';
    title = this.notesRow.aiTitle;
    let text = '';
    text = this.notesRow.aiText?.replace(/<[^>]*>/g, '');
    if (text == null || text === undefined) {
      text = '';
    }
    let url = '';
    url = this.notesRow.aiUrl;
    const note = '.. Additional Note for Info : ' + this.view.value('aiNotes');
    const message = `Insight from InsIQ Ai#Category: ${type} #${title}\n${text}#For more Info follow :${url}${note}`;

    this.teamshare1(message);
  }

  cnfrmfavcat(row, likeOrUnlike, cat) {
    // console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Action', message: 'Are you sure want to remove from Favorite ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.catliker(row, '0', cat);
      }
    });
  }

  catliker(row, likeOrUnlike, cat) {

    let alreadyLiked = false;
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = this.aitype;
    } else {
      aitypeVal = '2';
    }
    if (row.liked) {
      this.view.set('id', row.liked.id);
      alreadyLiked = true;
    }
    //    this.view.set('projectId', this.project);
    //    this.view.set('likeon', likeOrUnlike);
    //    this.view.set('aiId', row.aiId);
    //    this.view.set('aiType',aitypeVal);

    this.view.set('projectId', this.project);
    this.view.set('aiLikeon', likeOrUnlike);
    this.view.set('aiId', row.aiId);
    this.view.set('aiModule', aitypeVal);
    this.view.set('aiApiType', aitypeVal);
    this.view.set('aiParentId', '0');
    this.view.set('aiDeepDive', '0');
    this.view.set('aiReporton', '0');
    this.view.set('aiTypeId', '1');

    this.view.set('aiName', cat);
    this.view.set('aiTitle', row.aiTitle);
    this.view.set('aiText', row.aiText);

    this.view.set('aiUrl', row.aiUrl);
    this.view.set('aiImages', row.aiImages);
    this.view.set('aiVideos', row.aiVideos);
    this.view.set('brandName', this.project.brandName);
    this.view.set('apiLanguage', this.project.apiLanguage);
    this.view.set('problemSolve', this.project.problemSolve);
    this.view.set('productEnviornment', this.project.productEnviornment);
    this.view.set('productNewOrExisting', this.project.productNewOrExisting);
    this.view.set('mode', this.project.mode);

    this.view.set('productName', this.project.productName);
    this.view.set('productDescription', this.project.productDescription);
    this.view.set('productTypeId', this.project.productTypeId);
    if (likeOrUnlike == 0) {
      this.likeval = 2;
    } else if (likeOrUnlike == 1) {
      this.likeval = 1;
    }
    // if (location.host.includes(Config.MAINPROD_URL)) {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL}/likesdislikes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${row.aiId}` +
    //     `&ld=${this.likeval}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     this.setHasRecord();
    //   });
    // } else {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL_PREPROD}/testlikesdislikes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${row.aiId}` +
    //     `&ld=${this.likeval}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     this.setHasRecord();
    //   });
    // }

    let flag;
    const querryUrlParams = 'pid=' + this.project.id +
      '&aiid=' + row.aiId +
      '&ld=' + this.likeval +
      '&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    // this.view.get(`aiinsights/likesdislikes/${flag}/${querryUrlParams}`).subscribe(res => {
    //   Config.loading = false;
    //   this.setHasRecord();
    // });


    // this.view.saveAndAdd(); // this should call the create service
    this.view.saveCallback(() => {
      if (!alreadyLiked) {
        row['liked'] = this.view.form.value;
        this.view.set('liked', this.view.form.value);
        this.likes.push(this.view.form.value);
      } else {
        this.likes = this.likes.filter(u => u.id !== row['liked'].id);
        row['liked'] = null;
        this.view.set('liked', null);
      }
      this.view.newform();
      this.setProject();

    }, this.view.url, '');
  }

  likeOrUnlikecat(row, likeOrUnlike, cat) {
    if (this.featureChecker(16)) {
      if (row.liked) {
        this.cnfrmfavcat(row, likeOrUnlike, cat);
      } else {
        this.catliker(row, likeOrUnlike, cat);
      }
    } else {
      this.featurealert('Favorite');

    }

  }
  featureChecker(id) {
    return this.config.featureChecker(id);
  }
  cnfrmfav(row, likeOrUnlike) {
    // console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Action', message: 'Are you sure want to remove from Favorite ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.liker(row, '0');
      }
    });
  }
  private _filterPrompts(value: string): any[] {
    const filterValue = value.toLowerCase();
    //console.log(this?.chatPrompt)
    // console.log(this?.chatPrompt?.filter(prompt => prompt.prompt.toLowerCase().includes(filterValue)))
    return this?.chatPrompt?.filter(prompt => prompt.prompt.toLowerCase().includes(filterValue));
  }
  chatPromptCat() {
    const uniqueValues = Array.from(new Set(this?.chatPrompt?.map(obj => obj.catName)));
    return uniqueValues;
  }
  chatPromptCatPrompt(prmptCat) {
    const uniqueArray = this?.chatPrompt?.filter(obj => prmptCat.includes(obj.name));
    return uniqueArray;
  }
  getPromptText(prmptCat) {

    this.uniquePromptArray = this?.chatPrompt?.filter(obj => prmptCat.includes(obj.catName));
    //return uniqueArray;
    //console.log(this.uniquePromptArray)

  }
  setPromptText(promptText) {
    this.newMessageForm.get('newMessage')?.setValue(promptText);
  }
  liker(row, likeOrUnlike) {
    // console.log(row);
    let alreadyLiked = false;
    if (row.liked) {
      this.view.set('id', row.liked.id);
      alreadyLiked = true;
    }
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = this.aitype;
    } else {
      aitypeVal = '2';
    }

    this.view.set('projectId', this.project);
    this.view.set('aiLikeon', likeOrUnlike);
    this.view.set('aiId', row.aiId);
    this.view.set('aiModule', aitypeVal);
    this.view.set('aiApiType', aitypeVal);
    this.view.set('aiParentId', '0');
    this.view.set('aiDeepDive', '0');
    this.view.set('aiReporton', '0');
    this.view.set('aiTypeId', '1');


    let titleName: string;
    let aiUrl: string;
    let aiTitle;
    let aiPrice;
    //    if (this.smartTitle == 'All') {
    //      titleName = row.aiName;
    //    }    //    else {
    //      titleName = this.smartTitle;
    //    }
    if (this.smartTitle == 'All') {
      titleName = row.aiName;
    } else if (this.averagePrice) {
      titleName = 'Pricing Insights';

    } else if (this.insightsearch) {
      titleName = row.aiName;
    } else if (row.aiNameTemp == 'All Insights') {
      titleName = 'Deep Dive';
    } else if (row.aiNameTemp != '') {
      titleName = row.aiNameTemp;
    } else {
      titleName = this.smartTitle;
    }


    if (this.averagePrice) {
      aiUrl = row.aiLink;
      aiTitle = row.aiName;
      aiPrice = row.aiPrice + ' ' + this.averagePriceRes.Currency;
    } else {
      aiUrl = row.aiUrl;
      aiTitle = row.aiTitle;
    }
    this.view.set('aiName', titleName);
    this.view.set('aiTitle', aiTitle);
    this.view.set('aiText', row.aiText);
    this.view.set('aiPrice', aiPrice);
    this.view.set('aiUrl', aiUrl);
    this.view.set('aiImages', row.aiImages);
    this.view.set('aiVideos', row.aiVideos);
    this.view.set('brandName', this.project.brandName);
    this.view.set('apiLanguage', this.project.apiLanguage);
    this.view.set('problemSolve', this.project.problemSolve);
    this.view.set('productEnviornment', this.project.productEnviornment);
    this.view.set('productNewOrExisting', this.project.productNewOrExisting);
    this.view.set('mode', this.project.mode);

    this.view.set('productName', this.project.productName);
    this.view.set('productDescription', this.project.productDescription);
    this.view.set('productTypeId', this.project.productTypeId);

    this.view.set('keyword31', this.project.keyword31);
    this.view.set('keyword6', this.project.keyword6);
    this.view.set('keyword10', this.project.keyword10);
    this.view.set('keyword2', this.project.keyword2);
    this.view.set('keyword32', this.project.keyword32);
    this.view.set('keyword8', this.project.keyword8);
    this.view.set('keyword11', this.project.keyword11);
    this.view.set('keyword7', this.project.keyword7);
    this.view.set('keyword5', this.project.keyword5);
    this.view.set('keyword4', this.project.keyword4);
    this.view.set('keyword9', this.project.keyword9);
    if (likeOrUnlike == 0) {
      this.likeval = 2;
    } else if (likeOrUnlike == 1) {
      this.likeval = 1;
    }
    // if (location.host.includes(Config.MAINPROD_URL)) {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL}/likesdislikes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${row.aiId}` +
    //     `&ld=${this.likeval}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     this.setHasRecord();
    //   });
    // } else {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL_PREPROD}/testlikesdislikes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${row.aiId}` +
    //     `&ld=${this.likeval}` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     Config.loading = false;
    //     this.setHasRecord();
    //   });
    // }

    let flag;
    const querryUrlParams = 'pid=' + this.project.id + '&aiid=' + row.aiId + '&ld=' + this.likeval + '&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    // this.view.get(`aiinsights/likesdislikes/${flag}/${querryUrlParams}`).subscribe(res => {
    //   Config.loading = false;
    //   this.setHasRecord();
    // });


    // this.view.saveAndAdd(); // this should call the create service
    this.view.saveCallback(() => {
      if (!alreadyLiked) {
        row['liked'] = this.view.form.value;
        this.view.set('liked', this.view.form.value);
        this.likes.push(this.view.form.value);
      } else {
        this.likes = this.likes.filter(u => u.id !== row['liked'].id);
        row['liked'] = null;
        this.view.set('liked', null);
        this.alertService.success('Successfully Removed', true, 10 * 1000);

      }
      this.view.newform();
      this.setProject();

    }, this.view.url, '');
  }
  likeOrUnlike(row, likeOrUnlike) {

    this.view.set('aiNotes', '');
    this.view.set('notesId', null);
    if (this.featureChecker(16)) {
      if (row.liked) {
        this.cnfrmfav(row, likeOrUnlike);
      } else {
        this.liker(row, likeOrUnlike);
      }
    } else {
      this.featurealert('Favorite');

    }
  }
  removefav(row) {
    // console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Action', message: 'Are you sure want to remove from Favorite ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.removefavconfrm(row);
      }
    });
  }
  removefavconfrm(row) {
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    //this.view.deleteList(`wishes/${row.id}/${this.project.id}/${row.aiId}/${aitypeVal}`);

    this.view.deleteList(`wishes/${row.id}/${this.project.id}/${row.aiId}/${aitypeVal}`, null, 'Successfully Removed.');

    //this.view.deleteList(pu.id, 'projectuser', 'Successfully deleted the member.');

    this.wishListAll = this.wishListAll.filter(u => u.id !== row.id);
    this.likes = this.likes.filter(u => u.aiId !== row.aiId);
    this.smartlistItemDetail = this.wishListAll;
    this.smartlistItemDetailtip = this.smartlistItemDetail;
    this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
    if (this.allcatflag) {
      this.filterWish('');
    } else {
      this.filterWish(row.aiName);
    }
  }
  removenote(row) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion', message: 'Are you sure want to delete this note ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.removenotecnfrm(row);
      }
    });
  }
  removenotecnfrm(row) {
    // console.log(row);
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;

    // if (location.host.includes(Config.MAINPROD_URL)) {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL}/usernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${row.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&mode=2` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     // Config.loading = false;
    //     // this.setHasRecord();

    //   });
    // } else {
    //   this.view.http.get<any>(
    //     `${Config.AI_URL_PREPROD}/testusernotes` +
    //     `?pid=${this.project.id}` +
    //     `&aiid=${row.aiId}` +
    //     `&nt=${this.view.value('aiNotes')}` +
    //     `&mode=2` +
    //     `&key=${environment.ai.key}`
    //   ).subscribe(res => {
    //     // Config.loading = false;
    //     // this.setHasRecord();
    //     // console.log(res);
    //   });
    // }

    let flag;
    const querryUrlParams = 'pid=$' + this.project.id + '&aiid=' + row.aiId + '&nt=' + encodeURIComponent(this.transform(this.view.value('aiNotes'))) + '&mode=2&key=';

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/usernotes/${flag}/${querryUrlParams}`).subscribe(res => {
      Config.loading = false;
      this.setHasRecord();
    });

    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }

    // this.notesView.set('aiName', 'Deep Dive');
    this.notesView.set('aiTitle', this.notesRow.aiTitle);

    this.notesView.set('projectId', this.project);
    this.notesView.set('aiId', this.notesRow.aiId);
    // his.notesView.set('aiType',aitypeVal);
    this.notesView.set('aiNotes', '');

    this.notesView.set('projectId', this.project);
    //  this.notesView.set('aiLikeon', likeOrUnlike);
    // this.view.set('aiId', row.aiId);
    this.notesView.set('aiModule', aitypeVal);
    this.notesView.set('aiApiType', aitypeVal);
    this.notesView.set('aiParentId', '0');
    this.notesView.set('aiDeepDive', '0');
    this.notesView.set('aiReporton', '0');
    this.notesView.set('aiTypeId', '1');
    //    if(type=='Trend Insights')
    //    {
    //    this.notesView.set('aiUrl', this.notesRow.aiurl);
    //    }    //     else if(type=='Sentiment Analysis')
    //    {
    //    this.notesView.set('aiUrl', this.notesRow.aiUrl);
    //    }
    //    this.notesView.set('aiImages', '');
    //    this.notesView.set('aiVideos', '');
    //    this.notesView.set('projectId', this.project);
    //    this.notesView.set('aiId', this.notesRow.aiId);
    //    this.notesView.set('aiType', this.aitype);
    //    this.notesView.set('aiNotes', this.view.value('aiNotes'));


    // this.view.set('notes', this.view.value('notes'));

    // this.view.saveAndAdd(); // this should call the create service


    this.notesView.saveCallback(() => {
      this.notesRow.notesId = null;
      this.notesRow.noteAdd = null;
      this.notesadd = false;
      this.getComments();
      this.getReports();
    }, 'aiinsight/notesupdate', '');
    this.wishListAll = this.wishListAll.filter(u => u.id !== row.id);
    this.likes = this.likes.filter(u => u.aiId !== row.aiId);
    this.smartlistItemDetail = this.wishListAll;
    this.smartlistItemDetailtip = this.smartlistItemDetail;
    this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
    this.getComments();
    this.getReports();
    this.setHighLight(this.wishListAll, false, (this.existingcomments && this.existingcomments.length > 0));
    if (this.allcatflag) {
      this.filterWish('');
    } else {
      this.filterWish(row.aiName);
    }
  }
  setProject() {
    // this.view.set('id', this.project.id);
    this.view.set('projectId', this.project);
    this.view.set('productName', this.project.productName);
    this.view.set('productNewOrExisting', this.project.productNewOrExisting);
    this.view.set('mode', this.project.mode);

    this.view.set('brandName', this.project.brandName);
    this.view.set('problemSolve', this.project.problemSolve);
    this.view.set('productEnviornment', this.project.productEnviornment);
    this.view.set('apiLanguage', this.project.apiLanguage);
    this.view.set('productDescription', this.project.productDescription);
    this.view.set('productTypeId', this.project.productTypeId);
    this.view.set('customer', this.project.customer);

    this.view.set('companyname', this.project.companyname);
    this.view.set('projectOwner', this.project.projectOwner);
    this.view.set('competitors', this.project.competitors);

    this.view.set('competitorspdt', this.project.competitorspdt);
    this.view.set('targetRegion', this.project.targetRegion);
    this.view.set('apiLanguage', this.project.apiLanguage);

    this.view.set('keyword31', this.project.keyword31);
    this.view.set('keyword6', this.project.keyword6);
    this.view.set('keyword10', this.project.keyword10);
    this.view.set('keyword2', this.project.keyword2);
    this.view.set('keyword32', this.project.keyword32);
    this.view.set('keyword8', this.project.keyword8);
    this.view.set('keyword11', this.project.keyword11);
    this.view.set('keyword7', this.project.keyword7);
    this.view.set('keyword5', this.project.keyword5);
    this.view.set('keyword4', this.project.keyword4);
    this.view.set('keyword9', this.project.keyword9);



    // console.log(this.project.productTypeId);
  }

  getLiked() {
    // this.likes = [];
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    this.view.get(`aiinsight/favoritelist/${aitypeVal}/${this.project.id}`).subscribe(res => {

      this.likes = res;
    });
  }
  getComments() {
    // this.likes = [];
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    this.notesView.get(`aiinsight/noteslist/${aitypeVal}/${this.project.id}`).subscribe(res => {
      //   console.log(res);
      this.existingcomments = res;
    });
  }

  getReports() {
    // console.log('heree');
    this.chartreportinsights = [];
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    // this.likes = [];
    this.notesView.get(`aiinsight/reportlistlist/${aitypeVal}/${this.project.id}`).subscribe(res => {

      this.reportinsights = res;
    });
    this.notesView.get(`aireport/aicharts/${this.project.id}/${aitypeVal}`).subscribe(res => {

      this.chartreportinsights = res;


      for (var i = 0; i < this.chartreportinsights.length; i++) {
        if (this.chartreportinsights[i].aiCode === '99' || this.chartreportinsights[i].aiCode == 99) {
          this.sentimentCharts = this.sentimentCharts + this.chartreportinsights[i];

        }
        switch (this.chartreportinsights[i].aiCode) {
          case 1:
            // console.log('1111111111');
            this.insightTR = true;
            this.trendReportId = this.chartreportinsights[i].id;
            this.imageTRN = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightTRN = true;

              this.trendNotes = this.chartreportinsights[i].aiNote;


            }

            break;
          case 2:
            this.insightC0 = true;
            // console.log(this.chartreportinsights[i].chartImage);
            //  this.imageC0N = `data:image/png;base64,${{this.chartreportinsights[i].chartImage }}`;
            this.imageC0N = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightC0N = true;
            }
            break;
          case 3:
            this.insightC1 = true;
            this.imageC1N = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightC1N = true;
            }
            break;
          case 4:
            this.insightC2 = true;
            this.imageC2N = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightC2N = true;
            }
            break;
          case 5:
            this.insightC3 = true;
            this.imageC3N = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightC3N = true;
            }
            break;
          case 6:
            this.insightC4 = true;
            this.imageC4N = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightC4N = true;
            }
            break;
          case 7:
            this.insightC5 = true;
            this.imageC5N = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightC5N = true;
            }
            break;
          case 8:
            this.insightC6 = true;
            this.imageC6N = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightC6N = true;
            }
            break;
          case 9:
            this.insightC7 = true;
            this.imageC7N = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightC7N = true;
            }
            break;
          case 10:
            this.insightC8 = true;
            this.imageC8N = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
            if (this.chartreportinsights[i].aiNote) {
              this.insightC8N = true;
            }
            break;
          case 99:

            switch (this.chartreportinsights[i].aiSubcode) {
              case 10: this.insightSA10 = true;
                this.imageSAN10 = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
                if (this.chartreportinsights[i].aiNote) {
                  this.insightSAN10 = true;
                }
                this.insightSA10T = this.chartreportinsights[i].chartTitle;

                break;
              case 20: this.insightSA20 = true;
                this.imageSAN20 = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
                if (this.chartreportinsights[i].aiNote) {
                  this.insightSAN20 = true;
                } this.insightSA20T = this.chartreportinsights[i].chartTitle;
                break;
              case 30: this.insightSA30 = true;
                this.imageSAN30 = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
                if (this.chartreportinsights[i].aiNote) {
                  this.insightSAN30 = true;
                } this.insightSA30T = this.chartreportinsights[i].chartTitle;
                break;
              case 40: this.insightSA40 = true;
                this.imageSAN40 = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
                if (this.chartreportinsights[i].aiNote) {
                  this.insightSAN40 = true;
                } this.insightSA40T = this.chartreportinsights[i].chartTitle;
                break;
              case 50: this.insightSA50 = true;
                this.imageSAN50 = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
                if (this.chartreportinsights[i].aiNote) {
                  this.insightSAN50 = true;
                } this.insightSA50T = this.chartreportinsights[i].chartTitle;
                break;
              case 60: this.insightSA60 = true;
                this.imageSAN60 = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
                if (this.chartreportinsights[i].aiNote) {
                  this.insightSAN60 = true;
                } this.insightSA60T = this.chartreportinsights[i].chartTitle;
                break;

              default: this.insightSAR = true;
                this.imageSANR = 'data:image/png;base64,' + this.chartreportinsights[i].chartImage;
                if (this.chartreportinsights[i].aiNote) {
                  this.insightSANR = true;
                } this.insightSART = this.chartreportinsights[i].chartTitle;

                break;
            }

            break;
          // case 1:
          //   this.insightTR = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightTRN = true;
          //   }

          //   break;
          // case 2:
          //   this.insightC0 = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightC0N = true;
          //   }
          //   break;
          // case 3:
          //   this.insightC1 = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightC1N = true;
          //   }
          //   break;
          // case 4:
          //   this.insightC2 = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightC2N = true;
          //   }
          //   break;
          // case 5:
          //   this.insightC3 = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightC3N = true;
          //   }
          //   break;
          // case 6:
          //   this.insightC4 = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightC4N = true;
          //   }
          //   break;
          // case 7:
          //   this.insightC5 = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightC5N = true;
          //   }
          //   break;
          // case 8:
          //   this.insightC6 = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightC6N = true;
          //   }
          //   break;
          // case 9:
          //   this.insightC7 = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightC7N = true;
          //   }
          //   break;
          // case 10:
          //   this.insightC8 = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightC8N = true;
          //   }
          //   break;
          // case 11:
          //   this.insightSA = true;
          //   if (this.chartreportinsights[i].aiNote) {
          //     this.insightSAN = true;
          //   }
          //   break;
        }

      }
      // console.log( this.sentimentCharts);
    });
  }
  setLiked(list) {
    if (list && list !== null) {
      this.setHighLight(list, (this.likes && this.likes.length > 0), (this.existingcomments && this.existingcomments.length > 0));
    } else {

      this.smartlistItemDetail = null;
      this.smartlistItemDetailpaginator = null;
      this.deepdiveDetails = null;
      this.searchinsightDetails = null;
      this.trendDetails = null;

      this.smartlistItemDetailtip = null;
    }
    this.setHasRecord();
  }
  setHighLightPrice(list, hasLike, hasNotes) {
    list = list?.sResponse;
    // console.log('ggggg');
    // console.log(list);
    let item;

    // console.log(list);
    for (let i = 0; i < list?.length; i++) {
      item = list[i];
      item['liked'] = null;
      item['notesId'] = null;
      item['report'] = '0';
    }
    // console.log(this.likes);
    // console.log(this.existingcomments);
    //
    // console.log(this.reportinsights);
    // console.log(list[0]);
    for (let i = 0; i < list?.length; i++) {

      item = list[i];
      // console.log(item);
      for (let j = 0; j < this.likes.length; j++) {
        //console.log(parseInt(this.likes[j].aiId, 10));
        //console.log(parseInt(item.aiId, 10));

        if (parseInt(this.likes[j].aiId, 10) === parseInt(item.aiId, 10)) {
          item['liked'] = this.likes[j];
          break;
        }
      }

      for (let j = 0; j < this.existingcomments.length; j++) {
        if (parseInt(this.existingcomments[j].aiId, 10) === parseInt(item.aiId, 10)) {
          item['notesId'] = this.existingcomments[j].id;
          item['noteAdd'] = this.existingcomments[j].aiNotes;
          //  console.log(this.existingcomments[j].aiNotes);
          //  console.log('hereee'+this.existingcomments[j].notes);
          // console.log('==='+item['notesId']);
          break;
        }
      }
      for (let j = 0; j < this.reportinsights.length; j++) {

        if (parseInt(this.reportinsights[j].aiId, 10) === parseInt(item.aiId, 10)) {
          item['report'] = '1';

          //  console.log('hereee'+this.existingcomments[j].notes);
          // console.log('==='+item['notesId']);
          break;
        }
      }
      list[i] = item;
    }
    this.averagePriceRes.sResponse = list;
  }
  setHighLight(list, hasLike, hasNotes) {
    let item;

    // console.log(list);
    for (let i = 0; i < list?.length; i++) {
      item = list[i];
      item['liked'] = null;
      item['notesId'] = null;
      item['report'] = '0';
    }
    // console.log(this.likes);
    // console.log(this.existingcomments);
    //
    // console.log(this.reportinsights);
    // console.log(list[0]);
    for (let i = 0; i < list?.length; i++) {

      item = list[i];

      for (let j = 0; j < this.likes.length; j++) {
        if (parseInt(this.likes[j].aiId, 10) === parseInt(item.aiId, 10)) {
          item['liked'] = this.likes[j];
          break;
        }
      }

      for (let j = 0; j < this.existingcomments.length; j++) {
        if (parseInt(this.existingcomments[j].aiId, 10) === parseInt(item.aiId, 10)) {
          item['notesId'] = this.existingcomments[j].id;
          item['noteAdd'] = this.existingcomments[j].aiNotes;
          //  console.log(this.existingcomments[j].aiNotes);
          //  console.log('hereee'+this.existingcomments[j].notes);
          // console.log('==='+item['notesId']);
          break;
        }
      }
      for (let j = 0; j < this.reportinsights.length; j++) {

        if (parseInt(this.reportinsights[j].aiId, 10) === parseInt(item.aiId, 10)) {
          item['report'] = '1';

          //  console.log('hereee'+this.existingcomments[j].notes);
          // console.log('==='+item['notesId']);
          break;
        }
      }
      list[i] = item;
    }

    // console.log(this.smartlistItemDetail);
    this.smartlistItemDetail = list;
    this.smartlistItemDetailtip = this.smartlistItemDetail;
    this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);


    //  console.log('Highlighter cmnts' + this.smartlistItemDetail);

    try {
      for (var i = 0; i < this.smartlistItem?.length; i++) {
        //  console.log( this.smartlistItem[i].aiLink);
        for (var j = 0; j < this.smartlistItem[i]?.aiLink.length; j++) {



          this.smartlistItem[i].aiLink[j].aiNameTemp = this.smartlistItem[i].aiName;

          this.smartlistItem[i].aiLink[j].aiNameIdTemp = this.smartlistItem[i].aiTypeID;

          // this.smartlistItem[i].aiLink[j].aiDateTemp = this.smartlistItem[i].aiLink[j].aiDate;

          var date = this.smartlistItem[i].aiLink[j].aiDate;
          this.smartlistItem[i].aiLink[j].aiDateTemp = new Date(date.split('/').reverse().join('-'));


          //  console.log( this.smartlistItem[i].aiLink[j].aiDateTemp);



          //
        }
      }
    } catch (exception) {
      // console.log(exception);

    }

    try {
      //  this.settagCloud();
    } catch (Error) {
      console.log(Error);
    }
    // shuffle(this.pieChartData);
    const NumResultsBycategory = (this.smartlist == undefined) ? 'That is true : 5>3' : this.smartlist.NumResultsBycategory?.replace(/,/g, '');

    //  const NumResultsBycategory = this.smartlist.NumResultsBycategory.replace(/,/g, '');
    const numarray = NumResultsBycategory?.trim().split(' ');


  }

  titleManager(aiText = '') {
    let aiTextFinal;
    if (aiText?.includes('Curated')) {
      // console.log('here'+aiText.bold());

      aiTextFinal = aiText.bold();
    } else {
      aiTextFinal = aiText;
    }
    return aiTextFinal;
  }

  notesListview(view: View, p: Project = null, aitype = '1') {
    if (this.featureChecker(15)) {
      this.notesListview1(view, p, aitype);
    } else {
      this.featurealert('Notes');

    }
  }
  notesListview1(view: View, p: Project = null, aitype = '1') {
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.allcatflag = true;

    this.hasRecord = 'none';
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    this.aitype = aitypeVal;
    this.project = p === null ? view.form.value : p;
    this.setAIView(true);
    this.displayReq = true;
    this.showWish = false;
    // this.dashFav=false;
    this.modeval = null;
    this.smartTitle = '';
    this.getLiked();
    this.getComments();
    this.getReports();
    // console.log('main test'+this.existingcomments);
    // this.setHighLight(this.smartlistItemDetail,false, (this.existingcomments && this.existingcomments.length > 0));
    this.notesviewflag = true;
    this.reportviewflag = false;
    this.insightsearch = false;

    const list = new Array();
    try {
      //    for (var i = 0; i < this.smartlistItem.length; i++) {
      //      //  console.log(this.smartlistItem[i].aiLink.length);
      //      for (var j = 0; j < this.smartlistItem[i].aiLink.length; j++) {
      //        for (var k = 0; k < this.existingcomments.length; k++)
      // {
      //   console.log(this.smartlistItem[i].aiLink[j]);
      //   console.log(this.existingcomments[k]);
      //
      //          if (this.smartlistItem[i].aiLink[j].aiId == this.existingcomments[k].aiId) {
      //          this.smartlistItem[i].aiLink[j].aiNameTemp = this.smartlistItem[i].aiName;
      //
      //          list.push(this.smartlistItem[i].aiLink[j]);
      //        }
      // }
      //        //
      //      }
      //    }
      const aitipenote = '77';

      // this.getComments();
      // this.getReports();
      this.progress = 0;
      this.config.setLoaderPercentage(this.progress);
      Config.loading = true;
      view.get(`aiinsight/noteslist/${aitypeVal}/${this.project.id}`).subscribe(res => {
        Config.loading = false;
        this.wishListAll = res;
        view.get(`aiinsight/notesnames/${aitypeVal}/${this.project.id}`).subscribe(r => {
          this.smartlistItem = r;
          // console.log(this.smartlistItem);

        });
        // console.log(this.wishListAll);
        this.smartTitle = 'All';
        // console.log(this.existingcomments);
        for (var i = 0; i < this.existingcomments.length; i++) {
          for (var j = 0; j < this.wishListAll.length; j++) {
            if (this.existingcomments[i].aiId == this.wishListAll[j].aiId) {
              this.wishListAll[j].aiNameTemp = this.wishListAll[j].aiName;
              this.wishListAll[j].aiNameIdTemp = this.catId(this.wishListAll[j].aiName);

              list.push(this.wishListAll[j]);

            }
          }
        }
        this.dashboard = false;
        this.listViewInsight = true;

        this.InsIQclickflg = false;
        this.cacheClear = false;
        this.chartReports = false;
        this.sentimentclickflg = false;
        this.averagePrice = false;

        this.sentimentSelected = '';

        this.partnerclickflg = false;
        this.inddeep = false;

        // this.smartTitle = 'Notes Entered ';
        this.showWish = false;
        this.tagcloudclick = false;
        // this.notesviewflag=false;
        this.selCat = 0;
        this.setLiked(list);
        // this.getComments();
        // this.getReports();
        // console.log(list);
        if (list.length > 0) {
          this.smartlistItemDetail = list;
          this.smartlistItemDetailtip = this.smartlistItemDetail;
          this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
        }
        this.smartlist = this.wishListAll;
      });

    } catch (exception) {
      console.log(exception);

    }


  }
  getnote() {
    this.getComments();
    // this.deepdiveDetails;
  }
  settagCloud() {
    let tc: TagCloud;
    this.tagCloud = [];
    try {
      // this.setbarchrt();
    } catch (Error) {
      console.log(Error);
    }
    const tier1wrds = this.smartlist?.ExtractedConceptsTier1.trim().split(',');
    const tier1array = tier1wrds;
    for (let x = 0; x < tier1array.length; x++) {
      tc = new TagCloud();
      tc.label = tier1array[x];
      tc.weight = 10;
      this.tagCloud.push(tc);
    }


    // this.pieChartData6.push(cnt6);
    const tier2wrds = (this.smartlist == undefined) ? 'That is true : 5>3' : this.smartlist.ExtractedConceptsTier2.trim().split(',');

    // const tier2wrds = this.smartlist.ExtractedConceptsTier2.replace(/,/g, '');
    const tier2array = tier2wrds;
    // console.log(tier2array);
    for (let x = 0; x < tier2array.length; x++) {

      tc = new TagCloud();
      tc.label = tier2array[x];
      tc.weight = 5;
      this.tagCloud.push(tc);

      // this.pieChartLabels.push(tc.label);

      // this.pieChartData6.push(cnt6);


    }
    // console.log( this.smartlist.Word2Vec);
    const tier3wrds = (this.smartlist == undefined) ? 'That is true : 5>3' : this.smartlist.Word2Vec.trim().split(',');
    // console.log(tier3wrds);
    // const tier3wrds = this.smartlist.Word2Vec.replace(/,/g, '');
    const tier3array = tier3wrds;
    // console.log(tier3array);
    for (let x = 0; x < tier3array.length; x++) {
      tc = new TagCloud();
      tc.label = tier3array[x];
      tc.weight = 1;
      this.tagCloud.push(tc);

      //  this.pieChartLabels.push(tc.label);

    }
    shuffle(this.tagCloud);
  }
  catflagmanager(item, j) {
    // console.log(aiName);

    if (item.aiTypeID === '2') {
      // this.insight0 = true;
      const numarrayp = item.aiLink[j].aiCategory;
      // console.log(numarrayp);
      this.catbarchartData0.push(numarrayp);
    } else if (item.aiTypeID === '3') {
      // this.insight1 = true;
      const numarrayp = item.aiLink[j].aiCategory;
      this.catbarchartData1.push(numarrayp);
    } else if (item.aiTypeID === '5') {
      // this.insight2 = true;
      const numarrayp = item.aiLink[j].aiCategory;
      this.catbarchartData2.push(numarrayp);
    } else if (item.aiTypeID === '8') {
      // this.insight3 = true;
      const numarrayp = item.aiLink[j].aiCategory;
      this.catbarchartData3.push(numarrayp);
    } else if (item.aiTypeID === '6') {
      // this.insight4 = true;
      const numarrayp = item.aiLink[j].aiCategory;
      this.catbarchartData4.push(numarrayp);
    } else if (item.aiTypeID === '7') {
      // this.insight5 = true;
      const numarrayp = item.aiLink[j].aiCategory;
      this.catbarchartData5.push(numarrayp);
    } else if (item.aiTypeID === '4') {
      // this.insight6 = true;
      const numarrayp = item.aiLink[j].aiCategory;
      this.catbarchartData6.push(numarrayp);
    } else if (item.aiTypeID === '9') {
      // this.insight7 = true;
      const numarrayp = item.aiLink[j].aiCategory;
      this.catbarchartData7.push(numarrayp);
    } else if (item.aiTypeID === '10') {
      // this.insight8 = true;
      const numarrayp = item.aiLink[j].aiCategory;
      this.catbarchartData8.push(numarrayp);
    }
  }

  flagmanager(item, j) {
    if (item.aiTypeID === '2') {
      this.insight0 = true;
      // console.log(item.aiLink[j].aiDateTemp.getFullYear());
      const numarrayp = filter_array(item.aiLink[j].aiConcepts.split(','));
      // console.log(numarrayp);
      this.barchartData0.push(numarrayp);
      this.yrbarchartData0.push(item.aiLink[j].aiDateTemp?.getFullYear());


    } else if (item.aiTypeID === '3') {
      this.insight1 = true;
      const numarrayp = filter_array(item.aiLink[j].aiConcepts.split(','));
      this.barchartData1.push(numarrayp);
    } else if (item.aiTypeID === '5') {
      this.insight2 = true;
      const numarrayp = filter_array(item.aiLink[j].aiConcepts.split(','));
      this.barchartData2.push(numarrayp);
    } else if (item.aiTypeID === '8') {
      this.insight3 = true;
      const numarrayp = filter_array(item.aiLink[j].aiConcepts.split(','));
      this.barchartData3.push(numarrayp);
    } else if (item.aiTypeID === '6') {
      this.insight4 = true;
      const numarrayp = filter_array(item.aiLink[j].aiConcepts.split(','));
      this.barchartData4.push(numarrayp);
    } else if (item.aiTypeID === '7') {
      this.insight5 = true;
      const numarrayp = filter_array(item.aiLink[j].aiConcepts.split(','));
      this.barchartData5.push(numarrayp);
    } else if (item.aiTypeID === '4') {
      this.insight6 = true;
      const numarrayp = filter_array(item.aiLink[j].aiConcepts.split(','));
      this.barchartData6.push(numarrayp);
    } else if (item.aiTypeID === '9') {
      this.insight7 = true;
      const numarrayp = filter_array(item.aiLink[j].aiConcepts.split(','));
      this.barchartData7.push(numarrayp);
    } else if (item.aiTypeID === '10') {
      this.insight8 = true;
      const numarrayp = filter_array(item.aiLink[j].aiConcepts.split(','));
      this.barchartData8.push(numarrayp);
    }
  }
  getbgclr(i) {
    let colorcode;
    if (i == 0) {
      colorcode = '#36a2eb';
    } else if (i == 1) {
      colorcode = '#2b6fb1';
    } else if (i == 2) {
      colorcode = '#4bc0c0';

    } else if (i == 3) {
      colorcode = '#ff9f40';

    } else if (i == 4) {
      colorcode = '#3F51B5';

    } else if (i == 5) {
      colorcode = '#2196F3';

    } else if (i == 6) {
      colorcode = '#03A9F4';

    } else if (i == 7) {
      colorcode = '#00BCD4';

    } else if (i == 8) {
      colorcode = '#009688';

    } else if (i == 9) {
      colorcode = '#4CAF50';

    } else if (i == 10) {
      colorcode = '#7CB342';

    } else {
      colorcode = '#FFEB3B';

    }
    return colorcode;
  }
  onTrendClick(event) {
    this.getLiked();
    this.getComments();
    this.getReports();
    this.trendDetails = [];
    this.trenddate = '';
    // console.log('Here');
    // console.log(event);
    if (event.active.length > 0) {
      const chart = event.active[0]._chart;
      const activePoints = chart.getElementAtEvent(event.event);

      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        // const clickedElementIndex1 = activePoints[0]._datasetIndex;

        // console.log('CEI'+clickedElementIndex);
        const label = chart.data.labels[clickedElementIndex];

        //  console.log('lab'+label);

        for (var i = 0; i < this.trendlist[0].aiTrendsValuePair[0].aiValues.length; i++) {
          if (this.trendlist[0].aiTrendsValuePair[0].aiValues[i].aiYear == label) {
            this.trenddate = this.trendlist[0].aiTrendsValuePair[0].aiValues[i].aiDate;
            this.trendcountry = this.trendlist[0].Country;
            this.trendyr = this.trendlist[0].aiTrendsValuePair[0].aiValues[i].aiYear;
            for (var j = 0; j < this.trendlist[0].aiTrendsValuePair[0].aiValues[i].aiLink.length; j++) {

              this.trendDetails.push(this.trendlist[0].aiTrendsValuePair[0].aiValues[i].aiLink[j]);


            }
          }
        }
        this.setLiked(this.trendDetails);
        // this.getComments();
        //   this.getReports();
        // console.log(this.existingcomments);
        // console.log(insArray);

        let aitypeVal;
        if (!this.desiagnApiCall) {

          aitypeVal = '1';
        } else {
          aitypeVal = '2';
        }
        this.notesView.get(`aiinsight/noteslist/${aitypeVal}/${this.project.id}`).subscribe(res => {
          //  console.log(res);
          this.existingcomments = res;
          for (let i = 0; i < this.trendDetails.length; i++) {
            this.trendDetails[i].notesId = null;
            this.trendDetails[i].noteAdd = null;

            for (let j = 0; j < this.existingcomments.length; j++) {

              if (this.trendDetails[i].aiId == this.existingcomments[j].aiId) {
                this.trendDetails[i].notesId = this.existingcomments[j].id;
                this.trendDetails[i].noteAdd = this.existingcomments[j].aiNotes;
              }

              // if (this.existingcomments.length === 0) {
              //   this.trendDetails[i].notesId = null;
              //   this.trendDetails[i].noteAdd = null;
              // }
              // if (this.trendDetails[i].aiId != this.existingcomments[j].aiId) {
              //   // console.log('match found');
              //   this.trendDetails[i].notesId = null;
              //   this.trendDetails[i].noteAdd = null;

              // }
            }
          }
          if (this.trendDetails[0].aiId.length !== 0) {

            const dialogRef = this.dialog.open(TrendDialogComponent, {
              data: { trendDetails: this.trendDetails, charttitle: this.charttitle, trendyr: this.trendyr, trenddate: this.trenddate },
            });
          } else {
            this.openSnackBar({
              type: 'simple',
              message: 'No Relation Found!',
              action: 'CLOSE',
              duration: 3000
            });
          }
        });
        // aiTotalEstimatedMatches
        // this.openModal('trends-modal');
        // console.log( this.trendDetails);
        //  console.log('TrendDialogComponent');




      }
    }

  }
  aiReportTrack(index, row) {
    return row.aiTypeID;
  }
  getLabel(view) {
    let label: string;
    if (view.value('productNewOrExisting') == 1) {
      label = 'Consumer Information';
    } else if (view.value('productNewOrExisting') == 2) {
      label = 'Competitors';
    }

    return label;

  }
  onChartClick(event, aitype, view) {

    if (event.active.length > 0) {
      const chart = event.active[0]._chart;
      const activePoints = chart.getElementAtEvent(event.event);
      // console.log(activePoints);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const clickedElementIndex1 = activePoints[0]._datasetIndex;
        // console.log(clickedElementIndex1);
        const label = chart.data.labels[clickedElementIndex];
        // const valuep = chart.data.datasets[0].data[activePoint._index];
        const labelin = chart.data.datasets[clickedElementIndex1].label;
        // const value = chart.data.datasets[clickedElementIndex].data[clickedElementIndex];
        // get value by index
        // const value = chart.data.datasets[0].data[clickedElementIndex];
        // console.log(labelin);
        // console.log(value);
        // console.log(clickedElementIndex, label, value)

        const list = new Array();
        // this.smartlistItemDetail = this.wishListAll.filter(u => u.aiName === aiName);
        // console.log(this.smartlistItem.length);
        for (var i = 0; i < this.smartlistItem.length; i++) {
          // console.log(this.smartlistItem[i].aiLink.length);
          for (var j = 0; j < this.smartlistItem[i].aiLink.length; j++) {
            if (labelin == 'NA') {
              // console.log(this.smartlistItem[i].aiLink[j]);
              if (this.smartlistItem[i].aiLink[j].aiHistogram.split(',').includes(label.trim()) && this.smartlistItem[i].aiLink[j].aiDate == '') {
                this.smartlistItem[i].aiLink[j].aiNameTemp = this.smartlistItem[i].aiName;

                this.smartlistItem[i].aiLink[j].aiNameIdTemp = this.smartlistItem[i].aiTypeID;

                // console.log('==' + this.smartlistItem[i].aiLink[j].aiNameTemp + '==');
                if (aitype === this.smartlistItem[i].aiLink[j].aiNameIdTemp) {
                  list.push(this.smartlistItem[i].aiLink[j]);
                  // console.log(this.smartlistItem[i].aiLink[j]);
                }
              }
            } else {
              //  console.log( this.smartlistItem[i]);
              if (
                this.smartlistItem[i].aiLink[j].aiDateTemp.getFullYear() == labelin &&
                this.smartlistItem[i].aiLink[j].aiHistogram.split(',').includes(label.trim())
              ) {

                this.smartlistItem[i].aiLink[j].aiNameTemp = this.smartlistItem[i].aiName;
                this.smartlistItem[i].aiLink[j].aiNameIdTemp = this.smartlistItem[i].aiTypeID;

                // console.log('==' + this.smartlistItem[i].aiLink[j].aiNameTemp + '==');
                if (aitype === this.smartlistItem[i].aiLink[j].aiNameIdTemp) {
                  list.push(this.smartlistItem[i].aiLink[j]);
                  // console.log(this.smartlistItem[i].aiLink[j]);
                }
              }
            }
          }
        }
        if (list.length > 0) {
          this.smartlistItemDetail = list;
          this.smartlistItemDetailtip = this.smartlistItemDetail;
          this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
          this.dashboard = false;
          this.listViewInsight = true;

          this.InsIQclickflg = false;
          this.cacheClear = false;
          this.chartReports = false;
          this.sentimentclickflg = false;
          this.averagePrice = false;

          this.sentimentSelected = '';

          this.partnerclickflg = false;
          this.inddeep = false;

          this.notesviewflag = false;
          this.reportviewflag = false;
          this.insightsearch = false;

          this.modeval = null;
          this.selCat = 0;
          this.setLiked(list);
          this.chartSel = aitype;
          this.smartTitle = this.config.catName(aitype, view.value('productNewOrExisting'), this.desiagnApiCall) + ' filtered by: ' + label + ' and ' + labelin;
          this.tagcloudclick = true;
          if (this.view.value('mode') == 'Turbo Mode') {
            this.tabNumber = 2;
          }
        } else {
          this.openSnackBar({
            type: 'simple',
            message: 'No Relation Found!',
            action: 'CLOSE',
            duration: 3000
          });
          // this.view.openSnackBar({
          //   type: 'custom',
          //   message: 'No Relation Found!',
          //   action: 'CLOSE',
          //   duration: 3000,
          //   component: SnackbarComponent
          // });
        }
      }
    }
  }
  onPieClick(event, type, view) {
    let aitype;
    switch (type) {
      case '0':
        aitype = '2';
        break;
      case '1':
        aitype = '3';
        break;
      case '2':
        aitype = '5';
        break;
      case '3':
        aitype = '8';
        break;
      case '4':
        aitype = '6';
        break;
      case '5':
        aitype = '7';
        break;
      case '6':
        aitype = '4';
        break;
      case '7':
        aitype = '9';
        break;
      case '8':
        aitype = '10';
        break;
      default:
        return '0';
        break;
    }
    const [tag, data] = event;
    // if (event.active.length > 0) {
    //   const chart = event.active[0]._chart;
    //   const activePoints = chart.getElementAtEvent(event.event);
    //   if (activePoints.length > 0) {
    //     // get the internal index of slice in pie chart
    //     const clickedElementIndex = activePoints[0]._index;
    //     const label = chart.data.labels[clickedElementIndex];
    //     // get value by index
    //     const value = chart.data.datasets[0].data[clickedElementIndex];
    // console.log(clickedElementIndex, label, value)

    const list = new Array();
    // this.smartlistItemDetail = this.wishListAll.filter(u => u.aiName === aiName);
    // console.log(this.smartlistItem.length);
    for (var i = 0; i < this.smartlistItem.length; i++) {
      //  console.log(this.smartlistItem[i].aiLink.length);
      for (var j = 0; j < this.smartlistItem[i].aiLink.length; j++) {

        // console.log(this.smartlistItem[i].aiLink[j].aiConcepts);
        if (this.smartlistItem[i].aiLink[j].aiCategory.includes(tag.label.trim())) {
          this.smartlistItem[i].aiLink[j].aiNameTemp = this.smartlistItem[i].aiName;
          this.smartlistItem[i].aiLink[j].aiNameIdTemp = this.smartlistItem[i].aiTypeID;


          //   console.log('==' + this.smartlistItem[i].aiLink[j].aiNameTemp + '==');
          if (aitype === this.smartlistItem[i].aiLink[j].aiNameIdTemp) {
            list.push(this.smartlistItem[i].aiLink[j]);
            // console.log(this.smartlistItem[i].aiLink[j]);
          }
        }
        //
      }
    }
    if (list.length > 0) {
      this.smartlistItemDetail = list;
      this.smartlistItemDetailtip = this.smartlistItemDetail;
      this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
      this.dashboard = false;
      this.listViewInsight = true;

      this.InsIQclickflg = false;
      this.cacheClear = false;
      this.chartReports = false;
      this.sentimentclickflg = false;
      this.averagePrice = false;

      this.sentimentSelected = '';

      this.partnerclickflg = false;
      this.inddeep = false;

      this.notesviewflag = false;
      this.reportviewflag = false;
      this.insightsearch = false;

      this.modeval = null;
      this.selCat = 0;
      this.setLiked(list);
      this.smartTitle = this.config.catName(aitype, view.value('productNewOrExisting'), this.desiagnApiCall) + ' based on Category: ' + tag.label;
      this.tagcloudclick = true;
    } else {
      this.openSnackBar({
        type: 'simple',
        message: 'No Relation Found!',
        action: 'CLOSE',
        duration: 3000
      });
      // this.view.openSnackBar({
      //   type: 'custom',
      //   message: 'No Relation Found!',
      //   action: 'CLOSE',
      //   duration: 3000,
      //   component: SnackbarComponent
      // });
    }
    //      }
    //    }
  }
  getDashTag(smartlistItem, dc) {
    // console.log('test');
    let tc: TagCloud;

    //  let catpieChartData: SingleDataSet =  tempIntArr;

    let catpieChartData = [];

    const tier1wrds = smartlistItem?.extractedConceptsTier1.trim().split(',');
    const tier1array = tier1wrds;
    for (let x = 0; x < tier1array.length; x++) {
      tc = new TagCloud();
      tc.label = tier1array[x];
      tc.weight = 4;
      catpieChartData.push(tc);
    }

    const tier2wrds = (this.smartlist == undefined) ? 'That is true : 5>3' : smartlistItem?.extractedConceptsTier2?.trim().split(',');
    const tier2array = tier2wrds;
    for (let x = 0; x < tier2array?.length; x++) {
      tc = new TagCloud();
      tc.label = tier2array[x];
      tc.weight = 2;
      catpieChartData.push(tc);
    }
    // console.log( this.smartlist.Word2Vec);
    const tier3wrds = (this.smartlist == undefined) ? 'That is true : 5>3' : smartlistItem.word2Vec.trim().split(',');
    const tier3array = tier3wrds;
    for (let x = 0; x < tier3array.length; x++) {
      tc = new TagCloud();
      tc.label = tier3array[x];
      tc.weight = 1;
      catpieChartData.push(tc);
    }
    shuffle(catpieChartData);
    dc.tagCloud = catpieChartData;
  }


  public dashCharts: DashCharts[] = [];
  buildChart() {
    // console.log('BUILDDD CHARTTTT');
    this.dashCharts = [];

    for (var j = 0; j < this.smartlist.aiInsightsLink.length; j++) {
      let dc = new DashCharts();
      let item = this.smartlist.aiInsightsLink[j];
      //  console.log();
      dc.aiName = item.aiName;
      dc.aiTypeID = item.aiTypeID;
      dc.reportId = item.reportId;
      dc.note = item.note;
      this.getDashboardCharts(j, item, dc);
      //console.log(item);
      this.getDashTag(item, dc);
      // console.log(dc.tagCloud);
      this.dashCharts.push(dc);
    }

  }
  insightcatflag() {
    return !this.showWish && !this.notesviewflag && !this.reportviewflag && !this.insightsearch;
    //return true;
  }
  setaiList() {
    // console.log(aiName);
    //  console.log(this.smartlistItem);
  }
  savedinsightcatflag(aiName) {
    console.log(aiName);
    console.log(this.ailist.length);
    if (this.ailist.length != 0) {
      for (let i = 0; i < this.ailist?.length; i++) {
        console.log(this?.ailist[i]);
        if (this.ailist[i]?.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        })
          ?.includes(aiName)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;

    }
  }
  savedinsightcatflag1() {
    return false;
  }

  // public chartLabel = new Array();
  // public chartData = new Array();
  getDashboardCharts(index, smartlistItem, dc) {
    // console.log(index);

    let pieChartLabels: Label[] = tempStrArray;

    let yrsortelement0 = [];
    let yrbarchartData0 = [];
    let yrmainsortelement0 = [];
    let mainsortelement0 = [];
    let barchartData = [];
    let sortelement0 = [];
    pieChartLabels = [];
    for (var j = 0; j < smartlistItem.aiGraphData.length; j++) {
      const numarrayp = filter_array(smartlistItem.aiGraphData[j].aiHistogram.split(','));
      barchartData.push(numarrayp);
    }

    const tier1wrds = (this.smartlist == undefined) ? 'That is true : 5>3' : this.smartlist.ExtractedConceptsTier1?.replace(/,/g, '');
    const tier1array = tier1wrds?.trim()?.split(' ');
    // console.log(tier1array);
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    // CHART 0
    var myNewArray = [].concat.apply([], barchartData);
    var uniques = myNewArray.filter(onlyUnique);

    yrsortelement0.push(yrbarchartData0.filter(onlyUnique));
    yrmainsortelement0.push(yrbarchartData0);
    sortelement0.push(uniques);
    mainsortelement0.push(myNewArray);

    var dcnt = 0;
    if (sortelement0[0].length === 0) {
      // insight0 = false;
    }
    for (var j = 0; j < mainsortelement0.length; j++) {
      var tp;
      if (sortelement0[0].length > 15) {
        tp = 15;
      } else {
        tp = sortelement0[0].length;
      }
      for (var i = 0; i < tp; i++) {
        var search = sortelement0[0];
        var search1 = search[i];
        if (mainsortelement0[j].includes(search1)) {
          dcnt++;
        }
        var count = mainsortelement0[j].reduce(function (n, val) {
          return n + (val === search1);
        }, 0);
        const cntpercent = Number((count / sortelement0[0].length) * 100).toFixed(0);
        pieChartLabels.push(search1);
        // this.pieChartData.push(count);
      }
    }



    let datacnt: number[];
    let stackArray: { data: number[], label: string, stack: string }[] = [
    ];
    // let stackChartData = [];
    const stackChartData: ChartDataSets[] = [];

    for (var i = 0; i < smartlistItem?.aiGraphData?.length; i++) {

      const words = smartlistItem?.aiGraphData[i].aiHistogram.split(',');
      datacnt = [];
      stackArray = [];

      // dataArray
      for (var j = 0; j < pieChartLabels.length; j++) {
        var dcnt = 0;
        for (var k = 0; k < words.length; k++) {
          if (pieChartLabels[j] == words[k]) {
            dcnt++;
          }
        }
        datacnt.push(dcnt);
      }

      const obj: {
        data: number[],
        label: string,
        stack: string,
        backgroundColor: string,
        hoverBackgroundColor: string,
        hoverBorderWidth: number
      } = {
        data: datacnt,
        label: smartlistItem.aiGraphData[i]?.aiYear,
        stack: 'a',
        backgroundColor: getbgclr(i),
        hoverBackgroundColor: getbgclr(i),
        hoverBorderWidth: 0
      };

      stackChartData.push(obj);
    }
    // console.log(this.chartLabel);
    dc.chartLabel = pieChartLabels;
    dc.chartData = stackChartData;
    dc.chartData = this.updateBarChartColors(dc.chartData);
    // this.chartLabel.push(pieChartLabels);
    // this.chartData.push(stackChartData);
    // smartlistItem['pieChartLabels']=pieChartLabels;
    // smartlistItem['stackChartData']=stackChartData;
    //  console.log(  this.chartLabel[index]);

    return;

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    function getbgclr(i) {
      let colorcode;
      if (i === 0) {
        colorcode = '#2B6FB1';
      } else if (i === 1) {
        colorcode = '#6A9CFF';
      } else if (i === 2) {
        colorcode = '#D3E6FF';

      } else if (i === 3) {
        colorcode = '#0476D0';

      } else if (i === 4) {
        colorcode = '#65ACF0';

      } else if (i === 5) {
        colorcode = '#2692D0';

      } else if (i === 6) {
        colorcode = '#82A4E3';
      } else if (i === 7) {
        colorcode = '#9ED3F8';

      } else if (i === 8) {
        colorcode = '#00988';

      } else if (i === 9) {
        colorcode = '#97B1DF';

      } else if (i === 10) {
        colorcode = '#4C70C8';

      } else {
        colorcode = '#6BCAE2';

      }
      return colorcode;
    }
  }
  updateBarChartColors(chartData: any[]): any[] {
    const shadesOfBlue = [
      '#2B6FB1',
      '#6A9CFF',
      '#D3E6FF',
      '#0476D0',
      '#65ACF0',
      '#2692D0',
      '#82A4E3',
      '#9ED3F8',
      '#97B1DF',
      '#4C70C8',
      '#6BCAE2'
      // Add more shades of blue color codes here
    ];

    return chartData.map((dataset, index) => {
      dataset.backgroundColor = shadesOfBlue[index % shadesOfBlue.length];
      return dataset;
    });
  }


  chooseProject(project) {
    localStorage.setItem('projRouted', JSON.stringify(project));
    localStorage.removeItem('projLoc');

    // window.location.href = '/project';
    // window.location.href = '/project';
    this.navigationService.triggerOnInit();
    this.router.navigate(['/']);
  }
  testprint() {
    // console.log('testprint()');
  }
  wishCategorymanger(aiName) {
    // if (aiName === this.catName('31', this.view)) {
    //   aiName = 'Consumer Information';
    // }
    // if (aiName === this.catName('4', this.view)) {
    //   aiName = 'Patents and Trademarks';
    // }
    // if (aiName === this.catName('7', this.view)) {
    //   aiName = 'Product Safety';
    // }
    if (aiName === this.catName('8', this.view)) {
      aiName = 'Influencers, SME’s and related';
    }

    if (aiName === this.catName('11', this.view)) {
      aiName = 'Scientific Research & Tech';
    }

    if (aiName === this.catName('4', this.view)) {
      aiName = 'Patents and Applications';
    }
    // if (aiName === this.catName('11', this.view)) {
    //   aiName = 'Scientific Research';
    // }
    // if (aiName === this.catName('5', this.view)) {
    //   aiName = 'Supply Chain';
    // }
    let tempArray = [];
    let tempArray1 = [];

    // console.log(this.smartreportlist);
    if (this.reportviewflag) {
      tempArray = this.smartreportlist?.filter(u => u.aiName === aiName);

    } else {
      tempArray = this.wishListAll?.filter(u => u.aiName === aiName);
    }
    //console.log(tempArray);

    //console.log(tempArray?.length);
    if (tempArray?.length == 0) {
      if (this.reportviewflag) {

        tempArray1 = this.insightsSummary?.filter(u => u.fl == this.config.catId(aiName));
        //console.log(tempArray1)
        if (tempArray1?.length == 0) {
          return true;

        } else {
          return false;

        }
      } else {
        return true;

      }
    } else {
      return false;
    }
  }
  filterWish(aiName) {
    // if (aiName === this.catName('31', this.view)) {
    //   aiName = 'Consumer Information';
    // }
    // if (aiName === this.catName('4', this.view)) {
    //   aiName = 'Patents and Trademarks';
    // }
    // if (aiName === this.catName('7', this.view)) {
    //   aiName = 'Product Safety';
    // }
    // if (aiName === this.catName('8', this.view)) {
    //   aiName = 'Professionals';
    // }
    // if (aiName === this.catName('11', this.view)) {
    //   aiName = 'Scientific Research';
    // }
    // if (aiName === this.catName('5', this.view)) {
    //   aiName = 'Supply Chain';
    // }
    // console.log(aiName);
    //  Average Price
    if (aiName === this.catName('8', this.view)) {
      aiName = 'Influencers, SME’s and related';
    }
    if (aiName === this.catName('11', this.view)) {
      aiName = 'Scientific Research & Tech';
    }
    if (aiName === this.catName('4', this.view)) {
      aiName = 'Patents and Applications';
    }
    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.averagePrice = false;

    this.sentimentSelected = '';

    this.partnerclickflg = false;
    this.inddeep = false;
    /// console.log('hereeeeeeeeee');
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.smartTitle = aiName;
    this.modeval = null;
    if (this.reportviewflag) {
      if (aiName === '') {
        this.smartTitle = 'All';
        this.smartlistItemDetail = this.smartreportlist;
        this.smartlistItemDetailtip = this.smartlistItemDetail;
        this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);
        this.allcatflag = true;
      } else {
        this.smartlistItemDetail = this.smartreportlist?.filter(u => u.aiName === aiName);
        this.smartlistItemDetailtip = this.smartlistItemDetail;
        this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);
        this.allcatflag = false;
      }
    }
    else {
      //  console.log(this.wishListAll);
      if (aiName === '') {
        this.smartTitle = 'All';
        this.smartlistItemDetail = this.wishListAll;
        this.smartlistItemDetailtip = this.smartlistItemDetail;
        this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);
        this.allcatflag = true;
      } else {
        this.smartlistItemDetail = this.wishListAll?.filter(u => u.aiName === aiName);
        this.smartlistItemDetailtip = this.smartlistItemDetail;
        this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);
        this.allcatflag = false;
      }
    }
  }
  // filterWish(aiName) {
  //   this.InsIQclickflg = false;
  //   this.partnerclickflg = false;
  //   this.inddeep = false;
  //   /// console.log('hereeeeeeeeee');
  //   this.pagemin = 0;
  //   this.pagemax = this.pageSize;
  //   this.pageno = this.pageSize;
  //   this.smartTitle = aiName;
  //   if (aiName === '') {
  //     this.smartTitle = 'All';
  //     this.smartlistItemDetail = this.wishListAll;
  //     this.smartlistItemDetailtip = this.smartlistItemDetail;
  //     this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
  //   } else {
  //     this.smartlistItemDetail = this.wishListAll.filter(u => u.aiName === aiName);
  //     this.smartlistItemDetailtip = this.smartlistItemDetail;
  //     this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
  //   }
  //  }
  setHasRecord() {
    if (this.smartlistItemDetail && this.smartlistItemDetail !== null && this.smartlistItemDetail.length > 0) {
      this.hasRecord = 'true';
    } else {

      this.hasRecord = 'false';
    }
  }
  prevclick() {
    // console.log('prev click');
    this.pagemax = this.pageno - this.pageSize;
    this.pagemin = this.pagemax - this.pageSize;
    this.pageno = this.pageno - this.pageSize;
    this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
  }
  nextclick() {
    // console.log('nxt click');
    this.pagemin = this.pagemax;
    this.pagemax = this.pageno + this.pageSize;
    this.pageno = this.pageno + this.pageSize;
    this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);

  }

  searchPrevclick() {
    // console.log('prev click');
    this.searchPagemax = this.searchPageno - this.searchPageSize;
    this.searchPagemin = this.searchPagemax - this.searchPageSize;
    this.searchPageno = this.searchPageno - this.searchPageSize;
    this.searchSmartlistItemDetailpaginator = this.searchinsightDetails.slice(this.searchPagemin, this.searchPagemax);
  }
  searchNextclick() {
    // console.log('nxt click');
    this.searchPagemin = this.searchPagemax;
    this.searchPagemax = this.searchPageno + this.searchPageSize;
    this.searchPageno = this.searchPageno + this.searchPageSize;
    this.searchSmartlistItemDetailpaginator = this.searchinsightDetails.slice(this.searchPagemin, this.searchPagemax);

  }

  catfilter() {
    const cataray = [];
    //  cataray.push('All');
    let check = 0;
    try {
      for (let i = 0; i < this.smartlistItemDetailtip?.length; i++) {
        if (this.smartlistItemDetailtip[i]?.aiTitle?.includes('InsIQ Curated')) {
          check = 1;
        }
        cataray.push(this.smartlistItemDetailtip[i]?.aiCategory);
      }
      if (check == 1) {
        cataray.push('InsIQ Curated');
      }
      const unique = cataray.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      // console.log(filter_array(unique).length);
      return filter_array(unique);
    } catch (error) {
      console.error(error);
    }

  }
  saveKeyword() {
    // console.log('heree', this.keywordsel);
    //  this.view.set('id', this.ssp.pro.view.value('id'));
    switch (this.keywordsel) {
      case 199:


        this.view.set('productDescription', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('productDescription', this.selectedKeywordSuggestion.toString());
        break;
      case 31:
        this.view.set('keyword31', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword31', this.selectedKeywordSuggestion.toString());
        break;

      case 6:
        this.view.set('keyword6', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword6', this.selectedKeywordSuggestion.toString());
        break;
      case 10:
        this.view.set('keyword10', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword10', this.selectedKeywordSuggestion.toString());
        break;
      case 2:
        this.view.set('keyword2', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword2', this.selectedKeywordSuggestion.toString());
        break;
      case 32:
        this.view.set('keyword32', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword32', this.selectedKeywordSuggestion.toString());
        break;
      case 8:
        this.view.set('keyword8', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword8', this.selectedKeywordSuggestion.toString());
        break;
      case 11:
        this.view.set('keyword11', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword11', this.selectedKeywordSuggestion.toString());
        break;
      case 7:
        this.view.set('keyword7', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword7', this.selectedKeywordSuggestion.toString());
        break;
      case 5:
        this.view.set('keyword5', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword5', this.selectedKeywordSuggestion.toString());
        break;
      case 4:
        this.view.set('keyword4', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword4', this.selectedKeywordSuggestion.toString());
        break;
      case 9:
        this.view.set('keyword9', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('keyword9', this.selectedKeywordSuggestion.toString());
        break;

      default:


        this.view.set('productDescription', this.selectedKeywordSuggestion.toString());

        this.ssp.pro.view.set('productDescription', this.selectedKeywordSuggestion.toString());
    }
    // if (this.keywordSaveFlag && (this.sentimentResp.length != 0 || this.smartlistItem != null)) {
    //   // this.keywordSaveFlag=true;
    //   if (
    //     (this.project.productName?.trim() !== this.view.value('productName')?.toString()?.trim()) ||
    //     (this.project.productDescription !== this.view.value('productDescription')?.toString()) ||
    //     (this.project.brandName?.trim() !== this.view.value('brandName')?.toString()?.trim()) ||
    //     (this.project.productTypeId?.title?.trim() !== this.view.value('productTypeId')?.title?.trim())
    //   ) {

    //     this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
    //       data: {
    //         title: 'Project Update Found',
    //         message: 'You have made changes to the project input, Please use ‘Clear Insights’ and re-run to get the most updated results.',
    //         cancelbtn: 'PROCEED WITHOUT CLEARING',
    //         okbtn: 'CLEAR INSIGHTS AND PROCEED'
    //       },
    //     });
    //     this.warningDialogRef.afterClosed().subscribe(result => {
    //       if (result?.data === 'confirm') {
    //         this.clearInsights = true;

    //         this.dashCharts = [];
    //         // this.showDashboard();
    //         this.clearCache1(this.view, '0', this.smartlistItem);
    //         this.project.productTypeId = this.view.value('productTypeId');
    //         if (this.sentimentResp.length != 0) {
    //           this.clearInsights = true;

    //           this.dashCharts = [];
    //           //   this.showDashboard();
    //           this.clearCache1(this.view, '99', this.smartlistItem);
    //           this.project.productTypeId = this.view.value('productTypeId');
    //         }
    //         this.commonFlag();
    //         // if (this.val(this.project.productNewOrExisting) == 1) {
    //         //   this.blogStatus = false;
    //         // } else if (this.val(this.project.productNewOrExisting) == 2) {
    //         this.blogStatus = false;
    //         if (this.val(this.project.competitors) == '' || this.val(this.project.competitorspdt) == '') {
    //           this.compStatus = true;
    //           this.compStatusDate = true;
    //         }    //         else {
    //           this.compStatus = false;
    //         }
    //         this.commonKeysave();

    //         this.smartSave('0009');
    //         this.showDashboard();


    //       } else if (result?.data === 'close') {
    //         this.commonFlag();
    //         // if (this.val(this.project.productNewOrExisting) == 1) {
    //         //   this.blogStatusDate = false;
    //         // } else if (this.val(this.project.productNewOrExisting) == 2) {
    //         this.blogStatusDate = false;
    //         this.compStatusDate = false;
    //         this.commonKeysave();
    //         this.smartSave('0009');


    //       }
    //     });



    //   }    //   else {
    //     this.commonKeysave();
    //   }
    // }
    // else {
    //   //this.keywordSaveFlag=false;

    //   this.commonKeysave();
    // }


    // // this.smartSave('0009');

  }
  openPopup(imageUrl: string): void {
    this.selectedImageUrl = imageUrl;
    this.showPopup = true;
  }
  openVideoPopup(imageUrl: string): void {
    this.selectedVideoUrl = imageUrl;
    this.showVideoPopup = true;
  }

  closePopup(): void {
    this.showPopup = false;
  }
  closeVideoPopup(): void {
    this.showVideoPopup = false;
  }
  exportToExcel(projectId: number, aiTypeId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Add any other headers as needed
    });
    let fl;
    if (this.flSelected == 199) {
      //console.log(this.smartTitle.split(':')[0])
      fl = this.chartSel;
    } else {
      fl = this.flSelected;
    }
    this.http.get(`${Config.apiUrl}/aiinsights/exportexcel/${this.config.currentUser.id}/${this.project.id}/${fl}`, {
      headers,
      responseType: 'arraybuffer',
      observe: 'response', // to get full response including headers
    })
      .subscribe(
        (response) => {
          const contentDispositionHeader = response.headers.get('Content-Disposition');
          const fileNameMatch = contentDispositionHeader && contentDispositionHeader.match(/filename="(.+)"/);

          if (fileNameMatch && fileNameMatch.length === 2) {
            const fileName = fileNameMatch[1];
            const blob = new Blob([response.body], { type: 'application/octet-stream' });

            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error('Invalid or missing Content-Disposition header.');
          }
        },
        (error) => {
          console.error('Error in export request:', error);
        }
      );
  }

  commonKeysave() {
    this.view.set('productName', this.ssp.pro.view.value('productName'));

    this.view.set('brandName', this.ssp.pro.view.value('brandName'));
    this.view.set('apiLanguage', this.ssp.pro.view.value('apiLanguage'));
    this.view.set('problemSolve', this.ssp.pro.view.value('problemSolve'));
    this.view.set('productEnviornment', this.ssp.pro.view.value('productEnviornment'));
    this.view.set('productTypeId', this.ssp.pro.view.value('productTypeId'));


    this.view.set('keyword31', this.ssp.pro.view.value('keyword31'));
    this.view.set('keyword6', this.ssp.pro.view.value('keyword6'));
    this.view.set('keyword10', this.ssp.pro.view.value('keyword10'));
    this.view.set('keyword2', this.ssp.pro.view.value('keyword2'));
    this.view.set('keyword32', this.ssp.pro.view.value('keyword32'));
    this.view.set('keyword8', this.ssp.pro.view.value('keyword8'));
    this.view.set('keyword11', this.ssp.pro.view.value('keyword11'));
    this.view.set('keyword7', this.ssp.pro.view.value('keyword7'));
    this.view.set('keyword5', this.ssp.pro.view.value('keyword5'));
    this.view.set('keyword4', this.ssp.pro.view.value('keyword4'));
    this.view.set('keyword9', this.ssp.pro.view.value('keyword9'));

    this.projView.set('productDescription', this.ssp.pro.view.value('productDescription'));


    this.projView.set('keyword31', this.ssp.pro.view.value('keyword31'));
    this.projView.set('keyword6', this.ssp.pro.view.value('keyword6'));
    this.projView.set('keyword10', this.ssp.pro.view.value('keyword10'));
    this.projView.set('keyword2', this.ssp.pro.view.value('keyword2'));
    this.projView.set('keyword32', this.ssp.pro.view.value('keyword32'));
    this.projView.set('keyword8', this.ssp.pro.view.value('keyword8'));
    this.projView.set('keyword11', this.ssp.pro.view.value('keyword11'));
    this.projView.set('keyword7', this.ssp.pro.view.value('keyword7'));
    this.projView.set('keyword5', this.ssp.pro.view.value('keyword5'));
    this.projView.set('keyword4', this.ssp.pro.view.value('keyword4'));
    this.projView.set('keyword9', this.ssp.pro.view.value('keyword9'));
    this.project = this.view.form.value;
    // console.log('testtt', this.ssp.pro.view.value('productName'));
    // this.project?.productDescription = this.selectedKeywordSuggestion.toString();
    this.ssp.pro.view.set('productDescription', this.selectedKeywordSuggestion.toString());
    this.ssp.pro.refreshUserDataTable();
    //
  }
  selectkeyword(row, i) {
    //console.log(row);
    this.selectedKeywordSuggestion.push(row);

    let index = this.smartKeywordSuggestion.findIndex(d => d === row); //find index in your array
    this.smartKeywordSuggestion.splice(index, 1);
    // console.log(this.selectedKeywordSuggestion);
  }
  removekeyword(row, i) {
    // console.log(row);
    this.smartKeywordSuggestion.push(row);

    let index = this.selectedKeywordSuggestion.findIndex(d => d === row); //find index in your array
    this.selectedKeywordSuggestion.splice(index, 1);
    //console.log(this.selectedKeywordSuggestion);
  }
  categoryInsight(row, i) {
    //console.log(this.selectedSort);
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.modeval = null;
    this.selCat = i;
    // this.smartTitle = aiName;
    if (row === 'All') {
      this.smartlistItemDetail = this.smartlistItemDetailtip;
      this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);
    } else if (row === 'InsIQ Curated') {
      this.smartlistItemDetail = this.smartlistItemDetailtip;
      this.smartlistItemDetail = this.smartlistItemDetail?.filter(u => u.aiTitle.includes(row));
      this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);
    } else {
      this.smartlistItemDetail = this.smartlistItemDetailtip;
      this.smartlistItemDetail = this.smartlistItemDetail?.filter(u => u.aiCategory === row);
      this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);
    }
    this.sortChangeClick(this.selectedSort);
  }
  toggleCategorySelection(index: number): void {
    // console.log('hereee')
    this.allSelected = false;

    this.selectedCategories[index] = !this.selectedCategories[index];


  }

  toggleAllSelection(): void {
    this.allSelected = true;
    // Toggle the selection of all checkboxes based on the "All" checkbox
    for (let i = 0; i < this.selectedCategories?.length; i++) {
      this.selectedCategories[i] = false;

    }
    //this.selectedCategories.fill(this.allSelected);
  }



  onMenuClosed(): void {
    if (this.menuClosedFlag) {
      // Reset the flag
      this.menuClosedFlag = true;
    } else {
      // If the flag is not set, close the menu
      this.menuClosedFlag = true;
    }
  }
  applyFilter(): void {
    this.menuClosedFlag = false;
    this.filterMenuOpen = false;
    const selectedCategoryIndices = this.selectedCategories
      .map((value, index) => value ? index : -1)
      .filter(index => index !== -1);

    if (selectedCategoryIndices.length === 0) {
      // No categories selected, show all data
      this.smartlistItemDetail = this.smartlistItemDetailtip;
    } else if (selectedCategoryIndices.includes(0)) {
      // 'All' is selected, show all data
      this.smartlistItemDetail = this.smartlistItemDetailtip;
    } else {
      // Filter data based on selected category indices
      this.smartlistItemDetail = this.smartlistItemDetailtip.filter((item, index) =>
        selectedCategoryIndices.includes(index)
      );
    }

    this.sortChangeClick(this.selectedSort);
    this.pageInitiator();
  }


  clearFilter(): void {
    this.allSelected = true;

    this.filterMenuOpen = false;
    this.menuClosedFlag = false;
    // Reset selectedCategories array to clear all checkboxes
    this.selectedCategories.fill(false);

    // Reset data to show all items
    this.smartlistItemDetail = this.smartlistItemDetailtip;
    this.sortChangeClick(this.selectedSort);
    this.pageInitiator();
  }


  dateSorter(typ) {
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;

    this.sortBy.date.new = false;
    this.sortBy.date.old = false;

    if (typ === 1) {
      this.sortBy.date.new = true;
      this.smartlistItemDetail = this.smartlistItemDetail.sort((a, b) => b.aiDateTemp - a.aiDateTemp);
      this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
    }
    if (typ === 0) {
      this.sortBy.date.old = true;
      this.smartlistItemDetail = this.smartlistItemDetail.sort((a, b) => a.aiDateTemp - b.aiDateTemp);
      this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
    }
  }
  deepdive(view: View, e: any, p: Project = null, aitype = '1') {
    // console.log(e.checked);
    if (e.checked) {
      this.deepdiveflag = true;
      this.aiidset = '';
      // console.log('Deep Dive');
      this.progress = 0;
      this.config.setLoaderPercentage(this.progress);
      Config.loading = true;
      view.get(`aiinsight/favoritelist/${aitype}/${this.project.id}`).subscribe(res => {
        Config.loading = false;
        this.wishListAll = res;
        // console.log(this.wishListAll.length);
        if (this.wishListAll.length === 0) {
          this.openSnackBar({
            type: 'simple',
            message: 'No Favourite Insights Available.Please like your Favourite Insights to Deep Dive',
            action: 'CLOSE',
            duration: 3000
          });
          this.deepdiveflag = false;
          this.closeModal('custom-modal-2');
        } else {
          this.aiidset = this.aiidset + this.wishListAll[0].aiId;
          for (let i = 1; i < this.wishListAll.length; i++) {
            this.aiidset = this.aiidset + ',' + this.wishListAll[i].aiId;
          }
        }
      });
    } else {
      this.deepdiveflag = false;
      this.aiidset = '';
    }
  }

  insightDeep(aiId, fl) {
    if (this.featureChecker(14)) {
      this.deepcat = fl;
      this.deepdivelist = null;
      this.deepdiveDetails = null;
      //  this.searchinsightDetails = null;
      this.cacheClear = false;
      this.progress = 0;
      this.config.setLoaderPercentage(this.progress);
      Config.setLoaderImage('./assets/images/insiq_ai_insights.gif');
      Config.setLoaderMessage('InsIQ is finding the perfect insights for you, this can take a few seconds');
      this.config.setProgressVisibility(true);

      Config.loading = true;

      const DEEPDIVEURL = `deepdive` +
        `?pid=${this.project.id}` +
        `&lang=${encodeURIComponent(this.project.apiLanguage)}` +
        `&aiid=${aiId}` +
        `&fl=${fl}`;
      let flag;
      const querryUrlParams = DEEPDIVEURL.split('?');

      if (location.host.includes(Config.MAINPROD_URL)) {
        flag = 1;
      } else {
        flag = 0;
      }
      this.progressManager();

      this.view.get(`aiinsights/deepdiveapi/${flag}/${querryUrlParams[1]}`).subscribe(res => {
        Config.loading = false;
        this.config.apiUsage();

        this.deepdivelist = res;
        this.setDeepDive();
        // this.setHasRecord();
      });
    } else {
      this.featurealert('Deep Dive');
    }
    // this.dashboard = false;
    // this.tagcloudclick = false;
    // this.notesadd = false;
    // this.InsIQclickflg = false;
    // this.partnerclickflg = false;
    // this.inddeep = true;
    // this.notesviewflag = false;
  }

  featurealert(feature) {
    // this.openSnackBar({
    //   type: 'simple',
    //   duration: 3000,
    //   action: 'CLOSE',
    //   message: feature + 'Feature Not Available in your current plan. Please upgrade your Plan.'
    // });

    UpgradePlanComponent.prototype.title = `Upgrade your plan to get this feature`;
    UpgradePlanComponent.prototype.message = `<strong>${feature}</strong> feature is not available in your current plan. Please upgrade your Plan!`;

    this.dialog.open(CommonDialogComponent, {
      width: '600px',
      maxWidth: '98vw',
      minWidth: '320px',
      maxHeight: '98vh',
      panelClass: 'InsIQDialog',
      backdropClass: 'InsIQOverlay',
      data: {
        title: `Upgrade Plan`,
        component: UpgradePlanComponent
      }
    });
  }
  getReportStatus(aiCode) {
    let i;
    if (this.chartreportinsights.length === 0) {
      return false;
    } else {
      for (i = 0; i < this.chartreportinsights.length; i++) {
        if (this.chartreportinsights[i].aiCode = aiCode) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  getReportStatus1(aiCode) {
    let i;
    if (this.chartreportinsights.length === 0) {
      return false;
    } else {
      for (i = 0; i < this.chartreportinsights.length; i++) {

        if (this.chartreportinsights[i].aiCode = aiCode) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  typeOf(value) {
    return typeof value;
  }

  setDeepDive() {
    this.inddeep = true;
    if (this.deepdivelist[0].NumberOfResults === 0) {
      this.openSnackBar({
        type: 'simple',
        duration: 3000,
        action: 'CLOSE',
        message: 'No Deep Dive Insights Available. Please select another Insight and try again.'
      });
      this.inddeep = false;
    } else {
      const dummyarray = [];
      const insArray = [];

      for (let j = 0; j < this.deepdivelist[0].aiInsightsLink.length; j++) {
        for (let k = 0; k < this.deepdivelist[0].aiInsightsLink[j].aiLink.length; k++) {
          this.deepdivelist[0].aiInsightsLink[j].aiLink[k].aiName = this.deepdivelist[0].aiInsightsLink[j].aiName;
        }
      }

      for (let j = 0; j < this.deepdivelist[0].aiInsightsLink.length; j++) {
        dummyarray.push(this.deepdivelist[0].aiInsightsLink[j].aiLink);
      }

      for (let k = 0; k < dummyarray.length; k++) {
        for (let p = 0; p < dummyarray[k].length; p++) {
          insArray.push(dummyarray[k][p]);
        }
      }
      // this.getComments();
      //  this.getReports();

      let aitypeVal;
      if (!this.desiagnApiCall) {

        aitypeVal = '1';
      } else {
        aitypeVal = '2';
      }
      this.notesView.get(`aiinsight/noteslist/${aitypeVal}/${this.project.id}`).subscribe(res => {
        //   console.log(res);
        this.existingcomments = res;
        for (let i = 0; i < insArray.length; i++) {
          if (this.existingcomments.length === 0) {
            insArray[i].notesId = null;
            insArray[i].noteAdd = null;
          }
          for (let j = 0; j < this.existingcomments.length; j++) {


            if (insArray[i].aiId == this.existingcomments[j].aiId) {
              // console.log('match found');
              insArray[i].notesId = this.existingcomments[j].id;
              insArray[i].noteAdd = this.existingcomments[j].aiNotes;
              break;
            }
            if (insArray[i].aiId != this.existingcomments[j].aiId) {
              // console.log('match found');
              insArray[i].notesId = null;
              insArray[i].noteAdd = null;

            }
          }
        }
      });

      this.view.get(`aiinsight/favoritelist/${aitypeVal}/${this.project.id}`).subscribe(res => {
        this.likes = res; for (let i = 0; i < insArray.length; i++) {
          if (this.likes.length === 0) {
            insArray[i].liked = '';
          }
          for (let j = 0; j < this.likes.length; j++) {

            // console.log(this.likes);
            // console.log('likeai',insArray[i].aiId);
            // console.log('like ins',this.likes[j].aiId);
            if (insArray[i].aiId == this.likes[j].aiId) {
              // console.log('match found');
              insArray[i].liked = '1';
              break;
            }
            if (insArray[i].aiId != this.likes[j].aiId) {
              // console.log('match else found');
              insArray[i].liked = '';

            }
          }
        }
      });



      this.notesView.get(`aiinsight/reportlistlist/${aitypeVal}/${this.project.id}`).subscribe(res => {

        this.reportinsights = res; for (let i = 0; i < insArray.length; i++) {
          if (this.reportinsights.length === 0) {
            insArray[i].report = '';
          }
          for (let j = 0; j < this.reportinsights.length; j++) {


            if (insArray[i].aiId == this.reportinsights[j].aiId) {
              // console.log('match found');
              insArray[i].report = '1';
              break;
              //  insArray[i].noteAdd = this.reportinsights[j].aiNotes;
            }
            if (insArray[i].aiId != this.reportinsights[j].aiId) {
              // console.log('match found');
              insArray[i].report = '';
              //  insArray[i].noteAdd = this.reportinsights[j].aiNotes;
            }
          }
        }
      });


      this.deepdiveDetails = insArray;

      // console.log(this.deepdiveDetails);
      // this.smartlistItemDetail = insArray;
      // this.smartlistItemDetailtip = this.smartlistItemDetail;
      // this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
      //  this.smartTitle = 'Insights based on: Deep Dive ';
      // this.setHighLight(this.smartlistItemDetail, false, (this.existingcomments && this.existingcomments.length > 0));
      // this.openModal('custom-modal-2');
      // this.deepDiveDialogRef.close();
      if (this.deepDiveDialogRef !== null) {
        // console.log('Insight DeepdiveDialogComponent');
        this.deepDiveDialogRef = this.deepDiveDialog.open(DeepdiveDialogComponent, {
          width: '95vw',
          maxWidth: '95vw',
          maxHeight: '95vh',
          data: this.STAT_POPUP
        });
      }

      // this.deepDiveDialogRef.afterClosed().pipe(take(1)).subscribe((result => {
      //   console.log('dialog was closed');
      // }));
      // data: {charttitle:this.charttitle,
      // deepdiveDetails:this.deepdiveDetails,aiNameTemp:this.aiNameTemp,notesviewflag:this.notesviewflag},
      // });
      // this.inddeep = false;
    }
  }
  searchbyCategory() {
    this.searchinsightDetails = null;
    this.searchSmartlistItemDetailpaginator = null;

    this.searchDialogRef = this.searchDialog.open(SearchDialogComponent, {
      width: '1200px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: this.STAT_POPUP
    });
  }
  keywordStat(view) {
    let keyflag = false;
    // console.log(view.value('productTypeId')?.toString()?.trim());
    // console.log(view.value('productName')?.toString()?.trim());
    // console.log(view.value('productNewOrExisting'));
    // console.log(view.value('brandName')?.toString()?.trim());


    if (view.value('productNewOrExisting') == 1) {


      if (
        ((view.value('productName')?.toString()?.trim()) != null && view.value('productName')?.toString()?.trim()) &&
        ((view.value('productTypeId')?.toString()?.trim() != null) && view.value('productTypeId')?.toString()?.trim())) {
        keyflag = true;
      }
    } else if (view.value('productNewOrExisting') == 2) {

      if (
        ((view.value('productName')?.toString()?.trim()) != null && (view.value('productName')?.toString()?.trim())) &&
        (view.value('productTypeId')?.toString()?.trim() != null && (view.value('productTypeId')?.toString()?.trim()))
      ) {
        keyflag = true;
      }

    } else {
      if (
        ((view.value('productName')?.toString()?.trim()) != null && view.value('productName')?.toString()?.trim()) &&
        ((view.value('productTypeId')?.toString()?.trim() != null) && view.value('productTypeId')?.toString()?.trim())) {
        keyflag = true;
      }
    }
    // console.log(keyflag);
    return keyflag;
  }
  keywordSuggest(view, dashboard = true, fl = this.flSelected) {
    if (this.flSelected !== 0 && this.flSelected !== 199 && this.flSelected !== 101) {      // Code to be executed if the condition is true

      this.keywordCat = true;
      this.view.set('productDescription', this.project.productDescription);

    }
    //console.log(this.notesviewflag)
    if (this.notesviewflag) {
      this.tempStorer = this.smartlist;
      // console.log(this.tempStorer)
    }
    if (this.showWish) {
      this.tempStorer = this.wishListAll;
    }
    this.keywordProgress = true;
    this.smartKeywordSuggestion = '';
    this.selectedKeywordSuggestion = [];

    //console.log(this.sentimentResp.length != 0 || this.smartlistItem != '');
    //console.log(this.smartlistItem);

    if (dashboard && (this.sentimentResp?.length != 0 || this.smartlistItem != null)) {
      this.keywordSaveFlag = true;
      if (
        (this.project.productName?.trim() !== this.view.value('productName')?.toString()?.trim()) ||

        (this.project.brandName?.trim() !== this.view.value('brandName')?.toString()?.trim()) ||
        (this.project.productTypeId?.title?.trim() !== this.view.value('productTypeId')?.title?.trim())
      ) {

        this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
          data: {
            title: 'Project Update Found',
            message: 'You have made changes to the project input, Please use ‘Clear Insights’ and re-run to get the most updated results.',
            cancelbtn: 'PROCEED WITHOUT CLEARING',
            okbtn: 'CLEAR INSIGHTS AND PROCEED'
          },
        });
        this.warningDialogRef.afterClosed().subscribe(result => {
          if (result?.data === 'confirm') {
            this.clearInsights = true;
            this.clearChatTurbo();


            Config.loading = true;
            this.dashCharts = [];
            // this.showDashboard();
            this.clearCache1(this.view, '0', this.smartlistItem);
            Config.loading = true;
            this.project.productTypeId = this.view.value('productTypeId');
            if (this.sentimentResp?.length != 0) {
              this.clearInsights = true;

              this.dashCharts = [];
              //   this.showDashboard();
              this.clearCache1(this.view, '99', this.smartlistItem);
              Config.loading = true;
              this.project.productTypeId = this.view.value('productTypeId');
            }
            this.commonFlag1();
            // if (this.val(this.project.productNewOrExisting) == 1) {
            //   this.blogStatus = false;
            // } else if (this.val(this.project.productNewOrExisting) == 2) {
            this.blogStatus = false;
            if (this.val(this.project.competitors) == '' || this.val(this.project.competitorspdt) == '') {
              this.compStatus = true;
              this.compStatusDate = true;
            } else {
              this.compStatus = false;
            }
            this.smartSave('0009');
            // this.showDashboard();

            Config.loading = true;
            setTimeout(() => {

              this.getKeyWords(view, fl);
            }, 1000);
          } else if (result?.data === 'close') {
            this.commonFlag1();
            // if (this.val(this.project.productNewOrExisting) == 1) {
            //   this.blogStatusDate = false;
            // } else if (this.val(this.project.productNewOrExisting) == 2) {
            this.blogStatusDate = false;
            this.compStatusDate = false;
            this.smartSave('0009');
            //this.showDashboard();

            Config.loading = true;

            setTimeout(() => {
              this.getKeyWords(view, fl);
            }, 1000);

          }
        });



      } else {
        Config.loading = true;
        this.smartSave('0009');



        setTimeout(() => {
          this.getKeyWords(view, fl);
        }, 1000);
      }
    } else {
      this.keywordSaveFlag = false;
      this.getKeyWords(view, fl);
    }
  }
  getsummary() {
    // console.log(this.smartTitle)
    let catId = this.config.catId(this.smartTitle);
    //console.log(catId)
    return this.insightsSummary?.filter(u => "" + u.fl == catId)[0]?.summary;
  }
  clearChatTurbo() {
    if (this.view.value('mode') == 'Turbo Mode') {
      this.view.get(`aiinsights/deleteChatData/${this.project.id}`).subscribe(res => {
        //  console.log(res);
        this.messages = [];
        this.chatSequence = 0;
        this.isTyping = false;

      });
    }
  }
  getFlVal() {
    let fl;
    if (this.flSelected == 199) {
      fl = '0';

    } else if (this.flSelected == 101) {
      fl = '12';
    } else if (this.flSelected == 0) {
      fl = '13';
    } else {
      fl = this.flSelected;
    }
    return fl;
  }
  insightSummary(view) {
    // console.log(this.sentimentSelected.Mode)
    // console.log(this.getSubTitle())
    Config.loading = true;
    this.analysisprogressManagerkey(view.value('id', ''));
    this.config.setProgressVisibility(true);

    // Config.setLoaderImage('./assets/images/InsIQ_search.gif');
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    let SUMMARYURL;
    Config.setLoaderImage('./assets/images/insiq_ai_insights.gif');

    //  Config.setLoaderImage('./assets/images/insiq_ai.png');
    Config.setLoaderMessage('InsIQ is preparing your Insights Analysis');
    if (this.flSelected == 0) {
      SUMMARYURL = `rn=${encodeURIComponent(view.value('id', '') === '' ? '0' : view.value('id', ''))}`
        + `&fl=${this.getFlVal()}` + `&mode=${this.sentimentSelected.Mode}` + `&cn=${this.getSubTitle()}`;

    } else {
      SUMMARYURL = `rn=${encodeURIComponent(view.value('id', '') === '' ? '0' : view.value('id', ''))}`
        + `&fl=${this.getFlVal()}`;
    }

    // const SUMMARYURL = `rn=475`
    //   + `&fl=${this.flSelected == 199 ? '0' : this.flSelected}`;
    let flag;
    //  const querryUrlParams = DEEPDIVEURL.split('?');

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }
    this.view.get(`aiinsights/insightsummary/${flag}/${SUMMARYURL}`).subscribe(res => {
      Config.loading = false;

      //  console.log('chatGPT', res);
      if (res[0].SResponse != '') {
        this.summaryDialogRef = this.summaryDialog.open(SummaryDialogComponent, {
          width: '800px',
          maxWidth: '95vw',
          maxHeight: '95vh',
          data: res[0].SResponse
        });
      } else {
        // if (this.flSelected == 0 || this.flSelected == 101) {
        this.openSnackBar({
          type: 'simple',
          duration: 3000,
          action: 'CLOSE',
          message: res[0].Status
        });
        // }
        // else {
        //   this.openSnackBar({
        //     type: 'simple',
        //     duration: 3000,
        //     action: 'CLOSE',
        //     message: 'No Insights Analysis Available. Please try again.'
        //   });
        // }

      }

    });


    // Config.setLoaderImage('./assets/images/InsIQ_search.gif');5488

  }
  getKeyWords(view, fl) {
    this.progressManagerkey(view.value('id', ''));
    this.keywordsel = fl;
    Config.loading = true;
    // Config.setLoaderImage('./assets/images/InsIQ_search.gif');
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    Config.setLoaderImage('./assets/images/insiq_ai_insights.gif');
    Config.setLoaderMessage('InsIQ is searching for suitable keywords');
    this.config.setProgressVisibility(true);


    let epf = 1;

    epf = this.getepf(view.value('productNewOrExisting', ''));

    const SEARCHURL = `t=${encodeURIComponent(this.transform(view.value('productName', '')))}`
      + `&q=${this.getfieldKeyword(view)}`
      + `&q3=${encodeURIComponent(this.transform(view.value('productTypeId', '').title))}`
      + `&q4=${encodeURIComponent(this.transform(view.value('customer', '')))}`
      + `&pn=${encodeURIComponent(this.transform(view.value('brandName', '')))}`
      + `&cc=${encodeURIComponent(this.transform(view.value('targetRegion', '')))}`
      + `&rn=${encodeURIComponent(view.value('id', '') === '' ? '0' : view.value('id', ''))}`
      + `&epf=${encodeURIComponent(epf)}`
      + `&lang=${encodeURIComponent(this.transform(view.value('apiLanguage', '')))}`
      + `&epc=${encodeURIComponent(this.transform(view.value('companyname', '')))}`
      + `&epcomp=${encodeURIComponent(this.transform(view.value('competitors', '')))}`
      + `&epprod=${encodeURIComponent(this.transform(view.value('competitorspdt', '')))}`
      + `&fl=${fl == 199 ? '0' : fl}`
      + `&rt=${encodeURIComponent(this.reporttype)}`
      + `&dd=${this.aiidset}`
      + `&mode=1`
      + `&keywords=1`;
    let flag;
    //  const querryUrlParams = DEEPDIVEURL.split('?');

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }

    this.view.get(`aiinsights/keywordsearch/${flag}/${SEARCHURL}`).subscribe(res => {
      Config.loading = false;
      const list = new Array();

      this.keywordProgress = false;
      if (this.notesviewflag) {
        // console.log('tempp', this.tempStorer)
        this.wishListAll = this.tempStorer;
        for (var i = 0; i < this.existingcomments.length; i++) {
          for (var j = 0; j < this.wishListAll.length; j++) {
            if (this.existingcomments[i].aiId == this.wishListAll[j].aiId) {
              this.wishListAll[j].aiNameTemp = this.wishListAll[j].aiName;
              this.wishListAll[j].aiNameIdTemp = this.catId(this.wishListAll[j].aiName);

              list.push(this.wishListAll[j]);

            }
          }
        }

        this.dashboard = false;
        this.listViewInsight = true;

        this.InsIQclickflg = false;
        this.cacheClear = false;
        this.chartReports = false;
        this.sentimentclickflg = false;
        this.averagePrice = false;

        this.sentimentSelected = '';

        this.partnerclickflg = false;
        this.inddeep = false;

        // this.smartTitle = 'Notes Entered ';
        this.showWish = false;
        this.tagcloudclick = false;
        // this.notesviewflag=false;
        this.selCat = 0;
        this.setLiked(list);
        // this.getComments();
        // this.getReports();
        // console.log(list);
        if (list.length > 0) {
          this.smartlistItemDetail = list;
          this.smartlistItemDetailtip = this.smartlistItemDetail;
          this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
        }
        this.smartlist = this.wishListAll;
      } else if (this.showWish) {
        // console.log('hereeeeee');
        this.allcatflag = true;
        this.pagemin = 0;
        this.pagemax = this.pageSize;
        this.pageno = this.pageSize;
        this.insightwish = true;
        this.setAIView(false);
        // this.smartlistItemDetail = null;
        // this.smartlistItem = null;
        //Config.loading = true;
        // Config.setLoaderMessage('InsIQ is consulting internal and external datasets, this can take 12-15 secs');
        this.dashboard = false;
        this.listViewInsight = true;

        this.InsIQclickflg = false;
        this.cacheClear = false;
        this.chartReports = false;
        this.sentimentclickflg = false;
        this.averagePrice = false;

        this.sentimentSelected = '';

        this.partnerclickflg = false;
        this.inddeep = false;
        this.notesviewflag = false;
        this.reportviewflag = false;
        this.insightsearch = false;

        this.selCat = 0;
        this.hasRecord = 'none';


        this.showWish = true;
        // this.dashFav=false;

        this.setAIView(true);
        this.smartTitle = '';
        this.modeval = null;
        this.wishListAll = this.tempStorer;
        console.log(this.wishListAll)
        this.smartlistItemDetail = this.wishListAll;
        this.smartlistItemDetailtip = this.smartlistItemDetail;
        this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
        this.modeval = null;
        this.smartTitle = 'All';
        this.smartlist = this.wishListAll;
        // this.smartlist1=this.smartlist;
        this.setHighLight(this.smartlist, false, (this.existingcomments && this.existingcomments.length > 0));
        this.setHasRecord();

      }

      this.config.apiUsage();

      // console.log(res[0].Word2Vec);
      if (res != null) {
        if (res[0].NumberOfResults != 0) {
          this.smartKeywordSuggestion = res[0].Word2Vec.split(',');
          let currentKeyword = [];
          // console.log(view.value('productDescription'));

          if (this.getfieldKeywordOld(view) != null) {
            let supplyWord = this.getfieldKeywordOld(view)?.toString()?.trim();
            currentKeyword = supplyWord?.split(',').filter(e => e);
            //console.log(currentKeyword);
          }
          let i;
          for (i = 0; i < currentKeyword.length; i++) {
            this.selectedKeywordSuggestion.push(currentKeyword[i]);
            let index = this.smartKeywordSuggestion.findIndex(d => d.trim() === currentKeyword[i].trim()); //find index in your array
            this.smartKeywordSuggestion.splice(index, 1);
          }





          this.keywordDialogRef = this.keyworsDialog.open(KeywordsDialogComponent, {
            width: '800px',
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: this.STAT_POPUP
          });
        } else {
          this.openSnackBar({
            type: 'simple',
            duration: 3000,
            action: 'CLOSE',
            message: 'No Keyword suggestion Available. Please provide more information and try again.'
          });
        }

      }


    });

  }
  getfieldKeywordOld(view: View) {
    let val;
    if (this.keywordsel == 31) {

      // if (this.view.value('keyword31') === '' || this.view.value('keyword31') === null || this.view.value('keyword31') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword31');

      //}

    } else if (this.keywordsel == 6) {

      // if (this.view.value('keyword6') === '' || this.view.value('keyword6') === null || this.view.value('keyword6') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword6');
      // }

    } else if (this.keywordsel == 10) {

      // if (this.view.value('keyword10') === '' || this.view.value('keyword10') === null || this.view.value('keyword10') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword10');
      // }

    } else if (this.keywordsel == 2) {

      // if (this.view.value('keyword2') === '' || this.view.value('keyword2') === null || this.view.value('keyword2') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword2');
      // }

    } else if (this.keywordsel == 32) {

      // if (this.view.value('keyword32') === '' || this.view.value('keyword32') === null || this.view.value('keyword32') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword32');
      // }

    } else if (this.keywordsel == 8) {

      // if (this.view.value('keyword8') === '' || this.view.value('keyword8') === null || this.view.value('keyword8') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword8');
      // }

    } else if (this.keywordsel == 11) {

      // if (this.view.value('keyword11') === '' || this.view.value('keyword11') === null || this.view.value('keyword11') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword11');
      // }

    } else if (this.keywordsel == 7) {

      // if (this.view.value('keyword7') === '' || this.view.value('keyword7') === null || this.view.value('keyword7') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword7');
      // }

    } else if (this.keywordsel == 5) {

      // if (this.view.value('keyword5') === '' || this.view.value('keyword5') === null || this.view.value('keyword5') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword5');
      //  }

    } else if (this.keywordsel == 4) {

      // if (this.view.value('keyword4') === '' || this.view.value('keyword4') === null || this.view.value('keyword4') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword4');
      //  }

    } else if (this.keywordsel == 9) {

      // if (this.view.value('keyword9') === '' || this.view.value('keyword9') === null || this.view.value('keyword9') === 'null') {

      //   val = this.view.value('productDescription');
      // }
      // else {

      val = view.value('keyword9');
      // }

    } else if (this.keywordsel == 199) {


      val = view.value('productDescription');


    } else if (this.keywordsel == 0) {


      val = view.value('productDescription');


    } else if (this.keywordsel == 101) {


      val = view.value('productDescription');


    }

    return val;
  }
  cnfrmReport(row) {
    // console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        title: 'Confirm Action',
        message: 'Are you sure want to remove from Report?',
        cancelbtn: 'No',
        okbtn: 'Yes'
      },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.addReport(row);
      }
    });
  }
  addremoveReport(row) {
    this.view.set('aiNotes', '');
    this.view.set('notesId', null);
    if (this.featureChecker(17)) {
      if (row.report == '1') {
        this.cnfrmReport(row);
      } else {
        this.addReport(row);
      }
    } else {
      this.featurealert('Report');

    }

  }

  addReport(row) {
    let aiReportOn;
    let alreadyReport = false;
    if (row.report == '1') {
      alreadyReport = true;
      aiReportOn = 0;
      // if (this.reportviewflag) {
      this.alertService.success('Successfully Removed', true, 10 * 1000);

      //}


    } else {
      aiReportOn = 1;
    }
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = this.aitype;
    } else {
      aitypeVal = '2';
    }
    //    this.view.set('projectId', this.project);
    //    this.view.set('likeon', 1);
    //    this.view.set('aiId', row.aiId);
    //    this.view.set('aiType', aitypeVal);


    this.view.set('projectId', this.project);
    this.view.set('aiLikeon', '0');
    this.view.set('aiId', row.aiId);
    this.view.set('aiModule', aitypeVal);
    this.view.set('aiApiType', aitypeVal);
    this.view.set('aiParentId', '0');
    this.view.set('aiDeepDive', '0');
    this.view.set('aiReporton', aiReportOn);
    this.view.set('aiTypeId', '1');
    //  this.modeval = null;
    let titleName;
    let aiUrl;
    let aiTitle, aiPrice;
    if (this.smartTitle == 'All' || this.insightsearch) {
      titleName = row.aiName;
    } else if (this.averagePrice) {
      titleName = 'Pricing Insights';

    } else if (row.aiNameTemp == 'All Insights') {
      titleName = 'Deep Dive';
    } else if (row.aiNameTemp != '') {
      titleName = row.aiNameTemp;
    } else {
      titleName = this.smartTitle;
    }
    let aiText = '';
    if (row.aiText) {
      aiText = row.aiText;
    }




    if (this.averagePrice) {
      aiUrl = row.aiLink;
      aiTitle = row.aiName;
      aiPrice = row.aiPrice + ' ' + this.averagePriceRes.Currency;
    } else {
      aiUrl = row.aiUrl;
      aiTitle = row.aiTitle;
    }
    this.view.set('aiName', titleName);
    this.view.set('aiTitle', aiTitle);
    this.view.set('aiUrl', aiUrl);
    this.view.set('aiPrice', aiPrice);

    this.view.set('aiImages', row.aiImages);
    this.view.set('aiText', row.aiText);

    this.view.set('aiVideos', row.aiVideos);
    this.view.set('aiText', aiText);
    this.view.set('productName', this.project.productName);
    this.view.set('productNewOrExisting', this.project.productNewOrExisting);
    this.view.set('mode', this.project.mode);

    this.view.set('brandName', this.project.brandName);
    this.view.set('apiLanguage', this.project.apiLanguage);
    this.view.set('problemSolve', this.project.problemSolve);
    this.view.set('productEnviornment', this.project.productEnviornment);
    this.view.set('productDescription', this.project.productDescription);
    this.view.set('productTypeId', this.project.productTypeId);

    this.view.set('keyword31', this.project.keyword31);
    this.view.set('keyword6', this.project.keyword6);
    this.view.set('keyword10', this.project.keyword10);
    this.view.set('keyword2', this.project.keyword2);
    this.view.set('keyword32', this.project.keyword32);
    this.view.set('keyword8', this.project.keyword8);
    this.view.set('keyword11', this.project.keyword11);
    this.view.set('keyword7', this.project.keyword7);
    this.view.set('keyword5', this.project.keyword5);
    this.view.set('keyword4', this.project.keyword4);
    this.view.set('keyword9', this.project.keyword9);

    // if (likeOrUnlike == 0) {
    //   this.likeval = 2;
    // } else if (likeOrUnlike == 1) {
    //   this.likeval = 1;
    // }

    // this.view.saveAndAdd(); // this should call the create service
    this.view.saveCallback(() => {
      if (!alreadyReport) {
        row['report'] = '1';
      } else {
        row['report'] = '0';
        if (this.reportviewflag) {
          this.smartreportlist = this.smartreportlist.filter(u => u.id !== row.id);
          this.reportinsights = this.reportinsights.filter(u => u.id !== row.id);

          this.smartlistItemDetail = this.smartreportlist.filter(u => u.aiName == titleName);
          this.smartlistItemDetailtip = this.smartlistItemDetail;
          this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
          this.getComments();
          this.getReports();
          this.setHighLight(this.smartreportlist, false, (this.reportinsights && this.reportinsights.length > 0));
          if (this.allcatflag) {
            this.filterWish('');
          } else {
            this.filterWish(titleName);
          }
        }
      }

      this.view.newform();
      // this.getReports();
      this.setProject();
    }, 'aiinsight/report', '');
  }
  shareProj(project) {
    const dialogData = project;
    // console.log(dialogData);
    this.cloneDialogRef = this.cloneDialog.open(CloneprojectComponent, {
      width: '600px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: dialogData
    });

  }
  omit_special_char(event, searchVal, fl = 1, commonflag = false) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)


    if (k == 13) {

      if (searchVal.trim() != '') {

        this.searchApiCall(searchVal, fl, commonflag);
      } else {
        this.openSnackBar({
          type: 'simple',
          message: 'Please Enter some keywords to search insights.',
          action: 'CLOSE',
          duration: 2000
        });
      }
    }
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  searchApiCall(Aikeyword, fl = 1, commonflag = false) {
    // console.log(Aikeyword);
    Config.loading = true;
    Config.setLoaderImage('./assets/images/insiq_ai.gif');
    Config.setLoaderMessage('InsIQ is searching for insights');
    const SEARCHURL = `q=${encodeURIComponent(Aikeyword)}` +
      `&rn=${this.project.id}` +
      `&fl=${fl}`;
    let flag;
    //  const querryUrlParams = DEEPDIVEURL.split('?');

    if (location.host.includes(Config.MAINPROD_URL)) {
      flag = 1;
    } else {
      flag = 0;
    }

    this.view.get(`aiinsights/insightsearch/${flag}/${SEARCHURL}`).subscribe(res => {
      Config.loading = false;
      this.config.apiUsage();
      // this.config.apiUsage();
      // console.log(res);
      let trimResult: any;



      if (commonflag) {
        this.wishListAll = res[0]?.aiResults;
        let aiNameTemp = [];
        for (var j = 0; j < this.wishListAll.length; j++) {

          this.wishListAll[j].aiName = this.catName(this.wishListAll[j].aiType, this.view);
          // this.wishListAll[j].aiNameIdTemp = this.catName(this.wishListAll[j].aiType,this.view);

          //  this.wishListAll[j].aiCategory = this.wishListAll[j].aiConcepts;

          this.wishListAll[j].aiTypeID = this.wishListAll[j].aiType;
          this.wishListAll[j].aiNameIdTemp = this.catId(this.wishListAll[j].aiName);

          aiNameTemp.push(this.wishListAll[j].aiName);

        }
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        this.smartlistItem = aiNameTemp.filter(onlyUnique);
        // this.filterWish(fl);
        // console.log(this.wishListAll);
        if (this.wishListAll.length == 0) {
          this.wishListAll = null;
          this.openSnackBar({
            type: 'simple',
            message: 'No Related Insights Available. Please try some other keywords.',
            action: 'CLOSE',
            duration: 3000
          });
        }
      }


      // console.log(fl);
      if (fl != 1 && !commonflag) {
        this.fixaitypeId = fl;
        this.fixaitypeName = this.catName(fl, this.view);

        // console.log('hereeeeee', this.fixaitypeId);
        //console.log('hereeeeee', this.catName(fl, this.view));

        //  this.searchinsightDetails = this.wishListAll;
        const dummyarray = [];
        const insArray = res[0]?.aiResults;
        // let searchArray = res[0]?.aiResults;
        //console.log(searchArray);
        // for (let j = 0; j < searchArray[0].aiInsightsLink.length; j++) {
        //   for (let k = 0; k < searchArray[0].aiInsightsLink[j].aiLink.length; k++) {
        //     searchArray[0].aiInsightsLink[j].aiLink[k].aiName = searchArray[0].aiInsightsLink[j].aiName;
        //   }
        // }

        // for (let j = 0; j < searchArray[0].aiInsightsLink.length; j++) {
        //   dummyarray.push(searchArray[0].aiInsightsLink[j].aiLink);
        // }

        // for (let k = 0; k < dummyarray.length; k++) {
        //   for (let p = 0; p < dummyarray[k].length; p++) {
        //     insArray.push(dummyarray[k][p]);
        //   }
        // }
        // this.getComments();
        //  this.getReports();

        let aitypeVal;
        if (!this.desiagnApiCall) {

          aitypeVal = '1';
        } else {
          aitypeVal = '2';
        }
        this.notesView.get(`aiinsight/noteslist/${aitypeVal}/${this.project.id}`).subscribe(res => {
          //   console.log(res);
          this.existingcomments = res;
          for (let i = 0; i < insArray.length; i++) {
            if (this.existingcomments.length === 0) {
              insArray[i].notesId = null;
              insArray[i].noteAdd = null;
            }
            for (let j = 0; j < this.existingcomments.length; j++) {


              if (insArray[i].aiId == this.existingcomments[j].aiId) {
                // console.log('match found');
                insArray[i].notesId = this.existingcomments[j].id;
                insArray[i].noteAdd = this.existingcomments[j].aiNotes;
                break;
              }
              if (insArray[i].aiId != this.existingcomments[j].aiId) {
                // console.log('match found');
                insArray[i].notesId = null;
                insArray[i].noteAdd = null;

              }
            }
          }
        });

        this.view.get(`aiinsight/favoritelist/${aitypeVal}/${this.project.id}`).subscribe(res => {
          this.likes = res; for (let i = 0; i < insArray.length; i++) {
            if (this.likes.length === 0) {
              insArray[i].liked = '';
            }
            for (let j = 0; j < this.likes.length; j++) {

              // console.log(this.likes);
              // console.log('likeai',insArray[i].aiId);
              // console.log('like ins',this.likes[j].aiId);
              if (insArray[i].aiId == this.likes[j].aiId) {
                // console.log('match found');
                insArray[i].liked = '1';
                break;
              }
              if (insArray[i].aiId != this.likes[j].aiId) {
                // console.log('match else found');
                insArray[i].liked = '';

              }
            }
          }
        });



        this.notesView.get(`aiinsight/reportlistlist/${aitypeVal}/${this.project.id}`).subscribe(res => {

          this.reportinsights = res; for (let i = 0; i < insArray.length; i++) {
            if (this.reportinsights.length === 0) {
              insArray[i].report = '';
            }
            for (let j = 0; j < this.reportinsights.length; j++) {


              if (insArray[i].aiId == this.reportinsights[j].aiId) {
                // console.log('match found');
                insArray[i].report = '1';
                break;
                //  insArray[i].noteAdd = this.reportinsights[j].aiNotes;
              }
              if (insArray[i].aiId != this.reportinsights[j].aiId) {
                // console.log('match found');
                insArray[i].report = '';
                //  insArray[i].noteAdd = this.reportinsights[j].aiNotes;
              }
            }
          }
        });


        //this.deepdiveDetails = insArray;

        this.searchinsightDetails = insArray;
        this.searchPagemin = 0;
        this.searchPagemax = this.searchPageSize;
        this.searchPageno = this.searchPageSize;
        this.searchSmartlistItemDetailpaginator = this.searchinsightDetails.slice(this.searchPagemin, this.searchPagemax);

        // console.log(this.wishListAll);
        if (this.searchinsightDetails.length == 0) {
          this.searchinsightDetails = null;
          this.searchSmartlistItemDetailpaginator = null;

          this.openSnackBar({
            type: 'simple',
            message: 'No Related Insights Available. Please try some other keywords.',
            action: 'CLOSE',
            duration: 3000
          });
        }

      } else {
        this.smartlistItemDetail = this.wishListAll;
        this.smartlistItemDetailtip = this.smartlistItemDetail;
        this.smartlistItemDetailpaginator = this.smartlistItemDetail?.slice(this.pagemin, this.pagemax);
        this.smartTitle = 'All';
        this.setHighLight(this.wishListAll, false, (this.existingcomments && this.existingcomments.length > 0));
        this.setHasRecord();
        if (fl != 1) {
          this.filterWish(this.catName(fl, this.view));

        }

      }
      // this.smartlistItem = this.smartlist;
      //console.log(this.smartlist);
      //this.deepdivelist = res;

    });
  }


  insightSearchView(view: View, p: Project = null, aitype = '1') {
    this.wishListAll = [];
    this.insightsearch = true;
    this.smartlistItem = null;
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.stackChartData00 = this.stackChartData0;
    this.insighttemp = this.insight0;
    this.pieChartLabels00 = this.pieChartLabels0;

    this.pieChartLabels11 = this.pieChartLabels1;
    this.pieChartLabels22 = this.pieChartLabels2;
    this.pieChartLabels33 = this.pieChartLabels3;

    this.pieChartLabels44 = this.pieChartLabels4;
    this.pieChartLabels55 = this.pieChartLabels5;
    this.pieChartLabels66 = this.pieChartLabels6;
    this.pieChartLabels77 = this.pieChartLabels7;
    this.pieChartLabels88 = this.pieChartLabels8;

    this.chartreportinsights = [];
    this.allcatflag = true;
    this.hasRecord = 'none';
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = aitype;
    } else {
      aitypeVal = '2';
    }
    this.aitype = aitypeVal;
    this.project = p === null ? view.form.value : p;
    this.setAIView(true);
    this.displayReq = true;
    this.showWish = false;
    this.smartTitle = '';
    // this.getLiked();
    // this.getComments();
    this.modeval = null;
    // console.log('main test'+this.existingcomments);
    // this.setHighLight(this.smartlistItemDetail,false, (this.existingcomments && this.existingcomments.length > 0));
    this.notesviewflag = false;
    this.reportviewflag = false;

    const list = new Array();
    try {
      const aitipenote = '77';

      // this.getComments();
      this.progress = 0;
      this.config.setLoaderPercentage(this.progress);
      // Config.loading = true;
      this.dashboard = false;
      this.listViewInsight = true;

      this.InsIQclickflg = false;
      this.cacheClear = false;
      this.chartReports = false;
      this.partnerclickflg = false;
      this.inddeep = false;
      this.sentimentclickflg = false;
      this.averagePrice = false;

      this.sentimentSelected = '';

      // this.smartTitle = 'Notes Entered ';
      this.showWish = false;
      this.tagcloudclick = false;
      // this.notesviewflag=false;
      this.selCat = 0;
      // this.setLiked(list);
      // this.getComments();


      let aitypeVal;
      if (!this.desiagnApiCall) {

        aitypeVal = '1';
      } else {
        aitypeVal = '2';
      }
    } catch (exception) {
      console.log(exception);

    }
  }
  reportview(view: View, p: Project = null, aitype = '1') {
    if (this.featureChecker(17)) {
      this.view.get(`aiinsights/getallinsightsummary/${this.project.id}`).subscribe(res => {
        // console.log(res)
        this.insightsSummary = res;
      });
      this.reportview1(view, p, aitype);
    } else {
      this.featurealert('Report');

    }
  }
  reportview1(view: View, p: Project = null, aitype = '1') {
    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
    this.stackChartData00 = this.stackChartData0;
    this.insighttemp = this.insight0;
    this.pieChartLabels00 = this.pieChartLabels0;

    this.pieChartLabels11 = this.pieChartLabels1;
    this.pieChartLabels22 = this.pieChartLabels2;
    this.pieChartLabels33 = this.pieChartLabels3;

    this.pieChartLabels44 = this.pieChartLabels4;
    this.pieChartLabels55 = this.pieChartLabels5;
    this.pieChartLabels66 = this.pieChartLabels6;
    this.pieChartLabels77 = this.pieChartLabels7;
    this.pieChartLabels88 = this.pieChartLabels8;

    this.chartreportinsights = [];
    this.allcatflag = true;
    this.hasRecord = 'none';
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = aitype;
    } else {
      aitypeVal = '2';
    }
    this.aitype = aitypeVal;
    this.project = p === null ? view.form.value : p;
    this.setAIView(true);
    this.displayReq = true;
    this.showWish = false;
    this.smartTitle = '';
    this.getLiked();
    this.getComments();
    this.modeval = null;
    // console.log('main test'+this.existingcomments);
    // this.setHighLight(this.smartlistItemDetail,false, (this.existingcomments && this.existingcomments.length > 0));
    this.notesviewflag = false;
    this.reportviewflag = true;
    this.insightsearch = false;

    const list = new Array();
    try {
      const aitipenote = '77';

      this.getComments();
      this.progress = 0;
      this.config.setLoaderPercentage(this.progress);
      Config.loading = true;
      view.get(`aiinsight/favoritelist/${aitypeVal}/${this.project.id}`).subscribe(res => {
        Config.loading = false;
        this.wishListAll = res;


        view.get(`aiinsight/reportnames/${this.aitype}/${this.project.id}`).subscribe(r => {
          this.smartlistItem = r;

          view.get(`aiinsight/reportlistlist/${this.aitype}/${this.project.id}`).subscribe(res => {

            this.reportinsights = res;
            this.modeval = null;
            this.smartTitle = 'All';
            // console.log(this.existingcomments);
            for (var i = 0; i < this.reportinsights.length; i++) {
              list.push(this.reportinsights[i]);
              this.reportinsights[i].aiNameTemp = this.reportinsights[i].aiName;

              this.reportinsights[i].aiNameIdTemp = this.catId(this.reportinsights[i].aiName);


            }

            // list.push(  this.reportinsights);
            this.dashboard = false;
            this.listViewInsight = true;

            this.InsIQclickflg = false;
            this.cacheClear = false;
            this.chartReports = false;
            this.partnerclickflg = false;
            this.inddeep = false;
            this.sentimentclickflg = false;
            this.averagePrice = false;

            this.sentimentSelected = '';

            // this.smartTitle = 'Notes Entered ';
            this.showWish = false;
            this.tagcloudclick = false;
            // this.notesviewflag=false;
            this.selCat = 0;
            this.setLiked(list);
            this.getComments();

            // console.log(list);
            if (list.length > 0) {
              this.smartreportlist = list;
              this.smartlistItemDetail = list;
              this.smartlistItemDetailtip = this.smartlistItemDetail;
              this.smartlistItemDetailpaginator = this.smartlistItemDetail.slice(this.pagemin, this.pagemax);
            }
            let aitypeVal;
            if (!this.desiagnApiCall) {

              aitypeVal = '1';
            } else {
              aitypeVal = '2';
            }
            view.get(`aireport/aicharts/${this.project.id}/${aitypeVal}`).subscribe(res => {

              this.chartreportinsights = res;
              //   console.log(this.chartreportinsights);
              for (var i = 0; i < this.chartreportinsights.length; i++) {
                if (this.chartreportinsights[i].aiCode === '99' || this.chartreportinsights[i].aiCode == 99) {
                  this.sentimentCharts = this.chartreportinsights[i];
                  // console.log( this.sentimentCharts);
                }
              }
              if (this.reportinsights.length === 0) {

                if (res != '') {


                  this.chartReport();
                }
              }
              this.getReports();
            });
          });

        });
        this.smartlist = this.reportinsights;

        // console.log(this.wishListAll);

      });
    } catch (exception) {
      console.log(exception);

    }
  }

  openModal(id: string) {
    this.modalService?.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    this.deepdiveDetails = null;
    this.searchinsightDetails = null;
    this.trendDetails = null;
  }
  favDeepdive(fl: any, view: View, e: any, p: Project = null, aitype = '1') {
    this.smartDevclose();
    this.aiidset = '';
    // console.log('Deep Dive');
    this.progress = 0;
    this.config.setLoaderPercentage(this.progress);
    Config.loading = true;
    view.get(`aiinsight/favoritelist/${aitype}/${this.project.id}`).subscribe(res => {
      //    view.get(`ailike/wishes/${aitype}/${this.project.id}`).subscribe(res => {
      Config.loading = false;
      this.wishListAll = res;
      // console.log(this.wishListAll.length);
      if (this.wishListAll.length == 0) {
        this.openSnackBar({
          type: 'simple',
          message: 'No Favorite Insights Available. Please like your Favorite Insights to Deep Dive',
          action: 'CLOSE',
          duration: 3000
        });
        this.deepdiveflag = false;
      } else {
        this.aiidset = this.aiidset + this.wishListAll[0].aiId;
        for (let i = 1; i < this.wishListAll.length; i++) {
          this.aiidset = this.aiidset + ',' + this.wishListAll[i].aiId;
        }
      }
      // this.insightDeep(this.aiidset);
      this.smartDev(fl, view);
    });
  }

  aiTexttrim(text) {
    const ending = '...';
    const length = 400;

    if (text?.length > length) {
      return text?.substring(0, length - ending.length) + ending;
    } else {
      return text;
    }
  }
  helpInfo(tit) {
    console.log('ConfirmationDialogComponent');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '639px',
      maxWidth: '95vw',
      data: { title: tit }
    });
  }
  patentModal(aiId) {
    // this.openModal('patent-modal');
    let patentarr = [];
    patentarr = this.smartlistItemDetailpaginator.filter(u => u.aiId === aiId);

    this.patentCountry = patentarr[0].aiCountry;
    this.patentDomain = patentarr[0].aiTechDomain;

    this.patentClaim = patentarr[0].aiClaim;
    // console.log('PatentDialogComponent');
    const dialogRef = this.dialog.open(PatentDialogComponent, {
      width: '806px',
      maxWidth: '65vw',
      data: {
        patentCountry: this.patentCountry,
        patentDomain: this.patentDomain,
        patentClaim: this.patentClaim,
        charttitle: this.charttitle
      },
    });
  }
  deeppatentModal(domain, claim) {

    console.log('PatentDialogComponent');
    const dialogRef = this.dialog.open(PatentDialogComponent, {
      width: '806px',
      maxWidth: '65vw',
      data: {
        patentCountry: '',
        patentDomain: domain,
        patentClaim: claim,
        charttitle: this.charttitle
      },
    });
  }

  transform(value: any): any {
    let newVal = value?.replace(/[^,"a-zA-Z0-9 ]/g, '').toLocaleLowerCase();
    return this.titleCase(newVal?.toLocaleLowerCase());
  }
  titleCase(str) {
    var splitStr = str?.toLowerCase().split(' ');
    for (var i = 0; i < splitStr?.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(' ');
  }
  sentimentapi(view: View, p: Project = null) {
    this.project = p === null ? view.form.value : p;

    const mode = 2;
    Config.setLoaderImage('./assets/images/insiq_ai_insights.gif');
    Config.loading = true;

    Config.setLoaderMessage('InsIQ is now processing Competitors');
    this.config.setProgressVisibility(true);
    this.SentimentprogressManager();
    // }, 25000);
    const epf = this.getepf(view.value('productNewOrExisting', ''));
    const SENTIMENTURL = `sentiment` +
      `?rn=${this.project.id}` +
      `&q=${epf === 0 ? this.transform(view.value('productName')) : this.transform(view.value('brandName'))}` +
      `&lang=${this.transform(view.value('apiLanguage'))}` +
      `&epf=${epf}`;
    const flag = (location.host.includes(Config.MAINPROD_URL)) ? 1 : 0;
    const querryUrlParams = SENTIMENTURL.split('?');

    this.view.get(`aiinsights/sentimentapi/${flag}/${querryUrlParams[1]}`).subscribe(res => {
      this.flValSelect = 0;
      // //console.log('hereee');
      // let sentRes = [];
      // //console.log(res);
      // for (let i = 0; i < res.length; i++) {
      //   let obj = JSON.parse(res[i]);
      //   //   console.log(obj);
      //   sentRes.push(obj);
      // }

      // console.log(sentRes);
      Config.loading = false;
      this.config.apiUsage();

      this.sentimentResp = res;

      // this.setSentiment();
      if (this.sentimentResp && this.sentimentResp.length !== 0) {
        this.sentimentclick(this.sentimentResp[0]);
      }
    });
  }
  sentimentDetail(event) {

    if (event.active.length > 0) {
      const chart = event.active[0]._chart;
      const activePoints = chart.getElementAtEvent(event.event);

      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        // const clickedElementIndex1 = activePoints[0]._datasetIndex;

        // console.log('CEI'+clickedElementIndex);//
        const label = chart.data.labels[clickedElementIndex];

        this.dsSentimentDetail = this.getSentAIText(label.split('Sentiment')[0] + 'Content', this.modeval);

        this.dsSentimentDetail = this.sentimenttLiker(this.dsSentimentDetail);
        //  console.log(this.dsSentimentDetail);

        const dialogRef = this.dialog.open(SentimentdialogComponent, {
          data: { filter: label.split('Sentiment')[0] + 'Content', chartTitle: this.getSubTitle(), insightDetails: this.dsSentimentDetail },
        });

      }
    }




  }

  sentimenttLiker(insArray) {
    // this.getComments();
    // this.getReports();
    // this.getLiked();
    //  console.log(this.reportinsights);
    // console.log(this.reportinsights);

    // console.log(this.likes);
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    this.notesView.get(`aiinsight/noteslist/${aitypeVal}/${this.project.id}`).subscribe(res => {
      //   console.log(res);
      this.existingcomments = res; for (let i = 0; i < insArray.length; i++) {
        if (this.existingcomments.length === 0) {
          insArray[i].aiData.notesId = null;
          insArray[i].aiData.noteAdd = null;
        }
        for (let j = 0; j < this.existingcomments.length; j++) {


          if (insArray[i].aiData.aiId == this.existingcomments[j].aiId) {
            // console.log('match found');
            insArray[i].aiData.notesId = this.existingcomments[j].id;
            insArray[i].aiData.noteAdd = this.existingcomments[j].aiNotes;
            break;
          }
          if (insArray[i].aiData.aiId != this.existingcomments[j].aiId) {
            // console.log('match found else');
            insArray[i].aiData.notesId = null;
            insArray[i].aiData.noteAdd = null;

          }
        }
      }
    });


    this.view.get(`aiinsight/favoritelist/${aitypeVal}/${this.project.id}`).subscribe(res => {

      this.likes = res;
      for (let i = 0; i < insArray.length; i++) {
        // console.log(this.likes);
        if (this.likes.length === 0) {
          insArray[i].aiData.liked = '';
        }
        for (let j = 0; j < this.likes.length; j++) {

          //  console.log(insArray[i].aiData.aiId);
          // console.log(this.likes[j].aiId);
          if (insArray[i].aiData.aiId == this.likes[j].aiId) {
            // console.log('match found',i);
            insArray[i].aiData.liked = '1';
            break;

          }
          if (insArray[i].aiData.aiId != this.likes[j].aiId) {
            //  console.log('match found elswe',i);
            insArray[i].aiData.liked = '';

          }
        }
      }
    });


    this.notesView.get(`aiinsight/reportlistlist/${aitypeVal}/${this.project.id}`).subscribe(res => {

      this.reportinsights = res;
      for (let i = 0; i < insArray.length; i++) {
        if (this.reportinsights.length === 0) {
          insArray[i].aiData.report = '';

        }
        for (let j = 0; j < this.reportinsights.length; j++) {

          // console.log(insArray[i].aiData.aiId);
          // console.log(this.reportinsights[j].aiId);
          if (insArray[i].aiData.aiId == this.reportinsights[j].aiId) {
            console.log('match found', i);
            insArray[i].aiData.report = '1';
            break;
            //  insArray[i].noteAdd = this.reportinsights[j].aiNotes;
          }
          if (insArray[i].aiData.aiId != this.reportinsights[j].aiId) {
            // console.log('match else found',i);
            insArray[i].aiData.report = '';

          }
        }
      }
    });

    // console.log(this.reportinsights);

    // console.log(insArray);
    return insArray;

  }
  toggleFilterMenu(): void {
    this.filterMenuOpen = !this.filterMenuOpen;
  }

  closeMenu(): void {
    this.filterMenuOpen = false;
  }
  sentimenttagManager(e) {
    const [tag, data] = e;
    //  console.log(tag.label);
    // console.log(tag.label.trim());
    //  console.log(this.getdataReviews(this.sentimentResp[0].aiReviewsP,tag.label.trim()));
    // let index = this.modeval;
    // for (let i = 0; i < this.sentimentResp.length; i++) {
    //   if (this.sentimentResp[i].mode == index) {
    //     index = i;
    //   }
    // }

    this.dsSentimentDetail = this.getdataReviews(this.sentimentSelected, tag.label.trim());
    if (this.dsSentimentDetail !== '') {
      this.dsSentimentDetail = this.sentimenttLiker(this.dsSentimentDetail);
      const dialogRef = this.dialog.open(SentimentdialogComponent, {
        data: { filter: tag.label.trim(), chartTitle: this.getSubTitle(), insightDetails: this.dsSentimentDetail },
      });
    } else {
      this.openSnackBar({
        type: 'simple',
        message: 'No Relation Found!',
        action: 'CLOSE',
        duration: 3000
      });
    }
  }

  getdataReviews(dataArray, tag, index = 0) {
    // console.log(dataArray);
    let SentAIText: { aiType: string, aiData: string }[] = [];
    for (var i = 0; i < dataArray.aiReviewsP.length; i++) {

      for (var j = 0; j < dataArray.aiReviewsP[i].aiConcepts.split(',').length; j++) {
        if (tag == dataArray.aiReviewsP[i].aiConcepts.split(',')[j]) {
          SentAIText.push({ 'aiType': 'Positive', 'aiData': dataArray.aiReviewsP[i] });
        }
      }

    }
    for (var i = 0; i < dataArray.aiReviewsN.length; i++) {

      for (var j = 0; j < dataArray.aiReviewsN[i].aiConcepts.split(',').length; j++) {
        if (tag == dataArray.aiReviewsN[i].aiConcepts.split(',')[j]) {
          SentAIText.push({ 'aiType': 'Negative', 'aiData': dataArray.aiReviewsN[i] });
        }
      }

    }
    for (var i = 0; i < dataArray.aiReviewsNT.length; i++) {

      for (var j = 0; j < dataArray.aiReviewsNT[i].aiConcepts.split(',').length; j++) {
        if (tag == dataArray.aiReviewsNT[i].aiConcepts.split(',')[j]) {

          SentAIText.push({ 'aiType': 'Neutral', 'aiData': dataArray.aiReviewsNT[i] });
        }
      }

    }
    return SentAIText;
  }
  getSentAIText(cat, index = 0) {
    // for (let i = 0; i < this.sentimentResp.length; i++) {
    //   if (this.sentimentResp[i].mode == index) {
    //     index = i;
    //   }
    // }
    let SentAIText: { aiType: string, aiData: string }[] = [];
    //    if(cat==='Positive Tweets')
    //    {
    //      SentAIText=this.sentimentResp[0].aiTweetsP.aiText;
    //    }    //    else if(cat==='Negative Tweets')
    //    {
    //      SentAIText=this.sentimentResp[0].aiTweetsN.aiText;
    //    }    //      else if(cat==='Neutral Tweets')
    //    {
    //      SentAIText=this.sentimentResp[0].aiTweetsNT.aiText;
    //    }

    // console.log(this.sentimentSelected);
    if (cat === 'Positive Content') {
      for (var i = 0; i < this.sentimentSelected.aiReviewsP.length; i++) {
        SentAIText.push({ 'aiType': 'Positive', 'aiData': this.sentimentSelected.aiReviewsP[i] });

      }


    } else if (cat === 'Negative Content') {
      for (var i = 0; i < this.sentimentSelected.aiReviewsN.length; i++) {
        SentAIText.push({ 'aiType': 'Negative', 'aiData': this.sentimentSelected.aiReviewsN[i] });

      }
    } else if (cat === 'Neutral Content') {
      for (var i = 0; i < this.sentimentSelected.aiReviewsNT.length; i++) {
        SentAIText.push({ 'aiType': 'Neutral', 'aiData': this.sentimentSelected.aiReviewsNT[i] });
      }

    }
    return SentAIText;
  }
  setSentiment() {

    this.sentimentTrendChartData = [];
    this.sentimentTrendChartLabels = [];

    this.smartTitle = 'Sentiment Analysis ' + (this.sentimentSelected.keyvalue !== '99' ? this.sentimentSelected.keyvalue : '');
    // this.modeval = res[index].mode;
    let tc: TagCloud;
    this.sentimentTag = [];
    this.sentimentapistat = true;
    this.spieChartLabels = [
      'Positive Sentiment Score',
      'Negative Sentiment Score',
      'Neutral Sentiment Score'
    ];

    this.spieChartData = [
      this.sentimentSelected.PositiveSentimentScore,
      this.sentimentSelected.NegativeSentimentScore,
      this.sentimentSelected.NeutralSentimentScore
    ];

    const total =
      this.sentimentSelected.PositiveSentimentScore +
      this.sentimentSelected.NegativeSentimentScore +
      this.sentimentSelected.NeutralSentimentScore;

    this.nrs = (
      ((this.sentimentSelected.PositiveSentimentScore / total) * 100) -
      ((this.sentimentSelected.NegativeSentimentScore + this.sentimentSelected.NeutralSentimentScore) / total) * 100
    ).toFixed(2);

    this.sentimentscore = this.sentimentSelected.Sentiment;

    const arr = this.sentimentSelected?.WordCloud?.split(/[ ,.]+/);
    const positiveWord = this.sentimentSelected?.PositiveWords?.split(/[ ,.]+/);
    const NegativeWords = this.sentimentSelected?.NegativeWords?.split(/[ ,.]+/);

    const NeutralWords = this.sentimentSelected?.NeutralWords?.split(/[ ,.]+/);

    //console.log(this.sentimentSelected);
    for (let i = 0; i < arr?.length; i++) {
      tc = new TagCloud();
      tc.label = arr[i];
      tc.weight = 1;
      // if (i % 2) {
      //
      // }
      // tc.color= "#000000";
      // tc.color='#ffaaee';
      for (let j = 0; j < positiveWord?.length; j++) {
        if (arr[i] == positiveWord[j]) {
          tc.styleClass = 'tag-positive';
        }
      }

      for (let k = 0; k < NegativeWords?.length; k++) {
        if (arr[i] == NegativeWords[k]) {
          tc.styleClass = 'tag-negative';
        }
      }
      for (let p = 0; p < NeutralWords?.length; p++) {
        if (arr[i] == NeutralWords[p]) {
          tc.styleClass = 'tag-neutral';
        }
      }

      this.sentimentTag.push(tc);
    }

    // this.sentimentTag=res[0].WordCloud;
    this.sbarChartData = [{
      label: 'PNRatioPercent',
      data: [this.sentimentSelected?.PNRatioPercent]
    }];
    // console.log(this.insightSA);
    for (let i = 0; i < this.sentimentSelected?.aiGraphData.length; i++) {
      this.sentimentTrendChartData.push(this.sentimentSelected?.aiGraphData[i].aiScore);

      this.sentimentTrendChartLabels.push(
        this.monthFinder(this.sentimentSelected?.aiGraphData[i].aiMonth, this.sentimentSelected?.aiGraphData[i].aiYear)
      );

    }
  }
  monthFinder(month, year) {
    let montheng = '';
    switch (month) {
      case '1': montheng = 'Jan';
        break;
      case '2': montheng = 'Feb';
        break;
      case '3': montheng = 'Mar';
        break;
      case '4': montheng = 'Apr';
        break;
      case '5': montheng = 'May';
        break;
      case '6': montheng = 'Jun';
        break;
      case '7': montheng = 'Jul';
        break;
      case '8': montheng = 'Aug';
        break;
      case '9': montheng = 'Sep';
        break;
      case '10': montheng = 'Oct';
        break;
      case '11': montheng = 'Nov';
        break;
      case '12': montheng = 'Dec';
        break;
      default: montheng = 'Jan';
        break;

    }
    return montheng + ' ' + year;
  }
  getScoreColor(score) {
    let emo = '';
    if (score === 'Positive' || score === 'Negative' || score === 'Neutral') {
      emo = score.toLowerCase();
    } else {
      emo = 'positive';
    }
    return emo;
  }

  averagePriceApi(view) {

    Config.setLoaderImage('./assets/images/insiq_ai_insights.gif');
    Config.setLoaderMessage('InsIQ is finding the Pricing Insights, this can take a few seconds');
    Config.loading = true;

    // console.log('AVG Price API');
    // console.log(this.project.id);
    let projId = view.value('id', '');
    let querryUrlParams = "T=" + this.transform(view.value('productName', '')) + "&rn=" + this.project.id;
    this.view.get(`aiinsights/averageprice/${this.project.id}/${querryUrlParams}`).subscribe(res => {
      Config.loading = false;
      // console.log(res);
      this.averagePriceRes = res;
      this.averagePriceResFinal = this.averagePriceRes?.sResponse;

      this.setPricingtagCloud();
      //this.setLiked(this.averagePriceRes);
      if (this.averagePriceRes != null) {
        this.setHighLightPrice(this.averagePriceRes, false, (this.existingcomments && this.existingcomments.length > 0));
      }
      this.commonFlagFalse();

    });


  }
  ptagManager(e) {


    const [tag, data] = e;
    this.priceFilter = tag.label;
    const list = new Array();

    // for (let i = 0; i < this.averagePriceRes.length; i++) {

    for (let j = 0; j < this.averagePriceRes?.sResponse?.length; j++) {

      let aiLink = this.averagePriceRes?.sResponse[j];

      if (aiLink.aiconcepts.split(',').includes(tag.label.trim())) {

        // aiLink.aiNameTemp = this.smartlistItem[i].aiName;

        //aiLink.aiNameIdTemp = this.smartlistItem[i].aiTypeID;

        // console.log('==' + this.smartlistItem[i].aiLink[j].aiNameTemp + '==');
        list.push(aiLink);
      }

      // }
    }
    if (list.length > 0) {
      this.averagePriceRes.sResponse = list;
      this.priceCloud = false;
      // this.smartTitle = 'Insights filtered by: ' + tag.label;

    } else {
      this.openSnackBar({
        type: 'simple',
        message: 'No Relation Found!',
        action: 'CLOSE',
        duration: 3000
      });

    }
  }
  avgclose() {
    this.priceCloud = true;

    //  console.log('heree', this.averagePriceResFinal)
    this.averagePriceRes.sResponse = this.averagePriceResFinal;
  }
  setPricingtagCloud() {
    //this.averagePriceResTemp = this.averagePriceRes.sResponse;

    this.priceCloud = true;
    let tc: TagCloud;
    this.ptagCloud = [];
    try {
      // this.setbarchrt();
    } catch (Error) {
      console.log(Error);
    }
    const tier1wrds = this.averagePriceRes?.ExtractedConceptsTier1?.trim().split(',');
    const tier1array = tier1wrds;
    for (let x = 0; x < tier1array?.length; x++) {
      tc = new TagCloud();
      tc.label = tier1array[x];
      tc.weight = 10;
      this.ptagCloud.push(tc);
    }


    // this.pieChartData6.push(cnt6);
    const tier2wrds = (this.averagePriceRes == undefined) ? 'That is true : 5>3' : this.averagePriceRes.ExtractedConceptsTier2?.trim().split(',');

    // const tier2wrds = this.smartlist.ExtractedConceptsTier2.replace(/,/g, '');
    const tier2array = tier2wrds;
    for (let x = 0; x < tier2array?.length; x++) {

      tc = new TagCloud();
      tc.label = tier2array[x];
      tc.weight = 5;
      this.ptagCloud.push(tc);

      // this.pieChartLabels.push(tc.label);

      // this.pieChartData6.push(cnt6);


    }
    // console.log( this.smartlist.Word2Vec);
    const tier3wrds = (this.averagePriceRes == undefined) ? 'That is true : 5>3' : this.averagePriceRes?.Word2Vec?.trim().split(',');
    // const tier3wrds = this.smartlist.Word2Vec.replace(/,/g, '');
    const tier3array = tier3wrds;
    // console.log(tier3array);
    for (let x = 0; x < tier3array?.length; x++) {
      tc = new TagCloud();
      tc.label = tier3array[x];
      tc.weight = 1;
      this.ptagCloud.push(tc);

      //  this.pieChartLabels.push(tc.label);

    }
    this.ptagCloud = shuffleptag(this.ptagCloud);
    //console.log((this.ptagCloud))
  }
  averagePriceClick() {
    // this.averagePriceRes.sResponse = this.averagePriceResFinal;
    this.flSelected = 101;
    this.fixaitypeId = '0';
    this.flValSelect = 101;
    this.commonFlagFalse();
    this.setPricingtagCloud();
    if (this.averagePriceRes) {
      this.averagePriceRes.sResponse = this.averagePriceResFinal;

    }
  }
  commonFlagFalse() {
    this.smartTitle = 'Pricing Insights';
    this.averagePrice = true;

    this.dashboard = false;
    this.listViewInsight = true;

    this.tagcloudclick = false;
    // this.smartTitle = 'Sentiment Analysis';
    this.partnerclickflg = false;
    this.inddeep = false;

    // this.notesviewflag=false;
    this.selCat = 0;
    this.InsIQclickflg = false;
    this.cacheClear = false;
    this.chartReports = false;
    this.sentimentclickflg = false;
    this.sentimentSelected = '';

    this.pagemin = 0;
    this.pagemax = this.pageSize;
    this.pageno = this.pageSize;
  }
  getScoreEmoji(score, fromSprite = false) {
    let emo = '';
    if (score === 'Positive') {
      emo = 'sentiment_very_satisfied';
    } else if (score === 'Negative') {
      emo = 'sentiment_very_dissatisfied';
    } else if (score === 'Neutral') {
      emo = 'sentiment_neutral';
    } else {
      emo = 'emoji_emotions';
    }

    // console.log(score);
    if (fromSprite === true) {
      if (emo === 'emoji_emotions') {
        return 'neutral';
      }
      if (emo.split('sentiment_')[1]) {
        return emo.split('sentiment_')[1];
      }
    }

    return emo;
  }

  getSubTitle() {
    if (this.sentimentSelected && this.sentimentSelected.Mode === 2) {
      return this.sentimentSelected.QueryUsed;
    } else {
      return this.sentimentSelected?.cn;
    }
  }

  // getFileNameChart(present) {
  //   switch (present) {
  //     case 'c2.png':
  //       if (this.desiagnApiCall) {
  //         return 'c21.png';
  //       } else {
  //         return present;
  //       }
  //     case 'c3.png':
  //       if (this.desiagnApiCall) {
  //         return 'c31.png';
  //       } else {
  //         return present;
  //       }
  //     case 'c4.png':
  //       if (this.desiagnApiCall) {
  //         return 'c41.png';
  //       } else {
  //         return present;
  //       }
  //     case 'c5.png':
  //       if (this.desiagnApiCall) {
  //         return 'c51.png';
  //       } else {
  //         return present;
  //       }
  //     case 'c6.png':
  //       if (this.desiagnApiCall) {
  //         return 'c61.png';
  //       } else {
  //         return present;
  //       }
  //     case 'c7.png':
  //       if (this.desiagnApiCall) {
  //         return 'c71.png';
  //       } else {
  //         return present;
  //       }
  //     case 'c8.png':
  //       if (this.desiagnApiCall) {
  //         return 'c81.png';
  //       } else {
  //         return present;
  //       }
  //     case 'c9.png':
  //       if (this.desiagnApiCall) {
  //         return 'c91.png';
  //       } else {
  //         return present;
  //       }
  //     case 'c10.png':
  //       if (this.desiagnApiCall) {
  //         return 'c101.png';
  //       } else {
  //         return present;
  //       }
  //     case 'c11.png':
  //       if (this.desiagnApiCall) {
  //         return 'c111.png';
  //       } else {
  //         if (this.view.value('productNewOrExisting') == 1) {
  //           return 'c99.png';
  //         } else if (this.view.value('productNewOrExisting') == 2) {
  //           if (this.sentimentSelected.mode === 2) {
  //             return 'c99.png';
  //           } else {
  //             return 'c99_' + this.sentimentSelected.mode + '.png';
  //           }
  //         }
  //       }
  //   }
  // }

  // getFileIdChart(present, subcode = '0') {
  //   switch (present) {
  //     case '2':
  //       if (this.desiagnApiCall) {
  //         return '21';
  //       } else {
  //         return present;
  //       }
  //     case '3':
  //       if (this.desiagnApiCall) {
  //         return '31';
  //       } else {
  //         return present;
  //       }
  //     case '4':
  //       if (this.desiagnApiCall) {
  //         return '41';
  //       } else {
  //         return present;
  //       }
  //     case '5':
  //       if (this.desiagnApiCall) {
  //         return '51';
  //       } else {
  //         return present;
  //       }
  //     case '6':
  //       if (this.desiagnApiCall) {
  //         return '61';
  //       } else {
  //         return present;
  //       }
  //     case '7':
  //       if (this.desiagnApiCall) {
  //         return '71';
  //       } else {
  //         return present;
  //       }
  //     case '8':
  //       if (this.desiagnApiCall) {
  //         return '81';
  //       } else {
  //         return present;
  //       }
  //     case '9':
  //       if (this.desiagnApiCall) {
  //         return '91';
  //       } else {
  //         return present;
  //       }
  //     case '10':
  //       if (this.desiagnApiCall) {
  //         return '101';
  //       } else {
  //         return present;
  //       }
  //     case '99':
  //       if (this.desiagnApiCall) {
  //         return '111';
  //       } else {
  //         if (!this.reportviewflag) {
  //           if (this.view.value('productNewOrExisting') == 1) {
  //             return '99';
  //           } else if (this.view.value('productNewOrExisting') == 2) {
  //             if (this.sentimentSelected.mode === 2) {
  //               return '99';
  //             } else { return '99_' + this.sentimentSelected.mode; }
  //           }
  //         } else {
  //           if (this.view.value('productNewOrExisting') == 1) {
  //             return '99';
  //           } else if (this.view.value('productNewOrExisting') == 2) {
  //             //  console.log('99_' + subcode);
  //             if (subcode === '0') {
  //               //console.log('1111');
  //               return '99';
  //             } else {
  //               return '99_' + subcode;
  //             }
  //           }
  //         }
  //       }
  //   }
  // }

  clearCache(view, event, data) {
    let eventval = '';
    if (event === '0') {
      eventval = 'all the Insights';
    } else if (event === '99') {
      eventval = 'Sentiment Analysis';
    } else if (event === '101') {
      eventval = 'Pricing Insights';
    } else {
      eventval = 'the Insights ';
    }
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        okbtn: 'Yes',
        cancelbtn: 'No',
        title: 'Confirm Action',
        message: `Are you sure want to clear ${eventval} ?`
      },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result?.data === 'confirm') {
        this.clearInsights = true;

        this.dashCharts = [];
        this.dashCharts.length = 0;
        this.clearCache1(view, event, data);
        if (event === '0') {
          this.clearCache1(view, '101', data);
          this.clearCache1(view, '99', data);

        }
        this.showDashboard();
        this.clearCache1(view, event, data);

      }
    });
  }
  clearCache1(view, event, data) {
    if (event === '101') {
      this.averagePriceRes = null;
      this.averagePrice = false;
    }
    // console.log(event);
    let aitypeVal;
    if (!this.desiagnApiCall) {

      aitypeVal = '1';
    } else {
      aitypeVal = '2';
    }
    view.get(`aiinsights/clearcache/${this.project.id}/${event}/${aitypeVal}`).subscribe(res => {
      if (event === '0' || res === null) {

        // if (this.val(this.project.productNewOrExisting) == 1) {
        //   this.blogStatus = false;
        // } else if (this.val(this.project.productNewOrExisting) == 2) {
        this.blogStatus = false;
        if (this.val(this.project.competitors) == '' || this.val(this.project.competitorspdt) == '') {
          this.compStatus = true;
          this.compStatusDate = true;
        } else {
          this.compStatus = false;
        }
        //  }
        this.trendchart = false;
        this.cacheClear = true;
        this.smartlist = null;
        this.smartlist1 = null;
        this.smartlistItem = null;
        this.hasRecord = 'false';
        this.insight0 = false;
        this.insight1 = false;
        this.insight2 = false;
        this.insight3 = false;
        this.insight4 = false;
        this.insight5 = false;
        this.insight6 = false;
        this.insight7 = false;
        this.insight8 = false;
        view.get(`aiinsights/getsentiment/${this.project.id}/${event}/${aitypeVal}`).subscribe(res1 => {
          this.sentimentResp = res1;
        });
        view.get(`aiinsights/getpricing/${this.project.id}`).subscribe(res2 => {
          // console.log('here');
          this.averagePriceRes = res2[0];
          this.averagePriceResFinal = this.averagePriceRes?.sResponse;
          if (this.averagePriceRes != null) {
            this.setHighLightPrice(this.averagePriceRes, false, (this.existingcomments && this.existingcomments.length > 0));
          }

        });
      } else {
        // console.log(res);
        this.smartlist = res;
        this.smartlist1 = this.smartlist;
        this.sentimentResp = res.sentimentapi;
        this.averagePriceRes = res?.pricingapi[0];
        this.averagePriceResFinal = this.averagePriceRes?.sResponse;
        if (this.averagePriceRes != null) {
          this.setHighLightPrice(this.averagePriceRes, false, (this.existingcomments && this.existingcomments.length > 0));
        }
        // console.log(this.sentimentResp[0]);
        if (this.sentimentResp && this.sentimentResp.length !== 0) {
          // console.log('heree');
          this.sentimentclick(this.sentimentResp[0]);
          this.sentimentapistat = true;
          // this.sentimentapi(view, this.project);
        }
        // console.log(this.smartlist1);
        if (!this.smartlist) {
          //console.log('1111');
          this.hasRecord = 'false';
          this.insight0 = false;
          this.insight1 = false;
          this.insight2 = false;
          this.insight3 = false;
          this.insight4 = false;
          this.insight5 = false;
          this.insight6 = false;
          this.insight7 = false;
          this.insight8 = false;


        } else {
          //   this.smartlistItemDetail=0;
          // console.log('2222');

          this.hasRecord = 'true';
          this.insight0 = false;
          this.insight1 = false;
          this.insight2 = false;
          this.insight3 = false;
          this.insight4 = false;
          this.insight5 = false;
          this.insight6 = false;
          this.insight7 = false;
          this.insight8 = false;
          // console.log( this.smartlistItemDetail);
          this.dashCharts = [];
        }


        this.insightCall(false);

        this.buildChart();
      }
    });
    // Config.setLoaderImage('./assets/images/insiq_ai.png');
    // this.config.start();

    this.updatesentimentflag();
    let dc = new DashCharts();

    this.dashCharts.push(dc);
    this.dashCharts.length = 0;

  }
  getVideoembed(t) {
    //    console.log(t);
    if (t === undefined) {
      return null;
    } else {
      let tArr = t?.split('src=');

      const minute = tArr[1];
      const second = tArr[2];
      tArr = minute?.split(' ');
      if (tArr) {
        const hour = tArr[0];
        return hour.replace(/['"]+/g, '');
      } else {
        return null;

      }

    }

  }

  txtchange(bn) {
    // bn=bn.replace(/\s/g, '');
    // console.log(this.noteOld);
    if (bn.trim() === undefined || bn.trim() === '' || bn.trim() === null || this.noteOld === bn.trim()) {
      this.newnotesave = false;
    } else {
      this.newnotesave = true;
    }
  }
  txtchangeC(bn) {
    if (bn.trim() === undefined || bn.trim() === '' || bn.trim() === null || this.chartnote === bn.trim()) {
      this.newnotesaveC = false;
    } else {
      this.newnotesaveC = true;
    }
  }
  getProjectForm() {
    return this.fb.group({
      id: [''],
      msid: [''],
      msteam: [''],
      productName: [''],
      productTypeId: [''],
      productDescription: [''],
      brandName: [''],
      problemSolve: [],
      productEnviornment: [],
      customer: [],
      apiLanguage: [''],
      productNewOrExisting: [''],
      targetRegion: [],
      competitors: [],
      competitorspdt: [],
      companyname: [],
      // projectDueDate: ['', Validators.required],
      projectOwner: [''],
      keyword31: [],
      keyword6: [],
      keyword10: [],
      keyword2: [],
      keyword32: [],
      keyword8: [],
      keyword11: [],
      keyword7: [],
      keyword5: [],
      keyword4: [],
      keyword9: [],

    });
  }
  projSettingOpen() {
    if (this.projSetting) {
      this.projSetting = false;
    } else {
      this.projSetting = true;
    }
    //this.project = this.view.form.value;



  }
  onMlInputChange(event, cat) {


    const inputValue = (event.target as HTMLInputElement).value;
    console.log(cat, this.flSelected)
    switch (cat) {
      case 199:

        if (this.flSelected === 199 || this.flSelected === 0 || this.flSelected === 101) {
          this.view.set('productDescription', inputValue.toString());

        }

        break;
      case 31:
        this.view.set('keyword31', inputValue.toString());

        break;

      case 6:
        //   console.log('kkuuuuuuuuuuuu')
        this.view.set('keyword6', inputValue.toString());

        break;
      case 10:
        this.view.set('keyword10', inputValue.toString());

        break;
      case 2:
        this.view.set('keyword2', inputValue.toString());

        break;
      case 32:
        this.view.set('keyword32', inputValue.toString());

        break;
      case 8:
        this.view.set('keyword8', inputValue.toString());

        break;
      case 11:
        this.view.set('keyword11', inputValue.toString());

        break;
      case 7:
        this.view.set('keyword7', inputValue.toString());

        break;
      case 5:
        this.view.set('keyword5', inputValue.toString());

        break;
      case 4:
        this.view.set('keyword4', inputValue.toString());

        break;
      case 9:
        this.view.set('keyword9', inputValue.toString());

        break;
      default:
        this.view.set('productDescription', inputValue.toString());
    }

  }
  projSettingOpenButton() {
    // let val;
    // if (this.projSetting) {
    //   val = 'View your product settings ▲';
    // }
    // else {
    //   val = 'View your product settings ▼';
    // }
    return this.projSetting;
  }
  getNotesForm() {
    return this.fb.group({
      id: [''],
      aiId: [''],
      aiUrl: [''],
      aiNotes: [''], // for notes
      aiType: [''],
      aiName: [''],
      aiPrice: [''],
      notesId: [''],
      aiTitle: [''],
      aiImages: [''],
      aiVideos: [''],
      projectId: ['']
    });
  }
  catName(fl, view) {
    return this.config.catName(fl, view.value('productNewOrExisting'), this.desiagnApiCall);
  }

  promptTexts(field, pdtType, labelCat) {
    return this.config.promptTexts(field, pdtType, labelCat);
  }

  designcatName(fl) {
    return this.config.designcatName(fl);
  }
  catId(fl) {
    // console.log(fl);
    return this.config.catId(fl);
  }

  filterBrands(event) {
    let query = event.query;
    query = query.toLowerCase().trim();


    this.filteredBrands = this.productTypes?.filter(pt => pt.title.toLowerCase().indexOf(query) >= 0);


  }
}

function shuffle(array) {
  array.sort(() => Math.random() - 0.5);
}
function shuffleptag(array) {
  return array.sort(() => Math.random() - 0.5);
}

function filter_array(test_array) {
  let index = -1;
  const arr_length = test_array ? test_array.length : 0;
  let resIndex = -1;
  const result = [];

  while (++index < arr_length) {
    const value = test_array[index];
    if (value) {
      result[++resIndex] = value;
    }
  }

  return result;
}

export class DashCharts {
  note: string;
  tagCloud: [];
  chartData: [];
  chartLabel: [];
  aiName: string;
  reportId: number;
  aiTypeID: string;

  constructor() {
  }
}
