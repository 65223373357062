import { Router } from '@angular/router';
import { AIView } from '../ai/insight.view';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../notesalerts/notes-dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { Component, Inject, TemplateRef, OnInit, Optional, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DeepdiveDialogComponent } from '../deepdive-dialog/deepdive-dialog.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Config, View } from 'src/app/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AlertService } from 'src/app/_services';

@Component({
  selector: 'app-summary-dialog',
  templateUrl: './summary-dialog.component.html',
  styleUrls: ['./summary-dialog.component.scss']
})
export class SummaryDialogComponent implements OnInit {
  @ViewChild('editableDiv') editableDiv!: ElementRef;
  updatedText: string = '';
  multilineTextControl: FormControl;
  sanitizedHTML: SafeHtml;

  constructor(
    public ai: AIView,
    public summaryview: View,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    public router: Router,
    public alertService: AlertService,
    public config: Config,

    public dialog: MatDialog, private renderer: Renderer2, private elementRef: ElementRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<SummaryDialogComponent>
  ) {

    //

  }

  ngAfterViewInit() {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = this.data;
    const formattedText = tempElement.textContent || tempElement.innerText;
    this.editableDiv.nativeElement.innerHTML = this.data;

  }
  getNewForm() {
    return this.fb.group({
      projectId: [''],
      fl: [''],
      summary: ['']
    });
  }
  ngOnInit() {
    // this.summaryview.initForm('aiinsights/insightsummary', this.getNewForm());

  }
  convertHtmlToFormattedText(htmlString: string): string {
    const tempElement = this.renderer.createElement('div');
    this.renderer.setProperty(tempElement, 'innerHTML', htmlString);
    const formattedText = tempElement.textContent || tempElement.innerText;
    return formattedText.trim();
  }
  updateHtmlString() {
    this.data = this.editableDiv.nativeElement.innerHTML;    // Optionally, you can extract the plain text without HTML tags.

  }
  sanitizeHTML() {
    this.sanitizedHTML = this.sanitizer.bypassSecurityTrustHtml(this.multilineTextControl.value);
  }
  handleButtonClick() {
    const updatedValue = this.editableDiv.nativeElement.innerHTML;    // Perform any further processing with the updated value
    // const updatedContentWithoutTags = updatedValue.replace(/<\/?div>/g, '');
    try {
      // console.log(updatedContentWithoutTags)
      //this.summaryview.set('projectId', this.ai.project);
      //this.summaryview.set('fl', this.ai.flSelected);
      //this.summaryview.set('summary', updatedValue);

      ///this.summaryview.save('aiinsights/insightsummary', 'Insights Summarization Saved Successfully.');

      let params = new HttpParams();
      params = params.append('projectId', this.ai.project.id);
      params = params.append('fl', this.ai.flSelected);
      params = params.append('summary', updatedValue);
      params = params.append('userId', this.config.getUsername());


      this.httpClient.post<any>(`${Config.apiUrl}/aiinsights/insightsummary`, null, { params }).subscribe(
        (res) => {
          //console.log(res);
          this.alertService.success('Insights Analysis Saved Successfully.');
          // Assuming you have defined the alertService and its success method
          this.dialogRef.close();
        },
        (error) => {
          console.error(error);
        }
      );

    } catch (e) {
      console.log(e)
    }
  }
  updateTextContent() {
    this.sanitizeHTML();
  }
  backtoPage() {
    this.dialogRef.close();
  }

  copyText() {
    this.updatedText = this.editableDiv.nativeElement.innerText;
    if (this.updatedText) {
      if (navigator.clipboard === undefined) {
        const tempTextarea = document.createElement('textarea');
        tempTextarea.value = this.updatedText;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextarea);
      } else {
        navigator.clipboard.writeText(this.updatedText);
      }
    }
  }
}
