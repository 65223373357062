import {
  OnInit,
  Component
} from '@angular/core';
import { Config } from 'src/app/config';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  // header: any;
  sticky = true;
  // menuPosition: any;

  constructor(
    public config: Config
  ) {
    Config.isProjectSelected = false;
  }

  scroll(el: HTMLElement, e) {
    const tgt = e.target;
    // document.querySelectorAll('.helpMenu .menu .active').forEach(function (elem, i, p) {
    //   elem.classList.remove('active');
    // });
    const active = document.querySelectorAll('.helpMenu .menu .active');
    for (let i = 0; i < active.length; i++) {
      const elem = active[i];
      elem.classList.remove('active');
    }

    tgt.closest('li').classList.add('active');

    document.querySelectorAll('.mat-drawer-content')[0].scrollTo({
      top: el.offsetTop + 20,
      behavior: 'smooth'
    });
  }

  ngOnInit() {
  }

}
