<!-- <router-outlet></router-outlet> -->
<div class="container-fluid d-flex h-100 {{homeFlag ? '_homePage' : ''}}"
  [ngClass]="view.isList() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="insiq" *ngIf="!customThemeService.dynamiclogo"
          (click)="linkClick1('project')">
        <img src="{{customThemeService.logo}}" alt="insiq" *ngIf="customThemeService.dynamiclogo"
          (click)="linkClick1('project')">
      </figure>
      <!-- <a [routerLink]="['/project/edit', 1]">Edit</a> -->

      <div class="actions">
        <button type="button" class="btn btn-primary btn-lg rounded justify-content-center" matRipple
          (click)="this.view.state='new'; newform(); this.catSelection=false;this.view.set('mode', null);this.autoModeSelect()">
          <!-- <i class="i i-add"></i> -->
          <span>Add Project</span>
        </button>
      </div>

      <nav class="nav">
        <a class="title" href="javascript:;" (click)="linkClick('project')">
          <i class="i i-projects"></i>
          <span>Projects</span>
        </a>
        <!-- <ul class="stackMenu">
          <li *ngFor="let project of view.list">
            <button type="button" class="btn" [ngClass]="{active: project?.id === view.active?.id}"
              (click)="edit(project); refreshUserDataTable()">
              {{getProjName(project)}}
            </button>
          </li>
        </ul> -->
      </nav>

      <div class="sidebar-footer">
        <button type="button" matRipple (click)="ai.yotubeClick()" class="btn btn-link">
          <mat-icon fontSet="i" class="i-youtube i-1_7x ib-m"></mat-icon>
          <span class="ib-m m-x-5">Watch our How-To Videos</span>
        </button>

        <button type="button" class="btn btn-primary btn-xl curved m-y-10 justify-content-center" matRipple
          (click)="ai.acdemyClick()">
          <span>Join our free academy</span>
        </button>
      </div>
    </div>

    <div class="rightSection" [ngClass]="view.isList() ? 'active' : 'hidden'" tabindex="0">
      <div class="pageWrapper">
        <div class="noDataFound" *ngIf="showvideo&&!homeFlag">
          <img src="./assets/images/insiq_ai.gif" alt="InsIQ" class="shrinkToFit d-block m-x-auto">
        </div>

        <div class="wrap" *ngIf="homeFlag">
          <img src="./assets/images/ai-cloud-chip.png" alt="AI Chip">
          <div class="title">
            Elevate Your Product Strategy with AI-Powered Insights
          </div>
          <p>
            InsIQ Al - Your ultimate Al assistant for all things related to Product development and Research. Gather
            data, analyze it and create customized reports with amazing speed!
          </p>
        </div>

        <div class="projects" *ngIf="view?.list?.length > 0&&!homeFlag">
          <div class="d-flex b-b-1 m-b-10 p-a-0 toolBar">
            <!-- trans-light -->
            <!-- <h2 class="h4 subHead b-b-0 m-b-0" *ngIf="!showvideo">My Projects :: {{filterdProject?.length}} of {{view.list.length}} Records Found</h2> -->

            <div class="row align-items-center ">
              <label class="bigLabel" *ngIf="!showvideo">
                My Projects :: {{filterdProject?.length}} of {{view.list.length}} Records Found
              </label>
            </div>

            <div class="spacer"></div>

            <mat-form-field floatLabel="never" appearance="outline" class="disableMatError">
              <mat-icon fontIcon="i-search" matPrefix></mat-icon>
              <!-- <mat-icon matPrefix>search</mat-icon> -->
              <input matInput (keyup)="filterProject(projSearch.value)" #projSearch placeholder="Search">
            </mat-form-field>
          </div>

          <div class="spacer"></div>

          <mat-card class="p-a-10 m-y-10" color="accent" *ngIf="getPin(filterdProject)?.length!=0">
            <mat-icon fontSet="i" title="Pinned Projects"
              class="i-1_3x text-warning m-l-10 m-t-10 m-l-10">turned_in</mat-icon><span>Pinned Projects</span>
            <div class="d-flex m-t-10">
              <div class="ib-m justify-content-center">
                <mat-chip-list aria-label="Pinned Projects">
                  <mat-chip color="primary" style="cursor: pointer;" [selected]="(i === ai.selCat)? true: false"
                    (click)="selectProject($event, project)" matTooltipPosition="below" [title]="getProjName(project)"
                    *ngFor="let project of getPin(filterdProject)">
                    <i class="i ico text-accent justify-content-center" [ngClass]="{
                      'i-idea': project.productNewOrExisting==1,
                      'i-monitor_product': project.productNewOrExisting==2
                    }"></i>
                    {{getProjName(project)}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </mat-card>
          <!-- <div class="m-b-10 fw" floatLabel="never" class="min w-220px" style="float: right;margin-top: -90px;" matTooltipPosition="above">
            <input placeholder="Search" style="background-color: #FFF; padding: 10px;color: #000;border-radius: 4px;align-items: flex-end" (keyup)="filterProject($event.target.value)">
          </div> -->

          <div class="row">
            <div class="projectCard" [ngClass]="{
                turbo: project.mode === 'Turbo Mode'
              }" *ngFor="let project of filterdProject">
              <!-- <pre>{{project | json}}</pre> -->
              <mat-menu #projectMenu="matMenu" xPosition="before" class="mat-elevation-z8">
                <button mat-menu-item (click)="edit(project);" *ngIf="isPrjAdmin"> Edit</button>
                <button mat-menu-item *ngIf="project.projectPin===0" (click)="pinstatus('1', project.id)">Pin
                  Project</button>
                <button mat-menu-item *ngIf="project.projectPin===1" (click)="pinstatus('0', project.id)">Unpin
                  Project</button>
                <button mat-menu-item (click)="shareProj(project);" *ngIf="isPrjAdmin"> Share Project</button>

                <!-- <button mat-menu-item>Share Project</button> -->
                <!-- <button mat-menu-item (click)="delete(project)" *ngIf="isPrjAdmin">Delete</button> -->
              </mat-menu>

              <mat-card>
                <mat-card-header class="clickable b-b-1 m-b-15" (click)="edit(project)">
                  <mat-card-title>
                    <!-- <mat-icon *ngIf="project.projectPin===1" fontSet="i" class="i-1_1x text-warning">turned_in</mat-icon> -->

                    <span>
                      <i class="i ico text-accent" [ngClass]="{
                        'i-idea': project.productNewOrExisting==1,
                        'i-monitor_product': project.productNewOrExisting==2
                      }"></i>
                    </span>
                    <span class="m-l-10">{{getProjName(project)}}</span>

                  </mat-card-title>

                </mat-card-header>
                <mat-card-content class="clickable" (click)="edit(project)">
                  <!-- <mat-card-subtitle class="new" *ngIf="project.productNewOrExisting === 1">
                    New
                  </mat-card-subtitle>
                  <mat-card-subtitle class="failure" *ngIf="project.productNewOrExisting === 2">
                    Failure Analysis
                  </mat-card-subtitle> -->
                  <div>
                    <p>
                      Owner:
                      <a [ngClass]="{'teamUser': project.projectOwner?.name.trim() !== config.currentUser?.name.trim() }"
                        href="javascript:;">{{ project.projectOwner?.name }}</a>
                    </p>

                    <!-- <p>
                      Users:
                      <span *ngFor="let user of project.projectUserList; last as isLast">
                        <a href="javascript:;">{{user.userId?.name}}</a>
                        <span *ngIf="!isLast">, </span>
                      </span>
                    </p> -->
                    <div class="description">
                      {{project.productDescription}}
                    </div>
                  </div>
                </mat-card-content>
                <mat-card-actions class="b-t-1">
                  <div class="d-flex p-t-5">

                    <div class="spacer"></div>

                    <button type="button" mat-icon-button title="Pin Project" matTooltipPosition="below"
                      (click)="pinstatus('1', project.id)" *ngIf="project.projectPin===0" [matTooltipClass]="'md-fs'">
                      <!-- <mat-icon class="i-1_7x text-grey">turned_in</mat-icon> -->
                      <i class="i i-turned_in_not i-1_7x"></i>
                    </button>

                    <button type="button" mat-icon-button title="Unpin Project" matTooltipPosition="below"
                      (click)="pinstatus('0', project.id)" *ngIf="project.projectPin===1" [matTooltipClass]="'md-fs'">
                      <i class="i i-turned_in i-1_7x  text-warning"></i>
                      <!-- <mat-icon class="i-1_7x text-warning">turned_in</mat-icon> -->
                    </button>
                    <button type="button" mat-icon-button title="Edit Project" matTooltipPosition="below"
                      [matTooltipClass]="'md-fs'" (click)="edit(project);">
                      <mat-icon class="i-1_7x">edit</mat-icon>
                    </button>
                    <button type="button" mat-icon-button title="Share Project" [matTooltipClass]="'md-fs'"
                      *ngIf="project.mode==='Turbo Mode'&& projectOwnerCheck1(project.projectOwner)"
                      (click)="shareProj(project);">
                      <mat-icon class="i-1_7x">share</mat-icon>
                    </button>
                    <button type="button" mat-icon-button title="Delete Project" [matTooltipClass]="'md-fs'"
                      *ngIf="isPrjAdmin" (click)="delete(project)"><mat-icon class="i-1_7x">delete</mat-icon></button>
                  </div>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid d-flex h-100" [ngClass]="view.isForm() ? 'active' : 'hidden'">
  <div class="wrapper" [ngClass]="!ai.display ? 'active' : 'hidden'">
    <div class="leftSection">
      <figure [routerLink]="['/project']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"
        (click)="linkClick('project')" style="cursor:pointer">
        <img src="./assets/images/icon-insiq.png" alt="insiq" *ngIf="!customThemeService.dynamiclogo">
        <img src="{{customThemeService.logo}}" alt="insiq" *ngIf="customThemeService.dynamiclogo">
      </figure>
      <!-- <figure *ngIf="!customThemeService.dynamiclogo">
        <img src="./assets/images/insiq.png" alt="dev2m">
      </figure> -->

      <div class="actions">
        <button type="button" class="btn btn-primary btn-lg rounded justify-content-center" matRipple
          (click)="this.view.state='new'; this.newform(); this.catSelection=false;this.view.set('mode', null);this.autoModeSelect()">
          <!-- <i class="i i-add"></i> -->
          <span>Add Project</span>
        </button>
      </div>

      <nav class="nav">
        <div class="title" [routerLink]="['/project']" [routerLinkActiveOptions]="{exact: true}"
          routerLinkActive="active" (click)="linkClick('project')" style="cursor:pointer">
          <i class="i i-projects"></i>
          <span>Projects</span>
        </div>
        <ul class="stackMenu">
          <li *ngFor="let project of view.list">
            <button type="button" class="btn" [ngClass]="{active: project?.id === view.active?.id}"
              (click)="edit(project); refreshUserDataTable()">
              <!-- <i class="i ico" [ngClass]="{
                'i-idea': project.productNewOrExisting==1,
                'i-monitor_product': project.productNewOrExisting==2
              }"></i> -->
              {{getProjName(project)}}
            </button>
          </li>
        </ul>
      </nav>

      <div class="sidebar-footer">
        <button type="button" matRipple (click)="ai.yotubeClick()" class="btn  btn-link">
          <mat-icon fontSet="i" class="i-youtube i-1_7x ib-m"></mat-icon>
          <span class="ib-m m-x-5">Watch our How-To Videos</span>
        </button>

        <button type="button" class="btn btn-primary btn-xl curved m-y-10 justify-content-center " matRipple
          (click)="ai.acdemyClick()">
          <span>Join our free academy</span>
        </button>

      </div>
    </div>
    <div class="rightSection" [ngClass]="{
      active: view.isForm(),
      hidden: !view.isForm(),
      center: !view.isEdit()&&!catSelection
    }" tabindex="0">
      <div class="pageWrapper {{view.isEdit() ? '_editMode' : ''}}">
        <div class="subHeader" *ngIf="(view.isNew() || view.isEdit())&&catSelection&&!cardDisplay">
          <!-- <span class="badge _warning">{{view.form.value.mode}}</span> -->
          <div class="spacer"></div>
          <div class="common-message" *ngIf="projectOwnerCheck()">
            <div class="ico">
              <i class="i i-info_outline text-warning"></i>
            </div>
            <em>Please fill out the form in English</em>
          </div>
        </div>

        <form [formGroup]="view.form" novalidate (ngSubmit)="onSubmit()">
          <div class="insightDiscovery" *ngIf="!view.isEdit()&&!catSelection&&this.researchMode&&this.turboMode">
            <div class="pageHeading m-b-30">
              InsIQ AI<br>
              Insights Discovery
            </div>

            <div class="stage _1"
              *ngIf="view.form.value.mode!=='Turbo Mode' && view.form.value.mode!=='Research Mode'&&this.researchMode&&this.turboMode">
              <div class="row">
                <div class="col-md-12">
                  <label for="aiMode" class="card customChooser">
                    <input type="radio" name="aiMode" id="aiMode" [checked]="false"
                      (change)="chooseMode($event, 'Research Mode')">
                    <div class="cardContent">
                      <div class="ico">
                        <i class="i i-research"></i>
                      </div>
                      <div class="title">Research</div>
                      <div class="description">
                        Navigate InsIQ's Research Mode to tailor your project with specific insights from a broad
                        feature set, ensuring detailed market exploration.
                      </div>
                      <div class="actions">
                        <span class="btn btn-primary btn-lg">Select</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-md-12">
                  <label for="aiMode" class="card customChooser">
                    <input type="radio" name="aiMode" id="aiMode" [checked]="false"
                      (change)="chooseMode($event, 'Turbo Mode');chooseCategory(1)">
                    <div class="cardContent">
                      <div class="ico">
                        <i class="i i-rocket"></i>
                      </div>
                      <div class="title">Turbo</div>
                      <div class="description">
                        Fast-track your insights with InsIQ's Turbo Mode, a chat-based AI for quick, relevant answers
                        to your market research questions.
                      </div>
                      <div class="actions">
                        <span class="btn btn-primary btn-lg">Select</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div class="stage _2" *ngIf="view.form.value.mode==='Turbo Mode' || view.form.value.mode==='Research Mode'">

              <div class="row">
                <div class="col-md-24">
                  <div class="card customChooser">
                    <div class="cardContent">
                      <div class="ico">
                        <i class="i" [ngClass]="{
                          'i-rocket': view.form.value.mode==='Turbo Mode',
                          'i-research': view.form.value.mode === 'Research Mode'
                        }"></i>
                      </div>
                      <div class="title">{{view.form.value.mode}}</div>
                      <div class="description" *ngIf="view.form.value.mode==='Research Mode'">
                        Navigate InsIQ's Research Mode to tailor your project with specific insights from a broad
                        feature set, ensuring detailed market exploration.
                      </div>
                      <div class="description" *ngIf="view.form.value.mode==='Turbo Mode'">
                        Fast-track your insights with InsIQ's Turbo Mode, a chat-based AI for quick, relevant answers
                        to your market research questions.
                      </div>
                      <div class="actions">
                        <button type="button" class="btn btn-primary btn-lg" (click)="chooseCategory(1)">
                          Explore New Idea
                        </button>
                        <button type="button" class="btn btn-primary btn-lg" (click)="chooseCategory(2)">
                          Monitor My Product
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="mr-auto">
                  <button type="button" class="btn btn-primary btn-link" (click)="view.form.value.mode=null">
                    <i class="i i-keyboard_arrow_left"></i>
                    <span>Back</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="productType" *ngIf="catSelection">
            <span *ngIf="view.form.value.productNewOrExisting === 1 && view.isEdit()"
              class="bar-T m-x-auto justify-content-center m-b-5 d-flex align-items-center">
              <i class="i ico text-accent" title="Explore New Idea" [ngClass]="{
                'i-idea': view.form.value.productNewOrExisting == 1,
                'i-monitor_product': view.form.value.productNewOrExisting == 2
              }"></i>
              {{ view.form.value.brandName ? view.form.value.brandName : view.form.value.productName }}
            </span>

            <span *ngIf="view.form.value.productNewOrExisting === 2 && view.isEdit()"
              class="bar-T m-x-auto justify-content-center d-flex align-items-center">
              <i class="i ico text-accent" title="Monitor your Product" [ngClass]="{
                'i-idea': view.form.value.productNewOrExisting == 1,
                'i-monitor_product': view.form.value.productNewOrExisting == 2
              }"></i>
              {{view.form.value.brandName}}
            </span>

            <div class="pageHeading" *ngIf="view.form.value.productNewOrExisting===1&&!cardDisplay&&view.isNew()">
              Explore New Idea
            </div>
            <div class="pageHeading" *ngIf="view.form.value.productNewOrExisting===2&&!cardDisplay&&view.isNew()">
              Monitor My Product
            </div>
            <div class="pageHeading" *ngIf="cardDisplay">
              Insights Discovery
            </div>
            <div class="pageHeading" *ngIf="view.isEdit()&&!cardDisplay">
              Dashboard
            </div>
            <span *ngIf="cardDisplay" class="bar-T m-x-auto justify-content-center ">Select the Insight category you
              want to
              discover</span>
          </div>

          <div class="bar-T m-x-auto justify-content-center" *ngIf="view.isEdit()&&!cardDisplay">
            <button type="button" matRipple *ngIf="view.active?.projectPin===0||view.active?.projectPin==null"
              (click)="pinstatus('1', view.active.id)" title="Pin Project" class="btn btn-primary btn-link">
              <i class="i i-turned_in_not i-1_5x"></i>
              <!-- <mat-icon fontSet="i" class="i-turned_in i-1_5x ib-m"></mat-icon> -->
              <span class="ib-m m-x-5 f-medium">Pin</span>
            </button>

            <button type="button" matRipple *ngIf="view.active?.projectPin===1" (click)="pinstatus('0', view.active.id)"
              title="Unpin Project" class="btn btn-primary btn-link">
              <!-- <mat-icon fontSet="i" class="i-turned_in i-1_5x ib-m"></mat-icon> -->
              <i class="i i-turned_in i-1_5x"></i>
              <span class="ib-m m-x-5 f-medium">Unpin</span>
            </button>

            <button type="button" matRipple (click)="shareProj(view.active);" title="Share Project"
              class="btn btn-primary btn-link"
              *ngIf="view.isEdit()&&view.form.value.mode==='Turbo Mode'&&projectOwnerCheck()">
              <!-- <mat-icon fontSet="i" class="i-share i-1_5x ib-m"></mat-icon> -->
              <i class="i i-share i-1_5x"></i>
              <span class="ib-m m-x-5 f-medium">Share</span>
            </button>

            <button type="button" [@pulse]="animateState" (@pulse.start)="triggerAnimation()" matRipple
              class="btn btn-primary btn-link" (click)="smartDev(2,view,true)"
              *ngIf="view.isEdit()&&cacheflag&&view.value('mode') == 'Research Mode'">
              <!-- <mat-icon class="i-1_5x ib-m">search</mat-icon> -->
              <i class="i i-ai_results i-1_5x"></i>
              <span class="ib-m m-x-5 f-medium">Last Results</span>
              <!-- <i class="i i-1_5x">get_app</i>-->
            </button>

            <button type="button" [@pulse]="animateState" (@pulse.start)="triggerAnimation()" matRipple
              class="btn btn-primary btn-link" *ngIf="view.isEdit()&&reportflag"
              (click)="ai.reportGenerator(view.value('productName'),view.value('id'))" #search>
              <!-- <mat-icon class="i-1_5x ib-m">AI</mat-icon> -->
              <i class="i i-ai_report i-1_5x"></i>
              <span class="ib-m m-x-5 f-medium">Report</span>
              <!-- <i class="i i-download i-1_5x"></i> -->
            </button>

            <button type="button" [@pulse]="animateState" (@pulse.start)="triggerAnimation()" matRipple
              (click)="favgo(view)" class="btn btn-primary btn-link" *ngIf="view.isEdit()&&favflag">
              <!-- <mat-icon fontSet="i" class="i-favourite i-1_5x ib-m"></mat-icon> -->
              <i class="i i-favourite i-1_5x "></i>
              <span class="ib-m m-x-5">Favorites</span>
            </button>
          </div>
          <div *ngIf="catSelection">
            <div [ngClass]="{
              gridFormWrapper: view.isEdit(),
              _turbo: view.value('mode') == 'Turbo Mode'
            }">
              <!-- [ngClass]="{
                collapsed: !ai.projSettingOpen()
              }" -->
              <button type="button" matRipple class="btn btn-lg btn-primary lighten w-100 t-c" [ngClass]="{
                collapsed: !projSettingOpenButton()
              }" *ngIf="view.isEdit()&&!cardDisplay" (click)="projSettingOpen()">
                <span>View your project input</span>
                <i class="i" [ngClass]="{
                  'i-keyboard_arrow_up': projSettingOpenButton(),
                  'i-keyboard_arrow_down': !projSettingOpenButton()
                }"></i>
              </button>
              <ng-container *ngIf="view.isEdit() ? projSettingOpenButton(): true">
                <!-- EXPLORE NEW+CREATE PROJ -->
                <div class="compactForm" *ngIf="view.value('mode') == 'Turbo Mode'">
                  <div class="formWrapper newProjectForm">
                    <div class="row">
                      <div class="col-lg-24">
                        <label for="describe" class="mandatory">Briefly describe your project or product</label>
                      </div>
                      <div class="col-lg-24">
                        <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                          <textarea matInput placeholder="Describe your project or product" rows="4"
                            (input)="onInputChange($event)" [readonly]="!projectOwnerCheck()"
                            formControlName="productName"></textarea>

                          <!-- <mat-error
                            *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error> -->
                        </mat-form-field>
                      </div>
                      <div class="col-lg-24">
                        <label for="name" class="mandatory">Project name</label>
                      </div>
                      <div class="col-lg-24">
                        <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                          <input type="text" matInput placeholder="Enter Project Name" formControlName="brandName"
                            [readonly]="!projectOwnerCheck()">
                        </mat-form-field>
                      </div>
                      <div class="col-lg-24">
                        <label for="describe" class="mandatory">What do you need to do?</label>
                      </div>
                      <div class="col-lg-24">
                        <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                          <!-- Replace input with textarea -->
                          <textarea matInput formControlName="productDescription" rows="3"
                            (input)="onInputChange1($event)" [readonly]="!projectOwnerCheck()"
                            placeholder="Enter what do you need to do"></textarea>
                        </mat-form-field>


                      </div>
                      <div class="action"></div>
                    </div>
                  </div>
                </div>

                <!-- EXPLORE NEW+CREATE PROJ -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==199&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is exploring a new idea for a product </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                  </div>
                  <div class="line">
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might</span>
                  </div>
                  <div class="line">
                    <span>emphasize </span>
                    <div class="fc_group fc_xl">

                      <div class="inputGroup mlInput " [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="productDescription"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                    </div>
                    <span> and could address to </span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>
                    <span>. We aim to</span>
                  </div>
                  <div class="line">
                    <span>understand potential consumer interest and market viability in the</span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>. Competitor Companies</span>
                  </div>
                  <div class="line">
                    <span>could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and competitor products could be </span>
                    <div class="fc_group fc_xxl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                  </div>
                  <div class="line">
                    <span>Kindly provide insights in </span>
                    <div class="fc_group fc_sm disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span> to help us shape our concept and strategy.</span>
                  </div>
                </div>
                <!-- EXPLORE NEW+Consumer Insights  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==31&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is looking for consumer insights that could inform the development of a product </span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xxl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword31"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <span> and could address to</span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>
                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span>and </span>
                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We seek insights in </span>
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="line">
                    <span>that could help us understand potential consumer needs, preferences, and behaviors in
                      the</span>
                    <div class="fc_group fc_lg">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>
                <!-- EXPLORE NEW+BLOGS  -->

                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==6&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_lg">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is looking for blogs and reviews related to products similar to our new idea </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>
                    </div>
                    <span>, which could be described as </span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">

                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword6"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could address to</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>
                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>
                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We seek content in </span>
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="line">
                    <span>that discusses and evaluates similar products, their features, and benefits in the </span>
                    <div class="fc_group fc_lg">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market to help us</span>
                  </div>
                  <div class="line">
                    <span> shape our product concept.</span>
                  </div>
                </div>
                <!-- EXPLORE NEW+PRICING Insights  -->

                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==101&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_xxl">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, needs pricing insights for products similar to our new idea </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>
                    </div>
                    <span>, which could be described as </span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">
                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="productDescription"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                    </div>
                    <span> and could address to</span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and</span>
                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We require detailed analysis in </span>
                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="line">
                    <span>to help us understand the potential pricing strategies and market opportunities in the</span>
                    <div class="fc_group fc_lg">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>

                <!-- EXPLORE NEW+PRODUCT NEWS Insights  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==10&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, requests recent product news related to products similar to our new idea </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword10"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could address to</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We seek updates in </span>
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="line">

                    <span>about industry developments, innovations, and events that may impact our new product idea in
                      the
                    </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>

                  </div>
                  <div class="line">
                    <span>market.</span>
                  </div>
                </div>

                <!-- EXPLORE NEW+Product and competitor sentiment  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==0&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_lg">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>,is interested in understanding the sentiment towards products similar to our </span>

                  </div>
                  <div class="line">
                    <span>new idea</span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>
                    </div>
                    <span>, which could be</span>
                  </div>
                  <div class="line">
                    <span>described as </span>

                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">

                      <div class="inputGroup mlInput " [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="productDescription"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                    </div>
                    <span> and could</span>

                  </div>
                  <div class="line">
                    <span>address to</span>

                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>

                  </div>

                  <div class="line">

                    <span>and competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We are seeking sentiment analysis in </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to gauge consumer and market reactions towards similar products and their competitors in the
                    </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.This information will help us anticipate potential reception of our product idea and
                      shape
                    </span>
                  </div>
                  <div class="line"><span>our development and marketing strategies accordingly.</span></div>
                </div>
                <!-- EXPLORE NEW+Similar Products or Projects  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==2&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_xl">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, requires information on similar products or projects to our new idea </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xxl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword2"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could address to</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>
                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>.We are interested in understanding the competitive</span>

                  </div>
                  <div class="line">
                    <span> landscape in the </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.Please provide insights in.</span>
                    <div class="fc_group fc_sm disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to help us </span>
                  </div>
                  <div class="line">


                    <span> identify potential strengths, weaknesses, opportunities, and threats.</span>
                  </div>

                </div>
                <!-- EXPLORE NEW+COMPETITORS  -->

                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==32&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_xl">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, needs an overview of potential competitors for our new product idea</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xxl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword32"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could address to</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We seek competitor profiles in </span>

                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="line">
                    <span>to assess their strategies, strengths, and weaknesses in the </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>

                <!-- EXPLORE NEW+INFLUENCERS -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==8&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_xl">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is looking for influencers and experts related to our new product idea </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword8"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could address to</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We request a list of relevant influencers and experts in </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>who could potentially help us promote our new product and improve its visibility in the
                    </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>
                <!-- EXPLORE NEW+Scientific Reserch & Tech  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==11&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_xl">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, requests scientific research related to our new product idea </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword11"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could address to</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We seek research findings and studies in </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to support our product development and ensure its potential efficacy and quality in the</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>
                <!-- EXPLORE NEW+Product safety & Regulatory  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==7&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_lg">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, requires product safety information for similar products to our new idea </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword7"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could address to</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We need insights in </span>
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="line">

                    <span> to ensure our product concept complies with safety regulations and standards in the </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>
                <!-- EXPLORE NEW+Suppliers logistics and related  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==5&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_lg">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is seeking information on suppliers and logistics for similar products to our</span>

                  </div>
                  <div class="line">
                    <span> new
                      idea</span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be</span>


                  </div>
                  <div class="line">
                    <span> described as </span>
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword5"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could</span>

                  </div>
                  <div class="line">
                    <span>address to</span>

                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>

                  </div>

                  <div class="line">
                    <span>and competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We require a list of potential suppliers and</span>

                  </div>
                  <div class="line">
                    <span> logistics partners in </span>
                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span> to help us understand the potential manufacturing and distribution processes</span>

                  </div>
                  <div class="line">
                    <span> for our new product
                      in the </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>
                <!-- EXPLORE NEW+Patents and Trademarks  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==4&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_lg">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>,is seeking information on patents and trademarks related to our new idea </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword4"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could address to</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We require insights in </span>
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="line">

                    <span>to ensure our new product concept complies with legal requirements and avoids potential
                      infringements in the
                    </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div><span>market.</span>
                  </div>
                </div>
                <!-- EXPLORE NEW+Analyze your data   -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '1'&&flSelected==9&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_xl">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is looking to analyze data related to our new idea</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[What would you like to name your product?]"
                        title="[What would you like to name your product?]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword9"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> and could address to</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Who is your Customer?]"
                        title="[Who is your Customer?]" formControlName="customer"></ngx-nitro-multiline>
                    </div>

                    <span>. Competitor Companies could be </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>

                  <div class="line">
                    <span>competitor products could be </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We need insights in </span>
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="line">

                    <span>to understand market trends, consumer behaviors, and competitive landscape in the </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market</span>
                  </div>
                  <div class="line">
                    <span> to shape our new product idea.</span>
                  </div>
                </div>


                <!-- Monitor+CREATE PROJ -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==199&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_lg">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, wants to ensure the success of our </span>
                    <div class="fc_group fc_xxl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                  </div>
                  <div class="line">

                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>

                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span>and</span>
                  </div>
                  <div class="line">

                    <span> emphasize</span>
                    <div class="fc_group fc_xl">

                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="productDescription"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                    </div>
                    <span>Our Product Company Name is</span>
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                    </div>
                    <span>.</span>
                  </div>
                  <div class="line">


                    <span> Competitor Companies are </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and competitor products are</span>
                    <div class="fc_group fc_lg">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>.</span>
                  </div>

                  <div class="line">
                    <span> We aim to gather insights on consumer usage and feedback to enhance our offering.
                      Kindly provide your observations and suggestions </span>

                  </div>
                  <div class="line">
                    <span> in </span>
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to help us improve and optimize our product
                      in the </span>

                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>

                </div>
                <!-- EXPLORE NEW+Consumer Insights  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==31&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>,is looking for consumer insights for our</span>

                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                  </div>
                  <div class="line">
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and </span>
                  </div>
                  <div class="line">
                    <span> emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword31"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span>.Our Product Company Name is</span>

                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                    </div>
                    <span>.</span>
                  </div>
                  <div class="line">

                    <span> Competitor Companies are</span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>

                    <span>and competitor products are </span>

                    <div class="fc_group fc_lg">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>.</span>
                  </div>
                  <div class="line">

                    <span> We aim to understand consumer preferences, behaviors, and purchasing patterns in the
                    </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>

                  <div class="line">
                    <span> Kindly provide insights in</span>


                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to help us tailor our marketing strategies and improve customer satisfaction. </span>
                  </div>
                </div>
                <!-- EXPLORE NEW+BLOGS  -->

                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==6&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is looking for blogs and reviews related to our</span>

                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                  </div>
                  <div class="line">
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which could be described as </span>


                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span>and</span>
                  </div>
                  <div class="line">
                    <span> emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword6"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> Our Product Company Name is </span>

                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                    </div>
                    <span>. </span>
                  </div>
                  <div class="line">

                    <span>Competitor Companies are </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and
                      competitor products are </span>

                    <div class="fc_group fc_lg">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. </span>
                  </div>

                  <div class="line">
                    <span> We seek content in </span>
                    <div class="fc_group fc_sm disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>that discusses and evaluates our product, its features, and benefits in the </span>
                  </div>
                  <div class="line">

                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market to enhance our
                      online presence and reputation.</span>

                  </div>
                </div>
                <!-- Monitor+PRICING Insights  -->

                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==101&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>,needs pricing insights for our </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                  </div>
                  <div class="line">

                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which is described as </span>
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>

                    <span> and emphasize</span>
                  </div>
                  <div class="line">

                    <div class="fc_group fc_xl">

                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="productDescription"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                    </div>
                    <span>Our Product Company Name is</span>
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                    </div>

                    <span>. Competitor </span>
                  </div>
                  <div class="line">
                    <span>Companies are</span>
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span>and competitor products are </span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>.</span>
                  </div>

                  <div class="line">
                    <span> We require detailed analysis in </span>
                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to help us establish
                      competitive pricing strategies and identify potential
                    </span>
                  </div>
                  <div class="line">
                    <span> opportunities in the</span>
                    <div class="fc_group fc_lg">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>

                <!-- Monitor+PRODUCT NEWS Insights  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==10&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, requests recent product news related to our </span>

                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                  </div>
                  <div class="line">
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which is described as </span>


                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and emphasize</span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword10"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> Our Product Company Name is</span>

                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                    </div>
                    <span>. Competitor</span>
                  </div>
                  <div class="line">
                    <span> Companies are </span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and
                      competitor products are </span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>.</span>
                  </div>
                  <div class="line">
                    <span> We seek updates in </span>
                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>about industry developments, innovations, and events that may impact our product's</span>
                  </div>
                  <div class="line">
                    <span> performance in the
                    </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>

                <!-- Monitor+Product and competitor sentiment  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==0&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is interested in understanding the sentiment towards products similar to our </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which is described as </span>
                  </div>
                  <div class="line">

                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and emphasize</span>
                    <div class="fc_group fc_xl">

                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="productDescription"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error>
                    </div>
                    <span> Our Product Company Name is</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                    </div>

                    <span>. Competitor Companies are </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and competitor </span>

                  </div>

                  <div class="line">
                    <span> products are </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We are seeking sentiment analysis in </span>
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                  </div>
                  <div class="line">
                    <span>to gauge consumer and market reactions towards similar products and their competitors in the
                    </span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                  </div>
                  <div class="line">
                    <span>market. This information will help us anticipate potential reception of our product idea and
                      shape our development and marketing
                    </span>
                  </div>
                  <div class="line"><span> strategies accordingly.</span></div>
                </div>
                <!-- Monitor+Similar Products or Projects  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==2&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_sm">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, requires information on similar products or projects to our </span>
                    <div class="fc_group fc_md">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>

                  </div>
                  <div class="line">
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which is described as </span>
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and emphasize</span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword2"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span>Our Product Company Name is</span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                    </div>
                    <span>. Competitor </span>
                  </div>
                  <div class="line">
                    <span>Companies are </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and competitor products are</span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>.</span>
                  </div>
                  <div class="line">
                    <span> We are interested in understanding the competitive landscape in the</span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.Please provide insights </span>
                  </div>
                  <div class="line">
                    <span>in.</span>
                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to help us identify potential strengths, weaknesses, opportunities, and threats. </span>
                  </div>
                </div>
                <!-- Monitor+COMPETITORS  -->

                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==32&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, needs an overview of potential competitors for our</span>
                    <div class="fc_group fc_md">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>

                  </div>
                  <div class="line">
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>
                    </div>
                    <span>, which is described as </span>
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and emphasize</span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword32"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> Our Product Company Name is</span>
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                    </div>
                    <span>. Competitor</span>
                  </div>
                  <div class="line">

                    <span> Companies are</span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and competitor products are </span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. </span>
                  </div>

                  <div class="line">

                    <span> We seek competitor profiles in </span>
                    <div class="fc_group fc_xl disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to assess their strategies, strengths, and weaknesses in the </span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>

                    <span>market.</span>
                  </div>
                </div>

                <!-- Monitor+INFLUENCERS -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==8&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is looking for influencers and experts related to our </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>

                  </div>
                  <div class="line">
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which is described as </span>

                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and emphasize</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <!-- <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}"
                        *ngIf="view.value('keyword8')===''||view.value('keyword8')===null||view.value('keyword8')==='null'">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="productDescription"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error> -->
                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword8"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> Our Product Company Name is</span>
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                    </div>
                    <span>.Competitor</span>
                  </div>
                  <div class="line">

                    <span> Companies are </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and competitor products are</span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. </span>
                  </div>

                  <div class="line">

                    <span> We request a list of relevant influencers and experts in </span>

                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>who could potentially help us promote </span>
                  </div>
                  <div class="line">
                    <span>our product and improve its visibility in the </span>

                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>

                </div>
                <!-- Monitor+Scientific Reserch & Tech  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==11&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, requests scientific research related to our</span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="brandName"></ngx-nitro-multiline>

                    </div>

                  </div>
                  <div class="line">
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>
                    </div>
                    <span>, which is described as </span>
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and emphasize</span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword11"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <span> Our Product Company Name is</span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                    </div>
                    <span>. Competitor</span>
                  </div>
                  <div class="line">
                    <span> Companies are </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and competitor products are </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>.</span>
                  </div>
                  <div class="line">
                    <span>We seek research findings and studies in </span>
                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to support our product development and ensure its potential </span>
                  </div>
                  <div class="line">
                    <span>efficacy and quality in the</span>
                    <div class="fc_group fc_lg">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>
                <!-- Monitor+Product safety & Regulatory  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==7&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_xl">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, requires product safety information for similar products to our </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which is described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword7"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> Our Product Company Name is</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                    </div>

                    <span>. Competitor Companies are </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>

                  <div class="line">
                    <span>competitor products are </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We need insights in </span>
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="line">

                    <span> to ensure our product concept complies with safety regulations and standards in the </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>
                <!-- Monitor+Suppliers logistics and related  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==5&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_lg">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is seeking information on suppliers and logistics for similar products to our</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xxl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which is described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword5"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> Our Product Company Name is</span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                    </div>

                    <span>. Competitor Companies are </span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and </span>

                  </div>

                  <div class="line">
                    <span>competitor products are</span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We require a list of potential suppliers and logistics partners in</span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_lg disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>
                      to help us understand the potential manufacturing and distribution processes for our product in
                      the</span>

                  </div>
                  <div class="line">

                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>
                <!-- Monitor+Patents and Trademarks  -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==4&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_xl">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>,is seeking information on patents and trademarks related to our </span>

                  </div>
                  <div class="line">
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which is described as </span>


                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and might emphasize</span>
                    <div class="fc_group fc_xl">


                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword4"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> Our Product Company</span>

                  </div>
                  <div class="line">
                    <span> Name is</span>
                    <div class="fc_group fc_xl">


                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>

                    </div>

                    <span>. Competitor Companies are </span>

                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span>and</span>
                  </div>

                  <div class="line">

                    <span>competitor products are </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. We require insights in </span>
                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to ensure our</span>
                  </div>
                  <div class="line">

                    <span> product concept complies with legal requirements and avoids potential
                      infringements in the
                    </span>
                    <div class="fc_group fc_lg">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market.</span>
                  </div>
                </div>
                <!--Monitor+Analyze your data   -->
                <div class="gridForm"
                  *ngIf="view.value('productNewOrExisting') == '2'&&flSelected==9&&!cardDisplay&&view.value('mode') == 'Research Mode'">
                  <div class="line">
                    <span>Our project owner, </span>
                    <div class="fc_group fc_md">
                      <div class="ui-fluid">
                        <p-autoComplete #projectOwner styleClass="w-100" formControlName="projectOwner" [minLength]="1"
                          [suggestions]="userFiltered" placeholder="Project Owners" title="Project Owners" field="name"
                          [forceSelection]="true"
                          [readonly]="view.isEdit()&&(!(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id))"
                          [dropdown]="view.isNew()||(config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id)"
                          [appFocusNext]="(view.invalid('projectOwner'))?projectOwner:projectOwner"
                          (completeMethod)="filterOwner($event)"></p-autoComplete>
                        <mat-error class="mat-error errorClass" *ngIf="view.invalid('projectOwner')">
                          {{view.error('projectOwner')}}
                        </mat-error>
                      </div>
                    </div>
                    <span>, is looking to analyze data related to our</span>
                    <div class="fc_group fc_xxl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Name]" title="[Product Name]"
                        formControlName="brandName"></ngx-nitro-multiline>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('brandName')">{{view.error('brandName')}}</mat-error>
                    </div>
                  </div>
                  <div class="line">
                    <span>in the </span>
                    <div class="fc_group fc_xl">
                      <p-autoComplete formControlName="productTypeId" [suggestions]="filteredBrands"
                        (completeMethod)="filterBrands($event)" styleClass="w-100" [minLength]="0"
                        placeholder="[Product Category]" title="[Product Category]" field="title" [dropdown]="false">
                      </p-autoComplete>
                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productTypeId')">{{view.error('productTypeId')}}</mat-error>

                    </div>
                    <span>, which is described as </span>
                    <div class="fc_group fc_xl">

                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Description]"
                        title="[Product Description]" formControlName="productName"></ngx-nitro-multiline>

                      <mat-error class="errorClass"
                        *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>
                    </div>
                    <span> and emphasize</span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_xl">

                      <div class="inputGroup mlInput" [ngClass]="{_R: ai.keywordStat(view)}">
                        <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                          (input)="onMlInputChange($event)" placeholder="[Keywords]" title="[Keywords]"
                          formControlName="keyword9"></ngx-nitro-multiline>
                        <div class="action">
                          <button type="button" class="btn btn-primary lighten" (click)="keywordSuggest(view)"
                            *ngIf="ai.keywordStat(view)">
                            <i class="i i-search i-1_5x" title="Get keyword suggestions"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                    <span> Our Product Company Name is</span>
                    <div class="fc_group fc_xl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Product Company Name]"
                        title="[Product Company Name]" formControlName="companyname"></ngx-nitro-multiline>
                    </div>
                    <span>. Competitor</span>
                  </div>
                  <div class="line">

                    <span> Companies are </span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Companies]" title="[Competitor Companies]"
                        formControlName="competitors"></ngx-nitro-multiline>
                    </div>
                    <span> and competitor products are</span>
                    <div class="fc_group fc_xxl">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (keyup)="txtchange($event.target.value)" (input)="onMlInputChange($event)"
                        placeholder="[Competitor Products]" title="[Competitor Products]"
                        formControlName="competitorspdt"></ngx-nitro-multiline>
                    </div>
                    <span>. </span>
                  </div>
                  <div class="line">
                    <span> We need insights in </span>
                    <div class="fc_group fc_md disableMatError">
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <mat-select [(value)]="selectedLanguage" formControlName="apiLanguage" title="Language">
                          <mat-option *ngFor="let lang of apiLanguage" [value]="lang.languageCode">
                            {{lang.languageName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span>to understand market trends, consumer behaviors, and competitive landscape in the </span>
                  </div>
                  <div class="line">
                    <div class="fc_group fc_lg">
                      <ngx-nitro-multiline class="customMultilineInput" [fontSize]="16"
                        (input)="onMlInputChange($event)" placeholder="[Region]" title="[Region]"
                        formControlName="targetRegion"></ngx-nitro-multiline>
                    </div>
                    <span>market to shape our product idea.</span>
                  </div>
                </div>
                <div class="row justify-content-center m-b-20">
                  <!-- <button type="button" matRipple [disabled]="!view.valid()" #update (click)="smartDev(2,view,true)"
                    *ngIf="view.isEdit()&&cacheflag&&view.value('mode') == 'Turbo Mode'"
                    class="btn btn-primary curved min w-160px">
                    <i class="i i-ai_results i-1_5x"></i>
                    <span>Last Results</span>
                  </button> -->
                  <!-- insightGenerator(view) -->
                  <button type="submit" matRipple [disabled]="!view.valid()"
                    *ngIf="view.isEdit()&&isPrjAdmin&&view.value('mode') == 'Research Mode'" #update
                    class="btn btn-primary curved m-x-5 m-r-10">
                    <i class="i i-save i-1_5x m-r-10"></i>
                    <span>Save Project</span>
                  </button>
                  <button *ngIf="view.isEdit()&&view.value('mode') == 'Turbo Mode'" #update matRipple type="button"
                    [disabled]="!view.valid()" (click)="newGo()" class="btn btn-primary curved m-l-10 min w-200px">
                    <span>Go</span>
                    <i class="i i-send i-1_5x m-l-10"></i>
                  </button>
                </div>
              </ng-container>

            </div>

            <!-- <pre>{{view.form.value | json}}</pre> -->
            <div class="menuCardGrid" *ngIf="cardDisplay&&view.value('mode') == 'Research Mode'">
              <label class="menu-item" (click)="flSelectorimg(31, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/1.png" alt="{{ai.catName('31',view)}}" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('31',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(6, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/2.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('6',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(101, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/3.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label"> Pricing Insights</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(10, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/4.png" alt="{{ai.catName('10',view)}}" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label"> {{ai.catName('10',view)}}</div>
                  </div>
                </div>
              </label>

              <label class="menu-item" (click)="flSelectorimg(2, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/6.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('2',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(32, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/7.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('32',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(8, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/8.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('8',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(11, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/9.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('11',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(7, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/10.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('7',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(5, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/11.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('5',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(4, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/12.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('4',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(9, $event)" *ngIf="ai.featureChecker(18)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/13.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label">{{ai.catName('9',view)}}</div>
                  </div>
                </div>
              </label>
              <label class="menu-item" (click)="flSelectorimg(0, $event)">
                <div class="card">
                  <div class="cardContent">
                    <figure>
                      <img src="assets/images/insights/5.png" />
                    </figure>
                  </div>
                  <div class="cardFooter">
                    <div class="label"> Product and Competitor Sentiment
                    </div>
                  </div>
                </div>
              </label>
            </div>

            <div class="m-y-20" *ngIf="view.isEdit()&&!cardDisplay&&view.value('mode') == 'Research Mode'">
              <mat-chip-list aria-label="Insight categories">
                <mat-chip matTooltipPosition="below" [title]="ai.catName('31',view)" (click)="flSelector(31)"
                  [ngClass]="flSelected==31?'active':''">
                  {{ai.catName('31',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('6',view)" (click)="flSelector(6)"
                  [ngClass]="flSelected==6?'active':''">
                  {{ai.catName('6',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('101',view)" (click)="flSelector(101)"
                  [ngClass]="flSelected==101?'active':''">
                  {{ai.catName('101',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('10',view)" (click)="flSelector(10)"
                  [ngClass]="flSelected==10?'active':''">
                  {{ai.catName('10',view)}}
                </mat-chip>

                <mat-chip matTooltipPosition="below" [title]="ai.catName('2',view)" (click)="flSelector(2)"
                  [ngClass]="flSelected==2?'active':''">
                  {{ai.catName('2',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('32',view)" (click)="flSelector(32)"
                  [ngClass]="flSelected==32?'active':''">
                  {{ai.catName('32',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('8',view)" (click)="flSelector(8)"
                  [ngClass]="flSelected==8?'active':''">
                  {{ai.catName('8',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('11',view)" (click)="flSelector(11)"
                  [ngClass]="flSelected==11?'active':''">
                  {{ai.catName('11',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('7',view)" (click)="flSelector(7)"
                  [ngClass]="flSelected==7?'active':''">
                  {{ai.catName('7',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('5',view)" (click)="flSelector(5)"
                  [ngClass]="flSelected==5?'active':''">
                  {{ai.catName('5',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('4',view)" (click)="flSelector(4)"
                  [ngClass]="flSelected==4?'active':''">
                  {{ai.catName('4',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('9',view)" (click)="flSelector(9)"
                  *ngIf="ai.featureChecker(18)" [ngClass]="flSelected==9?'active':''">
                  {{ai.catName('9',view)}}
                </mat-chip>
                <mat-chip matTooltipPosition="below" [title]="ai.catName('0',view)" (click)="flSelector(0)"
                  [ngClass]="flSelected==0?'active':''">
                  {{ai.catName('0',view)}}
                </mat-chip>
              </mat-chip-list>
            </div>

            <div *ngIf="showUserToProject&&view.value('mode') == 'Research Mode'"
              class="b-a-1 p-a-15 p-b-0 m-t-15 m-b-30">
              <!-- User ADD -->
              <div class="row">
                <div class="col-xl-8">
                  <span class="ui-fluid" title="" matTooltipPosition="above">
                    <label class="bigLabel">Team Members</label>
                    <div style="margin: .25em 0;">
                      <p-autoComplete [(ngModel)]="userSelected" [ngModelOptions]="{standalone: true}"
                        [suggestions]="userFiltered" (completeMethod)="filterUser($event)"
                        styleClass="w-100 appearance-fill m-t-0" [minLength]="1" placeholder="Team Members" field="name"
                        [multiple]="true" [dropdown]="true">
                      </p-autoComplete>
                    </div>
                  </span>
                </div>
                <div class="col-xl-8">
                  <label class="bigLabel">Member Role</label>
                  <mat-form-field class="fw" appearance="outline" title="" matTooltipPosition="above">
                    <mat-select [(value)]="userRoleSelected" placeholder="Role">
                      <mat-option *ngFor="let role of userRoleList" [value]="role">
                        {{role.description}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="p-t-25 t-r">
                  <button type="button" [disabled]="disableAddUserToProject()" (click)="addUserToProject()" matRipple
                    class="btn btn-primary lighten m-b-20 m-x-5">Assign To Project</button>
                  <button type="button" (click)="cancelAddUser()" matRipple
                    class="btn btn-danger lighten m-b-20 m-x-5">Cancel</button>
                </div>
              </div>
            </div>
            <div class="bar-T m-y-20 align-items-center">
              <div class="m-x-auto d-flex">
                <button type="button" matRipple (click)="nextTab()" *ngIf="designModule"
                  class="curved m-x-5 btn-cancel btn btn-primary btn-lg">Next</button>
                <button type="submit" matRipple [disabled]="view.invalid()" *ngIf="view.isNew()&&!cardDisplay" #create
                  class="curved m-x-5 btn btn-primary btn-xl m-l-10">Create Project</button>

                <button type="button" (click)="insightGenerator(view)" [disabled]="flSelected==199"
                  *ngIf="(view.isEdit()&&isPrjAdmin&&!cardDisplay)&&view.value('mode') == 'Research Mode'" #update
                  matRipple class="btn btn-primary btn-xl curved m-x-5">Update Insights</button>
                <button type="button" (click)="insightGenerator(view)" [disabled]="flSelected==199" *ngIf="cardDisplay"
                  #update matRipple class="btn btn-primary btn-xl curved m-x-5">Generate Insights</button>

                <div class="m-a-auto" *ngIf="view.value('mode') == 'Research Mode'">
                  <div class="action" style="min-width: 100px; text-align: right;"
                    *ngIf="!showUserToProject&&config.currentUser?.pin!=='USR'">
                    <button type="button" matRipple (click)="setShowUserToProject()"
                      class="btn btn-link btn-primary btn-lg">
                      <span class="m-l-10">Invite Team Members</span>
                      <i class="i i-member_add m-l-5"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center" *ngIf="view.value('mode') == 'Research Mode'">
              <div class="col-md-12">
                <mat-card class="b-a-2 b-a-primary p-a-15"
                  *ngIf="hasMs&&view.isEdit()&&(view.value('msteam')== undefined||view.value('msteam')== null)">
                  <h2 class="h3 f-bold">Create MS Teams Channel</h2>
                  <div class="highlight d-flex align-items-center m-b-30">
                    <i class="i i-ms-team i-4x ib-m text-accent"></i>
                    <p class="ib-m p-l-15 m-b-0">
                      InsIQ can create a channel for this project in your MS Teams account.
                    </p>
                  </div>
                  <div class="d-flex">
                    <!-- <label class="bigLabel">Name of the Channel</label> -->
                    <mat-form-field title="" matTooltipPosition="above" class="w-100" appearance="outline">
                      <input matInput [value]="view.value('productName')" placeholder="Name of the Channel"
                        maxlength="256" id="channelname">
                      <mat-hint class="text-danger" *ngIf="channelflag">Ms Teams Channel Name is Mandatory !!
                      </mat-hint>
                    </mat-form-field>

                    <button type="button" (click)="MSTeamChannel()" matRipple
                      class="btn btn-sm btn-primary m-x-5 w-180px t-c" style="height: 36px;">Create Now</button>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </form>
        <div *ngIf="(userDataTable?.data?.length!=0&&userDataTable?.data)&&catSelection&&!cardDisplay">
          <!-- trans-light -->
          <table mat-table [dataSource]="userDataTable" matSort class="w-100">
            <!-- photo Column -->
            <ng-container matColumnDef="photo">
              <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
              <td mat-cell *matCellDef="let row">
                <figure>
                  <img src="assets/images/users/default.png" alt="default">
                </figure>
              </td>
            </ng-container>

            <!-- Name Column d-->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let pu">{{pu.userId.name}}</td>
            </ng-container>

            <ng-container matColumnDef="loginKey">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Role</th>
              <td mat-cell *matCellDef="let pu" title="Role: {{pu.roleCode?.description}}" matTooltipPosition="below">
                {{pu.roleCode?.description}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let pu">

                <button mat-icon-button (click)="removeProjectUser(pu)" title="Remove" matTooltipPosition="below"
                  *ngIf="config.currentUser?.pin!=='USR'||selectedProject?.projectOwner?.id === config.currentUser?.id||view.value('projectOwner')?.id === config.currentUser?.id">
                  <i class="i i-delete i-1_3x text-danger"></i>
                  <!-- <mat-icon fontSet="i" aria-label="remove" class="i-1_3x">delete_sweep</mat-icon> -->
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['name','loginKey','action']"></tr>
            <tr mat-row *matRowDef="let pu; columns: ['name','loginKey','action']"></tr>
          </table>
          <!-- <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions"></mat-paginator> -->
        </div>

        <mat-tab-group mat-stretch-tabs color="primary" (selectedTabChange)="tabClick($event)"
          class="InsIQTabs mgt-x--30" [selectedIndex]="pageId" dynamicHeight *ngIf="designModule || riskanalysismodule">
          <mat-tab label="Design" *ngIf="designModule">
            <div class="t-c" *ngIf="!designModule">
              <div class="h1 o-50">Coming Soon...</div>
            </div>

            <form [formGroup]="view.form" novalidate (ngSubmit)="onSubmit()" *ngIf="designModule">
              <div class="d-flex align-items-center">
                <div class="m-x-auto">
                  <div class="productType">
                    <i class="ico i" [ngClass]="{
                      'i-idea': view.form.value.productNewOrExisting===1,
                      'i-monitor_product': view.form.value.productNewOrExisting===2
                    }"></i>
                    <div class="label" *ngIf="view.form.value.productNewOrExisting===1">
                      <div>Explore</div>
                      <div>New Idea</div>
                    </div>
                    <div class="label" *ngIf="view.form.value.productNewOrExisting===2">
                      <div>Monitor</div>
                      <div>your Product</div>
                    </div>
                  </div>

                </div>
                <div class="m-l-auto">
                  <div class="ib-m m-x-5" *ngIf="view.isEdit()&&cacheflag">
                    <button type="button" [@pulse]="animateState" (@pulse.start)="triggerAnimation()" mat-raised-button
                      class="t-normal curved text-accent  f-400" (click)="designsmartDev(2,view,true)">
                      <!-- <mat-icon class="i-1_5x ib-m">search</mat-icon> -->
                      <i class="i i-ai_results i-1_5x"></i>
                      <span class="ib-m m-x-5 f-medium">Last Results</span>
                    </button>
                  </div>
                  <div class="ib-m m-x-5" *ngIf="view.isEdit()&&reportflag">
                    <button type="button" [@pulse]="animateState" (@pulse.start)="triggerAnimation()" mat-raised-button
                      class="t-normal curved text-accent"
                      (click)="ai.reportGenerator(view.value('productName'),view.value('id'))" #search>
                      <i class="i i-ai_report i-1_5x"></i>
                      <span class="ib-m m-l-5 m-r-2">Report</span>

                    </button>
                  </div>
                  <div class="ib-m m-x-5" *ngIf="view.isEdit()&&reportflag">
                    <button type="button" [@pulse]="animateState" (@pulse.start)="triggerAnimation()"
                      *ngIf="view.isEdit()&&favflag" mat-raised-button (click)="ai.wishList(view)"
                      class="ib-m m-x-15 curved text-accent t-normal f-400">
                      <mat-icon fontSet="i" class="i-favourite i-1_5x ib-m"></mat-icon>
                      <span class="ib-m">Fav</span>
                    </button>
                  </div>
                  <div class="ib-m">
                    <div [ngClass]="{tourElement: tourflag}">
                      <i class="hotspot onTour"></i>
                    </div>

                    <mat-menu #appMenu1="matMenu" xPosition="before">

                      <h2 class="subTitle">SubHeading</h2>
                      <button mat-menu-item (click)="designsmartDev(2,view)"
                        [disabled]="!view.valid()">{{ai.designcatName('2')}}</button>
                      <button mat-menu-item (click)="designsmartDev(3,view)"
                        [disabled]="!view.valid()">{{ai.designcatName('3')}}</button>
                      <button mat-menu-item (click)="designsmartDev(4,view)"
                        [disabled]="!view.valid()">{{ai.designcatName('4')}}</button>
                      <button mat-menu-item (click)="designsmartDev(5,view)"
                        [disabled]="!view.valid()">{{ai.designcatName('5')}}</button>
                      <button mat-menu-item (click)="designsmartDev(6,view)"
                        [disabled]="!view.valid()">{{ai.designcatName('6')}}</button>

                    </mat-menu>

                    <button type="button" mat-raised-button [matMenuTriggerFor]="appMenu1"
                      class="curved text-accent t-normal" [@pulse]="animateState" (@pulse.start)="triggerAnimation()"
                      *ngIf="view.isEdit()">
                      <mat-icon class="i-1_5x ib-m">AI</mat-icon>
                      <span class="ib-m">Ask TOM</span>
                      <i class="i i-2x">arrow_drop_down</i>
                    </button>
                  </div>
                </div>
              </div>
              <div style="font-size: 14px;">
                <div class="">
                  <!-- trans-light -->
                  <div class="row">
                    <div class="col-24 col-xl-24">
                      <div class="d-flex">
                        <label class="bigLabel">What problem will the product solve?</label>
                        <div class="ib-m">
                          <app-quick-help [layout]="prodtPrblm"></app-quick-help>
                        </div>
                      </div>
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" #problemSolve
                          cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10" maxlength="256" formControlName="problemSolve"
                          autocomplete="off"
                          [appFocusNext]="(view.invalid('problemSolve'))?problemSolve:productEnviornment"
                          placeholder="This is not a story. The shorter the better. Try stating the problems that the product can solve"></textarea>
                        <mat-error *ngIf="view.invalid('problemSolve')">{{view.error('problemSolve')}}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-12">
                      <label class="bigLabel">What kind of environment will the product be in?</label>
                      <app-quick-help [layout]="pdtEnvt"></app-quick-help>
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="Try stating the kind of environment will the product be in."
                          formControlName="productEnviornment" #productEnviornment
                          [appFocusNext]="(view.invalid('productEnviornment'))?productEnviornment:productChannel">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-12">
                      <label class="bigLabel">What channels will the product be sold ?</label>
                      <app-quick-help [layout]="pdtChannel" [align]="'right'"></app-quick-help>
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="Provide the details of channels will the product be sold through."
                          formControlName="productChannel" #productChannel
                          [appFocusNext]="(view.invalid('productChannel'))?productChannel:prodnVol">
                        <mat-error *ngIf="view.invalid('productChannel')">{{view.error('productChannel')}}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <label class="bigLabel">What kind of annual volume of production do you expect?</label>
                      <app-quick-help [layout]="pdtVol"></app-quick-help>
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="Provide expected annual volume of production"
                          formControlName="prodnVol" #prodnVol
                          [appFocusNext]="(view.invalid('prodnVol'))?prodnVol:manufacturer">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-12">
                      <label class="bigLabel">Who will manufacture your product?</label>
                      <app-quick-help [layout]="projManufacturer" [align]="'right'"></app-quick-help>
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="State your product manufacturers" formControlName="manufacturer"
                          #manufacturer [appFocusNext]="(view.invalid('manufacturer'))?manufacturer:productRegulation">
                        <mat-error *ngIf="view.invalid('manufacturer')">{{view.error('manufacturer')}}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="d-flex">
                    <label class="bigLabel">
                      Please tell us if you know how your product is regulated by any government or other entities.
                    </label>
                    <app-quick-help [layout]="projRegulation"></app-quick-help>
                  </div>
                  <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                    matTooltipPosition="above">
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                      formControlName="productRegulation" autocomplete="off" #productRegulation
                      placeholder="Here you can elaborate a bit. Again, it’s not an essay! Try stating what the various regulations for your product."
                      cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                    <mat-error *ngIf="view.invalid('productRegulation')">{{view.error('productRegulation')}}</mat-error>
                  </mat-form-field>
                  <div class="row">
                    <div class="col-xl-12">
                      <label class="bigLabel">How much do you want to sell it for?</label>
                      <app-quick-help [layout]="pdtSell"></app-quick-help>
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="Try stating how much you want to sell it for?"
                          formControlName="sellRate" #sellRate>
                      </mat-form-field>
                    </div>
                    <!-- COMMENTED REPEAT IN RESERCH MODULE -->
                    <div class="col-xl-12" *ngIf="view.value('productNewOrExisting') == '2'">
                      <label class="bigLabel">Who are your main competitors?</label>
                      <app-quick-help [layout]="pdtComp" [align]="'right'"></app-quick-help>
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="Provide the details regarding your main competitors"
                          formControlName="competitors" #competitors
                          [appFocusNext]="(view.invalid('customer'))?customer:brandName">
                        <mat-error *ngIf="view.invalid('competitors')">{{view.error('competitors')}}</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-12" *ngIf="view.value('productNewOrExisting') == '1'">
                      <label class="bigLabel p-b-0">Who is the end customer?</label>
                      <app-quick-help [layout]="endCust"></app-quick-help>
                      <mat-form-field class="fw" floatLabel="never" appearance="outline" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="Provide your end customer details" formControlName="customer"
                          #customer>
                        <mat-error *ngIf="view.invalid('customer')">{{view.error('customer')}}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="m-r-auto">
                    </div>
                    <div class="m-l-auto">
                      <div class="ib-m">
                        <div *ngIf="!showUserToProject">
                          <button type="button" (click)="setShowUserToProject()" mat-stroked-button
                            class="curved m-b-30" *ngIf="isPrjAdmin">Add Team Members</button>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div *ngIf="showUserToProject" class="b-a-1 p-a-15 p-b-0 m-t-15 m-b-30">
                    <!-- User ADD -->
                    <div class="row">
                      <div class="col-xl-8">
                        <span class="ui-fluid" title="" matTooltipPosition="above">
                          <label class="bigLabel">Team Members</label>
                          <div style="margin: .25em 0;">
                            <p-autoComplete [(ngModel)]="userSelected" [ngModelOptions]="{standalone: true}"
                              [suggestions]="userFiltered" (completeMethod)="filterUser($event)"
                              styleClass="w-100 appearance-fill m-t-0" [minLength]="1" placeholder="Team Members"
                              field="name" [multiple]="true" [dropdown]="true">
                            </p-autoComplete>
                          </div>
                        </span>
                      </div>
                      <div class="col-xl-8">
                        <label class="bigLabel">Member Role</label>
                        <mat-form-field class="fw" appearance="outline" title="" matTooltipPosition="above">
                          <mat-select [(value)]="userRoleSelected" placeholder="Role">
                            <mat-option *ngFor="let role of userRoleList" [value]="role">
                              {{role.description}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xl-8 p-t-25 t-r">
                        <button type="button" [disabled]="disableAddUserToProject()" (click)="addUserToProject()"
                          matRipple class="btn btn-primary lighten m-b-20 m-x-5">Assign To Project</button>
                        <button type="button" (click)="cancelAddUser()" matRipple
                          class="btn btn-danger lighten m-b-20 m-x-5">Cancel</button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="m-r-auto">
                    </div>
                    <div class="m-l-auto">
                      <div class="bar-T m-b-10">
                        <button type="button" (click)="cancelDesign()" mat-raised-button
                          class="curved m-x-5 btn-cancel">Back</button>
                        <button type="submit" [disabled]="view.invalid()" *ngIf="view.isNew()" mat-raised-button
                          color="primary" #create class="curved m-x-5">Create</button>
                        <button type="submit" [disabled]="!view.valid()" *ngIf="view.isEdit()&&isPrjAdmin"
                          mat-raised-button color="primary" #update class="curved m-x-5">Update</button>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-md-12">
                    <mat-card class="b-a-2 b-a-primary p-a-15"
                      *ngIf="hasMs&&view.isEdit()&&(view.value('msteam')== undefined||view.value('msteam')== null)">
                      <h2 class="h3 f-bold">Create MS Teams Channel</h2>
                      <div class="highlight d-flex align-items-center m-b-30">
                        <i class="i i-ms-team i-4x ib-m text-accent"></i>
                        <p class="ib-m p-l-15 m-b-0">
                          InsIQ can create a channel for this project in your MS Teams account.
                        </p>
                      </div>
                      <div class="d-flex">
                        <!-- <label class="bigLabel">Name of the Channel</label> -->
                        <mat-form-field title="" matTooltipPosition="above" class="w-100">
                          <input matInput [value]="view.value('productName')" placeholder="Name of the Channel"
                            maxlength="256" id="channelname">
                          <mat-hint class="text-danger" *ngIf="channelflag">Ms Teams Channel Name is Mandatory !!
                          </mat-hint>
                        </mat-form-field>

                        <button type="button" (click)="MSTeamChannel()" matRipple
                          class="btn btn-sm btn-primary m-x-5 w-180px t-c" style="height: 36px;">Create Now</button>
                      </div>
                    </mat-card>
                  </div>
                </div>

                <div class="">
                  <!-- trans-light -->
                  <table mat-table [dataSource]="userDataTable" matSort class="w-100">
                    <!-- photo Column -->
                    <ng-container matColumnDef="photo">
                      <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
                      <td mat-cell *matCellDef="let row">
                        <figure>
                          <img src="assets/images/users/default.png" alt="default">
                        </figure>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                      <td mat-cell *matCellDef="let pu">{{pu.userId.name}}</td>
                    </ng-container>


                    <ng-container matColumnDef="loginKey">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Role</th>
                      <td mat-cell *matCellDef="let pu" title="Role: {{pu.roleCode.description}}"
                        matTooltipPosition="below">{{pu.roleCode.description}}</td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                      <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
                      <td mat-cell *matCellDef="let pu">

                        <button mat-icon-button (click)="removeProjectUser(pu)" title="Remove"
                          matTooltipPosition="below">
                          <mat-icon fontSet="i" aria-label="remove" class="i-1_3x text-danger">remove_circle_outline
                          </mat-icon>
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="['name','loginKey','action']"></tr>
                    <tr mat-row *matRowDef="let pu; columns: ['name','loginKey','action']"></tr>
                  </table>
                  <!-- <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions"></mat-paginator>-->
                </div>
              </div>
            </form>
          </mat-tab>
          <mat-tab label="Risk Analysis" *ngIf="riskanalysismodule">
            <div class="t-c" *ngIf="!riskanalysismodule">
              <div class="h1 o-50">Coming Soon...</div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <div class="wrapper" [ngClass]="ai.display ? 'active' : 'hidden'">
    <app-ai [ai]="ai" class="w-100"></app-ai>
  </div>
</div>