export class Project {
  id: number;
  productName: string;
  mode: string;
  productNewOrExisting: any;
  productDescription: any;
  productTypeId: any;
  customer: any;
  brandName: any;
  productBenifit: any;
  targetRegion: any;
  similarProduct: any;
  inspiration: any;
  competitors: any;
  competitorspdt: any;
  companyname: any;
  apiLanguage: any;
  problemSolve: any;
  productEnviornment: any;
  productChannel: any;
  prodnVol: any;
  manufacturer: any;
  productRegulation: any;
  sellRate: any;
  keyword31: any;
  keyword6: any;
  keyword10: any;
  keyword2: any;
  keyword32: any;
  keyword8: any;
  keyword11: any;
  keyword7: any;
  keyword5: any;
  keyword4: any;
  keyword9: any;
  constructor(
    id: number,
    productName: string = null,
    mode: string = null,
    productNewOrExisting: number,
    productDescription: any,
    productTypeId: any,
    customer: any,
    brandName: any,
    productBenifit: any,
    targetRegion: any,
    similarProduct: any,
    competitors: any,
    competitorspdt: any,
    companyname: any,
    apiLanguage: any,
    problemSolve: any,
    productEnviornment: any,
    productChannel: any,
    prodnVol: any,
    manufacturer: any,
    productRegulation: any,
    sellRate: any,
    inspiration: undefined,

    keyword31: undefined,
    keyword6: undefined,
    keyword10: undefined,
    keyword2: undefined,
    keyword32: undefined,
    keyword8: undefined,
    keyword11: undefined,
    keyword7: undefined,
    keyword5: undefined,
    keyword4: undefined,
    keyword9: undefined,
  ) {
    this.id = id;
    this.productName = productName;
    this.mode = mode;
    this.productDescription = productDescription;
    this.productNewOrExisting = productNewOrExisting;
    this.productTypeId = productTypeId;
    this.customer = customer;
    this.brandName = brandName;
    this.productBenifit = productBenifit;
    this.targetRegion = targetRegion;
    this.similarProduct = similarProduct;
    this.inspiration = inspiration;
    this.competitors = competitors;
    this.competitorspdt = competitorspdt;
    this.companyname = companyname;
    this.apiLanguage = apiLanguage;
    this.problemSolve = problemSolve;
    this.productEnviornment = productEnviornment;
    this.productChannel = productChannel;
    this.prodnVol = prodnVol;
    this.manufacturer = manufacturer;
    this.productRegulation = productRegulation;
    this.sellRate = sellRate;
    this.keyword31 = keyword31;
    this.keyword6 = keyword6;
    this.keyword10 = keyword10;
    this.keyword2 = keyword2;
    this.keyword32 = keyword32;
    this.keyword8 = keyword8;
    this.keyword11 = keyword11;
    this.keyword7 = keyword7;
    this.keyword5 = keyword5;
    this.keyword4 = keyword4;
    this.keyword9 = keyword9;

  }
}

