<div class="quick-tip {{cssClass}}" tabindex="0" [ngClass]="{
  'glassMode': !icon,
  'assistant': assistant,
  'right': align==='right'
}" (mouseenter)="hoverIn()" (mouseout)="hoverOut()">
  <i class="i i-help_bold o-0" *ngIf="icon"></i>
  <div class="tour" *ngIf="tour && icon">
    <div class="arrow">
      <i class="i i-curve_arrow"></i>
    </div>
    <div class="text">Quick Help</div>
  </div>

  <div class="popover">
    <div class="actions">
      <a mat-icon-button target="_blank" *ngIf="helpVideo !== undefined">
        <mat-icon fontSet="i" fontIcon="i-play_circle_outline" aria-label="Video Link"></mat-icon>
      </a>
      <a mat-icon-button [routerLink]="[helpLink]" target="_blank" *ngIf="helpLink !== undefined">
        <mat-icon fontSet="i" fontIcon="i-help" aria-label="help"></mat-icon>
      </a>
    </div>
    <ng-container *ngTemplateOutlet="layout ? layout: defaultLayout"></ng-container>
  </div>
</div>

<ng-template #defaultLayout>InsIQ</ng-template>