import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config, View } from 'src/app/config/global.config';

@Component({
  selector: 'app-userexist',
  templateUrl: './userexist.component.html',
  styleUrls: ['./userexist.component.scss']
})
export class UserexistComponent implements OnInit {
  msgtype;
  constructor(public config: Config, public view: View, private activatedRoute: ActivatedRoute
  ) {


  }

  ngOnInit() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.activatedRoute.queryParams.subscribe(params => {
      const msgtype = params['msgtype'];
      this.msgtype = msgtype;
      console.log(msgtype);
    });
  }
  getTitle() {
    let title;
    if (this.msgtype === 'alreadyexist') {
      title = 'Subscriber Account Already Exists in InsIQ.';
    }
    else if (this.msgtype === 'notfound') {
      title = 'Social Media Linked Account Not Found in InsIQ.';
    }
    return title;
  }
  getBody() {
    let body;
    if (this.msgtype === 'alreadyexist') {
      body = 'Kindly login using your InsIQ Credentials or other social media account used previously.';
    }
    else if (this.msgtype === 'notfound') {
      body = 'Kindly sign up using your social media account to continue InsIQ.';
    }
    return body;
  }
  toggleDev2MOverview() {
    //  this.config.dev2MOverview = !this.config.dev2MOverview;
    window.open("https://www.insiq.io/", "_blank");

  }
}
