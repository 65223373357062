import { Router } from '@angular/router';
import { AIView } from '../ai/insight.view';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../notesalerts/notes-dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { Component, Inject, TemplateRef, OnInit, Optional, ViewChild } from '@angular/core';
import { DeepdiveDialogComponent } from '../deepdive-dialog/deepdive-dialog.component';
@Component({
  selector: 'app-keywords-dialog',
  templateUrl: './keywords-dialog.component.html',
  styleUrls: ['./keywords-dialog.component.scss']
})
export class KeywordsDialogComponent implements OnInit {
  notesDialogRef: MatDialogRef<NotesDialogComponent, any>;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  favStat = false;
  selKeywords = [0, 2, 3, 4];
  constructor(
    public ai: AIView,
    public router: Router,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<DeepdiveDialogComponent>
  ) {
    this.layout = this.data;
    this.favStat = false;
    // console.log('test');
  }
  @ViewChild('secondDialog', { static: true }) STAT_POPUP: TemplateRef<any>;
  layout: TemplateRef<any>;
  ngOnInit() {
  }

  backtoPage() {
    this.dialogRef.close();
  }
  saveKeyword() {
    this.ai.saveKeyword();
    this.dialogRef.close();
    //  newValUpdate
  }

  backtoHome() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }
  getKeyText(row) {
    var length = 32;
    var trimmedString = row.length > length ?
      row.substring(0, length - 3) + "..." :
      row;
    return trimmedString;

  }
  getborder(deep: string) {
    if (deep === 'Yes') {
      return 'b-accent';
    } else if (deep === 'No') {
      return 'unknownbd';
    } else if (deep === null || typeof deep === 'undefined') {
      return 'unknownbd';
    } else {
      return 'b-primary';
    }
  }



  //  trimtooltip(noteAdd) {
  //    if (noteAdd?.length > 5) {
  //      return noteAdd.split(' ').slice(0, 5).join(' ') + '...';
  //    } else {
  //      return noteAdd;
  //    }
  //  }
}
