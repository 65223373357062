import {
  OnInit,
  Component
} from '@angular/core';

import { AIView } from '../ai/insight.view';
import { Config, View } from '../../config';
import { KeyplayerView } from '../discovery/keyplayer.view';
// import { SharedService } from '../discovery/shared.service';
import { SharedProjectService } from '../project/sharedproject.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  data: any;
  page: any;
  header: any;
  designConcept: string;

  constructor(
    public ai: AIView,
    public view: View,
    public config: Config,
    public kp: KeyplayerView,
    // public ss: SharedService,
    public ssp: SharedProjectService,
  ) {
    this.page = {
      from: 0,
      to: 1,
      size: 1,
      total: 0
    } || this.page;

    ai.display = false;
    this.kp.setForm();

    this.ssp.getProject();

    // console.log(this.ss);
    // console.log(this.ss.project);

    this.header = {
      percentage: 10,
      title: 'Dashboard',
      projectName: this.ssp.project.productName
    };

    view.get(`dashboard/all/${this.ssp.project.id}`).subscribe(res => {
      this.data = res;
      this.page.total = this.data.concept.length;
      this.designConcept = this.data.concept[0];
    });
  }

  next() {
    const newTo = this.page.from + 1;

    if (newTo > (this.page.total)) {
      this.page.to = newTo;
    } else if (this.page.total === this.page.to) {
      this.page.from = 0;
      this.page.to = this.page.size;
    } else {
      this.page.from = this.page.to;
      this.page.to = this.page.to + this.page.size;
    }

    this.designConcept = this.data.concept[this.page.to];
  }

  prev() {
    const newTo = (this.page.from - 1);
    if (newTo < 0) {
      this.page.from = this.page.total - 1;
      this.page.to = this.page.total;
    } else if (this.page.total === newTo) {
      this.page.from = 0;
      this.page.to = 1;
    } else {
      this.page.from = newTo;
      this.page.to = this.page.to - 1;
    }
    this.designConcept = this.data.concept[this.page.to];
  }

  ngOnInit() {
  }

}
