import { View } from '../config';

export class TreeData {
  name: string;
  comp: any;
  view: View;
  key: string;
  childLabel: any;
  activeNode: any;
  isParentOpen: boolean;
  icon: string;
  child = null;
  childLabelTree = '';
  config: any;

  constructor(comp: any = null, op) {
    this.config = {
      key: op.key || '',
      name: op.name || '',
      class: op.class || '',
      childId: op.childId || null,
      children: op.children || '',
      childName: op.childName || '',
      new: (op.new !== undefined) ? op.new : true,
      edit: (op.edit !== undefined) ? op.edit : true,
      multi: (op.multi !== undefined) ? op.multi : false,
      import: (op.import !== undefined) ? op.import : false,
      disabled: (op.disabled !== undefined) ? op.disabled : true,
      nodeImport: (op.nodeImport !== undefined) ? op.nodeImport : false,
      pagination: (op.pagination !== undefined) ? op.pagination : true,
      percentage: (op.percentage !== undefined) ? op.percentage : true
    };

    // console.log(this.config);

    this.key = this.config.key;
    this.name = this.config.name;
    this.childLabel = this.config.children;

    this.config.childId = (this.config.childId === null) ? this.config.children.split(',')[0] : this.config.childId;
    this.config.childName = (this.config.childName === '') ? this.config.children.split(',')[0] : this.config.childName;

    if (this.childLabel !== '') {
      if (this.childLabel.indexOf(',') > -1) {
        const array = this.childLabel.split(',');
        for (let index = 0; index < array.length; index++) {
          if (index > 0) {
            this.childLabelTree += ',';
          }
          this.childLabelTree += '#tree-' + array[index];
        }
      } else {
        this.childLabelTree = '#tree-' + this.childLabel;
      }
    }

    this.activeNode = null;

    if (comp !== null) {
      this.comp = comp;
      this.view = comp.view;
      this.view.active = null;
    }

    this.icon = this.getIcon(this.key);

  }

  getIcon(key) {
    switch (key) {
      case 'BEVC':
        return 'i-brain';
      case 'HOCG':
        return 'i-brain-cog';
      case 'ESFY':
        return 'i-safety';
      case 'keyplayer':
        return 'i-stakeholder';
      case 'requirementType':
        return 'i-requirement-type';
      case 'insight':
      case 'observation':
        return 'i-interview';
      case 'requirement':
        return 'i-requirement';
      case 'testing':
        return 'i-testing';
      case 'concept':
        return 'i-concept';
      case 'requirementFeature':
        return 'i-features';
      case 'requirementFeatureProcess':
        return 'i-process';
      default:
        return 'i-help';
    }
  }

  getChildLabelTree(key = null) {
    // console.log(key, this.childLabelTree);
    if (key) {
      return '#tree-' + key;
    } else {
      return this.childLabelTree;
    }
  }
}
