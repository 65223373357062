import { SharedService } from './shared.service';
import { Injectable } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { View, Config, FormDefault } from '../../config/global.config';

import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConceptView {
 // keyplayerCategoryCodes: any;
  url = 'concept';
  public view: View;
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    private ss: SharedService) {
    this.view = new View(http, router, route, alertService);

  }

  setForm() {
    this.ss.projectId=null;
    this.ss.setConceptView(this);
    this.view.initForm(this.url, this.getForm(), this.getNewDefault(), false);
    this.getList();
   // this.newform();
    this.view.state = 'list';
    this.view.refreshList = false;
    Config.isProjectSelected = true;
  }

  getNewDefault() {
    return new FormDefault(['projectId'], [this.ss.getProject()]);
  }

  newform() {
    this.view.newform();
  }

  save() {
    this.view.set('label', this.view.value('title'));
    //console.log(this.view.form.value);
    this.view.save();
  }

  edit(row) {
    this.view.edit(row);
  }

  saveAndAdd() {
    this.view.set('label', this.view.value('title'));
    this.view.saveAndAdd();
  }

  cancel() {
    this.getList();
    this.view.cancel();
  }

  getList() {
    this.view.get(`${this.url}/all/${this.ss.getProjectId()}`).subscribe(res => {
      this.view.list = res;
    });
  }

  getForm() {
    return this.fb.group({
      id: [''],
      label: [''],
      description: ['', Validators.required],
      title: ['', Validators.required],
      projectId: ['']
    });
  }
}
