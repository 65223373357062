<div class="container-fluid d-flex h-100">
  <div class="wrapper">
    <div class="leftSection">
      <figure class="clickable">
        <img src="./assets/images/icon-insiq.png" alt="insiq" *ngIf="!customThemeService.dynamiclogo"
          (click)="linkClick1('project')">
        <img src="{{customThemeService.logo}}" alt="insiq" *ngIf="customThemeService.dynamiclogo"
          (click)="linkClick1('project')">
      </figure>

      <!-- <figure *ngIf="!customThemeService.dynamiclogo">
        <img src="./assets/images/insiq.png" alt="dev2m">
      </figure> -->

      <div class="actions">
        <button type="button" class="btn btn-primary btn-lg rounded t-c" matRipple
          (click)="this.view.state='new'; this.toNewProject()">
          <!-- <i class="i i-add"></i> -->
          <span>Add Project</span>
        </button>
      </div>

      <nav class="nav">
        <div class="title clickable" (click)="linkClick1('project')">
          <i class="i i-projects"></i>
          <span>Projects</span>
        </div>
        <ul class="stackMenu">
          <li *ngFor="let project of projList">
            <button type="button" class="btn" [ngClass]="{active: project?.id === view.active?.id}"
              (click)="chooseProject(project);">
              <!-- <i class="i ico" [ngClass]="{
                'i-idea': project.productNewOrExisting==1,
                'i-monitor_product': project.productNewOrExisting==2
              }"></i> -->
              {{getProjName(project)}}
            </button>
          </li>
        </ul>
      </nav>

      <div class="sidebar-footer">
        <button type="button" matRipple (click)="yotubeClick()" class="btn  btn-link">
          <mat-icon fontSet="i" class="i-youtube i-1_7x ib-m"></mat-icon>
          <span class="ib-m m-x-5">Watch our How-To Videos</span>
        </button>

        <button type="button" class="btn btn-primary btn-xl curved m-y-10 justify-content-center " matRipple
          (click)="acdemyClick()">
          <span>Join our free academy</span>
        </button>

      </div>
    </div>
    <!-- <div *ngIf="resetPass === false"> -->
    <form [formGroup]="view.form" novalidate class="rightSection" tabindex="0">
      <div class="pageWrapper">
        <div class="InsIQCard">
          <!-- (selectedTabChange)="tabClick($event)" -->
          <mat-tab-group mat-align-tabs="center" class="transparentTabs" (selectedTabChange)="tabClick($event)"
            [selectedIndex]="pageId" [color]="pageId === 7 ? 'warn': 'accent'">
            <mat-tab label="{{getLabel()}}">
              <!-- <pre>{{view.form.value | json}}</pre> -->
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  <figure class="avatar circle" tabindex="0">
                    <img [src]="croppedImage" [attr.alt]="view.value('name')" [attr.title]="view.value('name')" />
                    <input type="file" (change)="fileChangeEvent($event, avatarCropper)" #avatarFile />
                  </figure>

                  <ng-template #avatarCropper>
                    <div class="avatarImageCrop">
                      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [resizeToWidth]="140" [resizeToHeight]="140" [output]="'base64'" [aspectRatio]="1 / 1"
                        format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                      <div class="action">
                        <button type="button" class="btn btn-primary" (click)="closeDialog()">Apply</button>
                      </div>
                    </div>
                  </ng-template>

                  <label for="name" class="mandatory d-block">Name</label>
                  <mat-form-field class="w-100 fw flat" floatLabel="never" appearance="fill">
                    <input type="text" matInput placeholder="Name" formControlName="name">
                    <mat-error *ngIf="view.invalid('name')">{{view.error('name')}}</mat-error>
                  </mat-form-field>
                  <label for="username" class="d-block">Username<span>(modification is not allowed)</span></label>
                  <mat-form-field class="w-100 fw flat" floatLabel="never" appearance="fill">
                    <!-- <small class="staticLabel">Username <em style="font-size: 9px;">(modification is not allowed)</em></small> -->
                    <input type="text" matInput formControlName="loginKey" [readonly]="view.isEdit()">
                    <mat-hint *ngIf="view.isEdit()"></mat-hint>
                    <mat-error *ngIf="view.invalid('loginKey')">{{view.error('loginKey')}}</mat-error>
                  </mat-form-field>
                  <label for="email" class="mandatory d-block">Email</label>
                  <mat-form-field class="w-100 fw flat" floatLabel="never" appearance="fill">
                    <input type="text" matInput placeholder="Email" formControlName="email" [readonly]="view.isEdit()">
                    <mat-error *ngIf="view.invalid('email')">{{view.error('email')}}</mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field class="w-100" *ngIf="!changezoneflag">
                    <input type="text" matInput placeholder="Country" formControlName="country" [readonly]="true">
                    <mat-error *ngIf="view.invalid('country')">{{view.error('country')}}</mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100" *ngIf="!changezoneflag">
                    <input type="text" matInput placeholder="Timezone" formControlName="timezone" [readonly]="true">
                    <mat-error *ngIf="view.invalid('timezone')">{{view.error('timezone')}}</mat-error>
                  </mat-form-field> -->

                  <!-- *ngIf="changezoneflag" -->
                  <!-- *ngIf="changezoneflag&&selectedCountry.value!=''" -->
                  <label for="country" class="d-block" *ngIf="currentUsr?.pin!='USR'">Country</label>
                  <div class="form-group fc-group" *ngIf="currentUsr?.pin!=='USR'">
                    <!--Using items input-->
                    <ng-select [items]="countries" appearance="outline" #comboCountries bindValue="value"
                      bindLabel="value" labelForId="country" [virtualScroll]="true" dropdownPosition="auto"
                      placeholder="Select country" formControlName="country" (change)="onCountryChange($event)"
                      [ngClass]="{
                        'disabled':currentUsr?.pin==='USR'
                      }" (clear)="onCountryClear($event)" appendTo="body">
                      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        {{item.value}}
                      </ng-template>
                    </ng-select>
                  </div>
                  <label for="timezone" class="d-block" *ngIf="currentUsr?.pin!='USR'">Time</label>
                  <div class="form-group fc-group" *ngIf="currentUsr?.pin!=='USR'">
                    <ng-select [items]="timeZones" appearance="outline" #comboTimezones bindValue="value"
                      bindLabel="value" labelForId="timezone" [virtualScroll]="true" dropdownPosition="auto"
                      placeholder="Select timezone" formControlName="timezone" (change)="onChange($event)"
                      appendTo="body" [attr.zIndex]="view.form.value.country===null ? -1: null" [ngClass]="{
                      'disabled': view.form.value.country===null||currentUsr?.pin==='USR'
                    }">
                      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        {{item.value}}
                      </ng-template>
                    </ng-select>
                  </div>
                  <!-- <mat-form-field class="w-100">
                    <mat-select placeholder="Choose role" formControlName="role" [errorStateMatcher]="matcher">
                      <mat-option value="0">Normal User</mat-option>
                      <mat-option value="1">Administrator</mat-option>
                    </mat-select>
                    <mat-error *ngIf="view.required('role')">You must make a selection</mat-error>
                  </mat-form-field> -->
                  <div class="t-c m-y-30">
                    <!-- <button type="button" (click)="changezone()" mat-raised-button
                    class="curved m-x-5 m-y-5 btn-cancel">Change TimeZone</button> -->
                    <button type="button" (click)="onSubmit()" [disabled]="view.invalid()&&!avatarUpdate"
                      *ngIf="view.isEdit()" class="btn btn-primary t-c btn-xl w-100">Update my Account</button>
                  </div>

                </div>
                <!-- <pre>{{currentUsr | json}}</pre> -->
                <!-- ***{{currentUsr.priority}} -->

                <div class="col-xl-12 col-lg-12"
                  *ngIf="(currentUserData?.currentPlan)&&(currentUsr?.pin!='USR')&&(currentUserData?.refund==0)">
                  <div class="card fieldsetCard planCard m-t-15">
                    <div class="cardHeader">
                      <div class="title">{{currentUserData?.currentPlan}}</div>
                    </div>
                    <div class="cardContent">
                      <div class="col">
                        <div class="validTill">
                          <div *ngIf="config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'">Your InsIQ Plan
                            Expired</div>
                          <div *ngIf="currentUsr?.usertype=='APPSUMO'||currentUsr?.daysRemaining>500">Lifetime
                            Validity</div>
                          <div *ngIf="currentUsr?.usertype!=='APPSUMO'&&currentUsr?.daysRemaining<500">
                            <div>Subscription Valid till: </div>
                            <div class="date">{{(currentUserData?.validTill)?.split(' ')[0]}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col t-r">
                        <div class="action">
                          <button type="button" matRipple class="btn btn-info _flat" (click)="upgradePlan()"
                            *ngIf="currentUsr?.pin!='USR'&&currentUsr?.usertype!=='APPSUMO'&&currentUsr?.priority!=1&&currentUsr?.daysRemaining>7">
                            UPGRADE PLAN
                          </button>

                          <button type="button" matRipple class="btn btn-info _flat" (click)="renewPlan()"
                            *ngIf="currentUsr?.pin!='USR'&&currentUsr?.usertype!=='APPSUMO'&&currentUsr?.daysRemaining<=7">
                            RENEW PLAN
                          </button>
                          <div
                            *ngIf="currentUsr?.pin!='USR'&&currentUsr?.usertype!=='APPSUMO'&&currentUsr?.daysRemaining>0&&currentUsr?.planType==2">
                            <button type="button" matRipple class="btn btn-info _flat outline rounded"
                              (click)="sachetsubscription('api')">
                              Buy AI Credits
                            </button>
                            <button type="button" matRipple class="btn btn-info _flat outline rounded"
                              *ngIf="(this.featureCheckerValid(23)&&this.featureCheckerValid(24))"
                              (click)="sachetsubscription('user')">
                              Buy Users
                            </button>
                            <button type="button" matRipple class="btn btn-info _flat outline rounded"
                              *ngIf="clientfeatureChecker()" (click)="sachetsubscription('data')">
                              Buy Data Add-ons
                            </button>
                            <button type="button" matRipple class="btn btn-danger _flat" (click)="cancelPlan()"
                              *ngIf="currentUsr?.pin!='USR'&&currentUsr?.usertype!=='APPSUMO'&&currentUsr?.daysRemaining>0&&currentUsr?.planType==2">
                              Cancel Subscription
                            </button>
                          </div>
                        </div>

                        <!-- <small class="footnote" *ngIf="currentUsr?.pin!='USR'&&currentUsr?.usertype!=='APPSUMO'">
                          * Paid plans are only available to customers in the UK, US, and EU countries.
                        </small> -->
                      </div>

                    </div>
                    <div class="message _warning"
                      *ngIf="!config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'&&currentUsr?.daysRemaining<500">
                      <!-- <i class="i i-warning"></i> -->
                      <span *ngIf="currentUsr?.daysRemaining>0">Expires in
                        {{currentUserData?.daysRemaining}} Days</span>
                      <span *ngIf="currentUsr?.daysRemaining<=0">Account Expired</span>
                    </div>
                    <div class="message _warning" *ngIf="config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'">
                      <i class="i i-warning"></i>
                      <span>Account will Terminate by {{currentUserData?.gp | date: 'dd/MM/yyyy'}}</span>
                    </div>
                  </div>

                  <!-- <mat-card class="plan">
                    <mat-card-header>
                      <mat-card-title>
                        {{currentUserData?.currentPlan}}
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div class="validTill" *ngIf="currentUsr?.usertype=='APPSUMO'">
                        <span class="date">Lifetime Validity</span>
                      </div>
                      <div class="validTill" *ngIf="currentUsr?.usertype!=='APPSUMO'">
                        Subscription Valid till:
                        <span class="date">{{(currentUserData?.validTill)?.split(' ')[0]}}</span>
                      </div>

                      <div class="validTill" *ngIf="config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'">
                        <span class="date">Your InsIQ Plan Expired</span>
                      </div>

                      <div class="message _warning" *ngIf="!config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'">
                        <i class="i i-warning"></i>
                        <span *ngIf="config?.currentUser?.daysRemaining>=0">Expires in
                          {{currentUserData?.daysRemaining}}
                          Days</span>
                        <span *ngIf="config?.currentUser?.daysRemaining<0">Account Expired</span>
                      </div>
                      <div class="message _warning" *ngIf="config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'">
                        <i class="i i-warning"></i>
                        <span>Account will Terminate by {{currentUserData?.gp | date: 'dd/MM/yyyy'}}</span>
                      </div>
                      <div class="t-c m-y-15" *ngIf="currentUsr?.pin!='USR'&&currentUsr?.usertype!=='APPSUMO'">
                        <button type="button" matRipple class="btn btn-accent curved w-200px min"
                          (click)="upgradePlan()">
                          UPGRADE PLAN*
                        </button>
                      </div>
                      <div class="t-c m-t-20 m-b-10"
                        *ngIf="(currentUsr?.pin!='USR'&&currentUserData?.planType!=1)&&currentUsr?.usertype!='APPSUMO'">
                        <button type="button" matRipple class="btn btn-accent outline m-x-5"
                          (click)="sachetsubscription('api')">
                          Buy AI Credits Sachet
                        </button>
                        <button type="button" matRipple class="btn btn-accent outline m-x-5"
                          *ngIf="clientfeatureChecker()" (click)="sachetsubscription('data')">
                          Buy Data Sachet
                        </button>
                      </div>
                      <small class="text-danger f-light info"
                        *ngIf="currentUsr?.pin!='USR'&&currentUsr?.usertype!=='APPSUMO'">
                        * Paid plans are only available to customers in the UK, US, and EU countries.
                      </small>

                    </mat-card-content>
                  </mat-card> -->
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Billing Information" *ngIf="currentUsr?.pin!='USR'">
              <div class="row align-items-start billingYourPlan p-a-0">
                <div class="col-md-24">
                  <div class="billingInfo m-b-0">
                    <!-- <mat-card-header>Billing Information</mat-card-header> -->
                    <mat-card-content>
                      <form [formGroup]="billingView.form" novalidate>
                        <div class="row">
                          <div class="col-md-12">
                            <label for="name" class="mandatory d-block">Name</label>
                            <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                              <input matInput placeholder="Enter Name" maxlength="100" formControlName="billingName">

                              <!-- <mat-error>Error Message</mat-error> -->
                            </mat-form-field>
                          </div>
                          <div class="col-md-12">
                            <label for="email" class="d-block">Email</label>
                            <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                              <input matInput placeholder="Enter Email" maxlength="100" formControlName="email">
                              <!-- <mat-error>Error Message</mat-error> -->
                            </mat-form-field>
                          </div>
                          <div class="col-md-12">
                            <label for="contactNumber" class="d-block">Contact Number</label>
                            <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                              <input matInput placeholder="Enter Contact Number" maxlength="100"
                                formControlName="phone">
                              <!-- <mat-error>Error Message</mat-error> -->
                            </mat-form-field>
                            <label for="VAT Number" class=" d-block">VAT Number</label>
                            <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                              <input matInput placeholder="Enter VAT Number" maxlength="100" formControlName="vat">
                            </mat-form-field>
                            <label for="country" class="mandatory d-block">Country</label>
                            <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                            <input matInput placeholder="Enter Country" maxlength="100" formControlName="country">
                          </mat-form-field> -->

                            <!-- <p-autoComplete formControlName="country" [suggestions]="filteredCountry" styleClass="w-100"
                            (completeMethod)="filterCountry($event)" [minLength]="0" placeholder="Enter country."
                            autocomplete="off" forceSelection="true" field="name" [dropdown]="true">
                          </p-autoComplete> -->

                            <ng-select [items]="countryList" appearance="outline" #comboCountries bindValue="name"
                              bindLabel="name" labelForId="country" [virtualScroll]="true" dropdownPosition="auto"
                              placeholder="Select country" formControlName="country" (change)="filterCountry($event)"
                              (clear)="onCountryClear($event)" appendTo="body">
                              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                {{item.name}}
                              </ng-template>
                            </ng-select>
                            <label for="Region" class="mandatory d-block"
                              *ngIf="billingView.value('country')=='UNITED STATES OF AMERICA'">Region</label>

                            <ng-select [items]="regionList" appearance="outline" #comboregion bindValue="name"
                              *ngIf="billingView.value('country')=='UNITED STATES OF AMERICA'" bindLabel="name"
                              labelForId="region" [virtualScroll]="true" dropdownPosition="auto"
                              placeholder="Select Region" formControlName="region" (change)="filterCountry($event)"
                              (clear)="onCountryClear($event)" appendTo="body">
                              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                {{item.name}}
                              </ng-template>
                            </ng-select>

                            <!-- <small class="info text-primary">*Paid plans are only
                              available to customers in the UK,
                              US, and EU countries.
                            </small> -->
                            <!-- <ng-select [items]="countries" appearance="outline" #comboCountries bindValue="id"
                            bindLabel="value" labelForId="country" [virtualScroll]="true" dropdownPosition="auto"
                            placeholder="Select country" formControlName="country" (change)="onCountryChange($event)">
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                              {{item.value}}
                            </ng-template>
                          </ng-select> -->

                            <!-- <mat-error>Error Message</mat-error> -->
                            <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                            <input type="text" matInput formControlName="country" required [matAutocomplete]="autoGroup"
                              placeholder="Choose Country">
                            <mat-autocomplete #autoGroup="matAutocomplete">
                              <mat-option *ngFor="let name of countries" [value]="name">{{name}}</mat-option>
                            </mat-autocomplete>
                          </mat-form-field> -->

                            <label for="address" class="mandatory d-block m-t-20">ZIP Code</label>
                            <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                              <input matInput placeholder="Enter ZIP Code" maxlength="255" rows="7"
                                formControlName="zip" type="text">
                              <!-- <mat-error>Error Message</mat-error> -->
                            </mat-form-field>

                          </div>
                          <div class="col-md-12">
                            <label for="Company/Organization Name" class="mandatory d-block">Company/Organization
                              Name</label>
                            <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                              <input matInput placeholder="Enter Company/Organization Name" maxlength="100"
                                formControlName="company">
                            </mat-form-field>
                            <label for="address" class="mandatory d-block">Address Line 1</label>
                            <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                              <input matInput placeholder="Enter Address Line 1" maxlength="255" rows="7"
                                formControlName="address">
                              <!-- <mat-error>Error Message</mat-error> -->
                            </mat-form-field>
                            <label for="address" class="mandatory d-block">City</label>
                            <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                              <input matInput placeholder="Enter City" maxlength="255" rows="7" formControlName="city">
                              <!-- <mat-error>Error Message</mat-error> -->
                            </mat-form-field>

                          </div>
                          <div class="t-c m-t-20 action">
                            <!-- <button type="button" [routerLink]="['/project']" mat-raised-button
                            class="curved m-x-5 btn-cancel">Back</button> -->
                            <button type="button" (click)="billingInfoSubmit()" [disabled]="billingView.invalid()"
                              class="btn btn-primary" matRipple>Update</button>
                            <!-- <button type="button" (click)="billingInfoSubmit()" [disabled]="billingView.invalid()"
                            *ngIf="billingView.isEdit()" mat-raised-button color="primary"
                            class="curved m-x-5">Create</button> -->
                          </div>
                        </div>
                      </form>

                    </mat-card-content>
                  </div>
                </div>
                <!-- <div class="col-md-12 t-c">
                  <img src="assets/images/infographics/billing-info.png" alt="Billing Information">
                </div> -->
              </div>
            </mat-tab>
            <mat-tab label="Invoices" *ngIf="currentUsr?.pin!='USR'">
              <div class="total align-items-center">Plan Purchase Invoice</div>
              <div class="mat-elevation-z8 rel">
                <div class="d-flex">
                  <div class="spacer"></div>
                  <mat-form-field>
                    <input matInput (keyup)="applyInvoiceFilter($event)" placeholder="Search Columns" #input />
                  </mat-form-field>
                </div>
                <table mat-table [dataSource]="historylist" matSort class="w-100 InsIQTable">
                  <ng-container matColumnDef="invoicenumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
                    <td mat-cell *matCellDef="let row">{{row.invoice}}</td>
                  </ng-container>
                  <ng-container matColumnDef="paydate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date </th>
                    <td mat-cell *matCellDef="let row">{{row.createdAt}}</td>
                  </ng-container>

                  <ng-container matColumnDef="planname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Name</th>
                    <td mat-cell *matCellDef="let row">{{row.planId.plancode}}</td>
                  </ng-container>
                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                    <td mat-cell *matCellDef="let row">USD {{row.amountPaid}}</td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let row">{{row.payStatus}}</td>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">{{row.transactionType}}</td>
                  </ng-container>
                  <ng-container matColumnDef="transactionId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Id</th>
                    <td mat-cell *matCellDef="let row" class="word-breaks">{{row.transactionId.toUpperCase()}}</td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="planstat">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
                  <td mat-cell *matCellDef="let row" class="t-c">
                    <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
                    </mat-slide-toggle>
                    {{row.status}}
                  </td>
                </ng-container>-->
                  <ng-container matColumnDef="action">
                    <th class="w-120px" mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                    <td mat-cell *matCellDef="let row">
                      <!-- <button mat-icon-button (click)="invoiceDownload(row)" title="Edit" matTooltipPosition="below">
                      <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
                    </button> -->

                      <button mat-raised-button (click)="invoiceDownload(row,1)" color="accent"
                        class="curved m-r-10"><span class="ib-m p-l-5 t-normal">Invoice</span>
                        <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
                      </button>
                      <!-- <button mat-icon-button (click)="view.deleteList(row.id)" title="Delete" matTooltipPosition="below">
                      <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="delete" color="warn"></mat-icon>
                    </button> -->
                    </td>
                  </ng-container>

                  <tr mat-header-row
                    *matHeaderRowDef="['invoicenumber','paydate','planname','amount','status','type','transactionId','action']">
                  </tr>
                  <tr mat-row
                    *matRowDef="let row; columns: ['invoicenumber','paydate','planname','amount','status','type','transactionId','action']">
                  </tr>
                </table>

                <mat-paginator #paginator [pageSizeOptions]="[4, 6, 8]" showFirstLastButtons></mat-paginator>
              </div>
              <div class="total m-t-30 align-items-center">Add-ons Purchase Invoice</div>
              <div class="mat-elevation-z8 rel">
                <div class="d-flex">
                  <div class="spacer"></div>
                  <mat-form-field>
                    <input matInput (keyup)="applySachetFilter($event)" placeholder="Search Columns" #input />
                  </mat-form-field>
                </div>
                <table mat-table [dataSource]="sachethistorylist" matSort class="w-100 InsIQTable">
                  <ng-container matColumnDef="invoicenumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
                    <td mat-cell *matCellDef="let row">{{row.invoice}}</td>
                  </ng-container>
                  <ng-container matColumnDef="paydate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date </th>
                    <td mat-cell *matCellDef="let row">{{row.createdAt}}</td>
                  </ng-container>

                  <ng-container matColumnDef="planname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Add-ons Name</th>
                    <td mat-cell *matCellDef="let row">{{row.sachetId.sachetName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="plantype">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Add-ons Type</th>
                    <td mat-cell *matCellDef="let row">{{row.sachetId.sachetType}}</td>
                  </ng-container>
                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                    <td mat-cell *matCellDef="let row">USD {{row.amountPaid}}</td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let row">{{row.payStatus}}</td>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">{{row.transactionType}}</td>
                  </ng-container>

                  <ng-container matColumnDef="transactionId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction ID</th>
                    <td mat-cell *matCellDef="let row" class="word-breaks">{{row.transactionId.toUpperCase()}}</td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="planstat">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
                  <td mat-cell *matCellDef="let row" class="t-c">
                    <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
                    </mat-slide-toggle>
                    {{row.status}}
                  </td>
                </ng-container>-->
                  <ng-container matColumnDef="action">
                    <th class="w-120px" mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
                    <td mat-cell *matCellDef="let row">
                      <!-- <button mat-icon-button (click)="invoiceDownload(row)" title="Edit" matTooltipPosition="below">
                      <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
                    </button> -->

                      <button mat-raised-button (click)="invoiceDownload(row,2)" color="accent" class="curved  "><span
                          class="ib-m p-l-5 t-normal">Invoice</span>
                        <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
                      </button>
                      <!-- <button mat-icon-button (click)="view.deleteList(row.id)" title="Delete" matTooltipPosition="below">
                      <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="delete" color="warn"></mat-icon>
                    </button> -->
                    </td>
                  </ng-container>

                  <tr mat-header-row
                    *matHeaderRowDef="['invoicenumber','paydate','planname','plantype','amount','status','type','transactionId','action']">
                  </tr>
                  <tr mat-row
                    *matRowDef="let row; columns: ['invoicenumber','paydate','planname','plantype','amount','status','type','transactionId','action']">
                  </tr>
                </table>
                <mat-paginator #paginatorPageSize [pageSizeOptions]="[4, 6, 8]" showFirstLastButtons></mat-paginator>

                <!-- <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
              </mat-paginator> -->
              </div>
              <!-- Inser Table Code here -->
            </mat-tab>

            <mat-tab label="Transaction History" *ngIf="currentUsr?.pin!='USR'">
              <div class="total align-items-center">Transaction History</div>
              <div class="mat-elevation-z8 rel">

                <div class="d-flex">
                  <div class="spacer"></div>
                  <mat-form-field>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Search Columns" #input />
                  </mat-form-field>
                </div>


                <table mat-table [dataSource]="transactionhistorylist" matSort class="w-100 InsIQTable" *ngIf="(config.getUserCount() ? config.getUserCount()?.[0].totaldisplay :
                apiUsagestore?.[0].totaldisplay)>0">
                  <ng-container matColumnDef="invoicenumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction ID</th>
                    <td mat-cell *matCellDef="let row"> {{row.transactionId.toUpperCase()}}</td>
                  </ng-container>
                  <ng-container matColumnDef="paydate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date </th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">{{row.createdAt}}</td>
                  </ng-container>

                  <ng-container matColumnDef="planname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">{{row.transactionType}}</td>
                  </ng-container>
                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Price</th>
                    <td mat-cell *matCellDef="let row">USD {{row.planPrice}}</td>
                  </ng-container>
                  <ng-container matColumnDef="sachet">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>User Add-ons</th>
                    <td mat-cell *matCellDef="let row">USD {{row.sachetAmount}}</td>
                  </ng-container>
                  <ng-container matColumnDef="tax">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tax</th>
                    <td mat-cell *matCellDef="let row">USD {{row.taxAmount}}</td>
                  </ng-container>
                  <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Amount</th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">USD {{row.amountPaid}}</td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Status</th>
                    <td mat-cell *matCellDef="let row">{{row.payStatus}}</td>
                  </ng-container>


                  <!-- <ng-container matColumnDef="planstat">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
                    <td mat-cell *matCellDef="let row" class="t-c">
                      <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
                      </mat-slide-toggle>
                      {{row.status}}
                    </td>
                  </ng-container> -->

                  <tr mat-header-row
                    *matHeaderRowDef="['invoicenumber','paydate','planname','amount','sachet','tax','total','status']">
                  </tr>
                  <tr mat-row
                    *matRowDef="let row; columns: ['invoicenumber','paydate','planname','amount','sachet','tax','total','status']">
                  </tr>
                </table>
                <table mat-table [dataSource]="transactionhistorylist" matSort class="w-100 InsIQTable" *ngIf="(config.getUserCount() ? config.getUserCount()?.[0].totaldisplay :
                apiUsagestore?.[0].totaldisplay)===0">
                  <ng-container matColumnDef="invoicenumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction ID</th>
                    <td mat-cell *matCellDef="let row"> {{row.transactionId.toUpperCase()}}</td>
                  </ng-container>
                  <ng-container matColumnDef="paydate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date </th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">{{row.createdAt}}</td>
                  </ng-container>

                  <ng-container matColumnDef="planname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">{{row.transactionType}}</td>
                  </ng-container>
                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Price</th>
                    <td mat-cell *matCellDef="let row">USD {{row.planPrice}}</td>
                  </ng-container>

                  <ng-container matColumnDef="tax">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tax</th>
                    <td mat-cell *matCellDef="let row">USD {{row.taxAmount}}</td>
                  </ng-container>
                  <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Amount</th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">USD {{row.amountPaid}}</td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Status</th>
                    <td mat-cell *matCellDef="let row">{{row.payStatus}}</td>
                  </ng-container>


                  <!-- <ng-container matColumnDef="planstat">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
                    <td mat-cell *matCellDef="let row" class="t-c">
                      <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
                      </mat-slide-toggle>
                      {{row.status}}
                    </td>
                  </ng-container> -->

                  <tr mat-header-row
                    *matHeaderRowDef="['invoicenumber','paydate','planname','amount','tax','total','status']">
                  </tr>
                  <tr mat-row
                    *matRowDef="let row; columns: ['invoicenumber','paydate','planname','amount','tax','total','status']">
                  </tr>
                </table>
                <mat-paginator #paginator1 [pageSizeOptions]="[4, 6, 8]" showFirstLastButtons></mat-paginator>

                <!-- <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
              </mat-paginator> -->
              </div>
              <div class="total align-items-center m-t-20" *ngIf="refundlist">Refund
                Transactions
              </div>
              <div class="mat-elevation-z8 rel" *ngIf="refundlist">

                <div class="d-flex">
                  <div class="spacer"></div>
                  <mat-form-field>
                    <input matInput (keyup)="applyFilter1($event)" placeholder="Search Columns" #input />
                  </mat-form-field>
                </div>


                <table mat-table [dataSource]="refundhistorylist" matSort class="w-100 InsIQTable">
                  <ng-container matColumnDef="invoicenumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction ID</th>
                    <td mat-cell *matCellDef="let row"> {{row.paymentintentid.toUpperCase()}}</td>
                  </ng-container>
                  <ng-container matColumnDef="paydate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date </th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">{{row.createdAt}}</td>
                  </ng-container>

                  <ng-container matColumnDef="planname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">Refund</td>
                  </ng-container>

                  <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Amount</th>
                    <td mat-cell *matCellDef="let row" class="textaligncenter">USD {{ extractNumericValue(row.amount)}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Status</th>
                    <td mat-cell *matCellDef="let row">{{row.description}}</td>
                  </ng-container>


                  <!-- <ng-container matColumnDef="planstat">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
                  <td mat-cell *matCellDef="let row" class="t-c">
                    <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
                    </mat-slide-toggle>
                    {{row.status}}
                  </td>
                </ng-container>-->

                  <tr mat-header-row *matHeaderRowDef="['invoicenumber','paydate','planname','total','status']">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['invoicenumber','paydate','planname','total','status']">
                  </tr>
                </table>
                <mat-paginator #paginator2 [pageSizeOptions]="[4, 6, 8]" showFirstLastButtons></mat-paginator>

                <!-- <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
              </mat-paginator> -->
              </div>
              <!-- Inser Table Code here -->
            </mat-tab>


            <mat-tab label="Microsoft Teams" *ngIf="currentUsr?.pin!='USR'">
              <div class="row align-items-center">
                <div class="col-md-12" *ngIf="featureChecker()">
                  <form [formGroup]="msaccountview.form" (ngSubmit)="onidSubmit()" novalidate>

                    <div *ngIf="(!fstuser||authenticated); then welcomeUser else signInPrompt"></div>
                    <ng-template #signInPrompt>
                      <div class="t-c">
                        <figure>
                          <img src="assets/images/infographics/InsIQTeamConnect.png"
                            alt="Connect InsIQ to Microsoft Teams">
                        </figure>
                        <button type="button" (click)="setupbtn()" matRipple
                          class="btn btn-lg btn-accent curved p-x-30 m-t-30">Sign in to Microsoft Account</button>
                      </div>
                      <!-- OLD CODE for unwanted Client/tenant ID form COMMENTED :::begins -->
                      <!-- NEEDS TO CHECK IF any impact happens here -->
                      <!-- <div>
                      {{getUsrMsg()}}
                      <small class="textfnt"></small>
                    </div>
                    <div class="w-320px m-t-20">
                      <mat-form-field class="w-100">
                        <input type="text" matInput placeholder="Client Id" formControlName="clientId">
                        <mat-error *ngIf="msaccountview.invalid('clientId')">{{msaccountview.error('clientId')}}
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field class="w-100">
                        <input type="text" matInput placeholder="Tenant Id" formControlName="tenantId">
                        <mat-error *ngIf="msaccountview.invalid('tenantId')">{{msaccountview.error('tenantId')}}
                        </mat-error>
                      </mat-form-field>
                      <div class="t-c" *ngIf="fstuser">
                        <button type="button" (click)="onidSubmit()" mat-raised-button color="primary"
                          [disabled]="msaccountview.invalid()" class="curved m-x-5">Save</button>
                      </div>
                      <div class="t-c" *ngIf="!fstuser">
                        <button type="button" [routerLink]="['/project']" mat-raised-button
                          class="curved m-x-5 btn-cancel">Back</button>
                      </div>
                    </div> -->
                      <!-- OLD CODE for unwanted Client/tenant ID form COMMENTED :::ends -->
                    </ng-template>
                  </form>
                </div>

                <div class="col-md-12 t-c" *ngIf="!featureChecker()">
                  <app-upgrade-plan title="Feature not available"
                    message="<strong>Microsoft Teams</strong> feature is not available in your current plan. Please contact us at <a href='mailto:support@insiq.io?subject=Plan Upgrade'>support@insiq.io</a> to avail this feature">
                  </app-upgrade-plan>
                </div>

                <div class="col-md-12 t-c">
                  <img src="assets/images/infographics/ms-team.png" alt="MS Team">
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Change Password">
              <div class="row align-items-center">
                <div class="col-md-12">
                  <form [formGroup]="passView.form" novalidate>
                    <div class="col-xl-24">

                      <label class="mandatory d-block">Current Password</label>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                        <input type="password" matInput placeholder="Current Password" formControlName="currPwd">
                        <mat-error *ngIf="passView.invalid('currPwd')">{{passView.error('currPwd')}}</mat-error>
                      </mat-form-field>
                      <label class="mandatory d-block">New Password</label>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                        <input type="password" matInput placeholder="New Password" formControlName="inputPwd">
                        <mat-error *ngIf="passView.invalid('inputPwd')">{{passView.error('inputPwd')}}</mat-error>
                      </mat-form-field>
                      <label class="mandatory d-block">Confirm Password</label>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                        <input type="password" matInput placeholder="Confirm Password" formControlName="cpassword"
                          [errorStateMatcher]="matcher">
                        <mat-error *ngIf="passView.form.hasError('notSame')">Password and Confirm password does not
                          match
                        </mat-error>
                      </mat-form-field>

                      <!-- <mat-form-field class="w-100">
                        <input type="password" matInput placeholder="Current Password" formControlName="currPwd">
                        <mat-error *ngIf="passView.invalid('currPwd')">{{passView.error('currPwd')}}</mat-error>
                      </mat-form-field>
                      <mat-form-field class="w-100">
                        <input type="password" matInput placeholder="New Password" formControlName="inputPwd">
                        <mat-error *ngIf="passView.invalid('inputPwd')">{{passView.error('inputPwd')}}</mat-error>
                      </mat-form-field>
                      <mat-form-field class="w-100">
                        <input type="password" matInput placeholder="Confirm Password" formControlName="cpassword"
                          [errorStateMatcher]="matcher">
                        <mat-error *ngIf="passView.form.hasError('notSame')">Password and Confirm password does not
                          match
                        </mat-error>
                      </mat-form-field> -->
                      <div class="t-c">
                        <!-- <button type="button" [routerLink]="['/project']" mat-raised-button
                        class="curved m-x-5 btn-cancel">Back</button> -->
                        <button type="button" (click)="changePwd()" [disabled]="passView.invalid()" matRipple
                          class="btn btn-lg btn-primary m-x-5">Change</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-12 t-c">
                  <img src="assets/images/infographics/change-password.png" alt="Change Password">
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Personalize" *ngIf="currentUsr?.pin!='USR'">
              <div class="col-md-12 t-c" *ngIf="!personlizefeatureChecker()">
                <app-upgrade-plan title="Feature not available"
                  message="<strong>Personalize</strong> feature is not available in your current plan. Please contact us at <a href='mailto:support@insiq.io?subject=Plan Upgrade'>support@insiq.io</a> to avail this feature">
                </app-upgrade-plan>
              </div>
              <div class="tabWrapperMinHgt" *ngIf="personlizefeatureChecker()">
                <div class="title">Personalize InsIQ App</div>

                <div class="row align-items-center">
                  <div class="col-md-12">
                    <form [formGroup]="themeView.form" novalidate>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="logo" class="d-block f-bold">Upload Custom Logo</label>
                          <div class="form-group">
                            <div class="fc-group">
                              <div class="fc-fileupload">
                                <input appFcUi type="file" accept="image/png, image/jpeg" name="logo" id="logo"
                                  class="fc" (change)="previewFile($event)" formControlName="logopath"
                                  placeholder="Upload Custom Logo">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <label for="appTheme" class="d-block f-bold">Choose App Theme</label>
                          <div class="form-group fc-group">
                            <ng-select [items]="themeList" [searchable]="true" bindLabel="themeCode" id="appTheme"
                              (change)="onThemeChange($event)" formControlName="apptheme_id">
                            </ng-select>
                          </div>
                        </div>
                        <div class="col-md-24">

                          <label for="domainname" class="d-block f-bold">Choose Custom Domain</label>
                          <div class="form-group">
                            <div class="fc-group">
                              <div class="fc-inline-text">
                                <span>
                                  <input appFcUi name="domainname" id="domainname" class="fc"
                                    formControlName="domainname" [readonly]="domainStatus"
                                    (keypress)="omit_special_char($event)" (keydown.space)="$event.preventDefault();"
                                    placeholder="Prefered Domain">
                                </span>
                                <span class="text text-primary">.insiq.ai</span>
                                <div class="m-l-30 text text-danger" *ngIf="domainStatus&&domainverify===0">
                                  <i class="i i-warning"></i>
                                  <span>Verification in Progress</span>
                                </div>
                                <div class="m-l-30 text text-success" *ngIf="domainStatus&&domainverify===1">
                                  <i class="i i-check_circle"></i>
                                  <span>Verified Domain</span>
                                </div>

                              </div>


                            </div>
                            <mat-error *ngIf="view.invalid('email')">{{view.error('email')}}</mat-error>
                          </div>
                          <div class="text-danger f-light info">
                            You may opt for your brand/company specific subdomain for your InsIQ subscription and to
                            have
                            a rebranded login URL for your organization.
                            <br><br>
                            For example, if your company name is ‘apple’, you can opt for :
                            https://apple.insiq.ai
                            <br><br>
                            N.B:-Activation & validation of subdomain may take maximum of 48 hours.
                            <br><br>
                            Once a sub domain is created,you cannot modify it.
                          </div>

                        </div>

                        <div class="col-md-24 t-c m-t-30">
                          <!-- <button type="button" [routerLink]="['/project']" mat-raised-button
                        class="curved m-x-5 btn-cancel">Back</button> -->
                          <button type="button" (click)="upload()" matRipple
                            class="btn btn-lg btn-primary m-x-5">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-12 t-c">
                    <label for="address" class="d-block f-bold">App Logo Preview</label>
                    <img class="w-20 h-20" [src]="photoURL()" alt="App Logo">

                  </div>

                </div>
              </div>
            </mat-tab>
            <mat-tab *ngIf="currentUsr?.pin!='USR'">
              <ng-template mat-tab-label>
                <span class="deleteAccount">Delete Account</span>
              </ng-template>
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="deleteSect">
                    <p class="title t-AA">
                      <!-- <i class="i i-warning"></i> -->
                      Delete Account! Are you ABSOLUTELY SURE?
                    </p>
                    <p class="text-danger">
                      Deletion is permanent and is an irreversible process, which we can't revert even if you perform it
                      by accident.
                    </p>
                    <div class="message _warning">
                      <i class="i i-warning i-2x"></i>
                      <div class="details text-dark">
                        <strong class="d-block m-b-10">You are about to permanently delete your InsIQ Account</strong>
                        <div>
                          Deleting your account will remove all your data associated with this account and access to
                          <strong>InsIQ</strong>
                        </div>
                      </div>
                    </div>
                    <!-- <p>
                    This action cannot be undone. You will lose the project's respository and all content: issues, merge
                    requests, etc.
                  </p> -->
                    <mat-checkbox (change)="changeCheck($event)">
                      <p class="text-danger m-t-20">
                        Please Confirm your action.
                      </p>
                    </mat-checkbox>

                    <div class="d-flex justify-content-center">
                      <!-- <button type="button" mat-raised-button class="curved m-b-15 m-x-5 btn-cancel"
                      (click)="resetPassword(false)">
                      Back
                    </button> -->
                      <button type="button" (click)="cnfirmdel()" matRipple [disabled]="disablecheck()"
                        class="btn btn-lg btn-danger m-b-15 m-x-5">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 t-c">
                  <img src="assets/images/infographics/delete-account.png" alt="Delete Account">
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

    </form>

    <ng-template #welcomeUser>
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="m-r-5">
            <img src="assets/images/users/default.png" [alt]="user?.displayName">
          </div>
          <mat-card-title>Welcome {{user?.displayName?user?.displayName:teamsManager?.displayName}}</mat-card-title>
          <mat-card-subtitle>{{user?.email?user?.email:teamsManager?.msMail}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <p class="highlight">Microsoft Account Integrated Successfully with InsIQ</p>
          <div class="row m-b-20">
            <div class="col">
              <small class="lbl">Display name</small>
              <div class="f-700 text-accent">{{user?.displayName?user?.displayName:teamsManager?.displayName}}</div>
            </div>
            <div class="col">
              <small class="lbl">Email</small>
              <div class="f-700 text-accent">{{user?.email?user?.email:teamsManager?.msMail}}</div>
            </div>
            <div class="col"></div>
          </div>

          <div class="highlight">Microsoft Teams Details</div>
          <div class="row m-y-20">
            <div class="col">
              <small class="lbl">Team Id</small>
              <div class="f-700 text-accent">{{teamdetails?.teamId?teamdetails?.teamId:teamsManager?.teamId}}</div>
            </div>
            <div class="col">
              <small class="lbl">Team Name</small>
              <div class="f-700 text-accent">
                {{teamdetails?.displayName?teamdetails?.displayName:teamsManager?.displayName}}</div>
            </div>
            <div class="col">
              <small class="lbl">Team Description</small>
              <div class="f-700 text-accent">
                {{teamdetails?.description?teamdetails?.description:teamsManager?.teamDescription}}</div>
            </div>
          </div>

          <div class="b-a-2 b-a-primary dashed d-flex p-a-15 align-items-center">
            <div class="ib-m">
              <i class="i i-assignment_ind i-4x text-accent"></i>
            </div>
            <div class="ib-m p-l-15">
              <p class="h4 text-accent m-b-0">Add your AD Users to InsIQ Account</p>
              <p class="textfnt">
                You may add team members from your organisation Active Directory as users in your <b>InsIQ</b>
                account for furthur collabrations.
              </p>
            </div>
          </div>

        </mat-card-content>
        <mat-card-footer>
          <div class="t-c">
            <button type="button" [routerLink]="['/users']" matRipple class="btn btn-lg btn-primary m-b-10 m-x-5">
              Add AD Users
            </button>
            <button type="button" (click)="signOut()" matRipple class="btn btn-lg btn-primary m-x-5 m-b-10">
              Sign Out from MS Teams
            </button>
            <button type="button" (click)="removeMs()" matRipple class="btn btn-lg btn-danger m-x-5 m-b-10">
              Delete MS Teams Integration
            </button>
          </div>
        </mat-card-footer>
      </mat-card>
    </ng-template>
  </div>
</div>
<ng-template #secondDialog>
  <!-- <div class="row align-items-center"> -->

  <div class="deleteSect b-a-0 p-a-0">
    <p class="title t-AA">
      <!-- <i class="i i-warning"></i> -->
      Confirm Subscription Cancellation
    </p>
    <p>
      If you proceed with cancelling your subscription, your current plan will be terminated. This means you will
      lose access to your projects until you purchase a new plan, and your team members will no longer be able to
      log in. Are you certain you want to proceed with the cancellation?
    </p>
    <div class="message _warning justify-content-start">
      <i class="i i-warning i-2x"></i>
      <div class="details text-dark">
        <strong class="d-block m-b-10">{{message}}</strong>

        <small> *A refund is eligible when you cancel your current plan within seven days of subscribing.</small>
        <!-- <strong>InsIQ</strong> -->

      </div>
    </div>


    <div class="d-flex justify-content-center">
      <button type="button" class="btn btn-lg btn-cancel m-b-15 m-x-5" (click)="cancelpop()">
        Back
      </button>
      <button type="button" (click)="cancelConfirm()" matRipple class="btn btn-lg btn-danger m-b-15 m-x-5">
        Proceed
      </button>
    </div>
  </div>


</ng-template>