import { ActivatedRoute, Router } from '@angular/router';
import { AIView } from '../ai/insight.view';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../notesalerts/notes-dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { Component, Inject, TemplateRef, OnInit, Optional, ViewChild } from '@angular/core';
import { DeepdiveDialogComponent } from '../deepdive-dialog/deepdive-dialog.component';
import { Config, View } from 'src/app/config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/_services';
import { Console } from 'console';
@Component({
  selector: 'app-cloneproject',
  templateUrl: './cloneproject.component.html',
  styleUrls: ['./cloneproject.component.scss']
})
export class CloneprojectComponent implements OnInit {
  notesDialogRef: MatDialogRef<NotesDialogComponent, any>;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  mailStat = false;
  validFlag = false;
  InsIQUser = false;
  constructor(
    public ai: AIView,
    public router: Router,
    public dialog: MatDialog,
    private http: HttpClient,
    private fb: FormBuilder,
    public shareProjView: View,
    public alertService: AlertService,

    public route: ActivatedRoute,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<DeepdiveDialogComponent>
  ) {
    this.layout = this.data;
    this.mailStat = false;
    this.validFlag = false;
    this.InsIQUser = false;
    this.shareProjView = new View(http, router, route, alertService);

    //
    // console.log('test');

  }
  @ViewChild('secondDialog', { static: true }) STAT_POPUP: TemplateRef<any>;
  layout: TemplateRef<any>;
  ngOnInit() {
  }

  backtoPage() {
    this.dialogRef.close();
  }

  backtoHome() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }
  omit_special_char(event, searchVal, fl = 1, commonflag = false) {
    //console.log('hereee')
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    // console.log(k);
    if (k === 44 || k === 59 || k === 37 || k === 38 || k === 43) {
      event.preventDefault();
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    this.mailStat = emailRegex.test(searchVal);


    return true;
  }

  validateUser(searchVal) {
    Config.loading = true;

    this.shareProjView.get('user/uservalidate/' + searchVal).subscribe(res => {

      // this.countryList=res;
      //  console.log(res);

      if (res?.length != 0) {
        Config.loading = false;
        this.mailStat = false;

        this.validFlag = true;
        if (res.userstatus === 'valid') {
          this.InsIQUser = true;
        } else {
          this.InsIQUser = false;
        }
      }

    });

  }

  getNewProjectShareForm() {
    return this.fb.group({
      projectId: [''],
      userId: [''],
      tomail: [''],
      accepted: [''],

    });
  }
  shareProject(toMail) {
    //console.log(this.data);
    let projId = this.data.id;
    //this.getNewProjectShareForm();
    this.shareProjView.initForm('project/shareproject', this.getNewProjectShareForm());

    Config.loading = true;
    // this.shareProjView.set('projectId', projId);
    // this.shareProjView.set('userId', this.data?.subscriptionId?.userId?.id);
    // this.shareProjView.set('tomail', toMail);
    // this.shareProjView.set('accepted', 0);

    //this.shareProjView.save('project/shareproject', 'Project Shared Successfully');
    this.shareProjView.get('project/shareproject/' + projId + '/' + toMail).subscribe(res => {
      this.dialogRef.close();
      Config.loading = false;
      this.alertService.success('Project Shared Successfully', true, 10 * 1000);

    });

    //this.shareProjView.end();
  }
}
