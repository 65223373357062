import { HttpClient } from '@angular/common/http';
import { SharedService } from './shared.service';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { View, Config } from '../../config/global.config';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';
import { SharedProjectService } from '../project/sharedproject.service';
@Injectable({
  providedIn: 'root'
})
export class RequirementTypeView {
  url = 'requirementtype';
  public view: View;
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private fb: FormBuilder,
    private ss: SharedService,
    private ssp:SharedProjectService) {
    this.view = new View(http, router, route, alertService);
  }

  setForm() {
    this.ss.setRequirementTypeView(this);
    this.view.state = 'list';
    this.view.refreshList = false;
    Config.isProjectSelected = true;
  }


  getList() {
    this.view.get(`${this.url}/observation/${this.ssp.getProjectId()}/${this.ss.keyPlayer.id}`).subscribe(res => {
      this.view.list = res;
    });
  }

  edit(row) {
    this.view.edit(row);
  }

}
