<div class="w-100 h-100 loginMain">


  <form class="regForm" [formGroup]="view.form">
    <div class="card loginCard" *ngIf="!overlayflag">
      <div>
        <div class="loginForm">
          <!-- <img src="../../../assets/images/insiq.png" alt="InsIQ"> -->

          <div class="title">Get Started for FREE</div>
          <div class="h6">Already have an account? <a [routerLink]="['/login']" [queryParams]="{url: view.returnUrl}"
              class="link">Sign In</a> </div>
          <ul class="row">
            <li class="col-12"><i class="i i-check_outline_circle"></i><span>AI-Powered Insights</span></li>
            <li class="col-12"><i class="i i-check_outline_circle"></i><span>Free Access</span></li>
            <li class="col-12"><i class="i i-check_outline_circle"></i><span>User-Centric Design</span></li>
            <li class="col-12"><i class="i i-check_outline_circle"></i><span>Market Analysis</span></li>
          </ul>




          <div class="row">
            <div class="col-md-24">
              <div class="form-group">
                <div class="fc-group" [ngClass]="{hasError: view.invalid('email')}">
                  <input appFcUi type="email" name="Email" id="email" inputmode="email" class="fc"
                    formControlName="email" placeholder="Email Address (Username)">
                </div>
                <mat-error *ngIf="view.invalid('email')">You must enter a valid email</mat-error>
              </div>
            </div>
            <div class="col-md-24">
              <div class="form-group">
                <div class="fc-group" [ngClass]="{hasError: view.invalid('name')}">
                  <input appFcUi type="text" name="FirstName" id="fname" inputmode="text" class="fc"
                    formControlName="name" placeholder="Name">
                </div>
                <mat-error *ngIf="view.invalid('name')">You must enter name</mat-error>
              </div>
            </div>
            <!-- <div class="col-md-12">
                <div class="form-group">
                  <div class="fc-group">
                    <input appFcUi type="text" name="LastName" id="lname" inputmode="text" class="fc"
                      placeholder="Last Name">
                  </div>
                </div>
              </div> -->
            <div class="col-md-24">
              <div class="form-group">
                <div class="fc-group __o" [ngClass]="{hasError: view.invalid('inputPwd')}">
                  <input appFcUi [type]="isPasswordReveal ? 'text': 'password'" name="Password" id="password"
                    formControlName="inputPwd" class="fc" placeholder="Password">
                  <div class="ic ev" (click)="isPasswordReveal = !isPasswordReveal" tabindex="0">
                    <i class="i" aria-label="password">{{isPasswordReveal ? 'visibility_off' : 'visibility'}}</i>
                  </div>
                </div>
                <mat-error *ngIf="view.invalid('inputPwd')">You must enter password</mat-error>
              </div>
            </div>
            <div class="col-md-24">
              <div class="form-group">
                <div class="fc-group __o" [ngClass]="{hasError: view.form.hasError('notSame')}">
                  <input appFcUi [type]="isPasswordReveal1 ? 'text': 'password'" name="Password" id="password"
                    formControlName="cpassword" class="fc" placeholder="Confirm Password">
                  <div class="ic ev" (click)="isPasswordReveal1 = !isPasswordReveal1" tabindex="0">
                    <i class="i" aria-label="password">{{isPasswordReveal1 ? 'visibility_off' : 'visibility'}}</i>
                  </div>
                </div>
                <mat-error *ngIf="view.form.hasError('notSame')">Password and Confirm password does not match
                </mat-error>
              </div>
            </div>
            <div class="col-md-24">
              <div class="form-group fc-group">
                <!--Using items input-->
                <ng-select [items]="countries" #comboCountries bindValue="id" bindLabel="value" labelForId="country"
                  [virtualScroll]="true" dropdownPosition="auto" placeholder="Select Country" formControlName="country"
                  (change)="onCountryChange($event)" appendTo="body">
                  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                    {{item.value}}
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="col-md-24">
              <div class="form-group fc-group" *ngIf="selectedCountry.value!=''">
                <ng-select [items]="timeZones" #comboTimezones bindValue="id" bindLabel="value" labelForId="timezone"
                  [virtualScroll]="true" dropdownPosition="auto" placeholder="Select Timezone"
                  formControlName="timezone" (change)="onChange($event)" appendTo="body">
                  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                    {{item.value}}
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="agreement">
            <mat-checkbox name="chk" id="chk" class="custom" (change)="tNcChange($event)">
              <label for="chk">
                By clicking Create Account, I agree that I have read and<br>
                accepted the Terms of Use and Privacy Policy.
                <!-- <a (click)="openTnC()" class="link">Privacy Policy and Terms of Service</a>. -->
              </label>
            </mat-checkbox>
            <!-- <input type="checkbox" name="chk" id="chk" class="custom" (change)="tNcChange($event)">
                <label for="chk">
                  By signing up, I agree to the InsIQ
                  <a (click)="openTnC()" class="link">Privacy Policy and Terms of Service</a>.
                </label> -->
          </div>
          <!-- type="submit" -->
          <div class="d-flex m-t-20">
            <button class="btn btn-primary btn-{{getStyle()}} btn-lg w-100 t-c" [disabled]="view.invalid() ||!istandc"
              matRipple (click)="nextStep()">
              Get Started Now
            </button>
          </div>
          <hr />
          <p>
            Protected by reCAPTCHA and subject to the InsIQ
            <a href="javascript:;" (click)="openTnC('privacy')" class="link">Privacy Policy</a> and
            <a href="javascript:;" (click)="openTnC('terms')" class="link">Terms of Service</a>.
          </p>

        </div>
        <div class="logoWrap">
          <div class="wrap">
            <div class="title">Trusted by professionals who have worked for</div>
            <div class="logoImages">

              <div class="row">
                <div class="col-6 col-md-12">
                  <picture>
                    <source media="(min-width: 0px)" srcset="./assets/images/login/google.png">
                    <img src="./assets/images/login/google.png" alt="google">
                  </picture>
                </div>
                <div class="col-6 col-md-12">
                  <picture>
                    <source media="(min-width: 0px)" srcset="./assets/images/login/adobe.png">
                    <img src="./assets/images/login/adobe.png" alt="adobe">
                  </picture>
                </div>
                <div class="col-6 col-md-12">
                  <picture>
                    <source media="(min-width: 0px)" srcset="./assets/images/login/microsoft.png">
                    <img src="./assets/images/login/microsoft.png" alt="microsoft">
                  </picture>
                </div>
                <div class="col-6 col-md-12">
                  <picture>
                    <source media="(min-width: 0px)" srcset="./assets/images/login/dropbox.png">
                    <img src="./assets/images/login/dropbox.png" alt="dropbox">
                  </picture>
                </div>
                <div class="col-6 col-md-12">
                  <picture>
                    <source media="(min-width: 0px)" srcset="./assets/images/login/asana.png">
                    <img src="./assets/images/ligin/asana.png" alt="asana">
                  </picture>
                </div>
                <div class="col-6 col-md-12">
                  <picture>
                    <source media="(min-width: 0px)" srcset="./assets/images/login/atlassian.png">
                    <img src="./assets/images/ligin/atlassian.png" alt="atlassian">
                  </picture>
                </div>
                <div class="col-6 col-md-12">
                  <picture>
                    <source media="(min-width: 0px)" srcset="./assets/images/login/hubspot.png">
                    <img src="./assets/images/ligin/hubspot.png" alt="hubspot">
                  </picture>
                </div>
                <div class="col-6 col-md-12">
                  <picture>
                    <source media="(min-width: 0px)" srcset="./assets/images/login/shopify.png">
                    <img src="./assets/images/ligin/shopify.png" alt="shopify">
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- <div class="stringText m-y-25" *ngIf="(!overlayflag&&!appsumo)&&(!overlayflag&&!pdthunt)">
              <span>or</span>
            </div>

            <div class="socialmedia" *ngIf="(!overlayflag&&!appsumo)&&(!overlayflag&&!pdthunt)">
              <button type="button" class="btn btn-default btn-lg t-c" matRipple (click)="googleSign()">
                <img src="../../assets/images/ico_google.svg" alt="Google">
                <span>Google</span>
              </button>
              <button type="button" class="btn btn-default btn-lg t-c" matRipple (click)="linkedinSign()">
                <img src="../../assets/images/ico_linkedin.svg" alt="LinkedIn">
                <span>LinkedIn</span>
              </button>

            </div> -->

      </div>


    </div>
    <div class="card loginCard" *ngIf="overlayflag">
      <aside>
        <nav>
          <ol class="timeline">
            <li class="{{step1}}">
              <span>Sign Up Info</span>
            </li>
            <li class="{{step2}}">
              <span>Type of Industry</span>
            </li>
            <li class="{{step3}}">
              <span>Role</span>
            </li>
            <li class="{{step4}}" *ngIf="appsumo">
              <span>Redeem Code</span>
            </li>
            <!-- <li>
                  <span>Business Details</span>
                </li>
                <li>
                  <span>Team</span>
                </li> -->
          </ol>
        </nav>
      </aside>
      <main>
        <!-- <pre>{{view.form.value | json}}</pre> -->
        <!-- (selectionChange)="stepChanged($event)" -->
        <!-- <div class="d-flex">
              <div class="m-l-auto">
                Having trouble?
                <a href="javascript:;" class="link f-bold">Get Help</a>
              </div>
            </div> -->
        <mat-horizontal-stepper linear #stepper class="transparentStepper">
          <mat-step [stepControl]="view.form" [editable]="true">
            <h2 class="title">Choose type of Industry</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->


            <ng-select [items]="companyTypeList" appearance="outline" #companytype bindValue="type_name"
              bindLabel="type_name" labelForId="companytype" [virtualScroll]="true" dropdownPosition="auto"
              placeholder="Select Industry" formControlName="companytype" appendTo="body">
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                {{item.type_name}}
              </ng-template>
            </ng-select>
            <div class="m-t-20" *ngIf="view.value('companytype')=='Others (Please specify)'">
              <div class="form-group">
                <div class="fc-group" [ngClass]="{hasError: view.invalid('companytypeother')}">
                  <input appFcUi type="text" name="companytypeother" id="fname" inputmode="text" class="fc"
                    formControlName="companytypeother" placeholder="Please Specify the Industry">
                </div>
                <mat-error *ngIf="view.invalid('companytypeother')">Please Specify the Industry</mat-error>
              </div>
            </div>

            <!-- <ul class="optGroup">
            
              <li *ngFor="let menu of companyTypeList">
                <input type="radio" name="companytype" [id]="menu.iconclass"
                  [checked]="this.view.value('companytype')===menu.iconclass" [value]="menu.iconclass" class="custom"
                  (input)="updateCtrlValue($event, 'companytype')">
                <label [for]="menu.iconclass" class="btn navigate">
                  <div class="ico"><i class="i i-{{menu.iconclass}}"></i></div>
                  <div class="label">
                    <div class="caption">{{menu.type_name}}</div>
                    <div class="detail">{{menu.description}}</div>
                  </div>
                </label>
              </li>
              <input type="hidden" formControlName="companytype">
            </ul> -->

            <div class="actions">
              <div class="m-r-auto">
                <button type="button" matRipple class="btn btn-outline-primary t-c" matStepperPrevious
                  (click)="prevtoPersonal()">Previous</button>
              </div>
              <div class="m-l-auto">
                <button type="button" matRipple class="btn btn-{{!this.view.value('companytype')?'':'primary'}} t-c"
                  matStepperNext [disabled]="disableCheck()" (click)="nexttoRole()">Next</button>
              </div>
            </div>
          </mat-step>
          <mat-step [stepControl]="view.form" [editable]="true">
            <h2 class="title">Choose your role</h2>
            <!-- <p>Our platform enables you to transform data into insights and make decisions accordingly.</p> -->

            <ng-select [items]="companyRoleList" appearance="outline" #companyrole bindValue="description"
              bindLabel="description" labelForId="companyrole" [virtualScroll]="true" dropdownPosition="auto"
              placeholder="Select Role" formControlName="companyrole" appendTo="body">
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                {{item.description}}
              </ng-template>
            </ng-select>
            <div class="m-t-20" *ngIf="view.value('companyrole')=='Others (Please specify)'">
              <div class="form-group">
                <div class="fc-group" [ngClass]="{hasError: view.invalid('companyroleother')}">
                  <input appFcUi type="text" name="companyroleother" id="fname" inputmode="text" class="fc"
                    formControlName="companyroleother" placeholder="Please Specify the Industry">
                </div>
                <mat-error *ngIf="view.invalid('companyroleother')">Please Specify the Industry</mat-error>
              </div>
            </div>

            <!-- <ul class="optGroup tiles">
              <li *ngFor="let menu of companyRoleList">
                <input type="radio" name="company" [id]="menu.idclass" [value]="menu.idclass"
                  [checked]="this.view.value('companyrole')===menu.iconclass" class="custom"
                  (input)="updateCtrlValue($event, 'companyrole')">
                <label [for]="menu.idclass" class="btn">
                  <div class="ico"><i class="i i-role"></i></div>
                  <div class="label">
                    <div class="caption">{{menu.description}}</div>
                  </div>
                </label>
              </li>
              <input type="hidden" formControlName="companyrole">
            </ul> -->

            <div class="actions">
              <div class="m-r-auto">
                <button type="button" matRipple class="btn btn-outline-primary" (click)="prevtoRole()"
                  matStepperPrevious>Previous</button>
                <!-- <button type="button" matRipple class="btn btn-outline-primary" *ngIf="appsumo"
                      (click)="prevtoRole()" matStepperPrevious>Previous</button> -->
              </div>
              <div class="m-l-auto">
                <button type="submit" (click)="onSubmit()" matRipple *ngIf="!appsumo"
                  class="btn btn-{{!this.view.value('companyrole')?'':'primary'}} t-c" matStepperNext
                  [disabled]="disableCheckRole()" matStepperNext>Submit</button>

                <button type="submit" (click)="nexttoCode()" matRipple *ngIf="appsumo"
                  class="btn btn-{{!this.view.value('companyrole')?'':'primary'}} t-c" matStepperNext
                  [disabled]="disableCheckRole()" matStepperNext>Next</button>

              </div>
            </div>
          </mat-step>
          <mat-step [stepControl]="view.form" [editable]="true" *ngIf="appsumo">
            <h2 class="title">Redeem Appsumo Code</h2>
            <!-- <p>Our platform enables you to transform data into insights and make decisions accordingly.</p> -->
            <ul class="optGroup tiles">
              <div class="form-group">
                <div class="fc-group" [ngClass]="{hasError: view.invalid('redeemcode')}">
                  <input appFcUi type="text" name="redeemcode" id="fname" inputmode="text" class="fc"
                    formControlName="redeemcode" placeholder="Redeem Code">
                </div>
                <mat-error *ngIf="view.invalid('redeemcode')">{{error('redeemcode')}}</mat-error>
              </div>
            </ul>
            <div class="actions">
              <div class="m-r-auto">
                <button type="button" matRipple class="btn btn-outline-primary t-c" (click)="redeemPrev()"
                  matStepperPrevious>Previous</button>
              </div>
              <div class="m-l-auto">
                <button type="submit" (click)="onSubmit($event)" matRipple
                  class="btn btn-{{!this.view.value('redeemcode')?'':'primary'}} t-c" matStepperNext
                  [disabled]="!this.view.value('redeemcode')" matStepperNext>Submit</button>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </main>
    </div>

  </form>




</div>