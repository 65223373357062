<div>
  <div class="d-block ">
    <h4 class="text-accent">
    <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" [mat-dialog-close]="true">
      <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
    </button>
  </h4>
    <!-- <mat-card-header>
      <div mat-card-avatar class="b-a-1 t-c"><i class="i i-info i-x text-primary"></i></div>
      <mat-card-title>Help</mat-card-title>
    </mat-card-header> -->
    <!-- <mat-card-subtitle>{{ai.charttitle}}</mat-card-subtitle></mat-card-header> -->
    <!-- <div class="actions">
      <button class="btn-md" (click)="backtoPage()">{{data.title}}</button>
      <button class="btn-md btnalert" (click)="backtoHome()">NO</button>
    </div> -->
  </div>
  <img src="./assets/images/{{getImg(data.title)}}" style="max-width: 600px;">
</div>