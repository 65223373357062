<header *ngIf="!config.isLoggedIn()">
  <div class="container-fluid d-flex">
    <a [routerLink]="['/']" class="brand">
      <img src="../../../assets/images/insiq.png" alt="">
    </a>
    <div class="spacer"></div>
    <ul class="nav">
      <li>
        <a [routerLink]="['/register']">
          Register
        </a>
      </li>
    </ul>
  </div>
</header>
<div class="container-fluid d-flex h-100">
  <div class="wrapper">
    <!--  [ngClass]="config.isLoggedIn()?'':'p-t-0'" -->
    <div class="conArea help">
      <div class="row">
        <div class="col-sm-16">
          <section class="m-b-40">
            <h1 class="h1 text-accent">InsIQ Help Manual</h1>
            <p>
              Welcome to InsIQ your smart SaaS platform with Augmented Intelligence (AI). InsIQ is designed to help
              with ‘Development To Market’ for your new product idea. In this guide we show you how to make best use of
              the InsIQ platform for your development. If you have not read our FREE ebook on ‘How To Launch A Great
              Product’, we highly recommend you have a look at it before using our software. Should you need any
              support with the use of InsIQ platform or have suggestions, our friendly team is only an email away.
              Reach us at: <a href="mailto:support@insiq.io">support@insiq.io</a>
            </p>
            <p>
              <span class="text-accent f-bold">InsIQ</span> is <em>AI-enabled</em> product development software that
              brings your <em>ideas to life</em> and your product to market.
            </p>
          </section>

          <article #register>
            <h1 class="h3 text-accent">Step 1: Register for your new account</h1>
            <figure>
              <img src="./assets/images/help/1.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>

            <p>
              Once, you have an account you can login next time with your credentials.
            </p>

            <figure>
              <img src="./assets/images/help/2.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>

            <!--<iframe width="420" height="315" [src]="https://www.youtube.com/embed/YE7VzlLtp-4" ></iframe>-->
            <!--<video preload="auto" poster="./assets/videos/bbb.jpg" controls>
              <source src="./assets/videos/video.mp4" type="video/mp4">
              <source src="./assets/videos/video.ogg" type="video/ogg">
              <source src="./assets/videos/video.webm" type="video/webm">
              <p>Your browser does not support this video.</p>
            </video>-->
          </article>

          <article #newProject>
            <h1 class="h3 text-accent">Step 2: Create a new project</h1>
            <figure>
              <img src="./assets/images/help/3.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>

            <p>
              As soon as you hit ‘Create’ you can start viewing your list of curated insights related to patents,
              competition and key players who might affect your product development. If you find a relevant link you
              can hit the ‘thumbs up’ icon and save it to your favourites. The initial insights may not be too refined
              (InsIQ AI has only just started to work on your project), as you progress you will start to see more and
              more relevant insights specific to the product you are developing. You can also edit your project (title
              or description) anytime. Access ‘Initial Insights’ (top right bar).
            </p>

            <figure>
              <img src="./assets/images/help/4.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>

            <div class="proTip">
              <h5 class="h5 text-accent">Pro Tip: </h5>
              <p>
                The project title should be short. This is one of the important elements which helps InsIQ provide
                curated insights from the web, back end data sets or custom proprietary data sets which can be uploaded
                by the user. The insights are in the form of links, images, videos etc.
                For the project description, here is a template you can follow.
              </p>

              <p>
                <b>Description</b>: Name (brand name) is a ______________ (product category) designed for
                ____________(the Key Players) who has a need to ________________ (a problem - requirements). We will
                sell
                to _________ (B2C/ B2B).
                The product _______ has several features to _______ (product features).
                The customer type is primarily a business or consumer i.e. are you B2B/ B2C?
                Business to Consumer/ B2C: Your users are primarily _____________ (people) in the age group __________
                and residing in the __ (market by region).
                Business to Business/ B2B: Your customers are primarily __________ (company size) in the sector
                __________ and based in the _____(country).
              </p>

              <figure>
                <img src="./assets/images/help/5.png" alt="Help Figure">
                <!-- <figcaption>Figure 1.1</figcaption> -->
              </figure>
            </div>
          </article>

          <article #reviewAIInsights>
            <h1 class="h3 text-accent">Step 3: Review Your ‘AI Insights’ From Dashboard</h1>

            <p>
              Throughout your project you can review a list of curated results that InsIQ powered by its AI (Augmented
              Intelligence) recommends for you. You can access it anytime from your project dashboard via the ‘AI
              Insights’ tab.
            </p>

            <figure>
              <img src="./assets/images/help/6.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>

            <div>
              <i class="i i-ai i-5x text-primary"></i>
            </div>
            <p>
              Whenever you see this icon, it is our AI working for you behind the scenes. Click to get insights.
            </p>

            <p>Example AI Insights on wearable nutrition:</p>

            <figure>
              <img src="./assets/images/help/7.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>
            <figure>
              <img src="./assets/images/help/8.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>
            <figure>
              <img src="./assets/images/help/9.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>

            <div class="proTip">
              <h5 class="h5 text-accent">Pro Tip: </h5>
              <p>
                Study the links to your competitor products, see what they are doing ‘right’ and what they are doing
                ‘wrong’; checkout the reviews, see what the influencers are talking about and where are the true pain
                points in the market that maybe only your product can solve?
                Find out who else owns Intellectual Property (IP) in this space but do not be discouraged if there are
                other patents. IP is a complex topic and InsIQ insights may help guide you through some of your own IP
                decisions.
              </p>

              <figure>
                <img src="./assets/images/help/10.png" alt="Help Figure">
                <!-- <figcaption>Figure 1.1</figcaption> -->
              </figure>
            </div>
          </article>

          <article #addKeyPlayers>
            <h1 class="h3 text-accent">Step 4: Add KeyPlayers</h1>

            <p>
              Start your product development Planning process in the guided menu of InsIQ Discovery phase by adding a
              new KeyPlayer.
            </p>

            <figure>
              <img src="./assets/images/help/11.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>

            <figure>
              <img src="./assets/images/help/12.png" alt="Help Figure">
              <figcaption>Figure 1: InsIQ Planning -> Discovery </figcaption>
            </figure>

            <p>
              Now you are ready to start on your product development journey with InsIQ. Here is a guide to selecting
              your key players:
            </p>
            <p>
              To make it simple, your key players will always belong to one of these three groups (KeyPlayer category)
              as below. Describe and choose a category for your KeyPlayer.
            </p>
            <p>
              The key players for your product are your customers, suppliers, your investors, regulatory agencies, etc.
              In short, anyone who helps or influences your journey from idea to market.
            </p>
            <p>
              To make it simple, your key players will always belong to one of these three groups:
            </p>

            <ol>
              <li>CONSUMERS: The people who are going to buy or use or consume your product.
                They are the most important key player.
                For example, if you were developing a frozen vegan pizza, your consumers would be “Female vegans under
                the age of 45 who live on the west coast of the US”.
                You could be as specific or general as you need to.</li>
              <li>
                CONTRIBUTORS: These are all the people or groups who help bring your idea to life and in the hands of
                your consumers.
                These could be your retailers, manufacturers, designers, suppliers, etc.
                To continue with the pizza example,
                your contributors could be Costco, where your pizza is going to be sold,
                the bakery which makes and freezes the pizza,
                the shipper who gets it to Costco,
                the packaging company which designed and manufactures the boxes and so on.
              </li>
              <li>
                INFLUENCERS: These could be the people or groups like your investors, your management, regulatory
                agencies, celebrities and magazines or blogs which review your product category, etc. who influence
                your decisions.
              </li>
            </ol>

            <p>For a food product for instance your key players could be your investors who are expecting a good return
              on their money, the Department of Public Health, Consumer Reports, Vegan Food and Living magazine, etc.</p>

            <figure>
              <img src="./assets/images/help/13.png" alt="Help Figure">
              <figcaption>Figure 2: InsIQ Planning -> Discovery -> KeyPlayers</figcaption>
            </figure>
            <p>
              Here, we take the example of a ‘Wearable Patch – a fictional nutrition supplement’ product, and show the
              higher order requirements for a key player ‘exhausted parents’ or type ‘consumer’.
            </p>
          </article>

          <article #reviewKeyPlayerInsights>
            <h1 class="h3 text-accent">Step 5: Review Your KeyPlayer Insights</h1>
            <figure>
              <img src="./assets/images/help/14.png" alt="Help Figure">
              <figcaption>Figure 3: Reiew Key Player Insights</figcaption>
            </figure>

            <p>
              Here you will be able to review your key player insights by category, e.g. Behavioral (touch) or Safety
              (compliance). There are plenty of other parameters to review (have a read in the InsIQ ebook and click
              on the relevant insights tab to ensure you do not miss a fundamental requirement for any of your key
              players.
            </p>

            <figure>
              <img src="./assets/images/help/15.png" alt="Help Figure">
              <!-- <figcaption>Figure 1.1</figcaption> -->
            </figure>
            <p>
              Once you collect insights about key player requirements both from the AI and other means such as surveys
              or personal interviews, you are ready to develop a Product Requirements Specification (PRS) in the next
              stages. This is akin to having ‘voice of the customer’ in your product or more generic frameworks that
              help collect such info. Your product requirements can be derived from these insights. InsIQ helps you
              implement this in process steps.
            </p>

            <figure>
              <img src="./assets/images/help/16.png" alt="Help Figure">
              <figcaption>Figure 4: Sample Key Player Insights In Behavioral Category For Coffee Mug</figcaption>
            </figure>
          </article>

          <article #addRequirements>
            <h1 class="h3 text-accent">Step 6: Add requirements for your KeyPlayers</h1>

            <p>
              These are the require ments of your key players which will help develop your product specification.
            </p>
            <p>
              All your key players interact with your product, and they like or dislike your product based on these
              interactions.
            </p>
            <p>
              Typically, every requirement for your Consumers, Contributors, and Influencer will fall into one these
              three groups (requirement type):
            </p>
            <p>
              BEHAVIORAL AND VISCERAL: These are needs based on how people interact with products with one or more
              their five basic human senses- sight, touch, smell, taste, and hearing.
            </p>
            <p>
              COGNITIVE AND HIGHER ORDER: Needs which extend beyond the five basic senses fall into this category.
            </p>
            <p>
              SAFETY AND ERROR-PROOFING: You cannot afford to have a product that is unsafe for any of your key
              players.
            </p>

            <figure>
              <img src="./assets/images/help/17.png" alt="Help Figure">
              <figcaption>Figure 5: InsIQ Planning -> Discovery -> KeyPlayers -> Requirements</figcaption>
            </figure>
            <figure>
              <img src="./assets/images/help/18.png" alt="Help Figure">
              <figcaption>Figure 6: InsIQ Planning -> Discovery -> KeyPlayer Requirements By Requirement Type</figcaption>
            </figure>
            <figure>
              <img src="./assets/images/help/19.png" alt="Help Figure">
              <figcaption>Figure 7: InsIQ Planning -> Discovery -> KeyPlayers -> Requirement List</figcaption>
            </figure>

            <div class="proTip">
              <h5 class="h5 text-accent">Pro Tip: </h5>
              <p>
                Pay careful attention and consideration to all the requirements for all your key players. In next
                stages
                you will prioritise them, but at this point try to ensure you do not miss anything.
              </p>

              <figure>
                <img src="./assets/images/help/20.png" alt="Help Figure">
                <!-- <figcaption>Figure 7: InsIQ Planning -> Discovery -> KeyPlayers -> Requirement List</figcaption> -->
              </figure>
              <p>
                This is an example of a requirement that can cause serious consequences for your product if ignored and
                not properly tested. In this case a wearable patch causing skin allergies may get banned by relevant
                regulatory bodies.
              </p>
              <figure>
                <img src="./assets/images/help/21.png" alt="Help Figure">
                <figcaption>Figure 6: InsIQ Planning -> Discovery -> KeyPlayer Requirements By Requirement Type</figcaption>
              </figure>
              <figure>
                <img src="./assets/images/help/22.png" alt="Help Figure">
                <figcaption>Figure 7: InsIQ Planning -> Discovery -> KeyPlayers -> Requirement List</figcaption>
              </figure>
            </div>

            <p>
              Note: A consumer requirement here can be attractive look, feel or a higher order need like for the
              wearable sticker in our example to be waterproof for everyday use. Here is a good place to get some
              Insights too. The requirements of your Consumers are essential, but it is critical to making sure that
              your Contributors and Influencers work well with your product too.
            </p>
          </article>

          <article #prioritiseAndTest>
            <h1 class="h3 text-accent">Step 7: Prioritise and Test For Important Key Player requirements</h1>
            <p>
              Now you will need to choose the specific level of importance for each requirement from ‘nice to have’ to
              ‘very important’; in our wearable patch example the ‘waterproof’ requirement is ‘important’; while
              testing for skin allergens on children aged 12+ is a ‘very important’ requirement for the needs of key
              player parents and needs to be tested.
            </p>

            <figure>
              <img src="./assets/images/help/23.png" alt="Help Figure">
              <figcaption>Figure 8: Requirement Importance</figcaption>
            </figure>

            <p>Review AI testing insights and start to build test cases to cover the key requirements. </p>

            <figure>
              <img src="./assets/images/help/24.png" alt="Help Figure">
              <!-- <figcaption>Figure 6: InsIQ Planning -> Discovery -> KeyPlayer Requirements By Requirement Type</figcaption> -->
            </figure>

            <figure>
              <img src="./assets/images/help/25.png" alt="Help Figure">
              <figcaption>Figure 9: InsIQ Planning -> Discovery -> KeyPlayers -> Requirement Type -> Requirements ->
                Testing</figcaption>
            </figure>

            <p>
              You need to be able to test that the requirement can be met via a measurable metric. In our wearable
              patch this is tested for IP-67 ingress protection level to meet the waterproof requirement.
            </p>

            <figure>
              <img src="./assets/images/help/26.png" alt="Help Figure">
              <!-- <figcaption>Figure 7: InsIQ Planning -> Discovery -> KeyPlayers -> Requirement List</figcaption> -->
            </figure>

            <p>
              Once you have defined you key players, the requirements and a test strategy for the requirements, you
              have completed what we call in InsIQ – the Discovery.
            </p>

            <p>
              You discovered who your key players are, what they need or want and how to test your product to make sure
              those needs are met.
            </p>
            <p>
              Tip: A product is successful only if it meets the requirements of your key players, ensure that you do
              this properly.
            </p>
            <p>
              Your InsIQ PLANNING process involves Discovery & Concept stages.
            </p>
            <p>
              The concept design is usually considered to be a drawing, image or rendering of what your final product
              might look like, this is a Behavioral (visual) need. For your design concept you may also need to
              consider what the product would feel like, this is a Behavioral (touch) requirement. Similarly, many such
              requirements can be now imported from the requirements list and evaluated for design features that will
              meet these needs. Not all needs are equally important. They should be organized as being ‘essential’ or
              ‘nice to have’ – this is a part of what product management function in an organisation does (with InsIQ
              you can do it yourself).
            </p>
            <p>
              The InsIQ software allows you to add this element of planning to the start of the design process. Here
              you take all the needs or requirements which you discovered earlier for each key player and brainstorm
              design features.
            </p>

            <figure>
              <img src="./assets/images/help/27.png" alt="Help Figure">
              <figcaption>Figure 10: InsIQ Planning -> Design Concept</figcaption>
            </figure>
            <figure>
              <img src="./assets/images/help/28.png" alt="Help Figure">
              <figcaption>Figure 11: InsIQ Planning-> Concept -> Import Requirement</figcaption>
            </figure>
            <figure>
              <img src="./assets/images/help/29.png" alt="Help Figure">
              <figcaption>Figure 12: InsIQ Planning -> Concept -> Requirement -> Feature -> Process Feature</figcaption>
            </figure>

            <p>
              Here the process feature ensures your defined design feature ‘water resistant material’ to meet the
              ‘waterproof’ requirement of the product can be produced economically and viably.
            </p>
            <p>
              Currently, your beta trial provides you everything you need till the InsIQ Planning. If you have enjoyed
              using the software or like to suggest improvements, please get in touch!
            </p>
            <p>
              In the next release candidate, you will be able to begin EXECUTION phase starting with the Risk Analysis.
              Some samples below of what more to come.
            </p>
            <figure>
              <img src="./assets/images/help/30.png" alt="Help Figure">
              <figcaption>Figure 12: InsIQ Planning -> Concept -> Requirement -> Feature -> Process Feature</figcaption>
            </figure>

            <p>
              <b>A brief note on InsIQ’s built - in intelligence</b> : We are focusing on providing AI driven insights
              within a process or framework of product development. The intent is not to provide the exact answer to
              the user but rather provide relevant links ,images or data which helps the user make the right decisions
              at different stages of development. We continue to improve this capability and would like feedback from
              our users to make it useful for them.
            </p>

            <p>
              There is also a lot more to come beyond the Framework – message centre, team collaboration, feedback box
              and place to gather roadmap ideas, exporting reports, a dedicated InsIQ forum and more… we need your
              help to build the next elements of InsIQ platform that will be guiding makers, inventors, Product Owners
              and entrepreneurs like you through further stages of development to market. Contact: <a href="mailto:support@insiq.io">support@insiq.io</a>
            </p>
          </article>
        </div>
        <div class="col-sm-8 helpMenu" id="helpMenu" #stickyMenu>
          <mat-accordion [class.sticky]="sticky">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon fontSet="i" fontIcon="i-help"></mat-icon>
                  <span>InsIQ Help Manual</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul class="menu">
                <li class="active">
                  <a (click)="scroll(register, $event)">Step 1: Register for your new account</a>
                </li>
                <li>
                  <a (click)="scroll(newProject, $event)">Step 2: Create a new project</a>
                </li>
                <li>
                  <a (click)="scroll(reviewAIInsights, $event)">Step 3: Review Your ‘AI Insights’ From Dashboard</a>
                </li>
                <li>
                  <a (click)="scroll(addKeyPlayers, $event)">Step 4: Add KeyPlayers</a>
                </li>
                <li>
                  <a (click)="scroll(reviewKeyPlayerInsights, $event)">Step 5: Review Your KeyPlayer Insights</a>
                </li>
                <li>
                  <a (click)="scroll(addRequirements, $event)">Step 6: Add requirements for your KeyPlayers</a>
                </li>
                <li>
                  <a (click)="scroll(prioritiseAndTest, $event)">Step 7: Prioritise and Test For Important Key Player
                    requirements</a>
                </li>
              </ul>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Help section #2
                </mat-panel-title>
                <mat-panel-description>
                  Currently I am
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ul class="menu">
                <li>
                  <a href="javascript:;">List item 1</a>
                </li>
                <li>
                  <a href="javascript:;">List item 2</a>
                </li>
                <li>
                  <a href="javascript:;">List item 3</a>
                </li>
              </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Help section #3
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul class="menu">
                <li>
                  <a href="javascript:;">List item 1</a>
                </li>
                <li>
                  <a href="javascript:;">List item 2</a>
                </li>
                <li>
                  <a href="javascript:;">List item 3</a>
                </li>
              </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  FAQ
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul class="menu">
                <li>
                  <a href="javascript:;">FAQ 1</a>
                </li>
                <li>
                  <a href="javascript:;">FAQ 2</a>
                </li>
                <li>
                  <a href="javascript:;">FAQ 3</a>
                </li>
              </ul>
            </mat-expansion-panel> -->
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
