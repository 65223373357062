import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';


@Component({
  selector: 'app-teammessage-dialog',
  templateUrl: 'teammessage-dialog.html',
  styleUrls: ['teammessage-dialog.scss']
})

export class TeammessageDialogComponent {
 errmsg: boolean;
  constructor(
    public dialogRef: MatDialogRef<TeammessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public router: Router,
 
  ) {
  this.errmsg=false;
  }

  backtoPage() {
    this.dialogRef.close({data:'',status:'close'});
  }
  backtoHome() {
    this.dialogRef.close({data:'',status:'close'});
    this.router.navigate(['']);
  }
  sentmsg()
  {
     
      const channelname = ((document.getElementById('channelmsg') as HTMLInputElement).value);
      if(channelname=='')
      {
         this.errmsg=true;
      }
      else
      {
         this.dialogRef.close({data:channelname,status:'post'});
      }
     
       
  }

}
