<div class="d-block p-a-0">
    <h4 class="text-accent">
        <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" [mat-dialog-close]="true" title="Close">
            <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
        </button>
    </h4>
    <mat-card-header>
        <div mat-card-avatar class="b-a-1 t-c">
            <i class="i i-idea i-2_5x text-primary"></i>
        </div>
        <mat-card-title>InsIQ Share Project</mat-card-title>
        <mat-card-subtitle>Share your project and its associated research with others here.</mat-card-subtitle>
    </mat-card-header>

    <div class="searchBar b-t-3 p-t-10 p-b-5">
        <div class="row align-items-center">

            <div class="col w-100">
                <mat-form-field appearance="outline" class="w-100 m-b--10 sm">
                    <!-- <mat-label>InsIQ AI Report Title</mat-label> -->
                    <input matInput placeholder="Enter Email Id of the user to share this project" #Aikeyword
                        (keydown.backspace)="omit_special_char($event,Aikeyword.value.trim(),ai.fixaitypeId)"
                        (keydown.space)="$event.preventDefault()"
                        (keypress)="omit_special_char($event,Aikeyword.value.trim(),ai.fixaitypeId)" id="newHero">
                    <!-- <mat-hint>Modify here to view AI Insights</mat-hint> -->
                </mat-form-field>
            </div>
            <!-- <div class="deepDiv"><span>Deep Dive </span>
          <mat-slide-toggle
            class="tp-margin" (change)="ai.deepdive(view, $event)"        
            [checked]="ai.deepdiveflag"
            [disabled]="disabled">
          </mat-slide-toggle>
        </div> -->

            <div class="col-auto p-l-0 m-b-5">
                <div>
                    <button matRipple class="t-normal btn btn-lg btn-primary" *ngIf="mailStat"
                        (click)="validateUser(Aikeyword.value.trim())" #search>
                        <mat-icon class="i-1_5x ib-m">account_box</mat-icon>
                        <span class="ib-m m-l-15">Validate User</span>
                        <i class="i i-2x">serach</i>
                    </button>

                    <!-- <mat-button-toggle-group class="sm">
              <mat-button-toggle>
                <mat-icon class="i-1_5x ib-m">AI</mat-icon>
                <span class="ib-m">Ask TOM</span>
              </mat-button-toggle>
              <mat-button-toggle color="accent">
                <mat-icon>arrow_drop_down</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group> -->
                </div>

            </div>

        </div>
        <div class="w-100 d-flex align-items-center m-t-30 m-b-20 justify-content-center" *ngIf="!mailStat&&validFlag">

            <div class="m-l-30 text text-success" *ngIf="InsIQUser">
                <i class="i i-check_circle"></i>
                <span>Valid InsIQ User.You can share this project with the user directly using InsIQ.</span>
            </div>
            <div class="m-l-30 text text-warning" *ngIf="!InsIQUser">
                <i class="i i-warning"></i>
                <span>Not a InsIQ User.Even though you can share this project with the user via Email and the user need
                    to create a InsIQ account using the same Email id.</span>
            </div>
        </div>
        <div class="w-100 d-flex align-items-center m-t-30 m-b-20 justify-content-center" *ngIf="!mailStat&&validFlag">



            <button type="button" matRipple class="btn btn-lg btn-primary m-x-5"
                (click)="shareProject(Aikeyword.value.trim())">Share
                Project</button>

        </div>
    </div>

</div>