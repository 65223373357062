import {
  Input,
  Output,
  OnInit,
  Component,
  EventEmitter,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'app-smart-tree',
  templateUrl: './smart-tree.component.html',
  styleUrls: ['./smart-tree.component.scss']
})
export class SmartTreeComponent implements OnInit {

  @Input() data: any;
  @Input() quickHelp = false;
  @Input() helpTemplate: TemplateRef<any>;
  @Output() nodeOpen = new EventEmitter<any>();
  @Output() nodeClose = new EventEmitter<any>();
  @Output() createNode = new EventEmitter<any>();

  public page: any;

  constructor() {
  }

  ngOnInit() {
    // console.log(this.data);
  }

  autoOpen(tree, bool: boolean) {
    tree.open = bool;
    return bool;
  }

  openTree(el, target) {
    // console.log(el, target);
    const tgt = target.split(',');
    // document.querySelector(target);
    target = tgt;
    const openedNodes = el.closest('.tree').querySelector('.open');
    if (openedNodes) {
      openedNodes.classList.remove('open');
    }

    // if (target.length > 1) {
    target.forEach((node, i) => {
      // console.log(node);
      tgt[i] = document.querySelector(node);
      // console.log(tgt.length);
      if (i !== (target.length - 1)) {
        // console.log(node + ' li');
        if (document.querySelector(node + ' li')) {
          document.querySelector(node + ' li').classList.remove('open');
          // document.querySelector(node + ' li:first-child').classList.add('open');
        } else {
          console.log('List (' + node + ' li) is Empty / is loading...');
        }
      }
    });
    // }

    // Opening current tree
    if (el.nodeName.toLowerCase() === 'li' || el.closest('li').length <= 0) {
      el.classList.add('open');
    } else {
      el.closest('li').classList.add('open');
    }

    // console.log('Opening', typeof tgt);
    // console.log(tgt);

    if (tgt) {
      tgt.forEach((tree, i) => {
        // console.log('Enable');
        // console.log(tree);
        tree.closest('li').classList.remove('disabled');
        tree.classList.add('open');
      });
    } else {
      console.error('target: ', null);
      // setTimeout(() => {
      //   tgt.closest('li').classList.remove('disabled');
      //   tgt.classList.add('open');
      // }, 500);;
    }
  }

  closeTree(el, target) {
    // console.log(el, target);
    // const tgt = document.querySelector(target);
    const tgtArr = target.split(',');
    target.split(',').forEach((element, i) => {
      tgtArr[i] = document.querySelector(element);
    });

    // console.log(tgt);
    // const groupTree =
    // (el.closest('[data-group-tree]')) ? el.closest('[data-group-tree]').getAttribute('data-group-tree').split(',') : [];

    tgtArr.forEach((tgt, tgtI) => {
      // tgt = tgt[0];
      if (tgt.hasAttribute('data-child-tree') && tgt.querySelector('.open') !== null) {
        this.closeTree(tgt.querySelector('li.open .nodeBtn'), tgt.getAttribute('data-child-tree'));
      }

      // Closing previously opened trees
      const openedNodes = el.closest('.tree').querySelector('.open');
      if (openedNodes) {
        openedNodes.classList.remove('open');
      }

      // Closing current tree
      if (el.nodeName.toLowerCase() === 'li' || el.closest('li').length <= 0) {
        el.classList.remove('open');
      } else {
        el.closest('li').classList.remove('open');
      }

      // for Groups
      // if (groupTree.length > 1) {
      //   groupTree.forEach((node, i) => {
      //     if (document.querySelector(node + ' li')) {
      //       if (i !== (target.length - 1)) {
      //         document.querySelector(node + ' li').classList.remove('open');
      //       }
      //     }
      //   });
      // }

      if (tgt) {
        // console.log('Disable');
        // console.log(tgt);
        tgt.closest('li').classList.add('disabled');
        tgt.classList.remove('open');
      }
      // else {
      //   console.log('tgt: ', null);
      //   setTimeout(() => {
      //     tgt.closest('li').classList.add('disabled');
      //     tgt.classList.remove('open');
      //   }, 500);
      // }
    });
  }

  toggleTree(e, node, data, tree) {
    const key = tree.key;
    const el = e.target,
      tgt = el.dataset.targetTree,
      stat = el.classList.contains('open') || el.closest('li').classList.contains('open');

    tree.open = stat;

    // console.log(tgt);
    // console.log(groupTree);
    tree.activeNode = node.id;
    tree.view.active = node;

    const groupTree = (el.closest('[data-group-tree]')) ? el.closest('[data-group-tree]').getAttribute('data-group-tree').split(',') : [];

    if (groupTree.length > 1) {
      groupTree.forEach((t, i) => {
        this.closeTree(el, t);
        this.nodeClose.emit([tgt, key, node, data]);
      });
    }

    this.closeTree(el, tgt);
    this.nodeClose.emit([tgt, key, node, data]);

    if (tree.open) {
      tree.activeNode = null;
      tree.view.active = null;
    }

    tree.open = !tree.open;

    // this.getNodeData(key, node, data);

    // console.log(tgt);
    if (!stat) {
      // console.log('Opening');
      this.nodeOpen.emit([tgt, key, node, data]);
      this.openTree(el, tgt);
    }
    return !stat;
  }

  newConnectNode(e, node, keys, tree) {
    tree.activeNode = null;
    tree.view.active = null;

    this.closeTree(e.target, e.target.dataset.targetTree);

    keys = keys.split(',');
    keys.forEach(key => {
      this.createNode.emit([node, key]);
    });
  }
}
