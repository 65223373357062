import { Router } from '@angular/router';
import { AIView } from '../ai/insight.view';
import { NotesDialogComponent } from '../notesalerts/notes-dialog';
import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-sentimentdialog',
  templateUrl: './sentimentdialog.component.html',
  styleUrls: ['./sentimentdialog.component.scss']
})
export class SentimentdialogComponent {
  showWish = false;
  favStat = false;
  cols;
  // this.selCat = 0;
  notesDialogRef: MatDialogRef<NotesDialogComponent, any>;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  constructor(
    public aiv: AIView,
    public router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRefsentiment: MatDialogRef<SentimentdialogComponent>
  ) {
    this.cols = [
      { field: 'actions', header: 'Actions' },
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'keywords', header: 'Keywords' },
      { field: 'date', header: 'Date' }
    ];
    this.showWish = false;
    this.favStat = false;
  }

  @ViewChild('secondDialog', { static: true }) STAT_POPUP: TemplateRef<any>;

  backtoPage() {
    this.dialogRefsentiment.close();
    this.aiv.listViewInsight = true;

  }
  truncateText(inputText: string): string {
    const maxLength = 50;

    if (inputText.length <= maxLength) {
      return inputText;
    } else {
      return inputText.substring(0, maxLength) + '...';
    }
  } backtoHome() {
    this.dialogRefsentiment.close();
    this.aiv.listViewInsight = true;

    this.router.navigate(['']);
  }

  insightDeep(aiId, fl) {
    this.aiv.insightDeep(aiId, fl);
  }
  catName(fl) {
    return this.aiv.catName(fl, this.aiv.view);

  }
  openLinkInNewTab(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
  splitKeywordsAndDescription(aiText: any): { keywords: string; description: string } {
    if (!aiText) {
      return { keywords: '', description: '' };
    }

    const splitText = aiText.split('<br /><br />');
    const keywords = splitText[0].replace('Keywords: ', '').trim(); // Trim to remove leading and trailing spaces
    const description = splitText[1] || '';
    return { keywords, description };
  }

  splitTextPricing(text) {
    const regex = /Keywords:\s*([^<]*)<br\s*\/>\s*(.*)/i;
    const match = text.match(regex);

    if (match) {
      const keywords = match[1].trim();
      const description = match[2].trim();
      return {
        keywords: keywords,
        description: description
      };
    } else {
      return {
        keywords: '',
        description: text.trim()
      };
    }
  }

  splitKeywordsAndTitle(aiText: any): { keywords: string; title: string } {
    if (!aiText) {
      return { keywords: '', title: '' };
    }

    const splitText = aiText.split('<br />');
    const keywords = splitText[1]?.replace('Keywords: ', '').trim(); // Trim to remove leading and trailing spaces
    const title = splitText[0] || '';
    return { keywords, title };
  }
  likeOrUnlike(row, val) {
    let insight;
    row.aiUrl = row.aiUrl;

    row.aiImages = '';
    row.aiVideos = '';
    row.aiTitle = row.aiTitle;
    row.aiText = row.aiText;
    if (row.liked) {
      val = 0;
    }
    //row.aiId=row.aiid;
    this.aiv.likeOrUnlikecat(row, val, 'Product and Competitor sentiment');
  }

  categoryInsight(row, i) {

  }

  filter_array(test_array) {
    let index = -1;
    const arr_length = test_array ? test_array.length : 0;
    let resIndex = -1;
    const result = [];

    while (++index < arr_length) {
      const value = test_array[index];
      if (value) {
        result[++resIndex] = value;
      }
    }

    return result;
  }
  catfilter() {
    const cataray = [];
    cataray.push('All');
    let check = 0;
    //  console.log(this.data.insightDetails.length);
    try {
      for (let i = 0; i < this.data.insightDetails.length; i++) {
        //        if (this.smartlistItemDetailtip[i].aiTitle.includes('InsIQ Curated')) {
        //          check = 1;
        //        }
        console.log(this.data.insightDetails[i].aiData.aiCategory);
        cataray.push(this.data.insightDetails[i].aiData.aiCategory);
      }
      //      if (check == 1) {
      //        cataray.push('InsIQ Curated');
      //      }
      const unique = cataray.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      console.log(this.filter_array(unique));
      return this.filter_array(unique);
    } catch (error) {
      //   console.error(error);
    }

  }

  addremoveReport(row) {
    row.aiUrl = row.aiUrl;
    row.aiImages = '';
    row.aiVideos = '';
    row.aiNameTemp = 'Product and Competitor sentiment';
    this.aiv.addremoveReport(row);
  }
  testfn(row) {
    if (this.aiv.featureChecker(15)) {
      this.testfn1(row);
    } else {
      this.aiv.featurealert('Notes');
    }
  }
  testfn1(row) {
    //    row.aiUrl = row.aiUrl;
    //      row.aiImages = '';
    //    row.aiVideos = '';
    this.aiv.newnotesave = false;
    if (row?.liked?.aiLikeon === 1) {
      this.favStat = true;
    } else {
      this.favStat = false;
    }
    this.aiv.notesRow = row;
    this.aiv.view.set('notes', row.noteAdd);
    this.aiv.noteOld = row.noteAdd;
    if (!this.aiv.noteOld) {
      //   this.aiv.newnotesave = true;
      this.aiv.view.set('notesId', null);

    } else {
      this.aiv.view.set('notesId', 1);

    }

    //  console.log(this.STAT_POPUP);
    this.notesDialogRef = this.dialog.open(NotesDialogComponent, {
      data: this.STAT_POPUP
    });
  }

  cancelNote() {
    this.notesDialogRef.close();
  }
  cnfrmLike() {
    // console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Add to Favorite?', message: 'Would you like to mark this Insight as Favorite also ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.likeOrUnlike(this.aiv.notesRow, '1');
      }
    });
  }
  saveNote() {



    this.aiv.saveNotetrend('Product and Competitor sentiment');
    if (!this.favStat) {
      this.cnfrmLike();
    }
    this.notesDialogRef.close();
  }

  openConfirmationDialog() {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure want to delete this note ?',
        cancelbtn: 'No',
        okbtn: 'Yes'
      },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.aiv.deleteNotetrend();
        this.notesDialogRef.close();
      }
    });
  }

  deleteNote() {
    this.openConfirmationDialog();
  }

  saveNotems() {
    this.aiv.saveNotemstrend();
    this.notesDialogRef.close();
  }

  fbshare(e: { preventDefault: () => void; }, urls: string) {
    e.preventDefault();
    const facebookWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php${urls}`
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }

  linkedinshre(e: { preventDefault: () => void; }, urls: string, aiTitle: string) {
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${urls}&title=${aiTitle}&summary=${aiTitle}&source=InsIQ`;
    e.preventDefault();
    const linkedinWindow = window.open(url);
    if (linkedinWindow.focus) {
      linkedinWindow.focus();
    }
    return false;
  }

  twittershare(e: { preventDefault: () => void; }, urls: string) {
    const text = 'InsIQ';
    const hashtag = 'insiq';
    e.preventDefault();
    const twitterWindow = window.open(
      `http://twitter.com/share?text=${text}&url=${urls}&hashtags=${hashtag}`
    );
    if (twitterWindow.focus) {
      twitterWindow.focus();
    }
    return false;
  }

  watzappshare(e: { preventDefault: () => void; }, urls: string, aiTitle: string) {
    e.preventDefault();
    const watzappWindow = window.open(
      `https://web.whatsapp.com/send?text=InsIQ AI Insight:  ${aiTitle}  Visit: ${urls} for more info  #insiqai,#insiq,#aipowered,#devtominsights`
    );
    if (watzappWindow.focus) {
      watzappWindow.focus();
    }
    return false;
  }
  getScoreColor(score) {

    let emo = '';
    if (score === 'Positive') {
      emo = 'text-primary';
    } else if (score === 'Negative') {
      emo = 'text-danger';
    } else if (score === 'Neutral') {
      emo = 'text-accent';
    } else {
      emo = 'text-default';
    }
    return emo;
  }

  getScoreEmoji(score) {
    let emo = '';
    if (score === 'Positive') {
      emo = 'sentiment_very_satisfied';
    } else if (score === 'Negative') {
      emo = 'sentiment_very_dissatisfied';
    } else if (score === 'Neutral') {
      emo = 'sentiment_satisfied';
    } else {
      emo = 'emoji_emotions';
    }
    return emo;
  }
  trimtooltip(noteAdd: string) {
    try {
      if (noteAdd.length > 5) {
        return noteAdd.split(' ').slice(0, 5).join(' ') + '...';
      } else {
        return noteAdd;
      }
    } catch (exception) { }
  }
}
